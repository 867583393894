import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonListHeader,
  IonList,
  IonItem,
  IonLabel,
  IonLoading
} from '@ionic/react';
import moment, { Moment } from 'moment';
import Calendar from 'rc-calendar';
import 'rc-calendar/assets/index.css';

import connect from '../../data/connect';
import {
  fetchTimeslotsByDate,
  createTimeslot,
  enableTimeslot,
  fetchClinicMembers
} from '../../data/dataApi';
import { User, Location } from '../../models';
import './ClinicTimeslots.scss';

interface Slot {
  slot: number
  status: string
  type: string
}

interface StateProps {
  user: User
}

type ProviderTimeslotsProps = StateProps;

const ProviderTimeslots: React.FC<ProviderTimeslotsProps> = ({ user }) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [slots, setSlots] = useState([] as Slot[]);
  const [members, setMembers] = useState<User[]>([]);
  const [member, setMember] = useState({} as User);
  const [location, setLocation] = useState({} as Location);
  const [isLoading, setIsLoading] = useState(false);

  const onSelectDate = useCallback(
    async (date: Moment) => {
      setCurrentDate(date);
      const response: { success: boolean, slots: Slot[] } = await fetchTimeslotsByDate(
        member.id,
        location.id,
        moment(date.format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm')
      );
      if (response.success) {
        setSlots(response.slots);
      }
    },
    [member.id, location]
  );

  const slotToMoment = (slot: number): Moment => moment({
    hour: slot / 4,
    minute: (slot % 4) * 15
  });

  const onDisableTimeslot = (slot: number, type: string): void => {
    createTimeslot({
      user: member.id,
      startDay: currentDate.utc().format('YYYY-MM-DD'),
      location: location.id,
      start: slotToMoment(slot).utc().format('HH:mm'),
      end: slotToMoment(slot + 1).utc().format('HH:mm'),
      type
    })
      .then((res: { success: boolean }) => {
        if (res.success) {
          setSlots(
            slots.map((item) => (item.slot === slot ? { ...item, status: 'disabled' } : item))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const onEnableTimeslot = (slot: number): void => {
    enableTimeslot(
      member.id,
      currentDate.utc().format('YYYY-MM-DD'),
      slotToMoment(slot).utc().format('HH:mm')
    )
      .then((res: { success: boolean }) => {
        if (res.success) {
          setSlots(
            slots.map((item) => (item.slot === slot ? { ...item, status: 'enabled' } : item))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (currentDate) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onSelectDate(currentDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const renderSlot = (item: Slot): JSX.Element => {
    if (item.status === 'closed') {
      return (
        <IonButton
          className="round-border"
          fill="outline"
          expand="block"
          // eslint-disable-next-line no-nested-ternary
          color={item.type === 'video-visit' ? 'facebook' : item.type === 'provider-visit' ? 'primary' : 'workshop'}
          disabled
        >
          {slotToMoment(item.slot).format('h:mm A')}
        </IonButton>
      );
    }
    if (item.status === 'enabled') {
      return (
        <IonButton
          className="round-border"
          fill="solid"
          expand="block"
          // eslint-disable-next-line no-nested-ternary
          color={item.type === 'video-visit' ? 'facebook' : item.type === 'provider-visit' ? 'primary' : 'workshop'}
          onClick={(): void => onDisableTimeslot(item.slot, item.type)}
        >
          {slotToMoment(item.slot).format('h:mm A')}
        </IonButton>
      );
    }
    if (item.status === 'disabled') {
      return (
        <IonButton
          className="round-border"
          fill="outline"
          expand="block"
          // eslint-disable-next-line no-nested-ternary
          color={item.type === 'video-visit' ? 'facebook' : item.type === 'provider-visit' ? 'primary' : 'workshop'}
          onClick={(): void => onEnableTimeslot(item.slot)}
        >
          {slotToMoment(item.slot).format('h:mm A')}
        </IonButton>
      );
    }
    return <></>;
  };

  useEffect(() => {
    setIsLoading(true);
    fetchClinicMembers(user.id)
      .then((res: { success: boolean, members: User[] }) => {
        if (res.success) {
          setMembers(res.members);
          if (res.members.length) {
            setMember(res.members[0]);
            if (res.members[0].locations.length) {
              setLocation(res.members[0].locations[0]);
            }
          }
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <IonContent id="clinic-timeslots" className="provider-schedule-content page-content ion-padding">
      <IonListHeader className="page-header">Change Business Hours</IonListHeader>
      <IonList>
        <IonItem lines="none">
          <IonSelect
            className="select"
            placeholder="Select a member"
            value={member}
            onIonChange={(e): void => {
              const tempUser = e.detail.value as User;
              setMember(tempUser);
              if (tempUser.locations.length) {
                setLocation(tempUser.locations[0]);
              }
            }}
          >
            {members.map((mb) => (
              <IonSelectOption key={mb.id} value={mb}>
                {mb.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem lines="none">
          <IonSelect
            className="select"
            placeholder="Select your location"
            value={location}
            disabled={!member.locations || !member.locations.length}
            onIonChange={(e): void => setLocation(e.detail.value)}
          >
            {member.locations
              && member.locations.map((item) => (
                <IonSelectOption key={item.id} value={item}>
                  {item.address}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonItem>
      </IonList>
      <IonGrid>
        <IonRow>
          <IonLabel color="dark" className="sub-title">
            {currentDate.format('MMMM Do, YYYY')}
          </IonLabel>
        </IonRow>
        <IonRow>
          <Calendar
            className="calendar"
            showDateInput={false}
            showToday={false}
            onSelect={(date): Promise<void> => onSelectDate(date)}
            disabledDate={(current): boolean => {
              if (member.limitPeriod) {
                return !(moment(moment().format('MM-DD-YYYY')).isSameOrBefore(current) && moment(moment().add(member.limitPeriod, 'weeks').format('MM-DD-YYYY')).isSameOrAfter(current));
              }
              return moment(moment().format('MM-DD-YYYY')).isSameOrAfter(current);
            }}
          />
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonLabel color="dark" className="sub-title">
              Select slot
            </IonLabel>
          </IonCol>
          <div className="slot-legend">
            <div className="slot-legend-option video">
              <span />
              Virtual
            </div>
            <div className="slot-legend-option provider">
              <span />
              Provider Location
            </div>
            <div className="slot-legend-option customer">
              <span />
              Customer Location
            </div>
          </div>
        </IonRow>
        <IonRow>
          {slots.map((item: Slot) => (
            <IonCol key={item.slot} size="3">
              {renderSlot(item)}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <IonLoading isOpen={isLoading} />
    </IonContent>
  );
};

ProviderTimeslots.propTypes = {
  user: PropTypes.any.isRequired
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  component: React.memo(ProviderTimeslots)
});
