import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonAlert,
  IonListHeader
} from '@ionic/react';

import connect from '../../data/connect';
import { stripeClient, createProviderBankAccount } from '../../data/dataApi';
import { dispatchUpdateUser } from '../../data/store/auth/auth.actions';
import {
  UserIcon, LandIcon, CardIcon, PortraitIcon
} from '../../icons';
import { User } from '../../models';

import './ProviderAccount.scss';

interface StateProps {
  user: User
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

type Props = StateProps & RouteComponentProps & DispatchProps

const ProviderAccount: React.FC<Props> = ({
  user,
  history,
  updateUser
}) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(null);
  const [country, setCountry] = useState<'US' | 'CA'>('US');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [transitNumber, setTransitNumber] = useState('');
  const [institutionNumber, setInstitutionNumber] = useState('');
  const [error, setError] = useState('');
  const [isExist, setIsExist] = useState(false);

  const handleClick = async (): Promise<void> => {
    setLoading(true);
    const options: stripe.BankAccountTokenOptions = {
      country,
      currency: 'USD',
      routing_number:
        (country === 'US'
          ? routingNumber
          : `${transitNumber}-${institutionNumber}`) || '',
      account_number: accountNumber || '',
      account_holder_name: `${firstName} ${lastName}`,
      account_holder_type: 'individual'
    };
    const { token, error: stripeError } = await stripeClient.createToken('bank_account', options);
    if (stripeError) {
      setError('Please add correct information of your bank account.');
    }
    if (token) {
      const data = {
        token: token.id,
        firstName,
        lastName
      };
      createProviderBankAccount(data)
        .then((res) => {
          if (res.success) {
            updateUser({ ...user, sources: [...user.sources, res.bank] });
            setShowAlert(res.bank.last4);
            setError('');
          }
        })
        .catch((err) => {
          setError(err?.response?.data?.msg ?? '');
          if (err?.response?.status === 409) {
            setIsExist(true);
          }
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  const isSubmitDisabled = useMemo(() => {
    if (
      !firstName
      || !lastName
      || !accountNumber
      || accountNumber.length !== 12
      || (country === 'US' && (!routingNumber || routingNumber.length !== 9))
      || (country === 'CA' && (!transitNumber || transitNumber.length !== 5 || !institutionNumber || institutionNumber.length !== 3))
    ) {
      return true;
    }
    return false;
  }, [
    country, firstName, lastName, routingNumber, accountNumber, transitNumber, institutionNumber
  ]);

  return (
    <IonContent id="bank-information" className="page-content ion-padding">
      <IonListHeader className="page-header">Bank Information</IonListHeader>
      <IonList>
        <IonItem lines="none">
          <LandIcon />
          <IonSelect
            placeholder="Select One"
            value={country}
            onIonChange={(e): void => setCountry(e.detail.value)}
          >
            <IonSelectOption value="US">United States</IonSelectOption>
            <IonSelectOption value="CA">Canada</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem lines="none">
          <UserIcon />
          <IonInput
            name="firstName"
            value={firstName}
            placeholder="First Name"
            onIonChange={(evt): void => setFirstName(evt.detail.value || '')}
          />
        </IonItem>
        <IonItem lines="none">
          <UserIcon />
          <IonInput
            name="lastName"
            value={lastName}
            placeholder="Last Name"
            onIonChange={(evt): void => setLastName(evt.detail.value || '')}
          />
        </IonItem>
        {country === 'US' && (
          <IonItem lines="none">
            <CardIcon />
            <IonInput
              name="routingNumber"
              placeholder="Routing Number"
              maxlength={9}
              value={routingNumber}
              onIonChange={(evt): void => setRoutingNumber(evt.detail.value || '')}
            />
          </IonItem>
        )}
        {country === 'CA' && (
          <>
            <IonItem lines="none">
              <CardIcon />
              <IonInput
                name="routingNumber"
                placeholder="Transit Number"
                maxlength={5}
                value={transitNumber}
                onIonChange={(evt): void => setTransitNumber(evt.detail.value || '')}
              />
            </IonItem>
            <IonItem lines="none">
              <CardIcon />
              <IonInput
                name="routingNumber"
                placeholder="Institution Number"
                maxlength={3}
                value={institutionNumber}
                onIonChange={(evt): void => setInstitutionNumber(evt.detail.value || '')}
              />
            </IonItem>
          </>
        )}
        <IonItem lines="none">
          <PortraitIcon />
          <IonInput
            name="accountNumber"
            placeholder="Account Number"
            maxlength={12}
            value={accountNumber}
            onIonChange={(evt): void => setAccountNumber(evt.detail.value || '')}
          />
        </IonItem>
        <IonButton color="favorite" disabled={isSubmitDisabled} expand="block" onClick={handleClick}>
          Add Account
        </IonButton>
      </IonList>

      <IonAlert
        isOpen={!!showAlert}
        onDidDismiss={(): void => {
          history.goBack();
        }}
        header="Care Platform"
        message={`Bank Account with account number ending with ${showAlert ?? ''} is added`}
        buttons={['OK']}
      />
      <IonAlert
        isOpen={!!error}
        onDidDismiss={(): void => {
          setError('');
          if (isExist) {
            history.goBack();
          }
        }}
        header="Care Platform"
        message={error}
        buttons={['OK']}
      />

      <IonLoading isOpen={loading} />
    </IonContent>
  );
};

ProviderAccount.propTypes = {
  user: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  updateUser: PropTypes.func.isRequired,
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(ProviderAccount)
});
