import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonList,
  IonButton,
  IonIcon,
  IonModal,
  IonRippleEffect,
  IonSegment,
  IonSegmentButton,
  IonListHeader,
  IonRange,
  IonLoading,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';
import {
  options, locationOutline, searchOutline
} from 'ionicons/icons';

import connect from '../../data/connect';
import {
  User,
  Language,
  Specialist,
  ProviderTypeLabels,
  UserRole
} from '../../models';
import {
  addFavoriteProvider,
  deleteFavoriteProvider,
  fetchProviders,
  fetchClinics,
  fetchClinicMembers,
  fetchProvidersToday,
  fetchProvidersWithin3Days
} from '../../data/dataApi';
import UserItem from '../../components/UserItem';
import PlaceSearchInput from '../../components/PlaceSearchInput';
import ProviderMapView from '../ProviderMapView';
import { StarIcon } from '../../icons';

import './ProviderList.scss';

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface MatchParams {
  providerTypeId: string,
  specialistId: string
}

interface StateProps {
  user: User
  languages: Language[]
  specialists: Specialist[]
}

type ProviderListProps = RouteComponentProps<MatchParams> & StateProps;

const ProviderList: React.FC<ProviderListProps> = ({
  history,
  match,
  user,
  languages,
  specialists,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [providers, setProviders] = useState<User[]>([]);
  const [clinics, setClinics] = useState<User[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [selectedClinic, setSelectedClinic] = useState<User | null>(null);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRating, setSelectedRating] = useState(0);
  const [selectedLanguages, setSelectedLanguages] = useState([] as number[]);
  const [selectedSpecialists, setSelectedSpecialists] = useState([] as number[]);
  const [tempSelectedRating, setTempSelectedRating] = useState(0);
  const [tempSelectedLanguages, setTempSelectedLanguages] = useState(
    [] as number[]
  );
  const [tempSelectedSpecialists, setTempSelectedSpecialists] = useState(
    [] as number[]
  );
  const [segment, setSegment] = useState<'all' | 'today' | 'triple'>('all');
  const [typeSegement, setTypeSegement] = useState<'provider' | 'clinic-admin'>('provider');
  const [view, setView] = useState<'list' | 'map'>('list');
  const [location, setLocation] = useState({} as any);
  const [specialistName, setSpecialistName] = useState('');

  const onClickLanguage = (id: number): void => {
    if (tempSelectedLanguages.includes(id)) {
      setTempSelectedLanguages(
        tempSelectedLanguages.filter((lang) => lang !== id)
      );
    } else {
      setTempSelectedLanguages([...tempSelectedLanguages, id]);
    }
  };

  const onOpenFilter = (): void => {
    setTempSelectedLanguages(selectedLanguages);
    setTempSelectedSpecialists(selectedSpecialists);
    setShowFilter(true);
  };

  const onDismissFilter = (): void => {
    setTempSelectedLanguages([]);
    setTempSelectedSpecialists([]);
    setTempSelectedRating(0);
    setShowFilter(false);
  };

  const onClear = (): void => {
    setSelectedLanguages([]);
    setSelectedSpecialists([]);
    setSelectedRating(0);
    setProviders([]);
    setClinics([]);
    setMembers([]);
    setShowFilter(false);
    setIsFetchedAll(false);
  };

  const onApplyFilter = (): void => {
    setSelectedLanguages(tempSelectedLanguages);
    setSelectedSpecialists(tempSelectedSpecialists);
    setSelectedRating(tempSelectedRating);
    setProviders([]);
    setClinics([]);
    setMembers([]);
    setShowFilter(false);
    setIsFetchedAll(false);
  };

  const moveToDetails = (role: string, id: number): void => {
    if (role === UserRole.PROVIDER) {
      history.push(`/customer/provider/${id}`);
    } else if (role === UserRole.CLINIC_ADMIN) {
      history.push(`/customer/clinic/${id}`);
    }
  };

  const onAddFavorite = async (id: number, provider: User) => {
    try {
      await addFavoriteProvider(id, provider.id);
      if (typeSegement === 'provider') {
        const updatedProviders = providers.map((pv) => {
          if (pv.id === provider.id) {
            return { ...pv, isFavorite: 1 };
          }
          return pv;
        });
        setProviders([...updatedProviders]);
      }
      if (typeSegement === 'clinic-admin' && !selectedClinic) {
        const updatedClinics = clinics.map((cl) => {
          if (cl.id === provider.id) {
            return { ...cl, isFavorite: 1 };
          }
          return cl;
        });
        setClinics([...updatedClinics]);
      }
      if (typeSegement === 'clinic-admin' && selectedClinic) {
        const updatedMembers = members.map((mb) => {
          if (mb.id === provider.id) {
            return { ...mb, isFavorite: 1 };
          }
          return mb;
        });
        setMembers([...updatedMembers]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onRemoveFavorite = async (id: number, provider: User) => {
    try {
      await deleteFavoriteProvider(id, provider.id);
      if (typeSegement === 'provider') {
        const updatedProviders = providers.map((pv) => {
          if (pv.id === provider.id) {
            return { ...pv, isFavorite: 0 };
          }
          return pv;
        });
        setProviders([...updatedProviders]);
      }
      if (typeSegement === 'clinic-admin' && !selectedClinic) {
        const updatedClinics = clinics.map((cl) => {
          if (cl.id === provider.id) {
            return { ...cl, isFavorite: 0 };
          }
          return cl;
        });
        setClinics([...updatedClinics]);
      }
      if (typeSegement === 'clinic-admin' && selectedClinic) {
        const updatedMembers = members.map((mb) => {
          if (mb.id === provider.id) {
            return { ...mb, isFavorite: 0 };
          }
          return mb;
        });
        setMembers([...updatedMembers]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMore = () => {
    setIsFetching(true);
    if (typeSegement === 'provider' && !isFetchedAll) {
      const params = {
        skip: providers.length,
        providerType: match.params.providerTypeId,
        specialists: [match.params.specialistId],
        languages: selectedLanguages
      };
      fetchProviders(params)
        .then((res) => {
          if (res.success) {
            setProviders([...providers, ...res.providers]);
            if (!res.providers.length || res.providers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
    if (typeSegement === 'clinic-admin' && !isFetchedAll) {
      const params = {
        skip: clinics.length,
        providerType: match.params.providerTypeId,
        specialists: [match.params.specialistId],
        languages: selectedLanguages
      };
      fetchClinics(params)
        .then((res) => {
          if (res.success) {
            setClinics([...clinics, ...res.clinics]);
            if (!res.clinics.length || res.clinics.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
  };

  const fetchAvailableProviders = () => {
    if (segment === 'today') {
      setIsFetching(true);
      fetchProvidersToday(match.params.providerTypeId)
        .then((res) => {
          if (res.success) {
            setProviders([...res.providers]);
            setIsFetchedAll(true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
    if (segment === 'triple') {
      setIsFetching(true);
      fetchProvidersWithin3Days(match.params.providerTypeId)
        .then((res) => {
          if (res.success) {
            setProviders([...res.providers]);
            setIsFetchedAll(true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
  };

  const fetchMembers = (id: number) => {
    setIsFetching(true);
    const params = {
      providerType: match.params.providerTypeId,
      specialists: [match.params.specialistId],
      languages: selectedLanguages
    };
    fetchClinicMembers(id, params)
      .then((res) => {
        if (res.success) {
          setMembers(res.members);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    if (user.locations.length) {
      setLocation({ ...user.locations[0], address: '' });
    }
  }, [user]);

  useEffect(() => {
    if (segment === 'all' && typeSegement === 'clinic-admin' && selectedClinic) {
      fetchMembers(selectedClinic.id);
    }
    if (segment === 'all' && !selectedClinic) {
      fetchMore();
    }
    if (segment !== 'all') {
      fetchAvailableProviders();
    }
  }, [segment, typeSegement, selectedLanguages]);

  useEffect(() => {
    if (selectedClinic) {
      fetchMembers(selectedClinic.id);
    }
  }, [selectedClinic]);

  useEffect(() => {
    // fetch providers
    const { specialistId, providerTypeId } = match.params;
    if (specialistId && providerTypeId) {
      setIsFetching(true);
      const params = {
        skip: 0,
        providerType: providerTypeId,
        specialists: [specialistId]
      };
      fetchProviders(params)
        .then((res) => {
          if (res.success) {
            setProviders([...res.providers]);
            if (res.providers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }

    if (specialistId) {
      setSelectedSpecialists([Number(specialistId)]);
      const item = specialists.find((spec) => spec.id === Number(specialistId));
      if (item) {
        setSpecialistName(item.name);
      }
    }
  }, []);

  return (
    <IonContent id="provider-list" className="page-content ion-padding">
      <div className="provider-list-header">
        <IonListHeader className="page-header">
          {view === 'list' ? `Registered ${
            ProviderTypeLabels[parseInt(match.params.providerTypeId, 10)]
          } - ${specialistName}` : 'Map'}
        </IonListHeader>
        {view === 'map' ? (
          <IonButton color="favorite" fill="clear" onClick={() => setView('list')}>List view</IonButton>
        ) : null}
      </div>
      {view === 'list' ? (
        <div className="provider-list-controls">
          <div className="provider-view-options">
            <IonSegment
              mode="ios"
              value={segment}
              disabled={typeSegement === 'clinic-admin'}
              onIonChange={(e): void => {
                setProviders([]);
                setClinics([]);
                setMembers([]);
                setSelectedClinic(null);
                setIsFetchedAll(false);
                setSegment(e.detail.value as 'all' | 'today' | 'triple');
              }}
            >
              <IonSegmentButton value="all">All</IonSegmentButton>
              <IonSegmentButton value="today">Today</IonSegmentButton>
              <IonSegmentButton value="triple">1-3days</IonSegmentButton>
            </IonSegment>
            <IonSegment
              mode="ios"
              value={typeSegement}
              onIonChange={(e): void => {
                setProviders([]);
                setClinics([]);
                setMembers([]);
                setSegment('all');
                setSelectedClinic(null);
                setIsFetchedAll(false);
                setTypeSegement(e.detail.value as 'provider' | 'clinic-admin');
              }}
            >
              <IonSegmentButton value="provider">Individuals</IonSegmentButton>
              <IonSegmentButton value="clinic-admin">Clinics</IonSegmentButton>
            </IonSegment>
          </div>
          <div>
            <IonButton color="dark" fill="outline" onClick={(): void => setView('map')}>
              <IonIcon icon={locationOutline} />
              Location
            </IonButton>
            <IonButton color="dark" fill="outline" onClick={onOpenFilter}>
              <IonIcon icon={options} />
              Filter
            </IonButton>
          </div>
        </div>
      ) : (
        <div className="location-search">
          <IonIcon icon={searchOutline} />
          <PlaceSearchInput
            isCitiesLimited
            value={location.address}
            onChange={(value): void => setLocation(value)}
          />
        </div>
      )}
      {
        view === 'list' && (
          <>
            <IonList>
              {typeSegement === 'provider'
                ? providers
                  .map((provider) => (
                    <UserItem
                      key={provider.id}
                      user={provider}
                      addFavoriteProvider={onAddFavorite}
                      deleteFavoriteProvider={onRemoveFavorite}
                      onClick={() => history.push(`/${user.role}/provider/${provider.id}`)}
                    />
                  )) : null}
              {typeSegement === 'clinic-admin' && !selectedClinic
                ? clinics
                  .map((clinic) => (
                    <UserItem
                      key={clinic.id}
                      user={clinic}
                      addFavoriteProvider={onAddFavorite}
                      deleteFavoriteProvider={onRemoveFavorite}
                      onClick={() => setSelectedClinic(clinic)}
                    />
                  )) : null}
              {typeSegement === 'clinic-admin' && selectedClinic
                ? members
                  .map((member) => (
                    <UserItem
                      key={member.id}
                      user={member}
                      addFavoriteProvider={onAddFavorite}
                      deleteFavoriteProvider={onRemoveFavorite}
                      onClick={() => history.push(`/${user.role}/provider/${member.id}`)}
                    />
                  )) : null}
            </IonList>
            {isFetchedAll ? null : (
              <IonInfiniteScroll
                onIonInfinite={(ev) => {
                  fetchMore();
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
                }}
              >
                <IonInfiniteScrollContent
                  loadingText="Fetching more..."
                  loadingSpinner="circles"
                />
              </IonInfiniteScroll>
            )}
          </>
        )
      }
      {
        view === 'map' && (
          <div className="provider-map">
            <ProviderMapView
              hasSearchbar={false}
              center={{ ...location, zoom: 9 }}
              users={providers}
              onSelectUser={moveToDetails}
            />
          </div>
        )
      }
      <IonModal
        cssClass="filter-modal"
        isOpen={showFilter}
        onDidDismiss={onDismissFilter}
      >
        <div className="modal-header">
          <h2>Filter Providers</h2>
          <IonButton color="favorite" fill="clear" onClick={onClear}>Clear All</IonButton>
        </div>
        <div className="setting">
          <div className="header">Language Skills</div>
          <div className="content">
            {languages.map((item, index) => (
              <div
                key={index}
                className={`ion-activatable toggle${
                  tempSelectedLanguages.includes(item.id) ? ' active' : ''
                }`}
                onClick={(): void => onClickLanguage(item.id)}
              >
                {item.name}
                <IonRippleEffect />
              </div>
            ))}
          </div>
        </div>
        <div className="setting">
          <div className="header">
            Rating
            <div>
              <StarIcon />
              {tempSelectedRating.toFixed(1)}
            </div>
          </div>
          <div className="content">
            <IonRange
              mode="ios"
              min={0}
              max={5}
              step={0.5}
              value={tempSelectedRating}
              onIonChange={({ detail }) => setTempSelectedRating(Number(detail.value))}
            />
          </div>
        </div>
        <div className="btn-apply">
          <IonButton color="favorite" expand="block" onClick={onApplyFilter}>
            Apply
          </IonButton>
        </div>
      </IonModal>

      <IonLoading isOpen={isFetching} />
    </IonContent>
  );
};

ProviderList.propTypes = {
  history: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  languages: PropTypes.array.isRequired,
  specialists: PropTypes.array.isRequired
};

export default connect<RouteComponentProps<MatchParams>, StateProps, {}>({
  mapStateToProps: (state, OwnProps) => ({
    user: state.auth.user,
    languages: state.data.allLanguages,
    specialists: state.data.allSpecialists
  }),
  component: React.memo(ProviderList)
});
