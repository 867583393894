import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import {
  chevronForwardOutline,
  chevronBackOutline,
  arrowBackOutline,
  closeOutline
} from 'ionicons/icons';
import {
  Calendar,
  momentLocalizer
} from 'react-big-calendar';
import moment from 'moment';
import stc from 'string-to-color';
import clsx from 'clsx';

import connect from '../../data/connect';
import { AppointmentType, Booking, User } from '../../models';
import { fetchBookings, fetchBookingsByDate } from '../../data/dataApi';
import AdminBookingItem from '../../components/AdminBookingItem';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarView.scss';

const localizer = momentLocalizer(moment);

const EventWrapper = (props: any) => {
  const {
    style, event, type, onClick
  } = props;

  const elementStyle = style ?? {};
  if (style) {
    Object.keys(elementStyle).forEach((key) => {
      elementStyle[key] = `${elementStyle[key]}%`;
    });
    elementStyle.position = 'absolute';
    elementStyle.backgroundColor = 'red';
    elementStyle.borderRadius = '8px';
  } else {
    elementStyle.backgroundColor = 'red';
    elementStyle.width = '100%';
    elementStyle.borderRadius = '4px';
  }
  elementStyle.border='0.25px solid #FFF';
  elementStyle.backgroundColor = stc(event.title);
  elementStyle.opacity = '0.8';

  const title = `${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')} : ${event.title}`

  return (
    <div title={title} style={elementStyle} className="custom-eventwrapper cursor-pointer" onClick={onClick}>
      <span>{event.title}</span>
      {type === 'time' ? <span className="time">{`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}</span> : null}
    </div>
  );
};

const Toolbar = (props: any) => {
  const {
    label, date, view, views, onView, onNavigate
  } = props;
  const [month, setMonth] = useState('January');
  const mMonth = moment(date).format('MMMM');

  useEffect(() => {
    setMonth(mMonth);
  }, [mMonth]);

  const goToView = (view: any) => {
    onView(view);
  };

  const goToBack = () => {
    onNavigate('PREV');
  };
  const goToNext = () => {
    onNavigate('NEXT');
  };

  const goToToday = () => {
    onNavigate('TODAY');
  };

  return (
    <div className="rbc-toolbar">
      <div className="date-info-view">
        <div className="rbc-btn-group">
          <button type="button" onClick={goToToday}>Today</button>
          <button type="button" className="icon-button" onClick={goToBack}>
            <IonIcon icon={chevronBackOutline} />
          </button>
          <button type="button" className="icon-button" onClick={goToNext}>
            <IonIcon icon={chevronForwardOutline} />
          </button>
        </div>
        <div className="rbc-toolbar-label">
          {view === 'month' ? (
            <span className="rbc-year">{`${month} ${moment(date).format('YYYY')}`}</span>
          ) : (
            label
          )}
        </div>
      </div>
      <div className="rbc-btn-group">
        {views?.map((item: any) => (
          <button
            type="button" 
            onClick={() => goToView(item)}
            key={item}
            className={clsx({ 'rbc-active': view === item })}
          >
            {item.charAt(0).toUpperCase() + item.slice(1)}
          </button>
        ))}
      </div>
    </div>
  );
};

interface StateProps {
  user: User
}

const CalendarView: React.FC<StateProps> = ({ user }) => {
  const [status, setStatus] = useState<'accepted' | 'rejected' | 'cancelled' | 'completed' | 'incomplete' | 'pending'>('accepted');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [bookingsByDate, setBookingsByDate] = useState<Booking[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });
  const [selectedBookingId, setSelectedBookingId] = useState(-1);

  const handleRangeChange = (data: any) => {
    const keys = Object.keys(data);
    let start = '';
    let end = '';
    if (keys.length === 1) {
      start = moment(data[0]).toISOString();
      end = moment(data[0]).add(1, 'days').toISOString();
    } else if (keys.length === 7) {
      start = moment(data[0]).toISOString();
      end = moment(data[6]).add(1, 'days').toISOString();
    } else {
      start = moment(data['start']).toISOString();
      end = moment(data['end']).add(1, 'days').toISOString();
    }
    setDateRange({ start, end });
  };

  const selectedBooking = useMemo(() => {
    if (selectedBookingId > -1) {
      return bookings.find((booking) => booking.id === selectedBookingId);
    }
    return null;
  }, [selectedBookingId, bookings]);

  const events = useMemo(() => bookings
    .filter((booking) => booking.status.includes(status))
    .map((booking) => ({
      id: booking.id,
      title: `${booking.customer.name} - ${booking.provider.name}`,
      start: moment.utc(`${booking.date} ${booking.time}`).local().toDate(),
      end: moment.utc(`${booking.date} ${booking.time}`).add(booking.duration, 'minutes').local().toDate()
    })), [status, bookings]);

  const appointmentType = useMemo(() => {
    if (selectedBooking) {
      switch (selectedBooking.type) {
        case AppointmentType.VIRTUAL:
          return 'Virtual';
        case AppointmentType.PROVIDER_LOCATION:
          return 'Provider Location';
        case AppointmentType.CUSTOMER_LOCATION:
          return 'Customer Location';
        default:
          return 'N|A';
      }
    }
    return 'N|A';
  }, [selectedBooking]);

  useEffect(() => {
    if (selectedDate) {
      setIsFetching(true);
      fetchBookingsByDate(
        user.id,
        moment(selectedDate).utc().format('YYYY-MM-DD HH:mm')
      )
        .then((res: { success: boolean, bookings: Booking[] }) => {
          if (res.success && res.bookings) {
            setBookingsByDate(res.bookings);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    } else {
      const start = moment().startOf('month').toISOString();
      const end = moment().endOf('month').toISOString();
      setBookings([]);
      setDateRange({ start, end });
    }
  }, [selectedDate, user]);

  useEffect(() => {
    const data = {
      status,
      dateRange,
      noPagination: true
    };
    setIsFetching(true);
    fetchBookings(data)
      .then((res: { success: boolean, bookings: Booking[] }) => {
        if (res.success) {
          setBookings([...res.bookings]);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsFetching(false));
  }, [dateRange, status]);

  return (
    <IonContent id="calendar-view" className="page-content ion-padding">
      <div className="header">
        {selectedDate ? (
          <>
            <span className="back-btn" onClick={() => setSelectedDate(null)}>
              <IonIcon color="favorite" icon={arrowBackOutline} size="large" />
            </span>
            <IonListHeader className="page-header">
              {moment(selectedDate).format('MMMM D, YYYY')}
            </IonListHeader>
          </>
        ) : (
          <IonListHeader className="page-header">Calendar View</IonListHeader>
        )}
        <div className="status-selector">
          Appointment Status:
          <IonSelect
            value={status}
            onIonChange={(e): void => {
              setBookings([]);
              setStatus(e.detail.value);
            }}
          >
            <IonSelectOption value="accepted">Accepted</IonSelectOption>
            <IonSelectOption value="rejected">Rejected</IonSelectOption>
            <IonSelectOption value="cancelled">Cancelled</IonSelectOption>
            <IonSelectOption value="completed">Completed</IonSelectOption>
            <IonSelectOption value="incomplete">Incomplete</IonSelectOption>
            <IonSelectOption value="pending">Pending</IonSelectOption>
          </IonSelect>
        </div>
      </div>
      {selectedDate ? (
        <>
          <IonGrid>
            <IonRow>
              {bookingsByDate
                .filter((booking) => booking.status.includes(status))
                .map((booking) => (
                  <IonCol size="4">
                    <AdminBookingItem booking={booking} />
                  </IonCol>
                ))}
            </IonRow>
          </IonGrid>
          <IonLoading isOpen={isFetching} />
        </>
      ) : (
        <div className="calendar-view">
          <Calendar
            localizer={localizer}
            events={events}
            components={{
              toolbar: Toolbar,
              eventWrapper: (props) => <EventWrapper {...props} onClick={() => setSelectedBookingId(props?.event?.id)} />
            }}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%', minHeight: 600 }}
            views={['day', 'week', 'month']}
            onDrillDown={(e) => setSelectedDate(e)}
            onRangeChange={handleRangeChange}
          />
        </div>
      )}

      <IonModal
        cssClass="booking-details-modal"
        isOpen={!!selectedBooking}
        onDidDismiss={() => setSelectedBookingId(-1)}
      >
        <IonLabel>Appointment Details</IonLabel>
        <IonButton color="dark" fill="clear" onClick={() => setSelectedBookingId(-1)}>
          <IonIcon icon={closeOutline} />
        </IonButton>
        {selectedBooking ? (
          <IonList className="booking-details">
            <IonItem lines="none">
              <p slot="start">Appointment type</p>
              <span slot="end">{appointmentType}</span>
            </IonItem>
            <IonItem lines="none">
              <p slot="start">Customer</p>
              <span slot="end">{`${selectedBooking.customer.name} ${selectedBooking.customer.lastName ?? ''}`}</span>
            </IonItem>
            <IonItem lines="none">
              <p slot="start">Provider</p>
              <span slot="end">{`${selectedBooking.provider.name} ${selectedBooking.provider.lastName ?? ''}`}</span>
            </IonItem>
            <IonItem lines="none">
              <p slot="start">Service</p>
              <span slot="end">{`${selectedBooking.service} - ${selectedBooking.duration}mins - $${selectedBooking.price}`}</span>
            </IonItem>
            <IonItem lines="none">
              <p slot="start">Date</p>
              <span slot="end">{moment.utc(`${selectedBooking.date} ${selectedBooking.time}`).local().format('YYYY MMMM DD HH:mm')}</span>
            </IonItem>
          </IonList>
        ) : null}
      </IonModal>
    </IonContent>
  );
};

CalendarView.propTypes = {
  user: PropTypes.any.isRequired
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  component: React.memo(CalendarView)
});
