import React from 'react';
import clsx from 'clsx';
import { LocalVideoTrack, Participant, RemoteVideoTrack } from 'twilio-video';
import { IonIcon } from '@ionic/react';
import { videocam } from 'ionicons/icons';

import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import useIsTrackSwitchedOff from '../../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../../hooks/usePublications/usePublications';
import useTrack from '../../../hooks/useTrack/useTrack';

import './MainParticipantInfo.scss';

interface MainParticipantInfoProps {
  participant: Participant
  children: React.ReactNode
}

export default function MainParticipantInfo({
  participant,
  children
}: MainParticipantInfoProps) {
  const publications = usePublications(participant);
  const videoPublication = publications.find((p) => p.trackName === 'camera');
  const screenSharePublication = publications.find(
    (p) => p.trackName === 'screen'
  );
  const isVideoEnabled = Boolean(videoPublication);

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  return (
    <div
      data-cy-main-participant
      className={clsx('main-participant-info-container', {
        'is-video-switched-Off': isVideoSwitchedOff
      })}
    >
      <div className="info-container">
        <h4 className="identity">
          {participant.identity}
          {!isVideoEnabled && <IonIcon icon={videocam} />}
        </h4>
      </div>
      {isVideoSwitchedOff && <BandwidthWarning />}
      {children}
    </div>
  );
}
