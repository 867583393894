/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonListHeader,
  IonSelect,
  IonSelectOption,
  IonLabel,
} from '@ionic/react';
import moment, { Moment } from 'moment';
import Calendar from 'rc-calendar';
import 'rc-calendar/assets/index.css';

import connect from '../../data/connect';
import {
  fetchTimeslotsByDate,
  createTimeslot,
  enableTimeslot
} from '../../data/dataApi';
import * as selectors from '../../data/selectors';
import { Location, User } from '../../models';
import './ClinicMemberTimeslots.scss';

interface Slot {
  slot: number
  status: string
  type: string
}

interface StateProps {
  member: User | undefined
}

type ClinicMemberTimeslotsProps = RouteComponentProps & StateProps;

const ClinicMemberTimeslots: React.FC<ClinicMemberTimeslotsProps> = ({
  member
}) => {
  const [currentDate, setCurrentDate] = useState<Moment | null>(null);
  const [slots, setSlots] = useState([] as Slot[]);
  const [location, setLocation] = useState<Location | null>(null);

  const onSelectDate = useCallback(
    async (date: Moment) => {
      if (member && location) {
        setCurrentDate(date);
        const response: { success: boolean, slots: Slot[] } = await fetchTimeslotsByDate(
          member.id,
          location.id,
          moment(date.format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm')
        );
        if (response.success) {
          setSlots(response.slots);
        }
      }
    },
    [member, location]
  );

  useEffect(() => {
    if (member?.locations?.length) {
      setLocation(member?.locations[0]);
    }
  }, [member]);

  useEffect(() => {
    if (currentDate) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onSelectDate(currentDate);
    }
  }, [location]);

  const slotToMoment = (slot: number): Moment => moment({
    hour: slot / 4,
    minute: (slot % 4) * 15
  });

  const onDisableTimeslot = (slot: number, type: string): void => {
    if (!member || !currentDate) {
      return;
    }
    createTimeslot({
      user: member.id,
      startDay: currentDate.utc().format('YYYY-MM-DD'),
      location: null,
      start: slotToMoment(slot).utc().format('HH:mm'),
      end: slotToMoment(slot + 1).utc().format('HH:mm'),
      type
    })
      .then((res: { success: boolean }) => {
        if (res.success) {
          setSlots(
            slots.map((item) => (item.slot === slot ? { ...item, status: 'disabled' } : item))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const onEnableTimeslot = (slot: number): void => {
    if (!member || !currentDate) {
      return;
    }
    enableTimeslot(
      member.id,
      currentDate.utc().format('YYYY-MM-DD'),
      slotToMoment(slot).utc().format('HH:mm')
    )
      .then((res: { success: boolean }) => {
        if (res.success) {
          setSlots(
            slots.map((item) => (item.slot === slot ? { ...item, status: 'enabled' } : item))
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const renderSlot = (item: Slot): JSX.Element => {
    if (item.status === 'closed') {
      return (
        <IonButton
          className="round-border"
          fill="outline"
          expand="block"
          // eslint-disable-next-line no-nested-ternary
          color={item.type === 'video-visit' ? 'facebook' : item.type === 'provider-visit' ? 'primary' : 'workshop'}
          disabled
        >
          <div className="slot-button">
            {slotToMoment(item.slot).format('h:mm A')}
          </div>
        </IonButton>
      );
    }
    if (item.status === 'enabled') {
      return (
        <IonButton
          className="round-border"
          fill="solid"
          expand="block"
          // eslint-disable-next-line no-nested-ternary
          color={item.type === 'video-visit' ? 'facebook' : item.type === 'provider-visit' ? 'primary' : 'workshop'}
          onClick={(): void => onDisableTimeslot(item.slot, item.type)}
        >
          {slotToMoment(item.slot).format('h:mm A')}
        </IonButton>
      );
    }
    if (item.status === 'disabled') {
      return (
        <IonButton
          className="round-border"
          fill="outline"
          expand="block"
          // eslint-disable-next-line no-nested-ternary
          color={item.type === 'video-visit' ? 'facebook' : item.type === 'provider-visit' ? 'primary' : 'workshop'}
          onClick={(): void => onEnableTimeslot(item.slot)}
        >
          <div className="slot-button">
            {slotToMoment(item.slot).format('h:mm A')}
          </div>
        </IonButton>
      );
    }
    return <></>;
  };

  return (
    <IonContent id="clinic-member-availability" className="provider-schedule-content page-content ion-padding">
      <IonListHeader className="page-header">Change Time Slots Availability</IonListHeader>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonSelect
              className="select"
              placeholder="Select your location"
              value={location}
              onIonChange={(e): void => setLocation(e.detail.value)}
            >
              {member?.locations.map((loc) => (
                <IonSelectOption key={loc.id} value={loc}>
                  {loc.address}
                </IonSelectOption>
              ))}

            </IonSelect>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonLabel className="sub-title">
            {currentDate ? currentDate.format('MMMM Do, YYYY') : 'Select Date'}
          </IonLabel>
        </IonRow>
        <IonRow>
          <Calendar
            className="calendar"
            showDateInput={false}
            showToday={false}
            onSelect={(date): Promise<void> => onSelectDate(date)}
            disabledDate={(current): boolean => {
              if (member?.limitPeriod) {
                return !(moment(moment().format('MM-DD-YYYY')).isSameOrBefore(current) && moment(moment().add(member.limitPeriod, 'weeks').format('MM-DD-YYYY')).isSameOrAfter(current));
              }
              return moment(moment().format('MM-DD-YYYY')).isSameOrAfter(current);
            }}
          />
        </IonRow>
        <IonRow>
          <IonLabel className="sub-title">
            Select slot
          </IonLabel>
          <div className="slot-legend">
            <div className="slot-legend-option video">
              <span />
              Virtual
            </div>
            <div className="slot-legend-option provider">
              <span />
              Provider Location
            </div>
            <div className="slot-legend-option customer">
              <span />
              Customer Location
            </div>
          </div>
        </IonRow>
        <IonRow>
          {slots.map((item: Slot) => (
            <IonCol key={item.slot} size="3">
              {renderSlot(item)}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

ClinicMemberTimeslots.propTypes = {
  member: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, StateProps, {}>({
  mapStateToProps: (state, OwnProps) => ({
    member: selectors.getMember(state, OwnProps)
  }),
  component: React.memo(ClinicMemberTimeslots)
});
