import { AuthActions } from './auth.actions';
import initialAuthState, { AuthState } from './auth.state';

export default function authReducer(
  state: AuthState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case '@auth/set-loading':
      return { ...state, loading: action.isLoading };
    case '@auth/set-error-message':
      return { ...state, errorMessage: action.message };
    case '@auth/set-user':
      return { ...state, user: action.data };
    case '@auth/reset':
      return initialAuthState;
    default:
      return state;
  }
}
