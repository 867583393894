import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CardIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M15.4167 0H4.58333C2.05583 0 0 2.05583 0 4.58333V15.4167C0 17.9442 2.05583 20 4.58333 20H15.4167C17.9442 20 20 17.9442 20 15.4167V4.58333C20 2.05583 17.9442 0 15.4167 0ZM17.5 15.4167C17.5 16.565 16.565 17.5 15.4167 17.5H4.58333C3.435 17.5 2.5 16.565 2.5 15.4167V4.58333C2.5 3.435 3.435 2.5 4.58333 2.5H15.4167C16.565 2.5 17.5 3.435 17.5 4.58333V15.4167ZM6.66667 5.41667C6.66667 6.10667 6.10667 6.66667 5.41667 6.66667C4.72667 6.66667 4.16667 6.10667 4.16667 5.41667C4.16667 4.72667 4.72667 4.16667 5.41667 4.16667C6.10667 4.16667 6.66667 4.72667 6.66667 5.41667ZM10.8333 5.41667C10.8333 6.10667 10.2733 6.66667 9.58333 6.66667C8.89333 6.66667 8.33333 6.10667 8.33333 5.41667C8.33333 4.72667 8.89333 4.16667 9.58333 4.16667C10.2733 4.16667 10.8333 4.72667 10.8333 5.41667Z" fill={color} />
  </svg>
);

export default CardIcon;
