import React from 'react';

import { IonIcon, IonFabButton } from '@ionic/react';
import { mic, micOff } from 'ionicons/icons';

import useLocalAudioToggle from '../../../../hooks/useLocalAudioToggle/useLocalAudioToggle';

export default function ToggleAudioButton(props: { disabled?: boolean }) {
  const [hasMicrophone, isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  return (
    <IonFabButton
      onClick={toggleAudioEnabled}
      disabled={props.disabled || !hasMicrophone}
      data-cy-audio-toggle
    >
      {isAudioEnabled ? <IonIcon icon={mic} /> : <IonIcon icon={micOff} />}
    </IonFabButton>
  );
}
