import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import ToggleChatButton from './ToggleChatButton/ToggleChatButton';

import useIsUserActive from './useIsUserActive/useIsUserActive';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton';
import { Booking } from '../../../models';

import './Controls.scss';

interface ControlsProps {
  booking: Booking
  currentDate: Date
}

const Controls: React.FC<ControlsProps> = ({ booking, currentDate }) => {
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const isUserActive = useIsUserActive();
  const showControls = isUserActive || roomState === 'disconnected';
  const { isChatConnected } = useChatContext();

  return (
    <div className={clsx('controls-container', { showControls })}>
      <ToggleAudioButton disabled={isReconnecting} />
      <ToggleVideoButton disabled={isReconnecting} />
      {roomState !== 'disconnected' && (
        <>
          <ToggleScreenShareButton disabled={isReconnecting} />
          <ToggleChatButton disabled={isReconnecting || !isChatConnected} />
          <EndCallButton booking={booking} currentDate={currentDate} />
        </>
      )}
    </div>
  );
};

Controls.propTypes = {
  booking: PropTypes.any.isRequired,
  currentDate: PropTypes.any.isRequired
};

export default React.memo(Controls);
