import React from 'react';
import { IonButton, IonIcon, IonLabel } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

import useChatContext from '../../../../hooks/useChatContext/useChatContext';

const ChatWindowHeader = () => {
  const { setIsChatWindowOpen } = useChatContext();

  return (
    <div className="chat-window-header">
      <IonLabel>Chat</IonLabel>
      <IonButton color="dark" fill="clear" onClick={() => setIsChatWindowOpen(false)}>
        <IonIcon icon={closeOutline} />
      </IonButton>
    </div>
  );
};

export default ChatWindowHeader;
