import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MarketingIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M5.95833 13.75H1.375C0.615083 13.75 0 13.1349 0 12.375C0 11.6151 0.615083 11 1.375 11H5.22225L6.64767 8.86233C6.919 8.45442 7.37917 8.22158 7.87692 8.25275C8.36642 8.283 8.80183 8.57175 9.02183 9.00992L10.7122 12.3906L11.231 11.6123C11.4858 11.2301 11.9158 11 12.375 11H14.2083C14.9683 11 15.5833 11.6151 15.5833 12.375C15.5833 13.1349 14.9683 13.75 14.2083 13.75H13.1111L11.6857 15.8877C11.4299 16.2718 10.9991 16.5 10.5417 16.5C10.0109 16.5 9.53058 16.1782 9.3115 15.7401L7.62117 12.3594L7.10233 13.1377C6.8475 13.5199 6.41758 13.75 5.95833 13.75ZM18.6899 3.91233L16.2543 1.47675C15.3019 0.524333 14.0369 0 12.6894 0H6.875C4.09475 0 1.83333 2.26142 1.83333 5.04167V7.79167C1.83333 8.55158 2.44842 9.16667 3.20833 9.16667C3.96825 9.16667 4.58333 8.55158 4.58333 7.79167V5.04167C4.58333 3.7785 5.61183 2.75 6.875 2.75H11.9167V6.41667C11.9167 7.42958 12.7371 8.25 13.75 8.25H17.4167V16.9583C17.4167 18.2215 16.3882 19.25 15.125 19.25H6.875C5.61183 19.25 4.58333 18.2215 4.58333 16.9583C4.58333 16.1984 3.96825 15.5833 3.20833 15.5833C2.44842 15.5833 1.83333 16.1984 1.83333 16.9583C1.83333 19.7386 4.09475 22 6.875 22H15.125C17.9053 22 20.1667 19.7386 20.1667 16.9583V7.47725C20.1667 6.13067 19.6423 4.86475 18.6899 3.91233Z" fill={color} />
  </svg>
);

export default MarketingIcon;
