import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TrashIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_720_8826)">
      <path d="M3.3335 5.83398H16.6668" stroke="#EB4D4D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.3335 9.16602V14.166" stroke="#EB4D4D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.6665 9.16602V14.166" stroke="#EB4D4D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.1665 5.83398L4.99984 15.834C4.99984 16.276 5.17543 16.6999 5.48799 17.0125C5.80055 17.3251 6.22448 17.5007 6.6665 17.5007H13.3332C13.7752 17.5007 14.1991 17.3251 14.5117 17.0125C14.8242 16.6999 14.9998 16.276 14.9998 15.834L15.8332 5.83398" stroke="#EB4D4D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 5.83333V3.33333C7.5 3.11232 7.5878 2.90036 7.74408 2.74408C7.90036 2.5878 8.11232 2.5 8.33333 2.5H11.6667C11.8877 2.5 12.0996 2.5878 12.2559 2.74408C12.4122 2.90036 12.5 3.11232 12.5 3.33333V5.83333" stroke="#EB4D4D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_720_8826">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TrashIcon;
