import React, {
  createContext, useCallback, useEffect, useRef, useState
} from 'react';
import { Client, Conversation, Message } from '@twilio/conversations';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { leaveConversation } from '../../../data/dataApi';
import useRoomState from '../../../hooks/useRoomState/useRoomState';

type ChatContextType = {
  isChatWindowOpen: boolean;
  isChatConnected: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  conversation: Conversation | null;
  closeConversation: (removeChannel: boolean) => void;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider: React.FC = ({ children }: any) => {
  const { room, onError } = useVideoContext();
  const roomState = useRoomState();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<Client | null>(null);
  const [isChatConnected, setIsChatConnected] = useState(false);

  const connect = useCallback(
    (token: string) => {
      const client = new Client(token);

      const handleClientInitialized = () => {
        // @ts-ignore
        window.chatClient = client;
        setChatClient(client);
      };

      client.on('stateChanged', handleClientInitialized);

      return () => {
        client.off('stateChanged', handleClientInitialized);
      };
    },
    [onError]
  );

  const closeConversation = useCallback((removeChannel) => {
    if (conversation) {
      if (removeChannel) {
        leaveConversation({ bookingId: room.name, conversationSid: conversation.sid, removeConversation: true });
      } else {
        leaveConversation({ bookingId: room.name, conversationSid: conversation.sid, removeConversation: false });
      }
    }
  }, [conversation]);

  useEffect(() => {
    if (conversation) {
      setIsChatConnected(true);
      setIsChatWindowOpen(true);
      const handleMessageAdded = (message: Message) => setMessages((oldMessages) => [...oldMessages, message]);
      conversation.getMessages().then((newMessages) => setMessages(newMessages.items));
      conversation.on('messageAdded', handleMessageAdded);
      return () => {
        conversation.off('messageAdded', handleMessageAdded);
      };
    }
  }, [conversation]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  useEffect(() => {
    if (room && chatClient) {
      chatClient
        .getConversationByUniqueName(room.sid)
        .then((newConversation) => {
          //@ts-ignore
          window.chatConversation = newConversation;
          setConversation(newConversation);
        })
        .catch((e) => console.error(e));
    }
  }, [room, chatClient]);

  useEffect(() => {
    if (roomState === 'disconnected') {
      setIsChatConnected(false);
    }
  }, [roomState]);

  return (
    <ChatContext.Provider
      value={{
        isChatWindowOpen,
        isChatConnected,
        hasUnreadMessages,
        messages,
        conversation,
        closeConversation,
        setIsChatWindowOpen,
        connect
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
