import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ExportIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_720_11342)">
      <path d="M11.8667 14.14C12.1467 14.62 11.98 15.2267 11.5 15.5067C10.9467 15.8267 10.3133 16 9.66667 16H3.66667C1.64667 16 0 14.3533 0 12.3333V3.66667C0 1.64667 1.64667 0 3.66667 0H7.89333C8.86 0 9.80667 0.393333 10.4867 1.07333L12.26 2.84667C12.8067 3.39333 13.16 4.08 13.2867 4.84C13.38 5.38667 13.0067 5.91333 12.4667 6.00667C12.4267 6.01333 8.67333 6.00667 8.67333 6.00667C7.94 6.00667 7.34 5.40667 7.34 4.67333V2H3.66667C2.74667 2 2 2.74667 2 3.66667V12.3333C2 13.2533 2.74667 14 3.66667 14H9.66667C9.96 14 10.2467 13.92 10.5 13.78C10.9733 13.5067 11.5867 13.6667 11.8667 14.1467V14.14ZM15.8067 9.86L13.8067 7.75333C13.3867 7.33333 12.6667 7.63333 12.6667 8.22667V9.33333H9C8.44667 9.33333 8 9.78 8 10.3333C8 10.8867 8.44667 11.3333 9 11.3333H12.6667V12.44C12.6667 13.0333 13.3867 13.3333 13.8067 12.9133L15.8067 10.8067C16.0667 10.5467 16.0667 10.1267 15.8067 9.86667V9.86Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_720_11342">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ExportIcon;
