import React, { useState } from 'react';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonListHeader,
  IonSegment,
  IonSegmentButton
} from '@ionic/react';
import { calendarOutline, removeOutline } from 'ionicons/icons';
import moment from 'moment';
import { DateRange as DateRangePicker, Range } from 'react-date-range';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import clsx from 'clsx';

import CustomersView from './CustomersView';
import ProvidersView from './ProvidersView';
import BookingsView from './BookingsView';
import ClinicsView from './ClinicsView';

import './AdminReports.scss';

const AdminReports = () => {
  const [segment, setSegment] = useState<'customer' | 'provider' | 'booking' | 'clinic'>('customer');
  const [dateRange, setDateRange] = useState<Range | null>(null);
  const [tempDateRange, setTempDateRange] = useState<Range | null>(null);

  const [showCalendarPopover, setShowCalendarPopover] = useState(false);

  const selectPeriod = (type: string) => {
    if (type === 'today') {
      setTempDateRange({
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        key: 'selection'
      });
    }
    if (type === 'week') {
      setTempDateRange({
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastMonth') {
      setTempDateRange({
        startDate: moment().subtract(1, 'months').startOf('month').toDate(),
        endDate: moment().subtract(1, 'months').endOf('month').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastQuarter') {
      setTempDateRange({
        startDate: moment().subtract(3, 'months').startOf('quarter').toDate(),
        endDate: moment().subtract(3, 'months').endOf('quarter').toDate(),
        key: 'selection'
      });
    }
  };

  return (
    <IonContent id="admin-report" className="page-content ion-padding">
      <div className="content">
        <IonListHeader className="page-header">Reports</IonListHeader>
        <div className="report-type">
          <IonSegment
            mode="ios"
            value={segment}
            onIonChange={(e) => setSegment(e.detail.value as 'customer' | 'provider' | 'booking' | 'clinic')}
          >
            <IonSegmentButton value="customer">Customers</IonSegmentButton>
            <IonSegmentButton value="provider">Providers</IonSegmentButton>
            <IonSegmentButton value="clinic">Clinics</IonSegmentButton>
            <IonSegmentButton value="booking">Bookings</IonSegmentButton>
          </IonSegment>
          <Popover
            isOpen={showCalendarPopover}
            positions={['bottom', 'top', 'left', 'right']}
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor="#daf3ef"
                arrowSize={10}
                className="popover-arrow-container"
                arrowClassName="popover-arrow"
              >
                <div className="popover-content calendar">
                  <div className="panel">
                    <div
                      className={clsx('option', { selected: !tempDateRange })}
                      onClick={() => setTempDateRange(null)}
                    >
                      All periods
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment(tempDateRange.endDate).format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('today')}
                    >
                      Today
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().startOf('week').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().endOf('week').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('week')}
                    >
                      This week
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('lastMonth')}
                    >
                      Last month
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').startOf('quarter').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').endOf('quarter').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('lastQuarter')}
                    >
                      Last quarter
                    </div>
                    <IonButton
                      color="favorite"
                      onClick={() => {
                        setDateRange(tempDateRange);
                        setShowCalendarPopover(false);
                      }}
                    >
                      Apply
                    </IonButton>
                  </div>
                  <div className="calendar">
                    <DateRangePicker
                      editableDateInputs
                      onChange={(item) => {
                        setTempDateRange({
                          startDate: moment(item.selection.startDate).toDate(),
                          endDate: moment(item.selection.endDate).add(1, 'days').subtract(1, 'milliseconds').toDate(),
                          key: 'selection'
                        });
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={tempDateRange ? [tempDateRange] : [
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: 'selection'
                        },
                      ]}
                      rangeColors={['#000']}
                    />
                  </div>
                </div>
              </ArrowContainer>
            )}
            onClickOutside={() => setShowCalendarPopover(false)}
          >
            <IonItem lines="none" className="date-range" onClick={() => setShowCalendarPopover(true)}>
              <div>
                {dateRange ? moment(dateRange.startDate).format('DD MMMM, YYYY') : 'Start Date'}
              </div>
              <IonIcon color="dark" icon={removeOutline} />
              <div>
                {dateRange ? moment(dateRange.endDate).format('DD MMMM, YYYY') : 'End Date'}
              </div>
              <IonIcon slot="end" icon={calendarOutline} />
            </IonItem>
          </Popover>
        </div>
        {segment === 'customer' ? <CustomersView dateRange={dateRange} /> : null}
        {segment === 'provider' ? <ProvidersView dateRange={dateRange} /> : null}
        {segment === 'clinic' ? <ClinicsView dateRange={dateRange} /> : null}
        {segment === 'booking' ? <BookingsView dateRange={dateRange} /> : null}
      </div>
    </IonContent>
  );
};

export default React.memo(AdminReports);
