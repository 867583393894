import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonList,
  IonSelect,
  IonSelectOption,
  IonListHeader,
  IonLabel,
  IonLoading
} from '@ionic/react';

import connect from '../../data/connect';
import {
  dispatchCreateTimeslot,
  dispatchDeleteTimeslot,
  dispatchUpdateTimeslot
} from '../../data/store/data/data.actions';
import { dispatchUpdateUser } from '../../data/store/auth/auth.actions';
import { fetchUser, updateUser } from '../../data/dataApi';
import WeekdayItem from '../../components/WeekdayItem';
import { Timeslot, Location, User, AppointmentType } from '../../models';
import { localToUtc, getLocalTimeSlots } from '../../util/slots';

import './BusinessHour.scss';

interface MatchProps {
  id: string
}

interface StateProps {
  user: User
  slots: Timeslot[]
  locations: Location[]
}

interface DispatchProps {
  createTimeslot: typeof dispatchCreateTimeslot
  deleteTimeslot: typeof dispatchDeleteTimeslot
  updateTimeslot: typeof dispatchUpdateTimeslot
  updateStoredUser: typeof dispatchUpdateUser
}

type BusinessHourProps = RouteComponentProps<MatchProps> &
StateProps &
DispatchProps;

const BusinessHour: React.FC<BusinessHourProps> = ({
  match,
  user,
  slots,
  locations,
  createTimeslot,
  deleteTimeslot,
  updateTimeslot,
  updateStoredUser
}) => {
  const workdays: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const [location, setLocation] = useState({} as Location);
  const [limitPeriod, setLimitPeriod] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (locations.length) {
      setLocation(locations[0]);
    }
  }, [locations]);

  const add = (day: string, start: string, type: string): void => {
    const startHour = Number(start.split(':')[0]);
    const startMins = Number(start.split(':')[1]);
    const startUtc = localToUtc(startHour, startMins);
    const endUtc = localToUtc(startHour + 1, 0);
    const startDayIndex = workdays.indexOf(day) + Number(startUtc[2]);
    const endDayIndex = workdays.indexOf(day) + Number(endUtc[2]);

    createTimeslot({
      user: match.params.id,
      location: type === AppointmentType.PROVIDER_LOCATION ? location.id : null,
      startDay: workdays[startDayIndex],
      start: `${startUtc[0]}:${startUtc[1]}`,
      endDay: workdays[endDayIndex],
      end: `${endUtc[0]}:${endUtc[1]}`,
      type
    });
  };

  const remove = (id: number): void => {
    deleteTimeslot(id);
  };

  const onUpdateLimitPeriod = (value: number) => {
    setIsUpdating(true);
    updateUser(Number(match.params.id), { limitPeriod: value })
      .then((res: { success: boolean, user: User }) => {
        if (res.success) {
          if (user.id === Number(match.params.id)) {
            updateStoredUser(res.user);
          }
          setLimitPeriod(value);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsUpdating(false));
  };

  const update = useCallback((id: number, data: any): void => {
    updateTimeslot(id, data);
  }, [updateTimeslot]);

  useEffect(() => {
    const getCurrentLimitPeriod = (id: number) => {
      setIsUpdating(true);
      fetchUser(id)
        .then((res: { success: boolean, user: User }) => {
          if (res.success) {
            const { limitPeriod: initLimitPeriod } = res.user;
            setLimitPeriod(initLimitPeriod);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setIsUpdating(false));
    };

    if (match.params.id) {
      getCurrentLimitPeriod(Number(match.params.id));
    }
  }, [match.params.id]);

  return (
    <IonContent id="business-hour" className="page-content ion-padding">
      <IonListHeader className="page-header">Business Hours</IonListHeader>
      <br />
      <IonLabel>Select location</IonLabel>
      <IonSelect
        className="select"
        placeholder="Select your location"
        value={location}
        disabled={!locations.length}
        onIonChange={(e): void => setLocation(e.detail.value)}
      >
        {locations.map((item) => (
          <IonSelectOption key={item.id} value={item}>
            {item.address}
          </IonSelectOption>
        ))}
      </IonSelect>
      <IonLabel>Limitation period</IonLabel>
      <IonSelect
        className="select"
        value={limitPeriod}
        onIonChange={(e): void => onUpdateLimitPeriod(Number(e.detail.value ?? 0))}
      >
        <IonSelectOption value={0}>Unlimited</IonSelectOption>
        <IonSelectOption value={2}>2 Weeks</IonSelectOption>
        <IonSelectOption value={4}>1 Month</IonSelectOption>
        <IonSelectOption value={8}>2 Months</IonSelectOption>
        <IonSelectOption value={12}>3 Months</IonSelectOption>
        <IonSelectOption value={24}>6 Months</IonSelectOption>
      </IonSelect>
      <IonLabel>Date</IonLabel>
      <IonList>
        {workdays.map((day) => (
          <WeekdayItem
            key={day}
            type={day}
            locationId={location.id}
            slots={getLocalTimeSlots(slots).filter(
              (item: Timeslot) => ((item.startDay === item.endDay && item.startDay === day)
                || (item.startDay !== item.endDay && (item.startDay === day || (item.endDay === day && Number(item.end.split(':')[0]) !== 0))))
            )}
            add={add}
            remove={remove}
            update={update}
          />
        ))}
      </IonList>

      <IonLoading isOpen={isUpdating} />
    </IonContent>
  );
};

BusinessHour.propTypes = {
  match: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  slots: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  createTimeslot: PropTypes.func.isRequired,
  deleteTimeslot: PropTypes.func.isRequired,
  updateTimeslot: PropTypes.func.isRequired,
  updateStoredUser: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user,
    slots: state.data.timeslots,
    locations: state.data.locations
  }),
  mapDispatchToProps: {
    createTimeslot: dispatchCreateTimeslot,
    deleteTimeslot: dispatchDeleteTimeslot,
    updateTimeslot: dispatchUpdateTimeslot,
    updateStoredUser: dispatchUpdateUser
  },
  component: React.memo(BusinessHour)
});
