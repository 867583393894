import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IonCol } from '@ionic/react';

import { Transfer, UserRole } from '../models';

interface TransferItemProps {
  index: number
  transfer: Transfer
  role: UserRole
}

const TransferItem: React.FC<TransferItemProps> = ({ index, transfer, role }) => (
  <>
    <IonCol className="sm">
      {index}
    </IonCol>
    <IonCol className="empty-cell sm" />
    <IonCol>Payout</IonCol>

    {/* date column */}
    <IonCol>
      {moment(transfer.createdAt).local().format('YYYY-MM-DD hh:mm A')}
    </IonCol>

    {/* service column */}
    <IonCol className="empty-cell" />

    {/* provider amount column */}
    <IonCol className="charged">
      {role === UserRole.ADMIN || role === UserRole.REVENUE_ANALYST || role === UserRole.HELPDESK ? '' : `$${transfer.amount}`}
    </IonCol>

    {/* customer charged amount column */}
    <IonCol className="empty-cell" />

    {/* refunded amount column */}
    <IonCol className="empty-cell" />

    {/* platform fee column */}
    <IonCol className="fee">
      {role === UserRole.ADMIN || role === UserRole.REVENUE_ANALYST || role === UserRole.HELPDESK ? `$${transfer.amount}` : ''}
    </IonCol>

    {/* stripe fee column */}
    <IonCol className="empty-cell" />
    {/* twilio fee column */}
    <IonCol className="empty-cell" />

    {/* provider info column */}
    {role === UserRole.CLINIC_ADMIN || role === UserRole.ADMIN || role === UserRole.REVENUE_ANALYST || role === UserRole.HELPDESK ? <IonCol className="empty-cell" /> : null}

    {/* reason and status column */}
    <IonCol className="empty-cell" />
    <IonCol className="empty-cell" />
    <IonCol className="empty-cell" />
  </>
);

TransferItem.propTypes = {
  index: PropTypes.number.isRequired,
  transfer: PropTypes.any.isRequired,
  role: PropTypes.any.isRequired
};

export default TransferItem;
