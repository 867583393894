import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AchiveIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_720_8813)">
      <path d="M2.5 5.00065C2.5 4.55862 2.67559 4.1347 2.98816 3.82214C3.30072 3.50958 3.72464 3.33398 4.16667 3.33398H15.8333C16.2754 3.33398 16.6993 3.50958 17.0118 3.82214C17.3244 4.1347 17.5 4.55862 17.5 5.00065C17.5 5.44268 17.3244 5.8666 17.0118 6.17916C16.6993 6.49172 16.2754 6.66732 15.8333 6.66732H4.16667C3.72464 6.66732 3.30072 6.49172 2.98816 6.17916C2.67559 5.8666 2.5 5.44268 2.5 5.00065Z" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.1665 6.66602V14.9993C4.1665 15.4414 4.3421 15.8653 4.65466 16.1779C4.96722 16.4904 5.39114 16.666 5.83317 16.666H14.1665C14.6085 16.666 15.0325 16.4904 15.345 16.1779C15.6576 15.8653 15.8332 15.4414 15.8332 14.9993V6.66602" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.3335 10H11.6668" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_720_8813">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AchiveIcon;
