import moment from 'moment';
import URI from 'urijs';

const getDatetimeFormat = (datetime: any, format: string) => moment(datetime).utcOffset(0).format(format);

export const googleCalendarLink = (
  title: any,
  desc: any,
  locat: any,
  start: any,
  end: any
): string => {
  const uri = new URI('https://www.google.com/calendar/render');
  uri.query({
    action: 'TEMPLATE',
    text: title,
    details: desc,
    location: locat,
    dates: `${getDatetimeFormat(
      start,
      'YYYYMMDDTHHmmss[Z]'
    )}/${getDatetimeFormat(end, 'YYYYMMDDTHHmmss[Z]')}`
  });

  return uri.toString();
};

export const outlookCalendarLink = (
  title: any,
  desc: any,
  locat: any,
  start: any,
  end: any
): string => {
  const uri = new URI('https://outlook.live.com/calendar/0/deeplink/compose');
  uri.query({
    rru: 'addevent',
    path: '/calendar/action/compose',
    subject: title,
    body: desc,
    location: locat,
    startdt: getDatetimeFormat(start, 'YYYY-MM-DDTHH:mm:SS[Z]'),
    enddt: getDatetimeFormat(end, 'YYYY-MM-DDTHH:mm:SS[Z]')
  });
  return uri.toString();
};
