import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel
} from '@ionic/react';
import { alert, people, cash } from 'ionicons/icons';

import connect from '../../data/connect';
import {
  dispatchFetchBookings,
  dispatchFetchLanguages,
  dispatchFetchSpecialists,
  dispatchFetchClinicMembers,
  dispatchGetAllNotification,
  dispatchFetchProviderTypes
} from '../../data/store/data/data.actions';
import { getUnreadCount } from '../../data/selectors';
import { socket } from '../../data/dataApi';
import { User } from '../../models';
import useGetView from '../../hooks/useGetView/useGetView';
import Layout from '../../components/Layout';
import BusinessHour from '../BusinessHour';
import PaymentInformation from '../PaymentInformation';
import Notifications from '../Notifications';
import Wallet from '../Wallet';
import Bookings from '../Bookings';
import CustomerRating from '../CustomerRating';
import ClinicMembers from '../ClinicMembers';
import ClinicMemberDetail from '../ClinicMemberDetail';
import ClinicMemberProfile from '../ClinicMemberProfile';
import ClinicAddMember from '../ClinicAddMember';
import ClinicMemberTimeslots from '../ClinicMemberTimeslots';
import ClinicMemberAccountSetting from '../ClinicMemberAccountSetting';
import ClinicAccount from '../ClinicAccount';
import AccountSetting from '../AccountSetting';
import ClinicAdminProfile from '../ClinicAdminProfile';
import ClinicTimeslots from '../ClinicTimeslots';
import ClinicReport from '../ClinicReport';
import UserReport from '../UserReport';
import BookingReport from '../BookingReport';
import Subscription from '../Subscription';

import './ClinicAdminView.scss';

interface StateProps {
  user: User
  unreadCount: number
}

interface DispatchProps {
  fetchBookings: typeof dispatchFetchBookings
  fetchLanguages: typeof dispatchFetchLanguages
  fetchSpecialists: typeof dispatchFetchSpecialists
  fetchClinicMembers: typeof dispatchFetchClinicMembers
  getAllNotification: typeof dispatchGetAllNotification
  fetchProviderTypes: typeof dispatchFetchProviderTypes
}

type ClinicAdminViewProps = StateProps & DispatchProps;

const ClinicAdminView: React.FC<ClinicAdminViewProps> = ({
  user,
  unreadCount,
  fetchBookings,
  fetchLanguages,
  fetchSpecialists,
  fetchProviderTypes,
  fetchClinicMembers,
  getAllNotification
}) => {
  const [tab, setTab] = useState(0);
  const { isMobile } = useGetView();

  useEffect(() => {
    socket.on('new-message', (e: any) => {
      fetchBookings();
      getAllNotification();
    });
  }, []);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    fetchSpecialists();
  }, [fetchSpecialists]);

  useEffect(() => {
    fetchClinicMembers(user.id);
  }, [user, fetchClinicMembers]);

  useEffect(() => {
    fetchProviderTypes();
  }, [fetchProviderTypes]);

  const renderNotificationIcon = (): JSX.Element => {
    if (tab === 3) {
      return <IonIcon icon={alert} color="primary" />;
    }
    if (unreadCount) {
      return <IonIcon icon={alert} color="danger" />;
    }
    return <IonIcon icon={alert} color="medium" />;
  };

  const renderRoutes = () => (
    <IonRouterOutlet>
      <Layout>
        <Route path="/clinic-admin" render={() => <Redirect to="/clinic-admin/dashboard" />} exact />
        <Route path="/clinic-admin/dashboard" component={Bookings} exact />
        <Route
          path="/clinic-admin/availability"
          component={ClinicTimeslots}
          exact
        />
        <Route
          path="/clinic-admin/payment"
          component={PaymentInformation}
          exact
        />
        <Route
          path="/clinic-admin/notification"
          component={Notifications}
          exact
        />
        <Route
          path="/clinic-admin/profile"
          component={ClinicAdminProfile}
          exact
        />
        <Route path="/clinic-admin/wallet" component={Wallet} exact />
        <Route
          path="/clinic-admin/new-payment-source"
          component={ClinicAccount}
          exact
        />
        <Route
          path="/clinic-admin/add-member"
          component={ClinicAddMember}
          exact
        />
        <Route path="/clinic-admin/members" component={ClinicMembers} exact />
        <Route
          path="/clinic-admin/member/:id"
          component={ClinicMemberDetail}
          exact
        />
        <Route
          path="/clinic-admin/member/:id/business-hour"
          component={BusinessHour}
          exact
        />
        <Route
          path="/clinic-admin/member/:id/profile"
          component={ClinicMemberProfile}
          exact
        />
        <Route
          path="/clinic-admin/member/:id/timeslot"
          component={ClinicMemberTimeslots}
          exact
        />
        <Route
          path="/clinic-admin/member/:id/account-setting"
          component={ClinicMemberAccountSetting}
          exact
        />
        <Route
          path="/clinic-admin/rate-booking/:id"
          component={CustomerRating}
          exact
        />
        <Route
          path="/clinic-admin/report"
          component={ClinicReport}
          exact
        />
        <Route
          path="/clinic-admin/report/user/:id"
          component={UserReport}
          exact
        />
        <Route
          path="/clinic-admin/report/booking/:id"
          component={BookingReport}
          exact
        />
        <Route path="/clinic-admin/account" component={AccountSetting} exact />
        <Route path="/clinic-admin/subscribe" component={Subscription} exact />
      </Layout>
    </IonRouterOutlet>
  );

  if (!isMobile) {
    return renderRoutes();
  }

  return (
    <IonTabs>
      {renderRoutes()}
      <IonTabBar slot="bottom">
        <IonTabButton
          tab="dashboard"
          href="/clinic-admin/dashboard"
          onClick={(): void => setTab(0)}
        >
          <IonIcon src="assets/img/icon/dashboard.svg" />
          <IonLabel>Dashboard</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="availability"
          href="/clinic-admin/members"
          onClick={(): void => setTab(1)}
        >
          <IonIcon icon={people} />
          <IonLabel>Members</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="payments"
          href="/clinic-admin/payment"
          onClick={(): void => setTab(2)}
        >
          <IonIcon icon={cash} />
          <IonLabel>Payments</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="notifications"
          href="/clinic-admin/notification"
          onClick={(): void => setTab(3)}
        >
          {renderNotificationIcon()}
          <IonLabel>Notifications</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

ClinicAdminView.propTypes = {
  user: PropTypes.any.isRequired,
  unreadCount: PropTypes.number.isRequired,
  fetchBookings: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  fetchSpecialists: PropTypes.func.isRequired,
  fetchClinicMembers: PropTypes.func.isRequired,
  fetchProviderTypes: PropTypes.func.isRequired,
  getAllNotification: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user,
    unreadCount: getUnreadCount(state)
  }),
  mapDispatchToProps: {
    fetchBookings: dispatchFetchBookings,
    fetchLanguages: dispatchFetchLanguages,
    fetchSpecialists: dispatchFetchSpecialists,
    fetchProviderTypes: dispatchFetchProviderTypes,
    fetchClinicMembers: dispatchFetchClinicMembers,
    getAllNotification: dispatchGetAllNotification
  },
  component: React.memo(ClinicAdminView)
});
