import {
  User,
  Booking,
  Currency,
  Message,
  Language,
  Specialist,
  ProviderType,
  Category,
  Timeslot,
  Location,
  Request,
  SocialMedia,
  Role
} from '../../../models';

export interface DataState {
  loading: boolean
  bookings: Booking[]
  notifications: Message[],
  allLanguages: Language[]
  allSpecialists: Specialist[]
  providerTypes: ProviderType[]
  categories: Category[]

  customers: User[]
  providers: User[]
  favoriteProviders: User[]
  clinics: User[]
  members: User[]
  timeslots: Timeslot[]
  locations: Location[]
  requests: Request[]
  currencies: Currency[]
  roles: Role[]
  socialMediaTypes: SocialMedia[]

  isFetchedAllCustomers: boolean
  isFetchedAllProviders: boolean
  isFetchedAllClinics: boolean
}

const initialDataState: DataState = {
  loading: false,
  bookings: [],
  notifications: [],
  allLanguages: [],
  allSpecialists: [],
  providerTypes: [],
  categories: [],

  customers: [],
  providers: [],
  favoriteProviders: [],
  clinics: [],
  members: [],
  timeslots: [],
  locations: [],
  requests: [],
  currencies: [],
  roles: [],
  socialMediaTypes: [],

  isFetchedAllCustomers: false,
  isFetchedAllProviders: false,
  isFetchedAllClinics: false
};

export default initialDataState;
