interface Dictionary {
  [id: string]: string
}

export enum BookingStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
  REJECTED_CUSTOMER = 'rejected-customer',
  REJECTED_PROVIDER = 'rejected-provider',
  INCOMPLETE = 'incomplete',
  CANCELLED_CUSTOMER = 'cancelled-customer',
  CANCELLED_PROVIDER = 'cancelled-provider'
}

export enum UserStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  BLOCKED = 'bloked',
  INACTIVE = 'inactive',
  ARCHIVED = 'archived'
}

export enum CampaignStatus {
  NEW = 'new',
  SCHEDULED = 'scheduled',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed'
}

export enum MailingListStatus {
  NEW = 'new',
  SENT = 'sent'
}

export enum VerificationStatus {
  PENDING = 'pending',
  REJECTED = 'rejected',
  VERIFIED = 'verified'
}

export enum UserRole {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
  PROVIDER = 'provider',
  CLINIC_ADMIN = 'clinic-admin',
  CLINIC_MEMBER = 'clinic-member',
  MARKETING_MEMBER = 'marketing-member',
  REVENUE_ANALYST = 'revenue-analyst',
  HELPDESK = 'helpdesk'
}

export const TransferStatus = {
  PENDING: 'pending',
  SUCCESS: 'transfer-success'
};

export const ChargeStatus = {
  HOLD: 'hold',
  CHARGED: 'charged',
  RELEASED: 'released',
  REFUNDED: 'refunded',
  PAYOUT: 'payout'
};

export const AppointmentType = {
  VIRTUAL: 'video-visit',
  PROVIDER_LOCATION: 'provider-visit',
  CUSTOMER_LOCATION: 'customer-visit'
};

export const BookingStatusLabels: Dictionary = {
  completed: 'Completed',
  pending: 'Pending',
  accepted: 'Accepted',
  rejected: 'Rejected',
  incomplete: 'Incomplete',
  'cancelled-customer': 'Cancelled by customer',
  'cancelled-provider': 'Cancelled by provider',
  'rejected-customer': 'Pending appointment cancelled by customer',
  'rejected-provider': 'Pedning appointment rejected by provider',
};

export const ProviderTypeLabels = [
  '',
  'Dietitians',
  'Massage Therapists',
  'Physicians'
];

export const verificationMethods = [
  { name: 'Driver License', countries: ['us', 'ca'] },
  { name: 'Passport', countries: ['us', 'ca'] },
  { name: 'Birth Certificate', countries: ['us', 'ca'] },
  { name: 'Green Card', countries: ['us'] },
  { name: 'Permanent Resident', countries: ['ca'] }
];

export const providerOnboardingSteps = [
  'Enter additional information',
  'Enter location and profile photo',
  'Enter services and prices',
  'Enter business hours',
  'Update Availability for each location',
  'Upload License and ID Proof'
];

export const clinicMemberOnboardingSteps = [
  'Update Password',
  'Enter additional information',
  'Enter location and profile photo',
  'Enter services and prices',
  'Enter business hours',
  'Update Availability for each location',
  'Upload License and ID Proof'
];
