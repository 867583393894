import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { IonAvatar } from '@ionic/react';

import StatusIndicator from './StatusIndicator';
import { getImage } from '../data/dataApi';
import { User } from '../models';

interface AvatarProps {
  user: User;
  size?: string;
  noIndicator?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ user, size = 'sm', noIndicator = false }) => {
  const avatarClass = useMemo(() => `avatar-${size}`, [size]);

  return (
    <IonAvatar className={avatarClass}>
      <img src={getImage(user.image)} alt="selfie" />
      {!noIndicator ? (
        <div className={`status-indicator ${size}`}>
          <StatusIndicator user={user} size={size} />
        </div>
      ) : null}
    </IonAvatar>
  );
};

Avatar.defaultProps = {
  size: 'sm',
  noIndicator: false,
};

Avatar.propTypes = {
  user: PropTypes.any.isRequired,
  size: PropTypes.string,
  noIndicator: PropTypes.bool,
};

export default Avatar;
