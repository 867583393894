import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { IonAlert, IonFabButton, IonIcon } from '@ionic/react';
import { call } from 'ionicons/icons';
import moment from 'moment';

import connect from '../../../../data/connect';
import { updateBooking, uploadTranscript, socket } from '../../../../data/dataApi';
import {
  Booking, BookingStatus, User, UserRole
} from '../../../../models';
import useChatContext from '../../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import useParticipants from '../../../../hooks/useParticipants/useParticipants';
import { StatusContext } from '../../../../data/StatusContext';
import { SOCKET_KEYS } from '../../../../data/constants';

interface StateProps {
  user: User
}

interface EndCallButtonProps {
  booking: Booking
  currentDate: Date
}

const EndCallButton: React.FC<RouteComponentProps & StateProps & EndCallButtonProps> = ({
  history, user, booking, currentDate
}) => {
  const { room } = useVideoContext();
  const { closeConversation } = useChatContext();
  const { updateStatus } = useContext(StatusContext);
  const participants = useParticipants();
  const [isMeaningfulCall, setIsMeaningfulCall] = useState(false);
  const [showCompleteAlert, setShowCompleteAlert] = useState(false);

  const finishCall = () => {
    room?.localParticipant.audioTracks.forEach((publication) => {
      publication.track.stop();
    });
    room?.localParticipant.videoTracks.forEach((publication) => {
      publication.unpublish();
      publication.track.stop();
    });
    if (room?.participants?.size === 0) {
      closeConversation(true);
    } else {
      closeConversation(false);
    }
    room.disconnect();
    updateStatus(user, 'online')
    history.goBack();
  };

  const onEnd = () => {
    if (user.role !== UserRole.CUSTOMER && isMeaningfulCall) {
      setShowCompleteAlert(true);
    } else {
      finishCall();
    }
  };

  const onComplete = async () => {
    await uploadTranscript(user.role, booking.id);
    updateBooking(Number(room.name), BookingStatus.COMPLETED)
      .then((res) => {
        if (res.success) {
          socket.emit(SOCKET_KEYS.completed, Number(room.name));
        }
      })
      .catch((err) => console.log(err));

    finishCall();
  };

  useEffect(() => {
    if (participants.length) {
      setIsMeaningfulCall(true);
    }
  }, [participants]);

  useEffect(() => {
    if (moment.utc(`${booking.date} ${booking.time}`).add(booking.duration, 'minutes').diff(moment(currentDate).utc(), 'minutes') < 0) {
      if (user.role !== UserRole.CUSTOMER && isMeaningfulCall) {
        setShowCompleteAlert(true);
      } else {
        finishCall();
      }
    }
  }, [currentDate, booking]);

  return (
    <div>
      <IonFabButton onClick={() => onEnd()} color="danger">
        <IonIcon icon={call} />
      </IonFabButton>

      <IonAlert
        isOpen={showCompleteAlert}
        onDidDismiss={finishCall}
        header="CarePlatform"
        message="Call has been processed successfully. Would you complete this appointment?"
        buttons={[
          {
            text: 'Complete',
            cssClass: 'confirm-button',
            handler: () => onComplete()
          },
          // {
          //   text: 'End Call',
          //   cssClass: 'cancel-button',
          //   handler: async () => {
          //     await uploadTranscript(user.role, booking.id);
          //     finishCall();
          //   }
          // }
        ]}
      />
    </div>
  );
};

EndCallButton.propTypes = {
  history: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  booking: PropTypes.any.isRequired,
  currentDate: PropTypes.any.isRequired
};

export default connect<EndCallButtonProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {},
  component: withRouter(EndCallButton)
});
