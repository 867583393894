import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo
} from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  IonButton, IonLoading, IonIcon, IonSpinner
} from '@ionic/react';
import { logOutOutline } from 'ionicons/icons';
import PropTypes from 'prop-types';

import connect from '../../../data/connect';
import { useAppState } from '../../../data/VideoContext';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import { StatusContext } from '../../../data/StatusContext';
import { Booking, User, UserRole } from '../../../models';

import './MenuBar.scss';

interface MenuBarProps {
  data: Booking | null
}

interface StateProps {
  user: User
}

const MenuBar:React.FC<StateProps & MenuBarProps> = ({
  user, data
}) => {
  const history = useHistory();
  const location = useLocation();
  const { room } = useParams();
  const { getToken, isFetching } = useAppState();
  const { isConnecting, connect: videoConnect } = useVideoContext();
  const { connect: chatConnect } = useChatContext();
  const { updateStatus } = useContext(StatusContext);
  const [isLoading, setIsLoading] = useState(true);

  const roomState = useRoomState();

  const onJoin = useCallback(() => {
    if (room && user) {
      getToken('abc', room)
        .then((token) => {
          videoConnect(token);
          chatConnect(token);
          updateStatus(user, 'busy');
        })
        .catch((err) => console.log(err));
    }
  }, [room, user, getToken, videoConnect, chatConnect, updateStatus]);

  const onCancel = () => {
    history.goBack();
  };

  const buttonTitle = useMemo(() => {
    if (room && data) {
      return user.role === UserRole.CUSTOMER ? `Join Room with ${data.provider.name}`
        : `Join Room with ${data.customer.name}`;
    }
    return 'Join Room';
  }, [room, data]);

  useEffect(() => {
    if (location.pathname.includes('/video/') && room) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  }, [location, room]);

  return (
    <div className="video-menubar">
      {roomState === 'disconnected' && (
        <>
          <IonButton
            color="favorite"
            disabled={isConnecting || isFetching || isLoading}
            onClick={() => onJoin()}
          >
            {isConnecting || isFetching ? <IonSpinner className="join-button" /> : buttonTitle}
          </IonButton>
          <IonButton
            color="danger"
            onClick={onCancel}
          >
            <IonIcon icon={logOutOutline} />
          </IonButton>
        </>
      )}
      <IonLoading isOpen={isLoading} />
    </div>
  );
};

MenuBar.propTypes = {
  data: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired
};

export default connect<MenuBarProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {},
  component: React.memo(MenuBar)
});
