import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  StripeProvider,
  Elements,
  ReactStripeElements
} from 'react-stripe-elements';
import {
  IonGrid, IonRow, IonCol, IonButton
} from '@ionic/react';

import { StripePK } from '../data/constants';

const createOptions = (): any => ({
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#c23d4b'
    }
  }
});

interface OwnProps {
  handleToken: (data: any) => void
}

type _CardFormProps = OwnProps & ReactStripeElements.InjectedStripeProps;

const StripeCardForm: React.FC<_CardFormProps> = (props) => {
  const [hasError, setHasError] = useState(true);
  const [addedNumber, setAddedNumber] = useState(false);
  const [addedExpDate, setAddedExpDate] = useState(false);
  const [addedCVC, setAddedCVC] = useState(false);

  const handleSubmit = (evt: any): void => {
    evt.preventDefault();
    if (props.stripe) {
      props.stripe.createToken().then((res: any) => props.handleToken(res));
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  const validate = (e: any) => {
    switch (e.elementType) {
      case 'cardNumber':
        setAddedNumber(e.complete);
        break;
      case 'cardExpiry':
        setAddedExpDate(e.complete);
        break;
      case 'cardCvc':
        setAddedCVC(e.complete);
        break;
    }
    if (e?.error) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  const isDisabled = useMemo(() => {
    if (hasError || !addedNumber || !addedCVC || !addedExpDate) {
      return true;
    }
    return false;
  }, [hasError, addedNumber, addedExpDate, addedCVC]);

  return (
    <form className="card-form" onSubmit={handleSubmit}>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <label>
              Card number
              <CardNumberElement {...createOptions()} onChange={validate} />
            </label>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <label>
              Expiration date
              <CardExpiryElement {...createOptions()} onChange={validate} />
            </label>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <label>
              CVC
              <CardCVCElement {...createOptions()} onChange={validate} />
            </label>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonButton
              type="submit"
              color="favorite"
              expand="block"
              disabled={isDisabled}
            >
              Add
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  );
};

StripeCardForm.propTypes = {
  stripe: PropTypes.any.isRequired,
  handleToken: PropTypes.func.isRequired
};

const SplitFieldsForm = injectStripe(StripeCardForm);

interface CardFormProps {
  handleToken: (data: any) => void
}

const CardForm: React.FC<CardFormProps> = ({ handleToken }) => (
  <StripeProvider apiKey={StripePK}>
    <Elements>
      <SplitFieldsForm handleToken={handleToken} />
    </Elements>
  </StripeProvider>
);

CardForm.propTypes = {
  handleToken: PropTypes.func.isRequired
};

export default CardForm;
