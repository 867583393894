import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ActiveIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_720_8868)">
      <path d="M8.33327 17.3134C7.60794 17.1488 6.91133 16.8767 6.2666 16.5059" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.6665 2.68555C13.3233 3.06394 14.8025 3.99362 15.862 5.32239C16.9215 6.65115 17.4985 8.30026 17.4985 9.99971C17.4985 11.6992 16.9215 13.3483 15.862 14.677C14.8025 16.0058 13.3233 16.9355 11.6665 17.3139" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.81596 14.244C3.36138 13.5835 3.01612 12.8542 2.79346 12.084" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.60352 8.75091C2.73685 7.95924 2.99352 7.20924 3.35352 6.52174L3.49435 6.26758" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.75586 3.81555C6.53557 3.2788 7.41029 2.89531 8.33336 2.68555" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.5 10.0007L9.16667 11.6673L12.5 8.33398" stroke="#0D0D0D" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_720_8868">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ActiveIcon;
