import React, {
  useContext, useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonText,
  IonAlert,
  IonLoading
} from '@ionic/react';
import Form from '@rjsf/material-ui';
import SignatureCanvas from 'react-signature-canvas';
import { ISubmitEvent, IChangeEvent, WidgetProps } from '@rjsf/core';
import OtpInput from 'react-otp-input';

import {
  getRegisterJson,
  registerConsent,
  verify,
  resend
} from '../../data/dataApi';
import { StatusContext } from '../../data/StatusContext';
import connect from '../../data/connect';
import { User, UserRole, UserStatus } from '../../models';
import { dispatchUpdateUser, dispatchLogout } from '../../data/store/auth/auth.actions';
import { SignOutIcon } from '../../icons';

import logoImg from '../../assets/logo.png';
import './Verification.scss';

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
  logout: typeof dispatchLogout
}

interface StateProps {
  user: User
}

type VerificationProps = RouteComponentProps & StateProps & DispatchProps;

const Verification: React.FC<VerificationProps> = ({
  history,
  user,
  updateUser,
  logout
}) => {
  const { syncDisconnect } = useContext(StatusContext);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [scroll, setScroll] = useState(true);
  const [consentLoading, setConsenLoading] = useState(false);

  const [jsonSchema, setJsonSchema] = useState({});
  const [formData, setformData] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const getRegisterJsonData = (): void => {
    getRegisterJson().then((res) => {
      if (res.success) {
        setLoading(false);

        const registerSchema = res.registerformData;
        const { form_schema, ui_schema, form_data } = registerSchema;
        if (typeof form_schema === 'string') {
          setJsonSchema(JSON.parse(form_schema));
          setUiSchema(JSON.parse(ui_schema));
          setformData(JSON.parse(form_data));
        } else {
          setJsonSchema(form_schema);
          setUiSchema(ui_schema);
          setformData(form_data);
        }
      }
    });
  };
  const sigCanvasRef = useRef<any>(null);

  const onSignOut = (): void => {
    syncDisconnect(user);
    logout();
    history.push('/login');
  };

  const onResend = (): void => {
    setLoading(true);
    resend().then(() => {
      console.log('Verification code is sent to your mobile phone');
      setLoading(false);
    });
  };

  const onVerify = (e: React.FormEvent): void => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!code) {
      setCodeError(true);
    }

    if (code) {
      setLoading(true);
      verify(user.phone, code).then((response) => {
        if (response.success) {
          setShowAlert(true);
          setLoading(false);
        }
      });
    }
  };

  const onSubmit = (event: any) => {
    setConsenLoading(true);
    const { allow, signature } = event.formData;

    if (allow && signature.length > 3) {
      registerConsent({ signature }).then(
        (response: { success: boolean, signature: string }) => {
          if (response.success) {
            updateUser({ ...user, status: 'active', signature: response.signature });
            setIsVerified(true);
            setConsenLoading(false);
            setShowAlert(true);
          }
        },
        () => {}
      );
    }
  };

  const jsonChange = (event: ISubmitEvent<IChangeEvent>) => {
    setformData(event.formData);
  };

  const MyCustomWidget = (props: WidgetProps) => {
    const clearDrawing = () => {
      props.onChange(null);
      sigCanvasRef?.current?.clear();
    };

    const saveDrawing = () => {
      const canvas = sigCanvasRef?.current?.toDataURL();
      props.onChange(canvas);
    };

    useEffect(() => {
      if (props.value) {
        sigCanvasRef?.current?.fromDataURL(props.value);
      }
    }, [props]);

    return (
      <>
        <SignatureCanvas
          ref={sigCanvasRef}
          onEnd={saveDrawing}
          canvasProps={{ className: 'signature-canvas' }}
        />
        <IonRow>
          <IonCol>
            <IonButton
              color="danger"
              onClick={clearDrawing}
            >
              Clear
            </IonButton>
          </IonCol>
        </IonRow>
      </>
    );
  };

  const widgets: any = {
    myCustomWidget: MyCustomWidget
  };

  const isSubmitDisabled = useMemo(() => {
    const { allow, signature } = formData as any;
    if (!allow || !signature) {
      return true;
    }
    return false;
  }, [formData]);

  const jsonContent = (
    <IonContent scrollY={scroll} className="page-content">
      <IonButton className="signout-btn" color="favorite" fill="clear" onClick={onSignOut}>
        <SignOutIcon color="#08A284" />
        &nbsp;Sign Out
      </IonButton>
      <div className="consent-container">
        <div className="login-logo">
          <img src={logoImg} alt="Ionic logo" />
          <h3>Welcome to Care Platform</h3>
        </div>
        <div>
          <Form
            schema={jsonSchema}
            formData={formData}
            onChange={jsonChange}
            onSubmit={onSubmit}
            omitExtraData
            uiSchema={uiSchema}
            widgets={widgets}
          >
            <IonButton color="favorite" expand="block" type="submit" disabled={isSubmitDisabled}>Submit</IonButton>
          </Form>
        </div>
      </div>
      {isVerified && (
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={(): void => setShowAlert(false)}
          header="Phone Number Verification"
          message="Thank you! Verification is completed!"
          buttons={[
            {
              text: 'OK',
              handler: (): void => {
                if (user.role === UserRole.PROVIDER || user.role === UserRole.CLINIC_MEMBER) {
                  history.push(`/${user.role}/onboarding`);
                } else {
                  history.push(`/${user.role}/dashboard`);
                }
              }
            }
          ]}
        />
      )}
      <IonLoading isOpen={consentLoading} />
    </IonContent>
  );

  useEffect(() => {
    if (user && user.phone) {
      if (user.status === UserStatus.ACTIVE) {
        history.push(`/${user.role}`);
      } else {
        onResend();
        getRegisterJsonData();
      }
    }
  }, [user]);

  return (
    <IonPage
      id={formSubmitted && !loading ? 'consent-form' : 'verification-page'}
    >
      {formSubmitted && !loading ? (
        jsonContent
      ) : (
        <>
          <IonContent className="page-content">
            <IonButton className="signout-btn" color="favorite" fill="clear" onClick={onSignOut}>
              <SignOutIcon color="#08A284" />
              &nbsp;Sign Out
            </IonButton>
            <div className="verification-container">
              <div className="description">
                <img src={logoImg} alt="Ionic logo" />
                <h3>Verification</h3>
                <div>
                  {`We’ve sent confirmation password on you ${user.phone} number`}
                </div>
              </div>

              <form noValidate onSubmit={onVerify}>
                <IonList>
                  <IonItem lines="none">
                    <OtpInput
                      className="otp-input"
                      value={code}
                      numInputs={6}
                      onChange={(value: string) => setCode(value)}
                    />
                  </IonItem>

                  {formSubmitted && codeError && (
                    <IonText color="danger">
                      <p className="ion-padding-start">
                        Verification Code is required
                      </p>
                    </IonText>
                  )}
                </IonList>

                <IonRow>
                  <IonCol>
                    <IonButton
                      color="favorite"
                      type="submit"
                      expand="block"
                      disabled={loading}
                    >
                      Submit
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      color="dark"
                      expand="block"
                      fill="outline"
                      disabled={loading}
                      onClick={(): void => onResend()}
                    >
                      Resend
                    </IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </div>
          </IonContent>
          <IonLoading isOpen={loading} />
        </>
      )}
    </IonPage>
  );
};

Verification.propTypes = {
  history: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  updateUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser,
    logout: dispatchLogout
  },
  component: Verification
});
