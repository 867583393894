import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent, IonListHeader, IonSegment, IonSegmentButton, IonList, IonLoading, IonInfiniteScroll, IonInfiniteScrollContent, IonGrid, IonRow, IonCol
} from '@ionic/react';
import moment from 'moment';

import connect from '../../data/connect';
import ProviderBookingItem from '../../components/ProviderBookingItem';
import { Booking, BookingStatus } from '../../models';
import { fetchBookings, socket } from '../../data/dataApi';
import { SOCKET_KEYS } from '../../data/constants';

import './ClinicMemberDashboard.scss';

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

type ClinicMemberDashboardProps = RouteComponentProps;

const ClinicMemberDashboard: React.FC<ClinicMemberDashboardProps> = ({
  history
}) => {
  const [segment, setSegment] = useState<'upcoming' | 'pending'>(
    'upcoming'
  );
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);

  const fetchMore = () => {
    setIsFetching(true);
    const data = {
      skip: bookings.length,
      status: segment === 'pending' ? 'pending' : 'accepted',
    };
    fetchBookings(data)
      .then((res) => {
        if (res.success) {
          setBookings([...bookings, ...res.bookings]);
          if (res.bookings.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsFetching(false));
  };

  const onUpdateBooking = (booking: Booking) => {
    if (segment === 'upcoming' && booking.status === BookingStatus.ACCEPTED) {
      const updatedBookings: Booking[] = bookings.map((bk) => {
        if (bk.id === booking.id) {
          return booking;
        }
        return bk;
      });
      setBookings([...updatedBookings]);
    } else {
      const updatedBookings = bookings.filter((bk) => bk.id !== booking.id);
      setBookings([...updatedBookings]);
    }
  };

  useEffect(() => {
    fetchMore();
  }, [segment]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <IonContent id="clinic-member-dashboard" className="page-content ion-padding">
      <IonListHeader className="page-header">Appointments</IonListHeader>
      <IonSegment
        mode="ios"
        value={segment}
        onIonChange={(e): void => {
          setBookings([]);
          setIsFetchedAll(false);
          setSegment(e.detail.value as 'upcoming' | 'pending');
        }}
      >
        <IonSegmentButton value="upcoming">Upcoming</IonSegmentButton>
        <IonSegmentButton value="pending">Pending</IonSegmentButton>
      </IonSegment>
      <IonGrid>
        {segment === 'upcoming' ? (
          <IonRow>
            {bookings
              .sort((a, b) => {
                const diff = moment(`${a.date} ${a.time}`).diff(moment(`${b.date} ${b.time}`));
                if (diff < 0) {
                  return -1;
                }
                return 1;
              })
              .filter((booking) => booking.status === BookingStatus.ACCEPTED)
              .map((booking) => (
                <IonCol size="4" key={booking.id}>
                  <ProviderBookingItem
                    currentDate={currentDate}
                    booking={booking}
                    onUpdate={onUpdateBooking}
                    onMeeting={(): void => history.push(`/video/${booking.id}`)}
                  />
                </IonCol>
              ))}
          </IonRow>
        ) : (
          <IonRow>
            {bookings
              .sort((a, b) => {
                const diff = moment(`${a.date} ${a.time}`).diff(moment(`${b.date} ${b.time}`));
                if (diff < 0) {
                  return -1;
                }
                return 1;
              })
              .filter((booking) => booking.status === BookingStatus.PENDING)
              .map((booking) => (
                <IonCol size="4" key={booking.id}>
                  <ProviderBookingItem
                    currentDate={currentDate}
                    booking={booking}
                    onUpdate={onUpdateBooking}
                  />
                </IonCol>
              ))}
          </IonRow>
        )}
      </IonGrid>
      {isFetchedAll ? null : (
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            fetchMore();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent
            loadingText="Please wait..."
            loadingSpinner="circles"
          />
        </IonInfiniteScroll>
      )}

      <IonLoading isOpen={isFetching} />
    </IonContent>
  );
};

ClinicMemberDashboard.propTypes = {
  history: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, {}, {}>({
  mapStateToProps: () => ({}),
  component: React.memo(ClinicMemberDashboard)
});
