import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import { IonRouterOutlet, IonTabs } from '@ionic/react';

import connect from '../../data/connect';
import Layout from '../../components/Layout';
import { User, Request } from '../../models';
import useGetView from '../../hooks/useGetView/useGetView';
import AdminDashboard from '../AdminDashboard';
import ProvidersList from '../ProvidersList';
import ProviderCertification from '../ProviderCertification';
import ClinicsList from '../ClinicsList';
import CalendarView from '../CalendarView';
import PaymentInformation from '../PaymentInformation';
import AdminReports from '../AdminReports';
import BookingReport from '../BookingReport';
import ClinicReport from '../ClinicReport';
import UserReport from '../UserReport';
import AdminEarnings from '../AdminEarnings';
import AdminClinicMembers from '../AdminClinicMembers';
import AdminMarketing from '../AdminMarketing';
import Users from '../Users';
import {
  dispatchFetchBookings,
  dispatchFetchProviderTypes,
  dispatchFetchLanguages,
  dispatchFetchSpecialists,
  dispatchFetchProviders,
  dispatchFetchRequests,
  dispatchUpdateRequest,
  dispatchFetchCustomers,
  dispatchFetchClinics,
  dispatchSocialMediaTypes,
  dispatchFetchRoles
} from '../../data/store/data/data.actions';
import { socket } from '../../data/dataApi';

interface StateProps {
  user: User
}

interface DispatchProps {
  fetchBookings: typeof dispatchFetchBookings
  fetchProviders: typeof dispatchFetchProviders
  fetchProviderTypes: typeof dispatchFetchProviderTypes
  fetchLanguages: typeof dispatchFetchLanguages
  fetchSpecialists: typeof dispatchFetchSpecialists
  fetchRequests: typeof dispatchFetchRequests
  updateRequest: typeof dispatchUpdateRequest
  fetchCustomers: typeof dispatchFetchCustomers
  fetchClinics: typeof dispatchFetchClinics
  fetchSocialMediaTypes: typeof dispatchSocialMediaTypes
  fetchRoles: typeof dispatchFetchRoles
}

type PlatformAdminProps = StateProps & DispatchProps;

const AdminView: React.FC<PlatformAdminProps> = ({
  user,
  fetchBookings,
  fetchProviders,
  fetchProviderTypes,
  fetchLanguages,
  fetchSpecialists,
  fetchRequests,
  updateRequest,
  fetchCustomers,
  fetchClinics,
  fetchSocialMediaTypes,
  fetchRoles
}) => {
  const { isMobile } = useGetView();

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  useEffect(() => {
    fetchProviders();
  }, [fetchProviders]);

  useEffect(() => {
    fetchProviderTypes();
  }, [fetchProviderTypes]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    fetchSpecialists();
  }, [fetchSpecialists]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  useEffect(() => {
    fetchClinics();
  }, [fetchClinics]);

  useEffect(() => {
    fetchSocialMediaTypes();
  }, [fetchSocialMediaTypes]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    socket.on('update-request', (request: Request) => {
      fetchRequests();
    });
  }, []);

  const renderRoutes = () => (
    <IonRouterOutlet>
      <Layout>
        <Route path="/admin" render={() => <Redirect to="/admin/dashboard" />} exact />
        <Route path="/admin/dashboard" component={AdminDashboard} exact />
        <Route path="/admin/providers" component={ProvidersList} exact />
        <Route path="/admin/provider/:id" component={ProviderCertification} exact />
        <Route path="/admin/clinics" component={ClinicsList} exact />
        <Route path="/admin/clinic/:id" component={ProvidersList} exact />
        <Route path="/admin/calendar" component={CalendarView} exact />
        <Route path="/admin/users" component={Users} exact />
        <Route path="/admin/payment" component={PaymentInformation} exact />
        <Route path="/admin/report" component={AdminReports} exact />
        <Route path="/admin/report/user/:id" component={UserReport} exact />
        <Route path="/admin/report/clinic/:id" component={ClinicReport} exact />
        <Route path="/admin/report/booking/:id" component={BookingReport} exact />
        <Route path="/admin/earnings" component={AdminEarnings} exact />
        <Route path="/admin/clinic-members" component={AdminClinicMembers} exact />
        <Route path="/admin/marketing" component={AdminMarketing} exact />
      </Layout>
    </IonRouterOutlet>
  );

  if (!isMobile) {
    return renderRoutes();
  }

  return (
    <IonTabs>
      {renderRoutes()}
    </IonTabs>
  );
};

AdminView.propTypes = {
  user: PropTypes.any.isRequired,
  fetchBookings: PropTypes.func.isRequired,
  fetchProviders: PropTypes.func.isRequired,
  fetchProviderTypes: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  fetchSpecialists: PropTypes.func.isRequired,
  fetchRequests: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  fetchCustomers: PropTypes.func.isRequired,
  fetchClinics: PropTypes.func.isRequired,
  fetchSocialMediaTypes: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {
    fetchBookings: dispatchFetchBookings,
    fetchProviders: dispatchFetchProviders,
    fetchProviderTypes: dispatchFetchProviderTypes,
    fetchLanguages: dispatchFetchLanguages,
    fetchSpecialists: dispatchFetchSpecialists,
    fetchRequests: dispatchFetchRequests,
    updateRequest: dispatchUpdateRequest,
    fetchCustomers: dispatchFetchCustomers,
    fetchClinics: dispatchFetchClinics,
    fetchSocialMediaTypes: dispatchSocialMediaTypes,
    fetchRoles: dispatchFetchRoles
  },
  component: React.memo(AdminView)
});
