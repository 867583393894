import { DataActions } from './data.actions';
import initialDataState, { DataState } from './data.state';
import {
  BookingStatus, User, UserStatus, Request
} from '../../../models';

export default function dataReducer(
  state: DataState,
  action: DataActions
): DataState {
  const { bookings, notifications } = state;
  let updProv = null;
  let updatedCustomers = null;
  switch (action.type) {
    case '@data/set-loading':
      return { ...state, loading: action.isLoading };
    case '@data/reset':
      return initialDataState;
    case '@data/set-bookings':
      return { ...state, bookings: action.data };
    case '@data/set-provider-types':
      return { ...state, providerTypes: action.providerTypes };
    case '@data/set-social-media-types':
      return { ...state, socialMediaTypes: action.socialMediaTypes };
    case '@data/set-currencies':
      return { ...state, currencies: action.currencies };
    case '@data/set-roles':
      return { ...state, roles: action.roles };
    case '@data/set-categories':
      return { ...state, categories: action.categories };
    case '@data/add-booking':
      return {
        ...state,
        bookings: [...state.bookings, action.booking]
      };
    case '@data/update-booking':
      return {
        ...state,
        bookings: bookings.map((item) => (item.id === action.id ? { ...item, status: action.status } : item))
      };
    case '@data/set-languages':
      return { ...state, allLanguages: action.languages };
    case '@data/set-specialists':
      return { ...state, allSpecialists: action.specialists };
    case '@data/get-all-notification':
      return {
        ...state,
        notifications: action.notifications
      };
    case '@data/update-notification':
      return {
        ...state,
        notifications: notifications.map((item) => (item.id === action.id ? { ...item, ...action.data } : item))
      };
    case '@data/delete-notification':
      return {
        ...state,
        notifications: notifications.filter(
          (item) => item.id !== action.id
        )
      };
    case '@data/read-all-notification':
      return {
        ...state,
        notifications: notifications.map((item) => ({
          ...item,
          read: true
        }))
      };
    case '@data/delete-all-notification':
      return {
        ...state,
        notifications: []
      };
    case '@data/set-clinic-members':
      return {
        ...state,
        members: action.members
      };
    case '@data/set-providers':
      return {
        ...state,
        providers: [...state.providers, ...action.data as User[]],
        isFetchedAllProviders: [...action.data as User[]].length === 0
      };
    case '@data/empty-providers':
      return {
        ...state,
        providers: []
      };
    case '@data/set-clinics':
      return {
        ...state,
        clinics: [...state.clinics, ...action.data as User[]],
        isFetchedAllClinics: [...action.data as User[]].length === 0
      };
    case '@data/empty-clinics':
      return {
        ...state,
        clinics: []
      };
    case '@data/set-favorite-providers':
      const fProvMap = (action.data as User[]).reduce((accm: any, p: any) => {
        accm[p.id] = p;
        p.isFavorite = 1;
        return accm;
      }, {});
      updProv = state.providers.map(p => {
        if (fProvMap[p.id]) {
          p.isFavorite = 1;
        }
        return p;
      });
      return {
        ...state,
        providers: [...updProv],
        favoriteProviders: action.data as User[]
      };
    case '@data/add-favorite-provider':
      updProv = state.providers.map(p => {
        if (action.data.id === p.id) {
          p.isFavorite = 1;
        }
        return p;
      });
      return {
        ...state,
        providers: [...updProv],
        favoriteProviders: [...state.favoriteProviders, action.data]
      };
    case '@data/delete-favorite-provider':
      updProv = state.providers.map((p) => {
        if (action.data === p.id) {
          p.isFavorite = 0;
        }
        return p;
      });
      return {
        ...state,
        providers: [...updProv],
        favoriteProviders: state.favoriteProviders.filter((p) => p.id !== action.data)
      };
    case '@data/update-provider':
      updProv = state.providers.map((p) => {
        if (action.provider.id === p.id) {
          return action.provider;
        }
        return p;
      });
      return {
        ...state,
        providers: [...updProv]
      };
    case '@data/delete-provider':
      updProv = state.providers.filter((provider) => provider.id !== action.providerId);
      return {
        ...state,
        providers: [...updProv]
      };
    case '@data/delete-or-inactivate-clinic':
      if (action.data.isDelete) {
        updProv = state.providers.filter((provider) => provider.id !== action.data.clinicId && provider.clinic !== action.data.clinicId);
      } else {
        updProv = state.providers.map((p) => {
          if (action.data.clinicId === p.id || action.data.clinicId === p.clinic) {
            p.status = UserStatus.INACTIVE;
            return p;
          }
          return p;
        });
      }
      return {
        ...state,
        providers: [...updProv]
      };
    case '@data/set-requests':
      return { ...state, requests: action.data as Request[] };
    case '@data/update-request':
      const index = state.requests.findIndex((r) => r.id === action.request.id);
      if (index > -1) {
        const updatedRequests = state.requests.map((r) => {
          if (action.request.id === r.id) {
            return action.request;
          }
          return r;
        });
        return { ...state, requests: [...updatedRequests] };
      }
      return { ...state, requests: [action.request, ...state.requests] };
    case '@data/set-customers':
      return {
        ...state,
        customers: [...state.customers, ...action.data as User[]],
        isFetchedAllCustomers: [...action.data as User[]].length === 0
      };
    case '@data/empty-customers':
      return {
        ...state,
        customers: []
      };
    case '@data/update-customer':
      updatedCustomers = state.customers.map((customer) => {
        if (action.customer.id === customer.id) {
          return action.customer;
        }
        return customer;
      });
      return { ...state, customers: [...updatedCustomers] };
    case '@data/delete-customer':
      updatedCustomers = state.customers.filter((customer) => customer.id !== action.customerId);
      return { ...state, customers: [...updatedCustomers] };
    case '@data/set-timeslots': {
      return {
        ...state,
        timeslots: action.slots,
        locations: action.locations
      };
    }
    case '@data/add-timeslot-day': {
      const { timeslots } = state;
      return {
        ...state,
        timeslots: [...timeslots, action.slot]
      };
    }
    case '@data/remove-timeslot': {
      const { timeslots } = state;
      return {
        ...state,
        timeslots: timeslots.filter((item) => item.id !== action.id)
      };
    }
    case '@data/update-timeslot': {
      const { timeslots } = state;
      const { slot } = action;
      const idx = timeslots.findIndex((item) => item.id === slot.id);
      const updatedSlots = [...timeslots];
      updatedSlots.splice(idx, 1, slot);
      return {
        ...state,
        timeslots: [...updatedSlots]
      };
    }
    default:
      return state;
  }
}
