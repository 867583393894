import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonListHeader,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonIcon
} from '@ionic/react';
import { mailOpenOutline } from 'ionicons/icons';
import moment from 'moment';

import Avatar from '../../components/Avatar';
import connect from '../../data/connect';
import {
  AppointmentType, BookingStatus, Message, User, UserRole
} from '../../models';
import MessageItem from '../../components/MessageItem';
import {
  dispatchUpdateNotification,
  dispatchDeleteNotification,
  dispatchReadAllNotification,
  dispatchDeleteAllNotification
} from '../../data/store/data/data.actions';
import { ClockIcon } from '../../icons';

import './Notifications.scss';

interface StateProps {
  user: User
  notifications: Message[]
}

interface DispatchProps {
  updateNotification: typeof dispatchUpdateNotification
  deleteNotification: typeof dispatchDeleteNotification
  readAllNotification: typeof dispatchReadAllNotification
  deleteAllNotification: typeof dispatchDeleteAllNotification
}

type NotificationsProps = RouteComponentProps & StateProps & DispatchProps;

const Notifications: React.FC<NotificationsProps> = ({
  history,
  user,
  notifications,
  updateNotification,
  deleteNotification,
  readAllNotification,
  deleteAllNotification
}) => {
  const [segment, setSegment] = useState<'unread' | 'all'>('unread');
  const [selectedNotification, setSelectedNotification] = useState<Message | null>(null);

  const onClick = (item: Message) => {
    setSelectedNotification(item);
    if (!item.isRead) {
      updateNotification(item.id, { isRead: true });
    }
  };

  const availableNotifications = useMemo(() => {
    if (segment === 'unread') {
      return notifications.filter((nt) => !nt.isRead);
    }
    return notifications;
  }, [notifications, segment]);

  const notificationTitle = useMemo(() => {
    if (selectedNotification) {
      let title = '';
      switch (selectedNotification.type) {
        case 'booking.new':
          title = 'You have a new appointment.';
          break;
        case 'booking.accepted':
          title = 'You appointment has been accepted.';
          break;
        case 'booking.cancelled-customer':
        case 'booking.cancelled-provider':
          title = 'You appointment has been cancelled.';
          break;
        case 'booking.rejected-customer':
          title = 'You appointment has been abandoned.';
          break;
        case 'booking.rejected-provider':
          title = 'You appointment has been rejected.';
          break;
        case 'booking.completed':
          title = 'You appointment is completed.';
          break;
        case 'booking.reminder':
          title = 'You appointment is coming now.';
          break;
        case 'identity.approved':
          title = 'Your ID has been approved.';
          break;
        case 'identity.rejected':
          title = 'Your ID has been rejected.';
          break;
        case 'license.approved':
          title = 'Your license has been approved.';
          break;
        case 'license.rejected':
          title = 'Your license has been rejected.';
          break;
        case 'booking.call-customer':
          title = selectedNotification.booking.type === AppointmentType.VIRTUAL
            ? 'Customer is Waiting for you in call'
            : 'Customer arrived';
          break;
        case 'booking.call-provider':
          title = selectedNotification.booking.type === AppointmentType.VIRTUAL
            ? 'Provider is Waiting for you in call'
            : 'Provider arrived';
          break;
        case 'subscription.charged':
          title = 'Subscription Charged';
          break;
        default:
          break;
      }
      return title;
    }
    return '';
  }, [selectedNotification]);

  const notificationContent = useMemo(() => {
    if (selectedNotification) {
      switch (selectedNotification.type) {
        case 'booking.new':
          if (user.role === UserRole.CLINIC_ADMIN) {
            return (
              <div>
                <span>New appointment between</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>and</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')} `}</span>
                <span>is</span>
                <span>pending</span>
                <span>confirmation.</span>
                {selectedNotification.booking.type !== AppointmentType.VIRTUAL ? (
                  <div>
                    <span>Meeting location:</span>
                    <span>{selectedNotification.booking.address}</span>
                  </div>
                ) : null}
              </div>
            );
          }
          return (
            <div>
              <span>New appointment with</span>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
              <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
              <span>on</span>
              <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')} `}</span>
              <span>is</span>
              <span>pending</span>
              <span>confirmation.</span>
              {selectedNotification.booking.type !== AppointmentType.VIRTUAL ? (
                <div>
                  <span>Meeting location:</span>
                  <span>{selectedNotification.booking.address}</span>
                </div>
              ) : null}
            </div>
          );
        case 'booking.accepted':
          if (user.role === UserRole.CLINIC_ADMIN) {
            return (
              <div>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>accepted</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
              </div>
            );
          }
          if (selectedNotification.receiverId === selectedNotification.senderId) {
            return (
              <div>
                <span>You</span>
                <span>accepted</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
              </div>
            );
          }
          return (
            <div>
              <span>Your appointment with</span>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
              <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
              <span>on</span>
              <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')} `}</span>
              <span>has</span>
              <span>been</span>
              <span>accepted.</span>
            </div>
          );
        case 'booking.cancelled-customer':
          if (user.role === UserRole.CLINIC_ADMIN) {
            return (
              <div>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>cancelled</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
                {selectedNotification.booking.cancelledReason ? (
                  <>
                    <span>This</span>
                    <span>is</span>
                    <span>reason:</span>
                    <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                  </>
                ) : null}
              </div>
            );
          }
          if (selectedNotification.receiverId === selectedNotification.senderId) {
            return (
              <div>
                <span>You</span>
                <span>cancelled</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
                {selectedNotification.booking.cancelledReason ? (
                  <>
                    <span>This</span>
                    <span>is</span>
                    <span>reason:</span>
                    <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                  </>
                ) : null}
              </div>
            );
          }
          return (
            <div>
              <span>Your</span>
              <span>appointment</span>
              <span>with</span>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
              <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
              <span>on</span>
              <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')} `}</span>
              <span>has</span>
              <span>been</span>
              <span>cancelled.</span>
              {selectedNotification.booking.cancelledReason ? (
                <>
                  <span>This</span>
                  <span>is</span>
                  <span>reason:</span>
                  <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                </>
              ) : null}
            </div>
          );
        case 'booking.cancelled-provider':
          if (user.role === UserRole.CLINIC_ADMIN) {
            return (
              <div>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>cancelled</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
                {selectedNotification.booking.cancelledReason ? (
                  <>
                    <span>This</span>
                    <span>is</span>
                    <span>reason:</span>
                    <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                  </>
                ) : null}
              </div>
            );
          }
          if (selectedNotification.receiverId === selectedNotification.senderId) {
            return (
              <div>
                <span>You</span>
                <span>cancelled</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
                {selectedNotification.booking.cancelledReason ? (
                  <>
                    <span>This</span>
                    <span>is</span>
                    <span>reason:</span>
                    <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                  </>
                ) : null}
              </div>
            );
          }
          return (
            <div>
              <span>Your</span>
              <span>appointment</span>
              <span>with</span>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
              <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
              <span>on</span>
              <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')} `}</span>
              <span>has</span>
              <span>been</span>
              <span>cancelled.</span>
              {selectedNotification.booking.cancelledReason ? (
                <>
                  <span>This</span>
                  <span>is</span>
                  <span>reason:</span>
                  <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                </>
              ) : null}
            </div>
          );
        case 'booking.rejected-customer':
          if (user.role === UserRole.CLINIC_ADMIN) {
            return (
              <div>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>cancelled</span>
                <span>pending</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
                {selectedNotification.booking.cancelledReason ? (
                  <>
                    <span>This</span>
                    <span>is</span>
                    <span>reason:</span>
                    <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                  </>
                ) : null}
              </div>
            );
          }
          if (selectedNotification.receiverId === selectedNotification.senderId) {
            return (
              <div>
                <span>You</span>
                <span>cancelled</span>
                <span>pending</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
                {selectedNotification.booking.cancelledReason ? (
                  <>
                    <span>This</span>
                    <span>is</span>
                    <span>reason:</span>
                    <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                  </>
                ) : null}
              </div>
            );
          }
          return (
            <div>
              <span>Your</span>
              <span>appointment</span>
              <span>with</span>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
              <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
              <span>on</span>
              <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')} `}</span>
              <span>has</span>
              <span>been</span>
              <span>abandoned.</span>
              {selectedNotification.booking.cancelledReason ? (
                <>
                  <span>This</span>
                  <span>is</span>
                  <span>reason:</span>
                  <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                </>
              ) : null}
            </div>
          );
        case 'booking.rejected-provider':
          if (user.role === UserRole.CLINIC_ADMIN) {
            return (
              <div>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>rejected</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
                {selectedNotification.booking.cancelledReason ? (
                  <>
                    <span>This</span>
                    <span>is</span>
                    <span>reason:</span>
                    <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                  </>
                ) : null}
              </div>
            );
          }
          if (selectedNotification.receiverId === selectedNotification.senderId) {
            return (
              <div>
                <span>You</span>
                <span>rejected</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
                {selectedNotification.booking.cancelledReason ? (
                  <>
                    <span>This</span>
                    <span>is</span>
                    <span>reason:</span>
                    <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                  </>
                ) : null}
              </div>
            );
          }
          return (
            <div>
              <span>Your</span>
              <span>appointment</span>
              <span>with</span>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
              <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
              <span>on</span>
              <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}`}</span>
              <span>has</span>
              <span>been</span>
              <span>rejected.</span>
              {selectedNotification.booking.cancelledReason ? (
                <>
                  <span>This</span>
                  <span>is</span>
                  <span>reason:</span>
                  <span>{`"${selectedNotification.booking.cancelledReason}"`}</span>
                </>
              ) : null}
            </div>
          );
        case 'booking.completed':
          if (user.role === UserRole.CLINIC_ADMIN) {
            return (
              <div>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>completed</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
              </div>
            );
          }
          if (selectedNotification.receiverId === selectedNotification.senderId) {
            return (
              <div>
                <span>You</span>
                <span>completed</span>
                <span>appointment</span>
                <span>with</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>on</span>
                <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}.`}</span>
              </div>
            );
          }
          return (
            <div>
              <span>Your</span>
              <span>appointment</span>
              <span>with</span>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
              <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
              <span>on</span>
              <span>{`${moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).local().format('YYYY-MM-DD hh:mm A')}`}</span>
              <span>has</span>
              <span>been</span>
              <span>completed.</span>
            </div>
          );
        case 'booking.call-customer':
          if (selectedNotification.booking.type === AppointmentType.VIRTUAL) {
            return (
              <div>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                <span>is</span>
                <span>on</span>
                <span>the</span>
                <span>meeting</span>
                <span>now.</span>
              </div>
            );
          }
          return (
            <div>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
              <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
              <span>has</span>
              <span>arrived</span>
              <span>for</span>
              <span>meeting</span>
              <span>now.</span>
            </div>
          );
        case 'booking.call-provider':
          if (selectedNotification.booking.type === AppointmentType.VIRTUAL) {
            return (
              <div>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>is</span>
                <span>on</span>
                <span>the</span>
                <span>meeting</span>
                <span>now.</span>
              </div>
            );
          }
          return (
            <div>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
              <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
              <span>has</span>
              <span>arrived</span>
              <span>for</span>
              <span>meeting</span>
              <span>now.</span>
            </div>
          );
        case 'booking.refunded':
          if (user.role === UserRole.CUSTOMER) {
            return (
              <div>
                <span>{`$${Number(selectedNotification.booking.charge.amount).toFixed(2)} has been refunded because the appointment with`}</span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                <span>is</span>
                <span>{selectedNotification.booking.status === 'incomplete' ? 'incomplete.' : 'cancelled.'}</span>
              </div>
            );
          }
          return (
            <div>
              <span>{`You should charge $${Number(selectedNotification.booking.charge.providerFee * -1).toFixed(2)}`}</span>
              <span>because</span>
              <span>you</span>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <span>didn't</span>
              <span>join</span>
              <span>call</span>
              <span>with</span>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
              <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
            </div>
          );
        case 'booking.released':
          return (
            <div>
              <span>{`$${Number(selectedNotification.booking.charge.amount).toFixed(2)} has been released because the appointment with`}</span>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
              <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
              <span>is</span>
              {selectedNotification.booking.status === BookingStatus.INCOMPLETE
                ? <span>incomplete.</span>
                : null}
              {selectedNotification.booking.status === BookingStatus.REJECTED_CUSTOMER
                ? <span>abandoned.</span>
                : null}
              {selectedNotification.booking.status === BookingStatus.REJECTED_PROVIDER
                ? <span>rejected.</span>
                : null}
            </div>
          );
        case 'booking.charged':
          if (user.role === UserRole.CUSTOMER) {
            return (
              <div>
                <span>
                  {`You have been charged $${Number(selectedNotification.booking.charge.chargedAmount).toFixed(2)} for the appointment with`}
                </span>
                <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}.`}</span>
              </div>
            );
          }
          return (
            <div>
              <span>{`CarePlatform have provided $${Number(selectedNotification.booking.charge.providerFee).toFixed(2)} for the appointment with`}</span>
              <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
              <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}.`}</span>
            </div>
          );
        case 'booking.reminder': {
          const gap = moment.utc(`${selectedNotification.booking.date} ${selectedNotification.booking.time}`).diff(moment.utc(), 'minutes');
          return (
            <div>
              <span>You</span>
              <span>have</span>
              <span>an</span>
              <span>appointment</span>
              <span>with</span>
              {user.role === UserRole.CUSTOMER ? (
                <>
                  <Avatar size="xs" noIndicator user={selectedNotification.booking?.provider} />
                  <span>{`${selectedNotification.booking.provider.name} ${selectedNotification.booking.provider.lastName ?? ''}`}</span>
                </>
              ) : (
                <>
                  <Avatar size="xs" noIndicator user={selectedNotification.booking?.customer} />
                  <span>{`${selectedNotification.booking.customer.name} ${selectedNotification.booking.customer.lastName ?? ''}`}</span>
                </>
              )}
              {gap > 120 ? (
                <>
                  <span>tomorrow.</span>
                </>
              ) : null}
              {gap <= 120 && gap > 30 ? (
                <>
                  <span>2</span>
                  <span>hours</span>
                  <span>later.</span>
                </>
              ) : null}
              {gap <= 30 ? (
                <>
                  <span>30</span>
                  <span>minutes</span>
                  <span>later.</span>
                </>
              ) : null}
              {selectedNotification.booking.type !== AppointmentType.VIRTUAL ? (
                <div>
                  <span>Meeting location:</span>
                  <span>{selectedNotification.booking.address}</span>
                </div>
              ) : null}
            </div>
          );
        }
        case 'user.passwordChange':
          return (
            <div>
              Your password has been changed.
            </div>
          );
        case 'identity.approved':
          return (
            <div>Your uploaded ID has been approved by Administrator</div>
          );
        case 'identity.rejected':
          return (
            <div>
              Your uploaded ID has been rejected by Administrator.
              Please check the reason on settings page.
            </div>
          );
        case 'license.approved':
          return (
            <div>Your uploaded license has been approved by Administrator</div>
          );
        case 'license.rejected':
          return (
            <div>
              Your uploaded license has been rejected by Administrator.
              Please check the reason on settings page.
            </div>
          );
        case 'subscription.charged':
          return (
            <div>
              {`$${selectedNotification.subscriptionCharge.price.toFixed(2)} has been charged for subscription.`}
            </div>
          );
        default:
          return null;
      }
    }
    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotification]);

  return (
    <IonContent id="notification" className="page-content">
      <IonListHeader>Notifications</IonListHeader>
      <IonGrid>
        <IonRow>
          <IonCol size="4">
            <IonSegment
              mode="ios"
              value={segment}
              onIonChange={(e): void => setSegment(e.detail.value as 'all' | 'unread')}
            >
              <IonSegmentButton value="unread">Unread</IonSegmentButton>
              <IonSegmentButton value="all">All</IonSegmentButton>
            </IonSegment>
            <IonList>
              {availableNotifications.map((item) => (
                <MessageItem
                  key={item.id}
                  message={item}
                  isSelected={item.id === selectedNotification?.id}
                  onClick={() => onClick(item)}
                />
              ))}
            </IonList>
          </IonCol>
          <IonCol offset="0.1">
            <IonButton
              color="favorite"
              fill="clear"
              onClick={readAllNotification}
            >
              Mark all as read
            </IonButton>
            <div className="notification-content">
              {selectedNotification ? (
                <>
                  <div className="content-header">
                    <p className="title">{notificationTitle}</p>
                    <span className="time">
                      <ClockIcon color="#78908F" />
                      {moment(selectedNotification.createdAt).format('MMM DD, YYYY hh:mm A')}
                    </span>
                    <IonIcon icon={mailOpenOutline} />
                  </div>
                  <div className="content-body">
                    <p className="title">{`👋 Hi ${user.name} ${user.lastName ?? ''}`}</p>
                    {notificationContent}
                  </div>
                </>
              ) : <h2 className="no-selected">Please select notification to see details</h2>}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

Notifications.propTypes = {
  history: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  notifications: PropTypes.array.isRequired,
  updateNotification: PropTypes.func.isRequired,
  deleteNotification: PropTypes.func.isRequired,
  readAllNotification: PropTypes.func.isRequired,
  deleteAllNotification: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user,
    notifications: state.data.notifications
  }),
  mapDispatchToProps: {
    updateNotification: dispatchUpdateNotification,
    deleteNotification: dispatchDeleteNotification,
    readAllNotification: dispatchReadAllNotification,
    deleteAllNotification: dispatchDeleteAllNotification
  },
  component: React.memo(Notifications)
});
