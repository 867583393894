import React from 'react';
import PropTypes from 'prop-types';
import {
  IonItem, IonLabel, IonIcon, IonButton
} from '@ionic/react';
import { brushOutline, trashOutline } from 'ionicons/icons';

import { WalletIcon } from '../icons';
import { PaymentSource } from '../models';

interface CreditCardItemProps {
  isEnableDelete?: boolean
  source: PaymentSource
  onDelete?: (id: number) => void
  onEdit?: (id: number) => void
  onSelectDefault?: (id: number) => void
}

const CreditCardItem: React.FC<CreditCardItemProps> = ({
  isEnableDelete,
  source,
  onEdit,
  onDelete,
  onSelectDefault
}) => (
  <IonItem lines="none" className="credit-card">
    {source.default ? (
      <IonButton fill="clear">
        <WalletIcon color="#08A284" />
      </IonButton>
    ) : (
      <IonButton
        fill="clear"
        onClick={(): void => onSelectDefault && onSelectDefault(source.id)}
      >
        <WalletIcon />
      </IonButton>
    )}
    <IonLabel>
      <p>{`**** **** **** ${source.last4}`}</p>
    </IonLabel>
    <div className="actions" slot="end">
      <IonButton
        fill="clear"
        color="dark"
        onClick={(): void => onEdit && onEdit(source.id)}
      >
        <IonIcon
          icon={brushOutline}
        />
      </IonButton>
      {!source.default && isEnableDelete ? (
        <IonButton
          fill="clear"
          color="dark"
          onClick={(): void => onDelete && onDelete(source.id)}
        >
          <IonIcon
            className="close-icon"
            icon={trashOutline}
            size="small"
          />
        </IonButton>
      ) : null}
    </div>
  </IonItem>
);

CreditCardItem.defaultProps = {
  isEnableDelete: true,
  onEdit: (): void => {},
  onDelete: (): void => {},
  onSelectDefault: (): void => {}
};

CreditCardItem.propTypes = {
  isEnableDelete: PropTypes.bool,
  source: PropTypes.any.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onSelectDefault: PropTypes.func
};

export default CreditCardItem;
