import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton
} from '@ionic/react';
import {
  closeOutline, ellipsisHorizontal, helpCircle, options, searchOutline
} from 'ionicons/icons';
import { Range } from 'react-date-range';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import moment from 'moment';
import clsx from 'clsx';

import Avatar from '../../components/Avatar';
import connect from '../../data/connect';
import { fetchBookings } from '../../data/dataApi';
import {
  Booking,
  BookingStatus,
  ChargeStatus,
  ProviderType
} from '../../models';
import { DetailsIcon } from '../../icons';

interface BookingActionsProps {
  booking: Booking
}

const BookingActions: React.FC<BookingActionsProps> = ({ booking }) => {
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onShowDetails = () => {
    history.push(`/admin/report/booking/${booking.id}`);
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'top', 'left', 'right']}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="#daf3ef"
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className="popover-content">
            <div className="option" onClick={onShowDetails}>
              <IonLabel>
                <DetailsIcon />
                Details
              </IonLabel>
            </div>
          </div>
        </ArrowContainer>
      )}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      <span
        className="action"
        title="More actions"
        onClick={(e) => {
          e.stopPropagation();
          setIsPopoverOpen(!isPopoverOpen);
        }}
      >
        <IonIcon icon={ellipsisHorizontal} />
      </span>
    </Popover>
  );
};

BookingActions.propTypes = {
  booking: PropTypes.any.isRequired
};

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface BookingsViewProps {
  dateRange: Range | null
}

interface StateProps {
  providerTypes: ProviderType[]
}

const BookingsView: React.FC<BookingsViewProps & StateProps> = ({ dateRange, providerTypes }) => {
  const [segment, setSegment] = useState<'all' | 'live' | 'pending' | 'accepted' | 'rejected' | 'abandoned' | 'cancelled' | 'incomplete' | 'completed'>('all');
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [filterParams, setFilterParams] = useState({
    country: '',
    province: '',
    city: '',
    type: '',
    bookings: {
      min: 0,
      max: 500
    }
  });
  const [tempFilterParams, setTempFilterParams] = useState({
    country: '',
    province: '',
    city: '',
    type: '',
    bookings: {
      min: 0,
      max: 500
    }
  });
  const [showTooltipPopover, setShowTooltipPopover] = useState(false);

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const fetchMore = (isNew: boolean) => {
    const params = {
      skip: isNew ? 0 : bookings.length,
      status: segment,
      searchKey,
      dateRange: dateRange ? {
        start: moment(dateRange.startDate).toISOString(),
        end: moment(dateRange.endDate).toISOString()
      } : null,
      orderBy,
      isDescending
    };

    setIsFetching(true);
    fetchBookings(params)
      .then((res) => {
        if (res.success) {
          if (isNew) {
            setBookings([...res.bookings]);
          } else {
            setBookings([...bookings, ...res.bookings]);
          }
          if (!res.bookings.length || res.bookings.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsFetching(false));
  };

  const onSearch = () => {
    fetchMore(true);
  };

  const onOpenFilter = () => {
    // TODO
  };

  useEffect(() => {
    fetchMore(true);
  }, [dateRange, segment, orderBy, isDescending, filterParams]);

  useEffect(() => {
    if (!searchKey) {
      fetchMore(true);
    }
  }, [searchKey]);

  return (
    <div className="admin-report-bookings">
      <div className="sub-header">
        <IonSegment
          mode="ios"
          value={segment}
          onIonChange={(e) => setSegment(e.detail.value as 'all' | 'live' | 'pending' | 'accepted' | 'rejected' | 'abandoned' | 'cancelled' | 'incomplete' | 'completed')}
        >
          <IonSegmentButton value="all">All</IonSegmentButton>
          <IonSegmentButton value="live">Live</IonSegmentButton>
          <IonSegmentButton value="pending">Pending</IonSegmentButton>
          <IonSegmentButton value="accepted">Accepted</IonSegmentButton>
          <IonSegmentButton value="rejected">Rejected</IonSegmentButton>
          <IonSegmentButton value="abandoned">Abandoned</IonSegmentButton>
          <IonSegmentButton value="cancelled">Cancelled</IonSegmentButton>
          <IonSegmentButton value="incomplete">Incomplete</IonSegmentButton>
          <IonSegmentButton value="completed">Completed</IonSegmentButton>
        </IonSegment>
        <Popover
          isOpen={showTooltipPopover}
          positions={['bottom', 'top', 'left', 'right']}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor="#daf3ef"
              arrowSize={10}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              <div className="popover-content tooltip">
                <div
                  className="status-option live"
                  onClick={() => {
                    setSegment('live');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Live
                  </p>
                  <span>
                    Bookings that are ongoing between 15mins before current time and 15 mins after
                  </span>
                </div>
                <div
                  className="status-option pending"
                  onClick={() => {
                    setSegment('pending');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Pending
                  </p>
                  <span>
                    Customer booked, provider has not accepted it
                  </span>
                </div>
                <div
                  className="status-option accepted"
                  onClick={() => {
                    setSegment('accepted');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Accepted
                  </p>
                  <span>
                    Customer booked and Provider accepted it
                  </span>
                </div>
                <div
                  className="status-option rejected"
                  onClick={() => {
                    setSegment('rejected');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Rejected
                  </p>
                  <span>
                    Customer books and Provider does not accepts it and rejects it
                  </span>
                </div>
                <div
                  className="status-option abandoned"
                  onClick={() => {
                    setSegment('abandoned');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Abandoned
                  </p>
                  <span>
                    Cancelled by the customer before the provider accepts it.
                  </span>
                </div>
                <div
                  className="status-option cancelled"
                  onClick={() => {
                    setSegment('cancelled');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Cancelled
                  </p>
                  <span>
                    Appointment is cancelled by Customer or Provider after its accepted
                  </span>
                </div>
                <div
                  className="status-option incomplete"
                  onClick={() => {
                    setSegment('incomplete');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Incomplete
                  </p>
                  <span>
                    Both Customer and Provider don't join.
                    If any one of then don't join the call
                    if appointment is not accepted until booking date time.
                  </span>
                </div>
                <div
                  className="status-option completed"
                  onClick={() => {
                    setSegment('completed');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Completed
                  </p>
                  <span>
                    Service done and complete
                  </span>
                </div>
              </div>
            </ArrowContainer>
          )}
          onClickOutside={() => setShowTooltipPopover(false)}
        >
          <span
            className="help-icon"
            onClick={() => setShowTooltipPopover(true)}
          >
            <IonIcon color="favorite" icon={helpCircle} />
          </span>
        </Popover>
        <IonItem className="search-input" lines="none">
          <IonInput
            value={searchKey}
            placeholder="Search here"
            onKeyPress={(e) => {
              if (e.keyCode === 13 || e.key === 'Enter') {
                onSearch();
              }
            }}
            onIonChange={(e) => {
              setSearchKey(e.detail.value ?? '');
            }}
          />
          <IonIcon size="small" icon={searchOutline} onClick={() => onSearch()} />
          <IonIcon size="small" icon={closeOutline} onClick={() => setSearchKey('')} />
        </IonItem>
        <IonButton color="dark" fill="outline" onClick={onOpenFilter}>
          <IonIcon icon={options} />
          Filter
        </IonButton>
      </div>
      <div className="booking-list">
        <IonGrid>
          <IonRow className="table-header">
            <IonCol className="sm">
              NO
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer sm', { isOrderBy: orderBy === 'id' })}
              onClick={() => onChangeOrderBy('id')}
            >
              B.ID
              {orderBy === 'id' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              size="2.25"
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'customer' })}
              onClick={() => onChangeOrderBy('customer')}
            >
              CUS. NAME AND EMAIL
              {orderBy === 'customer' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>
              PRO.TYPE AND SERVICE
            </IonCol>
            <IonCol
              size="2.25"
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'provider' })}
              onClick={() => onChangeOrderBy('provider')}
            >
              PRO. NAME AND EMAIL
              {orderBy === 'provider' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              size="1"
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'date' })}
              onClick={() => onChangeOrderBy('date')}
            >
              DATETIME&nbsp;
              {orderBy === 'date' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>
              STATUS
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'cost' })}
              onClick={() => onChangeOrderBy('cost')}
            >
              COST
              {orderBy === 'cost' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'providerFee' })}
              onClick={() => onChangeOrderBy('providerFee')}
            >
              PRO. SPLIT
              {orderBy === 'providerFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'cpFee' })}
              onClick={() => onChangeOrderBy('cpFee')}
            >
              C.P SPLIT
              {orderBy === 'cpFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'stripeFee' })}
              onClick={() => onChangeOrderBy('stripeFee')}
            >
              STRIPE FEES
              {orderBy === 'stripeFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>
              PAY. STATUS
            </IonCol>
            <IonCol>
              ACTIONS
            </IonCol>
          </IonRow>
          {bookings.map((booking, index) => (
            <IonRow key={booking.id}>
              <IonCol className="sm">
                {index + 1}
              </IonCol>
              <IonCol className="sm">
                {booking.id}
              </IonCol>
              <IonCol size="2.25">
                <div className="user-name-email">
                  <Avatar user={booking.customer} />
                  <div>
                    <h4>{`${booking.customer.name} ${booking.customer.lastName ?? ''}`}</h4>
                    <p>{booking.customer.email}</p>
                  </div>
                </div>
              </IonCol>
              <IonCol>
                <div className="type-service">
                  <span>
                    {providerTypes.find((pt) => pt.id === booking.provider.type)?.name}
                  </span>
                  <span>
                    {booking.service}
                  </span>
                </div>
              </IonCol>
              <IonCol size="2.25">
                <div className="user-name-email">
                  <Avatar user={booking.provider} />
                  <div>
                    <h4>{`${booking.provider.name} ${booking.provider.lastName ?? ''}`}</h4>
                    <p>{booking.provider.email}</p>
                  </div>
                </div>
              </IonCol>
              <IonCol size="1">
                {moment.utc(`${booking.date} ${booking.time}`).local().format('DD MMMM, YYYY hh:mm A')}
              </IonCol>
              <IonCol>
                {booking.status === BookingStatus.PENDING ? (
                  <div className="status pending">Pending</div>
                ) : null}
                {booking.status === BookingStatus.REJECTED_PROVIDER ? (
                  <div className="status rejected">Rejected</div>
                ) : null}
                {booking.status === BookingStatus.REJECTED_CUSTOMER ? (
                  <div className="status abandoned">Abandoned</div>
                ) : null}
                {booking.status === BookingStatus.CANCELLED_CUSTOMER || booking.status === BookingStatus.CANCELLED_PROVIDER ? (
                  <div className="status cancelled">Cancelled</div>
                ) : null}
                {booking.status === BookingStatus.ACCEPTED ? (
                  <div className="status accepted">Accepted</div>
                ) : null}
                {booking.status === BookingStatus.COMPLETED ? (
                  <div className="status completed">Completed</div>
                ) : null}
                {booking.status === BookingStatus.INCOMPLETE ? (
                  <div className="status incomplete">Incomplete</div>
                ) : null}
              </IonCol>
              <IonCol>
                {`$${booking.charge.amount}`}
              </IonCol>
              <IonCol>
                {booking.charge.providerFee >= 0 ? `$${booking.charge.providerFee}` : `-$${-1 * booking.charge.providerFee}`}
              </IonCol>
              <IonCol>
                {booking.charge.cpFee >= 0 ? `$${booking.charge.cpFee}` : `-$${-1 * booking.charge.cpFee}`}
              </IonCol>
              <IonCol>
                {`$${booking.charge.stripeFee}`}
              </IonCol>
              <IonCol>
                {booking.charge.status === ChargeStatus.CHARGED ? (
                  <div className="status charged">Charged</div>
                ) : null}
                {booking.charge.status === ChargeStatus.REFUNDED ? (
                  <div className="status refunded">Refunded</div>
                ) : null}
                {booking.charge.status === ChargeStatus.HOLD ? (
                  <div className="status hold">Hold</div>
                ) : null}
                {booking.charge.status === ChargeStatus.RELEASED ? (
                  <div className="status refunded">Released</div>
                ) : null}
              </IonCol>
              <IonCol>
                <BookingActions booking={booking} />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
        {isFetchedAll ? null : (
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              fetchMore(false);
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent
              loadingText="Fetching more..."
              loadingSpinner="circles"
            />
          </IonInfiniteScroll>
        )}
      </div>
    </div>
  );
};

BookingsView.defaultProps = {
  dateRange: null
};

BookingsView.propTypes = {
  dateRange: PropTypes.any,
  providerTypes: PropTypes.array.isRequired
};

export default connect<BookingsViewProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes
  }),
  component: React.memo(BookingsView)
});
