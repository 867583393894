import React from 'react';
import PropTypes from 'prop-types';
import { Media } from '@twilio/conversations';
import { IonIcon } from '@ionic/react';
import { documentAttachOutline, downloadOutline } from 'ionicons/icons';

interface MediaMessageProps {
  media: Media
}

const formatFileSize = (bytes: number, suffixIndex = 0): string => {
  const suffixes = ['bytes', 'KB', 'MB', 'GB'];
  if (bytes < 1000) return +bytes.toFixed(2) + ' ' + suffixes[suffixIndex];
  return formatFileSize(bytes / 1024, suffixIndex + 1);
};

const MediaMessage:React.FC<MediaMessageProps> = ({ media }) => {
  const handleClick = () => {
    media.getContentTemporaryUrl().then(url => {
      const anchorEl = document.createElement('a');

      anchorEl.href = url!;
      anchorEl.target = '_blank';
      anchorEl.rel = 'noopener';

      // setTimeout is needed in order to open files in iOS Safari.
      setTimeout(() => {
        anchorEl.click();
      });
    });
  };

  return (
    <div className="message-attachment" onClick={handleClick}>
      <div className="file-preview">
        <IonIcon icon={documentAttachOutline} />
        <IonIcon className="download" icon={downloadOutline} />
      </div>
      <div className="file-info">
        <span>{`${media?.filename} - ${formatFileSize(media.size)}`}</span>
      </div>
    </div>
  );
};

MediaMessage.propTypes = {
  media: PropTypes.any.isRequired
};

export default MediaMessage;
