import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const EmailIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M16.9583 0.916504H5.04167C3.70498 0.91796 2.42346 1.4496 1.47828 2.39478C0.533097 3.33996 0.00145582 4.62148 0 5.95817L0 16.0415C0.00145582 17.3782 0.533097 18.6597 1.47828 19.6049C2.42346 20.5501 3.70498 21.0817 5.04167 21.0832H16.9583C18.295 21.0817 19.5765 20.5501 20.5217 19.6049C21.4669 18.6597 21.9985 17.3782 22 16.0415V5.95817C21.9985 4.62148 21.4669 3.33996 20.5217 2.39478C19.5765 1.4496 18.295 0.91796 16.9583 0.916504ZM16.9583 3.6665C17.5143 3.66835 18.0502 3.87417 18.4644 4.24492L12.5061 10.2033C12.1014 10.5898 11.5633 10.8055 11.0037 10.8055C10.444 10.8055 9.90594 10.5898 9.50125 10.2033L3.54292 4.24492C3.95528 3.87584 4.48827 3.67014 5.04167 3.6665H16.9583ZM16.9583 18.3332H5.04167C4.43388 18.3332 3.85098 18.0917 3.42121 17.662C2.99144 17.2322 2.75 16.6493 2.75 16.0415V7.34875L7.55242 12.1512C8.00503 12.604 8.54242 12.9632 9.13389 13.2082C9.72536 13.4533 10.3593 13.5795 10.9995 13.5795C11.6398 13.5795 12.2737 13.4533 12.8652 13.2082C13.4567 12.9632 13.9941 12.604 14.4467 12.1512L19.25 7.34875V16.0415C19.25 16.6493 19.0086 17.2322 18.5788 17.662C18.149 18.0917 17.5661 18.3332 16.9583 18.3332Z" fill={color} />
  </svg>
);

export default EmailIcon;
