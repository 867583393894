import React from 'react';
import { IonIcon } from '@ionic/react';
import './BandwidthWarning.scss';

export default function BandwidthWarning() {
  return (
    <div className="bandwidth-warning">
      <div>
        <IonIcon name="alert-circle-outline" />
      </div>
      <h3>Insufficient Bandwidth</h3>
    </div>
  );
}
