import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const HeartIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
    <path d="M15.8125 0.696777C14.8723 0.705873 13.9473 0.935055 13.1116 1.36596C12.2759 1.79687 11.5527 2.41752 11 3.17819C10.4473 2.41752 9.72418 1.79687 8.88847 1.36596C8.05276 0.935055 7.12774 0.705873 6.18753 0.696777C4.48415 0.7648 2.87701 1.50452 1.71751 2.7542C0.558016 4.00388 -0.0594858 5.66183 2.91588e-05 7.36553C2.91588e-05 11.5382 4.28453 16.0051 7.88336 19.0182C8.75725 19.753 9.86239 20.1559 11.0042 20.1559C12.1459 20.1559 13.2511 19.753 14.1249 19.0182C17.7201 16.0051 22.0083 11.5382 22.0083 7.36553C22.0678 5.66046 21.4491 4.0013 20.2879 2.75139C19.1266 1.50147 17.5174 0.762675 15.8125 0.696777ZM12.3539 16.9108C11.9748 17.23 11.4952 17.405 10.9996 17.405C10.504 17.405 10.0243 17.23 9.6452 16.9108C5.26353 13.2368 2.75003 9.75803 2.75003 7.36553C2.69158 6.39131 3.01979 5.43336 3.66338 4.69966C4.30697 3.96597 5.214 3.51576 6.18753 3.44678C7.16106 3.51576 8.06809 3.96597 8.71168 4.69966C9.35527 5.43336 9.68348 6.39131 9.62503 7.36553C9.62503 7.7302 9.7699 8.07994 10.0278 8.3378C10.2856 8.59566 10.6354 8.74053 11 8.74053C11.3647 8.74053 11.7144 8.59566 11.9723 8.3378C12.2302 8.07994 12.375 7.7302 12.375 7.36553C12.3166 6.39131 12.6448 5.43336 13.2884 4.69966C13.932 3.96597 14.839 3.51576 15.8125 3.44678C16.7861 3.51576 17.6931 3.96597 18.3367 4.69966C18.9803 5.43336 19.3085 6.39131 19.25 7.36553C19.25 9.75803 16.7365 13.2368 12.3539 16.9108Z" fill={color} />
  </svg>
);

export default HeartIcon;
