import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CoinsIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g clipPath="url(#clip0_901_943)">
      <path d="M15.125 0C11.2695 0 8.25 1.81225 8.25 4.125V6.49642C7.80633 6.44417 7.34708 6.41667 6.875 6.41667C3.0195 6.41667 0 8.22892 0 10.5417V17.875C0 20.1877 3.0195 22 6.875 22C10.0063 22 12.5868 20.8047 13.4447 19.1162C13.992 19.1959 14.5512 19.25 15.125 19.25C18.9805 19.25 22 17.4377 22 15.125V4.125C22 1.81225 18.9805 0 15.125 0ZM15.125 1.83333C18.0107 1.83333 20.1667 3.04333 20.1667 4.125C20.1667 5.20667 18.0107 6.41667 15.125 6.41667C12.2393 6.41667 10.0833 5.20667 10.0833 4.125C10.0833 3.04333 12.2393 1.83333 15.125 1.83333ZM6.875 8.25C9.76067 8.25 11.9167 9.46 11.9167 10.5417C11.9167 11.6233 9.76067 12.8333 6.875 12.8333C3.98933 12.8333 1.83333 11.6233 1.83333 10.5417C1.83333 9.46 3.98933 8.25 6.875 8.25ZM1.83333 13.3668C3.08 14.1689 4.86108 14.6667 6.875 14.6667C8.88892 14.6667 10.67 14.1689 11.9167 13.3668V14.2083C11.9167 15.29 9.76067 16.5 6.875 16.5C3.98933 16.5 1.83333 15.29 1.83333 14.2083V13.3668ZM6.875 20.1667C3.98933 20.1667 1.83333 18.9567 1.83333 17.875V17.0335C3.08 17.8356 4.86108 18.3333 6.875 18.3333C8.88892 18.3333 10.67 17.8356 11.9167 17.0335V17.875C11.9167 18.9567 9.76067 20.1667 6.875 20.1667ZM15.125 17.4167C14.6538 17.4167 14.1973 17.3736 13.75 17.3103V15.4953C14.201 15.5485 14.6575 15.5833 15.125 15.5833C17.1389 15.5833 18.92 15.0856 20.1667 14.2835V15.125C20.1667 16.2067 18.0107 17.4167 15.125 17.4167ZM15.125 13.75C14.6538 13.75 14.1973 13.7069 13.75 13.6437V11.8287C14.201 11.8818 14.6575 11.9167 15.125 11.9167C17.1389 11.9167 18.92 11.4189 20.1667 10.6168V11.4583C20.1667 12.54 18.0107 13.75 15.125 13.75ZM15.125 10.0833C14.6282 10.0833 14.1478 10.0366 13.6785 9.96692C13.497 9.15383 12.9369 8.42325 12.1009 7.83933C13.0103 8.10058 14.0314 8.25092 15.125 8.25092C17.1389 8.25092 18.92 7.75317 20.1667 6.95108V7.79258C20.1667 8.87425 18.0107 10.0843 15.125 10.0843V10.0833Z" fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_901_943">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CoinsIcon;
