import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  IonContent,
  IonList,
  IonListHeader,
  IonImg,
  IonLabel,
  IonLoading,
} from '@ionic/react';

import connect from '../../data/connect';
import { fetchFavoriteProviders, deleteFavoriteProvider } from '../../data/dataApi';
import UserItem from '../../components/UserItem';
import { User } from '../../models';
import emptyImg from '../../assets/empty.png';

import './Favorites.scss';

interface StateProps {
  activeUser: User;
}

type FavoritesProps = StateProps;

const Empty = () => (
  <div className="empty">
    <div>
      <IonImg src={emptyImg} alt="empty" />
      <IonLabel className="title">Nothing found</IonLabel>
      <IonLabel>Try searching again, or add someone to your favorites</IonLabel>
    </div>
  </div>
);

const Favorites: React.FC<FavoritesProps> = ({
  activeUser
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [providers, setProviders] = useState<User[]>([]);

  const onRemoveFavorite = async (id: number, provider: User) => {
    try {
      await deleteFavoriteProvider(id, provider.id);
      const updatedProviders = providers.filter((pv) => pv.id !== provider.id);
      setProviders([...updatedProviders]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      fetchFavoriteProviders(activeUser.id)
        .then((res) => {
          if (res.success) {
            setProviders(res.providers);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    };

    getData();
  }, []);

  return (
    <IonContent id="favorites-list" className="page-content ion-padding">
      <IonListHeader>My Favorites</IonListHeader>
      {providers && providers.length ? (
        <IonList>
          {providers.map((provider) => (
            <UserItem
              key={provider.id}
              user={{ ...provider, isFavorite: 1 }}
              addFavoriteProvider={() => {}}
              deleteFavoriteProvider={onRemoveFavorite}
              onClick={() => history.push(`/${activeUser.role}/provider/${provider.id}`)}
            />
          ))}
        </IonList>
      ) : <Empty />}
      <IonLoading isOpen={isLoading} />
    </IonContent>
  );
};

Favorites.propTypes = {
  activeUser: PropTypes.any.isRequired
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state, OwnProps) => ({
    activeUser: state.auth.user
  }),
  component: React.memo(Favorites)
});
