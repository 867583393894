import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonRange,
  IonRippleEffect,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonSpinner
} from '@ionic/react';
import {
  checkmarkCircle,
  closeOutline,
  ellipsisHorizontal,
  helpCircle,
  options,
  searchOutline
} from 'ionicons/icons';
import { Range } from 'react-date-range';
import { Country, State, City } from 'country-state-city';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import clsx from 'clsx';
import moment from 'moment';

import {
  activateAccount,
  fetchProviders,
  inactivateAccount
} from '../../data/dataApi';
import connect from '../../data/connect';
import {
  User, UserStatus, ProviderType, VerificationStatus, Language, Specialist
} from '../../models';
import Avatar from '../../components/Avatar';
import StarRating from '../../components/StarRating';
import {
  ArchiveIcon,
  ActiveIcon,
  BlockActionIcon,
  DetailsIcon,
  InactiveIcon,
  TrashIcon,
  UnblockActionIcon,
  StarIcon
} from '../../icons';

interface UserActionsProps {
  user: User
  updateUser: (user: User) => void
  deleteUser: (id: number) => void
}

const UserActions: React.FC<UserActionsProps> = ({
  user, updateUser, deleteUser
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [showInactiveAlert, setShowInactiveAlert] = useState(false);

  const onInactivate = () => {
    setIsLoading(true);
    inactivateAccount(user.id, false)
      .then((res) => {
        if (res.success) {
          updateUser?.({ ...user, status: UserStatus.INACTIVE });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onActivate = () => {
    setIsLoading(true);
    activateAccount(user.id)
      .then((res) => {
        if (res.success) {
          updateUser?.({ ...user, status: UserStatus.ACTIVE });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onRemove = () => {
    setIsLoading(true);
    inactivateAccount(user.id, true)
      .then((res) => {
        if (res.success) {
          deleteUser?.(user.id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onShowDetails = () => {
    history.push(`/admin/report/user/${user.id}`);
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'top', 'left', 'right']}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="#daf3ef"
            arrowSize={10}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className="popover-content">
              {user.status === UserStatus.ACTIVE ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowInactiveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel>
                      <InactiveIcon />
                      Inactivate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.INACTIVE ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={onActivate}>
                    <IonLabel>
                      <ActiveIcon />
                      Activate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.BLOCKED ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <UnblockActionIcon />
                      Unblock
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.ARCHIVED ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={onActivate}>
                    <IonLabel>
                      <ActiveIcon />
                      Activate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.PENDING ? (
                <>
                  {/* <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div> */}
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
            </div>
          </ArrowContainer>
        )}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        {isLoading ? <IonSpinner /> : (
          <span
            className="action"
            title="More actions"
            onClick={(e) => {
              e.stopPropagation();
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            <IonIcon icon={ellipsisHorizontal} />
          </span>
        )}
      </Popover>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={(): void => setShowRemoveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to delete this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onRemove()
          }
        ]}
      />

      <IonAlert
        isOpen={showInactiveAlert}
        onDidDismiss={(): void => setShowInactiveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to inactivate this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onInactivate()
          }
        ]}
      />
    </>
  );
};

UserActions.propTypes = {
  user: PropTypes.any.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired
};

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

type RangeType = {
  min: number
  max: number
};

type FilterParams = {
  country: string
  province: string
  city: string
  bookings: RangeType
  ratings: RangeType
  providerType: number
  specialists: number[]
  languages: number[]
};

interface ProvidersViewProps {
  dateRange: Range | null
}

interface StateProps {
  providerTypes: ProviderType[]
  languages: Language[]
  specialists: Specialist[]
}

const ProvidersView: React.FC<ProvidersViewProps & StateProps> = ({
  providerTypes, languages, specialists, dateRange
}) => {
  const [segment, setSegment] = useState<'all' | 'active' | 'inactive' | 'archive' | 'blocked'>('all');
  const [searchKey, setSearchKey] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [filterParams, setFilterParams] = useState<FilterParams>({
    country: '',
    province: '',
    city: '',
    bookings: {
      min: 0,
      max: 500
    },
    ratings: {
      min: 0,
      max: 5
    },
    providerType: 0,
    specialists: [],
    languages: []
  });
  const [tempFilterParams, setTempFilterParams] = useState<FilterParams>({
    country: '',
    province: '',
    city: '',
    bookings: {
      min: 0,
      max: 500
    },
    ratings: {
      min: 0,
      max: 5
    },
    providerType: 0,
    specialists: [],
    languages: []
  });
  const [providers, setProviders] = useState<User[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [showTooltipPopover, setShowTooltipPopover] = useState(false);

  const fetchMore = (isNew: boolean) => {
    const params = {
      skip: isNew ? 0 : providers.length,
      status: segment,
      searchKey,
      hasBookingsInfo: true,
      dateRange: dateRange ? [
        moment(dateRange.startDate).toISOString(),
        moment(dateRange.endDate).toISOString()
      ] : null,
      country: filterParams.country,
      state: filterParams.province,
      city: filterParams.city,
      providerType: filterParams.providerType,
      specialists: filterParams.specialists,
      languages: filterParams.languages,
      bookingsCntRange: filterParams.bookings,
      ratingRange: filterParams.ratings,
      orderBy,
      isDescending
    };

    setIsFetching(true);
    fetchProviders(params)
      .then((res) => {
        if (res.success) {
          if (isNew) {
            setProviders([...res.providers]);
          } else {
            setProviders([...providers, ...res.providers]);
          }
          if (!res.providers.length || res.providers.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsFetching(false));
  };

  const onSearch = () => {
    fetchMore(true);
  };

  const onOpenFilter = () => {
    setShowFilter(true);
    setTempFilterParams(filterParams);
  };

  const onDismissFilter = () => {
    setShowFilter(false);
  };

  const onClear = () => {
    setFilterParams({
      country: '',
      province: '',
      city: '',
      bookings: {
        min: 0,
        max: 500
      },
      ratings: {
        min: 0,
        max: 5
      },
      providerType: 0,
      specialists: [],
      languages: []
    });
    setShowFilter(false);
  };

  const onApplyFilter = () => {
    setFilterParams(tempFilterParams);
    setShowFilter(false);
  };

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const onDeleteUser = (id: number) => {
    const updatedProviders = providers.filter((c) => c.id !== id);
    setProviders([...updatedProviders]);
  };

  const onUpdateUser = (user: User) => {
    if (segment === 'all') {
      const updatedProviders = providers.map((c) => {
        if (c.id === user.id) {
          return user;
        }
        return c;
      });
      setProviders([...updatedProviders]);
    } else {
      onDeleteUser(user.id);
    }
  };

  const providerTypeName = (type: number) => {
    const item = providerTypes.find((pt) => pt.id === type);
    return item?.name || '';
  };

  const onClickSpecialist = (id: number): void => {
    if (tempFilterParams.specialists.includes(id)) {
      setTempFilterParams({
        ...tempFilterParams,
        specialists: tempFilterParams.specialists.filter((spec) => spec !== id)
      });
    } else {
      setTempFilterParams({
        ...tempFilterParams,
        specialists: [...tempFilterParams.specialists, id]
      });
    }
  };

  const onClickLanguage = (id: number): void => {
    if (tempFilterParams.languages.includes(id)) {
      setTempFilterParams({
        ...tempFilterParams,
        languages: tempFilterParams.languages.filter((lng) => lng !== id)
      });
    } else {
      setTempFilterParams({
        ...tempFilterParams,
        languages: [...tempFilterParams.languages, id]
      });
    }
  };

  const isSubscribedProvider = (provider: User) => {
    if (provider.subscription && ['active', 'paused'].includes(provider.subscription.status)) {
      return true;
    }
    return false;
  };

  const availableStates = useMemo(() => {
    if (tempFilterParams.country) {
      const country = Country.getAllCountries().find((c) => c.name === tempFilterParams.country);
      const states = State.getStatesOfCountry(country?.isoCode);
      return states || [];
    }
    return [];
  }, [tempFilterParams.country]);

  const availableCities = useMemo(() => {
    if (tempFilterParams.country && tempFilterParams.province) {
      const country = Country.getAllCountries().find((c) => c.name === tempFilterParams.country);
      const state = State.getStatesOfCountry(country?.isoCode).find((s) => s.name === tempFilterParams.province);
      if (country && state) {
        const cities = City.getCitiesOfState(country.isoCode, state.isoCode);
        return cities;
      }
    }
    return [];
  }, [tempFilterParams.country, tempFilterParams.province]);

  useEffect(() => {
    fetchMore(true);
  }, [dateRange, segment, orderBy, isDescending, filterParams]);

  useEffect(() => {
    if (!searchKey) {
      fetchMore(true);
    }
  }, [searchKey]);

  return (
    <div className="admin-report-users">
      <div className="sub-header">
        <IonSegment
          mode="ios"
          value={segment}
          onIonChange={(e) => setSegment(e.detail.value as 'all' | 'active' | 'inactive' | 'archive' | 'blocked')}
        >
          <IonSegmentButton value="all">All</IonSegmentButton>
          <IonSegmentButton value="active">Active</IonSegmentButton>
          <IonSegmentButton value="inactive">Inactive</IonSegmentButton>
          <IonSegmentButton value="archive">Archive</IonSegmentButton>
          <IonSegmentButton value="blocked">Blocked</IonSegmentButton>
        </IonSegment>
        <Popover
          isOpen={showTooltipPopover}
          positions={['bottom', 'top', 'left', 'right']}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor="#daf3ef"
              arrowSize={10}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              <div className="popover-content tooltip sm">
                <div
                  className="status-option live"
                  onClick={() => {
                    setSegment('all');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    All
                  </p>
                  <span>
                    All Providers
                  </span>
                </div>
                <div
                  className="status-option completed"
                  onClick={() => {
                    setSegment('active');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Active
                  </p>
                  <span>
                    Providers Who are active now
                  </span>
                </div>
                <div
                  className="status-option inactive"
                  onClick={() => {
                    setSegment('inactive');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Inactive
                  </p>
                  <span>
                    Providers Who are inactive now
                  </span>
                </div>
                <div
                  className="status-option archive"
                  onClick={() => {
                    setSegment('archive');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Archive
                  </p>
                  <span>
                    Providers Who are archived now
                  </span>
                </div>
                <div
                  className="status-option abandoned"
                  onClick={() => {
                    setSegment('blocked');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Blocked
                  </p>
                  <span>
                    Providers Who are blocked now
                  </span>
                </div>
              </div>
            </ArrowContainer>
          )}
          onClickOutside={() => setShowTooltipPopover(false)}
        >
          <span
            className="help-icon"
            onClick={() => setShowTooltipPopover(true)}
          >
            <IonIcon color="favorite" icon={helpCircle} />
          </span>
        </Popover>
        <IonItem className="search-input" lines="none">
          <IonInput
            value={searchKey}
            placeholder="Search by name"
            onKeyPress={(e) => {
              if (e.keyCode === 13 || e.key === 'Enter') {
                onSearch();
              }
            }}
            onIonChange={(e) => {
              setSearchKey(e.detail.value ?? '');
            }}
          />
          <IonIcon size="small" icon={searchOutline} onClick={() => onSearch()} />
          <IonIcon size="small" icon={closeOutline} onClick={() => setSearchKey('')} />
        </IonItem>
        <IonButton color="dark" fill="outline" onClick={onOpenFilter}>
          <IonIcon icon={options} />
          Filter
        </IonButton>
      </div>
      <div className="user-list">
        <IonGrid>
          <IonRow className="table-header">
            <IonCol className="sm">NO</IonCol>
            <IonCol
              className={clsx('cursor-pointer sm', { isOrderBy: orderBy === 'id' })}
              onClick={() => onChangeOrderBy('id')}
            >
              ID&nbsp;
              {orderBy === 'id' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              size="2.5"
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'name' })}
              onClick={() => onChangeOrderBy('name')}
            >
              NAME AND EMAIL&nbsp;
              {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>CONTACT NO</IonCol>
            <IonCol>SPECIALITY</IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'country' })}
              onClick={() => onChangeOrderBy('country')}
            >
              COUNTRY&nbsp;
              {orderBy === 'country' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'state' })}
              onClick={() => onChangeOrderBy('state')}
            >
              PROVINCE&nbsp;
              {orderBy === 'state' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'city' })}
              onClick={() => onChangeOrderBy('city')}
            >
              CITY&nbsp;
              {orderBy === 'city' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'rating' })}
              onClick={() => onChangeOrderBy('rating')}
            >
              RATINGS&nbsp;
              {orderBy === 'rating' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'bookingsCnt' })}
              onClick={() => onChangeOrderBy('bookingsCnt')}
            >
              BOOKINGS&nbsp;
              {orderBy === 'bookingsCnt' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol size="1.5">LICENSE AND ID VERIFIED</IonCol>
            <IonCol>STATUS</IonCol>
            <IonCol>ACTION</IonCol>
          </IonRow>
          {providers.map((provider, index) => (
            <IonRow key={provider.id} className="table-row">
              <IonCol className="sm">{index + 1}</IonCol>
              <IonCol className="sm">{provider.id}</IonCol>
              <IonCol size="2.5">
                <div className="user-name-email">
                  <Avatar user={provider} />
                  <div>
                    <h4>
                      {`${provider.name} ${provider.lastName ?? ''}`}
                      {isSubscribedProvider(provider) ? <span className="subscribe-badge">Subscribed</span> : null}
                    </h4>
                    <p>{provider.email}</p>
                  </div>
                </div>
              </IonCol>
              <IonCol>{provider.phone}</IonCol>
              <IonCol>{providerTypeName(provider.type)}</IonCol>
              <IonCol>{provider.locations?.[0]?.country}</IonCol>
              <IonCol>{provider.locations?.[0]?.state}</IonCol>
              <IonCol>{provider.locations?.[0]?.city}</IonCol>
              <IonCol>
                <StarRating hasOneStar readonly rating={(provider.rating || 0) / 5} />
                {(provider.rating || 0).toFixed(1)}
              </IonCol>
              <IonCol>{provider?.bookingsCnt || 0}</IonCol>
              <IonCol size="1.5">
                {
                  provider.licenses && provider.licenses.find((license) => license.status === VerificationStatus.VERIFIED && `${license.type}s` === providerTypeName(provider.type))
                    ? <IonIcon icon={checkmarkCircle} color="danger" />
                    : <IonIcon icon={checkmarkCircle} color="medium" />
                }
                {
                  provider.identity && provider.identity.status === VerificationStatus.VERIFIED
                    ? <IonIcon icon={checkmarkCircle} color="favorite" />
                    : <IonIcon icon={checkmarkCircle} color="medium" />
                }
              </IonCol>
              <IonCol>
                {provider.status === UserStatus.ACTIVE ? (
                  <div className="status active">Active</div>
                ) : null}
                {provider.status === UserStatus.INACTIVE ? (
                  <div className="status inactive">Inactive</div>
                ) : null}
                {provider.status === UserStatus.BLOCKED ? (
                  <div className="status blocked">Blocked</div>
                ) : null}
                {provider.status === UserStatus.PENDING ? (
                  <div className="status inactive">Pending</div>
                ) : null}
              </IonCol>
              <IonCol>
                <UserActions user={provider} updateUser={onUpdateUser} deleteUser={onDeleteUser} />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
        {isFetchedAll ? null : (
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              fetchMore(false);
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent
              loadingText="Fetching more..."
              loadingSpinner="circles"
            />
          </IonInfiniteScroll>
        )}
      </div>

      <IonLoading isOpen={isFetching} />

      <IonModal
        cssClass="users-filter-modal"
        isOpen={showFilter}
        onDidDismiss={onDismissFilter}
      >
        <div className="modal-header">
          <h2>Filter Providers</h2>
          <IonButton color="favorite" fill="clear" onClick={onClear}>Clear All</IonButton>
        </div>
        <div className="setting-group">
          <div className="setting">
            <div className="header">Country</div>
            <div className="content">
              <IonItem lines="none" className="select">
                <IonSelect
                  className="select"
                  placeholder="Select Country..."
                  value={tempFilterParams.country}
                  onIonChange={(e): void => setTempFilterParams({ ...tempFilterParams, country: String(e.detail.value) })}
                >
                  <IonSelectOption value="United States">United States</IonSelectOption>
                  <IonSelectOption value="Canada">Canada</IonSelectOption>
                </IonSelect>
              </IonItem>
            </div>
          </div>
          <div className="setting">
            <div className="header">Province</div>
            <div className="content">
              <IonItem lines="none" className="select">
                <IonSelect
                  className="select"
                  placeholder="Select State..."
                  value={tempFilterParams.province}
                  disabled={!tempFilterParams.country}
                  onIonChange={(e): void => setTempFilterParams({ ...tempFilterParams, province: String(e.detail.value) })}
                >
                  {availableStates.map((state) => (
                    <IonSelectOption key={state.name} value={state.name}>{state.name}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </div>
          </div>
        </div>
        <div className="setting-group">
          <div className="setting">
            <div className="header">City</div>
            <div className="content">
              <IonItem lines="none" className="select">
                <IonSelect
                  className="select"
                  placeholder="Select City..."
                  value={tempFilterParams.city}
                  disabled={!tempFilterParams.country || !tempFilterParams.province}
                  onIonChange={(e): void => setTempFilterParams({ ...tempFilterParams, city: String(e.detail.value) })}
                >
                  {availableCities.map((city) => (
                    <IonSelectOption key={city.name} value={city.name}>{city.name}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </div>
          </div>
          <div className="setting">
            <div className="header">Provider Types</div>
            <div className="content">
              <IonItem lines="none" className="select">
                <IonSelect
                  className="select"
                  placeholder="Select Provider Type..."
                  value={tempFilterParams.providerType}
                  onIonChange={(e): void => {
                    setTempFilterParams({ ...tempFilterParams, providerType: Number(e.detail.value), specialists: [] });
                  }}
                >
                  <IonSelectOption key="0" value={0}>All Provider Types</IonSelectOption>
                  {providerTypes.map((pt) => (
                    <IonSelectOption key={pt.id} value={pt.id}>{pt.name}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </div>
          </div>
        </div>
        <div className="setting-group">
          <div className="setting">
            <div className="header">Specialty</div>
            <div className="content">
              {specialists
                .map((item, index) => (
                  <div
                    key={index}
                    className={clsx('ion-activatable toggle', {
                      active: tempFilterParams.specialists.includes(item.id),
                      disabled: item.type !== tempFilterParams.providerType
                    })}
                    onClick={(): void => onClickSpecialist(item.id)}
                  >
                    {item.name}
                    <IonRippleEffect />
                  </div>
                ))}
            </div>
          </div>
          <div className="setting">
            <div className="header">Language Skills</div>
            <div className="content">
              {languages.map((item, index) => (
                <div
                  key={index}
                  className={`ion-activatable toggle${
                    tempFilterParams.languages.includes(item.id) ? ' active' : ''
                  }`}
                  onClick={(): void => onClickLanguage(item.id)}
                >
                  {item.name}
                  <IonRippleEffect />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="setting-group">
          <div className="setting">
            <div className="header">
              Number of booking
              <div className="range">
                {`${tempFilterParams.bookings.min} - ${tempFilterParams.bookings.max}`}
              </div>
            </div>
            <div className="content">
              <IonRange
                mode="ios"
                min={0}
                max={500}
                step={1}
                dualKnobs
                ticks
                value={{
                  lower: tempFilterParams.bookings.min,
                  upper: tempFilterParams.bookings.max
                }}
                onIonChange={(e: CustomEvent<any>) => setTempFilterParams({
                  ...tempFilterParams,
                  bookings: { min: e.detail.value.lower, max: e.detail.value.upper }
                })}
              />
            </div>
            <div className="range-mark">
              <span>0</span>
              <span>500</span>
            </div>
          </div>
          <div className="setting">
            <div className="header">
              Rating
              <div className="range">
                <StarIcon />
                {`${tempFilterParams.ratings.min.toFixed(1)} - ${tempFilterParams.ratings.max.toFixed(1)}`}
              </div>
            </div>
            <div className="content">
              <IonRange
                mode="ios"
                min={0}
                max={5}
                step={0.1}
                dualKnobs
                ticks
                value={{
                  lower: tempFilterParams.ratings.min,
                  upper: tempFilterParams.ratings.max
                }}
                onIonChange={(e: CustomEvent<any>) => setTempFilterParams({
                  ...tempFilterParams,
                  ratings: { min: Number(Number(e.detail.value.lower).toFixed(1)), max: Number(Number(e.detail.value.upper).toFixed(1)) }
                })}
              />
            </div>
            <div className="range-mark">
              <span>0</span>
              <span>5</span>
            </div>
          </div>
        </div>
        <div className="btn-apply">
          <IonButton color="favorite" expand="block" onClick={onApplyFilter}>
            Apply
          </IonButton>
        </div>
      </IonModal>
    </div>
  );
};

ProvidersView.defaultProps = {
  dateRange: null
};

ProvidersView.propTypes = {
  dateRange: PropTypes.any,
  providerTypes: PropTypes.array.isRequired,
  specialists: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired
};

export default connect<ProvidersViewProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes,
    languages: state.data.allLanguages,
    specialists: state.data.allSpecialists
  }),
  component: React.memo(ProvidersView)
});
