import React, {
  useCallback, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonList,
  IonIcon,
  IonLoading
} from '@ionic/react';
import {
  chevronBackOutline,
  chevronForwardOutline
} from 'ionicons/icons';
import Calendar from 'rc-calendar';
import 'rc-calendar/assets/index.css';
import moment, { Moment } from 'moment';

import connect from '../../../data/connect';
import { Location, User, UserRole } from '../../../models';
import {
  fetchTimeslotsByDate,
  createTimeslot,
  enableTimeslot,
  updateProviderProfile
} from '../../../data/dataApi';
import { dispatchUpdateUser } from '../../../data/store/auth/auth.actions';

interface Slot {
  slot: number
  status: string
  type: string
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

interface FifthStepProps {
  user: User
  next: () => void
  prev: () => void
}

const FifthStep: React.FC<FifthStepProps & DispatchProps> = ({
  user, next, prev, updateUser
}) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [slots, setSlots] = useState([] as Slot[]);
  const [location, setLocation] = useState({} as Location);
  const [isLoading, setIsLoading] = useState(false);

  const onSelectDate = useCallback(
    async (date: Moment) => {
      setCurrentDate(date);
      const response: { success: boolean, slots: Slot[] } = await fetchTimeslotsByDate(
        user.id,
        location.id,
        moment(date.format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm')
      );
      if (response.success) {
        setSlots(response.slots);
      }
    },
    [user.id, location]
  );

  useEffect(() => {
    if (user.locations.length) {
      setLocation(user.locations[0]);
    }
  }, [user]);

  const slotToMoment = (slot: number): Moment => moment({
    hour: slot / 4,
    minute: (slot % 4) * 15
  });

  const onDisableTimeslot = (slot: number): void => {
    createTimeslot({
      user: user.id,
      startDay: currentDate.utc().format('YYYY-MM-DD'),
      location: location.id,
      start: slotToMoment(slot).utc().format('HH:mm'),
      end: slotToMoment(slot + 1).utc().format('HH:mm')
    })
      .then((res: { success: boolean }) => {
        if (res.success) {
          setSlots(
            slots.map((item) => (item.slot === slot ? { ...item, status: 'disabled' } : item))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const onEnableTimeslot = (slot: number): void => {
    enableTimeslot(
      user.id,
      currentDate.utc().format('YYYY-MM-DD'),
      slotToMoment(slot).utc().format('HH:mm')
    )
      .then((res: { success: boolean }) => {
        if (res.success) {
          setSlots(
            slots.map((item) => (item.slot === slot ? { ...item, status: 'enabled' } : item))
          );
        }
      })
      .catch((error) => console.log(error));
  };

  const onSubmit = () => {
    setIsLoading(true);
    const nextStep = user.role === UserRole.PROVIDER ? 5 : 6;
    updateProviderProfile(user.id, {
      profile: {
        onboardingStep: user.onboardingStep > nextStep ? user.onboardingStep : nextStep
      },
      languages: user.languages.map((language) => language.id),
      specialists: user.specialists.map((specialist) => specialist.id),
      locations: user.locations,
      services: user.services
    })
      .then((res: { success: boolean, user: User }) => {
        if (res.success) {
          updateUser(res.user);
          next();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const renderSlot = (item: Slot): JSX.Element => {
    if (item.status === 'closed') {
      return (
        <IonButton
          className="round-border"
          fill="outline"
          expand="block"
          // eslint-disable-next-line no-nested-ternary
          color={item.type === 'video-visit' ? 'facebook' : item.type === 'provider-visit' ? 'primary' : 'workshop'}
          disabled
        >
          <div className="slot-button">
            {slotToMoment(item.slot).format('h:mm A')}
          </div>
        </IonButton>
      );
    }
    if (item.status === 'enabled') {
      return (
        <IonButton
          className="round-border"
          fill="solid"
          expand="block"
          // eslint-disable-next-line no-nested-ternary
          color={item.type === 'video-visit' ? 'facebook' : item.type === 'provider-visit' ? 'primary' : 'workshop'}
          disabled={false}
          onClick={(): void => onDisableTimeslot(item.slot)}
        >
          {slotToMoment(item.slot).format('h:mm A')}
        </IonButton>
      );
    }
    if (item.status === 'disabled') {
      return (
        <IonButton
          className="round-border"
          fill="outline"
          expand="block"
          // eslint-disable-next-line no-nested-ternary
          color={item.type === 'video-visit' ? 'facebook' : item.type === 'provider-visit' ? 'primary' : 'workshop'}
          onClick={(): void => onEnableTimeslot(item.slot)}
        >
          <div className="slot-button">
            {slotToMoment(item.slot).format('h:mm A')}
          </div>
        </IonButton>
      );
    }
    return <></>;
  };

  return (
    <div id="step-five">
      <IonGrid className="step-content">
        <IonRow>
          <IonCol>
            <IonList>
              <IonItem lines="none" className="select">
                <IonSelect
                  className="select"
                  placeholder="Select your location"
                  value={location}
                  onIonChange={(e): void => {
                    setLocation(e.detail.value);
                    setSlots([]);
                  }}
                >
                  {user.locations.map((item) => (
                    <IonSelectOption key={item.id} value={item}>
                      {item.address}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <br />
              <Calendar
                className="calendar"
                showDateInput={false}
                showToday={false}
                onSelect={(date): Promise<void> => onSelectDate(date)}
                disabledDate={(current): boolean => moment(moment().format('MM-DD-YYYY')).isSameOrAfter(current)}
              />
              <br />
              <IonItem lines="none" className="input">
                {slots.length === 0 && 'No available slots'}
                <IonGrid className="slot-table">
                  <IonRow>
                    {slots.map((item: Slot) => (
                      <IonCol key={item.slot} size="3">
                        {renderSlot(item)}
                      </IonCol>
                    ))}
                  </IonRow>
                </IonGrid>
              </IonItem>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className="actions">
        <IonButton color="favorite" onClick={prev}>
          <IonIcon icon={chevronBackOutline} />
          Back
        </IonButton>
        <IonButton color="favorite" onClick={onSubmit}>
          Next
          <IonIcon icon={chevronForwardOutline} />
        </IonButton>
      </div>
      <IonLoading isOpen={isLoading} />
    </div>
  );
};

FifthStep.propTypes = {
  user: PropTypes.any.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<FifthStepProps, {}, {}>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(FifthStep)
});
