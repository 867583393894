import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Message } from '@twilio/conversations';
import moment from 'moment';

import TextMessage from './TextMessage';
import MediaMessage from './MediaMessage';
import ChatBotMessage from './ChatBotMessage';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

interface MessageListProps {
  messages: Message[];
}

const MessageList: React.FC<MessageListProps> = ({ messages }) => {
  const { room } = useVideoContext();

  useEffect(() => {
    const element = document.getElementById('messages');
    setTimeout(() => {
      element?.scrollTo({ left: 0, top: element?.scrollHeight, behavior: 'smooth' });
    }, 100);
  }, [messages]);

  return (
    <div id="messages" className="message-list">
      {messages.map((message, index) => {
        const isLocalParticipant = room.localParticipant?.identity === message.author;
        return (
          <>
            {message.author !== 'CarePlatform' ? (
              <div className="message-author-info">
                <span>{message.author}</span>
                <span>{moment(message.dateCreated).format('HH:mm')}</span>
              </div>
            ) : null}
            {message.type === 'text' && message.author !== 'CarePlatform' ? (
              <TextMessage body={message.body!} isLocalParticipant={isLocalParticipant} />
            ) : null}
            {message.type === 'media' ? <MediaMessage media={message.attachedMedia![0]} /> : null}
            {message.author === 'CarePlatform' ? <ChatBotMessage body={message.body!} date={moment(message.dateCreated).local().format('HH:mm')} /> : null}
          </>
        );
      })}
    </div>
  );
};

MessageList.propTypes = {
  messages: PropTypes.any.isRequired
};

export default MessageList;
