import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const OutIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M6.66786 17.5H2.84204C2.65399 17.4995 2.50165 17.3472 2.50122 17.1592V2.84082C2.50169 2.65277 2.65399 2.50043 2.84204 2.5H6.66786C7.35821 2.5 7.91786 1.94035 7.91786 1.25C7.91786 0.559648 7.35821 0 6.66786 0H2.84204C1.27384 0.00183594 0.00305664 1.27266 0.0012207 2.84082V17.1591C0.00305664 18.7273 1.27384 19.9982 2.84204 20H6.66786C7.35821 20 7.91786 19.4404 7.91786 18.75C7.91786 18.0596 7.35821 17.5 6.66786 17.5Z" fill={color} />
    <path d="M18.9028 7.34832L15.6678 4.11582C15.1795 3.62773 14.388 3.62792 13.8999 4.11625C13.4118 4.60457 13.412 5.39609 13.9003 5.88417L16.7437 8.72668L5.41785 8.75C4.7275 8.75 4.16785 9.30964 4.16785 10C4.16785 10.6903 4.7275 11.25 5.41785 11.25L16.7895 11.2292L13.9003 14.1184C13.43 14.6237 13.4583 15.4146 13.9635 15.885C14.4438 16.3321 15.188 16.3317 15.6678 15.8842L18.9003 12.6517C20.3648 11.1872 20.3648 8.81285 18.9004 7.34839L18.9003 7.34835L18.9028 7.34832Z" fill={color} />
  </svg>
);

export default OutIcon;
