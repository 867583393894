import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonBackButton,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonSelect,
  IonSelectOption,
  IonAlert
} from '@ionic/react';
import Calendar from 'rc-calendar';
import moment, { Moment } from 'moment';

import connect from '../../data/connect';
import {
  User, Service, Location, ServiceItem
} from '../../models';
import { fetchProviderTimeslots, fetchClinicMembers } from '../../data/dataApi';
import * as selectors from '../../data/selectors';
import './ClinicSchedule.scss';

interface StateProps {
  clinic?: User
}

type ClinicScheduleProps = RouteComponentProps & StateProps;

const ProviderSchedule: React.FC<ClinicScheduleProps> = ({
  history,
  clinic
}) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [slots, setSlots] = useState([] as number[]);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [selectedServiceItem, setSelectedServiceItem] = useState<ServiceItem | null>(null);
  const [location, setLocation] = useState<Location | null>();
  const [alert, setAlert] = useState(false);

  const [serviceName, setServiceName] = useState('');
  const [allMembers, setAllMembers] = useState([] as User[]);
  const [members, setMembers] = useState([] as User[]);
  const [provider, setProvider] = useState<User | null>({} as User);

  const onSelectDate = useCallback(
    async (date: Moment) => {
      if (!provider || !location) {
        setAlert(true);
        return;
      }
      setCurrentDate(date);
      const data = await fetchProviderTimeslots(
        provider.id,
        location.id,
        date.format('YYYY-MM-DD')
      );
      if (data.success) {
        setSlots(data.slots);
      }
    },
    [provider, location]
  );

  useEffect(() => {
    if (clinic) {
      fetchClinicMembers(clinic.id)
        .then((res) => {
          if (res.success) {
            setAllMembers(res.members);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [clinic]);

  useEffect(() => {
    const availableMembers = allMembers.filter((member) => member.services.find((service) => service.name === serviceName));
    setMembers(availableMembers);
    if (availableMembers.length) {
      setProvider(availableMembers[0]);
    } else {
      setProvider(null);
    }
  }, [serviceName]);

  useEffect(() => {
    if (provider?.services) {
      const service = provider.services.find(
        (item) => item.name === serviceName
      );
      if (service) {
        setSelectedService(service);
        if (service.items?.length) {
          setSelectedServiceItem(service.items[0]);
        }
      }
    } else {
      setSelectedService(null);
      setSelectedServiceItem(null);
    }

    if (provider?.locations) {
      if (provider.locations.length) {
        setLocation(provider.locations[0]);
      }
    } else {
      setLocation(null);
    }
  }, [provider]);

  const renderService = (service: Service): JSX.Element => (
    <IonItem key={service.id}>
      <IonRadio slot="start" value={service} />
      <IonLabel>{service.name}</IonLabel>
      {selectedService && selectedService.id === service.id && (
        <IonSelect
          slot="end"
          value={selectedServiceItem}
          okText="Select"
          cancelText="Cancel"
          onIonChange={(e): void => {
            e.stopPropagation();
            setSelectedServiceItem(e.detail.value);
          }}
        >
          {service.items!.map((item) => (
            <IonSelectOption key={item.id} value={item}>
              {`${item.duration}min - $${item.price.toFixed(1)}`}
            </IonSelectOption>
          ))}
        </IonSelect>
      )}
    </IonItem>
  );

  const onConfirm = (slot: number): void => {
    if (provider && selectedService && selectedServiceItem && location) {
      history.push(
        `/customer/provider/${provider.id}/${
          location.id
        }/confirm/${currentDate.format('YYYY-MM-DD')}/${slot}/${
          selectedService.id
        }/${selectedServiceItem.id}`
      );
    } else {
      setAlert(true);
    }
  };

  if (!clinic) {
    return <div>Invalid Provider</div>;
  }

  return (
    <IonPage id="clinic-schedule">
      <IonHeader>
        <IonToolbar className="top-header">
          <IonButtons slot="start">
            <img src="assets/img/hair-logo.svg" alt="ionic logo" />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
        <IonToolbar className="dashboard-header">
          <IonButtons slot="start">
            <IonBackButton color="primary" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="provider-schedule-content">
        <IonGrid>
          <IonRow>
            <div style={{ textAlign: 'center', flex: 1 }}>
              <h3>{clinic.title}</h3>
              <div>
                {selectedService && selectedService.name}
                {selectedServiceItem
                  && selectedServiceItem.duration
                  && `(${selectedServiceItem.duration}min - $${selectedServiceItem.price})`}
              </div>
            </div>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonSelect
                className="select"
                placeholder="Select service"
                // value={provider}
                onIonChange={(e): void => setServiceName(e.detail.value)}
              >
                {clinic.services.map((item) => (
                  <IonSelectOption key={item.id} value={item.name}>
                    {item.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
          </IonRow>
          {members.length > 0 && (
            <IonRow>
              <IonCol>
                <IonSelect
                  className="select"
                  placeholder="Select provider"
                  value={provider}
                  onIonChange={(e): void => setProvider(e.detail.value)}
                >
                  {members.map((item) => (
                    <IonSelectOption key={item.id} value={item}>
                      {item.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>
          )}
          {members.length === 0 && (
            <IonRow>
              <IonCol>No available stuff</IonCol>
            </IonRow>
          )}
          {selectedService && (
            <IonRow>
              <IonCol>
                <IonSelect
                  value={selectedServiceItem}
                  okText="Select"
                  cancelText="Cancel"
                  onIonChange={(e): void => {
                    e.stopPropagation();
                    setSelectedServiceItem(e.detail.value);
                  }}
                >
                  {selectedService.items!.map((item) => (
                    <IonSelectOption key={item.id} value={item}>
                      {`${item.duration}min - $${item.price.toFixed(1)}`}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
            </IonRow>
          )}
          {provider && (
            <IonRow>
              <IonCol>
                <IonSelect
                  className="select"
                  placeholder="Select location"
                  value={location}
                  onIonChange={(e): void => setLocation(e.detail.value)}
                >
                  {provider.locations
                    && provider.locations.map((item) => (
                      <IonSelectOption key={item.id} value={item}>
                        {item.address}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonCol>
            </IonRow>
          )}
          <IonRow>
            <IonCol>
              <Calendar
                className="calendar"
                showDateInput={false}
                showToday={false}
                onSelect={(date): Promise<void> => onSelectDate(date)}
                disabledDate={(current): boolean => moment(moment().format('MM-DD-YYYY')).isSameOrAfter(current)}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <div className="sub-title">
                {currentDate.format('MMMM Do, YYYY')}
              </div>
            </IonCol>
            {slots
              .filter((slot) => {
                if (!selectedServiceItem) {
                  return true;
                }
                return slots.includes(
                  slot + selectedServiceItem.duration / 30 - 1
                );
              })
              .map((slot: number, index: number) => (
                <IonCol key={index} size="6">
                  <IonButton
                    className="round-border"
                    fill="clear"
                    expand="block"
                    onClick={(): void => onConfirm(slot)}
                  >
                    {moment({ hour: slot / 4, minute: 15 * (slot % 4) }).format(
                      'HH:mm'
                    )}
                  </IonButton>
                </IonCol>
              ))}
            {slots.length === 0 && 'No available slots'}
          </IonRow>
        </IonGrid>
        <IonAlert
          isOpen={alert}
          onDidDismiss={(): void => setAlert(false)}
          header="Care Platform"
          message="Please select service, duration and location."
          buttons={['ok']}
        />
      </IonContent>
    </IonPage>
  );
};

ProviderSchedule.propTypes = {
  clinic: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, StateProps, {}>({
  mapStateToProps: (state, OwnProps) => ({
    clinic: selectors.getProvider(state, OwnProps)
  }),
  component: React.memo(ProviderSchedule)
});
