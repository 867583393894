import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel
} from '@ionic/react';
import { alertCircle, alarm, cash } from 'ionicons/icons';

import connect from '../../data/connect';
import { dispatchValidate } from '../../data/store/auth/auth.actions';
import {
  dispatchFetchBookings,
  dispatchFetchLanguages,
  dispatchFetchSpecialists,
  dispatchFetchProviderTypes,
  dispatchGetAllNotification,
  dispatchFetchTimeslots,
  dispatchFetchCurrencies
} from '../../data/store/data/data.actions';
import { getUnreadCount } from '../../data/selectors';
import { socket } from '../../data/dataApi';
import { User } from '../../models';
import Layout from '../../components/Layout';
import useGetView from '../../hooks/useGetView/useGetView';
import ProviderDashboard from '../ProviderDashboard';
import ProviderTimeslots from '../ProviderTimeslots';
import BusinessHour from '../BusinessHour';
import PaymentInformation from '../PaymentInformation';
import Notifications from '../Notifications';
import Reviews from '../Reviews/Reviews';
import ProviderProfile from '../ProviderProfile';
import ProviderAccount from '../ProviderAccount';
import Wallet from '../Wallet';
import Bookings from '../Bookings';
import CustomerRating from '../CustomerRating';
import BookingReport from '../BookingReport/BookingReport';
import UserReport from '../UserReport/UserReport';
import AccountSetting from '../AccountSetting';
import ContactUs from '../ContactUs';
import ProviderOnboarding from '../ProviderOnboarding';
import Subscription from '../Subscription';

import './ProviderView.scss';

interface StateProps {
  user: User
  unreadCount: number
}

interface DispatchProps {
  fetchValidate: typeof dispatchValidate
  fetchTimeslots: typeof dispatchFetchTimeslots
  fetchBookings: typeof dispatchFetchBookings
  fetchLanguages: typeof dispatchFetchLanguages
  fetchSpecialists: typeof dispatchFetchSpecialists
  fetchProviderTypes: typeof dispatchFetchProviderTypes
  fetchCurrencies: typeof dispatchFetchCurrencies
  getAllNotification: typeof dispatchGetAllNotification
}

type ProviderViewProps = StateProps & DispatchProps;

const ProviderView: React.FC<ProviderViewProps> = ({
  user,
  unreadCount,
  fetchValidate,
  fetchTimeslots,
  fetchBookings,
  fetchLanguages,
  fetchSpecialists,
  fetchProviderTypes,
  fetchCurrencies,
  getAllNotification
}) => {
  const [tab, setTab] = useState(0);
  const { isMobile } = useGetView();

  useEffect(() => {
    socket.on('new-message', (e: any) => {
      if (e?.type?.includes('identity') || e?.type?.includes('license')) {
        fetchValidate();
      }
      fetchBookings();
      getAllNotification();
    });
  }, []);

  useEffect(() => {
    fetchTimeslots(user.id);
  }, [user.id, fetchTimeslots]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    fetchSpecialists();
  }, [fetchSpecialists]);

  useEffect(() => {
    fetchProviderTypes();
  }, [fetchProviderTypes]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  const renderNotificationIcon = (): JSX.Element => {
    if (tab === 3) {
      return <IonIcon icon={alertCircle} color="primary" />;
    }
    if (unreadCount) {
      return <IonIcon icon={alertCircle} color="danger" />;
    }
    return <IonIcon icon={alertCircle} color="medium" />;
  };

  const renderRoutes = () => (
    <IonRouterOutlet>
      <Layout>
        <Route path="/provider" render={() => <Redirect to="/provider/onboarding" />} exact />
        <Route path="/provider/onboarding" component={ProviderOnboarding} exact />
        <Route path="/provider/dashboard" component={ProviderDashboard} exact />
        <Route
          path="/provider/availability"
          component={ProviderTimeslots}
          exact
        />
        <Route
          path="/provider/:id/business-hour"
          component={BusinessHour}
          exact
        />
        <Route path="/provider/payment" component={PaymentInformation} exact />
        <Route path="/provider/notification" component={Notifications} exact />
        <Route path="/provider/profile" component={ProviderProfile} exact />
        <Route path="/provider/profile/reviews" component={Reviews} exact />
        <Route path="/provider/wallet" component={Wallet} exact />
        <Route
          path="/provider/new-payment-source"
          component={ProviderAccount}
          exact
        />
        <Route path="/provider/bookings" component={Bookings} exact />
        <Route
          path="/provider/rate-booking/:id"
          component={CustomerRating}
          exact
        />
        <Route
          path="/provider/report"
          component={UserReport}
          exact
        />
        <Route
          path="/provider/report/booking/:id"
          component={BookingReport}
          exact
        />
        <Route path="/provider/subscribe" component={Subscription} exact />
        <Route path="/provider/account" component={AccountSetting} exact />
        <Route path="/provider/contact-us" component={ContactUs} exact />
      </Layout>
    </IonRouterOutlet>
  );

  if (!isMobile) {
    return renderRoutes();
  }

  return (
    <IonTabs>
      {renderRoutes()}
      <IonTabBar slot="bottom">
        <IonTabButton
          tab="dashboard"
          href="/provider/dashboard"
          onClick={(): void => setTab(0)}
        >
          <IonIcon src="assets/img/icon/dashboard.svg" />
          <IonLabel>Dashboard</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="availability"
          href="/provider/availability"
          onClick={(): void => setTab(1)}
        >
          <IonIcon icon={alarm} />
          <IonLabel>Availability</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="payments"
          href="/provider/payment"
          onClick={(): void => setTab(2)}
        >
          <IonIcon icon={cash} />
          <IonLabel>Payments</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="notifications"
          href="/provider/notification"
          onClick={(): void => setTab(3)}
        >
          {renderNotificationIcon()}
          <IonLabel>Notifications</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

ProviderView.propTypes = {
  user: PropTypes.any.isRequired,
  unreadCount: PropTypes.number.isRequired,
  fetchValidate: PropTypes.func.isRequired,
  fetchTimeslots: PropTypes.func.isRequired,
  fetchBookings: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  fetchSpecialists: PropTypes.func.isRequired,
  fetchProviderTypes: PropTypes.func.isRequired,
  fetchCurrencies: PropTypes.func.isRequired,
  getAllNotification: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user,
    unreadCount: getUnreadCount(state)
  }),
  mapDispatchToProps: {
    fetchValidate: dispatchValidate,
    fetchTimeslots: dispatchFetchTimeslots,
    fetchBookings: dispatchFetchBookings,
    fetchLanguages: dispatchFetchLanguages,
    fetchSpecialists: dispatchFetchSpecialists,
    fetchProviderTypes: dispatchFetchProviderTypes,
    fetchCurrencies: dispatchFetchCurrencies,
    getAllNotification: dispatchGetAllNotification
  },
  component: React.memo(ProviderView)
});
