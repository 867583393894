import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonText,
  IonLoading,
  IonItem,
  IonLabel,
  IonIcon
} from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';

import './Login.scss';
import { dispatchLogin, dispatchResetErrorMessage, dispatchValidate } from '../../data/store/auth/auth.actions';
import connect from '../../data/connect';
import { getSyncToken, activateStripeAccount } from '../../data/dataApi';
import { StatusContext } from '../../data/StatusContext';
import { User, UserStatus } from '../../models';
import PhonenumberInput from '../../components/PhonenumberInput';

import logoImg from '../../assets/logo.png';

interface DispatchProps {
  login: typeof dispatchLogin
  resetErrorMessage: typeof dispatchResetErrorMessage
  validate: typeof dispatchValidate
}

interface StateProps {
  user: User,
  loading: boolean
  errorMessage: string
  loggedin: boolean
  role: string
}

type LoginProps = RouteComponentProps & StateProps & DispatchProps;

const Login: React.FC<LoginProps> = ({
  history,
  user,
  loading,
  errorMessage,
  loggedin,
  role,
  login,
  resetErrorMessage,
  validate
}) => {
  const location = useLocation();
  const { syncConnect } = useContext(StatusContext);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (loggedin && user) {
      getSyncToken()
        .then(({ success, token }) => {
          if (success) {
            syncConnect(token, user);
          }
        })
        .catch((error) => console.log(error));
      if (user.status !== UserStatus.ACTIVE) {
        history.push('/verify');
      } else {
        history.push(`/${role}`);
      }
      setPhone('');
      setPassword('');
    }
  }, [loggedin, user.id]);

  const onLogin = (e: React.FormEvent): void => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!phone) {
      setPhoneError(true);
      return;
    }
    if (!password) {
      setPasswordError(true);
      return;
    }

    login({ phone, password });
  };

  useEffect(() => {
    resetErrorMessage();
    const queryParams = new URLSearchParams(location.search);
    const providerBankAccountStatus = queryParams.get('bankaccount');
    const userId = queryParams.get('userId');
    if (providerBankAccountStatus === 'verified' && userId) {
      activateStripeAccount(Number(userId))
        .then(() => {
          validate();
        })
        .catch((err) => console.log(err));
    }
  }, [location]);

  return (
    <IonPage id="login-page">
      <IonContent className="page-content">
        <div className="login-container">
          <div className="login-logo">
            <img src={logoImg} alt="Ionic logo" />
            <h3>Sign In</h3>
            <div>
              Please enter the registered phone number & Password for your User
              account!
            </div>
          </div>

          {errorMessage && (
            <IonText color="danger">
              <p className="ion-padding-start">{errorMessage}</p>
            </IonText>
          )}

          <form noValidate onSubmit={onLogin}>
            <IonItem lines="none" class="input">
              <PhonenumberInput
                value={phone}
                onChange={(value) => {
                  setPhone(value);
                  setPhoneError(false);
                }}
              />
            </IonItem>

            {formSubmitted && phoneError && (
              <IonText color="danger">
                <p className="ion-padding-start">Phone Number is required</p>
              </IonText>
            )}

            <div className="password-input">
              <IonInput
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                value={password}
                onIonChange={(e): void => {
                  setPassword(e.detail.value || '');
                  setPasswordError(false);
                }}
              />
              <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowPassword(!showPassword)} />
            </div>

            {formSubmitted && passwordError && (
              <IonText color="danger">
                <p className="ion-padding-start">Password is required</p>
              </IonText>
            )}
            <IonItem lines="none">
              <IonButton
                className="forgot-password-btn"
                slot="end"
                fill="clear"
                color="dark"
                routerLink="/forget-password"
              >
                Forgot password?
              </IonButton>
            </IonItem>

            <IonRow>
              <IonCol size="12">
                <button
                  className="submit-button"
                  type="submit"
                  disabled={loading || !password || !phone}
                >
                  Sign In
                </button>
              </IonCol>
              <IonCol>
                <div className="sign-up">
                  <IonLabel>Don't you have an account?</IonLabel>
                  <IonButton
                    className="sign-in-btn"
                    routerLink="/signup"
                    color="favorite"
                    fill="clear"
                  >
                    SignUp
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </div>

        <IonLoading isOpen={loading} />
      </IonContent>
    </IonPage>
  );
};

Login.propTypes = {
  user: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  loggedin: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
  login: PropTypes.func.isRequired,
  resetErrorMessage: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user,
    loading: state.auth.loading,
    errorMessage: state.auth.errorMessage,
    loggedin: !!state.auth.user.phone,
    role: state.auth.user.role
  }),
  mapDispatchToProps: {
    login: dispatchLogin,
    resetErrorMessage: dispatchResetErrorMessage,
    validate: dispatchValidate
  },
  component: Login
});
