import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonList,
  IonItem,
  IonButton,
  IonInput,
  IonAlert,
  IonLoading,
  IonBackButton
} from '@ionic/react';

import connect from '../../data/connect';
import { resetPassword } from '../../data/dataApi';
import { User } from '../../models';
import './AccountSetting.scss';

interface StateProps {
  user: User
}

type AccountSettingProps = RouteComponentProps & StateProps;

const AccountSetting: React.FC<AccountSettingProps> = ({ user, history }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const onUpdate = (): void => {
    setLoading(true);
    resetPassword(user.id, {
      oldPassword,
      newPassword
    })
      .then((res) => {
        if (res.success) {
          setShowAlert(true);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <IonPage id="provider-profile">
      <IonHeader>
        <IonToolbar color="secondary" className="top-header">
          <IonButtons slot="start">
            <img src="assets/img/hair-logo.svg" alt="ionic logo" />
          </IonButtons>
          <IonTitle>Update setting</IonTitle>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
        <IonToolbar className="dashboard-header">
          <IonButtons slot="start">
            <IonBackButton color="primary" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          <IonItem>
            <IonInput
              name="old-password"
              type="password"
              placeholder="Old Password"
              value={oldPassword}
              onIonChange={(e): void => setOldPassword(e.detail.value || '')}
            />
          </IonItem>
          <IonItem>
            <IonInput
              name="new-password"
              type="password"
              placeholder="New Password"
              value={newPassword}
              onIonChange={(e): void => setNewPassword(e.detail.value || '')}
            />
          </IonItem>
          <IonItem>
            <IonInput
              name="new-password-confirm"
              type="password"
              placeholder="Confirm New Password"
            />
          </IonItem>
          <IonButton expand="block" onClick={(): void => onUpdate()}>
            Update
          </IonButton>
        </IonList>

        <IonAlert
          isOpen={showAlert}
          onDidDismiss={(): void => {
            setShowAlert(false);
            history.goBack();
          }}
          header="Care Platform"
          message="Password changed successfully."
          buttons={['Ok']}
        />

        <IonLoading isOpen={loading} />
      </IonContent>
    </IonPage>
  );
};

AccountSetting.propTypes = {
  user: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {},
  component: React.memo(AccountSetting)
});
