import {
  fetchBookings,
  updateBooking,
  fetchLanguages,
  fetchSpecialists,
  fetchProviderTypes,
  fetchCurrencies,
  fetchCategories,
  fetchRoles,
  deleteStripeCard,
  changeDefaultCard,
  updateNotification,
  deleteProviderBankAccount,
  changeDefaultBankAccount,
  getAllNotification,
  deleteNotification,
  readAllNotification,
  deleteAllNotification,
  fetchClinicMembers,
  fetchProviders,
  fetchFavoriteProviders,
  addFavoriteProvider,
  deleteFavoriteProvider,
  fetchTimeslots,
  createTimeslot,
  deleteTimeslot,
  updateTimeslot,
  fetchRequests,
  fetchCustomers,
  fetchClinics,
  socket,
  fetchSocialMedias
} from '../../dataApi';
import { ActionType } from '../../../util/types';
import { SOCKET_KEYS } from '../../constants';
import {
  Booking,
  Currency,
  Language,
  Specialist,
  User,
  PaymentSource,
  UserRole,
  BookingStatus,
  ProviderType,
  Category,
  Message,
  Timeslot,
  Location,
  Request,
  SocialMedia,
  Role
} from '../../../models';
import { DataState } from './data.state';

export const actionSetLoading = (isLoading: boolean) => ({
  type: '@data/set-loading',
  isLoading
} as const);

export const actionSetBookings = (data: Booking[]) => ({
  type: '@data/set-bookings',
  data
} as const);

export const actionReset = () => ({
  type: '@data/reset'
} as const);

export const actionAddBooking = (booking: Booking) => ({
  type: '@data/add-booking',
  booking
} as const);

export const actionUpdateBooking = (id: number, status: BookingStatus) => ({
  type: '@data/update-booking',
  id,
  status
} as const);

export const actionSetLanguages = (languages: Language[]) => ({
  type: '@data/set-languages',
  languages
} as const);

export const actionSetSpecialists = (specialists: Specialist[]) => ({
  type: '@data/set-specialists',
  specialists
} as const);

export const actionSetProviderTypes = (providerTypes: ProviderType[]) => ({
  type: '@data/set-provider-types',
  providerTypes
} as const);

export const actionSetSocialMediaTypes = (socialMediaTypes: SocialMedia[]) => ({
  type: '@data/set-social-media-types',
  socialMediaTypes
} as const);

export const actionSetCurrencies = (currencies: Currency[]) => ({
  type: '@data/set-currencies',
  currencies
} as const);

export const actionSetRoles = (roles: Role[]) => ({
  type: '@data/set-roles',
  roles
} as const);

export const actionSetcategories = (categories: Category[]) => ({
  type: '@data/set-categories',
  categories
} as const);

export const actionAddPaymentSource = (source: PaymentSource) => ({
  type: '@data/add-payment-source',
  source
} as const);

export const actionRemovePaymentSource = (id: number) => ({
  type: '@data/remove-payment-source',
  id
} as const);

export const actionSelectDefaultPaymentSource = (id: number) => ({
  type: '@data/select-default-payment-source',
  id
} as const);

export const actionGetAllNotification = (notifications: Message[]) => ({
  type: '@data/get-all-notification',
  notifications
} as const);

export const actionUpdateNotification = (id: number, data: Message) => ({
  type: '@data/update-notification',
  id,
  data
} as const);

export const actionDeleteNotification = (id: number) => ({
  type: '@data/delete-notification',
  id
} as const);

export const actionReadAllNotification = () => ({
  type: '@data/read-all-notification'
} as const);

export const actionDeleteAllNotification = () => ({
  type: '@data/delete-all-notification'
} as const);

export const actionSetClinicMembers = (members: User[]) => ({
  type: '@data/set-clinic-members',
  members
} as const);

export const actionSetProviders = (data: Partial<DataState>) => ({
  type: '@data/set-providers',
  data
} as const);

export const actionSetFavoriteProviders = (data: Partial<DataState>) => ({
  type: '@data/set-favorite-providers',
  data
} as const);

export const actionAddFavoriteProvider = (data: User) => ({
  type: '@data/add-favorite-provider',
  data
} as const);

export const actionDeleteFavoriteProvider = (data: number) => ({
  type: '@data/delete-favorite-provider',
  data
} as const);

export const actionUpdateProvider = (provider: User) => ({
  type: '@data/update-provider',
  provider
} as const);

export const actionDeleteProvider = (providerId: number) => ({
  type: '@data/delete-provider',
  providerId
} as const);

export const actionDeleteOrInactivate = (clinicId: number, isDelete: boolean) => ({
  type: '@data/delete-or-inactivate-clinic',
  data: { clinicId, isDelete }
} as const);

export const actionSetTimeslots = (slots: Timeslot[], locations: Location[]) => ({
  type: '@data/set-timeslots',
  slots,
  locations
} as const);

export const actionAddTimeslot = (slot: Timeslot) => ({
  type: '@data/add-timeslot-day',
  slot
} as const);

export const actionRemoveTimeslot = (id: number) => ({
  type: '@data/remove-timeslot',
  id
} as const);

export const actionUpdateTimeslot = (slot: Timeslot) => ({
  type: '@data/update-timeslot',
  slot
} as const);

export const actionSetRequests = (data: Partial<DataState>) => ({
  type: '@data/set-requests',
  data
} as const);

export const actionUpdateRequest = (request: Request) => ({
  type: '@data/update-request',
  request
} as const);

export const actionSetCustomers = (data: Partial<DataState>) => ({
  type: '@data/set-customers',
  data
} as const);

export const actionUpdateCustomer = (customer: User) => ({
  type: '@data/update-customer',
  customer
} as const);

export const actionDeleteCustomer = (customerId: number) => ({
  type: '@data/delete-customer',
  customerId
} as const);

export const actionSetClinics = (data: Partial<DataState>) => ({
  type: '@data/set-clinics',
  data
} as const);

export const actionEmptyProviders = () => ({
  type: '@data/empty-providers'
} as const);

export const actionEmptyCustomers = () => ({
  type: '@data/empty-customers'
} as const);

export const actionEmptyClinics = () => ({
  type: '@data/empty-clinics'
} as const);

export const dispatchFetchBookings = (status?: string) => (dispatch: React.Dispatch<any>): void => {
  dispatch(actionSetLoading(true));
  fetchBookings(status)
    .then((res) => {
      if (res.success) {
        dispatch(actionSetBookings(res.bookings));
      }
    })
    .catch(() => {})
    .finally(() => dispatch(actionSetLoading(false)));
};

export const dispatchCreateBooking = (booking: Booking) => (dispatch: React.Dispatch<any>): any => {
  dispatch(actionAddBooking(booking));
};

export const dispatchUpdateBooking = (id: number, status: BookingStatus) => (
  dispatch: React.Dispatch<any>
): void => {
  dispatch(actionSetLoading(true));
  updateBooking(id, status)
    .then((res) => {
      if (res.success) {
        dispatch(actionUpdateBooking(id, status));
        socket.emit(SOCKET_KEYS[status], id);
      }
    })
    .catch(() => {})
    .finally(() => dispatch(actionSetLoading(false)));
};

export const dispatchFetchLanguages = () => (
  dispatch: React.Dispatch<any>
): void => {
  dispatch(actionSetLoading(true));
  fetchLanguages()
    .then((res) => {
      if (res.success) {
        dispatch(actionSetLanguages(res.languages));
      }
    })
    .catch(() => {})
    .finally(() => dispatch(actionSetLoading(false)));
};

export const dispatchFetchSpecialists = () => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const data = await fetchSpecialists();
  if (data.success) {
    dispatch(actionSetSpecialists(data.specialists));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchFetchProviderTypes = () => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const data = await fetchProviderTypes();
  if (data.success) {
    dispatch(actionSetProviderTypes(data.providerTypes));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchSocialMediaTypes = () => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const data = await fetchSocialMedias();
  if (data.success) {
    dispatch(actionSetSocialMediaTypes(data.socialMediaTypes));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchFetchCurrencies = () => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const data = await fetchCurrencies();
  if (data.success) {
    dispatch(actionSetCurrencies(data.currencies));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchFetchRoles = () => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const data = await fetchRoles();
  if (data.success) {
    dispatch(actionSetRoles(data.roles));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchFetchCategories = () => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const data = await fetchCategories();
  if (data.success) {
    dispatch(actionSetcategories(data.categories));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchCreatePaymentSource = (card: PaymentSource) => (
  dispatch: React.Dispatch<any>
): any => {
  dispatch(actionAddPaymentSource(card));
};

export const dispatchDeletePaymentSource = (id: number, role: string) => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const data = role === UserRole.CUSTOMER
    ? await deleteStripeCard(id)
    : await deleteProviderBankAccount(id);
  if (data.success) {
    dispatch(actionRemovePaymentSource(id));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchSelectDefaultPaymentSource = (id: number, role: string) => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const res = role === UserRole.CUSTOMER
    ? await changeDefaultCard(id)
    : await changeDefaultBankAccount(id);
  if (res.success) {
    dispatch(actionSelectDefaultPaymentSource(id));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchGetAllNotification = () => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const res = await getAllNotification();
  if (res.success) {
    dispatch(actionGetAllNotification(res.messages));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchUpdateNotification = (id: number, data: any) => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const res = await updateNotification(id, data);
  if (res.success) {
    dispatch(actionUpdateNotification(id, data));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchDeleteNotification = (id: number) => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const res = await deleteNotification(id);
  if (res.success) {
    dispatch(actionDeleteNotification(id));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchReadAllNotification = () => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const res = await readAllNotification();
  if (res.success) {
    dispatch(actionReadAllNotification());
  }
  dispatch(actionSetLoading(false));
};

export const dispatchDeleteAllNotification = () => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const res = await deleteAllNotification();
  if (res.success) {
    dispatch(actionDeleteAllNotification());
  }
  dispatch(actionSetLoading(false));
};

export const dispatchFetchClinicMembers = (clinic: number) => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  dispatch(actionSetLoading(true));
  const res = await fetchClinicMembers(clinic);
  if (res.success) {
    dispatch(actionSetClinicMembers(res.members));
  }
  dispatch(actionSetLoading(false));
};

export const dispatchFetchProviders = (params?: any) => async (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  const data = await fetchProviders(params);
  dispatch(actionSetProviders(data.providers));
};

export const dispatchFetchFavoriteProviders = (userId: number) => async (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  const data = await fetchFavoriteProviders(userId);
  dispatch(actionSetFavoriteProviders(data.providers));
};

export const dispatchAddFavoriteProvider = (userId: number, provider: User) => async (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  await addFavoriteProvider(userId, provider.id);
  dispatch(actionAddFavoriteProvider(provider));
};

export const dispatchDeleteFavoriteProvider = (userId: number, provider: User) => async (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  await deleteFavoriteProvider(userId, provider.id);
  dispatch(actionDeleteFavoriteProvider(provider.id));
};

export const dispatchUpdateProvider = (provider: User) => (
  dispatch: React.Dispatch<any>
): void => {
  dispatch(actionUpdateProvider(provider));
};

export const dispatchDeleteProvider = (providerId: number) => (
  dispatch: React.Dispatch<any>
): void => {
  dispatch(actionDeleteProvider(providerId));
};

export const dispatchDeleteOrInactivateClinic = (clinicId: number, isDelete: boolean) => (
  dispatch: React.Dispatch<any>
): void => {
  dispatch(actionDeleteOrInactivate(clinicId, isDelete));
};

export const dispatchFetchTimeslots = (id: number) => async (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  const { rules, locations } = await fetchTimeslots(id);
  if (rules && locations) {
    dispatch(actionSetTimeslots(rules, locations));
  }
};

export const dispatchCreateTimeslot = (slot: any) => async (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  const data = await createTimeslot(slot);
  if (data.success) {
    dispatch(actionAddTimeslot(data.slot));
  }
};

export const dispatchDeleteTimeslot = (id: number) => async (
  dispatch: React.Dispatch<any>
): Promise<any> => {
  const data = await deleteTimeslot(id);
  if (data.success) {
    dispatch(actionRemoveTimeslot(id));
  }
};

export const dispatchUpdateTimeslot = (id: number, params: any) => async (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  const data = await updateTimeslot(id, params);
  if (data.success) {
    dispatch(actionUpdateTimeslot(data.slot));
  }
};

export const dispatchFetchRequests = () => async (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  const data = await fetchRequests();
  dispatch(actionSetRequests(data.requests));
};

export const dispatchUpdateRequest = (request: Request) => (
  dispatch: React.Dispatch<any>
): void => {
  dispatch(actionUpdateRequest(request));
};

export const dispatchFetchCustomers = (params?: any) => async (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  const data = await fetchCustomers(params);
  dispatch(actionSetCustomers(data.customers));
};

export const dispatchUpdateCustomer = (customer: User) => (
  dispatch: React.Dispatch<any>
): void => {
  dispatch(actionUpdateCustomer(customer));
};

export const dispatchFetchClinics = (params?: any) => async (
  dispatch: React.Dispatch<any>
): Promise<void> => {
  const data = await fetchClinics(params);
  dispatch(actionSetClinics(data.clinics));
};

export const dispatchDeleteCustomer = (customerId: number) => (
  dispatch: React.Dispatch<any>
): void => {
  dispatch(actionDeleteCustomer(customerId));
};

export type DataActions =
  | ActionType<typeof actionSetLoading>
  | ActionType<typeof actionReset>
  | ActionType<typeof actionSetBookings>
  | ActionType<typeof actionAddBooking>
  | ActionType<typeof actionUpdateBooking>
  | ActionType<typeof actionSetLanguages>
  | ActionType<typeof actionSetSpecialists>
  | ActionType<typeof actionSetProviderTypes>
  | ActionType<typeof actionSetSocialMediaTypes>
  | ActionType<typeof actionSetCurrencies>
  | ActionType<typeof actionSetRoles>
  | ActionType<typeof actionSetcategories>
  | ActionType<typeof actionAddPaymentSource>
  | ActionType<typeof actionRemovePaymentSource>
  | ActionType<typeof actionSelectDefaultPaymentSource>
  | ActionType<typeof actionGetAllNotification>
  | ActionType<typeof actionUpdateNotification>
  | ActionType<typeof actionDeleteNotification>
  | ActionType<typeof actionReadAllNotification>
  | ActionType<typeof actionDeleteAllNotification>
  | ActionType<typeof actionSetClinicMembers>
  | ActionType<typeof actionSetProviders>
  | ActionType<typeof actionSetFavoriteProviders>
  | ActionType<typeof actionAddFavoriteProvider>
  | ActionType<typeof actionDeleteFavoriteProvider>
  | ActionType<typeof actionUpdateProvider>
  | ActionType<typeof actionDeleteProvider>
  | ActionType<typeof actionDeleteOrInactivate>
  | ActionType<typeof actionSetTimeslots>
  | ActionType<typeof actionAddTimeslot>
  | ActionType<typeof actionRemoveTimeslot>
  | ActionType<typeof actionUpdateTimeslot>
  | ActionType<typeof actionSetRequests>
  | ActionType<typeof actionUpdateRequest>
  | ActionType<typeof actionSetCustomers>
  | ActionType<typeof actionUpdateCustomer>
  | ActionType<typeof actionDeleteCustomer>
  | ActionType<typeof actionSetClinics>
  | ActionType<typeof actionEmptyProviders>
  | ActionType<typeof actionEmptyCustomers>
  | ActionType<typeof actionEmptyClinics>;
