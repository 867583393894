import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonList,
  IonCol,
  IonAlert,
  IonLabel,
  IonLoading
} from '@ionic/react';

import Avatar from '../../components/Avatar';
import connect from '../../data/connect';
import { User } from '../../models';
import { fetchClinicRatings, fetchProvider } from '../../data/dataApi';
import ClinicRatingItem from '../../components/ClinicRatingItem';
import StarRating from '../../components/StarRating';
import Map from '../../components/Map';
import './ClinicDetail.scss';

interface MatchParams {
  id: string
}

type ClinicDetailProps = RouteComponentProps<MatchParams>;

const ClinicDetail: React.FC<ClinicDetailProps> = ({
  match,
  history
}) => {
  const [clinic, setClinic] = useState<User | null>(null);
  const [ratings, setRatings] = useState([]);
  const [alert, setAlert] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const onViewReviews = (): void => {
    fetchClinicRatings(Number(match.params.id))
      .then((res) => {
        setRatings(res.bookings);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (match.params.id) {
      setIsLoading(true);
      fetchProvider(Number(match.params.id))
        .then((res) => {
          if (res.success) {
            setClinic(res.user);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [match]);

  if (!clinic) {
    return <div>Invalid Provider</div>;
  }

  return (
    <IonContent className="provider-detail-content">
      <IonGrid>
        <IonRow className="profile-content profile">
          <Avatar user={clinic} size="lg" />
          <IonLabel>
            <h2>{`${clinic.name} ${clinic.lastName ?? ''}`}</h2>
            <p>{clinic.role}</p>
            <span>
              <StarRating hasOneStar rating={Number(((clinic.rating ?? 0) / 5).toFixed(1))} readonly />
              <span className="rating">{(clinic.rating ?? 0).toFixed(1)}</span>
            </span>
          </IonLabel>
        </IonRow>
        <IonRow>
          <IonCol>
            <div className="sub-detail">
              {clinic.services.map((service) => (
                <div key={service.id}>{service.name}</div>
              ))}
            </div>
          </IonCol>
          <IonCol>
            <IonButton
              color="primary"
              routerLink={`/customer/clinic/${match.params.id}/schedule`}
            >
              Book Now
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <div className="sub-detail">
            <div className="sub-title">{`About ${clinic.title}`}</div>
            <div>{clinic.about || 'No description'}</div>
          </div>
        </IonRow>
        <IonRow>
          <div className="sub-detail">
            <div className="sub-title">Specialists</div>
            <div>
              {clinic.specialists
                .map((specialist) => specialist.name)
                .join(' | ')}
            </div>
          </div>
        </IonRow>
        <IonRow>
          <div className="sub-detail location">
            <div className="sub-title">Locations</div>
            {clinic.locations.map((item, index) => (
              <IonRow key={item.address}>
                <IonCol>{item.address}</IonCol>
                <IonCol size="3">
                  <IonButton
                    expand="block"
                    color="primary"
                    size="small"
                    onClick={(): void => setCurrentLocation(index)}
                  >
                    Direction
                  </IonButton>
                </IonCol>
              </IonRow>
            ))}
          </div>
        </IonRow>
        {clinic.locations.length > 0 && (
          <IonRow>
            <div className="map-container">
              <Map
                locations={clinic.locations}
                mapCenter={clinic.locations[currentLocation]}
              />
            </div>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <IonButton
              expand="block"
              fill="solid"
              className="round-border"
              routerLink={`/customer/clinic/${match.params.id}/members`}
            >
              To Provider List
            </IonButton>
          </IonCol>
        </IonRow>
        {ratings.length > 0 && (
          <IonRow>
            <IonCol size="12">
              <IonList>
                {ratings.map((booking, index) => (
                  <ClinicRatingItem key={index} booking={booking} />
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <IonButton
              expand="block"
              fill="clear"
              className="round-border"
              onClick={(): void => onViewReviews()}
            >
              View reviews
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonAlert
        isOpen={alert}
        onDidDismiss={(): void => {
          setAlert(false);
        }}
        header="Care Platform"
        message="Please attach your credit card to make appointments."
        buttons={[
          'No',
          {
            text: 'Yes',
            handler: (): void => history.push('/customer/wallet')
          }
        ]}
      />
      <IonLoading isOpen={isLoading} />
    </IonContent>
  );
};

ClinicDetail.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired
};

export default connect<RouteComponentProps<MatchParams>, {}, {}>({
  component: React.memo(ClinicDetail)
});
