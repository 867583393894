import {
  setToken,
  login,
  register,
  validate
} from '../../dataApi';
import { ActionType } from '../../../util/types';
import { User } from '../../../models';
import initialAuthState from './auth.state';
import { actionReset as actionDataReset } from '../data/data.actions';

export const actionSetLoading = (isLoading: boolean) => ({
  type: '@auth/set-loading',
  isLoading
} as const);

export const actionSetErrorMessage = (message: string) => ({
  type: '@auth/set-error-message',
  message
} as const);

export const actionReset = () => ({
  type: '@auth/reset'
} as const);

export const actionSetUser = (data: User) => ({
  type: '@auth/set-user',
  data
} as const);

export const dispatchResetErrorMessage = () => (dispatch: React.Dispatch<any>): void => {
  dispatch(actionSetErrorMessage(''));
};

export const dispatchLogin = (params: any) => (dispatch: React.Dispatch<any>): void => {
  dispatch(actionSetLoading(true));
  dispatch(actionSetErrorMessage(''));
  login(params)
    .then(async (res) => {
      if (!res.error) {
        await setToken(res.token);
        dispatch(actionSetUser(res.current));
      }
    })
    .catch((error) => dispatch(actionSetErrorMessage(error?.response?.data?.msg)))
    .finally(() => dispatch(actionSetLoading(false)));
};

export const dispatchLogout = () => async (dispatch: React.Dispatch<any>): Promise<any> => {
  await setToken();
  dispatch(actionReset());
  dispatch(actionDataReset());
};

export const dispatchRegister = (params: any) => (dispatch: React.Dispatch<any>): void => {
  dispatch(actionSetLoading(true));
  register(params)
    .then(async (res) => {
      if (!res.error) {
        await setToken(res.token);
        dispatch(actionSetUser(res.current));
      }
    })
    .catch((error) => dispatch(actionSetErrorMessage(error?.response?.data?.msg ?? 'Something went wrong. Please try again later.')))
    .finally(() => dispatch(actionSetLoading(false)));
};

export const dispatchValidate = () => (dispatch: React.Dispatch<any>): void => {
  dispatch(actionSetLoading(true));
  validate()
    .then((res) => {
      dispatch(
        actionSetUser(res.success ? res.current : initialAuthState.user)
      );
    })
    .catch(async (error) => {
      if (error?.response?.status === 401) {
        await setToken();
        dispatch(actionReset());
        dispatch(actionDataReset());
      }
    })
    .finally(() => dispatch(actionSetLoading(false)));
};

export const dispatchUpdateUser = (data: any) => (dispatch: React.Dispatch<any>): any => {
  dispatch(actionSetUser(data));
};

export type AuthActions =
  | ActionType<typeof actionSetLoading>
  | ActionType<typeof actionSetErrorMessage>
  | ActionType<typeof actionSetUser>
  | ActionType<typeof actionReset>;
