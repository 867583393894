import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ClockIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g clipPath="url(#clip0_34_488)">
      <path d="M12.3746 1.93875V1.375C12.3746 1.01033 12.2298 0.660591 11.9719 0.402728C11.7141 0.144866 11.3643 0 10.9996 0C10.635 0 10.2852 0.144866 10.0274 0.402728C9.76951 0.660591 9.62464 1.01033 9.62464 1.375V1.93875C7.87597 2.17487 6.22024 2.86706 4.82381 3.94576C3.42738 5.02446 2.33944 6.45168 1.6693 8.08401C0.99916 9.71635 0.770427 11.4963 1.00608 13.245C1.24174 14.9938 1.93348 16.6497 3.01181 18.0464C2.63227 18.3584 2.32883 18.7527 2.12456 19.1996C1.9203 19.6464 1.82057 20.1339 1.83298 20.625C1.83298 20.9897 1.97784 21.3394 2.23571 21.5973C2.49357 21.8551 2.8433 22 3.20798 22C3.57265 22 3.92239 21.8551 4.18025 21.5973C4.43811 21.3394 4.58298 20.9897 4.58298 20.625C4.56897 20.4888 4.60298 20.3519 4.67913 20.2381C4.75529 20.1243 4.8688 20.0407 5.00006 20.0017C6.73117 21.2979 8.83562 21.9985 10.9983 21.9985C13.1609 21.9985 15.2654 21.2979 16.9965 20.0017C17.1283 20.0402 17.2424 20.1236 17.3191 20.2374C17.3958 20.3513 17.4302 20.4884 17.4163 20.625C17.4163 20.9897 17.5612 21.3394 17.819 21.5973C18.0769 21.8551 18.4266 22 18.7913 22C19.156 22 19.5057 21.8551 19.7636 21.5973C20.0214 21.3394 20.1663 20.9897 20.1663 20.625C20.1787 20.1339 20.079 19.6464 19.8747 19.1996C19.6705 18.7527 19.367 18.3584 18.9875 18.0464C20.0658 16.6497 20.7575 14.9938 20.9932 13.245C21.2289 11.4963 21.0001 9.71635 20.33 8.08401C19.6599 6.45168 18.5719 5.02446 17.1755 3.94576C15.7791 2.86706 14.1233 2.17487 12.3746 1.93875ZM3.66631 11.9167C3.66631 10.4663 4.0964 9.04845 4.9022 7.84248C5.708 6.63652 6.85331 5.69659 8.1933 5.14155C9.53329 4.58651 11.0078 4.44128 12.4303 4.72424C13.8528 5.0072 15.1595 5.70563 16.1851 6.73122C17.2107 7.7568 17.9091 9.06348 18.1921 10.486C18.475 11.9085 18.3298 13.383 17.7748 14.723C17.2197 16.063 16.2798 17.2083 15.0738 18.0141C13.8679 18.8199 12.45 19.25 10.9996 19.25C9.05539 19.2478 7.1914 18.4745 5.81661 17.0997C4.44181 15.7249 3.66849 13.8609 3.66631 11.9167Z" fill={color} />
      <path d="M17.875 5.98119e-05C17.5103 5.98119e-05 17.1606 0.144925 16.9027 0.402788C16.6449 0.66065 16.5 1.01039 16.5 1.37506C16.5 1.73973 16.6449 2.08947 16.9027 2.34733C17.1606 2.60519 17.5103 2.75006 17.875 2.75006C18.0575 2.73586 18.2409 2.76201 18.4122 2.82663C18.5835 2.89126 18.7384 2.99279 18.8661 3.12402C18.9937 3.25524 19.0909 3.41295 19.1508 3.58595C19.2106 3.75896 19.2317 3.94301 19.2124 4.12506C19.2124 4.48973 19.3573 4.83947 19.6151 5.09733C19.873 5.35519 20.2227 5.50006 20.5874 5.50006C20.9521 5.50006 21.3018 5.35519 21.5597 5.09733C21.8176 4.83947 21.9624 4.48973 21.9624 4.12506C21.9836 3.58086 21.8927 3.0381 21.6953 2.53052C21.4979 2.02294 21.1982 1.56138 20.8149 1.17452C20.4316 0.787661 19.9728 0.483768 19.467 0.281725C18.9613 0.0796832 18.4194 -0.0161912 17.875 5.98119e-05Z" fill={color} />
      <path d="M2.7872 4.12506C2.76795 3.94301 2.789 3.75896 2.84886 3.58595C2.90872 3.41295 3.00592 3.25524 3.13356 3.12402C3.2612 2.99279 3.41615 2.89126 3.58743 2.82663C3.7587 2.76201 3.9421 2.73586 4.12462 2.75006C4.48929 2.75006 4.83903 2.60519 5.09689 2.34733C5.35475 2.08947 5.49962 1.73973 5.49962 1.37506C5.49962 1.01039 5.35475 0.66065 5.09689 0.402788C4.83903 0.144925 4.48929 5.98119e-05 4.12462 5.98119e-05C3.58024 -0.0161912 3.03834 0.0796832 2.53259 0.281725C2.02684 0.483768 1.56804 0.787661 1.1847 1.17452C0.801368 1.56138 0.501687 2.02294 0.304282 2.53052C0.106877 3.0381 0.0159668 3.58086 0.0371996 4.12506C0.0371996 4.48973 0.182065 4.83947 0.439928 5.09733C0.69779 5.35519 1.04753 5.50006 1.4122 5.50006C1.77687 5.50006 2.12661 5.35519 2.38447 5.09733C2.64233 4.83947 2.7872 4.48973 2.7872 4.12506Z" fill={color} />
      <path d="M12.4023 11.3475V7.79175C12.4023 7.42708 12.2575 7.07734 11.9996 6.81948C11.7418 6.56161 11.392 6.41675 11.0273 6.41675C10.6627 6.41675 10.3129 6.56161 10.0551 6.81948C9.79721 7.07734 9.65234 7.42708 9.65234 7.79175V11.727C9.65245 12.2132 9.84567 12.6794 10.1895 13.0232L11.8331 14.6667C12.0924 14.9172 12.4398 15.0558 12.8003 15.0527C13.1608 15.0495 13.5057 14.9049 13.7606 14.65C14.0155 14.3951 14.1601 14.0502 14.1633 13.6897C14.1664 13.3292 14.0278 12.9818 13.7773 12.7225L12.4023 11.3475Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_34_488">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ClockIcon;
