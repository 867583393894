import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

interface PlaceSearchInputProps {
  isRequired?: boolean
  isCitiesLimited?: boolean
  value?: string
  onChange?: (value: any) => void
}

const PlaceSearchInput: React.FC<PlaceSearchInputProps> = ({
  isRequired,
  isCitiesLimited,
  value,
  onChange
}) => {
  const mapEle = useRef<HTMLInputElement>(null);
  const map = useRef<google.maps.places.Autocomplete>();
  const [address, setAddress] = useState('');
  const geocoder = new google.maps.Geocoder();

  const getCity = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const cityComponent = addressComponents.find((component) => (
      component.types.includes('locality')
    ));
    return cityComponent ? cityComponent.long_name : '';
  };

  const getState = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const stateComponent = addressComponents.find((component) => (
      component.types.includes('administrative_area_level_1')
    ));
    return stateComponent ? stateComponent.long_name : '';
  };

  const getCountry = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    const countryComponent = addressComponents.find((component) => (
      component.types.includes('country')
    ));
    return countryComponent ? countryComponent.long_name : '';
  };

  useEffect(() => {
    if (!mapEle.current) {
      return;
    }

    const options = isCitiesLimited ? {
      types: ['(cities)'],
      componentRestrictions: { country: ['us', 'ca'] }
    } : {};
    map.current = new google.maps.places.Autocomplete(mapEle.current, options);
    map.current.setFields([
      'address_components',
      'formatted_address',
      'geometry'
    ]);

    google.maps.event.addListenerOnce(map.current, 'place_changed', () => {
      if (map.current && onChange) {
        const place = map.current.getPlace();
        let city = '';
        let state = '';
        let country = '';
        geocoder.geocode({ address: place.formatted_address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const addressComponents = results[0].address_components;
            city = getCity(addressComponents);
            state = getState(addressComponents);
            country = getCountry(addressComponents);
          }
          onChange({
            address: place.formatted_address,
            lat: place.geometry && place.geometry.location.lat(),
            lng: place.geometry && place.geometry.location.lng(),
            city,
            state,
            country
          });
        });
      }
    });
  }, [onChange]);

  useEffect(() => {
    setAddress(value || '');
  }, [value]);

  useEffect(() => {
    if (!address && onChange) {
      onChange(null);
    }
  }, [address]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <input
        ref={mapEle}
        value={address}
        placeholder={isRequired ? '* Enter a location...' : 'Enter a location...'}
        onChange={(e): void => setAddress(e.target.value)}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
          outline: 'none'
        }}
      />
    </div>
  );
};

PlaceSearchInput.defaultProps = {
  isRequired: false,
  isCitiesLimited: false,
  value: '',
  onChange: (): void => {}
};

PlaceSearchInput.propTypes = {
  isRequired: PropTypes.bool,
  isCitiesLimited: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default PlaceSearchInput;
