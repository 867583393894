import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLoading,
  IonListHeader,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/react';

import connect from '../../data/connect';
import {
  Charge, ChargeStatus, Transfer, User, UserRole
} from '../../models';
import { fetchCharges, createTransfer } from '../../data/dataApi';
import PaymentItem from '../../components/PaymentItem';
import TransferItem from '../../components/TransferItem';
import { WalletIcon } from '../../icons';

import './PaymentInformation.scss';

interface StateProps {
  user: User
}

type PaymentInformationProps = StateProps;

const PaymentInformation: React.FC<PaymentInformationProps> = ({
  user
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [charges, setCharges] = useState([] as Charge[]);
  const [transfers, setTransfers] = useState([] as Transfer[]);

  const getPaymentHistory = (): void => {
    setLoading(true);
    fetchCharges()
      .then((res: { success: boolean, charges: Charge[], transfers: Transfer[] }) => {
        if (res.success) {
          setCharges(res.charges);
          setTransfers(res.transfers);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const getChargedPrice = (): number => charges
    .filter((charge) => charge.status === ChargeStatus.CHARGED)
    .reduce((total, charge) => {
      if (user.role === UserRole.CUSTOMER) {
        return (total * 10000 + charge.chargedAmount * 10000) / 10000;
      }
      return (total * 10000 + charge.providerFee * 10000) / 10000;
    }, 0);

  const getHoldPrice = (): number => charges
    .filter((charge) => charge.status === ChargeStatus.HOLD)
    .reduce((total, charge) => total + charge.amount, 0);

  const getCPChargedPrice = (): number => charges
    .filter((charge) => charge.status === ChargeStatus.CHARGED || charge.status === ChargeStatus.REFUNDED)
    .reduce((total, charge) => (total * 10000 + charge.cpFee * 10000) / 10000, 0);

  const getTPrice = (): number => transfers.reduce((a, t) => a + t.amount, 0);

  const onTransfer = (): void => {
    const amount = (getChargedPrice() * 10000 - getTPrice() * 10000) / 10000;
    if (amount > 0) {
      setLoading(true);
      createTransfer(amount)
        .then(() => {
          getPaymentHistory();
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  return (
    <IonContent id="payment-information" className="provider-schedule-content page-content ion-padding">
      <div className="payment-information-header">
        <IonListHeader className="page-header">Account Overview</IonListHeader>
        {['provider', 'admin', 'clinic-admin'].includes(user.role) ? (
          <IonButton
            color="favorite"
            fill="clear"
            onClick={() => history.push(`/${user.role}/wallet`)}
          >
            View Bank Accounts
          </IonButton>
        ) : null}
      </div>
      {user.role === UserRole.CUSTOMER ? (
        <IonGrid>
          <IonRow className="payment-summary">
            <IonList>
              <IonItem lines="none">
                <div className="payment-option">
                  <div className="option-title">
                    <WalletIcon />
                    Total Charges
                  </div>
                  {`$${(
                    getChargedPrice() - getTPrice()
                  )} USD`}
                </div>
              </IonItem>
              <IonItem lines="none">
                <div className="payment-option">
                  <div className="option-title">
                    <WalletIcon />
                    Total Hold
                  </div>
                  {`$${(
                    getHoldPrice()
                  )} USD`}
                </div>
              </IonItem>
            </IonList>
          </IonRow>
        </IonGrid>
      ) : null}
      {user.role === UserRole.PROVIDER || user.role === UserRole.CLINIC_ADMIN ? (
        <IonGrid>
          <IonRow className="payment-summary">
            <IonList>
              <IonItem lines="none">
                <div className="payment-option">
                  <div className="option-title">
                    <WalletIcon />
                    Total Balance
                  </div>
                  {`$${(
                    (getChargedPrice() * 1000 - getTPrice() * 1000) / 1000
                  )} USD`}
                </div>
              </IonItem>
              <IonItem lines="none">
                <div className="payment-option">
                  <div className="option-title">
                    <WalletIcon />
                    Currently in Transit
                  </div>
                  {`$${getTPrice()} USD`}
                </div>
              </IonItem>
              <IonItem lines="none">
                <div className="payment-option">
                  <div className="option-title">
                    <WalletIcon />
                    Available to Payout
                  </div>
                  {`$${getChargedPrice()} USD`}
                </div>
              </IonItem>
              <IonItem lines="none">
                <div className="payment-option">
                  <div className="option-title">
                    <WalletIcon />
                    Available soon
                  </div>
                  $0.0 USD
                </div>
              </IonItem>
            </IonList>
          </IonRow>
        </IonGrid>
      ) : null}
      {user.role === UserRole.ADMIN || user.role === UserRole.REVENUE_ANALYST || user.role === UserRole.HELPDESK ? (
        <IonGrid>
          <IonRow className="payment-summary">
            <IonList>
              <IonItem lines="none">
                <div className="payment-option">
                  <div className="option-title">
                    <WalletIcon />
                    Total Balance
                  </div>
                  {`$${(
                    getCPChargedPrice() - getTPrice()
                  )} USD`}
                </div>
              </IonItem>
              <IonItem lines="none">
                <div className="payment-option">
                  <div className="option-title">
                    <WalletIcon />
                    Currently in Transit
                  </div>
                  {`$${getTPrice()} USD`}
                </div>
              </IonItem>
              <IonItem lines="none">
                <div className="payment-option">
                  <div className="option-title">
                    <WalletIcon />
                    Available to Payout
                  </div>
                  {`$${getCPChargedPrice()} USD`}
                </div>
              </IonItem>
              <IonItem lines="none">
                <div className="payment-option">
                  <div className="option-title">
                    <WalletIcon />
                    Available soon
                  </div>
                  $0.0 USD
                </div>
              </IonItem>
            </IonList>
          </IonRow>
        </IonGrid>
      ) : null}
      {user.role === UserRole.CUSTOMER ? (
        <IonGrid>
          <IonRow>
            <div className="sub-title">Transaction Details</div>
          </IonRow>
          <IonRow className="table-header">
            <IonCol className="table-header-cell sm">
              No
            </IonCol>
            <IonCol className="table-header-cell sm">
              Booking ID
            </IonCol>
            <IonCol className="table-header-cell">
              Provider
            </IonCol>
            <IonCol className="table-header-cell">
              Date
            </IonCol>
            <IonCol className="table-header-cell">
              Service
            </IonCol>
            <IonCol className="table-header-cell">
              Provider
            </IonCol>
            <IonCol className="table-header-cell">
              Customer
            </IonCol>
            <IonCol className="table-header-cell">
              Refund
            </IonCol>
            <IonCol className="table-header-cell">
              CP Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Stripe Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Twilio Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Reason
            </IonCol>
            <IonCol className="table-header-cell">
              Status
            </IonCol>
            <IonCol className="table-header-cell">
              Receipt
            </IonCol>
          </IonRow>
          {charges
            .slice() // Create a shallow copy of the charges array to avoid mutating the original array
            .reverse() // Reverse the array to get descending order
            .map((charge, index) => (
              <IonRow key={charge.id} className="payment-item">
                <PaymentItem
                  index={charges.length - index} // Use array index + 1 as the descending index
                  charge={charge}
                  role={user.role}
                />
              </IonRow>
            ))}
        </IonGrid>
      ) : null}
      {user.role === UserRole.PROVIDER ? (
        <IonGrid>
          <IonRow>
            <div className="sub-title">Transaction Details</div>
          </IonRow>
          <IonRow className="table-header">
            <IonCol className="table-header-cell sm">
              No
            </IonCol>
            <IonCol className="table-header-cell sm">
              Booking ID
            </IonCol>
            <IonCol className="table-header-cell">
              Customer
            </IonCol>
            <IonCol className="table-header-cell">
              Date
            </IonCol>
            <IonCol className="table-header-cell">
              Service
            </IonCol>
            <IonCol className="table-header-cell">
              Provider
            </IonCol>
            <IonCol className="table-header-cell">
              Customer
            </IonCol>
            <IonCol className="table-header-cell">
              Refund
            </IonCol>
            <IonCol className="table-header-cell">
              CP Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Stripe Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Twilio Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Reason
            </IonCol>
            <IonCol className="table-header-cell">
              Status
            </IonCol>
            <IonCol className="table-header-cell">
              Receipt
            </IonCol>
          </IonRow>
          {charges
            .slice() // Create a shallow copy of the charges array to avoid mutating the original array
            .reverse() // Reverse the array to get descending order
            .map((charge, index) => (
              <IonRow key={charge.id} className="payment-item">
                <PaymentItem
                  index={charges.length - index} // Use array index + 1 as the descending index
                  charge={charge}
                  role={user.role}
                />
              </IonRow>
            ))}
          {transfers.map((transfer, index) => (
            <IonRow key={transfer.id} className="payment-item">
              <TransferItem
                index={charges.length + index + 1}
                transfer={transfer}
                role={user.role}
              />
            </IonRow>
          ))}
        </IonGrid>
      ) : null}
      {user.role === UserRole.CLINIC_ADMIN ? (
        <IonGrid>
          <IonRow>
            <div className="sub-title">Transaction Details</div>
          </IonRow>
          <IonRow className="table-header">
            <IonCol className="table-header-cell sm">
              No
            </IonCol>
            <IonCol className="table-header-cell sm">
              Booking ID
            </IonCol>
            <IonCol className="table-header-cell">
              Customer
            </IonCol>
            <IonCol className="table-header-cell">
              Date
            </IonCol>
            <IonCol className="table-header-cell">
              Service
            </IonCol>
            <IonCol className="table-header-cell">
              Provider
            </IonCol>
            <IonCol className="table-header-cell">
              Customer
            </IonCol>
            <IonCol className="table-header-cell">
              Refund
            </IonCol>
            <IonCol className="table-header-cell">
              CP Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Stripe Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Twilio Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Member
            </IonCol>
            <IonCol className="table-header-cell">
              Reason
            </IonCol>
            <IonCol className="table-header-cell">
              Status
            </IonCol>
            <IonCol className="table-header-cell">
              Receipt
            </IonCol>
          </IonRow>
          {charges
            .slice() // Create a shallow copy of the charges array to avoid mutating the original array
            .reverse() // Reverse the array to get descending order
            .map((charge, index) => (
              <IonRow key={charge.id} className="payment-item">
                <PaymentItem
                  index={charges.length - index} // Use array index + 1 as the descending index
                  charge={charge}
                  role={user.role}
                />
              </IonRow>
            ))}
          {transfers.map((transfer, index) => (
            <IonRow key={transfer.id} className="payment-item">
              <TransferItem
                index={charges.length + index + 1}
                transfer={transfer}
                role={user.role}
              />
            </IonRow>
          ))}
        </IonGrid>
      ) : null}
      {user.role === UserRole.ADMIN || user.role === UserRole.REVENUE_ANALYST || user.role === UserRole.HELPDESK ? (
        <IonGrid>
          <IonRow>
            <div className="sub-title">Transaction Details</div>
          </IonRow>
          <IonRow className="table-header">
            <IonCol className="table-header-cell sm">
              No
            </IonCol>
            <IonCol className="table-header-cell sm">
              Booking ID
            </IonCol>
            <IonCol className="table-header-cell">
              Customer
            </IonCol>
            <IonCol className="table-header-cell">
              Date
            </IonCol>
            <IonCol className="table-header-cell">
              Service
            </IonCol>
            <IonCol className="table-header-cell">
              Provider
            </IonCol>
            <IonCol className="table-header-cell">
              Customer
            </IonCol>
            <IonCol className="table-header-cell">
              Refund
            </IonCol>
            <IonCol className="table-header-cell">
              CP Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Stripe Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Twilio Fee
            </IonCol>
            <IonCol className="table-header-cell">
              Provider
            </IonCol>
            <IonCol className="table-header-cell">
              Reason
            </IonCol>
            <IonCol className="table-header-cell">
              Status
            </IonCol>
            <IonCol className="table-header-cell">
              Receipt
            </IonCol>
          </IonRow>
          {charges
            .slice() // Create a shallow copy of the charges array to avoid mutating the original array
            .reverse() // Reverse the array to get descending order
            .map((charge, index) => (
              <IonRow key={charge.id} className="payment-item">
                <PaymentItem
                  index={charges.length - index} // Use array index + 1 as the descending index
                  charge={charge}
                  role={user.role}
                />
              </IonRow>
            ))}
          {transfers.map((transfer, index) => (
            <IonRow key={transfer.id} className="payment-item">
              <TransferItem
                key={transfer.id}
                index={charges.length + index + 1}
                transfer={transfer}
                role={user.role}
              />
            </IonRow>
          ))}
        </IonGrid>
      ) : null}
      {['provider', 'admin', 'clinic-admin'].includes(user.role) ? (
        <IonGrid>
          <IonRow>
            <IonButton
              color="favorite"
              disabled={!user.method || user.method.status !== 'active'}
              onClick={onTransfer}
            >
              Transfer
            </IonButton>
          </IonRow>
          <br />
          {!user.method || user.method.status !== 'active' ? (
            <IonRow>
              {!user.method ? (
                <IonLabel color="danger">
                  *Please add your own payment account
                </IonLabel>
              ) : null}
              {user.method && user.method.status === 'pending' ? (
                <IonLabel color="danger">
                  *Please verify your payment account
                </IonLabel>
              ) : null}
              {user.method && user.method.status === 'blocked' ? (
                <IonLabel color="danger">
                  *Your payment account has been blocked
                </IonLabel>
              ) : null}
            </IonRow>
          ) : null}
        </IonGrid>
      ) : null}

      <IonLoading isOpen={loading} />
    </IonContent>
  );
};

PaymentInformation.propTypes = {
  user: PropTypes.any.isRequired
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user,
  }),
  component: React.memo(PaymentInformation)
});
