import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonAlert,
  IonLoading,
} from '@ionic/react';

import connect from '../../data/connect';
import { createStripeCard } from '../../data/dataApi';
import './CreditCardView.scss';
import CardForm from '../../components/CardForm';
import { dispatchUpdateUser } from '../../data/store/auth/auth.actions';
import { User } from '../../models';

interface StateProps {
  user: User
}
interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

type CreditCardViewProps = RouteComponentProps & StateProps & DispatchProps;

const CreditCardView: React.FC<CreditCardViewProps> = ({
  user,
  history,
  updateUser
}) => {
  const [loading, setLoading] = useState(false);
  const [newCard, setNewCard] = useState('');
  const [error, setError] = useState('');
  const [isExist, setIsExist] = useState(false);

  const handleToken = (token: string): void => {
    if (token) {
      setLoading(true);
      createStripeCard(token)
        .then((res) => {
          setNewCard(res.card.last4);
          updateUser({ ...user, sources: [...user.sources, res.card] });
          setError('');
        })
        .catch((err) => {
          setError(err?.response?.data?.msg ?? '');
          if (err?.response?.status === 409) {
            setIsExist(true);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <IonContent id="CreditCardView" className="page-content ion-padding">
      <CardForm handleToken={(res: any): void => handleToken(res.token.id)} />
      <IonAlert
        isOpen={!!newCard}
        onDidDismiss={(): void => {
          setNewCard('');
          history.goBack();
        }}
        header="Care Platform"
        message={`Card ending with ...${newCard} is added`}
        buttons={['OK']}
      />
      <IonAlert
        isOpen={!!error}
        onDidDismiss={(): void => {
          setNewCard('');
          setError('');
          if (isExist) {
            history.goBack();
          }
        }}
        header="Care Platform"
        message={error}
        buttons={['OK']}
      />

      <IonLoading isOpen={loading} />
    </IonContent>
  );
};

CreditCardView.propTypes = {
  user: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(CreditCardView)
});
