import React, { useRef, useEffect } from 'react';
import { Track } from 'twilio-video';

import { IVideoTrack } from '../../../data/VideoTypes';
import './VideoTrack.scss';

interface VideoTrackProps {
  track: IVideoTrack
  isLocal?: boolean
  priority?: Track.Priority | null
}

export default function VideoTrack({
  track,
  isLocal,
  priority
}: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    const el = ref.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored.
  const style = isLocal ? { transform: 'rotateY(180deg)' } : {};

  return <video className="video-track-container" ref={ref} style={style} />;
}
