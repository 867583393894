import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WalletIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M17.0833 4.99967H4.58333C3.92583 4.99967 3.30833 4.68634 2.91667 4.16717C3.2975 3.66134 3.90333 3.33301 4.58333 3.33301H18.75C20.3933 3.30467 20.3925 0.860508 18.75 0.833008H4.58333C2.05667 0.833008 0 2.88884 0 5.41634V14.583C0 17.1105 2.05667 19.1663 4.58333 19.1663H17.0833C18.6917 19.1663 20 17.858 20 16.2497V7.91634C20 6.30801 18.6917 4.99967 17.0833 4.99967ZM17.5 16.2497C17.5 16.4797 17.3125 16.6663 17.0833 16.6663H4.58333C3.43417 16.6663 2.5 15.7313 2.5 14.583V6.99634C3.13583 7.32134 3.84833 7.49967 4.58333 7.49967H17.0833C17.3125 7.49967 17.5 7.68634 17.5 7.91634V16.2497ZM15.8333 12.083C15.805 13.7263 13.3608 13.7255 13.3333 12.083C13.3617 10.4397 15.8058 10.4405 15.8333 12.083Z" fill={color} />
  </svg>
);

export default WalletIcon;
