import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonModal,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonAlert,
  IonLoading,
  IonIcon
} from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';

import { resetPassword } from '../../data/dataApi';
import { User } from '../../models';

interface ChangePasswordProps {
  user: User
  isOpen: boolean
  toggleModal: (value: boolean) => void
}

const ChangePasswordModal: React.FC<ChangePasswordProps> = ({
  user, isOpen, toggleModal
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isMatched, setIsMatched] = useState(true);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onUpdatePassword = () => {
    setErrorMessage('');
    if (newPassword !== confirmPassword) {
      setIsMatched(false);
    } else {
      setIsLoading(true);
      resetPassword(user.id, {
        oldPassword,
        newPassword
      })
        .then(() => {
          setShowSuccessAlert(true);
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data?.msg || 'Something went wrong, please try again later.');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const isSubmitDisabled = useMemo(() => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      return true;
    }
    return false;
  }, [oldPassword, newPassword, confirmPassword]);

  useEffect(() => {
    setNewPassword('');
    setOldPassword('');
    setConfirmPassword('');
  }, []);

  return (
    <IonModal
      cssClass="change-password-modal"
      isOpen={isOpen}
      onDidDismiss={() => toggleModal(false)}
    >
      <IonList>
        <IonItem lines="none">
          <IonLabel>Change Password</IonLabel>
        </IonItem>
        {errorMessage ? (
          <IonLabel color="danger">
            <span>{errorMessage}</span>
          </IonLabel>
        ) : null}
        <IonItem lines="none" className="input">
          <IonInput
            name="old-password"
            type={showOldPassword ? 'text' : 'password'}
            placeholder="Old Password"
            value={oldPassword}
            onIonChange={(e): void => setOldPassword(e.detail.value || '')}
          />
          <IonIcon icon={showOldPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowOldPassword(!showOldPassword)} />
        </IonItem>
        <IonItem lines="none" className="input">
          <IonInput
            name="new-password"
            type={showNewPassword ? 'text' : 'password'}
            placeholder="New Password"
            value={newPassword}
            onIonChange={(e): void => {
              setIsMatched(true);
              setNewPassword(e.detail.value || '');
            }}
          />
          <IonIcon icon={showNewPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowNewPassword(!showNewPassword)} />
        </IonItem>
        <IonItem lines="none" className="input">
          <IonInput
            name="new-password-confirm"
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder="Confirm New Password"
            value={confirmPassword}
            onIonChange={(e): void => {
              setIsMatched(true);
              setConfirmPassword(e.detail.value || '');
            }}
          />
          <IonIcon icon={showConfirmPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
        </IonItem>
        {isMatched ? null : (
          <IonLabel color="danger">
            <span>Password not matched</span>
          </IonLabel>
        )}
        <IonItem lines="none">
          <IonButton color="favorite" disabled={isSubmitDisabled} onClick={onUpdatePassword}>
            Update password
          </IonButton>
        </IonItem>
      </IonList>

      <IonAlert
        isOpen={showSuccessAlert}
        onDidDismiss={(): void => {
          setShowSuccessAlert(false);
          toggleModal(false);
        }}
        header="Care Platform"
        message="Password changed successfully."
        buttons={['Ok']}
      />

      <IonLoading isOpen={isLoading} />

    </IonModal>
  );
};

ChangePasswordModal.propTypes = {
  user: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default React.memo(ChangePasswordModal);
