import React from 'react';
import { IonIcon } from '@ionic/react';
import clsx from 'clsx';
import { LocalVideoTrack, Participant, RemoteVideoTrack } from 'twilio-video';
import {
  micOff, desktop, videocam, camera
} from 'ionicons/icons';

import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import NetworkQualityLevel from '../NewtorkQualityLevel/NetworkQualityLevel';
import ParticipantConnectionIndicator from './ParticipantConnectionIndicator/ParticipantConnectionIndicator';
import PinIcon from './PinIcon/PinIcon';

import useParticipantNetworkQualityLevel from '../../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import usePublications from '../../../hooks/usePublications/usePublications';
import useIsTrackSwitchedOff from '../../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublicationIsTrackEnabled from '../../../hooks/usePublicationIsTrackEnabled/usePublicationIsTrackEnabled';
import useTrack from '../../../hooks/useTrack/useTrack';
import './ParticipantInfo.scss';

interface ParticipantInfoProps {
  participant: Participant
  children: React.ReactNode
  onClick: () => void
  isSelected: boolean
}

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children
}: ParticipantInfoProps) {
  const publications = usePublications(participant);

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const videoPublication = publications.find((p) => p.trackName === 'camera');

  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  const isAudioEnabled = usePublicationIsTrackEnabled(audioPublication);
  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(
    (p) => p.trackName === 'screen'
  );

  const videoTrack = useTrack(videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(
    videoTrack as LocalVideoTrack | RemoteVideoTrack
  );

  return (
    <div
      className={clsx('container', {
        isVideoSwitchedOff
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
    >
      <div className={clsx('infoContainer', { hideVideo: !isVideoEnabled })}>
        <div className="infoRow">
          <h4 className="identity">
            <ParticipantConnectionIndicator participant={participant} />
            {participant.identity}
          </h4>
          <NetworkQualityLevel qualityLevel={networkQualityLevel} />
        </div>
        <div>
          {!isAudioEnabled && <IonIcon icon={micOff} color="primary" />}
          {!isVideoEnabled && <IonIcon icon={videocam} color="primary" />}
          {isScreenShareEnabled && <IonIcon icon={desktop} color="primary" />}
          {isSelected && <PinIcon />}
        </div>
      </div>
      {isVideoSwitchedOff && <BandwidthWarning />}
      {children}
    </div>
  );
}
