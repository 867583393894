import React, {
  useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  IonButton,
  IonIcon,
  IonLoading
} from '@ionic/react';
import {
  chevronBackOutline,
  chevronForwardOutline
} from 'ionicons/icons';

import connect from '../../../data/connect';
import { Service, User, UserRole } from '../../../models';
import { updateProviderProfile } from '../../../data/dataApi';
import { dispatchUpdateUser } from '../../../data/store/auth/auth.actions';
import ServiceManagement from '../../../components/ServiceManagement';

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

interface ThirdStepProps {
  user: User
  next: () => void
  prev: () => void
}

const ThirdStep: React.FC<ThirdStepProps & DispatchProps> = ({
  user, next, prev, updateUser
}) => {
  const [services, setServices] = useState<Service[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    setIsLoading(true);
    const nextStep = user.role === UserRole.PROVIDER ? 3 : 4;
    updateProviderProfile(user.id, {
      profile: {
        onboardingStep: user.onboardingStep > nextStep ? user.onboardingStep : nextStep
      },
      languages: user.languages.map((language) => language.id),
      specialists: user.specialists.map((specialist) => specialist.id),
      locations: user.locations,
      services
    })
      .then((res) => {
        if (res.success) {
          updateUser(res.user);
          next();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const isSubmitDisabled = useMemo(() => {
    if (!services.length) {
      return true;
    }
    return false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services]);

  useEffect(() => {
    if (user && user.services) {
      setServices(user.services);
    }
  }, [user]);

  return (
    <div id="step-three">
      <ServiceManagement
        cssClass="step-content"
        services={services}
        updateServices={setServices}
      />
      <div className="actions">
        <IonButton color="favorite" onClick={prev}>
          <IonIcon icon={chevronBackOutline} />
          Back
        </IonButton>
        <IonButton color="favorite" disabled={isSubmitDisabled} onClick={onSubmit}>
          Next
          <IonIcon icon={chevronForwardOutline} />
        </IonButton>
      </div>
      <IonLoading isOpen={isLoading} />
    </div>
  );
};

ThirdStep.propTypes = {
  user: PropTypes.any.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<ThirdStepProps, {}, {}>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(ThirdStep)
});
