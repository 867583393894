import { createSelector } from 'reselect';
import {
  Booking, BookingStatus, UserRole, User, Message
} from '../models';
import { AppState } from './state';

export const getProviders = (state: AppState): User[] => state.data.providers;
export const getMembers = (state: AppState): User[] => state.data.members;
const getBookings = (state: AppState): Booking[] => state.data.bookings;
const getNotifications = (state: AppState): Message[] => state.data.notifications;
export const getActiveBookings = (state: AppState): Booking[] => state.data.bookings.filter(
  ({ status }) => status === BookingStatus.ACCEPTED || status === BookingStatus.PENDING
);

const getIdParam = (_state: AppState, props: any): number => {
  const stringParam = props.match.params.id;
  return parseInt(stringParam, 10);
};

const getTypeParam = (state: AppState, props: any): string => props.match.params.providerTypeId;
const getLocationParam = (state: AppState, props: any): number => parseInt(props.match.params.location, 10);
const getServiceParam = (state: AppState, props: any): number => parseInt(props.match.params.service, 10);
const getServiceItemParam = (state: AppState, props: any): number => parseInt(props.match.params.item, 10);

export const getProvidersByType = createSelector(
  getProviders,
  getTypeParam,
  (providers, providerTypeId) => providers.filter((provider) => provider.type === parseInt(providerTypeId, 10))
);

export const getProvider = createSelector(
  getProviders,
  getIdParam,
  (providers, id) => providers.find((provider) => provider.id === id)
);

export const getMember = createSelector(getMembers, getIdParam, (members, id) => members.find((member) => member.id === id));

export const getLocation = createSelector(
  getProvider,
  getLocationParam,
  (provider, id) => provider && provider.locations.find((location) => location.id === id)
);

export const getService = createSelector(
  getProvider,
  getServiceParam,
  (provider, id) => provider && provider.services.find((service) => service.id === id)
);

export const getServiceItem = createSelector(
  getService,
  getServiceItemParam,
  (service, id) => service && service.items!.find((item) => item.id === id)
);

export const getBooking = createSelector(
  getBookings,
  getIdParam,
  (bookings, id) => bookings.find((booking) => booking.id === id)
);

export const getProviderRating = createSelector(
  getBooking,
  (booking) => booking && booking.feedbacks.find((item) => item.type === UserRole.PROVIDER)
);

export const getCustomerRating = createSelector(
  getBooking,
  (booking) => booking && booking.feedbacks.find((item) => item.type === UserRole.CUSTOMER)
);

export const getBookingsForDisplay = createSelector(
  getProviders,
  getBookings,
  (providers, bookings) => bookings.map(
    (booking) => ({
      ...booking,
      provider: providers.find(
        (provider) => provider.id === booking.providerId
      )
    } as Booking)
  )
);

export const getUnreadCount = createSelector(getNotifications, (items) => items.reduce((acc, item) => acc + (item.isRead ? 0 : 1), 0));
