/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, Suspense
} from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonLoading,
} from '@ionic/react';
import { alertCircle } from 'ionicons/icons';

import connect from '../../data/connect';
import ClinicDetail from '../ClinicDetail';
import ClinicSchedule from '../ClinicSchedule';
import CustomerDashboard from '../CustomerDashboard';
import Favorites from '../Favorites';
import ProvidersList from '../ProvidersList';
import Bookings from '../Bookings';
import ProviderList from '../ProviderList';
import ProviderDetail from '../ProviderDetail';
import ProviderSchedule from '../ProviderSchedule';
import ProviderScheduleConfirm from '../ProviderScheduleConfirm';
import Notifications from '../Notifications';
import Wallet from '../Wallet';
import PaymentInformation from '../PaymentInformation';
import CreditCardView from '../CreditCardView';
import ProviderRating from '../ProviderRating';
import ClinicMemberList from '../ClinicMemberList';
import CustomerProfile from '../CustomerProfile';
import ProviderTypeView from '../ProviderTypeView';
import ContactUs from '../ContactUs';
import UserReport from '../UserReport';
import BookingReport from '../BookingReport';

import CustomerViewLayout from '../../components/Layout';
import {
  dispatchFetchBookings,
  dispatchFetchLanguages,
  dispatchFetchSpecialists,
  dispatchFetchProviderTypes,
  dispatchFetchCategories,
  dispatchGetAllNotification,
  dispatchFetchProviders,
  dispatchFetchFavoriteProviders
} from '../../data/store/data/data.actions';
import { getUnreadCount } from '../../data/selectors';
import { socket } from '../../data/dataApi';
import { User } from '../../models';
import useGetView from '../../hooks/useGetView/useGetView';

import './CustomerView.scss';

interface StateProps {
  user: User;
  unreadCount: number;
}

interface DispatchProps {
  fetchProviders: typeof dispatchFetchProviders;
  fetchFavoriteProviders: typeof dispatchFetchFavoriteProviders;
  fetchBookings: typeof dispatchFetchBookings;
  fetchLanguages: typeof dispatchFetchLanguages;
  fetchSpecialists: typeof dispatchFetchSpecialists;
  fetchProviderTypes: typeof dispatchFetchProviderTypes;
  fetchCategories: typeof dispatchFetchCategories;
  getAllNotification: typeof dispatchGetAllNotification;
}

type CustomerViewProps = StateProps & DispatchProps;

const CustomerView: React.FC<CustomerViewProps> = ({
  user, unreadCount, fetchProviders, fetchFavoriteProviders, fetchBookings, fetchLanguages, fetchSpecialists, fetchCategories, fetchProviderTypes, getAllNotification
}) => {
  const [tab, setTab] = useState(0);
  const { isMobile } = useGetView();

  useEffect(() => {
    fetchProviders();
    fetchBookings();
  }, [fetchProviders, fetchBookings]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    fetchSpecialists();
  }, [fetchSpecialists]);

  useEffect(() => {
    fetchProviderTypes();
  }, [fetchProviderTypes]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    socket.on('new-message', (e: any) => {
      fetchBookings();
      getAllNotification();
    });
  }, []);

  useEffect(() => {
    fetchFavoriteProviders(user.id);
  }, []);

  const renderNotificationIcon = (): JSX.Element => {
    if (tab === 3) {
      return <IonIcon icon={alertCircle} color="primary" />;
    }
    if (unreadCount) {
      return <IonIcon icon={alertCircle} color="danger" />;
    }
    return <IonIcon icon={alertCircle} color="medium" />;
  };

  const renderRoutes = () => (
    <IonRouterOutlet>
      <CustomerViewLayout>
        <Route path="/customer" render={() => <Redirect to="/customer/dashboard" />} exact />
        <Route path="/customer/dashboard" component={CustomerDashboard} exact />
        <Route path="/customer/dashboard/category/:providerTypeId" component={ProviderTypeView} exact />
        <Route path="/customer/dashboard/category/:providerTypeId/specialist/:specialistId" component={ProviderList} exact />
        <Route path="/customer/providers" component={ProvidersList} exact />
        <Route path="/customer/providers/:type" component={ProviderList} exact />
        <Route path="/customer/favorites" component={Favorites} exact />
        <Route path="/customer/provider/:id" component={ProviderDetail} exact />
        <Route
          path="/customer/provider/:id/schedule"
          component={ProviderSchedule}
          exact
        />
        <Route
          path="/customer/provider/:id/confirm-schedule"
          component={ProviderScheduleConfirm}
          exact
        />
        <Route path="/customer/bookings" component={Bookings} exact />
        <Route path="/customer/notification" component={Notifications} exact />
        <Route path="/customer/wallet" component={Wallet} exact />
        <Route path="/customer/payment" component={PaymentInformation} exact />
        <Route
          path="/customer/new-payment-source"
          component={CreditCardView}
          exact
        />
        <Route
          path="/customer/rate-booking/:id"
          component={ProviderRating}
          exact
        />
        <Route path="/customer/clinic/:id" component={ClinicDetail} exact />
        <Route
          path="/customer/clinic/:id/schedule"
          component={ClinicSchedule}
          exact
        />
        <Route
          path="/customer/clinic/:id/members"
          component={ClinicMemberList}
          exact
        />
        <Route
          path="/customer/report"
          component={UserReport}
          exact
        />
        <Route
          path="/customer/report/booking/:id"
          component={BookingReport}
          exact
        />
        <Route path="/customer/profile" component={CustomerProfile} exact />
        {/* <Route path="/customer/video" component={VideoApp} exact /> */}
        <Route path="/customer/contact-us" component={ContactUs} exact />
      </CustomerViewLayout>
    </IonRouterOutlet>
  );

  if (!isMobile) {
    return (
      <Suspense fallback={<IonLoading isOpen />}>
        {renderRoutes()}
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<IonLoading isOpen />}>
      <IonTabs>
        {renderRoutes()}
        <IonTabBar slot="bottom">
          <IonTabButton
            id="customer-view-dashboard"
            tab="schedule"
            href="/customer/dashboard"
            onClick={(): void => setTab(0)}
          >
            <IonIcon src="assets/img/icon/dashboard.svg" />
            <IonLabel>Dashboard</IonLabel>
          </IonTabButton>
          <IonTabButton
            id="customer-view-favorites"
            tab="speakers"
            href="/customer/favorites"
            onClick={(): void => setTab(1)}
          >
            <IonIcon src="assets/img/icon/favorites.svg" />
            <IonLabel>My Favorites</IonLabel>
          </IonTabButton>
          <IonTabButton
            id="customer-view-bookings"
            tab="map"
            href="/customer/bookings"
            onClick={(): void => setTab(2)}
          >
            <IonIcon src="assets/img/icon/bookings.svg" />
            <IonLabel>My Bookings</IonLabel>
          </IonTabButton>
          <IonTabButton
            id="customer-view-notifications"
            tab="about"
            href="/customer/notification"
            onClick={(): void => setTab(3)}
          >
            {renderNotificationIcon()}
            <IonLabel>Notifications</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </Suspense>
  );
};

CustomerView.propTypes = {
  user: PropTypes.any.isRequired,
  unreadCount: PropTypes.number.isRequired,
  fetchProviders: PropTypes.func.isRequired,
  fetchBookings: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  fetchSpecialists: PropTypes.func.isRequired,
  fetchProviderTypes: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  getAllNotification: PropTypes.func.isRequired,
  fetchFavoriteProviders: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user,
    unreadCount: getUnreadCount(state)
  }),
  mapDispatchToProps: {
    fetchProviders: dispatchFetchProviders,
    fetchFavoriteProviders: dispatchFetchFavoriteProviders,
    fetchBookings: dispatchFetchBookings,
    fetchLanguages: dispatchFetchLanguages,
    fetchSpecialists: dispatchFetchSpecialists,
    fetchProviderTypes: dispatchFetchProviderTypes,
    fetchCategories: dispatchFetchCategories,
    getAllNotification: dispatchGetAllNotification
  },
  component: React.memo(CustomerView)
});
