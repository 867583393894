import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonRow
} from '@ionic/react';
import { chevronForwardOutline, eyeOffOutline, eyeOutline } from 'ionicons/icons';

import connect from '../../../data/connect';
import { resetPassword, updateProviderProfile } from '../../../data/dataApi';
import { User } from '../../../models';
import { dispatchUpdateUser } from '../../../data/store/auth/auth.actions';

interface UpdatePasswordProps {
  user: User
  next: () => void
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

const UpdatePassword: React.FC<UpdatePasswordProps & DispatchProps> = ({ user, next, updateUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isMatched, setIsMatched] = useState(true);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onUpdatePassword = () => {
    setErrorMessage('');
    if (newPassword !== confirmPassword) {
      setIsMatched(false);
    } else {
      setIsLoading(true);
      resetPassword(user.id, {
        oldPassword,
        newPassword
      })
        .then(() => {
          setShowSuccessAlert(true);
        })
        .catch((error) => {
          setErrorMessage(error?.response?.data?.msg || 'Something went wrong, please try again later.');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const onNext = () => {
    updateProviderProfile(user.id, {
      profile: {
        onboardingStep: 1
      },
      languages: user.languages.map((language) => language.id),
      specialists: user.specialists.map((specialist) => specialist.id),
      locations: user.locations ?? [],
      services: user.services ?? []
    })
      .then((res) => {
        if (res.success) {
          updateUser(res.user);
          next();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const isSubmitDisabled = useMemo(() => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      return true;
    }
    return false;
  }, [oldPassword, newPassword, confirmPassword]);

  useEffect(() => {
    setNewPassword('');
    setOldPassword('');
    setConfirmPassword('');
  }, []);

  return (
    <div id="step-update-password">
      <IonGrid className="step-content">
        <IonRow>
          <IonCol>
            <IonList>
              {errorMessage ? (
                <IonLabel color="danger">
                  <span>{errorMessage}</span>
                </IonLabel>
              ) : null}
              <IonItem lines="none" className="input">
                <IonInput
                  name="old-password"
                  type={showOldPassword ? 'text' : 'password'}
                  placeholder="Old Password"
                  value={oldPassword}
                  onIonChange={(e): void => setOldPassword(e.detail.value || '')}
                />
                <IonIcon icon={showOldPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowOldPassword(!showOldPassword)} />
              </IonItem>
              <IonItem lines="none" className="input">
                <IonInput
                  name="new-password"
                  type={showNewPassword ? 'text' : 'password'}
                  placeholder="New Password"
                  value={newPassword}
                  onIonChange={(e): void => {
                    setIsMatched(true);
                    setNewPassword(e.detail.value || '');
                  }}
                />
                <IonIcon icon={showNewPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowNewPassword(!showNewPassword)} />
              </IonItem>
              <IonItem lines="none" className="input">
                <IonInput
                  name="new-password-confirm"
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onIonChange={(e): void => {
                    setIsMatched(true);
                    setConfirmPassword(e.detail.value || '');
                  }}
                />
                <IonIcon icon={showConfirmPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
              </IonItem>
              {isMatched ? null : (
                <IonLabel color="danger">
                  <span>Password not matched</span>
                </IonLabel>
              )}
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className="actions">
        <IonButton color="favorite" disabled={isSubmitDisabled} onClick={onUpdatePassword}>
          Next
          <IonIcon icon={chevronForwardOutline} />
        </IonButton>
      </div>
      <IonLoading isOpen={isLoading} />

      <IonAlert
        isOpen={showSuccessAlert}
        onDidDismiss={(): void => {
          setShowSuccessAlert(false);
          onNext();
        }}
        header="Care Platform"
        message="Password changed successfully."
        buttons={['Ok']}
      />
    </div>
  );
};

UpdatePassword.propTypes = {
  user: PropTypes.any.isRequired,
  next: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<UpdatePasswordProps, {}, DispatchProps>({
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(UpdatePassword)
});
