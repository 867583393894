import React from 'react';
import PropTypes from 'prop-types';

import ChatWindowHeader from './ChatWindowHeader/ChatWindowHeader';
import ChatInput from './ChatInput/ChatInput';
import MessageList from './MessageList/MessageList';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import { Booking } from '../../../models';

import './ChatWindow.scss';

interface ChatWindowProps {
  booking: Booking
}

const ChatWindow: React.FC<ChatWindowProps> = ({ booking }) => {
  const { isChatWindowOpen, messages, conversation } = useChatContext();

  if (!isChatWindowOpen) {
    return null;
  }

  return (
    <aside className="chat-board">
      <ChatWindowHeader />
      <MessageList messages={messages} />
      <ChatInput booking={booking} conversation={conversation} messages={messages} isChatWindowOpen={isChatWindowOpen} />
    </aside>
  );
};

ChatWindow.propTypes = {
  booking: PropTypes.any.isRequired
};

export default React.memo(ChatWindow);
