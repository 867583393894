import React from 'react';
import { IonItem, IonThumbnail, IonLabel } from '@ionic/react';

import { Booking } from '../models';
import StarRating from './StarRating';
import { getImage } from '../data/dataApi';

interface RatingItemProps {
  booking: Booking
}

const ClinicRatingItem: React.FC<RatingItemProps> = ({ booking }) => {
  const feedback = booking.feedbacks.find((item) => item.type === 'clinic');
  return (
    <>
      <IonItem lines="none" className="profile">
        <IonThumbnail slot="start" className="round-border image small">
          <img src={getImage(booking.customer.image)} alt="selfie" />
        </IonThumbnail>
        <IonLabel>
          <h2>{booking.customer.name}</h2>
          <p>{booking.provider.name + " " + booking.provider.lastName}</p>
          <p>
            {booking.date}
            {' '}
            {booking.time}
          </p>
          <StarRating readonly rating={feedback!.rating} />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>{feedback!.review}</IonLabel>
      </IonItem>
    </>
  );
};

export default ClinicRatingItem;
