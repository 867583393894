import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonAlert,
  IonModal,
  IonSpinner,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonTextarea,
} from '@ionic/react';
import moment from 'moment';
import axios from 'axios';

import Avatar from './Avatar';
import {
  AppointmentType, Booking, BookingStatus, BookingStatusLabels
} from '../models';
import {
  updateBooking,
  getRecords,
  socket,
  getReadyAppointment,
  exportBookingReceipt,
  startMeeting
} from '../data/dataApi';
import { SOCKET_KEYS } from '../data/constants';

interface ProviderBookingItemProps {
  booking: Booking
  currentDate: Date
  onUpdate?: (booking: Booking) => void
  onMeeting?: () => void
}

const ProviderBookingItem: React.FC<ProviderBookingItemProps> = ({
  booking,
  currentDate,
  onUpdate,
  onMeeting
}) => {
  const [showAcceptAlert, setShowAcceptAlert] = useState(false);
  const [showRejectAlert, setShowRejectAlert] = useState(false);
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [showInProgressAlert, setShowInProgressAlert] = useState(false);
  const [showReasonsModal, setShowReasonsModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [errorAlert, setErrorAlert] = useState({
    open: false,
    message: ''
  });

  const downloadRecords = async () => {
    if (booking.hasDownloadFile) {
      setIsDownloading(true);
      try {
        const { records } = await getRecords(booking.id);
        if (records && records.length > 0) {
          const promises: any[] = [];
          records.map((record: any) => {
            promises.push(
              axios.get(record.signedUrl, { responseType: 'blob' })
                .then(res => {
                  // Creating new object of PDF file
                  const fileURL = window.URL.createObjectURL(res.data);
                  // Setting various property values
                  let alink = document.createElement('a');
                  alink.href = fileURL;
                  alink.download = record.filename;
                  alink.click();
                })
                .catch((err) => console.log(err))
            );
          });
          await Promise.all(promises);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsDownloading(false);
      }
    } else {
      setShowInProgressAlert(true);
    }
  };

  const onCancel = () => {
    setIsLoading(true);
    const reason = selectedReason === 'other' ? otherReason : 'No available at that time';
    const updateStatus: BookingStatus = booking.status === BookingStatus.PENDING ? BookingStatus.REJECTED_PROVIDER : BookingStatus.CANCELLED_PROVIDER;
    updateBooking(booking.id, updateStatus, reason).then((res: { success: boolean }) => {
      if (res.success) {
        onUpdate?.({ ...booking, status: updateStatus });
        socket.emit(SOCKET_KEYS[updateStatus], booking.id, reason);
      }
    })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onUpdateBooking = (status: BookingStatus) => {
    setIsLoading(true);
    updateBooking(booking.id, status)
      .then((res: { success: boolean }) => {
        if (res.success) {
          onUpdate?.({ ...booking, status });
          socket.emit(SOCKET_KEYS[status], booking.id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onStartMeeting = () => {
    startMeeting(booking.id, false)
      .then((res: { success: boolean, booking: Booking }) => {
        if (res.success) {
          onUpdate?.({
            ...booking,
            customerConnected: true,
            providerConnected: true,
            callStartedAt: res.booking.callStartedAt
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setErrorAlert({
            open: true,
            message: 'Customer is not ready yet'
          });
        }
      });
  };

  const onReadyMeeting = async () => {
    try {
      await getReadyAppointment(booking.id);
      if (booking.type === AppointmentType.CUSTOMER_LOCATION) {
        socket.emit(SOCKET_KEYS['meeting-provider'], booking.id);
      }
      onUpdate?.({ ...booking, providerConnected: true });
    } catch (error) {
      console.log(error);
    }
  };

  const getBookingReceiptPDF = async () => {
    const url = await exportBookingReceipt(booking.id);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Booking_Receipt_${booking.id}.pdf`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  };

  const callEnabled = useMemo(() => {
    const minsRemaining = moment.utc(`${booking.date} ${booking.time}`).diff(moment(currentDate).utc(), 'minutes');
    if (minsRemaining > -1 * booking.duration && minsRemaining < 5) {
      return true;
    }
    return false;
  }, [currentDate, booking]);

  const cancelButtonDisabled = useMemo(() => {
    if (!selectedReason || (selectedReason === 'other' && !otherReason)) {
      return true;
    }
    return false;
  }, [selectedReason, otherReason]);

  const appointmentType = useMemo(() => {
    switch (booking.type) {
      case 'video-visit':
        return 'Virtual';
      case 'provider-visit':
        return 'Provider Location';
      case 'customer-visit':
        return 'Customer Location';
      default:
        return 'N|A';
    }
  }, [booking.type]);

  const renderActions = (): JSX.Element => {
    if (booking.status === BookingStatus.PENDING) {
      return (
        <>
          <IonButton
            color="favorite"
            expand="block"
            onClick={(): void => setShowAcceptAlert(true)}
          >
            Accept
          </IonButton>
          <IonButton
            color="dark"
            fill="outline"
            expand="block"
            onClick={(): void => setShowReasonsModal(true)}
          >
            Reject
          </IonButton>
        </>
      );
    }
    if (booking.status === BookingStatus.ACCEPTED) {
      return (
        <>
          {booking.type === AppointmentType.VIRTUAL ? (
            <IonButton
              color="favorite"
              expand="block"
              disabled={!callEnabled}
              onClick={(): void => onMeeting?.()}
            >
              Call
            </IonButton>
          ) : null}
          {booking.type === AppointmentType.CUSTOMER_LOCATION && !booking.providerConnected ? (
            <IonButton
              color="favorite"
              expand="block"
              disabled={!callEnabled}
              onClick={onReadyMeeting}
            >
              Arrive
            </IonButton>
          ) : null}
          {booking.type === AppointmentType.PROVIDER_LOCATION && !booking.providerConnected ? (
            <IonButton
              color="favorite"
              expand="block"
              disabled={!callEnabled}
              onClick={onReadyMeeting}
            >
              Ready
            </IonButton>
          ) : null}
          {booking.type === AppointmentType.PROVIDER_LOCATION && booking.providerConnected && !booking.callStartedAt ? (
            <IonButton
              color="favorite"
              expand="block"
              disabled={!callEnabled}
              onClick={onStartMeeting}
            >
              Start
            </IonButton>
          ) : null}
          <IonButton
            color="favorite"
            fill="outline"
            expand="block"
            disabled={!booking.customerConnected || !booking.providerConnected || !booking.callStartedAt}
            onClick={(): void => onUpdateBooking(BookingStatus.COMPLETED)}
          >
            Complete
          </IonButton>
          <IonButton
            color="dark"
            fill="outline"
            expand="block"
            disabled={booking.customerConnected && booking.providerConnected && !!booking.callStartedAt}
            onClick={(): void => setShowReasonsModal(true)}
          >
            Cancel
          </IonButton>
        </>
      );
    }
    if (booking.status === BookingStatus.COMPLETED) {
      return (
        <>
          {/* <IonButton color="primary" expand="block">Profile</IonButton> */}
          <IonButton
            color="favorite"
            fill="outline"
            expand="block"
            routerLink={`/provider/rate-booking/${booking.id}`}
          >
            Review
          </IonButton>
          <IonButton
            color="favorite"
            expand="block"
            onClick={getBookingReceiptPDF}
          >
            Receipt
          </IonButton>
          {booking.type === AppointmentType.VIRTUAL ? (
            <IonButton
              color="favorite"
              fill="outline"
              expand="block"
              onClick={downloadRecords}
            >
              {isDownloading ? <IonSpinner /> : 'Call Record'}
            </IonButton>
          ) : null}
        </>
      );
    }
    // return (
    //   <IonButton color="favorite" expand="block">
    //     Profile
    //   </IonButton>
    // );
    return (
      <>
        <IonButton
          color="favorite"
          expand="block"
          onClick={getBookingReceiptPDF}
        >
          Receipt
        </IonButton>
      </>
    );
  };

  return (
    <div className="booking">
      <IonItem lines="none" className="content">
        <Avatar user={booking.customer} />
        <IonLabel>
          <h2>{`${booking.customer.name} ${booking.customer.lastName ?? ''}`}</h2>
          <p>{`${booking.service} - ${booking.duration}min - $${booking.price}`}</p>
        </IonLabel>
        <p className="status">
          <span className={booking.type}>
            {appointmentType}
          </span>
          {BookingStatusLabels[booking.status]}
        </p>
      </IonItem>
      <IonItem lines="none" className="footer">
        <p className="date">{moment.utc(`${booking.date} ${booking.time}`).local().format('MMM DD, YYYY hh:mm A')}</p>
        <div slot="end" className="actions">{renderActions()}</div>
      </IonItem>
      <IonAlert
        isOpen={showAcceptAlert}
        onDidDismiss={(): void => setShowAcceptAlert(false)}
        header="Care Platform"
        message={`Do you want to accept booking with ${booking.customer.name} on ${booking.date}?`}
        buttons={[
          {
            text: 'No',
            cssClass: 'cancel-button',
          },
          {
            text: 'Yes',
            cssClass: 'confirm-button',
            handler: (): void => onUpdateBooking(BookingStatus.ACCEPTED)
          }
        ]}
      />
      <IonAlert
        isOpen={showCancelAlert}
        onDidDismiss={(): void => setShowCancelAlert(false)}
        header="Care Platform"
        message={`Do you really want to cancel booking with ${booking.customer.name} on ${booking.date}?`}
        buttons={[
          {
            text: 'No',
            cssClass: 'cancel-button',
          },
          {
            text: 'Yes',
            cssClass: 'confirm-button',
            handler: (): void => onUpdateBooking(BookingStatus.CANCELLED_PROVIDER),
          }
        ]}
      />
      <IonAlert
        isOpen={showRejectAlert}
        onDidDismiss={(): void => setShowRejectAlert(false)}
        header="Care Platform"
        message={`Do you really want to cancel booking with ${booking.customer.name} on ${booking.date}?`}
        buttons={[
          {
            text: 'No',
            cssClass: 'cancel-button',
          },
          {
            text: 'Yes',
            cssClass: 'confirm-button',
            handler: (): void => onUpdateBooking(BookingStatus.REJECTED_PROVIDER)
          }
        ]}
      />
      <IonAlert
        isOpen={showInProgressAlert}
        onDidDismiss={(): void => setShowInProgressAlert(false)}
        header="Care Platform"
        message="Call record is being prepared, please allow up to 60 minutes from the end call time for the files to be available for download."
        buttons={['OK']}
      />
      <IonAlert
        isOpen={errorAlert.open}
        onDidDismiss={(): void => setErrorAlert({ open: false, message: '' })}
        header="CarePlatform"
        message={errorAlert.message}
        buttons={['OK']}
      />
      <IonModal
        cssClass="reasons-modal"
        isOpen={showReasonsModal}
        onDidDismiss={() => {
          setOtherReason('');
          setSelectedReason('');
          setShowReasonsModal(false);
        }}
      >
        <IonLabel>Select Reason</IonLabel>
        <div id="modal">
          <IonList>
            <IonRadioGroup
              value={selectedReason}
              onIonChange={(e) => setSelectedReason(e.detail.value)}
            >
              <IonItem lines="none" className="input">
                <IonLabel>No available at that time</IonLabel>
                <IonRadio slot="end" value="no_available" />
              </IonItem>
              <IonItem lines="none" className="input">
                <IonLabel>Other</IonLabel>
                <IonRadio slot="end" value="other" />
              </IonItem>
            </IonRadioGroup>
            {selectedReason === 'other' ? (
              <IonTextarea
                name="personalReason"
                value={otherReason}
                rows={3}
                placeholder="Please input reason here..."
                onIonChange={(e) => setOtherReason(e.detail.value as string)}
              />
            ) : null}
            <IonItem lines="none">
              <IonButton
                color="favorite"
                disabled={cancelButtonDisabled}
                onClick={onCancel}
              >
                OK
              </IonButton>
            </IonItem>
          </IonList>
        </div>
      </IonModal>
    </div>
  );
};

ProviderBookingItem.defaultProps = {
  onUpdate: (): void => {},
  onMeeting: (): void => {}
};

ProviderBookingItem.propTypes = {
  booking: PropTypes.any.isRequired,
  currentDate: PropTypes.any.isRequired,
  onUpdate: PropTypes.func,
  onMeeting: PropTypes.func
};

export default ProviderBookingItem;
