/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonListHeader,
  IonLoading,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonModal,
  IonLabel,
  IonButton,
  IonList,
  IonItem,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import { chevronForwardOutline, chevronBackOutline, closeOutline } from 'ionicons/icons';
import moment from 'moment';
import {
  Calendar,
  momentLocalizer
} from 'react-big-calendar';
import stc from 'string-to-color';
import clsx from 'clsx';
import slugify from 'slugify';
import connect from '../../data/connect';
import ProviderBookingItem from '../../components/ProviderBookingItem';
import {
  AppointmentType, Booking, BookingStatus, BookingStatusLabels
} from '../../models';
import { fetchBookings, socket } from '../../data/dataApi';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './ProviderDashboard.scss';

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

const localizer = momentLocalizer(moment);

const EventWrapper = (props: any) => {
  const {
    style, event, type, onClick
  } = props;

  const elementStyle = style ?? {};
  if (style) {
    Object.keys(elementStyle).forEach((key) => {
      elementStyle[key] = `${elementStyle[key]}%`;
    });
    elementStyle.position = 'absolute';
    elementStyle.backgroundColor = 'red';
    elementStyle.borderRadius = '8px';
  } else {
    elementStyle.backgroundColor = 'red';
    elementStyle.width = '100%';
    elementStyle.borderRadius = '4px';
  }
  elementStyle.border='0.25px solid #FFF';
  elementStyle.backgroundColor = stc(event.title);
  elementStyle.opacity = '0.8';

  const title = `${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')} : ${event.title}`

  return (
    <div title={title} style={elementStyle} className="custom-eventwrapper cursor-pointer" onClick={onClick}>
      <span>{event.title}</span>
      {type === 'time' ? <span className="time">{`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}</span> : null}
    </div>
  );
};

const Toolbar = (props: any) => {
  const {
    label, date, view, views, onView, onNavigate
  } = props;
  const [month, setMonth] = useState('January');
  const mMonth = moment(date).format('MMMM');

  useEffect(() => {
    setMonth(mMonth);
  }, [mMonth]);

  const goToView = (view: any) => {
    onView(view);
  };

  const goToBack = () => {
    onNavigate('PREV');
  };
  const goToNext = () => {
    onNavigate('NEXT');
  };

  const goToToday = () => {
    onNavigate('TODAY');
  };

  return (
    <div className="rbc-toolbar">
      <div className="date-info-view">
        <div className="rbc-btn-group">
          <button type="button" onClick={goToToday}>Today</button>
          <button type="button" className="icon-button" onClick={goToBack}>
            <IonIcon icon={chevronBackOutline} />
          </button>
          <button type="button" className="icon-button" onClick={goToNext}>
            <IonIcon icon={chevronForwardOutline} />
          </button>
        </div>
        <div className="rbc-toolbar-label">
          {view === 'month' ? (
            <span className="rbc-year">{`${month} ${moment(date).format('YYYY')}`}</span>
          ) : (
            label
          )}
        </div>
      </div>
      <div className="rbc-btn-group">
        {views?.map((item: any) => (
          <button
            type="button" 
            onClick={() => goToView(item)}
            key={item}
            className={clsx({ 'rbc-active': view === item })}
          >
            {item.charAt(0).toUpperCase() + item.slice(1)}
          </button>
        ))}
      </div>
    </div>
  );
};

type ProviderDashboardProps = RouteComponentProps;

const ProviderDashboard: React.FC<ProviderDashboardProps> = ({ history }) => {
  const [segment, setSegment] = useState<'calendar' | 'upcoming' | 'pending'>(
    'calendar'
  );
  const [status, setStatus] = useState<'accepted' | 'rejected' | 'cancelled' | 'completed' | 'incomplete' | 'pending'>('accepted');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [dateRange, setDateRange] = useState<{ start: string, end: string }>({ start: '', end: '' });
  const [selectedBookingId, setSelectedBookingId] = useState(-1);

  const getEvents = () => bookings
    // .filter((booking) => booking.status === BookingStatus.ACCEPTED)
    .map((booking) => ({
      id: booking.id,
      title: booking.customer.name,
      start: moment.utc(`${booking.date} ${booking.time}`).local().toDate(),
      end: moment.utc(`${booking.date} ${booking.time}`).add(booking.duration, 'minutes').local().toDate()
    }));

  const handleRangeChange = (data: any) => {
    const keys = Object.keys(data);
    let start = '';
    let end = '';
    if (keys.length === 1) {
      start = moment(data[0]).toISOString();
      end = moment(data[0]).add(1, 'days').toISOString();
    } else if (keys.length === 7) {
      start = moment(data[0]).toISOString();
      end = moment(data[6]).add(1, 'days').toISOString();
    } else {
      start = moment(data['start']).toISOString();
      end = moment(data['end']).add(1, 'days').toISOString();
    }
    setDateRange({ start, end });
  };

  const fetchMore = () => {
    setIsFetching(true);
    const data = {
      skip: bookings.length,
      // eslint-disable-next-line no-nested-ternary
      status: segment === 'pending' ? 'pending' : segment === 'upcoming' ? 'accepted' : 'all',
    };
    fetchBookings(data)
      .then((res: { success: boolean, bookings: Booking[] }) => {
        if (res.success) {
          setBookings([...bookings, ...res.bookings]);
          if (res.bookings.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsFetching(false));
  };

  const onUpdateBooking = (booking: Booking) => {
    if (segment === 'calendar' || (segment === 'upcoming' && booking.status === BookingStatus.ACCEPTED)) {
      const updatedBookings: Booking[] = bookings.map((bk) => {
        if (bk.id === booking.id) {
          return booking;
        }
        return bk;
      });
      setBookings([...updatedBookings]);
    } else {
      const updatedBookings = bookings.filter((bk) => bk.id !== booking.id);
      setBookings([...updatedBookings]);
    }
  };

  const selectedBooking = useMemo(() => {
    if (selectedBookingId > -1) {
      return bookings.find((booking) => booking.id === selectedBookingId);
    }
    return null;
  }, [selectedBookingId, bookings]);

  const appointmentType = useMemo(() => {
    if (selectedBooking) {
      switch (selectedBooking.type) {
        case AppointmentType.VIRTUAL:
          return 'Virtual';
        case AppointmentType.PROVIDER_LOCATION:
          return 'Provider Location';
        case AppointmentType.CUSTOMER_LOCATION:
          return 'Customer Location';
        default:
          return 'N|A';
      }
    }
    return 'N|A';
  }, [selectedBooking]);

  useEffect(() => {
    if (segment === 'calendar') {
      const start = moment().startOf('month').toISOString();
      const end = moment().endOf('month').toISOString();
      setBookings([]);
      setDateRange({ start, end });
    } else {
      fetchMore();
    }
  }, [segment, status]);

  useEffect(() => {
    const data = {
      status,
      dateRange,
      noPagination: true
    };
    setIsFetching(true);
    fetchBookings(data)
      .then((res: { success: boolean, bookings: Booking[] }) => {
        if (res.success) {
          setBookings([...res.bookings]);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsFetching(false));
  }, [dateRange]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);

    socket.on('start-meeting', (e: Booking) => {
      fetchMore();
    });

    return () => clearInterval(interval);
  }, []);

  return (
    <IonContent id="provider-dashboard-page" className="page-content ion-padding">
      <IonListHeader className="page-header">Appointments</IonListHeader>
      <div className="toolbar">
        <IonSegment
          mode="ios"
          value={segment}
          onIonChange={(e): void => {
            setBookings([]);
            setIsFetchedAll(false);
            setSegment(e.detail.value as 'calendar' | 'upcoming' | 'pending');
          }}
        >
          <IonSegmentButton id="Provider-Dashboard-Calendar-View-Button" value="calendar">Calendar View</IonSegmentButton>
          <IonSegmentButton id="Provider-Dashboard-Upcoming-Button" value="upcoming">Upcoming</IonSegmentButton>
          <IonSegmentButton id="Provider-Dashboard-Pending-Button" value="pending">Pending</IonSegmentButton>
        </IonSegment>
        {segment === 'calendar' ? (
          <div className="status-selector">
            Appointment Status:
            <IonSelect
              value={status}
              onIonChange={(e): void => {
                setBookings([]);
                setStatus(e.detail.value);
              }}
            >
              <IonSelectOption value="accepted">Accepted</IonSelectOption>
              <IonSelectOption value="rejected">Rejected</IonSelectOption>
              <IonSelectOption value="cancelled">Cancelled</IonSelectOption>
              <IonSelectOption value="completed">Completed</IonSelectOption>
              <IonSelectOption value="incomplete">Incomplete</IonSelectOption>
              <IonSelectOption value="pending">Pending</IonSelectOption>
            </IonSelect>
          </div>
        ) : null}
      </div>
      <IonGrid>
        {segment === 'calendar' ? (
          <div className="calendar-view">
            <Calendar
              localizer={localizer}
              events={getEvents()}
              components={{
                toolbar: Toolbar,
                eventWrapper: (props) => <EventWrapper {...props} onClick={() => setSelectedBookingId(props?.event?.id)} />
              }}
              startAccessor="start"
              endAccessor="end"
              style={{ height: '100%', minHeight: 600 }}
              views={['day', 'week', 'month']}
              onRangeChange={handleRangeChange}
            />
          </div>
        ) : null}
        {segment === 'upcoming' ? (
          <IonRow>
            {bookings
              .sort((a, b) => {
                const diff = moment(`${a.date} ${a.time}`).diff(moment(`${b.date} ${b.time}`));
                if (diff < 0) {
                  return -1;
                }
                return 1;
              })
              .map((booking) => (
                <IonCol size="4">
                  <ProviderBookingItem
                    key={booking.id}
                    currentDate={currentDate}
                    booking={booking}
                    onUpdate={onUpdateBooking}
                    onMeeting={(): void => history.push(`/video/${booking.id}`)}
                  />
                </IonCol>
              ))}
          </IonRow>
        ) : null}
        {segment === 'pending' ? (
          <IonRow>
            {bookings
              .sort((a, b) => {
                const diff = moment(`${a.date} ${a.time}`).diff(moment(`${b.date} ${b.time}`));
                if (diff < 0) {
                  return -1;
                }
                return 1;
              })
              .map((booking) => (
                <IonCol size="4">
                  <ProviderBookingItem
                    key={booking.id}
                    currentDate={currentDate}
                    booking={booking}
                    onUpdate={onUpdateBooking}
                  />
                </IonCol>
              ))}
          </IonRow>
        ) : null}
      </IonGrid>
      {segment !== 'calendar' && !isFetchedAll ? (
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            fetchMore();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent
            loadingText="Please wait..."
            loadingSpinner="circles"
          />
        </IonInfiniteScroll>
      ) : null}

      <IonLoading isOpen={isFetching} />

      <IonModal
        cssClass="booking-details-modal"
        isOpen={!!selectedBooking}
        onDidDismiss={() => setSelectedBookingId(-1)}
      >
        <IonLabel>Appointment Details</IonLabel>
        <IonButton color="dark" fill="clear" onClick={() => setSelectedBookingId(-1)} id="Provider-Dashboard-Set-Booking">
          <IonIcon icon={closeOutline} />
        </IonButton>
        {selectedBooking ? (
          <IonList className="booking-details">
            <IonItem lines="none" id={`provider-dashboard-customer-${slugify(selectedBooking.customer.name).toLowerCase()}`}>
              <p slot="start">Appointment type</p>
              <span slot="end">{appointmentType}</span>
            </IonItem>
            <IonItem lines="none" id={`provider-dashboard-customer-${slugify(selectedBooking.customer.name).toLowerCase()}`}>
              <p slot="start">Customer</p>
              <span slot="end">{`${selectedBooking.customer.name} ${selectedBooking.customer.lastName ?? ''}`}</span>
            </IonItem>
            <IonItem lines="none" id={`provider-dashboard-customer-${slugify(selectedBooking.service).toLowerCase()}`}>
              <p slot="start">Service</p>
              <span slot="end">{`${selectedBooking.service} - ${selectedBooking.duration}mins - $${selectedBooking.price}`}</span>
            </IonItem>
            <IonItem lines="none" id={`provider-dashboard-customer-${slugify(selectedBooking.date).toLowerCase()}`}>
              <p slot="start">Date</p>
              <span slot="end">{moment.utc(`${selectedBooking.date} ${selectedBooking.time}`).local().format('YYYY MMMM DD HH:mm')}</span>
            </IonItem>
            <IonItem lines="none" id={`provider-dashboard-customer-${selectedBooking.status}`}>
              <p slot="start">Status</p>
              <span slot="end">{BookingStatusLabels[selectedBooking.status]}</span>
            </IonItem>
          </IonList>
        ) : null}
      </IonModal>
    </IonContent>
  );
};

ProviderDashboard.propTypes = {
  history: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, {}, {}>({
  mapStateToProps: () => ({}),
  component: React.memo(ProviderDashboard)
});
