import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonListHeader,
  IonLoading,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner
} from '@ionic/react';
import {
  calendarOutline, checkmarkCircle, ellipsisHorizontal, removeOutline
} from 'ionicons/icons';
import { DateRange as DateRangePicker, Range } from 'react-date-range';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import moment from 'moment';
import clsx from 'clsx';

import connect from '../../data/connect';
import {
  activateAccount,
  exportUserReport,
  fetchBookingsByUser,
  fetchClinicMembers,
  inactivateAccount
} from '../../data/dataApi';
import Avatar from '../../components/Avatar';
import StarRating from '../../components/StarRating';
import {
  Booking, BookingStatus, ProviderType, Specialist, User, UserRole, UserStatus, VerificationStatus
} from '../../models';
import {
  ActiveIcon,
  ArchiveIcon,
  BlockActionIcon,
  DetailsIcon,
  ExportIcon,
  InactiveIcon,
  TrashIcon,
  UnblockActionIcon
} from '../../icons';

import './ClinicReport.scss';

interface ActionsProps {
  role: string
  type: string
  data: User | Booking
  updateUser?: (user: User) => void
  deleteUser?: (id: number) => void
}

const Actions: React.FC<ActionsProps> = ({
  role, type, data, updateUser, deleteUser
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [showInactiveAlert, setShowInactiveAlert] = useState(false);

  const onInactivate = () => {
    setIsLoading(true);
    inactivateAccount(data.id, false)
      .then((res) => {
        if (res.success) {
          updateUser?.({ ...data, status: UserStatus.INACTIVE } as User);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onActivate = () => {
    setIsLoading(true);
    activateAccount(data.id)
      .then((res) => {
        if (res.success) {
          updateUser?.({ ...data, status: UserStatus.ACTIVE } as User);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onRemove = () => {
    setIsLoading(true);
    inactivateAccount(data.id, true)
      .then((res) => {
        if (res.success) {
          deleteUser?.(data.id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onShowDetails = () => {
    if (type === 'member') {
      history.push(`/${role}/report/user/${data.id}`);
    } else {
      history.push(`/${role}/report/booking/${data.id}`);
    }
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'top', 'left', 'right']}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="#daf3ef"
            arrowSize={10}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className="popover-content">
              {type === 'member' && data.status === UserStatus.ACTIVE ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowInactiveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel>
                      <InactiveIcon />
                      Inactivate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {type === 'member' && data.status === UserStatus.INACTIVE ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={onActivate}>
                    <IonLabel>
                      <ActiveIcon />
                      Activate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {type === 'member' && data.status === UserStatus.BLOCKED ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <UnblockActionIcon />
                      Unblock
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {type === 'member' && data.status === UserStatus.ARCHIVED ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={onActivate}>
                    <IonLabel>
                      <ActiveIcon />
                      Activate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {type === 'member' && data.status === UserStatus.PENDING ? (
                <>
                  {/* <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div> */}
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {type === 'booking' ? (
                <div className="option" onClick={onShowDetails}>
                  <IonLabel>
                    <DetailsIcon />
                    Details
                  </IonLabel>
                </div>
              ) : null}
            </div>
          </ArrowContainer>
        )}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        {isLoading ? <IonSpinner /> : (
          <span
            className="action"
            title="More actions"
            onClick={(e) => {
              e.stopPropagation();
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            <IonIcon icon={ellipsisHorizontal} />
          </span>
        )}
      </Popover>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={(): void => setShowRemoveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to delete this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onRemove()
          }
        ]}
      />

      <IonAlert
        isOpen={showInactiveAlert}
        onDidDismiss={(): void => setShowInactiveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to inactivate this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onInactivate()
          }
        ]}
      />
    </>
  );
};

Actions.defaultProps = {
  updateUser: () => {},
  deleteUser: () => {}
};

Actions.propTypes = {
  role: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
  updateUser: PropTypes.func,
  deleteUser: PropTypes.func
};

interface MatchParams {
  id: string
}

interface StateProps {
  currentUser: User
  providerTypes: ProviderType[]
  allSpecialists: Specialist[]
}

type ClinicReportProp = RouteComponentProps<MatchParams> & StateProps;

const ClinicReport: React.FC<ClinicReportProp> = ({
  match, history, currentUser, providerTypes, allSpecialists
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [clinic, setClinic] = useState<User | null>(null);
  const [members, setMembers] = useState<User[]>([]);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [dateRange, setDateRange] = useState<Range | null>(null);
  const [tempDateRange, setTempDateRange] = useState<Range | null>(null);
  const [showCalendarPopover, setShowCalendarPopover] = useState(false);
  const [segment, setSegment] = useState<'bookings' | 'members'>('members');

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const onRemove = (userId: number) => {
    setIsLoading(true);
    inactivateAccount(userId, true)
      .then((res) => {
        if (res.success) {
          if (userId === clinic?.id) {
            history.goBack();
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const selectPeriod = (type: string) => {
    if (type === 'today') {
      setTempDateRange({
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        key: 'selection'
      });
    }
    if (type === 'week') {
      setTempDateRange({
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastMonth') {
      setTempDateRange({
        startDate: moment().subtract(1, 'months').startOf('month').toDate(),
        endDate: moment().subtract(1, 'months').endOf('month').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastQuarter') {
      setTempDateRange({
        startDate: moment().subtract(3, 'months').startOf('quarter').toDate(),
        endDate: moment().subtract(3, 'months').endOf('quarter').toDate(),
        key: 'selection'
      });
    }
  };

  const onDeleteUser = (id: number) => {
    const updatedMembers = members.filter((c) => c.id !== id);
    setMembers([...updatedMembers]);
  };

  const onUpdateUser = (user: User) => {
    const updatedMembers = members.map((c) => {
      if (c.id === user.id) {
        return user;
      }
      return c;
    });
    setMembers([...updatedMembers]);
  };

  const providerTypeName = (type: number) => {
    const item = providerTypes.find((pt) => pt.id === type);
    return item?.name || '';
  };

  const getClinicInfo = async (id: number) => {
    setIsLoading(true);
    const data = {
      userId: id,
      dateRange: dateRange ? [
        moment(dateRange.startDate).toISOString(),
        moment(dateRange.endDate).toISOString()
      ] : null,
      orderBy,
      hasBookingsInfo: true,
      isDescending,
    };
    try {
      const promises = [
        fetchBookingsByUser(data),
        fetchClinicMembers(id, data)
      ];
      const [bData, mData] = await Promise.all(promises);
      setClinic(bData.user);
      setBookings(bData.bookings);
      setMembers(mData.members);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserReportPDF = async () => {
    if (clinic) {
      const url = await exportUserReport(clinic.id, {
        dateRange: dateRange ? [
          moment(dateRange.startDate).toISOString(),
          moment(dateRange.endDate).toISOString()
        ] : null,
      });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Clinic_Report_${clinic.id}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const totalAmount = useMemo(() => {
    if (clinic) {
      return bookings
        .reduce((total, booking) => (total * 1000 + (booking.charge?.providerFee || 0) * 1000) / 1000, 0);
    }
    return 0;
  }, [bookings, clinic]);

  useEffect(() => {
    if (match.params.id) {
      getClinicInfo(Number(match.params.id));
    } else if (currentUser && currentUser.role === UserRole.CLINIC_ADMIN) {
      getClinicInfo(currentUser.id);
    }
  }, [match, currentUser]);

  useEffect(() => {
    if (clinic) {
      getClinicInfo(clinic.id);
    }
  }, [orderBy, isDescending, dateRange]);

  return (
    <IonContent id="clinic-report" className="page-content ion-padding">
      {isLoading || !clinic ? <IonLoading isOpen={isLoading} /> : (
        <>
          {match.params.id ? (
            <IonListHeader className="page-header">
              Clinic Information
            </IonListHeader>
          ) : null}
          <div className="personal-summary">
            <div className="personal-info">
              <Avatar user={clinic} size="lg" />
              <div className="details">
                <h2>{`${clinic.name} ${clinic.lastName || ''}`}</h2>
                <p>{clinic.email}</p>
              </div>
            </div>
            <div className="report-actions">
              <IonButton color="dark" fill="outline" onClick={getUserReportPDF}>
                <ExportIcon />
                &nbsp;Export
              </IonButton>
              {currentUser.role === UserRole.ADMIN ? (
                <IonButton color="danger" onClick={() => onRemove(clinic.id)}>
                  Delete Clinic
                </IonButton>
              ) : null}
            </div>
          </div>

          <div className="report-body">
            <div className="report-summary">
              <div className="report-sub-summary">
                <h2 className="sub-title">Info</h2>
                <div className="report-content">
                  <div className="report-option">
                    <span className="label">Name</span>
                    <span className="content">{clinic.name}</span>
                  </div>
                  <div className="report-option">
                    <span className="label">Location</span>
                    <div className="list-content col">
                      {clinic.locations.map((location) => (
                        <span className="location">{location.address}</span>
                      ))}
                    </div>
                  </div>
                  <div className="report-option">
                    <span className="label">Contact Number</span>
                    <span className="content">{clinic.phone}</span>
                  </div>
                  <div className="report-option">
                    <span className="label">Registration date</span>
                    <span className="content">{moment(clinic.createdAt).format('DD MMMM, YYYY')}</span>
                  </div>
                </div>
              </div>
              <div className="report-sub-summary">
                <h2 className="sub-title">Bookings Info</h2>
                <div className="report-content">
                  <div className="report-option">
                    <span className="label">Provider Type</span>
                    <span className="content">{providerTypes.find((pt) => pt.id === clinic.type)?.name}</span>
                  </div>
                  <div className="report-option">
                    <span className="label">Specialities</span>
                    <div className="list-content">
                      {clinic.specialists.map((spec) => (
                        <span className="speciality" key={spec.id}>
                          {spec.name}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="report-option">
                    <span className="label">Total bookings</span>
                    <span className="content">{bookings.length}</span>
                  </div>
                  <div className="report-option">
                    <span className="label">Total amount booked</span>
                    <span className="content">{`$${totalAmount}`}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="list-view">
              <div className="sub-header">
                <IonSegment
                  mode="ios"
                  value={segment}
                  onIonChange={(e) => setSegment(e.detail.value as 'members' | 'bookings')}
                >
                  <IonSegmentButton value="members">Members</IonSegmentButton>
                  <IonSegmentButton value="bookings">Bookings</IonSegmentButton>
                </IonSegment>
                <Popover
                  isOpen={showCalendarPopover}
                  positions={['bottom', 'top', 'left', 'right']}
                  content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor="#daf3ef"
                      arrowSize={10}
                      className="popover-arrow-container"
                      arrowClassName="popover-arrow"
                    >
                      <div className="popover-content calendar">
                        <div className="panel">
                          <div
                            className={clsx('option', { selected: !tempDateRange })}
                            onClick={() => setTempDateRange(null)}
                          >
                            All periods
                          </div>
                          <div
                            className={clsx('option', {
                              selected: tempDateRange && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment(tempDateRange.endDate).format('YYYY-MM-DD')
                            })}
                            onClick={() => selectPeriod('today')}
                          >
                            Today
                          </div>
                          <div
                            className={clsx('option', {
                              selected: tempDateRange
                                && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().startOf('week').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().endOf('week').format('YYYY-MM-DD')
                            })}
                            onClick={() => selectPeriod('week')}
                          >
                            This week
                          </div>
                          <div
                            className={clsx('option', {
                              selected: tempDateRange
                                && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                            })}
                            onClick={() => selectPeriod('lastMonth')}
                          >
                            Last month
                          </div>
                          <div
                            className={clsx('option', {
                              selected: tempDateRange
                                && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').startOf('quarter').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').endOf('quarter').format('YYYY-MM-DD')
                            })}
                            onClick={() => selectPeriod('lastQuarter')}
                          >
                            Last quarter
                          </div>
                          <IonButton
                            color="favorite"
                            onClick={() => {
                              setDateRange(tempDateRange);
                              setShowCalendarPopover(false);
                            }}
                          >
                            Apply
                          </IonButton>
                        </div>
                        <div className="calendar">
                          <DateRangePicker
                            editableDateInputs
                            onChange={(item) => {
                              setTempDateRange({
                                startDate: moment(item.selection.startDate).toDate(),
                                endDate: moment(item.selection.endDate).add(1, 'days').subtract(1, 'milliseconds').toDate(),
                                key: 'selection'
                              });
                            }}
                            moveRangeOnFirstSelection={false}
                            ranges={tempDateRange ? [tempDateRange] : [
                              {
                                startDate: new Date(),
                                endDate: new Date(),
                                key: 'selection'
                              },
                            ]}
                            rangeColors={['#000']}
                          />
                        </div>
                      </div>
                    </ArrowContainer>
                  )}
                  onClickOutside={() => setShowCalendarPopover(false)}
                >
                  <IonItem lines="none" className="date-range" onClick={() => setShowCalendarPopover(true)}>
                    <div>
                      {dateRange ? moment(dateRange.startDate).format('DD MMMM, YYYY') : 'Start Date'}
                    </div>
                    <IonIcon color="dark" icon={removeOutline} />
                    <div>
                      {dateRange ? moment(dateRange.endDate).format('DD MMMM, YYYY') : 'End Date'}
                    </div>
                    <IonIcon slot="end" icon={calendarOutline} />
                  </IonItem>
                </Popover>
              </div>
              <IonGrid className={clsx({ hidden: segment === 'members'})}>
                <IonRow className="table-header">
                  <IonCol className="sm">
                    NO
                  </IonCol>
                  <IonCol
                    className={clsx('cursor-pointer sm', { isOrderBy: orderBy === 'id' })}
                    onClick={() => onChangeOrderBy('id')}
                  >
                    B.ID&nbsp;
                    {orderBy === 'id' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    size="2.5"
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'customer' })}
                    onClick={() => onChangeOrderBy('customer')}
                  >
                    CUSTOMER NAME AND EMAIL&nbsp;
                    {orderBy === 'customer' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'service' })}
                    onClick={() => onChangeOrderBy('service')}
                  >
                    SERVICE NAME&nbsp;
                    {orderBy === 'service' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    size="2.5"
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'provider' })}
                    onClick={() => onChangeOrderBy('provider')}
                  >
                    PROVIDER NAME AND EMAIL&nbsp;
                    {orderBy === 'provider' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    size="1.2"
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'date' })}
                    onClick={() => onChangeOrderBy('date')}
                  >
                    APPT. DATETIME&nbsp;
                    {orderBy === 'date' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    size="1.2"
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'createdAt' })}
                    onClick={() => onChangeOrderBy('createdAt')}
                  >
                    CREATED AT&nbsp;
                    {orderBy === 'createdAt' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'duration' })}
                    onClick={() => onChangeOrderBy('duration')}
                  >
                    DURATION&nbsp;
                    {orderBy === 'duration' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'price' })}
                    onClick={() => onChangeOrderBy('price')}
                  >
                    PRICE&nbsp;
                    {orderBy === 'price' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'providerFee' })}
                    onClick={() => onChangeOrderBy('providerFee')}
                  >
                    PRO. SPLIT
                    {orderBy === 'providerFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'cpFee' })}
                    onClick={() => onChangeOrderBy('cpFee')}
                  >
                    C.P SPLIT
                    {orderBy === 'cpFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'stripeFee' })}
                    onClick={() => onChangeOrderBy('stripeFee')}
                  >
                    STRIPE FEES
                    {orderBy === 'stripeFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol>
                    STATUS
                  </IonCol>
                  <IonCol>
                    ACTIONS
                  </IonCol>
                </IonRow>
                {bookings.map((booking, index) => (
                  <IonRow key={booking.id} className="table-row">
                    <IonCol className="sm">{index + 1}</IonCol>
                    <IonCol className="sm">{booking.id}</IonCol>
                    <IonCol size="2.5">
                      <div className="user-name-email">
                        <Avatar user={booking.provider} />
                        <div>
                          <h4>{`${booking.provider.name} ${booking.provider.lastName ?? ''}`}</h4>
                          <p>{booking.provider.email}</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol>
                      {booking.service}
                    </IonCol>
                    <IonCol size="2.5">
                      <div className="user-name-email">
                        <Avatar user={booking.customer} />
                        <div>
                          <h4>{`${booking.customer.name} ${booking.customer.lastName ?? ''}`}</h4>
                          <p>{booking.customer.email}</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol size="1.2">
                      {moment.utc(`${booking.date} ${booking.time}`).local().format('DD MMMM, YYYY hh:mm A')}
                    </IonCol>
                    <IonCol size="1.2">
                      {moment.utc(booking.createdAt).local().format('DD MMM YYYY, hh:mm A')}
                    </IonCol>
                    <IonCol>
                      {`${booking.duration}mins`}
                    </IonCol>
                    <IonCol>
                      {`$${booking.price}`}
                    </IonCol>
                    <IonCol>
                      {booking.charge.providerFee >= 0 ? `$${booking.charge.providerFee}` : `-$${-1 * booking.charge.providerFee}`}
                    </IonCol>
                    <IonCol>
                      {booking.charge.cpFee >= 0 ? `$${booking.charge.cpFee}` : `-$${-1 * booking.charge.cpFee}`}
                    </IonCol>
                    <IonCol>
                      {`$${booking.charge.stripeFee}`}
                    </IonCol>
                    <IonCol>
                      {booking.status === BookingStatus.PENDING ? (
                        <div className="status pending">Pending</div>
                      ) : null}
                      {booking.status === BookingStatus.REJECTED_PROVIDER ? (
                        <div className="status rejected">Rejected</div>
                      ) : null}
                      {booking.status === BookingStatus.REJECTED_CUSTOMER ? (
                        <div className="status abandoned">Abandoned</div>
                      ) : null}
                      {booking.status === BookingStatus.CANCELLED_CUSTOMER || booking.status === BookingStatus.CANCELLED_PROVIDER ? (
                        <div className="status cancelled">Cancelled</div>
                      ) : null}
                      {booking.status === BookingStatus.ACCEPTED ? (
                        <div className="status accepted">Accepted</div>
                      ) : null}
                      {booking.status === BookingStatus.COMPLETED ? (
                        <div className="status completed">Completed</div>
                      ) : null}
                      {booking.status === BookingStatus.INCOMPLETE ? (
                        <div className="status incomplete">Incomplete</div>
                      ) : null}
                    </IonCol>
                    <IonCol>
                      <Actions role={currentUser.role} type="booking" data={booking} />
                    </IonCol>
                  </IonRow>
                ))}
              </IonGrid>
              <IonGrid className={clsx({ hidden: segment === 'bookings'})}>
                <IonRow className="table-header">
                  <IonCol className="sm">NO</IonCol>
                  <IonCol
                    className={clsx('cursor-pointer sm', { isOrderBy: orderBy === 'id' })}
                    onClick={() => onChangeOrderBy('id')}
                  >
                    ID&nbsp;
                    {orderBy === 'id' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    size="2.5"
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'name' })}
                    onClick={() => onChangeOrderBy('name')}
                  >
                    NAME AND EMAIL&nbsp;
                    {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol>CONTACT NO</IonCol>
                  <IonCol>PRO.TYPE</IonCol>
                  <IonCol
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'rating' })}
                    onClick={() => onChangeOrderBy('rating')}
                  >
                    RATINGS&nbsp;
                    {orderBy === 'rating' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'bookingsCnt' })}
                    onClick={() => onChangeOrderBy('bookingsCnt')}
                  >
                    BOOKINGS&nbsp;
                    {orderBy === 'bookingsCnt' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                  <IonCol size="1.5">LICENSE AND ID VERIFIED</IonCol>
                  <IonCol>STATUS</IonCol>
                  <IonCol>ACTION</IonCol>
                </IonRow>
                {members.map((member, index) => (
                  <IonRow key={member.id} className="table-row">
                    <IonCol className="sm">{index + 1}</IonCol>
                    <IonCol className="sm">{member.id}</IonCol>
                    <IonCol size="2.5">
                      <div className="user-name-email">
                        <Avatar user={member} />
                        <div>
                          <h4>{`${member.name} ${member.lastName ?? ''}`}</h4>
                          <p>{member.email}</p>
                        </div>
                      </div>
                    </IonCol>
                    <IonCol>{member.phone}</IonCol>
                    <IonCol>{providerTypeName(member.type)}</IonCol>
                    <IonCol>
                      <StarRating hasOneStar readonly rating={(member.rating || 0) / 5} />
                      {(member.rating || 0).toFixed(1)}
                    </IonCol>
                    <IonCol>{member?.bookingsCnt || 0}</IonCol>
                    <IonCol size="1.5">
                      {
                        member.licenses && member.licenses.find((license) => license.status === VerificationStatus.VERIFIED && `${license.type}s` === providerTypeName(member.type))
                          ? <IonIcon icon={checkmarkCircle} color="danger" />
                          : <IonIcon icon={checkmarkCircle} color="medium" />
                      }
                      {
                        member.identity && member.identity.status === VerificationStatus.VERIFIED
                          ? <IonIcon icon={checkmarkCircle} color="favorite" />
                          : <IonIcon icon={checkmarkCircle} color="medium" />
                      }
                    </IonCol>
                    <IonCol>
                      {member.status === UserStatus.ACTIVE ? (
                        <div className="status active">Active</div>
                      ) : null}
                      {member.status === UserStatus.INACTIVE ? (
                        <div className="status inactive">Inactive</div>
                      ) : null}
                      {member.status === UserStatus.BLOCKED ? (
                        <div className="status blocked">Blocked</div>
                      ) : null}
                      {member.status === UserStatus.PENDING ? (
                        <div className="status inactive">Pending</div>
                      ) : null}
                    </IonCol>
                    <IonCol>
                      <Actions
                        role={currentUser.role}
                        type="member"
                        data={member}
                        updateUser={onUpdateUser}
                        deleteUser={onDeleteUser}
                      />
                    </IonCol>
                  </IonRow>
                ))}
              </IonGrid>
            </div>
          </div>
        </>
      )}
    </IonContent>
  );
};

ClinicReport.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  currentUser: PropTypes.any.isRequired,
  providerTypes: PropTypes.array.isRequired,
  allSpecialists: PropTypes.array.isRequired
};

export default connect<RouteComponentProps<MatchParams>, StateProps, {}>({
  mapStateToProps: (state) => ({
    currentUser: state.auth.user,
    providerTypes: state.data.providerTypes,
    allSpecialists: state.data.allSpecialists,
  }),
  component: React.memo(ClinicReport)
});
