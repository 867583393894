import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  IonButton,
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea
} from '@ionic/react';
import {
  closeOutline, options, searchOutline
} from 'ionicons/icons';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import clsx from 'clsx';
import moment from 'moment';

interface CouponsProps {
  toggleHeader: (value: boolean) => void
}

const Coupons: React.FC<CouponsProps> = ({
  toggleHeader
}) => {
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const searchTimeoutRef = useRef<number | null>(null);


  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  return (
    <div className="marketing-section">
      {showDetails ? (
        <div className="marketing-section-details">
          <div className="marketing-section-header">
            <div className="screen-header">Add Campaign</div>
            <IonButton fill="clear" color="favorite" onClick={() => { setShowDetails(false); toggleHeader(true); }}>Back</IonButton>
          </div>
          <div className="marketing-section-content">
            <IonGrid className="campaign">
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="field-title">Assignee</IonLabel>
                  <IonItem lines="none" className="select">
                    <IonSelect
                      className="select"
                      placeholder="Please Select"
                    >
                      <IonSelectOption value="us">test</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="field-title">Budget Cost</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonInput
                      type="text"
                      placeholder=""
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="field-title">Name*</IonLabel>
                  <IonItem lines="none" className="select">
                    <IonSelect
                      className="select"
                      placeholder="Please Select"
                    >
                      <IonSelectOption value="us">test</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="field-title">Number Sent</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonInput
                      type="text"
                      placeholder=""
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="field-title">Status*</IonLabel>
                  <IonItem lines="none" className="select">
                    <IonSelect
                      className="select"
                      placeholder="Please Select"
                    >
                      <IonSelectOption value="us">test</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="field-title">Expected Response</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonInput
                      type="text"
                      placeholder=""
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="field-title">Social Media*</IonLabel>
                  <IonItem lines="none" className="select">
                    <IonSelect
                      className="select"
                      placeholder="Please Select"
                    >
                      <IonSelectOption value="us">test</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="field-title">Expected Revenue</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonInput
                      type="text"
                      placeholder=""
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="field-title">Start Date</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonDatetime
                      max={moment().format('YYYY-MM-DD')}
                      displayFormat="MMMM YYYY"
                      pickerFormat="YYYY MMMM"
                    />
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="field-title">End Date</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonDatetime
                      max="2100"
                      // min={license.issueDate ? moment(license.issueDate).format('YYYY-MM-DD') : undefined}
                      displayFormat="MMMM YYYY"
                      pickerFormat="YYYY MMMM"
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <br />
              <IonRow>
                <IonCol size="12">
                  <IonLabel className="field-title">Description</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonTextarea
                      placeholder=""
                      maxlength={500}
                      rows={3}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton color="favorite">
                    Save
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      ) : (
        <>
          <div className="marketing-section-header">
            <IonButton color="favorite" onClick={() => setShowNewModal(true)}>
              + Add new coupon
            </IonButton>
            <div className="marketing-section-filter">
              <IonItem className="search-input" lines="none">
              <IonInput
                  value={searchKey}
                  placeholder="Search"
                  onIonChange={(e) => {
                    const inputValue = e.detail.value ?? '';
                    setSearchKey(inputValue);
                    if (searchTimeoutRef.current) {
                      clearTimeout(searchTimeoutRef.current);
                    }
                  
                    // searchTimeoutRef.current = window.setTimeout(() => {
                    //   fetchMore(true, inputValue);
                    // }, 450);
                  }}
                />
                <IonIcon size="small" icon={searchOutline} />
                <IonIcon size="small" icon={closeOutline} />
              </IonItem>
              <IonButton color="dark" fill="outline">
                <IonIcon icon={options} />
                Filter
              </IonButton>
            </div>
          </div>
          <div className="marketing-section-list">
            <IonGrid className="table">
              <IonRow className="table-header">
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'name' })}
                  onClick={() => onChangeOrderBy('name')}
                >
                  COUPON NAME&nbsp;
                  {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'id' })}
                  onClick={() => onChangeOrderBy('id')}
                >
                  COUPON ID&nbsp;
                  {orderBy === 'id' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'percent' })}
                  onClick={() => onChangeOrderBy('percent')}
                >
                  DISCOUNT PERCENT&nbsp;
                  {orderBy === 'percent' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'start' })}
                  onClick={() => onChangeOrderBy('start')}
                >
                  ISSUE DATE&nbsp;
                  {orderBy === 'start' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'end' })}
                  onClick={() => onChangeOrderBy('end')}
                >
                  EXPIRATION DATE&nbsp;
                  {orderBy === 'end' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'status' })}
                  onClick={() => onChangeOrderBy('status')}
                >
                  STATUS&nbsp;
                  {orderBy === 'status' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol>ACTION</IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </>
      )}

      {/* Modal to add new coupon */}
      <IonModal
        cssClass="marketing-modal"
        isOpen={showNewModal}
        onDidDismiss={() => setShowNewModal(false)}
      >
        <div className="header">
          <IonLabel>Create New Coupon</IonLabel>
        </div>
        <div className="input-container">
          <div className="header">
            Name
          </div>
          <IonItem lines="none" className="input">
            <IonInput
              type="text"
              placeholder="Coupon Name"
            />
          </IonItem>
        </div>
        <div className="input-container">
          <div className="header">
            Issue Date
          </div>
          <IonItem lines="none" className="input">
            <IonDatetime
              max={moment().format('YYYY-MM-DD')}
              displayFormat="MMMM YYYY"
              pickerFormat="YYYY MMMM"
            />
          </IonItem>
        </div>
        <div className="input-container">
          <div className="header">
            Expiration Date
          </div>
          <IonItem lines="none" className="input">
            <IonDatetime
              max="2100"
              // min={license.issueDate ? moment(license.issueDate).format('YYYY-MM-DD') : undefined}
              displayFormat="MMMM YYYY"
              pickerFormat="YYYY MMMM"
            />
          </IonItem>
        </div>
        <div className="input-container">
          <div className="header">
            Discount Percent
          </div>
          <IonItem lines="none" className="input">
            <IonInput
              type="text"
              placeholder="Enter discount percent"
            />
          </IonItem>
        </div>
        <div className="actions">
          <IonButton color="dark" fill="outline" expand="block" onClick={() => setShowNewModal(false)}>
            Cancel
          </IonButton>
          <IonButton color="favorite" expand="block">
            Apply
          </IonButton>
        </div>
      </IonModal>
    </div>
  );
};

Coupons.propTypes = {
  toggleHeader: PropTypes.func.isRequired
};

export default React.memo(Coupons);
