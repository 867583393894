import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonThumbnail,
  IonAlert,
  IonLoading,
  IonIcon
} from '@ionic/react';
import { logoGoogle, logoWindows, logoApple } from 'ionicons/icons';
import moment from 'moment';

import Avatar from '../../components/Avatar';
import connect from '../../data/connect';
import {
  User, Service, ServiceItem, Location, ProviderType, Booking, AppointmentType
} from '../../models';
import { fetchProvider, createBooking as create, socket } from '../../data/dataApi';
import { SOCKET_KEYS } from '../../data/constants';
import { dispatchCreateBooking } from '../../data/store/data/data.actions';
import {
  googleCalendarLink,
  outlookCalendarLink
} from '../../data/calendarUrls';

import './ProviderScheduleConfirm.scss';

interface MatchParams {
  id: string
}

interface StateProps {
  providerTypes: ProviderType[]
}

interface DispatchProps {
  createBooking: typeof dispatchCreateBooking
}

type ProviderScheduleConfirmProps = RouteComponentProps<MatchParams> &
StateProps &
DispatchProps;

const ProviderScheduleConfirm: React.FC<ProviderScheduleConfirmProps> = ({
  match,
  history,
  providerTypes,
  createBooking
}) => {
  const { id } = match.params;
  const {
    state: {
      date, slot, type, item, service, location
    }
  }: any = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [provider, setProvider] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [selectedServiceItem, setselectedServiceItem] = useState<ServiceItem | null>(null);

  useEffect(() => {
    // setTotalPrice(provider.services.reduce((total, service) => total += service.price, 0));
  }, []);

  const getGoogleLink = () => {
    const start = moment(date)
      .set('hour', Number(slot) / 4)
      .set('minute', 15 * (Number(slot) % 4));
    const end = moment(start).add(selectedServiceItem?.duration, 'minute');
    return googleCalendarLink(
      'Care Platform Service',
      `Provider: ${provider?.name} \nService: ${selectedService?.name}(${selectedServiceItem?.duration}min - $${selectedServiceItem?.price})`,
      selectedLocation?.address,
      start,
      end
    );
  };

  const getOutlookLink = () => {
    const start = moment(date)
      .set('hour', Number(slot) / 4)
      .set('minute', 15 * (Number(slot) % 4));
    const end = moment(start).add(selectedServiceItem?.duration, 'minute');
    return outlookCalendarLink(
      'Care Platform Service',
      `Provider: ${provider?.name} \nService: ${selectedService?.name}(${selectedServiceItem?.duration}min - $${selectedServiceItem?.price})`,
      selectedLocation?.address,
      start,
      end
    );
  };

  const onConfirm = (): void => {
    if (!selectedService || !selectedServiceItem || !selectedLocation || !provider) {
      return;
    }
    setIsLoading(true);

    const utcDateTime = moment(date)
      .set('hour', Number(slot) / 4)
      .set('minute', 15 * (Number(slot) % 4))
      .utc();
    let data: any = {
      providerId: id,
      date: utcDateTime.format('YYYY-MM-DD'),
      time: utcDateTime.format('HH:mm'),
      service: selectedService.name,
      duration: selectedServiceItem.duration,
      price: selectedServiceItem.price,
      type
    };
    if (type !== AppointmentType.VIRTUAL) {
      data = {
        ...data,
        address: selectedLocation.address,
        country: selectedLocation.country,
        state: selectedLocation.state,
        city: selectedLocation.city
      };
    }
    create(data)
      .then((res: { success: boolean, booking: Booking }) => {
        if (res.success) {
          setShowAlert(true);
          createBooking({ ...res.booking, provider });
          socket.emit(SOCKET_KEYS.pending, res.booking.id);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const providerTypeName = useMemo(() => {
    const pType = providerTypes.find((pt) => pt.id === provider?.type);
    if (pType) {
      return pType.name;
    }
    return '';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, providerTypes]);

  const appointmentType = useMemo(() => {
    switch (type) {
      case 'video-visit':
        return 'Virtual';
      case 'provider-visit':
        return 'Provider Location';
      case 'customer-visit':
        return 'Customer Location';
      default:
        return 'N|A';
    }
  }, [type]);

  useEffect(() => {
    if (match.params.id) {
      setIsLoading(true);
      fetchProvider(Number(match.params.id))
        .then((res: { success: boolean, user: User }) => {
          if (res.success) {
            setProvider(res.user);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [match]);

  useEffect(() => {
    if (provider) {
      setSelectedLocation(location);
      setSelectedService(
        provider.services.find((sv) => sv.id === Number(service)) ?? null
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  useEffect(() => {
    if (selectedService) {
      setselectedServiceItem(selectedService.items?.find((it) => it.id === Number(item)) ?? null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService]);

  if (
    !provider
    || !selectedService
    || !selectedServiceItem
    || !selectedLocation
  ) {
    return <div>Invalid</div>;
  }

  return (
    <IonContent id="provider-schedule-confirm" className="provider-confirm-content page-content">
      <IonGrid>
        <IonRow className="profile-content">
          <Avatar user={provider} size="lg" />
          <div className="profile-information">
            <div className="name">{`${provider.name} ${provider.lastName ?? ''}`}</div>
            <div>{`Certified ${providerTypeName}` }</div>
          </div>
        </IonRow>
        <IonRow>
          <div className="info-section">
            <div className="sub-title">Appointment Details</div>
            <div>
              {appointmentType}
            </div>
          </div>
        </IonRow>
        <IonRow>
          <div className="info-section price">
            <div className="sub-title">Receipt</div>
            <div>
              {`${selectedService.name} (${selectedServiceItem.duration}min - $${selectedServiceItem.price})`}
            </div>
            <br />
            <div>{`Subtotal - $${selectedServiceItem.price.toFixed(1)}`}</div>
            <div className="total-price">
              Total - $
              {(selectedServiceItem.price - 0).toFixed(1)}
            </div>
          </div>
        </IonRow>
        <IonRow>
          <div className="info-section">
            <div className="sub-title">Confirmation</div>
            <IonRow>
              <IonCol size="2">
                <IonThumbnail>
                  <img src="assets/img/icon/calendar.svg" alt="calendar" />
                </IonThumbnail>
              </IonCol>
              <IonCol>
                <div>
                  {`${moment(date, 'YYYY-MM-DD').format(
                    'MMMM Do, YYYY'
                  )} ${moment({
                    hour: Number(slot) / 4,
                    minute: 15 * (Number(slot) % 4)
                  }).format('HH:mm')}`}
                </div>
                {type !== AppointmentType.VIRTUAL ? <div>{selectedLocation.address}</div> : null}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonButton
                  color="favorite"
                  onClick={() => window.open(getGoogleLink(), '_blank')}
                >
                  <IonIcon slot="icon-only" icon={logoGoogle} />
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  color="favorite"
                  onClick={() => window.open(getOutlookLink(), '_blank')}
                >
                  <IonIcon slot="icon-only" icon={logoWindows} />
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton color="favorite">
                  <IonIcon slot="icon-only" icon={logoApple} />
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </IonRow>
        <IonRow>
          <IonCol size="6">
            <IonButton color="dark" fill="outline" expand="block" onClick={(): void => history.goBack()}>
              Cancel
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton color="favorite" expand="block" onClick={(): void => onConfirm()}>
              Confirm
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={(): void => {
          setShowAlert(false);
          history.replace('/customer/dashboard');
        }}
        header="Thank you for booking with Care Platform"
        message={`Your appointment is confirmed ${moment(
          date,
          'YYYY-MM-DD'
        ).format('MMMM Do, YYYY')} ${moment({
          hour: Number(slot) / 4,
          minute: 15 * (Number(slot) % 4)
        }).format('HH:mm')} with ${provider.name} ${provider.lastName ?? ''}`}
        buttons={['OK']}
      />
      <IonLoading isOpen={isLoading} />
    </IonContent>
  );
};

ProviderScheduleConfirm.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  providerTypes: PropTypes.array.isRequired,
  createBooking: PropTypes.func.isRequired
};

export default connect<
RouteComponentProps<MatchParams>,
StateProps,
DispatchProps
>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes
  }),
  mapDispatchToProps: {
    createBooking: dispatchCreateBooking
  },
  component: React.memo(ProviderScheduleConfirm)
});
