import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonListHeader,
  IonRow,
  IonSegment,
  IonSegmentButton
} from '@ionic/react';

import connect from '../../data/connect';
import { Request } from '../../models';
import RequestItem from '../../components/RequestItem';

import './AdminDashboard.scss';

interface StateProps {
  requests: Request[]
}

const AdminDashboard: React.FC<StateProps> = ({
  requests
}) => {
  const [segment, setSegment] = useState<'pending' | 'completed'>('pending');

  const showingRequests = useMemo(() => {
    if (segment === 'pending') {
      return requests.filter((request) => request.status === 'pending');
    }
    return requests.filter((request) => request.status !== 'pending');
  }, [segment, requests]);

  return (
    <IonContent id="admin-dashboard" className="page-content ion-padding">
      <IonListHeader className="page-header">Requests from Users</IonListHeader>
      <IonSegment
        mode="ios"
        value={segment}
        onIonChange={(e): void => setSegment(e.detail.value as 'pending' | 'completed')}
      >
        <IonSegmentButton value="pending">Pending</IonSegmentButton>
        <IonSegmentButton value="completed">Completed</IonSegmentButton>
      </IonSegment>

      <IonGrid>
        <IonRow>
          {showingRequests.map((request) => (
            <IonCol size="4" key={request.id}>
              <RequestItem key={request.id} request={request} />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};

AdminDashboard.propTypes = {
  requests: PropTypes.array.isRequired
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    requests: state.data.requests
  }),
  component: React.memo(AdminDashboard)
});
