/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonListHeader,
  IonLoading,
  IonRow
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import moment from 'moment';

import connect from '../../data/connect';
import { getSubscriptionHistory, subscribe, updateSubscriptionStatus } from '../../data/dataApi';
import { dispatchUpdateUser } from '../../data/store/auth/auth.actions';
import CardForm from '../../components/CardForm';
import { Subscription, SubscriptionCharge, User } from '../../models';

import './Subscription.scss';

interface StateProps {
  user: User
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

const SubscriptionPage: React.FC<StateProps & DispatchProps> = ({
  user,
  updateUser
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSubscribeSreen, setShowSubscribeSreen] = useState(false);
  const [showSubscribeSuccessAlert, setShowSubscribeSuccessAlert] = useState(false);
  const [showUnsubscribeConfirmAlert, setShowUnsubscribeConfirmAlert] = useState(false);
  const [subscriptionCharges, setSubscriptionCharges] = useState<SubscriptionCharge[]>([]);

  const getHistory = () => {
    setIsLoading(true);
    getSubscriptionHistory(user.id)
      .then((res: { success: boolean, subscriptionCharges: SubscriptionCharge[] }) => {
        if (res.success) {
          setSubscriptionCharges(res.subscriptionCharges);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onSubscribe = (token: string) => {
    setIsLoading(true);
    subscribe({ token })
      .then((res: { success: boolean, subscription: Subscription }) => {
        if (res.success) {
          updateUser(({ ...user, subscription: res.subscription }));
          setTimeout(() => {
            getHistory();
          }, 2000);
          setShowSubscribeSuccessAlert(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onUnsubscribe = () => {
    if (user.subscription) {
      setIsLoading(true);
      updateSubscriptionStatus(user.subscription.subscriptionId, 'pause')
        .then((res: { success: boolean, subscription: Subscription }) => {
          if (res.success) {
            setShowUnsubscribeConfirmAlert(false);
            updateUser(({ ...user, subscription: res.subscription }));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onResumeSubscription = () => {
    if (user.subscription) {
      setIsLoading(true);
      updateSubscriptionStatus(user.subscription.subscriptionId, 'resume')
        .then((res: { success: boolean, subscription: Subscription }) => {
          if (res.success) {
            updateUser(({ ...user, subscription: res.subscription }));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const isSubscribed = useMemo(() => {
    if (user.subscription && ['active', 'paused'].includes(user.subscription.status)) {
      return true;
    }
    return false;
  }, [user]);

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <IonContent id="subscription" className="page-content ion-padding">
      {showSubscribeSreen ? (
        <div>
          <div className="header">
            <IonListHeader className="page-header">
              <IonIcon
                className="cursor-pointer"
                color="favorite"
                icon={arrowBackOutline}
                onClick={() => setShowSubscribeSreen(false)}
              />
              &nbsp;&nbsp;Add Card for Subscription
            </IonListHeader>
          </div>
          <div className="card-form-container">
            <CardForm
              handleToken={(res: { token: { id: string }}): void => onSubscribe(res.token.id)}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="header">
            <IonListHeader className="page-header">Subscription</IonListHeader>
            {isSubscribed && user.subscription && user.subscription.status === 'active' ? (
              <IonButton color="danger" onClick={() => setShowUnsubscribeConfirmAlert(true)}>
                Unsubscribe
              </IonButton>
            ) : null}
            {isSubscribed && user.subscription && user.subscription.status === 'paused' ? (
              <IonButton color="favorite" onClick={onResumeSubscription}>
                Resume
              </IonButton>
            ) : null}
            {!isSubscribed ? (
              <IonButton color="favorite" onClick={() => setShowSubscribeSreen(true)}>
                Subscribe
              </IonButton>
            ) : null}
          </div>
          <div className="subscription-description">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum repellendus sit voluptate voluptas eveniet porro. Rerum blanditiis perferendis totam, ea at omnis vel numquam exercitationem aut, natus minima, porro labore.
          </div>
          <div className="subscription-history">
            <div className="sub-title">
              Transaction History
              {user.subscription && user.subscription.status === 'active' ? (
                <span>{`(Renewal Charge Date: ${moment.unix(user.subscription.to).local().format('MMM DD, YYYY')})`}</span>
              ) : null}
              {user.subscription && user.subscription.status === 'paused' ? (
                <span>{`(Your subscription will be end at: ${moment.unix(user.subscription.to).local().format('MMM DD, YYYY')})`}</span>
              ) : null}
            </div>
            <div className="subscription-charges">
              <IonGrid>
                <IonRow className="table-header">
                  <IonCol className="sm">
                    NO
                  </IonCol>
                  <IonCol>
                    CHARGE AMOUNT
                  </IonCol>
                  <IonCol>
                    CHARGE DATE
                  </IonCol>
                  <IonCol>
                    INVOICE
                  </IonCol>
                </IonRow>
                {subscriptionCharges.map((subscriptionCharge, index) => (
                  <IonRow key={subscriptionCharge.id} className="table-row">
                    <IonCol className="sm">{index + 1}</IonCol>
                    <IonCol>{subscriptionCharge.price}</IonCol>
                    <IonCol>{moment.unix(subscriptionCharge.settledAt).local().format('MMM DD, YYYY')}</IonCol>
                    <IonCol>
                      <a href={subscriptionCharge.invoiceAndReceiptUrl} target="_blank">Invoice & Receipt</a>
                    </IonCol>
                  </IonRow>
                ))}
              </IonGrid>
            </div>
          </div>
        </>
      )}

      <IonAlert
        isOpen={showSubscribeSuccessAlert}
        onDidDismiss={() => {
          setShowSubscribeSuccessAlert(false);
          setShowSubscribeSreen(false);
        }}
        header="CarePlatform"
        message="Your account has been upgraded successfully!"
        buttons={[
          {
            text: 'OK',
            cssClass: 'confirm-button',
            handler: () => {
              setShowSubscribeSuccessAlert(false);
              setShowSubscribeSreen(false);
            }
          }
        ]}
      />

      <IonAlert
        isOpen={showUnsubscribeConfirmAlert}
        onDidDismiss={() => setShowUnsubscribeConfirmAlert(false)}
        header="CarePlatform"
        message="Are you sure you want to unsubscribe?"
        buttons={[
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: onUnsubscribe
          },
          {
            text: 'Cancel',
            cssClass: 'cancel-button'
          }
        ]}
      />

      <IonLoading isOpen={isLoading} />
    </IonContent>
  );
};

SubscriptionPage.propTypes = {
  user: PropTypes.any.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(SubscriptionPage)
});
