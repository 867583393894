import React, { useEffect, useMemo, useState } from 'react';
import {
  IonContent,
  IonIcon,
  IonListHeader,
  IonSegment,
  IonSegmentButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonInput,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonSpinner,
  IonAlert
} from '@ionic/react';
import { arrowBackOutline, closeOutline, searchOutline } from 'ionicons/icons';

import {
  addNewUser,
  fetchCustomers,
  fetchProviders,
  fetchClinics,
  fetchClinicMembers,
  fetchUsers
} from '../../data/dataApi';
import { User } from '../../models';
import UserItem from '../../components/UserItem';
import PhonenumberInput from '../../components/PhonenumberInput';

import './Users.scss';

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

const Users = () => {
  const [segment, setSegment] = useState<'customer' | 'provider' | 'clinic-admin' | 'admin' | 'marketing-member' | 'revenue-analyst' | 'helpdesk'>('customer');
  const [selectedClinic, setSelectedClinic] = useState<User | null>(null);
  const [searchKey, setSearchKey] = useState('');
  const [status, setStatus] = useState<'active' | 'pending' | 'inactive' | 'blocked'>('active');
  const [customers, setCustomers] = useState<User[]>([]);
  const [providers, setProviders] = useState<User[]>([]);
  const [clinics, setClinics] = useState<User[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [adminMembers, setAdminMembers] = useState<User[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [newUser, setNewUser] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: ''
  });
  const [isAdding, setIsAdding] = useState(false);
  const [showRequiredAlert, setShowRequiredAlert] = useState(false);
  const [error, setError] = useState('');

  const fetchMore = () => {
    setIsFetching(true);
    if (segment === 'provider' && !isFetchedAll) {
      const params = {
        skip: providers.length,
        status,
        searchKey
      };
      fetchProviders(params)
        .then((res) => {
          if (res.success) {
            setProviders([...providers, ...res.providers]);
            if (!res.providers.length || res.providers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
    if (segment === 'customer' && !isFetchedAll) {
      const params = {
        skip: customers.length,
        status,
        searchKey
      };
      fetchCustomers(params)
        .then((res) => {
          if (res.success) {
            setCustomers([...customers, ...res.customers]);
            if (!res.customers.length || res.customers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
    if (segment === 'clinic-admin' && !selectedClinic && !isFetchedAll) {
      const params = {
        skip: clinics.length,
        status,
        searchKey
      };
      fetchClinics(params)
        .then((res) => {
          if (res.success) {
            setClinics([...clinics, ...res.clinics]);
            if (!res.clinics.length || res.clinics.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
  };

  const fetchMembers = (id: number) => {
    setIsFetching(true);
    const params = {
      status,
      searchKey
    };
    fetchClinicMembers(id, params)
      .then((res) => {
        if (res.success) {
          setMembers(res.members);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsFetching(false));
  };

  const fetchAdminMembers = () => {
    setIsFetching(true);
    const params = {
      status,
      searchKey,
      role: segment
    };
    fetchUsers(params)
      .then((res) => {
        if (res.success) {
          setAdminMembers([...res.users]);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsFetching(false));
  };

  const onSearch = (str: string) => {
    setIsFetching(true);
    setIsFetchedAll(false);
    if (segment === 'provider') {
      const params = {
        skip: 0,
        status,
        searchKey: str
      };
      fetchProviders(params)
        .then((res) => {
          if (res.success) {
            setProviders([...res.providers]);
            if (!res.providers.length || res.providers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
    if (segment === 'customer') {
      const params = {
        skip: 0,
        status,
        searchKey: str
      };
      fetchCustomers(params)
        .then((res) => {
          if (res.success) {
            setCustomers([...res.customers]);
            if (!res.customers.length || res.customers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
    if (segment === 'clinic-admin' && !selectedClinic) {
      const params = {
        skip: 0,
        status,
        searchKey: str
      };
      fetchClinics(params)
        .then((res) => {
          if (res.success) {
            setClinics([...res.clinics]);
            if (!res.clinics.length || res.clinics.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
    if (segment === 'clinic-admin' && selectedClinic) {
      const params = {
        status,
        searchKey: str
      };
      fetchClinicMembers(selectedClinic.id, params)
        .then((res) => {
          if (res.success) {
            setMembers(res.members);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setIsFetching(false));
    }
    if (segment === 'admin' || segment === 'marketing-member' || segment === 'helpdesk' || segment === 'revenue-analyst') {
      const params = {
        status,
        searchKey: str,
        role: segment
      };
      fetchUsers(params)
        .then((res) => {
          if (res.success) {
            setAdminMembers([...res.users]);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
  };

  const deleteUser = (id: number) => {
    if (segment === 'customer') {
      const updatedCustomers = customers.filter((customer) => customer.id !== id);
      setCustomers([...updatedCustomers]);
    }
    if (segment === 'provider') {
      const updatedProviders = providers.filter((provider) => provider.id !== id);
      setProviders([...updatedProviders]);
    }
    if (segment === 'clinic-admin' && !selectedClinic) {
      const updatedClinics = clinics.filter((clinic) => clinic.id !== id);
      setClinics([...updatedClinics]);
    }
    if (segment === 'clinic-admin' && selectedClinic) {
      const updatedMembers = members.filter((member) => member.id !== id);
      setMembers([...updatedMembers]);
    }
    if (segment === 'admin' || segment === 'marketing-member' || segment === 'helpdesk' || segment === 'revenue-analyst') {
      const updatedAdminMembers = adminMembers.filter((am) => am.id !== id);
      setAdminMembers([...updatedAdminMembers]);
    }
  };

  const updateUser = (user: User) => {
    if (status !== user.status) {
      return deleteUser(user.id);
    }
    if (segment === 'customer') {
      const updatedCustomers = customers.map((customer) => {
        if (customer.id === user.id) {
          return user;
        }
        return customer;
      });
      return setCustomers([...updatedCustomers]);
    }
    if (segment === 'provider') {
      const updatedProviders = providers.map((provider) => {
        if (provider.id === user.id) {
          return user;
        }
        return provider;
      });
      return setProviders([...updatedProviders]);
    }
    if (segment === 'clinic-admin' && !selectedClinic) {
      const updatedClinics = clinics.map((clinic) => {
        if (clinic.id === user.id) {
          return user;
        }
        return clinic;
      });
      return setClinics([...updatedClinics]);
    }
    if (segment === 'clinic-admin' && selectedClinic) {
      const updatedMembers = members.map((member) => {
        if (member.id === user.id) {
          return user;
        }
        return member;
      });
      return setMembers([...updatedMembers]);
    }
    if (segment === 'admin' || segment === 'marketing-member' || segment === 'helpdesk' || segment === 'revenue-analyst') {
      const updatedAdminMembers = adminMembers.map((member) => {
        if (member.id === user.id) {
          return user;
        }
        return member;
      });
      return setAdminMembers([...updatedAdminMembers]);
    }
  };

  const onDismissModal = () => {
    setShowNewModal(false);
    setNewUser({
      name: '',
      lastName: '',
      email: '',
      phone: ''
    });
    setError('');
  };

  const onAddNew = (allowedSameEmail: boolean) => {
    if (
      !newUser.name
      || (!newUser.lastName && segment !== 'clinic-admin')
      || !newUser.email
      || !newUser.phone
    ) {
      return setShowRequiredAlert(true);
    }
    setError('');
    let data: any = {
      ...newUser,
      role: selectedClinic && segment === 'clinic-admin' ? 'clinic-member' : segment,
      phone: `+1${newUser.phone}`
    };
    if (allowedSameEmail) {
      data = {
        ...data,
        allowedSameEmail
      };
    }

    setIsAdding(true);
    return addNewUser(data)
      .then((res) => {
        if (res.success) {
          setShowNewModal(false);
          // TODO
        }
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setError(err.response.data.msg as string);
        }
        console.log(err);
      })
      .finally(() => setIsAdding(false));
  };

  const isLoading = useMemo(() => {
    if (segment === 'provider' && !providers.length && isFetching) {
      return true;
    }
    if (segment === 'customer' && !customers.length && isFetching) {
      return true;
    }
    if (segment === 'clinic-admin' && !selectedClinic && !clinics.length && isFetching) {
      return true;
    }
    if (segment === 'clinic-admin' && selectedClinic && !members.length && isFetching) {
      return true;
    }
    if ((segment === 'admin' || segment === 'marketing-member' || segment === 'helpdesk' || segment === 'revenue-analyst') && !adminMembers.length && isFetching) {
      return true;
    }
    return false;
  }, [segment, isFetching, providers, clinics, customers, adminMembers, members, selectedClinic]);

  const modalTitle = useMemo(() => {
    switch (segment) {
      case 'provider':
        return 'Add Provider';
      case 'customer':
        return 'Add Customer';
      case 'clinic-admin':
        if (selectedClinic) {
          return 'Add Clinic Member';
        }
        return 'Add Clinic';
      case 'admin':
        return 'Add Admin';
      case 'marketing-member':
        return 'Add Marketing Member';
      case 'revenue-analyst':
        return 'Add Revenue Analyst';
      case 'helpdesk':
        return 'Add Helpdesk';
      default:
        return '';
    }
  }, [segment, selectedClinic]);

  useEffect(() => {
    if (selectedClinic) {
      fetchMembers(selectedClinic.id);
    }
  }, [selectedClinic]);

  useEffect(() => {
    if (segment === 'admin' || segment === 'marketing-member' || segment === 'helpdesk' || segment === 'revenue-analyst') {
      fetchAdminMembers();
    } else if (selectedClinic) {
      fetchMembers(selectedClinic.id);
    } else {
      fetchMore();
    }
  }, [segment, status, selectedClinic]);

  useEffect(() => {
    if (!searchKey) {
      onSearch('');
    }
  }, [searchKey]);

  return (
    <IonContent id="users-list" className="page-content ion-padding">
      <div className="header">
        {selectedClinic ? (
          <span
            onClick={() => {
              setSelectedClinic(null);
              setSearchKey('');
            }}
          >
            <IonIcon color="favorite" icon={arrowBackOutline} size="large" />
          </span>
        ) : null}
        <IonListHeader className="page-header">
          {selectedClinic ? `Clinic Members of ${selectedClinic.name}` : 'Users'}
        </IonListHeader>
        <IonItem className="search-input" lines="none">
          <IonInput
            value={searchKey}
            placeholder="Search by name"
            onKeyPress={(e) => {
              if (e.keyCode === 13 || e.key === 'Enter') {
                onSearch(searchKey);
              }
            }}
            onIonChange={(e) => {
              setSearchKey(e.detail.value ?? '');
            }}
          />
          <IonIcon size="small" icon={searchOutline} onClick={() => onSearch(searchKey)} />
          <IonIcon size="small" icon={closeOutline} onClick={() => setSearchKey('')} />
        </IonItem>
        <div className="status-selector">
          Status:
          <IonSelect
            value={status}
            onIonChange={(e) => {
              setSearchKey('');
              setProviders([]);
              setCustomers([]);
              setClinics([]);
              setMembers([]);
              setAdminMembers([]);
              setIsFetchedAll(false);
              setStatus(e.detail.value);
            }}
          >
            <IonSelectOption value="active">Active</IonSelectOption>
            <IonSelectOption value="pending">Pending</IonSelectOption>
            <IonSelectOption value="inactive">Inactive</IonSelectOption>
            <IonSelectOption value="blocked">Blocked</IonSelectOption>
          </IonSelect>
        </div>
      </div>
      <div className="tab-bar">
        {selectedClinic ? null : (
          <IonSegment
            mode="ios"
            value={segment}
            onIonChange={(e): void => {
              setSearchKey('');
              setProviders([]);
              setCustomers([]);
              setClinics([]);
              setMembers([]);
              setAdminMembers([]);
              setIsFetchedAll(false);
              setSegment(e.detail.value as 'customer' | 'provider' | 'clinic-admin' | 'admin' | 'marketing-member' | 'revenue-analyst' | 'helpdesk');
            }}
          >
            <IonSegmentButton value="customer">Customers</IonSegmentButton>
            <IonSegmentButton value="provider">Providers</IonSegmentButton>
            <IonSegmentButton value="clinic-admin">Clinics</IonSegmentButton>
            <IonSegmentButton value="admin">Admins</IonSegmentButton>
            <IonSegmentButton value="marketing-member">Marketing</IonSegmentButton>
            <IonSegmentButton value="revenue-analyst">Revenue</IonSegmentButton>
            <IonSegmentButton value="helpdesk">Helpdesk</IonSegmentButton>
          </IonSegment>
        )}
        <IonButton color="favorite" onClick={() => setShowNewModal(true)}>
          Add New
        </IonButton>
        <IonButton color="dark" fill="outline">
          Bulk Load
        </IonButton>
      </div>
      <IonGrid>
        {segment === 'customer' ? (
          <IonRow>
            {customers.map((customer) => (
              <IonCol size="4" key={customer.id}>
                <UserItem
                  hasActions
                  user={customer}
                  updateUser={updateUser}
                  deleteUser={deleteUser}
                />
              </IonCol>
            ))}
          </IonRow>
        ) : null}
        {segment === 'provider' ? (
          <IonRow>
            {providers.map((provider) => (
              <IonCol size="4" key={provider.id}>
                <UserItem
                  hasActions
                  user={provider}
                  updateUser={updateUser}
                  deleteUser={deleteUser}
                />
              </IonCol>
            ))}
          </IonRow>
        ) : null}
        {segment === 'clinic-admin' && !selectedClinic ? (
          <IonRow>
            {clinics.map((clinic) => (
              <IonCol size="4" key={clinic.id}>
                <UserItem
                  hasActions
                  user={clinic}
                  updateUser={updateUser}
                  deleteUser={deleteUser}
                  onClick={() => {
                    setSearchKey('');
                    setSelectedClinic(clinic);
                  }}
                />
              </IonCol>
            ))}
          </IonRow>
        ) : null}
        {segment === 'clinic-admin' && selectedClinic ? (
          <IonRow>
            {members.map((member) => (
              <IonCol size="4" key={member.id}>
                <UserItem
                  hasActions
                  user={member}
                  updateUser={updateUser}
                  deleteUser={deleteUser}
                />
              </IonCol>
            ))}
          </IonRow>
        ) : null}
        {segment === 'admin' || segment === 'marketing-member' || segment === 'helpdesk' || segment === 'revenue-analyst' ? (
          <IonRow>
            {adminMembers.map((am) => (
              <IonCol size="4" key={am.id}>
                <UserItem
                  hasActions
                  user={am}
                  updateUser={updateUser}
                  deleteUser={deleteUser}
                />
              </IonCol>
            ))}
          </IonRow>
        ) : null}
      </IonGrid>
      {(segment !== 'clinic-admin' || !selectedClinic) && !isFetchedAll ? (
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            fetchMore();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent
            loadingText="Please wait..."
            loadingSpinner="circles"
          />
        </IonInfiniteScroll>
      ) : null}

      {/* Modal to add new user */}
      <IonModal
        cssClass="marketing-modal"
        isOpen={showNewModal}
        onDidDismiss={onDismissModal}
      >
        <div className="header">
          <IonLabel>{modalTitle}</IonLabel>
        </div>
        <div className="input-container">
          <div className="header">
            First Name*
          </div>
          <IonItem lines="none" className="input">
            <IonInput
              type="text"
              placeholder="First Name"
              value={newUser.name}
              onIonChange={(e) => setNewUser({ ...newUser, name: e.detail.value as string })}
            />
          </IonItem>
        </div>
        <div className="input-container">
          <div className="header">
            Last Name*
          </div>
          <IonItem lines="none" className="input">
            <IonInput
              type="text"
              placeholder="Last Name"
              value={newUser.lastName}
              onIonChange={(e) => setNewUser({ ...newUser, lastName: e.detail.value as string })}
            />
          </IonItem>
        </div>
        <div className="input-container">
          <div className="header">
            Email Address*
          </div>
          <IonItem lines="none" className="input">
            <IonInput
              type="email"
              placeholder="Email Address"
              value={newUser.email}
              onIonChange={(e) => setNewUser({ ...newUser, email: e.detail.value as string })}
            />
          </IonItem>
        </div>
        <div className="input-container">
          <div className="header">
            Contact Number*
          </div>
          <IonItem lines="none" className="input phone">
            <PhonenumberInput
              value={newUser.phone}
              onChange={(value) => setNewUser({ ...newUser, phone: value })}
            />
          </IonItem>
        </div>
        <div className="actions">
          <IonButton color="dark" fill="outline" expand="block" onClick={onDismissModal}>
            Cancel
          </IonButton>
          <IonButton color="favorite" expand="block" onClick={() => onAddNew(false)}>
            {isAdding ? <IonSpinner /> : 'Add'}
          </IonButton>
        </div>
      </IonModal>

      <IonAlert
        isOpen={showRequiredAlert}
        onDidDismiss={(): void => setShowRequiredAlert(false)}
        header="CarePlatform"
        message="Please fill empty information."
        buttons={['OK']}
      />

      <IonAlert
        isOpen={!!error}
        onDidDismiss={(): void => setError('')}
        header="CarePlatform"
        message={
          error.includes('email')
            ? 'Email is already in  use. Do you want to use the same email?'
            : 'Phone number is already in use'
        }
        buttons={error.includes('email') ? [
          {
            text: 'OK',
            handler: () => onAddNew(true)
          },
          'Cancel'
        ] : ['OK']}
      />

      <IonLoading isOpen={isLoading} />

    </IonContent>
  );
};

export default React.memo(Users);
