/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonSpinner,
} from '@ionic/react';
import {
  closeOutline, ellipsisHorizontal, searchOutline
} from 'ionicons/icons';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import clsx from 'clsx';

import connect from '../../data/connect';
import {
  fetchCustomers,
  fetchProviders,
  fetchClinics,
  fetchClinicMembers,
  addNewUser,
  inactivateAccount
} from '../../data/dataApi';
import PhonenumberInput from '../../components/PhonenumberInput';
import PlaceSearchInput from '../../components/PlaceSearchInput';
import {
  DetailsIcon, ExportIcon, TrashIcon, UsersIcon
} from '../../icons';
import {
  ProviderType, Specialist, User, UserRole
} from '../../models';

interface ActionsProps {
  user: User
  onSelect: (value: User) => void
  deleteUser: (id: number) => void
}

const Actions: React.FC<ActionsProps> = ({ user, onSelect, deleteUser }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onShowDetails = () => {
    // TODO
  };

  const onDelete = () => {
    setIsLoading(true);
    inactivateAccount(user.id, true)
      .then((res: { success: boolean }) => {
        if (res.success) {
          deleteUser(user.id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    setIsPopoverOpen(false);
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'top', 'left', 'right']}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="#8dbfb6"
            arrowSize={10}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className="popover-content">
              <div className="option" onClick={onShowDetails}>
                <IonLabel>
                  <DetailsIcon />
                  Details
                </IonLabel>
              </div>
              <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                <IonLabel color="danger">
                  <TrashIcon />
                  Delete
                </IonLabel>
              </div>
              {user.role === UserRole.CLINIC_ADMIN ? (
                <div className="option" onClick={() => onSelect(user)}>
                  <IonLabel>
                    <UsersIcon />
                    Members
                  </IonLabel>
                </div>
              ) : null}
            </div>
          </ArrowContainer>
        )}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        {isLoading ? <IonSpinner /> : (
          <span
            className="action"
            title="More actions"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <IonIcon icon={ellipsisHorizontal} />
          </span>
        )}
      </Popover>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={(): void => setShowRemoveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to delete this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onDelete()
          }
        ]}
      />
    </>
  );
};

Actions.propTypes = {
  user: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired
};

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface CRMProps {
  toggleHeader: (value: boolean) => void
}

interface StateProps {
  providerTypes: ProviderType[]
  allSpecialists: Specialist[]
}

const CRM: React.FC<StateProps & CRMProps> = ({
  toggleHeader, providerTypes, allSpecialists
}) => {
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [isDescending, setIsDescending] = useState(false);
  const [type, setType] = useState<'existing' | 'new'>('new');
  const [segment, setSegment] = useState<'provider' | 'customer' | 'clinic'>('customer');
  const [showNewModal, setShowNewModal] = useState(false);
  const [location, setlocation] = useState({} as any);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const searchTimeoutRef = useRef<number | null>(null);
  const [selectedClinic, setSelectedClinic] = useState<User | null>(null);
  const [newUser, setNewUser] = useState({
    name: '',
    lastName: '',
    type: 0,
    email: '',
    phone: '',
    specialists: []
  });
  const [isAdding, setIsAdding] = useState(false);
  const [showRequiredAlert, setShowRequiredAlert] = useState(false);
  const [error, setError] = useState('');

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const fetchMore = (isNew: boolean, hasSearchKey: boolean) => {
    const params = {
      skip: isNew ? 0 : users.length,
      isNew: type === 'new',
      searchKey: hasSearchKey ? searchKey : '',
      isDescending,
      orderBy
    };
    if (segment === 'provider') {
      setIsFetching(true);
      fetchProviders(params)
        .then((res: { success: boolean, providers: User[] }) => {
          if (res.success) {
            if (isNew) {
              setUsers([...res.providers]);
            } else {
              setUsers([...users, ...res.providers]);
            }
            if (!res.providers.length || res.providers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    } else if (segment === 'customer') {
      setIsFetching(true);
      fetchCustomers(params)
        .then((res: { success: boolean, customers: User[] }) => {
          if (res.success) {
            if (isNew) {
              setUsers([...res.customers]);
            } else {
              setUsers([...users, ...res.customers]);
            }
            if (!res.customers.length || res.customers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    } else if (segment === 'clinic' && !selectedClinic) {
      setIsFetching(true);
      fetchClinics(params)
        .then((res: { success: boolean, clinics: User[] }) => {
          if (res.success) {
            if (isNew) {
              setUsers([...res.clinics]);
            } else {
              setUsers([...users, ...res.clinics]);
            }
            if (!res.clinics.length || res.clinics.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    } else if (segment === 'clinic' && selectedClinic) {
      setIsFetching(true);
      fetchClinicMembers(selectedClinic.id, params)
        .then((res: { success: boolean, members: User[] }) => {
          if (res.success) {
            setUsers([...res.members]);
            setIsFetchedAll(true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
  };

  const fetchMore2 = (isNew: boolean, hasSearchKey: string) => {
    const params = {
      skip: isNew ? 0 : users.length,
      isNew: type === 'new',
      searchKey: hasSearchKey ? searchKey : '',
      isDescending,
      orderBy
    };
    if (segment === 'provider') {
      setIsFetching(true);
      fetchProviders(params)
        .then((res: { success: boolean, providers: User[] }) => {
          if (res.success) {
            if (isNew) {
              setUsers([...res.providers]);
            } else {
              setUsers([...users, ...res.providers]);
            }
            if (!res.providers.length || res.providers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    } else if (segment === 'customer') {
      setIsFetching(true);
      fetchCustomers(params)
        .then((res: { success: boolean, customers: User[] }) => {
          if (res.success) {
            if (isNew) {
              setUsers([...res.customers]);
            } else {
              setUsers([...users, ...res.customers]);
            }
            if (!res.customers.length || res.customers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    } else if (segment === 'clinic' && !selectedClinic) {
      setIsFetching(true);
      fetchClinics(params)
        .then((res: { success: boolean, clinics: User[] }) => {
          if (res.success) {
            if (isNew) {
              setUsers([...res.clinics]);
            } else {
              setUsers([...users, ...res.clinics]);
            }
            if (!res.clinics.length || res.clinics.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    } else if (segment === 'clinic' && selectedClinic) {
      setIsFetching(true);
      fetchClinicMembers(selectedClinic.id, params)
        .then((res: { success: boolean, members: User[] }) => {
          if (res.success) {
            setUsers([...res.members]);
            setIsFetchedAll(true);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
  };

  const onSearch = (isInit: boolean) => {
    setIsFetchedAll(false);
    if (isInit) {
      setSearchKey('');
    } else {
      fetchMore(true, true);
    }
  };

  const onDismissModal = () => {
    setShowNewModal(false);
    setNewUser({
      name: '',
      lastName: '',
      type: 0,
      email: '',
      phone: '',
      specialists: []
    });
    setlocation({} as any);
    setError('');
  };

  const onAddNew = (allowedSameEmail: boolean) => {
    if (
      !newUser.name
      || (!newUser.lastName && segment !== 'clinic')
      || !newUser.email
      || !newUser.phone
      || (!newUser.type && segment !== 'customer')
    ) {
      return setShowRequiredAlert(true);
    }
    setError('');
    let data: any = {
      isNew: true,
      name: newUser.name,
      location,
      email: newUser.email,
      phone: `+1${newUser.phone}`
    };
    if (segment === 'customer') {
      data = {
        ...data,
        role: 'customer',
        lastName: newUser.lastName
      };
    }
    if (segment === 'provider') {
      data = {
        ...data,
        role: 'provider',
        lastName: newUser.lastName,
        type: newUser.type,
        specialists: newUser.specialists
      };
    }
    if (segment === 'clinic') {
      data = {
        ...data,
        role: 'clinic-admin',
        type: newUser.type
      };
    }
    if (allowedSameEmail) {
      data = {
        ...data,
        allowedSameEmail
      };
    }

    setIsAdding(true);
    return addNewUser(data)
      .then((res: { success: boolean }) => {
        if (res.success) {
          setShowNewModal(false);
          setType('new');
          setOrderBy('');
          setIsDescending(!isDescending);
        }
      })
      .catch((err) => {
        if (err.response.status === 409) {
          setError(err.response.data.msg as string);
        }
        console.log(err);
      })
      .finally(() => setIsAdding(false));
  };

  const onDeleteUser = (userId: number) => {
    const updatedUsers = users.filter((c) => c.id !== userId);
    setUsers([...updatedUsers]);
  };

  const modalTitle = useMemo(() => {
    switch (segment) {
      case 'provider':
        return 'Add Provider';
      case 'customer':
        return 'Add Customer';
      case 'clinic':
        return 'Add Clinic';
      default:
        return '';
    }
  }, [segment]);

  useEffect(() => {
    setIsFetchedAll(false);
    fetchMore(true, true);
  }, [type, segment, isDescending, orderBy, selectedClinic]);

  useEffect(() => {
    if (!searchKey) {
      fetchMore(true, false);
    }
  }, [searchKey]);

  return (
    <div className="marketing-section">
      <div className="marketing-section-header">
        <div className="marketing-section-filter">
          <IonSegment
            className="sm"
            mode="ios"
            value={type}
            onIonChange={(e) => setType(e.detail.value as 'existing' | 'new')}
          >
            <IonSegmentButton value="new">New</IonSegmentButton>
            <IonSegmentButton value="existing">Exisiting</IonSegmentButton>
          </IonSegment>
          <IonSegment
            className="lg"
            mode="ios"
            value={segment}
            onIonChange={(e) => {
              setSelectedClinic(null);
              setSegment(e.detail.value as 'provider' | 'customer' | 'clinic');
            }}
          >
            <IonSegmentButton value="customer">Customers</IonSegmentButton>
            <IonSegmentButton value="provider">Providers</IonSegmentButton>
            <IonSegmentButton value="clinic">Clinics</IonSegmentButton>
          </IonSegment>
          {type === 'new' ? (
            <>
              <IonButton color="favorite" onClick={() => setShowNewModal(true)}>
                + Add Manual
              </IonButton>
              <IonButton color="dark" fill="outline">
                <ExportIcon />
                &nbsp;Import
              </IonButton>
            </>
          ) : null}
        </div>
        <IonItem className="search-input" lines="none">
          <IonInput
            value={searchKey}
            placeholder="Search"
            onIonChange={(e) => {
              const inputValue = e.detail.value ?? '';
              setSearchKey(inputValue);
              if (searchTimeoutRef.current) {
                clearTimeout(searchTimeoutRef.current);
              }
            
              searchTimeoutRef.current = window.setTimeout(() => {
                fetchMore2(true, inputValue);
              }, 450);
            }}
          />
          <IonIcon size="small" icon={searchOutline} onClick={() => onSearch(false)} />
          <IonIcon size="small" icon={closeOutline} onClick={() => setSearchKey('')} />
        </IonItem>
      </div>
      <div className="marketing-section-list">
        {selectedClinic ? (
          <div className="sub-title">
            {`Clinic Members of ${selectedClinic.name}`}
            <span onClick={() => setSelectedClinic(null)}>Back</span>
          </div>
        ) : null}
        <IonGrid className="table">
          <IonRow className="table-header">
            <IonCol className="sm">NO</IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'name' })}
              onClick={() => onChangeOrderBy('name')}
            >
              NAME&nbsp;
              {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'email' })}
              onClick={() => onChangeOrderBy('email')}
            >
              EMAIL ADDRESS&nbsp;
              {orderBy === 'email' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'phone' })}
              onClick={() => onChangeOrderBy('phone')}
            >
              CONTACT NUMBER&nbsp;
              {orderBy === 'phone' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            {segment !== 'customer' ? (
              <IonCol
                className={clsx('cursor-pointer', { isOrderBy: orderBy === 'type' })}
                onClick={() => onChangeOrderBy('type')}
              >
                PROVIDER TYPE&nbsp;
                {orderBy === 'type' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
              </IonCol>
            ) : null}
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'country' })}
              onClick={() => onChangeOrderBy('country')}
            >
              COUNTRY&nbsp;
              {orderBy === 'country' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'state' })}
              onClick={() => onChangeOrderBy('state')}
            >
              PROVINCE&nbsp;
              {orderBy === 'state' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'city' })}
              onClick={() => onChangeOrderBy('city')}
            >
              CITY&nbsp;
              {orderBy === 'city' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>ACTION</IonCol>
          </IonRow>
          {users.map((user, index) => (
            <IonRow key={user.id} className="table-row">
              <IonCol className="sm">
                {index + 1}
              </IonCol>
              <IonCol>
                {`${user.name} ${user.lastName ?? ''}`}
              </IonCol>
              <IonCol>
                {user.email}
              </IonCol>
              <IonCol>
                {user.phone}
              </IonCol>
              {segment !== 'customer' ? (
                <IonCol>
                  {providerTypes.find((pt) => pt.id === user.type)?.name}
                </IonCol>
              ) : null}
              <IonCol>{user.locations?.[0]?.country}</IonCol>
              <IonCol>{user.locations?.[0]?.state}</IonCol>
              <IonCol>{user.locations?.[0]?.city}</IonCol>
              <IonCol>
                <Actions user={user} onSelect={setSelectedClinic} deleteUser={onDeleteUser} />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
        {isFetchedAll ? null : (
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              fetchMore(false, true);
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent
              loadingText="Please wait..."
              loadingSpinner="circles"
            />
          </IonInfiniteScroll>
        )}
      </div>

      {/* Modal to add new user */}
      <IonModal
        cssClass={clsx('marketing-modal', { lg: segment === 'provider' })}
        isOpen={showNewModal}
        onDidDismiss={onDismissModal}
      >
        <div className="header">
          <IonLabel>{modalTitle}</IonLabel>
        </div>
        {segment === 'provider' ? (
          <>
            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <div className="input-container">
                    <div className="header">
                      First Name*
                    </div>
                    <IonItem lines="none" className="input">
                      <IonInput
                        type="text"
                        placeholder="First Name"
                        value={newUser.name}
                        onIonChange={(e) => setNewUser({ ...newUser, name: e.detail.value as string })}
                      />
                    </IonItem>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="input-container">
                    <div className="header">
                      Last Name*
                    </div>
                    <IonItem lines="none" className="input">
                      <IonInput
                        type="text"
                        placeholder="Last Name"
                        value={newUser.lastName}
                        onIonChange={(e) => setNewUser({ ...newUser, lastName: e.detail.value as string })}
                      />
                    </IonItem>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <div className="input-container">
                    <div className="header">
                      Email Address*
                    </div>
                    <IonItem lines="none" className="input">
                      <IonInput
                        type="email"
                        placeholder="Email Address"
                        value={newUser.email}
                        onIonChange={(e) => setNewUser({ ...newUser, email: e.detail.value as string })}
                      />
                    </IonItem>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="input-container">
                    <div className="header">
                      Contact Number*
                    </div>
                    <IonItem lines="none" className="input phone">
                      <PhonenumberInput
                        value={newUser.phone}
                        onChange={(value) => setNewUser({ ...newUser, phone: value })}
                      />
                    </IonItem>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <div className="input-container">
                    <div className="header">
                      Provider Type*
                    </div>
                    <IonItem lines="none" className="input">
                      <IonSelect
                        placeholder="Select Provider Type"
                        value={newUser.type}
                        onIonChange={(e): void => setNewUser({ ...newUser, type: parseInt(e.detail.value, 10) })}
                      >
                        {providerTypes.map((providerType) => (
                          <IonSelectOption key={providerType.id} value={providerType.id}>
                            {providerType.name.slice(0, -1)}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="input-container">
                    <div className="header">
                      Specialities
                    </div>
                    <IonItem lines="none" className="input">
                      <IonSelect
                        placeholder="Select specialties..."
                        multiple
                        value={newUser.specialists}
                        disabled={!newUser.type}
                        onIonChange={(e): void => setNewUser({ ...newUser, specialists: e.detail.value })}
                      >
                        {allSpecialists
                          .filter((sp) => sp.type === newUser.type)
                          .map((specialist) => (
                            <IonSelectOption key={specialist.id} value={specialist.id}>
                              {specialist.name}
                            </IonSelectOption>
                          ))}
                      </IonSelect>
                    </IonItem>
                  </div>
                </IonCol>
                <IonCol size="12">
                  <div className="input-container">
                    <div className="header">
                      Location
                    </div>
                    <IonItem lines="none" className="input">
                      <PlaceSearchInput
                        value={location?.address}
                        onChange={(value): void => setlocation(value)}
                      />
                    </IonItem>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        ) : null}
        {segment === 'customer' ? (
          <>
            <div className="input-container">
              <div className="header">
                First Name*
              </div>
              <IonItem lines="none" className="input">
                <IonInput
                  type="text"
                  placeholder="First Name"
                  value={newUser.name}
                  onIonChange={(e) => setNewUser({ ...newUser, name: e.detail.value as string })}
                />
              </IonItem>
            </div>
            <div className="input-container">
              <div className="header">
                Last Name*
              </div>
              <IonItem lines="none" className="input">
                <IonInput
                  type="text"
                  placeholder="Last Name"
                  value={newUser.lastName}
                  onIonChange={(e) => setNewUser({ ...newUser, lastName: e.detail.value as string })}
                />
              </IonItem>
            </div>
            <div className="input-container">
              <div className="header">
                Email Address*
              </div>
              <IonItem lines="none" className="input">
                <IonInput
                  type="email"
                  placeholder="Email Address"
                  value={newUser.email}
                  onIonChange={(e) => setNewUser({ ...newUser, email: e.detail.value as string })}
                />
              </IonItem>
            </div>
            <div className="input-container">
              <div className="header">
                Contact Number*
              </div>
              <IonItem lines="none" className="input phone">
                <PhonenumberInput
                  value={newUser.phone}
                  onChange={(value) => setNewUser({ ...newUser, phone: value })}
                />
              </IonItem>
            </div>
            <div className="input-container">
              <div className="header">
                Location
              </div>
              <IonItem lines="none" className="input">
                <PlaceSearchInput
                  value={location?.address}
                  onChange={(value): void => setlocation(value)}
                />
              </IonItem>
            </div>
          </>
        ) : null}
        {segment === 'clinic' ? (
          <>
            <div className="input-container">
              <div className="header">
                Clinic Name*
              </div>
              <IonItem lines="none" className="input">
                <IonInput
                  type="text"
                  placeholder="Clinic Name"
                  value={newUser.name}
                  onIonChange={(e) => setNewUser({ ...newUser, name: e.detail.value as string })}
                />
              </IonItem>
            </div>
            <div className="input-container">
              <div className="header">
                Email Address*
              </div>
              <IonItem lines="none" className="input">
                <IonInput
                  type="email"
                  placeholder="Email Address"
                  value={newUser.email}
                  onIonChange={(e) => setNewUser({ ...newUser, email: e.detail.value as string })}
                />
              </IonItem>
            </div>
            <div className="input-container">
              <div className="header">
                Contact Number*
              </div>
              <IonItem lines="none" className="input phone">
                <PhonenumberInput
                  value={newUser.phone}
                  onChange={(value) => setNewUser({ ...newUser, phone: value })}
                />
              </IonItem>
            </div>
            <div className="input-container">
              <div className="header">
                Provider Type*
              </div>
              <IonItem lines="none" className="input">
                <IonSelect
                  placeholder="Select Provider Type"
                  value={newUser.type}
                  onIonChange={(e): void => setNewUser({ ...newUser, type: parseInt(e.detail.value, 10) })}
                >
                  {providerTypes.map((providerType) => (
                    <IonSelectOption key={providerType.id} value={providerType.id}>
                      {providerType.name.slice(0, -1)}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </div>
            <div className="input-container">
              <div className="header">
                Location
              </div>
              <IonItem lines="none" className="input">
                <PlaceSearchInput
                  value={location?.address}
                  onChange={(value): void => setlocation(value)}
                />
              </IonItem>
            </div>
          </>
        ) : null}
        <div className="actions">
          <IonButton color="dark" fill="outline" expand="block" onClick={onDismissModal}>
            Cancel
          </IonButton>
          <IonButton color="favorite" expand="block" onClick={() => onAddNew(false)}>
            {isAdding ? <IonSpinner /> : 'Add'}
          </IonButton>
        </div>
      </IonModal>

      <IonAlert
        isOpen={showRequiredAlert}
        onDidDismiss={(): void => setShowRequiredAlert(false)}
        header="CarePlatform"
        message="Please input required information."
        buttons={['OK']}
      />

      <IonAlert
        isOpen={!!error}
        onDidDismiss={(): void => setError('')}
        header="CarePlatform"
        message={
          error.includes('email')
            ? 'Email is already in  use. Do you want to use the same email?'
            : 'Phone number is already in use'
        }
        buttons={error.includes('email') ? [
          {
            text: 'OK',
            handler: () => onAddNew(true)
          },
          'Cancel'
        ] : ['OK']}
      />

      <IonLoading isOpen={isFetching} />
    </div>
  );
};

CRM.propTypes = {
  toggleHeader: PropTypes.func.isRequired,
  providerTypes: PropTypes.array.isRequired,
  allSpecialists: PropTypes.any.isRequired
};

export default connect<CRMProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes,
    allSpecialists: state.data.allSpecialists
  }),
  component: React.memo(CRM)
});
