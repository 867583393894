import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonList,
  IonCol,
  IonAlert,
  IonListHeader,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonLoading,
} from '@ionic/react';

import Avatar from '../../components/Avatar';
import connect from '../../data/connect';
import { ProviderType, User, UserRole } from '../../models';
import { fetchProvider, fetchProviderRatings } from '../../data/dataApi';
import RatingItem from '../../components/RatingItem';
import Map from '../../components/Map';
import StarRating from '../../components/StarRating';
import './ProviderDetail.scss';

interface MatchParams {
  id: string
}

interface StateProps {
  user: User,
  providerTypes: ProviderType[]
}

type ProviderDetailProps = RouteComponentProps<MatchParams> & StateProps;

const ProviderDetail: React.FC<ProviderDetailProps> = ({
  match,
  history,
  user,
  providerTypes
}) => {
  const [provider, setProvider] = useState<User | null>(null);
  const [ratings, setRatings] = useState([]);
  const [alert, setAlert] = useState(false);
  const [profileAlert, setProfileAlert] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCertified, setIsCertified] = useState(false);

  const onViewReviews = (): void => {
    fetchProviderRatings(Number(match.params.id))
      .then((res) => {
        setRatings(res.bookings);
      })
      .catch((error) => console.log(error));
  };

  const onBookNow = (): void => {
    const {
      name, lastName, email, role, locations
    } = user;
    const nameEmailConditon = name
      && name.length > 0
      && lastName
      && lastName.length > 0
      && email
      && email.length > 0;

    const isValidProfile = nameEmailConditon && locations.length > 0;
    if (isValidProfile) {
      if (user.sources.filter((source) => source.status === 'active').length) {
        history.push(`/customer/provider/${match.params.id}/schedule`);
      } else {
        setAlert(true);
      }
    } else {
      setProfileAlert(true);
    }
  };

  const rating = provider?.rating ?? 0;

  useEffect(() => {
    if (match.params.id) {
      setIsLoading(true);
      fetchProvider(Number(match.params.id))
        .then((res) => {
          if (res.success) {
            setProvider(res.user);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [match]);

  useEffect(() => {
    if (provider) {
      provider.licenses.map((license) => {
        if (license.status === 'verified' && (providerTypes.find((pt) => pt.name === `${license.type}s`))?.id === provider.type) {
          setIsCertified(true);
        }
      });
    }
  }, [provider, providerTypes]);

  if (!provider) {
    return <div>Invalid Provider</div>;
  }

  return (
    <IonContent id="provider-detail" className="provider-detail-content page-content ion-padding">
      <IonListHeader className="page-header">Provider Profile</IonListHeader>
      <IonGrid>
        <IonRow className="profile-content profile">
          <Avatar user={provider} size="lg" />
          <IonLabel>
            <h2>{`${provider.name} ${provider.lastName ?? ''}`}</h2>
            <p>{provider.role}</p>
            <span>
              <StarRating hasOneStar rating={Number((rating / 5).toFixed(1))} readonly />
              <span className="rating">{rating.toFixed(1)}</span>
            </span>
            {isCertified ? null : <p className="certified-label">This Provider is not certified yet.</p>}
          </IonLabel>
        </IonRow>
        <IonRow className="booking-options">
          <IonLabel className="sub-title">Book Now</IonLabel>
          <div>
            {provider.services.map((service) => (
              <div key={service.id} className="book-service">{service.name}</div>
            ))}
          </div>
          <IonButton color="favorite" disabled={!isCertified} onClick={onBookNow}>
            Book Now
          </IonButton>
        </IonRow>
        <IonRow className="provider-info">
          <div className="sub-detail">
            <div className="section-title">
              {`About ${provider.name} ${provider.lastName ?? ''}`}
            </div>
            <div>{provider.about || 'No description'}</div>
          </div>
          <div className="sub-detail">
            <div className="section-title">Languages Spoken</div>
            <div>
              {provider.languages
                .map((language) => language.name)
                .join(' - ')}
            </div>
          </div>
          <div className="sub-detail">
            <div className="section-title">Services Offered</div>
            <div>
              {provider.specialists
                .map((specialist) => specialist.name)
                .join(' | ')}
            </div>
          </div>
        </IonRow>
        <IonRow className="location">
          <IonLabel className="sub-title">Select Location</IonLabel>
          <IonSelect
            interface="action-sheet"
            value={currentLocation}
            onIonChange={(e): void => setCurrentLocation(e.detail.value)}
          >
            {provider.locations.map((item, index) => (
              <IonSelectOption key={item.id} value={index}>
                {item.address}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonRow>
        {provider.locations.length > 0 && (
          <IonRow>
            <div className="map-container">
              <Map
                locations={provider.locations}
                mapCenter={provider.locations[currentLocation]}
              />
            </div>
          </IonRow>
        )}
        {ratings.length > 0 && (
          <IonRow>
            <IonCol size="12">
              <IonList>
                {ratings.map((booking, index) => (
                  <RatingItem key={index} booking={booking} />
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <IonButton
              color="favorite"
              onClick={(): void => onViewReviews()}
            >
              View reviews
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonAlert
        isOpen={alert}
        onDidDismiss={(): void => {
          setAlert(false);
        }}
        header="Care Platform"
        message="Please attach your credit card to make appointments."
        buttons={[
          'No',
          {
            text: 'Yes',
            handler: (): void => history.push('/customer/wallet')
          }
        ]}
      />

      <IonAlert
        isOpen={profileAlert}
        onDidDismiss={(): void => {
          setProfileAlert(false);
        }}
        header="Care Platform"
        message="Please enter all needed profile information before booking an appointment."
        buttons={[
          {
            text: 'Cancel',
            handler: (): void => history.push(`/${user.role}/dashboard`)
          },
          {
            text: 'Ok',
            handler: (): void => history.push(`/${user.role}/profile`)
          }
        ]}
      />
      <IonLoading isOpen={isLoading} />
    </IonContent>
  );
};

ProviderDetail.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  providerTypes: PropTypes.array.isRequired
};

export default connect<RouteComponentProps<MatchParams>, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user,
    providerTypes: state.data.providerTypes
  }),
  component: React.memo(ProviderDetail)
});
