import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonAlert,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSpinner
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import moment from 'moment';
import axios from 'axios';

import Avatar from './Avatar';
import { AppointmentType, Booking, BookingStatus, ChargeStatus } from '../models';
import { getRecords } from '../data/dataApi';

interface AdminBookingItemProps {
  booking: Booking
}

const AdminBookingItem: React.FC<AdminBookingItemProps> = ({ booking }) => {
  const {
    customer,
    provider,
    date,
    time,
    service,
    duration,
    price,
    status,
    charge
  } = booking;
  const { chargedAmount, amount, status: chargeStatus, stripeFee } = charge!;
  const [isDownloading, setIsDownloading] = useState(false);
  const [showInProgressAlert, setShowInProgressAlert] = useState(false);
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);

  const downloadRecords = async () => {
    if (booking.hasDownloadFile) {
      setIsDownloading(true);
      try {
        const { records } = await getRecords(booking.id);
        if (records && records.length > 0) {
          const promises: any[] = [];
          records.map((record: any) => {
            promises.push(
              axios.get(record.signedUrl, { responseType: 'blob' })
                .then(res => {
                  // Creating new object of PDF file
                  const fileURL = window.URL.createObjectURL(res.data);
                  // Setting various property values
                  let alink = document.createElement('a');
                  alink.href = fileURL;
                  alink.download = record.filename;
                  alink.click();
                })
                .catch((err) => console.log(err))
            );
          });
          await Promise.all(promises);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setShowInProgressAlert(true);
    }
  };

  const renderActions = (): JSX.Element => {
    if (status === BookingStatus.ACCEPTED) {
      return <></>;
    }
    if (status === BookingStatus.COMPLETED) {
      return (
        <>
          {booking.type === AppointmentType.VIRTUAL ? (
            <IonButton color="favorite" onClick={downloadRecords}>
              {isDownloading ? <IonSpinner /> : 'Call Record'}
            </IonButton>
          ) : null}
          <IonButton color="favorite" fill="outline" onClick={() => setShowPaymentInfo(true)}>
            Payment
          </IonButton>
        </>
      );
    }
    return (
      <IonButton color="favorite" fill="outline" onClick={() => setShowPaymentInfo(true)}>
        Payment
      </IonButton>
    );
  };

  return (
    <div className="booking">
      <IonItem lines="none" className="header">
        <p className="service-item">
          {`${service} - ${duration}min - $${price}`}
        </p>
      </IonItem>
      <IonItem lines="none" className="content admin">
        <Avatar user={customer} />
        <IonLabel>
          <h2>{`${customer.name} ${customer.lastName ?? ''}`}</h2>
        </IonLabel>
        <Avatar user={provider} />
        <IonLabel>
          <h2>{`${provider.name} ${provider.lastName ?? ''}`}</h2>
        </IonLabel>
      </IonItem>
      <IonItem lines="none" className="footer">
        <p className="date">{moment.utc(`${date} ${time}`).local().format('MMM DD, YYYY hh:mm A')}</p>
        <div slot="end" className="actions">
          {renderActions()}
        </div>
      </IonItem>
      <IonAlert
        isOpen={showInProgressAlert}
        onDidDismiss={(): void => setShowInProgressAlert(false)}
        header="Care Platform"
        message="Call record is being prepared, please allow up to 60 minutes from the end call time for the files to be available for download."
        buttons={['OK']}
      />
      <IonModal
        cssClass="payment-modal"
        isOpen={showPaymentInfo}
        onDidDismiss={() => setShowPaymentInfo(false)}
      >
        <IonLabel>Payment Information</IonLabel>
        <IonButton color="dark" fill="clear" onClick={() => setShowPaymentInfo(false)}>
          <IonIcon icon={closeOutline} />
        </IonButton>
        {chargeStatus === ChargeStatus.CHARGED && chargedAmount > amount / 2 ? (
          <IonList className="payment-content">
            <IonItem lines="none">
              <h5 slot="start">Provider</h5>
              <h3 slot="end">{`$${chargedAmount}`}</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Refund</h5>
              <h3 slot="end">$0</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">CarePlatform</h5>
              <h3 slot="end">{`$${(amount * 150 - stripeFee * 1000) / 1000}`}</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Stripe Fee</h5>
              <h3 slot="end">{`$${stripeFee}`}</h3>
            </IonItem>
          </IonList>
        ) : null}
        {chargeStatus === ChargeStatus.CHARGED && chargedAmount > 0 && chargedAmount < amount / 2 ? (
          <IonList className="payment-content">
            <IonItem lines="none">
              <h5 slot="start">Provider</h5>
              <h3 slot="end">{`$${chargedAmount}`}</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Refund</h5>
              <h3 slot="end">{`$${(amount * 850 - chargedAmount * 1000) / 1000}`}</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">CarePlatform</h5>
              <h3 slot="end">{`$${(amount * 150 - stripeFee * 1000) / 1000}`}</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Stripe Fee</h5>
              <h3 slot="end">{`$${stripeFee}`}</h3>
            </IonItem>
          </IonList>
        ) : null}
        {chargeStatus === ChargeStatus.CHARGED && chargedAmount === 0 ? (
          <IonList className="payment-content">
            <IonItem lines="none">
              <h5 slot="start">Provider</h5>
              <h3 slot="end">$0</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Refund</h5>
              <h3 slot="end">$0</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">CarePlatform</h5>
              <h3 slot="end">{`$${(amount * 1000 - stripeFee * 1000) / 1000}`}</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Stripe Fee</h5>
              <h3 slot="end">{`$${stripeFee}`}</h3>
            </IonItem>
          </IonList>
        ) : null}
        {chargeStatus === ChargeStatus.REFUNDED ? (
          <IonList className="payment-content">
            <IonItem lines="none">
              <h5 slot="start">Provider</h5>
              <h3 slot="end">$0</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Refund</h5>
              <h3 slot="end">{`$${amount}`}</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">CarePlatform</h5>
              <h3 slot="end">{stripeFee ? `-$${stripeFee}` : '$0'}</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Stripe Fee</h5>
              <h3 slot="end">{`$${stripeFee}`}</h3>
            </IonItem>
          </IonList>
        ) : null}
        {chargeStatus === ChargeStatus.HOLD ? (
          <IonList className="payment-content">
            <IonItem lines="none">
              <h5 slot="start">Hold</h5>
              <h3 slot="end">{`$${amount}`}</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Provider</h5>
              <h3 slot="end">$0</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Refund</h5>
              <h3 slot="end">$0</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">CarePlatform</h5>
              <h3 slot="end">$0</h3>
            </IonItem>
            <IonItem lines="none">
              <h5 slot="start">Stripe Fee</h5>
              <h3 slot="end">{`$${stripeFee}`}</h3>
            </IonItem>
          </IonList>
        ) : null}
      </IonModal>
    </div>
  );
};

AdminBookingItem.propTypes = {
  booking: PropTypes.any.isRequired
};

export default AdminBookingItem;
