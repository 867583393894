import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
  IonAlert,
  IonLoading
} from '@ionic/react';
import moment from 'moment';

import Avatar from '../../components/Avatar';
import connect from '../../data/connect';
import { Booking, Rating, UserRole } from '../../models';
import { updateProviderRating, fetchBooking } from '../../data/dataApi';
import StarRating from '../../components/StarRating';

import './ProviderRating.scss';

interface MatchParams {
  id: string
}

type ProviderRatingProps = RouteComponentProps<MatchParams>;

const ProviderRating: React.FC<ProviderRatingProps> = ({
  match,
  history
}) => {
  const [ratingProvider, setRatingProvider] = useState(0);
  const [reviewProvider, setReviewProvider] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [booking, setBooking] = useState<Booking | null>(null);
  const [feedback, setFeedback] = useState<Rating | null>(null);

  const onUpdate = (): void => {
    if (!booking) {
      return;
    }
    setIsUpdating(true);
    updateProviderRating({
      bookingId: booking.id,
      rating: ratingProvider,
      review: reviewProvider
    }).then(() => {
      setShowSuccessAlert(true);
    }).finally(() => setIsUpdating(false));
  };

  useEffect(() => {
    const getData = (id: number) => {
      setIsLoading(true);
      fetchBooking(id)
        .then((res) => {
          if (res.success) {
            setBooking(res.booking as Booking);
            const fd = res.booking.feedbacks.find((item: Rating) => item.type === UserRole.PROVIDER);
            if (fd) {
              setFeedback(fd);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          history.goBack();
        })
        .finally(() => setIsLoading(false));
    };

    if (match.params.id) {
      getData(Number(match.params.id));
    }
  }, [match.params.id]);

  useEffect(() => {
    if (feedback) {
      setRatingProvider(feedback.rating);
      setReviewProvider(feedback.review);
    }
  }, [feedback]);

  if (!booking) {
    return <div>Invalid Booking</div>;
  }

  return (
    <IonContent id="booking-rating" className="page-content ion-padding">
      {isLoading || !booking ? <IonLoading isOpen={isLoading} /> : (
        <div className="booking-rating-body">
          <IonItem lines="none" className="profile-content">
            <Avatar user={booking.provider} size="lg" />
            <div className="profile-information">
              <div className="name">{`${booking.provider.name} ${booking.provider.lastName ?? ''}`}</div>
              <div>{booking.provider.title}</div>
              <div>{moment.utc(`${booking.date} ${booking.time}`).local().format('MMM DD, YYYY hh:mm A')}</div>
            </div>
          </IonItem>
          <IonItem lines="none">
            <IonLabel className="rating" position="stacked">Rating</IonLabel>
            <StarRating
              rating={ratingProvider}
              readonly={false}
              onChange={(value): void => setRatingProvider(value)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Review</IonLabel>
            <IonTextarea
              rows={4}
              placeholder="Leave feedback here.."
              value={reviewProvider}
              onIonChange={(evt): void => setReviewProvider(evt.detail.value || '')}
            />
          </IonItem>
          <IonButton color="favorite" expand="block" onClick={(): void => onUpdate()}>
            Update
          </IonButton>

          <IonLoading isOpen={isUpdating} />
          <IonAlert
            isOpen={showSuccessAlert}
            onDidDismiss={(): void => {
              setShowSuccessAlert(false);
              history.goBack();
            }}
            header="Care Platform"
            message={`Your review for booking with ${booking.provider.name} ${booking.provider.lastName ?? ''} was updated successfully.`}
            buttons={['Ok']}
          />
        </div>
      )}
    </IonContent>
  );
};

ProviderRating.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, {}, {}>({
  component: React.memo(ProviderRating)
});
