import React, { useEffect} from 'react';

import { IonIcon, IonFabButton } from '@ionic/react';
import { chatboxOutline } from 'ionicons/icons';

import useChatContext from '../../../../hooks/useChatContext/useChatContext';

export default function ToggleChatButton(props: { disabled?: boolean }) {
  const {
    isChatWindowOpen, setIsChatWindowOpen, conversation, hasUnreadMessages
  } = useChatContext();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
  };

  useEffect(() => {
    if (conversation && !isChatWindowOpen) {
      // const handleNewMessage = () => setShouldAnimate(true);
      // conversation.on('messageAdded', handleNewMessage);
      // return () => {
      //   conversation.off('messageAdded', handleNewMessage);
      // };
    }
  }, [conversation, isChatWindowOpen]);

  return (
    <IonFabButton
      onClick={toggleChatWindow}
      disabled={props.disabled}
      data-cy-audio-toggle
      color={isChatWindowOpen ? 'favorite' : undefined}
    >
      <IonIcon icon={chatboxOutline} />
    </IonFabButton>
  );
}
