import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import PropTypes from 'prop-types';

import connect from '../data/connect';

interface OwnProps extends RouteProps {
  path: string
  component: React.ComponentType<any>
}

interface StateProps {
  isAuthenticated: boolean
}

type ProtectedRouteProps = OwnProps & StateProps;

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAuthenticated, path, component: Component
}) => (
  <Route path={path} render={() => (isAuthenticated ? <Component /> : <Redirect to="/login" />)} />
);

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticated: !!state.auth.user.phone
  }),
  component: React.memo(ProtectedRoute)
});
