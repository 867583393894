import { Plugins } from '@capacitor/core';
import axios from 'axios';
import socketIOClient from 'socket.io-client';

import {
  BaseURL, SocketURL, StripePK, SOCKET_KEYS
} from './constants';

let jwt = '';
const requestHeaders = (): any => ({
  headers: {
    Accept: 'application/json,text/plain',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${jwt}`,
    'Cache-Control': 'no-cache'
  }
});

const { Storage } = Plugins;

const TOKEN = 'token';

export const socket = socketIOClient(SocketURL);
export const stripeClient = Stripe(StripePK);

export const fetchProviders = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/provider-list`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchProvidersToday = async (type: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/providers-today/${type}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchProvidersWithin3Days = async (type: string): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/providers-3days/${type}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchLanguages = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/languages`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchSpecialists = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/specialists`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchProviderTypes = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/properties/provider-types`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchSocialMedias = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/properties/social-media-types`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchCurrencies = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/properties/currencies`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchRoles = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/properties/roles`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchCategories = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/properties/categories`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchPreServices = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/pre-services`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchProviderTimeslots = async (
  providerId: number,
  locationId: number,
  date: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/available-timeslots`,
      { providerId, locationId, date },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchProviderProfile = async (provider: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/provider-profile/${provider}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateProviderProfile = async (
  id: number,
  data: any
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/provider-profile/${id}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadProfileImage = async (
  id: number,
  file: any
): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('attachments', file);
    formData.append('type', 'avatar');
    const response = await axios.post(`${BaseURL}/upload/${id}`, formData);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateClinicProfile = async (
  id: number,
  data: any
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/clinic-profile/${id}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateCustomerProfile = async (
  id: number,
  data: any
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/customer-profile/${id}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchFavoriteProviders = async (id: number): Promise<any> => {
  try {
    const response = await axios.get(`${BaseURL}/private/favorite-providers/${id}`, requestHeaders());
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addFavoriteProvider = async (id: number, providerId: number): Promise<any> => {
  try {
    const response = await axios.post(`${BaseURL}/private/favorite-provider/${id}`, {providerId} , requestHeaders());
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteFavoriteProvider = async (id: number, providerId: number): Promise<any> => {
  try {
    const response = await axios.delete(`${BaseURL}/private/favorite-provider/${id}`, {...requestHeaders(), data : {providerId}});
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchBookings = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/bookings`,
      { ...data },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchBookingsByUser = async (data: any): Promise<any> => {
  try {
    const { userId, ...payload } = data;
    const response = await axios.post(
      `${BaseURL}/private/bookings/${userId as number}`,
      payload,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchBooking = async (bookingId: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/booking/${bookingId}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createBooking = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/booking`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const extendBooking = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/extend-call`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateBooking = async (
  id: number,
  status: string,
  reason?: string
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/booking-${status}/${id}`,
      { reason: reason || '' },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getReadyAppointment = async (id: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/ready-appointment/${id}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const startMeeting = async (
  bookingId: number,
  isPartnerArrived: boolean
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/meeting-start/${bookingId}`,
      { isPartnerArrived },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchCharges = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/charges`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createTransfer = async (amount: number): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/transfer`,
      { amount },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTimeslots = async (id: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/timeslots-day/${id}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTimeslotsByDate = async (
  providerId: number,
  locationId: number,
  date: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/timeslots-date`,
      { providerId, locationId, date },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkTimeSlots = async (
  providerId: number,
  locationId: number,
  service: string,
  date: string,
  type: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/check-timeslots`,
      {
        providerId, locationId, date, service, type
      },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const enableTimeslot = async (
  id: number,
  date: string,
  time: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/enable-timeslot`,
      { id, date, time },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createTimeslot = async (slot: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/timeslot`,
      slot,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteTimeslot = async (id: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BaseURL}/private/timeslot/${id}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateTimeslot = async (id: number, data: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/timeslot/${id}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createStripeCard = async (token: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/stripe-card`,
      { token },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateCreditCard = async (id: number, data: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/stripe-card/${id}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteStripeCard = async (id: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BaseURL}/private/stripe-card/${id}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeDefaultCard = async (card: number): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/default-card`,
      { card },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createProviderBankAccount = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/provider-bank-account`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteProviderBankAccount = async (id: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BaseURL}/private/bank-account/${id}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeDefaultBankAccount = async (bank: number): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/default-bank-account`,
      { bank },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createClinicBankAccount = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/clinic-bank-account`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchAccountVerificationLink = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/bank-account-verification-link`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchProviderRatings = async (
  providerId: number
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/ratings-provider`,
      { providerId },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchClinicRatings = async (clinicId: number): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/ratings-clinic`,
      { clinicId },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateProviderRating = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/rate-provider`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateClinicRating = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/rate-clinic`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateCustomerRating = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/rate-customer`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllNotification = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/notification`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateNotification = async (
  id: number,
  data: any
): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/notification/${id}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteNotification = async (id: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BaseURL}/private/notification/${id}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const readAllNotification = async (): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/notification-all`,
      {},
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAllNotification = async (): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BaseURL}/private/notification-all`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createClinicMember = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/clinic-member`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchClinicMembers = async (clinic: number, data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/clinic-members/${clinic}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const login = async (params: any): Promise<any> => {
  try {
    const timeOffset = new Date().getTimezoneOffset();
    const response = await axios.post(`${BaseURL}/public/login`, { ...params, timeOffset });
    if (response.data.current) {
      socket.emit(SOCKET_KEYS.greeting, response.data.current.id);
    }
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const validate = async (): Promise<any> => {
  const { value: token } = await Storage.get({ key: TOKEN });
  jwt = token || '';
  if (token) {
    try {
      const response = await axios.post(`${BaseURL}/public/validate`, { token });
      if (response.data.current) {
        socket.emit(SOCKET_KEYS.greeting, response.data.current.id);
      }
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }
  return Promise.reject();
};

export const register = async (params: any): Promise<any> => {
  try {
    const timeOffset = new Date().getTimezoneOffset();
    const response = await axios.post(`${BaseURL}/public/register`, { ...params, timeOffset });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verify = async (phone: string, code: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/public/verify`,
      { phone, code },
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resend = async (): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/request`,
      {},
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchUser = async (id: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/user/${id}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchUsers = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/users`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateUser = async (id: number, data: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/user/${id}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getVideoToken = async (room: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/video-token`,
      { room },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const joinCall = async (id: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/join-call/${id}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSyncToken = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/sync-token`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetPassword = async (id: number, data: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/reset-password/${id}`,
      data,
      requestHeaders()
    );
    if (response.data) {
      socket.emit(SOCKET_KEYS['reset-password'], id);
    }
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const forgetPassword = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(`${BaseURL}/public/forget-password`, data);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const resetForgetPassword = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/public/reset-forget-password`,
      data
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const setToken = async (token?: string): Promise<any> => {
  jwt = token || '';
  if (!token) {
    await Storage.remove({ key: TOKEN });
  } else {
    await Storage.set({ key: TOKEN, value: token });
  }
};

export const getRegisterJson = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/public/registrationJson`,
      requestHeaders()
    );

    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registerConsent = async (params: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/user/signature`,
      params,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getImage = (file: string | undefined): string => (file ? `${BaseURL}/upload/${file}` : '/assets/img/user.jpg');

export const sendTranscript = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/send-transcript`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadTranscript = async (role: string, id: number): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/utility/upload-transcript/${role}/${id}`,
      {}
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getRecords = async (bookingId: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/records/${bookingId}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadRecording = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/upload-recording`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const leaveConversation = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/leave-conversation`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadLicense = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/upload-license`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadIdentity = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/upload-identity`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchBookingsByDate = async (providerId: number, date: string): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/date-bookings`,
      { providerId, date },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchProvider = async (providerId: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/provider-profile/${providerId}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkIdentity = async (identityId: number, reason: string): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/check-identity/${identityId}`,
      { reason },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkLicense = async (licenseId: number, reason: string): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/check-license/${licenseId}`,
      { reason },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchRequests = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/requests`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchCustomers = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/customers`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const inactivateAccount = async (userId: number, isDelete: boolean): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/inactivate-account/${userId}`,
      { isDelete },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const activateAccount = async (userId: number): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/activate-account/${userId}`,
      {},
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const activateStripeAccount = async (userId: number): Promise<any> => {
  try {
    await axios.post(`${BaseURL}/utility/activate-bank-account/${userId}`);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTermsAndConditions = async (): Promise<any> => {
  try {
    const response = await axios.get(`${BaseURL}/utility/terms-and-conditions`);
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSignedUrl = async (fileName: string): Promise<any> => {
  try {
    const response = await axios.post(`${BaseURL}/utility/get-signedurl`, { fileName });
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchClinics = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/clinic-list`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchClinicInfo = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/clinic-info`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addNewUser = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/add-new`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const signInAsUser = async (userId: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/signin-as-user/${userId}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const signOut = async (): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/sign-out`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// campaigns apis
export const fetchCampaigns = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/campaigns`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addCampaign = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/campaign`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateCampaign = async (campaignId: number, data?: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/campaign/${campaignId}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteCampaign = async (campaignId: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BaseURL}/private/campaign/${campaignId}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addCampainDescription = async (data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/campaign/description`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// mailingList apis
export const fetchMailingLists = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/mailing-lists`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addMailingList = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/mailing-list`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateMailingList = async (mailingListId: number, data?: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/mailing-list/${mailingListId}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteMailingList = async (mailingListId: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BaseURL}/private/mailing-list/${mailingListId}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const processMailingList = async (mailingListId: number, data: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/mailing-list/${mailingListId}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const processMailingListToBulk = async (
  mailingListId: number, data: any
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/mailing-list/${mailingListId}/bulk`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// template apis
export const fetchTemplates = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/templates`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addTemplate = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/template`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateTemplate = async (templateId: number, data?: any): Promise<any> => {
  try {
    const response = await axios.put(
      `${BaseURL}/private/template/${templateId}`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteTemplate = async (templateId: number): Promise<any> => {
  try {
    const response = await axios.delete(
      `${BaseURL}/private/template/${templateId}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// location apis
export const deleteLocation = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/location/delete`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// subscription apis
export const subscribe = async (data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/subscribe`,
      data,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateSubscriptionStatus = async (
  subscriptionId: string, status: string
): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/subscription/${subscriptionId}`,
      { status },
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSubscriptionHistory = async (userId: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/subscription-history/${userId}`,
      requestHeaders()
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// Export as PDF
export const exportBookingReceipt = async (bookingId: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/pdf/booking-receipt/${bookingId}`,
      { ...requestHeaders(), responseType: 'arraybuffer' }
    );
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    return Promise.resolve(pdfUrl);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const exportUserReport = async (userId: number, data?: any): Promise<any> => {
  try {
    const response = await axios.post(
      `${BaseURL}/private/pdf/user-report/${userId}`,
      data,
      { ...requestHeaders(), responseType: 'arraybuffer' }
    );
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    return Promise.resolve(pdfUrl);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const exportMailingListReport = async (mailingListId: number): Promise<any> => {
  try {
    const response = await axios.get(
      `${BaseURL}/private/pdf/mailinglist-report/${mailingListId}`,
      { ...requestHeaders(), responseType: 'arraybuffer' }
    );
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    return Promise.resolve(pdfUrl);
  } catch (error) {
    return Promise.reject(error);
  }
};
