import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const AlarmIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M19.6732 14.1779L17.3398 5.76119C16.871 4.06809 15.849 2.58058 14.4368 1.53561C13.0245 0.49063 11.3032 -0.0517125 9.547 -0.00503257C7.7908 0.0416473 6.10072 0.674664 4.74598 1.79319C3.39124 2.91171 2.44976 4.45141 2.07149 6.16702L0.267323 14.2837C0.199686 14.5882 0.201314 14.9041 0.272087 15.208C0.342861 15.5118 0.480973 15.7959 0.676227 16.0392C0.871481 16.2825 1.11889 16.4789 1.40019 16.6138C1.6815 16.7487 1.98951 16.8187 2.30149 16.8187H5.75399C6.01918 17.7358 6.57519 18.5419 7.33826 19.1156C8.10134 19.6892 9.03016 19.9994 9.98482 19.9994C10.9395 19.9994 11.8683 19.6892 12.6314 19.1156C13.3945 18.5419 13.9505 17.7358 14.2157 16.8187H17.6665C17.9877 16.8187 18.3045 16.7445 18.5922 16.6018C18.88 16.4591 19.1308 16.2518 19.3252 15.9961C19.5195 15.7404 19.6521 15.4433 19.7127 15.1279C19.7732 14.8125 19.7599 14.4873 19.674 14.1779H19.6732ZM2.82066 14.3187L4.51232 6.70452C4.77142 5.53458 5.41444 4.48494 6.33903 3.72268C7.26361 2.96042 8.41662 2.52936 9.6145 2.49812C10.8124 2.46688 11.9863 2.83725 12.9493 3.55028C13.9124 4.26331 14.6093 5.27801 14.929 6.43285L17.119 14.3187H2.82066Z" fill={color} />
  </svg>
);

export default AlarmIcon;
