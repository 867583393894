import { LocalVideoTrack } from 'twilio-video';
import { useCallback, useEffect, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useLocalVideoToggle() {
  const [hasCamera, setHasCamera] = useState(false);

  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack
  } = useVideoContext();
  const videoTrack = localTracks.find(
    (track) => track.name === 'camera'
  ) as LocalVideoTrack;

  const toggleVideoEnabled = useCallback(() => {
    if (videoTrack) {
      if (localParticipant) {
        const localTrackPublication = localParticipant.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant.emit('trackUnpublished', localTrackPublication);
      }
      videoTrack.stop();
    } else {
      getLocalVideoTrack().then((track: LocalVideoTrack) => {
        if (localParticipant) {
          localParticipant.publishTrack(track);
        }
      });
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack]);

  useEffect(() => {
    const checkCameraDevice = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      setHasCamera(!!devices.find((dv) => dv.deviceId && dv.kind === 'videoinput'));
    };

    checkCameraDevice();
  }, [navigator]);

  return [hasCamera, !!videoTrack, toggleVideoEnabled] as const;
}
