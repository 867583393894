import React from 'react';
import { IonIcon, IonFabButton } from '@ionic/react';
import { videocam } from 'ionicons/icons';

import useLocalVideoToggle from '../../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { CameraOffIcon } from '../../../../icons';

export default function ToggleVideoButton(props: { disabled?: boolean }) {
  const [hasCamera, isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();

  return (
    <IonFabButton onClick={toggleVideoEnabled} disabled={props.disabled || !hasCamera}>
      {isVideoEnabled ? (
        <IonIcon icon={videocam} />
      ) : (
        <CameraOffIcon color="#FFF" />
      )}
    </IonFabButton>
  );
}
