import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const formatToPhone = (phoneNumber: string) => {
  const input = phoneNumber.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    return `(${areaCode}) ${middle}-${last}`;
  }
  if (input.length > 3) {
    return `(${areaCode}) ${middle}`;
  }
  if (input.length > 0) {
    return `(${areaCode}`;
  }

  return null;
};

const getDigits = (value: string) => {
  if (value) {
    return value?.replace(/\D/g, '');
  }
  return '';
};

interface PhonenumberInputProps {
  isRequired?: boolean
  value: string
  onChange: (value: string) => void
}

const PhonenumberInput: React.FC<PhonenumberInputProps> = ({
  isRequired, value, onChange
}) => {
  const [number, setNumber] = useState('');

  useEffect(() => {
    setNumber(value ?? '');
  }, [value]);

  return (
    <input
      name="phonenumber"
      placeholder={isRequired ? '* Phone Number' : 'Phone Number'}
      value={formatToPhone(number) || ''}
      onChange={(e) => {
        if (getDigits(e.target.value).length === 0) {
          setNumber('');
        } else if (getDigits(e.target.value).length < 11) {
          setNumber(e.target.value);
          onChange(getDigits(e.target.value));
        }
      }}
    />
  );
};

PhonenumberInput.defaultProps = {
  isRequired: false
};

PhonenumberInput.propTypes = {
  isRequired: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PhonenumberInput;
