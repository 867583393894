import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonListHeader,
  IonLoading,
  IonRippleEffect,
  IonRow,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import { calendarOutline, ellipsisHorizontal, removeOutline } from 'ionicons/icons';
import { DateRange as DateRangePicker, Range } from 'react-date-range';
import { Country, State, City } from 'country-state-city';
import { Doughnut } from 'react-chartjs-2';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import moment from 'moment';
import clsx from 'clsx';

import connect from '../../data/connect';
import {
  fetchBookings,
  fetchClinicInfo
} from '../../data/dataApi';
import {
  Booking, BookingStatus, ChargeStatus, ProviderType, Specialist
} from '../../models';
import Avatar from '../../components/Avatar';
import { DetailsIcon } from '../../icons';

import cashImg from '../../assets/cash.png';
import checkImg from '../../assets/check.png';
import closeImg from '../../assets/close.png';
import memoImg from '../../assets/memo.png';
import './AdminEarnings.scss';

interface BookingActionsProps {
  booking: Booking
}

const BookingActions: React.FC<BookingActionsProps> = ({ booking }) => {
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onShowDetails = () => {
    history.push(`/admin/report/booking/${booking.id}`);
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'top', 'left', 'right']}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="#daf3ef"
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className="popover-content">
            <div className="option" onClick={onShowDetails}>
              <IonLabel>
                <DetailsIcon />
                Details
              </IonLabel>
            </div>
          </div>
        </ArrowContainer>
      )}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      <span
        className="action"
        title="More actions"
        onClick={(e) => {
          e.stopPropagation();
          setIsPopoverOpen(!isPopoverOpen);
        }}
      >
        <IonIcon icon={ellipsisHorizontal} />
      </span>
    </Popover>
  );
};

BookingActions.propTypes = {
  booking: PropTypes.any.isRequired
};

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface StateProps {
  providerTypes: ProviderType[]
  specialists: Specialist[]
}

const AdminEarnings: React.FC<StateProps> = ({
  providerTypes, specialists
}) => {
  const history = useHistory();
  const [dateRange, setDateRange] = useState<Range | null>(null);
  const [tempDateRange, setTempDateRange] = useState<Range | null>(null);
  const [showCalendarPopover, setShowCalendarPopover] = useState(false);
  const [country, setCountry] = useState('all');
  const [state, setState] = useState('all');
  const [city, setCity] = useState('all');
  const [selectedProviderTypes, setSelectedProviderTypes] = useState<number[]>([]);
  const [selectedSpecialists, setSelectedSpecialists] = useState([] as number[]);
  const [showBookings, setShowBookings] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [clinicInfo, setClinicInfo] = useState({
    activeCnt: 0,
    pendingCnt: 0,
    withdrawnCnt: 0
  });
  const [sumInfo, setSumInfo] = useState({
    totalFee: 0,
    totalCpFee: 0,
    totalProviderFee: 0,
    totalClinicFee: 0,
    totalStripeFee: 0,
    totalChargedAmount: 0
  });
  const [bookingsInfo, setBookingsInfo] = useState({
    total: 0,
    completed: 0,
    accepted: 0,
    cancelled: 0,
    incomplete: 0,
    pending: 0
  });

  const selectPeriod = (type: string) => {
    if (type === 'today') {
      setTempDateRange({
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        key: 'selection'
      });
    }
    if (type === 'week') {
      setTempDateRange({
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastMonth') {
      setTempDateRange({
        startDate: moment().subtract(1, 'months').startOf('month').toDate(),
        endDate: moment().subtract(1, 'months').endOf('month').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastQuarter') {
      setTempDateRange({
        startDate: moment().subtract(3, 'months').startOf('quarter').toDate(),
        endDate: moment().subtract(3, 'months').endOf('quarter').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastSixMonths') {
      setTempDateRange({
        startDate: moment().subtract(7, 'months').startOf('month').toDate(),
        endDate: moment().subtract(1, 'months').endOf('month').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastYear') {
      setTempDateRange({
        startDate: moment().subtract(1, 'years').startOf('year').toDate(),
        endDate: moment().subtract(1, 'years').endOf('year').toDate(),
        key: 'selection'
      });
    }
  };

  const onShowBookings = () => {
    setShowBookings(true);
  };

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const onClickProviderType = (checked: boolean, id: number) => {
    if (checked && !selectedProviderTypes.includes(id)) {
      setSelectedProviderTypes([...selectedProviderTypes, id]);
      const addedSpecialistsIds = specialists.filter((sp) => sp.type === id).map((sp) => sp.id);
      setSelectedSpecialists([...selectedSpecialists, ...addedSpecialistsIds]);
    } else if (!checked && selectedProviderTypes.includes(id)) {
      const index = selectedProviderTypes.indexOf(id);
      const updatedProviderTypes = selectedProviderTypes;
      updatedProviderTypes.splice(index, 1);
      setSelectedProviderTypes([...updatedProviderTypes]);
      const updatedSpecialists = selectedSpecialists
        .filter((spId) => specialists.find((sp) => sp.id === spId)?.type !== id);
      setSelectedSpecialists([...updatedSpecialists]);
    }
  };

  const onClickSpecialist = (id: number): void => {
    if (selectedSpecialists.includes(id)) {
      setSelectedSpecialists(
        selectedSpecialists.filter((spec) => spec !== id)
      );
    } else {
      setSelectedSpecialists([...selectedSpecialists, id]);
    }
  };

  const fetchMoreBookings = (isNew: boolean) => {
    const params = {
      skip: isNew ? 0 : bookings.length,
      providerTypes: selectedProviderTypes,
      specialists: selectedSpecialists,
      dateRange: dateRange ? {
        start: moment(dateRange.startDate).toISOString(),
        end: moment(dateRange.endDate).toISOString()
      } : null,
      country: country === 'all' ? null : country,
      state: state === 'all' ? null : state,
      city: city === 'all' ? null : city,
      searchKey: '',
      hasRevenueInfo: true
    };

    setIsFetching(true);
    fetchBookings(params)
      .then((res) => {
        if (res.success) {
          if (isNew) {
            setIsFetchedAll(false);
            setBookings([...res.bookings]);
            setSumInfo({ ...res.sum });
            setBookingsInfo({...res.counts});
          } else {
            setBookings([...bookings, ...res.bookings]);
            setSumInfo({ ...res.sum });
            setBookingsInfo({...res.counts});
          }
          if (!res.bookings.length || res.bookings.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsFetching(false));
  };

  const availableStates = useMemo(() => {
    if (country) {
      const currentCuntry = Country.getAllCountries().find((c) => c.name === country);
      const states = State.getStatesOfCountry(currentCuntry?.isoCode);
      return states || [];
    }
    return [];
  }, [country]);

  const availableCities = useMemo(() => {
    if (country && state) {
      const currentCuntry = Country.getAllCountries().find((c) => c.name === country);
      const currentState = State.getStatesOfCountry(currentCuntry?.isoCode).find((s) => s.name === state);
      if (currentCuntry && currentState) {
        const cities = City.getCitiesOfState(currentCuntry.isoCode, currentState.isoCode);
        return cities;
      }
    }
    return [];
  }, [country, state]);

  useEffect(() => {
    const temp: number[] = [];
    providerTypes.map((pt) => {
      temp.push(pt.id);
    });
    setSelectedProviderTypes([...temp]);
  }, [providerTypes]);

  useEffect(() => {
    const temp: number[] = [];
    specialists.map((spec) => {
      temp.push(spec.id);
    });
    setSelectedSpecialists([...temp]);
  }, [specialists]);

  useEffect(() => {
    fetchMoreBookings(true);
  }, [selectedSpecialists, selectedProviderTypes, country, state, city, dateRange]);

  useEffect(() => {
    setIsFetching(true);
    fetchClinicInfo()
      .then((res) => {
        if (res.success) {
          setClinicInfo(res.result);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsFetching(false));
  }, []);

  return (
    <IonContent id="admin-earnings" className="page-content ion-padding">
      {showBookings ? (
        <div className="admin-earnings-header bookings">
          <IonListHeader>Bookings</IonListHeader>
          <span className="back-btn" onClick={() => setShowBookings(false)}>
            Back to Earnings
          </span>
        </div>
      ) : (
        <div className="admin-earnings-header">
          <IonListHeader>Earnings</IonListHeader>
        </div>
      )}
      {showBookings ? null : (
        <div className="admin-earnings-filter">
          <IonItem lines="none" className="select">
            <IonSelect
              className="select"
              placeholder="Select Country"
              value={country}
              onIonChange={(e) => setCountry(e.detail.value)}
            >
              <IonSelectOption value="all">All countries</IonSelectOption>
              <IonSelectOption value="United States">United States</IonSelectOption>
              <IonSelectOption value="Canada">Canada</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem lines="none" className="select">
            <IonSelect
              className="select"
              placeholder="Select Province"
              value={state}
              onIonChange={(e) => setState(e.detail.value)}
            >
              <IonSelectOption value="all">All provinces</IonSelectOption>
              {availableStates.map((st) => (
                <IonSelectOption key={st.name} value={st.name}>{st.name}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <IonItem lines="none" className="select">
            <IonSelect
              className="select"
              placeholder="Select City"
              value={city}
              onIonChange={(e) => setCity(e.detail.value)}
            >
              <IonSelectOption value="all">All cities</IonSelectOption>
              {availableCities.map((ct) => (
                <IonSelectOption key={ct.name} value={ct.name}>{ct.name}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
          <Popover
            isOpen={showCalendarPopover}
            positions={['bottom', 'top', 'left', 'right']}
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor="#daf3ef"
                arrowSize={10}
                className="popover-arrow-container"
                arrowClassName="popover-arrow"
              >
                <div className="popover-content calendar">
                  <div className="panel">
                    <div
                      className={clsx('option', { selected: !tempDateRange })}
                      onClick={() => setTempDateRange(null)}
                    >
                      All periods
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment(tempDateRange.endDate).format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('today')}
                    >
                      Today
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().startOf('week').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().endOf('week').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('week')}
                    >
                      This week
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('lastMonth')}
                    >
                      Last month
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').startOf('quarter').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').endOf('quarter').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('lastQuarter')}
                    >
                      Last quarter
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(7, 'months').startOf('month').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('lastSixMonths')}
                    >
                      Last 6 months
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('lastYear')}
                    >
                      Last year
                    </div>
                    <IonButton
                      color="favorite"
                      onClick={() => {
                        setDateRange(tempDateRange);
                        setShowCalendarPopover(false);
                      }}
                    >
                      Apply
                    </IonButton>
                  </div>
                  <div className="calendar">
                    <DateRangePicker
                      editableDateInputs
                      onChange={(item) => {
                        setTempDateRange({
                          startDate: moment(item.selection.startDate).toDate(),
                          endDate: moment(item.selection.endDate).add(1, 'days').subtract(1, 'milliseconds').toDate(),
                          key: 'selection'
                        });
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={tempDateRange ? [tempDateRange] : [
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: 'selection'
                        },
                      ]}
                      rangeColors={['#000']}
                    />
                  </div>
                </div>
              </ArrowContainer>
            )}
            onClickOutside={() => setShowCalendarPopover(false)}
          >
            <IonItem lines="none" className="date-range" onClick={() => setShowCalendarPopover(true)}>
              <div>
                {dateRange ? moment(dateRange.startDate).format('DD MMMM, YYYY') : 'Start Date'}
              </div>
              <IonIcon color="dark" icon={removeOutline} />
              <div>
                {dateRange ? moment(dateRange.endDate).format('DD MMMM, YYYY') : 'End Date'}
              </div>
              <IonIcon slot="end" icon={calendarOutline} />
            </IonItem>
          </Popover>
        </div>
      )}
      <div className={clsx('admin-earnings-summary', { hidden: showBookings })}>
        <IonGrid>
          <IonRow>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container cash">
                  <img src={cashImg} alt="cash" />
                </div>
                <h2>{`$${sumInfo.totalFee ? Number(sumInfo.totalFee.toFixed(0)) / 100 : 0}`}</h2>
                <p>Total Revenue</p>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container cash">
                  <img src={cashImg} alt="cash" />
                </div>
                <h2>{`$${sumInfo.totalChargedAmount ? Number(sumInfo.totalChargedAmount.toFixed(0)) / 100 : 0}`}</h2>
                <p>Total Charged Amount</p>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container cash">
                  <img src={cashImg} alt="cash" />
                </div>
                <h2>{`$${sumInfo.totalCpFee ? Number(sumInfo.totalCpFee?.toFixed(0)) / 100 : 0}`}</h2>
                <p>CarePlatform Net Revenue</p>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container total">
                  <img src={cashImg} alt="total" />
                </div>
                <h2>{`$${sumInfo.totalProviderFee ? Number(sumInfo.totalProviderFee?.toFixed(0)) / 100 : 0}`}</h2>
                <p>Provider Net Revenue</p>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container completed">
                  <img src={cashImg} alt="completed" />
                </div>
                <h2>{`$${sumInfo.totalClinicFee ? Number(sumInfo.totalClinicFee?.toFixed(0)) / 100 : 0}`}</h2>
                <p>Clinic Net Revenue</p>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container cancelled">
                  <img src={cashImg} alt="cancelled" />
                </div>
                <h2>{`$${sumInfo.totalStripeFee ? Number(sumInfo.totalStripeFee?.toFixed(0)) / 100 : 0}`}</h2>
                <p>Total Stripe Fee</p>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container total">
                  <img src={memoImg} alt="total" />
                </div>
                <h2>{bookingsInfo.total}</h2>
                <p>Total Bookings</p>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container total">
                  <img src={memoImg} alt="total" />
                </div>
                <h2>{bookingsInfo.pending}</h2>
                <p>Pending Bookings</p>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container total">
                  <img src={memoImg} alt="total" />
                </div>
                <h2>{bookingsInfo.accepted}</h2>
                <p>Accepted Bookings</p>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container total">
                  <img src={checkImg} alt="total" />
                </div>
                <h2>{bookingsInfo.completed}</h2>
                <p>Completed Bookings</p>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container cancelled">
                  <img src={closeImg} alt="completed" />
                </div>
                <h2>{bookingsInfo.cancelled}</h2>
                <p>Cancelled Bookings</p>
              </div>
            </IonCol>
            <IonCol size="2">
              <div className="admin-earnings-summary-option">
                <div className="image-container cancelled">
                  <img src={closeImg} alt="cancelled" />
                </div>
                <h2>{bookingsInfo.incomplete}</h2>
                <p>Incomplete Bookings</p>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>

      <div className={clsx('admin-earnings-summary', { hidden: showBookings })}>
        <IonGrid>
          <IonRow>
            <IonCol size="7">
              <div className="specialists-container">
                <div className="specialists-content">
                  <div className="admin-earnings-summary-option lg">
                    <div className="sub-header">
                      Provider Services
                    </div>
                    <div className="specialists-view">
                      {providerTypes.map((pt) => (
                        <div className="specialists-part">
                          <div className="provider-detail">
                            <IonCheckbox
                              slot="start"
                              checked={selectedProviderTypes.includes(pt.id)}
                              onIonChange={(e) => onClickProviderType(e.detail.checked, pt.id)}
                            />
                            <IonLabel>{pt.name}</IonLabel>
                          </div>
                          <div className="specialists-list">
                            {specialists
                              .filter((spec) => spec.type === pt.id)
                              .map((sp, index) => (
                                <div
                                  key={index}
                                  className={clsx('ion-activatable toggle', {
                                    active: selectedSpecialists.includes(sp.id),
                                    disabled: !selectedProviderTypes.includes(sp.type)
                                  })}
                                  onClick={(): void => onClickSpecialist(sp.id)}
                                >
                                  {sp.name}
                                  <IonRippleEffect />
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </IonCol>
            <IonCol size="5">
              <div className="admin-earnings-summary-option lg">
                <div className="sub-header">
                  Clinics
                  <span className="action" onClick={() => history.push('/admin/clinic-members')}>
                    View details
                  </span>
                </div>
                <div className="clinics-chart">
                  <Doughnut
                    data={{
                      labels: ['Active Clinics', 'Pending Clinics', 'Withdrawn Clinics'],
                      datasets: [
                        {
                          data: [
                            clinicInfo.activeCnt, clinicInfo.pendingCnt, clinicInfo.withdrawnCnt
                          ],
                          backgroundColor: [
                            '#16A34E',
                            '#F96A28',
                            '#BD2908'
                          ],
                          borderColor: '#FFF',
                          borderWidth: 2
                        },
                      ],
                    }}
                    options={{
                      legend: {
                        display: false
                      },
                      cutoutPercentage: 70
                    }}
                  />
                </div>
                <div className="clinics-infos">
                  <div>
                    <span className="title">
                      <span className="status active" />
                      <span>Active Clinics</span>
                    </span>
                    <span className="value">{clinicInfo.activeCnt}</span>
                  </div>
                  <div>
                    <span className="title">
                      <span className="status pending" />
                      <span>Pending Clinics</span>
                    </span>
                    <span className="value">{clinicInfo.pendingCnt}</span>
                  </div>
                  <div>
                    <span className="title">
                      <span className="status withdrawn" />
                      <span>Withdrawn Clinics</span>
                    </span>
                    <span className="value">{clinicInfo.withdrawnCnt}</span>
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonButton className="show-btn" color="favorite" onClick={onShowBookings}>
          Show bookings
        </IonButton>
      </div>

      <div className={clsx('earnings-booking-list', { hidden: !showBookings })}>
        <IonGrid>
          <IonRow className="table-header">
            <IonCol className="sm">
              NO
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer sm', { isOrderBy: orderBy === 'id' })}
              onClick={() => onChangeOrderBy('id')}
            >
              B.ID
              {orderBy === 'id' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              size="2.25"
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'customer' })}
              onClick={() => onChangeOrderBy('customer')}
            >
              CUS. NAME AND EMAIL
              {orderBy === 'customer' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>
              PRO.TYPE AND SERVICE
            </IonCol>
            <IonCol
              size="2.25"
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'provider' })}
              onClick={() => onChangeOrderBy('provider')}
            >
              PRO. NAME AND EMAIL
              {orderBy === 'provider' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              size="1"
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'date' })}
              onClick={() => onChangeOrderBy('date')}
            >
              DATETIME&nbsp;
              {orderBy === 'date' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>
              STATUS
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'cost' })}
              onClick={() => onChangeOrderBy('cost')}
            >
              COST
              {orderBy === 'cost' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'providerFee' })}
              onClick={() => onChangeOrderBy('providerFee')}
            >
              PRO. SPLIT
              {orderBy === 'providerFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'cpFee' })}
              onClick={() => onChangeOrderBy('cpFee')}
            >
              C.P SPLIT
              {orderBy === 'cpFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'stripeFee' })}
              onClick={() => onChangeOrderBy('stripeFee')}
            >
              STRIPE FEES
              {orderBy === 'stripeFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>
              PAY. STATUS
            </IonCol>
            <IonCol>
              ACTIONS
            </IonCol>
          </IonRow>
          {bookings.map((booking, index) => (
            <IonRow key={booking.id}>
              <IonCol className="sm">
                {index + 1}
              </IonCol>
              <IonCol className="sm">
                {booking.id}
              </IonCol>
              <IonCol size="2.25">
                <div className="user-name-email">
                  <Avatar user={booking.customer} />
                  <div>
                    <h4>{`${booking.customer.name} ${booking.customer.lastName ?? ''}`}</h4>
                    <p>{booking.customer.email}</p>
                  </div>
                </div>
              </IonCol>
              <IonCol>
                <div className="type-service">
                  <span>
                    {providerTypes.find((pt) => pt.id === booking.provider.type)?.name}
                  </span>
                  <span>
                    {booking.service}
                  </span>
                </div>
              </IonCol>
              <IonCol size="2.25">
                <div className="user-name-email">
                  <Avatar user={booking.provider} />
                  <div>
                    <h4>{`${booking.provider.name} ${booking.provider.lastName ?? ''}`}</h4>
                    <p>{booking.provider.email}</p>
                  </div>
                </div>
              </IonCol>
              <IonCol size="1">
                {moment.utc(`${booking.date} ${booking.time}`).local().format('DD MMMM, YYYY hh:mm A')}
              </IonCol>
              <IonCol>
                {booking.status === BookingStatus.PENDING ? (
                  <div className="status pending">Pending</div>
                ) : null}
                {booking.status === BookingStatus.REJECTED_PROVIDER ? (
                  <div className="status rejected">Rejected</div>
                ) : null}
                {booking.status === BookingStatus.REJECTED_CUSTOMER ? (
                  <div className="status abandoned">Abandoned</div>
                ) : null}
                {booking.status === BookingStatus.CANCELLED_CUSTOMER || booking.status === BookingStatus.CANCELLED_PROVIDER ? (
                  <div className="status cancelled">Cancelled</div>
                ) : null}
                {booking.status === BookingStatus.ACCEPTED ? (
                  <div className="status accepted">Accepted</div>
                ) : null}
                {booking.status === BookingStatus.COMPLETED ? (
                  <div className="status completed">Completed</div>
                ) : null}
                {booking.status === BookingStatus.INCOMPLETE ? (
                  <div className="status incomplete">Incomplete</div>
                ) : null}
              </IonCol>
              <IonCol>
                {`$${booking.charge.amount}`}
              </IonCol>
              <IonCol>
                {booking.charge.providerFee >= 0 ? `$${booking.charge.providerFee}` : `-$${-1 * booking.charge.providerFee}`}
              </IonCol>
              <IonCol>
                {booking.charge.cpFee >= 0 ? `$${booking.charge.cpFee}` : `-$${-1 * booking.charge.cpFee}`}
              </IonCol>
              <IonCol>
                {`$${booking.charge.stripeFee}`}
              </IonCol>
              <IonCol>
                {booking.charge.status === ChargeStatus.CHARGED ? (
                  <div className="status charged">Charged</div>
                ) : null}
                {booking.charge.status === ChargeStatus.REFUNDED ? (
                  <div className="status refunded">Refunded</div>
                ) : null}
                {booking.charge.status === ChargeStatus.HOLD ? (
                  <div className="status hold">Hold</div>
                ) : null}
                {booking.charge.status === ChargeStatus.RELEASED ? (
                  <div className="status refunded">Released</div>
                ) : null}
              </IonCol>
              <IonCol>
                <BookingActions booking={booking} />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
        {isFetchedAll ? null : (
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              fetchMoreBookings(false);
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent
              loadingText="Fetching more..."
              loadingSpinner="circles"
            />
          </IonInfiniteScroll>
        )}
      </div>

      <IonLoading isOpen={!showBookings && isFetching} />
    </IonContent>
  );
};

AdminEarnings.propTypes = {
  providerTypes: PropTypes.array.isRequired,
  specialists: PropTypes.array.isRequired
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes,
    specialists: state.data.allSpecialists
  }),
  component: React.memo(AdminEarnings)
});
