/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonLoading,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea,
} from '@ionic/react';
import {
  closeOutline, ellipsisHorizontal, options, searchOutline, mailOutline, arrowBackOutline
} from 'ionicons/icons';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import { Country, State, City } from 'country-state-city';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import AsyncSelect from 'react-select/async';
import { StylesConfig } from 'react-select';
import { Column } from 'react-table';
import clsx from 'clsx';
import moment from 'moment';

import connect from '../../data/connect';
import {
  fetchMailingLists,
  addMailingList,
  updateMailingList,
  deleteMailingList,
  processMailingList,
  processMailingListToBulk,
  fetchUsers,
  fetchTemplates,
  exportMailingListReport
} from '../../data/dataApi';
import {
  MailingListStatus, MailingList, ProviderType, Role, User, Template
} from '../../models';
import Table from '../../components/Table';
import Avatar from '../../components/Avatar';
import { DetailsIcon, TrashIcon } from '../../icons';

interface ActionsProps {
  isIndividual?: boolean
  user?: User | null
  mailingList: MailingList
  onSelect: () => void
  removeMailingList: (id: number) => void
  sentMailingList: (id: number) => void
}

const Actions: React.FC<ActionsProps> = ({
  isIndividual, user, mailingList, onSelect, removeMailingList, sentMailingList
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);

  const onRemove = () => {
    setIsProcessing(true);
    deleteMailingList(mailingList.id)
      .then((res: { success: boolean }) => {
        if (res.success) {
          removeMailingList(mailingList.id);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsProcessing(false));
  };

  const onProcess = () => {
    setIsPopoverOpen(false);
    setIsProcessing(true);
    processMailingList(mailingList.id, {})
      .then((res: { success: boolean }) => {
        if (res.success) {
          sentMailingList(mailingList.id);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsProcessing(false));
  };

  const onProcessToOne = () => {
    setIsPopoverOpen(false);
    if (mailingList && user) {
      setIsProcessing(true);
      processMailingListToBulk(mailingList.id, { phones: [user.phone] })
        .catch((error) => console.log(error))
        .finally(() => setIsProcessing(false));
    }
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'top', 'left', 'right']}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="#daf3ef"
            arrowSize={10}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            {isIndividual ? (
              <div className="popover-content">
                <div className="option" onClick={onProcessToOne}>
                  <IonLabel>
                    <IonIcon icon={mailOutline} />
                    Send
                  </IonLabel>
                </div>
              </div>
            ) : (
              <div className="popover-content">
                <div className="option" onClick={onSelect}>
                  <IonLabel>
                    <DetailsIcon />
                    Details
                  </IonLabel>
                </div>
                <div className="option" onClick={onProcess}>
                  <IonLabel>
                    <IonIcon icon={mailOutline} />
                    Send
                  </IonLabel>
                </div>
                <div className="option" onClick={() => { setIsPopoverOpen(false); setShowRemoveAlert(true); }}>
                  <IonLabel color="danger">
                    <TrashIcon />
                    Delete
                  </IonLabel>
                </div>
              </div>
            )}
          </ArrowContainer>
        )}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        {isProcessing ? <IonSpinner /> : (
          <span
            className="action cursor-pointer"
            title="More actions"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <IonIcon icon={ellipsisHorizontal} />
          </span>
        )}
      </Popover>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={(): void => setShowRemoveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to delete this sms list?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onRemove()
          }
        ]}
      />
    </>
  );
};

Actions.defaultProps = {
  isIndividual: false,
  user: null
};

Actions.propTypes = {
  isIndividual: PropTypes.bool,
  user: PropTypes.any,
  mailingList: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  removeMailingList: PropTypes.func.isRequired,
  sentMailingList: PropTypes.func.isRequired
};

const initSMSList: MailingList = {
  id: -1,
  name: '',
  subject: '',
  description: '',
  filter: null,
  type: 'sms',
  status: 'new'
};

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface OptionType {
  value: number;
  label: string;
  description: string;
}

interface SMSListProps {
  toggleHeader: (value: boolean) => void
}

interface StateProps {
  providerTypes: ProviderType[]
  roles: Role[]
}

const colorStyles: StylesConfig<OptionType> = {
  control: (styles) => ({
    ...styles,
    marginTop: 4,
    height: 40,
    borderColor: '#BADAD8',
    boxShadow: '0 0 0 1px #BADAD8',
    backgroundColor: '#E2F4F1',
    borderRadius: 10,
    ':hover': {
      borderColor: '#BADAD8',
      boxShadow: '0 0 0 1px #BADAD8'
    }
  })
};

const SMSList: React.FC<SMSListProps & StateProps> = ({
  providerTypes, roles, toggleHeader
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [mailingLists, setMailingLists] = useState<MailingList[]>([]);
  const [receivers, setReceivers] = useState<User[]>([]);
  const [receiversTotalCount, setReceiversTotalCount] = useState(0);
  const [selectedSMSList, setSelectedSMSList] = useState<MailingList | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    type: '',
    role: 'customer',
    searchKey: '',
    country: '',
    state: '',
    city: '',
    providerType: 0
  });
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [selectedReceivers, setSelectedReceivers] = useState<string[]>([]);
  const [unSelectedReceivers, setUnSelectedReceivers] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [isSendingBulk, setIsSendingBulk] = useState(false);
  const [showBulkSendResult, setShowBulkSendResult] = useState({
    open: false,
    message: ''
  });
  const searchTimeoutRef = useRef<number | null>(null);

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const removeMailingList = (mailingListId: number) => {
    const updatemailingLists = mailingLists.filter((ml) => ml.id !== mailingListId);
    setMailingLists([...updatemailingLists]);
  };

  const sentMailingList = (mailingListId: number) => {
    const updatemailingLists = mailingLists.map((ml) => {
      if (ml.id === mailingListId) {
        return { ...ml, status: 'sent' };
      }
      return ml;
    });
    setMailingLists([...updatemailingLists]);
  };

  const fetchMore = (isNew: boolean, str?: string) => {
    const params = {
      type: 'sms',
      skip: isNew ? 0 : mailingLists.length,
      searchKey: str === undefined ? searchKey : str,
      isDescending,
      orderBy
    };

    setIsFetching(true);
    fetchMailingLists(params)
      .then((res: { success: boolean, mailingLists: MailingList[] }) => {
        if (res.success) {
          if (isNew) {
            setMailingLists([...res.mailingLists]);
          } else {
            setMailingLists([...mailingLists, ...res.mailingLists]);
          }
          if (!res.mailingLists.length || res.mailingLists.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsFetching(false));
  };

  const fetchUsersMore = (isNew: boolean) => {
    const { type, providerType, ...rest } = filterOptions;
    const params: any = {
      ...rest,
      skip: isNew ? 0 : receivers.length,
      type: providerType,
      isDescending,
      orderBy
    };
    if (type) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      params.isNew = type === 'new';
    }
    setIsFetching(true);
    fetchUsers(params)
      .then((res: { success: boolean, users: User[], count: number }) => {
        if (res.success) {
          if (isNew) {
            setReceivers([...res.users]);
          } else {
            setReceivers([...receivers, ...res.users]);
            if (isCheckedAll) {
              const newSelectedReceivers = res.users
                .map((uu) => uu.phone)
                .filter((pn) => !unSelectedReceivers.find((ur) => ur === pn));
              setSelectedReceivers([...selectedReceivers, ...newSelectedReceivers]);
            }
          }
          setReceiversTotalCount(res.count);
          if (!res.users.length || res.users.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsFetching(false));
  };

  const onSelectUser = (phone: string) => {
    let updatedSelectedReceivers = [];
    let updatedUnSelectedReceivers = [];
    if (selectedReceivers.includes(phone)) {
      updatedSelectedReceivers = selectedReceivers.filter((sr) => sr !== phone);
      updatedUnSelectedReceivers = [...unSelectedReceivers, phone];
    } else {
      updatedSelectedReceivers = [...selectedReceivers, phone];
      updatedUnSelectedReceivers = unSelectedReceivers.filter((sr) => sr !== phone);
    }
    setSelectedReceivers(updatedSelectedReceivers);
    setUnSelectedReceivers(updatedUnSelectedReceivers);
  };

  const loadTemplates = (inputValue: string) => new Promise<{
    value: number, label: string, description: string
  }[]>((resolve) => {
    fetchTemplates({
      defaultLimit: 5,
      type: 'sms',
      searchKey: inputValue,
      orderBy: 'name',
      isDescending: false
    })
      .then((res: { success: boolean, templates: Template[] }) => {
        resolve(res.templates.map((tp) => ({
          value: tp.id,
          label: tp.name,
          description: tp.description
        })));
      })
      .catch((error) => resolve([]));
  });

  const onSendBulk = async () => {
    if (selectedSMSList) {
      setIsSendingBulk(true);
      try {
        if (isCheckedAll && !unSelectedReceivers.length) {
          await processMailingList(selectedSMSList.id, {});
        } else if (isCheckedAll && !!unSelectedReceivers.length) {
          await processMailingList(selectedSMSList.id, { excludedUsers: unSelectedReceivers });
        } else {
          await processMailingListToBulk(selectedSMSList.id, { phones: selectedReceivers });
        }
        setShowBulkSendResult({
          open: true,
          message: 'Sent SMS to selected users successfully.'
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsSendingBulk(false);
        setSelectedReceivers([]);
        setUnSelectedReceivers([]);
        setIsCheckedAll(false);
      }
    }
  };

  const onAllCheckChange = () => {
    if (selectedReceivers.length === receivers.length) {
      setIsCheckedAll(false);
      setSelectedReceivers([]);
      setUnSelectedReceivers([]);
    } else {
      setIsCheckedAll(true);
      setSelectedReceivers(receivers.map((rv) => rv.phone));
      setUnSelectedReceivers([]);
    }
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {
    if (
      !selectedSMSList
      || !selectedSMSList.name
      || !selectedSMSList.description
    ) {
      return setShowWarningAlert(true);
    }
    try {
      setIsUpdating(true);
      const formData = new FormData();
      const data = Object.fromEntries(Object.entries(selectedSMSList).filter(([k, v]) => k !== 'filter' && v != null && v !== '' && v !== -1));
      Object.entries(data).map((et) => formData.append(et[0], et[1]));
      formData.append('filter', JSON.stringify(filterOptions));
      if (selectedSMSList.id > -1) {
        const { mailingList } = await updateMailingList(selectedSMSList.id, formData);
        setSelectedSMSList(mailingList);
        fetchUsersMore(true);
      } else {
        await addMailingList(formData);
        setSelectedSMSList(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchedAll(false);
      setOrderBy('id');
      setIsEdit(false);
      setSelectedReceivers([]);
      setUnSelectedReceivers([]);
      setIsCheckedAll(false);
      setIsUpdating(false);
    }
  };

  const getMailingListReportPDF = async () => {
    if (selectedSMSList) {
      const url = await exportMailingListReport(selectedSMSList.id);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `SMSList_Report_${selectedSMSList.id}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const smsColumns = useMemo<Column<MailingList>[]>(
    () => [
      {
        Header: 'NO',
        id: 'index',
        accessor: (_row: any, i : number) => i + 1,
        width: 60
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'name' })}
            onClick={() => onChangeOrderBy('name')}
          >
            NAME
            {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'name',
        width: 'auto'
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'description' })}
            onClick={() => onChangeOrderBy('description')}
          >
            DESCRIPTION
            {orderBy === 'description' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'description',
        width: '50%'
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'createdAt' })}
            onClick={() => onChangeOrderBy('createdAt')}
          >
            CREATED AT
            {orderBy === 'createdAt' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'createdAt',
        Cell: ({ cell: { value } }) => moment.utc(value).local().format('MMM DD YYYY, hh:mm A'),
        width: 'auto'
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'status' })}
            onClick={() => onChangeOrderBy('status')}
          >
            STATUS
            {orderBy === 'status' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'status',
        // eslint-disable-next-line react/prop-types
        Cell: ({ cell: { value } }) => {
          if (value === MailingListStatus.NEW) {
            return (
              <div className="campaign-status new">
                New
              </div>
            );
          }
          return (
            <div className="campaign-status completed">
              Sent
            </div>
          );
        },
        width: 'auto'
      },
      {
        Header: 'ACTION',
        Cell: ({ row: { original } }: { row: { original: MailingList} }) => (
          <Actions
            mailingList={original}
            onSelect={() => {
              toggleHeader(false);
              setIsEdit(false);
              setIsFetchedAll(false);
              setOrderBy('id');
              setSelectedSMSList(original);
              setFilterOptions(original.filter);
            }}
            removeMailingList={removeMailingList}
            sentMailingList={sentMailingList}
          />
        ),
        width: 70
      },
    ], [orderBy, isDescending, onChangeOrderBy]
  );

  const memberColumns = useMemo<Column<User>[]>(
    () => [
      {
        id: 'select',
        Header: () => (
          <div onClick={onAllCheckChange}>
            <IonCheckbox
              disabled={!receivers.length}
              indeterminate={!!selectedReceivers.length && selectedReceivers.length !== receivers.length}
              checked={!!receivers.length && ((selectedReceivers.length === receivers.length) || isCheckedAll)}
            />
          </div>
        ),
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original: { phone } } }: { row: { original: User} }) => (
          <IonCheckbox
            checked={selectedReceivers.includes(phone)}
            onIonChange={() => onSelectUser(phone)}
          />
        ),
        width: 30
      },
      {
        Header: 'NO',
        id: 'index',
        accessor: (_row: any, i : number) => i + 1,
        width: 60
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'name' })}
            onClick={() => onChangeOrderBy('name')}
          >
            NAME
            {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'name',
        Cell: ({ row: { original } }: { row: { original: User} }) => (
          <div className="user-name-email">
            <Avatar user={original} />
            <div>
              <h4>{`${original?.name} ${original?.lastName ?? ''}`}</h4>
              <p>{original?.email}</p>
            </div>
          </div>
        ),
        width: 300
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'phone' })}
            onClick={() => onChangeOrderBy('phone')}
          >
            PHONE NUMBER
            {orderBy === 'phone' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'phone',
        width: 'auto'
      },
      {
        id: 'country',
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'country' })}
            onClick={() => onChangeOrderBy('country')}
          >
            COUNTRY
            {orderBy === 'country' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'locations',
        Cell: ({ cell: { value } }) => (Array.isArray(value) && value.length ? value[0].country : ''),
        width: 'auto'
      },
      {
        id: 'state',
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'state' })}
            onClick={() => onChangeOrderBy('state')}
          >
            PROVINCE
            {orderBy === 'state' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'locations',
        Cell: ({ cell: { value } }) => (Array.isArray(value) && value.length ? value[0].state : ''),
        width: 'auto'
      },
      {
        id: 'city',
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'city' })}
            onClick={() => onChangeOrderBy('city')}
          >
            CITY
            {orderBy === 'city' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'locations',
        Cell: ({ cell: { value } }) => (Array.isArray(value) && value.length ? value[0].city : ''),
        width: 'auto'
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'role' })}
            onClick={() => onChangeOrderBy('role')}
          >
            ROLE
            {orderBy === 'role' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'role',
        Cell: ({ cell: { value } }) => roles.find((role) => role.value === value)?.name,
        width: 'auto'
      },
      {
        Header: 'ACTION',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }: { row: { original: User} }) => {
          if (!selectedSMSList) {
            return null;
          }
          return (
            <Actions
              isIndividual
              user={original}
              mailingList={selectedSMSList}
              onSelect={() => {}}
              removeMailingList={() => {}}
              sentMailingList={() => {}}
            />
          );
        },
        width: 70
      },
    ], [orderBy, isDescending, onChangeOrderBy]
  );

  const totalCheckedCount = useMemo(() => {
    if (isCheckedAll && !unSelectedReceivers.length) {
      return receiversTotalCount;
    }
    if (isCheckedAll) {
      return receiversTotalCount - unSelectedReceivers.length;
    }
    return selectedReceivers.length;
  }, [isCheckedAll, selectedReceivers, receivers, unSelectedReceivers]);

  const availableStates = useMemo(() => {
    if (filterOptions.country) {
      const currentCuntry = Country.getAllCountries().find((c) => c.name === filterOptions.country);
      const states = State.getStatesOfCountry(currentCuntry?.isoCode);
      return states || [];
    }
    return [];
  }, [filterOptions.country]);

  const availableCities = useMemo(() => {
    if (filterOptions.country && filterOptions.state) {
      const currentCuntry = Country.getAllCountries().find((c) => c.name === filterOptions.country);
      const currentState = State.getStatesOfCountry(currentCuntry?.isoCode).find((s) => s.name === filterOptions.state);
      if (currentCuntry && currentState) {
        const cities = City.getCitiesOfState(currentCuntry.isoCode, currentState.isoCode);
        return cities;
      }
    }
    return [];
  }, [filterOptions.country, filterOptions.state]);

  useEffect(() => {
    if (!selectedSMSList) {
      fetchMore(true);
      toggleHeader(true);
    } else if (selectedSMSList.id !== -1) {
      fetchUsersMore(true);
    }
    setSelectedReceivers([]);
    setUnSelectedReceivers([]);
    setIsCheckedAll(false);
  }, [selectedSMSList?.id]);

  useEffect(() => {
    if (selectedSMSList && selectedSMSList.id !== -1) {
      fetchUsersMore(true);
    }
    if (!selectedSMSList) {
      fetchMore(true);
    }
  }, [orderBy, isDescending]);

  return (
    <div className="marketing-section">

      {/* selected SMS List Details */}
      {selectedSMSList && !isEdit ? (
        <>
          <div className="mailing-info-header">
            <IonListHeader className="page-header">
              <IonIcon color="favorite" icon={arrowBackOutline} onClick={() => { setSelectedSMSList(null); setOrderBy('id'); }} />
              SMS List Details
            </IonListHeader>
            <div className="details-actions">
              <IonButton color="favorite" onClick={getMailingListReportPDF}>
                Export
              </IonButton>
              <IonButton color="favorite" fill="outline" onClick={() => setIsEdit(true)}>
                Edit
              </IonButton>
            </div>
          </div>
          <div className="mailinglist-info">
            <div className="mailinglist-info-option">
              <p>NAME</p>
              <span>{selectedSMSList.name}</span>
            </div>
            <div className="mailinglist-info-option" style={{ maxWidth: '40%' }}>
              <p>DESCRIPTION</p>
              <span>{selectedSMSList.description}</span>
            </div>
            <div className="mailinglist-info-option">
              <p>STATUS</p>
              {selectedSMSList.status === MailingListStatus.NEW ? (
                <div className="mailinglist-status new">
                  <span />
                  New
                </div>
              ) : (
                <div className="mailinglist-status completed">
                  <span />
                  Sent
                </div>
              )}
            </div>
            <div className="mailinglist-info-option">
              <p>CREATED AT</p>
              <span>{moment.utc(selectedSMSList.createdAt).local().format('MMM DD YYYY, hh:mm A')}</span>
            </div>
            <div className="mailinglist-info-option">
              <p>UPDATED AT</p>
              <span>{moment.utc(selectedSMSList.updatedAt).local().format('MMM DD YYYY, hh:mm A')}</span>
            </div>
          </div>
          <div className="marketing-section-list">
            <div className="marketing-section-list-header">
              <div className="selected-count">
                Selected:
                <span>{totalCheckedCount}</span>
              </div>
              <IonButton color="favorite" disabled={!selectedReceivers.length} onClick={onSendBulk}>
                {isSendingBulk ? <IonSpinner className="light" /> : 'Send Bulk'}
              </IonButton>
            </div>
            <Table
              columns={memberColumns}
              data={receivers}
            />
            {isFetchedAll ? null : (
              <IonInfiniteScroll
                onIonInfinite={(ev) => {
                  fetchUsersMore(false);
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
                }}
              >
                <IonInfiniteScrollContent
                  loadingText="Fetching more..."
                  loadingSpinner="circles"
                />
              </IonInfiniteScroll>
            )}
          </div>
        </>
      ) : null}

      {/* main screen */}
      {!selectedSMSList && !isEdit ? (
        <>
          <div className="marketing-section-header">
            <IonButton
              color="favorite"
              onClick={() => {
                setSelectedSMSList(initSMSList);
                setFilterOptions({
                  type: '',
                  role: 'customer',
                  searchKey: '',
                  country: '',
                  state: '',
                  city: '',
                  providerType: 0
                });
                setIsEdit(true);
                toggleHeader(false);
              }}
            >
              + Create SMS List
            </IonButton>
            <div className="marketing-section-filter">
              <IonItem className="search-input" lines="none">
                <IonInput
                  value={searchKey}
                  placeholder="Search"
                  onIonChange={(e) => {
                    const inputValue = e.detail.value ?? '';
                    setSearchKey(inputValue);
                    if (searchTimeoutRef.current) {
                      clearTimeout(searchTimeoutRef.current);
                    }
                  
                    searchTimeoutRef.current = window.setTimeout(() => {
                      fetchMore(true, inputValue);
                    }, 450);
                  }}
                />
                <IonIcon size="small" icon={searchOutline} onClick={() => fetchMore(true, searchKey)} />
                <IonIcon size="small" icon={closeOutline} onClick={() => { setSearchKey(''); fetchMore(true, ''); }} />
              </IonItem>
              <IonButton color="dark" fill="outline">
                <IonIcon icon={options} />
                Filter
              </IonButton>
            </div>
          </div>
          <div className="marketing-section-list">
            <Table
              columns={smsColumns}
              data={mailingLists}
            />
            {isFetchedAll ? null : (
              <IonInfiniteScroll
                onIonInfinite={(ev) => {
                  fetchMore(false);
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
                }}
              >
                <IonInfiniteScrollContent
                  loadingText="Fetching more..."
                  loadingSpinner="circles"
                />
              </IonInfiniteScroll>
            )}
          </div>
        </>
      ) : null}

      {selectedSMSList && isEdit ? (
        <>
          <div className="marketing-section-details">
            <div className="marketing-section-header">
              <div className="screen-header">
                {selectedSMSList.id !== -1 ? 'Update SMS List' : 'Add SMS List'}
              </div>
            </div>
            <div className="marketing-section-content">
              <IonGrid>
                <IonRow>
                  <IonCol size="4">
                    <IonLabel className="field-title">Name*</IonLabel>
                    <IonItem lines="none" className="input">
                      <IonInput
                        type="text"
                        placeholder=""
                        value={selectedSMSList.name}
                        onIonChange={(e) => setSelectedSMSList({ ...selectedSMSList, name: e.detail.value as string })}
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol size="2" />
                  <IonCol size="6">
                    <IonLabel className="field-title">Template</IonLabel>
                    <AsyncSelect<OptionType>
                      placeholder="Search Template..."
                      loadOptions={loadTemplates}
                      onChange={(option) => {
                        if (option) {
                          setSelectedSMSList({ ...selectedSMSList, description: option.description });
                        }
                      }}
                      styles={colorStyles}
                    />
                  </IonCol>
                  <IonCol size="12">
                    <IonLabel className="field-title">Description*</IonLabel>
                    <IonItem lines="none" className="input">
                      <IonTextarea
                        placeholder=""
                        maxlength={500}
                        rows={3}
                        value={selectedSMSList.description}
                        onIonChange={(e) => setSelectedSMSList({ ...selectedSMSList, description: e.detail.value as string })}
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" />
                </IonRow>
                <IonRow>
                  <IonCol size="12">
                    <IonLabel className="section-title">
                      Receiver Filtering Options
                    </IonLabel>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="6" className="center">
                    <IonSegment
                      mode="ios"
                      className="md"
                      value={filterOptions.type}
                      onIonChange={(e) => setFilterOptions({ ...filterOptions, type: e.detail.value as string })}
                    >
                      <IonSegmentButton value="">All</IonSegmentButton>
                      <IonSegmentButton value="new">New</IonSegmentButton>
                      <IonSegmentButton value="existing">Existing</IonSegmentButton>
                    </IonSegment>
                  </IonCol>
                  <IonCol size="6">
                    <IonItem className="input sm" lines="none">
                      <IonInput
                        value={filterOptions.searchKey}
                        placeholder="Search by name"
                        onIonChange={(e) => {
                          setFilterOptions({ ...filterOptions, searchKey: e.detail.value ?? '' });
                        }}
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    <IonLabel className="field-title">Role</IonLabel>
                    <IonItem lines="none" className="select">
                      <IonSelect
                        className="select"
                        placeholder="Please Select"
                        disabled={!isEdit}
                        value={filterOptions.role}
                        onIonChange={(e) => setFilterOptions({ ...filterOptions, role: e.detail.value })}
                      >
                        <IonSelectOption value="customer">Customer</IonSelectOption>
                        <IonSelectOption value="provider">Provider</IonSelectOption>
                        <IonSelectOption value="clinic-admin">Clinic</IonSelectOption>
                        <IonSelectOption value="marketing-member">Marketing Member</IonSelectOption>
                        <IonSelectOption value="revenue-analyst">Revenue Analyst</IonSelectOption>
                        <IonSelectOption value="helpdesk">Helpdesk</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  {filterOptions.role === 'provider' || filterOptions.role === 'clinic-admin' ? (
                    <IonCol size="6">
                      <IonLabel className="field-title">Provider Type</IonLabel>
                      <IonItem lines="none" className="select">
                        <IonSelect
                          className="select"
                          placeholder="Select Provider Type"
                          value={filterOptions.providerType}
                          onIonChange={(e): void => {
                            setFilterOptions({ ...filterOptions, providerType: Number(e.detail.value) });
                          }}
                        >
                          <IonSelectOption key="0" value={0}>All Provider Types</IonSelectOption>
                          {providerTypes.map((pt) => (
                            <IonSelectOption key={pt.id} value={pt.id}>{pt.name}</IonSelectOption>
                          ))}
                        </IonSelect>
                      </IonItem>
                    </IonCol>
                  ) : null}
                  <IonCol size="6">
                    <IonLabel className="field-title">Country</IonLabel>
                    <IonItem lines="none" className="select">
                      <IonSelect
                        className="select"
                        placeholder="Select Country"
                        value={filterOptions.country}
                        onIonChange={(e) => setFilterOptions({ ...filterOptions, country: e.detail.value })}
                      >
                        <IonSelectOption value="">All countries</IonSelectOption>
                        <IonSelectOption value="United States">United States</IonSelectOption>
                        <IonSelectOption value="Canada">Canada</IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    <IonLabel className="field-title">Province</IonLabel>
                    <IonItem lines="none" className="select">
                      <IonSelect
                        className="select"
                        placeholder="Select Province"
                        value={filterOptions.state}
                        onIonChange={(e) => setFilterOptions({ ...filterOptions, state: e.detail.value })}
                      >
                        <IonSelectOption value="">All provinces</IonSelectOption>
                        {availableStates.map((st) => (
                          <IonSelectOption key={st.name} value={st.name}>{st.name}</IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol size="6">
                    <IonLabel className="field-title">City</IonLabel>
                    <IonItem lines="none" className="select">
                      <IonSelect
                        className="select"
                        placeholder="Select City"
                        value={filterOptions.city}
                        onIonChange={(e) => setFilterOptions({ ...filterOptions, city: e.detail.value })}
                      >
                        <IonSelectOption value="">All cities</IonSelectOption>
                        {availableCities.map((ct) => (
                          <IonSelectOption key={ct.name} value={ct.name}>{ct.name}</IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className="campaign-actions">
                      <IonButton color="favorite" onClick={onSubmit}>
                        {isUpdating ? <IonSpinner /> : null}
                        {!isUpdating && selectedSMSList.id > -1 ? 'Update' : null}
                        {!isUpdating && selectedSMSList.id === -1 ? 'Save' : null}
                      </IonButton>
                      <IonButton
                        color="dark"
                        fill="outline"
                        onClick={() => {
                          if (selectedSMSList.id === -1) {
                            setIsFetchedAll(false);
                            setOrderBy('id');
                            setSelectedSMSList(null);
                            toggleHeader(true);
                          }
                          setIsEdit(false);
                        }}
                      >
                        Cancel
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </>
      ) : null}

      <IonAlert
        isOpen={showWarningAlert}
        onDidDismiss={(): void => setShowWarningAlert(false)}
        header="CarePlatform"
        message="Please input mandatory information."
        buttons={['OK']}
      />

      <IonAlert
        isOpen={showBulkSendResult.open}
        onDidDismiss={(): void => setShowBulkSendResult({ open: false, message: '' })}
        header="CarePlatform"
        message={showBulkSendResult.message}
        buttons={['OK']}
      />

      <IonLoading isOpen={isFetching} />
    </div>
  );
};

SMSList.propTypes = {
  providerTypes: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired,
  toggleHeader: PropTypes.func.isRequired
};

export default connect<SMSListProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes,
    roles: state.data.roles
  }),
  component: React.memo(SMSList)
});
