import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IonButton, IonContent, IonImg } from '@ionic/react';

import {
  FirstStep,
  SecondStep,
  ThirdStep,
  FourthStep,
  FifthStep,
  SixthStep,
  UpdatePasswordStep
} from './steps';
import connect from '../../data/connect';
import { dispatchLogout } from '../../data/store/auth/auth.actions';
import { StatusContext } from '../../data/StatusContext';
import {
  User, UserRole, providerOnboardingSteps, clinicMemberOnboardingSteps
} from '../../models';
import { SignOutIcon } from '../../icons';

import logoImg from '../../assets/logo.png';
import './ProviderOnboarding.scss';

interface StateProps {
  user: User
}

interface DispatchProps {
  logout: typeof dispatchLogout
}

const ProviderOnboarding: React.FC<StateProps & DispatchProps> = ({
  user,
  logout
}) => {
  const history = useHistory();
  const { syncDisconnect } = useContext(StatusContext);
  const [step, setStep] = useState(0);

  const onSignOut = (): void => {
    syncDisconnect(user);
    logout();
    history.push('/login');
  };

  const onNext = () => {
    if (step === 5) {
      history.push('/provider/dashboard');
    } else {
      setStep(step + 1);
    }
  };

  const onPrev = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    if (user) {
      if (user.onboardingStep === 100) {
        history.push(`/${user.role}/dashboard`);
      } else {
        setStep(user.onboardingStep);
      }
    }
  }, []);

  return (
    <IonContent id="provider-onboarding" className="page-content ion-padding">
      <IonButton className="signout-btn" color="favorite" fill="clear" onClick={onSignOut}>
        <SignOutIcon color="#08A284" />
        Sign Out
      </IonButton>
      <div className="onboarding-panel">
        <IonImg src={logoImg} alt="logo" />
        {user.role === UserRole.PROVIDER ? (
          <>
            <h2 className="page-header">{providerOnboardingSteps[step]}</h2>
            <p className="current-step">
              {`${step + 1} of 6`}
            </p>
            <div className="stepper">
              {providerOnboardingSteps.map((st, index) => (
                <div key={st} className={index === step ? 'step-indicator active' : 'step-indicator'} />
              ))}
            </div>
            {step === 0 ? <FirstStep user={user} next={onNext} /> : null}
            {step === 1 ? <SecondStep user={user} prev={onPrev} next={onNext} /> : null}
            {step === 2 ? <ThirdStep user={user} prev={onPrev} next={onNext} /> : null}
            {step === 3 ? <FourthStep user={user} prev={onPrev} next={onNext} /> : null}
            {step === 4 ? <FifthStep user={user} prev={onPrev} next={onNext} /> : null}
            {step === 5 ? <SixthStep user={user} prev={onPrev} next={onNext} /> : null}
          </>
        ) : (
          <>
            <h2 className="page-header">{clinicMemberOnboardingSteps[step]}</h2>
            <p className="current-step">
              {`${step + 1} of 7`}
            </p>
            <div className="stepper">
              {clinicMemberOnboardingSteps.map((st, index) => (
                <div key={st} className={index === step ? 'step-indicator active' : 'step-indicator'} />
              ))}
            </div>
            {step === 0 ? <UpdatePasswordStep user={user} next={onNext} /> : null}
            {step === 1 ? <FirstStep user={user} next={onNext} /> : null}
            {step === 2 ? <SecondStep user={user} prev={onPrev} next={onNext} /> : null}
            {step === 3 ? <ThirdStep user={user} prev={onPrev} next={onNext} /> : null}
            {step === 4 ? <FourthStep user={user} prev={onPrev} next={onNext} /> : null}
            {step === 5 ? <FifthStep user={user} prev={onPrev} next={onNext} /> : null}
            {step === 6 ? <SixthStep user={user} prev={onPrev} next={onNext} /> : null}
          </>
        )}
      </div>
    </IonContent>
  );
};

ProviderOnboarding.propTypes = {
  user: PropTypes.any.isRequired,
  logout: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {
    logout: dispatchLogout
  },
  component: React.memo(ProviderOnboarding)
});
