import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Location } from '../../models';
import './Map.scss';

interface MapProps {
  locations: Location[]
  mapCenter: any
}

const Map: React.FC<MapProps> = ({ mapCenter, locations }) => {
  const mapEle = useRef<HTMLDivElement>(null);
  const map = useRef<google.maps.Map>();

  const addMarkers = useCallback((): void => {
    locations.forEach((markerData) => {
      const infoWindow = new google.maps.InfoWindow({
        content: `<h5>${markerData.address}</h5>`
      });

      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(markerData.lat, markerData.lng),
        map: map.current,
        title: markerData.address
      });

      marker.addListener('click', () => {
        infoWindow.open(map.current, marker);
      });
    });
  }, [locations]);

  useEffect(() => {
    if (!mapEle.current) {
      return;
    }
    map.current = new google.maps.Map<HTMLDivElement>(mapEle.current, {
      center: {
        lat: mapCenter.lat || 34.0195,
        lng: mapCenter.lng || -118.491
      },
      streetViewControl: false,
      zoom: 17
    });

    addMarkers();

    google.maps.event.addListenerOnce(map.current, 'idle', () => {
      if (mapEle.current) {
        mapEle.current.classList.add('show-map');
      }
    });
  }, [mapCenter, addMarkers]);

  return <div ref={mapEle} className="map-canvas" />;
};

Map.defaultProps = {
  locations: []
};

Map.propTypes = {
  mapCenter: PropTypes.any.isRequired,
  locations: PropTypes.any
};

export default Map;
