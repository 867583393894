import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonButton,
  IonContent,
  IonLoading,
  IonSpinner
} from '@ionic/react';
import moment from 'moment';
import axios from 'axios';

import connect from '../../data/connect';
import { fetchBooking, getRecords } from '../../data/dataApi';
import {
  Booking, BookingStatus, ChargeStatus, ProviderType
} from '../../models';
import { ExportIcon } from '../../icons';

import './BookingReport.scss';

interface MatchParams {
  id: string
}

interface StateProps {
  providerTypes: ProviderType[]
}

type BookingReportProp = RouteComponentProps<MatchParams> & StateProps;

const BookingReport: React.FC<BookingReportProp> = ({ match, history, providerTypes }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [booking, setBooking] = useState<Booking | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadRecords = async () => {
    if (booking && booking.hasDownloadFile) {
      setIsDownloading(true);
      try {
        const { records } = await getRecords(booking.id);
        if (records && records.length > 0) {
          const promises: any[] = [];
          records.map((record: any) => {
            promises.push(
              axios.get(record.signedUrl, { responseType: 'blob' })
                .then((res) => {
                  // Creating new object of PDF file
                  const fileURL = window.URL.createObjectURL(res.data);
                  // Setting various property values
                  let alink = document.createElement('a');
                  alink.href = fileURL;
                  alink.download = record.filename;
                  alink.click();
                })
                .catch((err) => console.log(err))
            );
          });
          await Promise.all(promises);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const reason = useMemo(() => {
    if (booking && booking.charge.status) {
      if (booking.status === BookingStatus.ACCEPTED) {
        return 'Accepted by Provider';
      }
      if (booking.status === BookingStatus.COMPLETED) {
        return 'Completed';
      }
      if (booking.status === BookingStatus.REJECTED_CUSTOMER) {
        return 'Pending appointment - Cancelled by Customer';
      }
      if (booking.status === BookingStatus.REJECTED_PROVIDER) {
        return 'Rejected by Provider';
      }
      if (booking.status === BookingStatus.CANCELLED_PROVIDER) {
        return 'Cancelled by Provider';
      }
      if (booking.status === BookingStatus.CANCELLED_CUSTOMER && booking.charge.chargedAmount === 0) {
        return 'Accepted appointment - Cancelled by Customer';
      }
      if (booking.status === BookingStatus.CANCELLED_CUSTOMER && booking.charge.chargedAmount > booking.charge.amount / 2) {
        return 'Accepted appointment - Cancelled by Customer at booking date';
      }
      if (booking.status === BookingStatus.CANCELLED_CUSTOMER && booking.charge.chargedAmount) {
        return 'Accepted appointment - Cancelled by Customer one day before booking date';
      }
      if (booking.status === BookingStatus.INCOMPLETE && !booking.charge.chargedAmount && booking.charge.status === ChargeStatus.REFUNDED && !booking.charge.stripeFee) {
        return 'Appointment not accepted';
      }
      if (booking.status === BookingStatus.INCOMPLETE && !booking.charge.chargedAmount && booking.charge.status === ChargeStatus.REFUNDED && booking.charge.stripeFee) {
        return 'Provider not attend call';
      }
      if (booking.status === BookingStatus.INCOMPLETE && booking.charge.chargedAmount && booking.charge.status === ChargeStatus.CHARGED && booking.charge.providerFee) {
        return 'Customer not attend call';
      }
      if (booking.status === BookingStatus.INCOMPLETE && booking.charge.chargedAmount && booking.charge.status === ChargeStatus.CHARGED && !booking.charge.providerFee) {
        return 'Both of provider and customer not attend call';
      }
      return 'Hold now';
    }
    return '';
  }, [booking]);

  useEffect(() => {
    const getData = (id: number) => {
      setIsLoading(true);
      fetchBooking(id)
        .then((res) => {
          if (res.success) {
            setBooking(res.booking as Booking);
          }
        })
        .catch((err) => {
          console.log(err);
          history.goBack();
        })
        .finally(() => setIsLoading(false));
    };

    if (match.params.id) {
      getData(Number(match.params.id));
    }
  }, [match.params.id]);

  return (
    <IonContent id="booking-report" className="page-content ion-padding">
      {isLoading || !booking ? <IonLoading isOpen={isLoading} /> : (
        <>
          <div className="booking-summary">
            <div className="booking-sub-summary">
              <h2 className="sub-title">Booking Info</h2>
              <div className="booking-content">
                <div className="booking-option">
                  <span className="label">Booking ID</span>
                  <span className="content">{booking.id}</span>
                </div>
                <div className="booking-option">
                  <span className="label">Customer name</span>
                  <span
                    className="content link"
                    onClick={() => history.push(`/admin/report/user/${booking.customer.id}`)}
                  >
                    {`${booking.customer.name} ${booking.customer.lastName || ''}`}
                  </span>
                </div>
                <div className="booking-option">
                  <span className="label">Phone number</span>
                  <span className="content">{booking.customer.phone}</span>
                </div>
                <div className="booking-option">
                  <span className="label">Provider name</span>
                  <span
                    className="content link"
                    onClick={() => history.push(`/admin/report/user/${booking.provider.id}`)}
                  >
                    {`${booking.provider.name} ${booking.provider.lastName || ''}`}
                  </span>
                </div>
                <div className="booking-option">
                  <span className="label">Provider location</span>
                  <span className="content">
                    {booking.provider.locations.find((loc) => loc.id === booking.locationId)?.address}
                  </span>
                </div>
                <div className="booking-option">
                  <span className="label">Provider type</span>
                  <span className="content">{providerTypes.find((pt) => pt.id === booking.provider.type)?.name}</span>
                </div>
                <div className="booking-option">
                  <span className="label">Service name</span>
                  <span className="content">{booking.service}</span>
                </div>
                <div className="booking-option">
                  <span className="label">Booking date and time</span>
                  <span className="content">{moment.utc(`${booking.date} ${booking.time}`).local().format('DD MMMM, YYYY hh:mm A')}</span>
                </div>
                <div className="booking-option">
                  <span className="label">Booking duration</span>
                  <span className="content">{`${booking.duration}mins`}</span>
                </div>
                {booking.status === BookingStatus.COMPLETED ? (
                  <div className="booking-option">
                    <span className="label">Call duration</span>
                    <span className="content">{`${moment(booking.callEndedAt).diff(moment(booking.callStartedAt), 'minutes')}mins`}</span>
                  </div>
                ) : null}
                <div className="booking-option">
                  <span className="label">Booking status</span>
                  {booking.status === BookingStatus.PENDING ? (
                    <div className="status pending">Pending</div>
                  ) : null}
                  {booking.status === BookingStatus.REJECTED_PROVIDER ? (
                    <div className="status rejected">Rejected</div>
                  ) : null}
                  {booking.status === BookingStatus.REJECTED_CUSTOMER ? (
                    <div className="status abandoned">Abandoned</div>
                  ) : null}
                  {booking.status === BookingStatus.CANCELLED_CUSTOMER || booking.status === BookingStatus.CANCELLED_PROVIDER ? (
                    <div className="status cancelled">Cancelled</div>
                  ) : null}
                  {booking.status === BookingStatus.ACCEPTED ? (
                    <div className="status accepted">Accepted</div>
                  ) : null}
                  {booking.status === BookingStatus.COMPLETED ? (
                    <div className="status completed">Completed</div>
                  ) : null}
                  {booking.status === BookingStatus.INCOMPLETE ? (
                    <div className="status incomplete">Incomplete</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="booking-sub-summary">
              <h2 className="sub-title">Payment Info</h2>
              <div className="booking-content">
                <div className="booking-option">
                  <span className="label">Payment Status</span>
                  {booking.charge.status === ChargeStatus.CHARGED ? (
                    <div className="status charged">Charged</div>
                  ) : null}
                  {booking.charge.status === ChargeStatus.REFUNDED ? (
                    <div className="status refunded">Refunded</div>
                  ) : null}
                  {booking.charge.status === ChargeStatus.RELEASED ? (
                    <div className="status refunded">Released</div>
                  ) : null}
                  {booking.charge.status === ChargeStatus.HOLD ? (
                    <div className="status hold">Hold</div>
                  ) : null}
                </div>
                <div className="booking-option">
                  <span className="label">Payment type</span>
                  <span className="content">
                    {booking.charge.sourceType}
                  </span>
                </div>
                <div className="booking-option">
                  <span className="label">Booking amount</span>
                  <span className="content">{`$${booking.price.toFixed(2)}`}</span>
                </div>
                <div className="booking-option">
                  <span className="label">Provider split</span>
                  <span className="content">{`$${booking.charge.providerFee.toFixed(2)}`}</span>
                </div>
                <div className="booking-option">
                  <span className="label">C.P split</span>
                  <span className="content">{`$${(booking.charge.cpFee + booking.charge.stripeFee).toFixed(2)}`}</span>
                </div>
                <div className="booking-option">
                  <span className="label">Stripe fees</span>
                  <span className="content">{`$${booking.charge.stripeFee.toFixed(2)}`}</span>
                </div>
                <div className="booking-option">
                  <span className="label">C.P net</span>
                  <span className="content">{`$${booking.charge.cpFee.toFixed(2)}`}</span>
                </div>
                <div className="booking-option">
                  <span className="label">Reason</span>
                  <span className="content">{reason}</span>
                </div>
                <br />
                <div className="booking-option">
                  <span className="label">Tax</span>
                  <span className="content">$0.00</span>
                </div>
                <div className="booking-option">
                  <span className="label">Total amount</span>
                  <span className="content">{`$${booking.price.toFixed(2)}`}</span>
                </div>
              </div>
            </div>
          </div>
          <IonButton color="dark" fill="outline">
            <ExportIcon />
            &nbsp;Export
          </IonButton>
          {booking.status === BookingStatus.COMPLETED && booking.hasDownloadFile ? (
            <IonButton color="favorite">
              {isDownloading ? <IonSpinner /> : 'Call Record'}
            </IonButton>
          ) : null}
        </>
      )}
    </IonContent>
  );
};

BookingReport.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  providerTypes: PropTypes.array.isRequired
};

export default connect<BookingReportProp, {}, {}>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes
  }),
  component: React.memo(BookingReport)
});
