import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonList,
  IonItem,
  IonButton,
  IonInput,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonAlert,
  IonLoading,
  IonAvatar,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import { brushOutline, checkmarkCircle } from 'ionicons/icons';
import clsx from 'clsx';

import connect from '../../data/connect';
import {
  User, Language, Specialist
} from '../../models';
import {
  updateProviderProfile,
  uploadProfileImage,
  getImage
} from '../../data/dataApi';
import { dispatchFetchClinicMembers } from '../../data/store/data/data.actions';
import * as selectors from '../../data/selectors';
import ServiceManagement from '../../components/ServiceManagement';
import Map from '../../components/Map';
import {
  UserIcon, PhoneIcon, ListIcon, SphereIcon, LocationIcon
} from '../../icons';
import './ClinicMemberProfile.scss';

interface StateProps {
  allLanguages: Language[]
  allSpecialists: Specialist[]
  member: User | undefined
  user: User
}

interface DispatchProps {
  updateUser: typeof dispatchFetchClinicMembers
}

type ClinicMemberProfileProps = RouteComponentProps & StateProps & DispatchProps;

const ClinicMemberProfile: React.FC<ClinicMemberProfileProps> = ({
  user,
  member,
  history,
  allLanguages,
  allSpecialists,
  updateUser
}) => {
  const [avatar, setAvatar] = useState('');
  const [file, setFile] = useState();

  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [about, setAbout] = useState('');
  const [type, setType] = useState(-1);
  const [languages, setLanguages] = useState([] as number[]);
  const [specialists, setSpecialists] = useState([] as number[]);
  const [services, setServices] = useState([] as any[]);
  const [location, setLocation] = useState('');

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const getTypeId = (str: string) => {
    let typeId: number;
    switch (str) {
      case 'Dietitian':
        typeId = 1;
        break;
      case 'Massage Therapist':
        typeId = 2;
        break;
      default:
        typeId = 3;
        break;
    }
    return typeId;
  };

  const onUpdateProfile = (): void => {
    if (member) {
      setLoading(true);
      if (file) {
        uploadProfileImage(member.id, file);
      }
      updateProviderProfile(member.id, {
        profile: {
          name,
          title,
          about
        },
        languages,
        specialists,
        services,
        locations: user.locations.filter((item) => item.address === location)
      })
        .then((res) => {
          if (res.success) {
            updateUser(user.id);
            setShowAlert(true);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const isCertified = useMemo(() => {
    if (member && member.licenses) {
      return !!member.licenses.find((lic) => getTypeId(lic.type) === type);
    }
    return false;
  }, [type, member]);

  useEffect(() => {
    if (member) {
      setAvatar(getImage(member.image));
      setName(member.name);
      setTitle(member.title);
      setAbout(member.about);
      setType(member.type);
      setLanguages(member.languages.map((language) => language.id));
      setSpecialists(member.specialists.map((specialist) => specialist.id));
      setServices(member.services);
      if (member.locations.length) {
        setLocation(member.locations[0].address || '');
      }
    }
  }, [member]);

  if (!member) {
    return <div>invalid member</div>;
  }

  return (
    <IonContent id="clinic-member-profile" className="page-content ion-padding">
      <IonList>
        <IonItem lines="none" className="avatar">
          <IonAvatar slot="start">
            <img src={avatar} alt="selfie" />
            <IonButton color="light" onClick={() => inputRef.current?.click()}>
              <IonIcon icon={brushOutline} color="dark" />
            </IonButton>
          </IonAvatar>
          <input
            ref={inputRef}
            type="file"
            id="myfile"
            name="myfile"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              if (e.target.files) {
                setAvatar(URL.createObjectURL(e.target.files[0]));
                setFile(e.target.files[0] as any);
              }
            }}
          />
          <IonLabel>
            <h2>{name}</h2>
            <p>{title}</p>
          </IonLabel>
        </IonItem>
        <IonItem lines="none" className="input">
          <UserIcon />
          <IonInput
            name="name"
            type="text"
            placeholder="Name"
            value={name}
            onIonChange={(e): void => {
              setName(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="input">
          <UserIcon />
          <IonInput
            name="title"
            type="text"
            placeholder="Title"
            value={title}
            onIonChange={(e): void => {
              setTitle(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="input disable">
          <PhoneIcon />
          <IonLabel>{member.phone}</IonLabel>
        </IonItem>
        <IonItem lines="none" className="input">
          <UserIcon />
          <IonTextarea
            name="about"
            value={about}
            rows={1}
            placeholder="About you..."
            onIonChange={(e): void => {
              setAbout(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="select">
          <UserIcon />
          <IonSelect
            className={clsx('select', { empty: !type })}
            placeholder="Select provider type..."
            value={type}
            onIonChange={(e): void => setType(parseInt(e.detail.value, 10))}
          >
            <IonSelectOption value={1}>Dietitian</IonSelectOption>
            <IonSelectOption value={2}>Massage Therapist</IonSelectOption>
            <IonSelectOption value={3}>Physician</IonSelectOption>
          </IonSelect>
          {isCertified ? <IonIcon color="favorite" icon={checkmarkCircle} /> : null}
        </IonItem>
        <IonItem lines="none" className="select">
          <ListIcon />
          <IonSelect
            className="select"
            placeholder="Select your specialty..."
            multiple
            value={specialists}
            disabled={!type}
            onIonChange={(e): void => setSpecialists(e.detail.value)}
          >
            {allSpecialists
              .filter((specialist) => specialist.type === type)
              .map((specialist) => (
                <IonSelectOption key={specialist.id} value={specialist.id}>
                  {specialist.name}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonItem>
        <IonItem lines="none" className="select">
          <SphereIcon />
          <IonSelect
            className="select"
            placeholder="Select languages"
            multiple
            value={languages}
            disabled={!type}
            onIonChange={(e): void => setLanguages(e.detail.value)}
          >
            {allLanguages.map((language) => (
              <IonSelectOption key={language.id} value={language.id}>
                {language.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <ServiceManagement
          cssClass="services-view"
          services={services}
          updateServices={setServices}
          isEnableToAdd={!!type}
          hasTitle
        />
        <IonItem lines="none" className="select">
          <LocationIcon />
          <IonSelect
            className="select"
            placeholder="Location"
            value={location}
            onIonChange={(e): void => setLocation(e.detail.value)}
          >
            {member.locations.map((item) => (
              <IonSelectOption key={item.address} value={item.address}>
                {item.address}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem lines="none" className="map">
          <div className="map-container">
            <Map
              locations={user.locations.filter(
                (item) => item.address === location
              )}
              mapCenter={location}
            />
          </div>
        </IonItem>
        {/* <IonItem lines="none" className="input">
          <IonLabel>
            <label htmlFor="certificate">
              {certificate ? certificate.name : 'Upload Certificate'}
            </label>
            <input
              type="file"
              id="certificate"
              name="certificate"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => {
                if (e.target.files) {
                  setCertificate(e.target.files[0] as any);
                }
              }}
            />
          </IonLabel>
        </IonItem> */}
      </IonList>
      <IonButton color="favorite" onClick={(): void => onUpdateProfile()}>
        Update
      </IonButton>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={(): void => {
          setShowAlert(false);
        }}
        header="Care Platform"
        message="Profile updated successfully."
        buttons={[
          {
            text: 'Ok',
            handler: (): void => history.goBack()
          }
        ]}
      />
      <IonLoading isOpen={loading} />
    </IonContent>
  );
};

ClinicMemberProfile.propTypes = {
  history: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  member: PropTypes.any.isRequired,
  allLanguages: PropTypes.array.isRequired,
  allSpecialists: PropTypes.array.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    user: state.auth.user,
    allLanguages: state.data.allLanguages,
    allSpecialists: state.data.allSpecialists,
    member: selectors.getMember(state, OwnProps)
  }),
  mapDispatchToProps: {
    updateUser: dispatchFetchClinicMembers
  },
  component: React.memo(ClinicMemberProfile)
});
