import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PanelIcon = ({ color = '#000' }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path d="M16.9583 0H5.04167C3.70498 0.00145582 2.42346 0.533097 1.47828 1.47828C0.533097 2.42346 0.00145582 3.70498 0 5.04167L0 16.9583C0.00145582 18.295 0.533097 19.5765 1.47828 20.5217C2.42346 21.4669 3.70498 21.9985 5.04167 22H16.9583C18.295 21.9985 19.5765 21.4669 20.5217 20.5217C21.4669 19.5765 21.9985 18.295 22 16.9583V5.04167C21.9985 3.70498 21.4669 2.42346 20.5217 1.47828C19.5765 0.533097 18.295 0.00145582 16.9583 0V0ZM2.75 16.9583V11H11V2.75H16.9583C17.5661 2.75 18.149 2.99144 18.5788 3.42121C19.0086 3.85098 19.25 4.43388 19.25 5.04167V11H11V19.25H5.04167C4.43388 19.25 3.85098 19.0086 3.42121 18.5788C2.99144 18.149 2.75 17.5661 2.75 16.9583Z" fill={color} />
  </svg>
);

export default PanelIcon;
