import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import { useLocation } from 'react-router-dom';

import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonAlert,
  IonIcon,
  IonLoading,
} from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';

import connect from '../../data/connect';
import { dispatchRegister, dispatchResetErrorMessage } from '../../data/store/auth/auth.actions';
import PhonenumberInput from '../../components/PhonenumberInput';

import logoImg from '../../assets/logo.png';
import './Signup.scss';

interface StateProps {
  loading: boolean
  loggedin: boolean
  role: string
  errorMessage: string
}

interface DispatchProps {
  register: typeof dispatchRegister
  resetErrorMessage: typeof dispatchResetErrorMessage
}

type SignupProps = RouteComponentProps & StateProps & DispatchProps;

const Signup: React.FC<SignupProps> = ({
  history, register, resetErrorMessage, loading, loggedin, role, errorMessage
}) => {
  const location = useLocation();
  const [phone, setPhone] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [noMatched, setNoMatched] = useState(false);

  const signup = (e: React.FormEvent): void => {
    e.preventDefault();
    if (password !== confirmPassword) {
      return setNoMatched(true);
    }

    if (phone && selectedRole) {
      register({
        phone: `+1${phone}`,
        name,
        lastName: selectedRole === 'clinic-admin' ? '' : lastName,
        password,
        role: selectedRole
      });
    }
  };

  const disabledSubmit = useMemo(() => {
    if (!phone || !name || !password || !confirmPassword || (selectedRole !== 'clinic-admin' && !lastName)) {
      return true;
    }
    return false;
  }, [phone, name, password, confirmPassword, lastName, selectedRole]);

  useEffect(() => {
    if (loggedin && role) {
      history.push('/verify');
    }
  }, [loggedin, role, history]);

  useEffect(() => {
    resetErrorMessage();
  }, [location]);

  return (
    <IonPage id="signup-page">
      <IonContent className="page-content">
        <div className="signup-container">
          <div className="login-logo">
            <img src={logoImg} alt="Ionic logo" />
            <h3>Sign Up</h3>
            <div>Please enter the information about you!</div>
          </div>

          {errorMessage && (
            <IonText color="danger">
              <p className="ion-padding-start">{errorMessage}</p>
            </IonText>
          )}

          <form noValidate onSubmit={signup}>
            <IonItem lines="none">
              <IonSelect
                placeholder="Please Select Role..."
                value={selectedRole}
                onIonChange={(e): void => setSelectedRole(e.detail.value)}
              >
                <IonSelectOption value="customer">Customer</IonSelectOption>
                <IonSelectOption value="provider">Provider</IonSelectOption>
                <IonSelectOption value="clinic-admin">Clinic</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem lines="none" className="input">
              <PhonenumberInput
                value={phone}
                onChange={(value) => setPhone(value)}
              />
            </IonItem>
            <IonInput
              name="name"
              placeholder={selectedRole === 'clinic-admin' ? 'Full Name' : 'First Name'}
              type="text"
              value={name}
              spellCheck={false}
              autocapitalize="off"
              onIonChange={(e): void => {
                setName(e.detail.value || '');
              }}
              required
            />
            {selectedRole !== 'clinic-admin' ? (
              <IonInput
                name="lastName"
                placeholder="Last Name"
                type="text"
                value={lastName}
                spellCheck={false}
                autocapitalize="off"
                onIonChange={(e): void => {
                  setLastName(e.detail.value || '');
                }}
                required
              />
            ) : null}
            <div className="password-input">
              <IonInput
                name="password"
                placeholder="Password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onIonChange={(e): void => {
                  setPassword(e.detail.value || '');
                  setNoMatched(false);
                }}
                required
              />
              <IonIcon icon={showPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowPassword(!showPassword)} />
            </div>
            <div className="password-input">
              <IonInput
                name="confirmPassword"
                placeholder="Confirm Password"
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onIonChange={(e): void => {
                  setConfirmPassword(e.detail.value || '');
                  setNoMatched(false);
                }}
                required
              />
              <IonIcon icon={showConfirmPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
            </div>
            {noMatched && (
              <IonText color="danger">
                <p className="ion-padding-start">Password is not matched.</p>
              </IonText>
            )}

            <IonRow>
              <IonCol>
                <button className="submit-button" type="submit" disabled={disabledSubmit}>
                  SignUp
                </button>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="signin">
                Already have an account?
                <IonButton
                  routerLink="/login"
                  color="favorite"
                  expand="block"
                  fill="clear"
                >
                  Sign In
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={(): void => setShowAlert(false)}
          header="Thank you for registering on Care Platform"
          message="Please login and verify your phone number."
          buttons={[
            {
              text: 'Login',
              handler: (): void => {
                history.push('/login');
              }
            }
          ]}
        />

        <IonAlert
          isOpen={failAlert}
          onDidDismiss={(): void => setFailAlert(false)}
          header="Care Platform"
          message="Phone number is already registered. If you have forgotten your password, please reset."
          buttons={[
            {
              text: 'Login',
              handler: (): void => {
                history.push('/login');
              }
            }
          ]}
        />
        <IonLoading isOpen={loading} />
      </IonContent>
    </IonPage>
  );
};

Signup.propTypes = {
  loading: PropTypes.bool.isRequired,
  loggedin: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
  register: PropTypes.func.isRequired,
  resetErrorMessage: PropTypes.func.isRequired
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    loading: state.auth.loading,
    loggedin: !!state.auth.user.phone,
    role: state.auth.user.role,
    errorMessage: state.auth.errorMessage
  }),
  mapDispatchToProps: {
    register: dispatchRegister,
    resetErrorMessage: dispatchResetErrorMessage
  },
  component: Signup
});
