import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  IonItem,
  IonLabel,
  IonButton,
  IonAlert,
  IonSpinner,
  IonModal,
  IonRadioGroup,
  IonTextarea,
  IonRadio,
  IonList
} from '@ionic/react';
import moment from 'moment';
import axios from 'axios';

import Avatar from './Avatar';
import {
  AppointmentType, Booking, BookingStatus, BookingStatusLabels
} from '../models';
import {
  getReadyAppointment,
  getRecords,
  updateBooking,
  startMeeting,
  exportBookingReceipt,
  socket
} from '../data/dataApi';
import { SOCKET_KEYS } from '../data/constants';

interface CustomerBookingItemProps {
  booking: Booking
  currentDate: Date
  onUpdate?: (booking: Booking) => void
  onMeeting?: () => void
}

const CustomerBookingItem: React.FC<CustomerBookingItemProps> = ({
  booking,
  currentDate,
  onUpdate,
  onMeeting
}) => {
  const history = useHistory();
  const [showCancelAlert, setShowCancelAlert] = useState(false);
  const [showRejectAlert, setShowRejectAlert] = useState(false);
  const [showInProgressAlert, setShowInProgressAlert] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelledReason, setShowCancelledReason] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [showReasonsModal, setShowReasonsModal] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    open: false,
    message: ''
  });

  const downloadRecords = async () => {
    if (booking.hasDownloadFile) {
      setIsDownloading(true);
      try {
        const { records } = await getRecords(booking.id);
        if (records && records.length > 0) {
          const promises: any[] = [];
          records.map((record: any) => {
            promises.push(
              axios.get(record.signedUrl, { responseType: 'blob' })
                .then((res) => {
                  // Creating new object of PDF file
                  const fileURL = window.URL.createObjectURL(res.data);
                  // Setting various property values
                  let alink = document.createElement('a');
                  alink.href = fileURL;
                  alink.download = record.filename;
                  alink.click();
                })
                .catch((err) => console.log(err))
            );
          });
          await Promise.all(promises);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsDownloading(false);
      }
    } else {
      setShowInProgressAlert(true);
    }
  };

  const onCancel = () => {
    setIsLoading(true);
    const reason = selectedReason === 'other' ? otherReason : 'No available at that time';
    const updateStatus: BookingStatus = booking.status === BookingStatus.PENDING ? BookingStatus.REJECTED_CUSTOMER : BookingStatus.CANCELLED_CUSTOMER;
    updateBooking(booking.id, updateStatus, reason)
      .then((res: { success: boolean }) => {
        if (res.success) {
          onUpdate?.({ ...booking, status: updateStatus });
          socket.emit(SOCKET_KEYS[updateStatus], booking.id, reason);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onReject = () => {
    setIsLoading(true);
    updateBooking(booking.id, BookingStatus.REJECTED_CUSTOMER)
      .then((res: { success: boolean }) => {
        if (res.success) {
          onUpdate?.({ ...booking, status: BookingStatus.REJECTED_CUSTOMER });
          socket.emit(SOCKET_KEYS['rejected-customer'], booking.id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onStartMeeting = () => {
    startMeeting(booking.id, false)
      .then((res: { success: boolean, booking: Booking }) => {
        if (res.success) {
          onUpdate?.({
            ...booking,
            customerConnected: true,
            providerConnected: true,
            callStartedAt: res.booking.callStartedAt
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setErrorAlert({
            open: true,
            message: 'Provider is not ready yet'
          });
        }
      });
  };

  const onReadyMeeting = async () => {
    try {
      await getReadyAppointment(booking.id);
      if (booking.type === AppointmentType.PROVIDER_LOCATION) {
        socket.emit(SOCKET_KEYS['meeting-customer'], booking.id);
      }
      onUpdate?.({ ...booking, customerConnected: true });
    } catch (error) {
      console.log(error);
    }
  };

  const getBookingReceiptPDF = async () => {
    const url = await exportBookingReceipt(booking.id);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Booking_Receipt_${booking.id}.pdf`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  };

  const callEnabled = useMemo(() => {
    const minsRemaining = moment.utc(`${booking.date} ${booking.time}`).diff(moment(currentDate).utc(), 'minutes');
    if (minsRemaining > -1 * booking.duration && minsRemaining < 5) {
      return true;
    }
    return false;
  }, [currentDate, booking]);

  const cancelButtonDisabled = useMemo(() => {
    if (!selectedReason || (selectedReason === 'other' && !otherReason)) {
      return true;
    }
    return false;
  }, [selectedReason, otherReason]);

  const cancelAlert = useMemo(() => {
    const daysOffset = moment.utc(`${booking.date} ${booking.time}`).diff(moment().startOf('day').utc(), 'hours');

    let message = `Do you really want to cancel booking with ${`${booking.provider.name} ${booking.provider.lastName ?? ''}`} on ${booking.date}?`;
    if (daysOffset <= 24 && booking.status === BookingStatus.ACCEPTED) {
      message = 'Cancelling your appointment within one day of the scheduled appointment, will result in being charged the entire amount, as per our policies and terms & conditions';
    } else if (daysOffset <= 48 && booking.status === BookingStatus.ACCEPTED) {
      message = 'Cancelling your appointment one day prior to the scheduled appointment will result in being charged 50% of the appointment amount, as per our policies and terms & conditions';
    }
    return {
      message,
      hasTermsButton: booking.status === BookingStatus.ACCEPTED && daysOffset <= 48
    };
  }, [booking]);

  const appointmentType = useMemo(() => {
    switch (booking.type) {
      case 'video-visit':
        return 'Virtual';
      case 'provider-visit':
        return 'Provider Location';
      case 'customer-visit':
        return 'Customer Location';
      default:
        return 'N|A';
    }
  }, [booking.type]);

  const renderActions = (): JSX.Element => {
    switch (booking.status) {
      case BookingStatus.ACCEPTED:
        return (
          <>
            {booking.type === AppointmentType.VIRTUAL ? (
              <IonButton
                color="favorite"
                expand="block"
                disabled={!callEnabled}
                onClick={(): void => onMeeting?.()}
              >
                Call
              </IonButton>
            ) : null}
            {booking.type === AppointmentType.PROVIDER_LOCATION && !booking.customerConnected ? (
              <IonButton
                color="favorite"
                expand="block"
                disabled={!callEnabled}
                onClick={onReadyMeeting}
              >
                Arrive
              </IonButton>
            ) : null}
            {booking.type === AppointmentType.CUSTOMER_LOCATION && !booking.customerConnected ? (
              <IonButton
                color="favorite"
                expand="block"
                disabled={!callEnabled}
                onClick={onReadyMeeting}
              >
                Ready
              </IonButton>
            ) : null}
            {booking.type === AppointmentType.CUSTOMER_LOCATION && booking.customerConnected && !booking.callStartedAt ? (
              <IonButton
                color="favorite"
                expand="block"
                disabled={!callEnabled}
                onClick={onStartMeeting}
              >
                Start
              </IonButton>
            ) : null}
            <IonButton
              color="dark"
              fill="outline"
              expand="block"
              disabled={booking.customerConnected && booking.providerConnected && !!booking.callStartedAt}
              onClick={(): void => setShowReasonsModal(true)}
            >
              Cancel
            </IonButton>
          </>
        );
      case BookingStatus.PENDING:
        return (
          <>
            <IonButton
              color="dark"
              fill="outline"
              expand="block"
              onClick={(): void => setShowReasonsModal(true)}
            >
              Cancel
            </IonButton>
          </>
        );
      case BookingStatus.COMPLETED:
        return (
          <>
            <IonButton
              color="favorite"
              routerLink={`/customer/provider/${booking.provider.id}`}
            >
              Book Again
            </IonButton>
            <IonButton
              color="favorite"
              fill="outline"
              expand="block"
              routerLink={`/customer/rate-booking/${booking.id}`}
            >
              Review
            </IonButton>
            <IonButton
              color="favorite"
              expand="block"
              onClick={getBookingReceiptPDF}
            >
              Receipt
            </IonButton>
            {booking.type === AppointmentType.VIRTUAL ? (
              <IonButton
                color="favorite"
                fill="outline"
                expand="block"
                onClick={downloadRecords}
              >
                {isDownloading ? <IonSpinner /> : 'Call Record'}
              </IonButton>
            ) : null}
          </>
        );
      case BookingStatus.CANCELLED_PROVIDER:
      case BookingStatus.REJECTED_PROVIDER:
        return (
          <>
            <IonButton
              color="favorite"
              expand="block"
              onClick={getBookingReceiptPDF}
            >
              Receipt
            </IonButton>
            <IonButton
              color="favorite"
              fill="outline"
              expand="block"
              onClick={() => setShowCancelledReason(true)}
            >
              Reason
            </IonButton>
          </>
        );
      default:
        return (
          <>
            <IonButton
              color="favorite"
              expand="block"
              onClick={getBookingReceiptPDF}
            >
              Receipt
            </IonButton>
          </>
        );
    }
  };

  return (
    <div className="booking">
      <IonItem lines="none" className="content">
        <Avatar user={booking.provider} />
        <IonLabel>
          <h2>{`${booking.provider.name} ${booking.provider.lastName ?? ''}`}</h2>
          <p>{`${booking.service} - ${booking.duration}min - $${booking.price}`}</p>
        </IonLabel>
        <p className="status">
          <span className={booking.type}>
            {appointmentType}
          </span>
          {BookingStatusLabels[booking.status]}
        </p>
      </IonItem>
      <IonItem lines="none" className="footer">
        <p className="date">{moment.utc(`${booking.date} ${booking.time}`).local().format('MMM DD, YYYY hh:mm A')}</p>
        <div slot="end" className="actions">{renderActions()}</div>
      </IonItem>
      <IonAlert
        cssClass="cancel-alert"
        isOpen={showCancelAlert}
        onDidDismiss={(): void => {
          setShowCancelAlert(false);
        }}
        header="Care Platform"
        message={cancelAlert.message}
        buttons={cancelAlert.hasTermsButton ? [
          {
            text: 'No',
            cssClass: 'cancel-button',
          },
          {
            text: 'Yes',
            cssClass: 'confirm-button',
            handler: (): void => onCancel()
          },
          {
            text: 'Terms and Conditions',
            handler: (): void => history.push('/terms')
          }
        ] : [
          {
            text: 'No',
            cssClass: 'cancel-button',
          },
          {
            text: 'Yes',
            cssClass: 'confirm-button',
            handler: (): void => onCancel()
          }
        ]}
      />
      <IonAlert
        isOpen={showInProgressAlert}
        onDidDismiss={(): void => setShowInProgressAlert(false)}
        header="Care Platform"
        message="Call record is being prepared, please allow up to 60 minutes from the end call time for the files to be available for download."
        buttons={['OK']}
      />
      <IonAlert
        isOpen={showRejectAlert}
        onDidDismiss={(): void => setShowRejectAlert(false)}
        header="Care Platform"
        message={`Do you really want to cancel booking with ${booking.provider.name} on ${booking.date}?`}
        buttons={[
          {
            text: 'No',
            cssClass: 'cancel-button',
          },
          {
            text: 'Yes',
            cssClass: 'confirm-button',
            handler: (): void => onReject()
          }
        ]}
      />
      <IonAlert
        isOpen={showCancelledReason}
        onDidDismiss={(): void => setShowCancelledReason(false)}
        header="CarePlatform"
        message={booking.cancelledReason}
        buttons={['OK']}
      />
      <IonAlert
        isOpen={errorAlert.open}
        onDidDismiss={(): void => setErrorAlert({ open: false, message: '' })}
        header="CarePlatform"
        message={errorAlert.message}
        buttons={['OK']}
      />
      <IonModal
        cssClass="reasons-modal"
        isOpen={showReasonsModal}
        onDidDismiss={() => {
          setOtherReason('');
          setSelectedReason('');
          setShowReasonsModal(false);
        }}
      >
        <IonLabel>Select Reason</IonLabel>
        <div id="modal">
          <IonList>
            <IonRadioGroup
              value={selectedReason}
              onIonChange={(e) => setSelectedReason(e.detail.value)}
            >
              <IonItem lines="none" className="input">
                <IonLabel>No available at that time</IonLabel>
                <IonRadio slot="end" value="no_available" />
              </IonItem>
              <IonItem lines="none" className="input">
                <IonLabel>Other</IonLabel>
                <IonRadio slot="end" value="other" />
              </IonItem>
            </IonRadioGroup>
            {selectedReason === 'other' ? (
              <IonTextarea
                name="personalReason"
                value={otherReason}
                rows={3}
                placeholder="Please input reason here..."
                onIonChange={(e) => setOtherReason(e.detail.value as string)}
              />
            ) : null}
            <IonItem lines="none">
              <IonButton
                color="favorite"
                disabled={cancelButtonDisabled}
                onClick={onCancel}
              >
                OK
              </IonButton>
            </IonItem>
          </IonList>
        </div>
      </IonModal>
    </div>
  );
};

CustomerBookingItem.defaultProps = {
  onUpdate: (): void => {},
  onMeeting: (): void => {}
};

CustomerBookingItem.propTypes = {
  currentDate: PropTypes.any.isRequired,
  booking: PropTypes.any.isRequired,
  onUpdate: PropTypes.func,
  onMeeting: PropTypes.func
};

export default CustomerBookingItem;
