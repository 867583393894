import React, {
  useContext, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  IonModal,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonAlert
} from '@ionic/react';
import moment from 'moment';
import Dropzone from 'react-dropzone';

import { User, Identity, verificationMethods } from '../../models';
import { uploadIdentity } from '../../data/dataApi';
import { AppContext } from '../../data/AppContext';

const initIdentity = {
  id: -1,
  type: '',
  country: '',
  firstname: '',
  lastname: '',
  birthdate: '',
  number: '',
  frontImage: '',
  backImage: '',
};

interface IdentityProps {
  user: User,
  isOpen: boolean
  toggleModal: (value: boolean) => void
}

const IdentityModal: React.FC<IdentityProps> = ({
  user, isOpen, toggleModal
}) => {
  const [identity, setIdentity] = useState<Identity>(initIdentity);
  const [imageType, setImageType] = useState<'front' | 'back'>('front');
  const [frontPhoto, setFrontPhoto] = useState<File | null>(null);
  const [backPhoto, setBackPhoto] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const { dispatch } = useContext(AppContext);

  const onDismissModal = () => {
    toggleModal(false);
  };

  const onSubmit = () => {
    const {
      type,
      country,
      firstname,
      lastname,
      birthdate,
      number
    } = identity;
    const data = new FormData();
    data.append('type', type);
    data.append('country', country);
    data.append('firstname', firstname);
    data.append('lastname', lastname);
    data.append('birthdate', birthdate);
    data.append('number', number);
    if (frontPhoto && backPhoto) {
      data.append('attachments', frontPhoto);
      data.append('attachments', backPhoto);
    }
    setUploading(true);
    uploadIdentity(data)
      .then((res) => {
        dispatch({
          type: '@user/set-user',
          data: { ...user, identity: res.identity }
        });
        setShowSuccessAlert(true);
      })
      .finally(() => setUploading(false));
  };

  const availableTypes = useMemo(() => {
    if (identity.country) {
      return verificationMethods.filter((item) => item.countries.includes(identity.country));
    }
    return null;
  }, [identity.country]);

  const isSubmitDisabled = useMemo(() => {
    const {
      type,
      country,
      firstname,
      lastname,
      birthdate,
      number,
      frontImage,
      backImage
    } = identity;
    if (!type || !country || !firstname || !lastname || !birthdate || !number || !frontImage || !backImage) {
      return true;
    }
    return false;
  }, [identity]);

  return (
    <IonModal
      cssClass="identity-modal"
      isOpen={isOpen}
      onDidDismiss={onDismissModal}
    >
      <IonLabel>ID Verification</IonLabel>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonLabel className="field-title">First Name</IonLabel>
            <IonItem lines="none" className="input">
              <IonInput
                name="firstname"
                type="text"
                value={identity.firstname}
                onIonChange={(e): void => setIdentity({ ...identity, firstname: e.detail.value ?? '' })}
              />
            </IonItem>
          </IonCol>
          <IonCol size="12">
            <IonLabel className="field-title">Last Name</IonLabel>
            <IonItem lines="none" className="input">
              <IonInput
                name="lastname"
                type="text"
                value={identity.lastname}
                onIonChange={(e): void => setIdentity({ ...identity, lastname: e.detail.value ?? '' })}
              />
            </IonItem>
          </IonCol>
          <IonCol size="12">
            <IonLabel className="field-title">Birthday</IonLabel>
            <IonItem lines="none" className="input">
              <IonDatetime
                max="2100"
                displayFormat="MMMM DD YYYY"
                pickerFormat="YYYY MMMM DD"
                value={identity.birthdate ? moment(identity.birthdate).toLocaleString() : null}
                onIonChange={(event): void => setIdentity({ ...identity, birthdate: moment(event.detail.value || '').format('YYYY-MM-DD') })}
              />
            </IonItem>
          </IonCol>
          <IonCol size="6">
            <IonLabel className="field-title">Country</IonLabel>
            <IonItem lines="none" className="select">
              <IonSelect
                className="select"
                placeholder="Select Country..."
                value={identity.country}
                onIonChange={(e): void => setIdentity({ ...identity, country: String(e.detail.value) })}
              >
                <IonSelectOption value="us">United State</IonSelectOption>
                <IonSelectOption value="ca">Canada</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size="6">
            <IonLabel className="field-title">ID Type</IonLabel>
            <IonItem lines="none" className="select">
              <IonSelect
                className="select"
                placeholder="Select Type..."
                value={identity.type}
                disabled={!availableTypes}
                onIonChange={(e): void => setIdentity({ ...identity, type: String(e.detail.value) })}
              >
                {availableTypes?.map((type, index) => (
                  <IonSelectOption key={index} value={type.name}>
                    {type.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size="12">
            <IonLabel className="field-title">ID Number</IonLabel>
            <IonItem lines="none" className="input">
              <IonInput
                name="number"
                type="text"
                value={identity.number}
                onIonChange={(e): void => setIdentity({ ...identity, number: e.detail.value ?? '' })}
              />
            </IonItem>
          </IonCol>
          <IonCol size="6">
            <Dropzone
              noClick
              onDrop={(files) => {
                if (files.length) {
                  setIdentity({ ...identity, frontImage: files[0].name });
                  setFrontPhoto(files[0]);
                }
              }}
              accept={{
                'image/*': ['.jpeg', '.png']
              }}
            >
              {({ getRootProps }) => (
                <section>
                  <div className="image-container-header" {...getRootProps()}>
                    <IonLabel className="field-title">Front Photo</IonLabel>
                    <IonButton
                      className="add-file"
                      color="favorite"
                      fill="clear"
                      onClick={() => {
                        setImageType('front');
                        inputRef.current?.click();
                      }}
                    >
                      {identity.frontImage ? 'Change Photo' : 'Add Photo'}
                    </IonButton>
                  </div>
                  <div className="image-container" {...getRootProps()}>
                    {frontPhoto ? (
                      <img src={URL.createObjectURL(frontPhoto)} alt="front" />
                    ) : (
                      <div>
                        Drag & Drop Image File Here
                        <br />
                        <span>(JPEG, PNG)</span>
                      </div>
                    )}
                    <IonLabel slot="start">{identity.frontImage || 'No Photo'}</IonLabel>
                  </div>
                </section>
              )}
            </Dropzone>
          </IonCol>
          <IonCol size="6">
            <Dropzone
              noClick
              onDrop={(files) => {
                if (files.length) {
                  setIdentity({ ...identity, backImage: files[0].name });
                  setBackPhoto(files[0]);
                }
              }}
              accept={{
                'image/*': ['.jpeg', '.png']
              }}
            >
              {({ getRootProps }) => (
                <section>
                  <div className="image-container-header" {...getRootProps()}>
                    <IonLabel className="field-title">Back Photo</IonLabel>
                    <IonButton
                      className="add-file"
                      color="favorite"
                      fill="clear"
                      onClick={() => {
                        setImageType('back');
                        inputRef.current?.click();
                      }}
                    >
                      {identity.backImage ? 'Change Photo' : 'Add Photo'}
                    </IonButton>
                  </div>
                  <div className="image-container" {...getRootProps()}>
                    {backPhoto ? (
                      <img src={URL.createObjectURL(backPhoto)} alt="back" />
                    ) : (
                      <div>
                        Drag & Drop Image File Here
                        <br />
                        <span>(JPEG, PNG)</span>
                      </div>
                    )}
                    <IonLabel>{identity.backImage || 'No Photo'}</IonLabel>
                  </div>
                </section>
              )}
            </Dropzone>
          </IonCol>
          <IonCol size="12">
            <IonItem lines="none">
              <IonButton color="favorite" disabled={isSubmitDisabled} onClick={onSubmit}>
                Submit
              </IonButton>
            </IonItem>
          </IonCol>
          <input
            ref={inputRef}
            type="file"
            id="myfile"
            name="myfile"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              if (e.target.files) {
                if (imageType === 'front') {
                  setIdentity({ ...identity, frontImage: e.target.files[0].name });
                  setFrontPhoto(e.target.files[0] as any);
                } else {
                  setIdentity({ ...identity, backImage: e.target.files[0].name });
                  setBackPhoto(e.target.files[0] as any);
                }
              }
            }}
          />
        </IonRow>
      </IonGrid>
      <IonLoading isOpen={uploading} />

      <IonAlert
        isOpen={showSuccessAlert}
        onDidDismiss={(): void => {
          toggleModal(false);
          setShowSuccessAlert(false);
        }}
        header="Care Platform"
        message="Your informations for verification has been submitted successfully. It will take some time to identify."
        buttons={['Ok']}
      />
    </IonModal>
  );
};

IdentityModal.propTypes = {
  user: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default React.memo(IdentityModal);
