import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { User } from '../models';
import { StatusContext } from '../data/StatusContext';

interface StatusIndicatorProps {
  user: User
  size?: string
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ user, size = 'sm' }) => {
  const { syncList } = useContext(StatusContext);
  const [status, setStatus] = useState('offline');

  const checkStatus = (isRemoved: boolean, args: any) => {
    if (user.name === args?.item?.data?.name && !isRemoved) {
      setStatus(args?.item?.data?.status);
    }
    if (user.name === args?.previousItemData?.name && isRemoved) {
      setStatus('offline');
    }
  };

  useEffect(() => {
    if (syncList && user) {
      if (user.syncId > -1) {
        syncList.get(user.syncId)
          .then((res: any) => setStatus(res?.data?.status))
          .catch(() => setStatus('offline'));
      }
      syncList.on('itemAdded', (args) => checkStatus(false, args));
      syncList.on('itemUpdated', (args) => checkStatus(false, args));
      syncList.on('itemRemoved', (args) => checkStatus(true, args));
    }
  }, [syncList, user.syncId]);

  return <span className={`status-${status} ${size}`} title={status.charAt(0).toUpperCase() + status.slice(1)} />;
};

StatusIndicator.defaultProps = {
  size: 'sm'
};

StatusIndicator.propTypes = {
  user: PropTypes.any.isRequired,
  size: PropTypes.string
};

export default StatusIndicator;
