import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonLoading,
  IonAlert,
  IonSlides,
  IonSlide,
  IonIcon,
  IonText,
} from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import OtpInput from 'react-otp-input';

import './ForgetPassword.scss';
import { forgetPassword, resetForgetPassword, verify } from '../../data/dataApi';
import connect from '../../data/connect';

import logoImg from '../../assets/logo.png';

type ForgetPasswordProps = RouteComponentProps;

const slideOpts = {
  allowTouchMove: false,
  initialSlide: 0,
  speed: 400,
};

const ForgetPassword: React.FC<ForgetPasswordProps> = ({ history }) => {
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [otp, setOtp] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [seconds, setSeconds] = useState(60);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [noMatched, setNoMatched] = useState(false);

  const mySlides = useRef<any>(null);

  const nextSlide = async () => {
    await mySlides?.current?.slideNext();
    const idx = await mySlides?.current?.getActiveIndex();
    setCurrentIndex(idx);
  };

  const onForgetPassword = (e: React.FormEvent): void => {
    e.preventDefault();
    if (!phone) {
      return;
    }
    setLoading(true);
    forgetPassword({ phone: `+1${phone}` })
      .then((res) => {
        if (res.success) {
          nextSlide();
        }
      })
      .catch((err) => {
        if (err?.response?.data?.msg) {
          setError(err?.response?.data?.msg);
        }
      })
      .finally(() => setLoading(false));
  };

  const onVerifyCode = (): void => {
    setLoading(true);
    verify(`+1${phone}`, otp)
      .then((res) => {
        if (res.success) {
          nextSlide();
        }
      })
      .finally(() => setLoading(false));
  };

  const onResetPassword = () => {
    if (newPassword !== confirmPassword) {
      return setNoMatched(true);
    }
    setLoading(true);
    return resetForgetPassword({
      phone: `+1${phone}`,
      password: newPassword,
    })
      .then((res) => {
        if (res.success) {
          setSuccess(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const description = useMemo(() => {
    switch (currentIndex) {
      case 0:
        return 'Please enter your phone number. You will receive an SMS to reset your password.';
        break;
      case 1:
        return `We’ve sent confirmation password on you +1${phone} number`;
        break;
      default:
        return 'Please enter the new password';
        break;
    }
  }, [currentIndex]);

  useEffect(() => {
    if (seconds > 0 && currentIndex === 1) {
      const timer = setInterval(() => setSeconds(seconds - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [seconds, currentIndex]);

  return (
    <IonPage id="forget-password-page">
      <IonContent className="page-content">
        <div className="forget-password-container">
          <div className="login-logo">
            <img src={logoImg} alt="Ionic logo" />
            <h3>Forgot Password</h3>
            <div>
              {description}
            </div>
          </div>
          <IonSlides ref={mySlides} pager={false} options={slideOpts}>
            <IonSlide>
              <form noValidate onSubmit={onForgetPassword}>
                <IonInput
                  name="phone"
                  placeholder="Phone Number"
                  type="tel"
                  value={phone}
                  spellCheck={false}
                  autocapitalize="off"
                  inputMode="tel"
                  maxlength={10}
                  onIonChange={(e): void => {
                    setPhone(e.detail.value || '');
                  }}
                  required
                />

                <IonRow>
                  <IonCol>
                    <IonButton
                      routerLink="/login"
                      color="dark"
                      expand="block"
                      fill="outline"
                    >
                      Cancel
                    </IonButton>
                  </IonCol>
                  <IonCol>
                    <IonButton
                      color="favorite"
                      type="submit"
                      expand="block"
                      disabled={loading}
                    >
                      Send
                    </IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </IonSlide>
            <IonSlide>
              <div className="slide-content">
                <OtpInput
                  className="otp-input"
                  value={otp}
                  numInputs={6}
                  onChange={(value: string) => setOtp(value)}
                />
                <IonButton
                  color="favorite"
                  expand="block"
                  disabled={loading || seconds <= 0}
                  onClick={onVerifyCode}
                >
                  Confirm
                </IonButton>
                <div className="code-resend">
                  {seconds > 0 ? `00 : ${String(seconds).padStart(2, '0')}` : 'Your verification code was expired.'}
                </div>
              </div>
            </IonSlide>
            <IonSlide>
              <div className="slide-content">
                <div className="password-input">
                  <IonInput
                    name="newPassword"
                    placeholder="New Password"
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPassword}
                    onIonChange={(e): void => {
                      setNewPassword(e.detail.value || '');
                    }}
                    required
                  />
                  <IonIcon icon={showNewPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowNewPassword(!showNewPassword)} />
                </div>
                <div className="password-input">
                  <IonInput
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={confirmPassword}
                    onIonChange={(e): void => {
                      setConfirmPassword(e.detail.value || '');
                      setNoMatched(false);
                    }}
                    required
                  />
                  <IonIcon icon={showConfirmPassword ? eyeOffOutline : eyeOutline} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                </div>

                {noMatched && (
                  <IonText color="danger">
                    <p className="ion-padding-start">Password is not matched.</p>
                  </IonText>
                )}
                <IonButton
                  className="save-button"
                  color="favorite"
                  type="submit"
                  expand="block"
                  disabled={loading}
                  onClick={onResetPassword}
                >
                  Save
                </IonButton>
              </div>
            </IonSlide>
          </IonSlides>

          {/* Success Alert */}
          <IonAlert
            isOpen={success}
            onDidDismiss={(): void => {
              setSuccess(false);
              history.push('/login');
            }}
            header="CAREPLATFORM"
            message="Your password has been updated successfully."
            buttons={['Ok']}
          />

          {/* Error Alert */}
          <IonAlert
            isOpen={!!error}
            onDidDismiss={(): void => {
              setError('');
            }}
            header="CAREPLATFORM"
            message={error}
            buttons={['Ok']}
          />
        </div>
        <IonLoading isOpen={loading} />
      </IonContent>
    </IonPage>
  );
};

ForgetPassword.propTypes = {
  history: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, {}, {}>({
  mapStateToProps: () => ({}),
  component: ForgetPassword
});
