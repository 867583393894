import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonButton,
  IonListHeader,
  IonItem,
  IonLabel,
  IonImg,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';
import slugify from 'slugify';
import moment from 'moment';

import connect from '../../data/connect';
import { Booking, BookingStatus, ProviderType } from '../../models';
import CustomerBookingItem from '../../components/CustomerBookingItem';
import { getImage, fetchBookings, socket } from '../../data/dataApi';

import './CustomerDashboard.scss';

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface StateProps {
  providerTypes: ProviderType[]
}

type CustomerDashboardProps = RouteComponentProps & StateProps;

const CustomerDashboard: React.FC<CustomerDashboardProps> = ({
  history,
  providerTypes
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());

  const fetchMore = () => {
    setIsLoading(true);
    const data = {
      skip: bookings.length,
      status: 'accepted',
    };
    fetchBookings(data)
      .then((res: { success: boolean, bookings: Booking[] }) => {
        if (res.success) {
          setBookings([...bookings, ...res.bookings]);
          if (res.bookings.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  const onUpdateBooking = (booking: Booking) => {
    if (booking.status === BookingStatus.ACCEPTED) {
      const updatedBookings: Booking[] = bookings.map((bk) => {
        if (bk.id === booking.id) {
          return booking;
        }
        return bk;
      });
      setBookings([...updatedBookings]);
    } else {
      const updatedBookings = bookings.filter((bk) => bk.id !== booking.id);
      setBookings([...updatedBookings]);
    }
  };

  const availableBookings = useMemo(() => bookings
    .sort((a, b) => {
      const diff = moment(`${a.date} ${a.time}`).diff(moment(`${b.date} ${b.time}`));
      if (diff < 0) {
        return -1;
      }
      return 1;
    })
    .filter((booking) => moment.utc(`${booking.date} ${booking.time}`).diff(moment.utc(), 'minutes') >= -1 * booking.duration), [bookings]);

  const categoriesTitle = useMemo(() => {
    if (showSubCategories) {
      return selectedCategory;
    }
    return 'Categories';
  }, [showSubCategories, selectedCategory]);

  useEffect(() => {
    fetchMore();
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);

    socket.on('start-meeting', (e: Booking) => {
      fetchMore();
    });

    return () => clearInterval(interval);
  }, []);

  return (
    <IonContent id="customer-dashboard-page" className="page-content ion-padding">
      <div className="custom-dashboard-header">
        <IonListHeader>
          {categoriesTitle}
        </IonListHeader>
        {showSubCategories ? (
          <IonButton id="customer-dashboard-show-categories-button" color="favorite" fill="clear" onClick={() => setShowSubCategories(false)}>Show Categories</IonButton>
        ) : null}
      </div>

      <IonGrid fixed>
        <IonRow>
          {providerTypes.map((providerType) => (
            <IonCol size="4" key={providerType.id}>
              <IonItem
                id={`customer-dashboard-provider-type-${slugify(providerType.name).toLowerCase()}`}
                lines="none"
                className="list-item round-border main-list-item"
                onClick={() => history.push(`/customer/dashboard/category/${providerType.id}`)}
              >
                <IonImg slot="start" src={getImage(providerType.logo)} />
                <IonLabel>{providerType.name}</IonLabel>
              </IonItem>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      <IonListHeader className="upcoming">Upcoming Appointments</IonListHeader>
      <IonGrid>
        <IonRow>
          {availableBookings.map((booking) => (
            <IonCol size="4" key={booking.id}>
              <CustomerBookingItem
                currentDate={currentDate}
                booking={booking}
                onUpdate={onUpdateBooking}
                onMeeting={(): void => history.push(`/video/${booking.id}`)}
              />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      {isFetchedAll ? null : (
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            fetchMore();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent
            loadingText="Please wait..."
            loadingSpinner="circles"
          />
        </IonInfiniteScroll>
      )}

      <IonLoading isOpen={isLoading} />
    </IonContent>
  );
};

CustomerDashboard.propTypes = {
  history: PropTypes.any.isRequired,
  providerTypes: PropTypes.array.isRequired
};

export default connect<RouteComponentProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes
  }),
  component: React.memo(CustomerDashboard)
});
