import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonList,
  IonItem,
  IonButton,
  IonInput,
  IonAlert,
  IonLoading,
  IonListHeader
} from '@ionic/react';

import PhonenumberInput from '../../components/PhonenumberInput';
import connect from '../../data/connect';
import { createClinicMember } from '../../data/dataApi';
import { dispatchFetchClinicMembers } from '../../data/store/data/data.actions';
import { UserRole, User } from '../../models';

import './ClinicAddMember.scss';

interface StateProps {
  user: User
}

interface DispatchProps {
  fetchClinicMembers: typeof dispatchFetchClinicMembers
}

type ClinicAddMemberProps = RouteComponentProps & StateProps & DispatchProps;

const ClinicAddMember: React.FC<ClinicAddMemberProps> = ({
  user,
  history,
  fetchClinicMembers
}) => {
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showFailedAlert, setShowFailedAlert] = useState(false);

  const onAdd = (): void => {
    setLoading(true);
    createClinicMember({
      phone: `+1${phone}`,
      name,
      email,
      role: UserRole.CLINIC_MEMBER
    })
      .then((res) => {
        if (res.success) {
          fetchClinicMembers(user.id);
          history.goBack();
        } else {
          setShowFailedAlert(true);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <IonContent id="clinic-add-member" className="page-content ion-padding">
      <IonListHeader className="page-header">Add Member</IonListHeader>
      <IonList>
        <IonItem lines="none" className="input">
          <PhonenumberInput
            isRequired
            value={phone}
            onChange={(value) => setPhone(value)}
          />
        </IonItem>
        <IonItem lines="none">
          <IonInput
            name="name"
            type="text"
            placeholder="* Name"
            value={name}
            onIonChange={(e): void => {
              setName(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none">
          <IonInput
            name="email"
            type="text"
            placeholder="* Email"
            value={email}
            onIonChange={(e): void => {
              setEmail(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonButton
          color="favorite"
          expand="block"
          disabled={!phone || !name || !email}
          onClick={(): void => onAdd()}
        >
          Add
        </IonButton>
      </IonList>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={(): void => {
          setShowAlert(false);
        }}
        header="Care Platform"
        message="New user is added successfully."
        buttons={['Ok']}
      />

      <IonAlert
        isOpen={showFailedAlert}
        onDidDismiss={(): void => setShowFailedAlert(false)}
        header="CarePlatform"
        message="Phone number already exists."
        buttons={['Ok']}
      />
      <IonLoading isOpen={loading} />
    </IonContent>
  );
};

ClinicAddMember.propTypes = {
  user: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  fetchClinicMembers: PropTypes.func.isRequired
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {
    fetchClinicMembers: dispatchFetchClinicMembers
  },
  component: React.memo(ClinicAddMember)
});
