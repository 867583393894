import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonPage,
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonLoading,
  IonAlert
} from '@ionic/react';

import './ResetForgetPassword.scss';
import { resetForgetPassword } from '../../data/dataApi';
import connect from '../../data/connect';

interface MatchParams {
  phone: string
}

type ResetForgetPasswordProps = RouteComponentProps<MatchParams>;

const ResetForgetPassword: React.FC<ResetForgetPasswordProps> = ({
  history,
  match
}) => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);

  const onReset = (e: React.FormEvent): void => {
    e.preventDefault();
    if (!code || !password) {
      return;
    }
    setLoading(true);
    resetForgetPassword({
      phone: `+1${match.params.phone}`,
      code,
      password
    })
      .then((res) => {
        if (res.success) {
          setShowAlert(true);
        } else {
          setShowFailureAlert(true);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <IonPage id="forget-password-page">
      <IonContent>
        <div className="reset-password-container ">
          <div className="login-logo">
            <img src="assets/img/login-logo.svg" alt="logo" />
            <h3>Welcome to Care Platform</h3>
            <div>Please enter the verification code and new password</div>
          </div>

          <form noValidate onSubmit={onReset}>
            <IonInput
              name="code"
              placeholder="Verification Code"
              type="text"
              value={code}
              onIonChange={(e): void => {
                setCode(e.detail.value || '');
              }}
            />

            <IonInput
              name="password"
              placeholder="New Password"
              type="text"
              value={password}
              onIonChange={(e): void => {
                setPassword(e.detail.value || '');
              }}
            />

            <IonRow>
              <IonCol>
                <IonButton
                  routerLink="/login"
                  color="primary"
                  expand="block"
                  fill="outline"
                >
                  Sign In
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  color="primary"
                  type="submit"
                  expand="block"
                  disabled={loading}
                >
                  Reset
                </IonButton>
              </IonCol>
            </IonRow>
          </form>

          <IonAlert
            isOpen={showAlert}
            onDidDismiss={(): void => {
              setShowAlert(false);
              history.push('/login');
            }}
            header="Care Platform"
            message="Password changed successfully."
            buttons={['Ok']}
          />

          <IonAlert
            isOpen={showFailureAlert}
            onDidDismiss={(): void => {
              setShowAlert(false);
            }}
            header="Care Platform"
            message="Please enter correct OTP."
            buttons={['Ok']}
          />
        </div>
        <IonLoading isOpen={loading} />
      </IonContent>
    </IonPage>
  );
};

ResetForgetPassword.propTypes = {
  history: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired
};

export default connect<RouteComponentProps<MatchParams>, {}, {}>({
  mapStateToProps: () => ({}),
  component: ResetForgetPassword
});
