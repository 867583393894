import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonRange,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonSpinner
} from '@ionic/react';
import {
  closeOutline,
  ellipsisHorizontal,
  helpCircle,
  options,
  searchOutline
} from 'ionicons/icons';
import { Range } from 'react-date-range';
import { Country, State, City } from 'country-state-city';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import clsx from 'clsx';
import moment from 'moment';

import {
  activateAccount,
  fetchCustomers,
  inactivateAccount
} from '../../data/dataApi';
import { User, UserStatus } from '../../models';
import Avatar from '../../components/Avatar';
import {
  ArchiveIcon,
  ActiveIcon,
  BlockActionIcon,
  DetailsIcon,
  InactiveIcon,
  TrashIcon,
  UnblockActionIcon
} from '../../icons';

interface UserActionsProps {
  user: User
  updateUser: (user: User) => void
  deleteUser: (id: number) => void
}

const UserActions: React.FC<UserActionsProps> = ({ user, updateUser, deleteUser }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [showInactiveAlert, setShowInactiveAlert] = useState(false);

  const onInactivate = () => {
    setIsLoading(true);
    inactivateAccount(user.id, false)
      .then((res) => {
        if (res.success) {
          updateUser?.({ ...user, status: UserStatus.INACTIVE });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    setIsPopoverOpen(false);
  };

  const onActivate = () => {
    setIsLoading(true);
    activateAccount(user.id)
      .then((res) => {
        if (res.success) {
          updateUser?.({ ...user, status: UserStatus.ACTIVE });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    setIsPopoverOpen(false);
  };

  const onRemove = () => {
    setIsLoading(true);
    inactivateAccount(user.id, true)
      .then((res) => {
        if (res.success) {
          deleteUser?.(user.id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    setIsPopoverOpen(false);
  };

  const onShowDetails = () => {
    history.push(`/admin/report/user/${user.id}`);
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'top', 'left', 'right']}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="#daf3ef"
            arrowSize={10}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className="popover-content">
              {user.status === UserStatus.ACTIVE ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowInactiveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel>
                      <InactiveIcon />
                      Inactivate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.INACTIVE ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={onActivate}>
                    <IonLabel>
                      <ActiveIcon />
                      Activate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.BLOCKED ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <UnblockActionIcon />
                      Unblock
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.ARCHIVED ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={onActivate}>
                    <IonLabel>
                      <ActiveIcon />
                      Activate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.PENDING ? (
                <>
                  {/* <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div> */}
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
            </div>
          </ArrowContainer>
        )}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        {isLoading ? <IonSpinner /> : (
          <span
            className="action"
            title="More actions"
            onClick={(e) => {
              e.stopPropagation();
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            <IonIcon icon={ellipsisHorizontal} />
          </span>
        )}
      </Popover>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={(): void => setShowRemoveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to delete this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onRemove()
          }
        ]}
      />

      <IonAlert
        isOpen={showInactiveAlert}
        onDidDismiss={(): void => setShowInactiveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to inactivate this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onInactivate()
          }
        ]}
      />
    </>
  );
};

UserActions.propTypes = {
  user: PropTypes.any.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired
};

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface CustomerViewProps {
  dateRange: Range | null
}

const CustomersView: React.FC<CustomerViewProps> = ({ dateRange }) => {
  const [segment, setSegment] = useState<'all' | 'active' | 'inactive' | 'archive' | 'blocked'>('all');
  const [searchKey, setSearchKey] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [filterParams, setFilterParams] = useState({
    country: '',
    province: '',
    city: '',
    type: '',
    bookings: {
      min: 0,
      max: 500
    }
  });
  const [tempFilterParams, setTempFilterParams] = useState({
    country: '',
    province: '',
    city: '',
    type: '',
    bookings: {
      min: 0,
      max: 500
    }
  });
  const [customers, setCustomers] = useState<User[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [showTooltipPopover, setShowTooltipPopover] = useState(false);

  const fetchMore = (isNew: boolean) => {
    const params = {
      skip: isNew ? 0 : customers.length,
      status: segment,
      searchKey,
      hasBookingsInfo: true,
      dateRange: dateRange ? [
        moment(dateRange.startDate).toISOString(),
        moment(dateRange.endDate).toISOString()
      ] : null,
      country: filterParams.country,
      state: filterParams.province,
      city: filterParams.city,
      bookingsCntRange: filterParams.bookings,
      orderBy,
      isDescending
    };

    setIsFetching(true);
    fetchCustomers(params)
      .then((res) => {
        if (res.success) {
          if (isNew) {
            setCustomers([...res.customers]);
          } else {
            setCustomers([...customers, ...res.customers]);
          }
          if (!res.customers.length || res.customers.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsFetching(false));
  };

  const onSearch = () => {
    fetchMore(true);
  };

  const onOpenFilter = () => {
    setShowFilter(true);
    setTempFilterParams(filterParams);
  };

  const onDismissFilter = () => {
    setShowFilter(false);
  };

  const onClear = () => {
    setFilterParams({
      country: '',
      province: '',
      city: '',
      type: '',
      bookings: {
        min: 0,
        max: 500
      }
    });
    setShowFilter(false);
  };

  const onApplyFilter = () => {
    setFilterParams(tempFilterParams);
    setShowFilter(false);
  };

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const onDeleteUser = (id: number) => {
    const updatedCustomers = customers.filter((c) => c.id !== id);
    setCustomers([...updatedCustomers]);
  };

  const onUpdateUser = (user: User) => {
    if (segment === 'all') {
      const updatedCustomers = customers.map((c) => {
        if (c.id === user.id) {
          return user;
        }
        return c;
      });
      setCustomers([...updatedCustomers]);
    } else {
      onDeleteUser(user.id);
    }
  };

  const availableStates = useMemo(() => {
    if (tempFilterParams.country) {
      const country = Country.getAllCountries().find((c) => c.name === tempFilterParams.country);
      const states = State.getStatesOfCountry(country?.isoCode);
      return states || [];
    }
    return [];
  }, [tempFilterParams.country]);

  const availableCities = useMemo(() => {
    if (tempFilterParams.country && tempFilterParams.province) {
      const country = Country.getAllCountries().find((c) => c.name === tempFilterParams.country);
      const state = State.getStatesOfCountry(country?.isoCode).find((s) => s.name === tempFilterParams.province);
      if (country && state) {
        const cities = City.getCitiesOfState(country.isoCode, state.isoCode);
        return cities;
      }
    }
    return [];
  }, [tempFilterParams.country, tempFilterParams.province]);

  useEffect(() => {
    fetchMore(true);
  }, [dateRange, segment, orderBy, isDescending, filterParams]);

  useEffect(() => {
    if (!searchKey) {
      fetchMore(true);
    }
  }, [searchKey]);

  return (
    <div className="admin-report-users">
      <div className="sub-header">
        <IonSegment
          mode="ios"
          value={segment}
          onIonChange={(e) => setSegment(e.detail.value as 'all' | 'active' | 'inactive' | 'archive' | 'blocked')}
        >
          <IonSegmentButton value="all">All</IonSegmentButton>
          <IonSegmentButton value="active">Active</IonSegmentButton>
          <IonSegmentButton value="inactive">Inactive</IonSegmentButton>
          <IonSegmentButton value="archive">Archive</IonSegmentButton>
          <IonSegmentButton value="blocked">Blocked</IonSegmentButton>
        </IonSegment>
        <Popover
          isOpen={showTooltipPopover}
          positions={['bottom', 'top', 'left', 'right']}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor="#daf3ef"
              arrowSize={10}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              <div className="popover-content tooltip sm">
                <div
                  className="status-option live"
                  onClick={() => {
                    setSegment('all');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    All
                  </p>
                  <span>
                    All Customers
                  </span>
                </div>
                <div
                  className="status-option completed"
                  onClick={() => {
                    setSegment('active');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Active
                  </p>
                  <span>
                    Customers Who are active now
                  </span>
                </div>
                <div
                  className="status-option inactive"
                  onClick={() => {
                    setSegment('inactive');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Inactive
                  </p>
                  <span>
                    Customers Who are inactive now
                  </span>
                </div>
                <div
                  className="status-option archive"
                  onClick={() => {
                    setSegment('archive');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Archive
                  </p>
                  <span>
                    Customers Who are archived now
                  </span>
                </div>
                <div
                  className="status-option abandoned"
                  onClick={() => {
                    setSegment('blocked');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Blocked
                  </p>
                  <span>
                    Customers Who are blocked now
                  </span>
                </div>
              </div>
            </ArrowContainer>
          )}
          onClickOutside={() => setShowTooltipPopover(false)}
        >
          <span
            className="help-icon"
            onClick={() => setShowTooltipPopover(true)}
          >
            <IonIcon color="favorite" icon={helpCircle} />
          </span>
        </Popover>
        <IonItem className="search-input" lines="none">
          <IonInput
            value={searchKey}
            placeholder="Search by name"
            onKeyPress={(e) => {
              if (e.keyCode === 13 || e.key === 'Enter') {
                onSearch();
              }
            }}
            onIonChange={(e) => {
              setSearchKey(e.detail.value ?? '');
            }}
          />
          <IonIcon size="small" icon={searchOutline} onClick={() => onSearch()} />
          <IonIcon size="small" icon={closeOutline} onClick={() => setSearchKey('')} />
        </IonItem>
        <IonButton color="dark" fill="outline" onClick={onOpenFilter}>
          <IonIcon icon={options} />
          Filter
        </IonButton>
      </div>
      <div className="user-list">
        <IonGrid>
          <IonRow className="table-header">
            <IonCol className="sm">
              NO
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer sm', { isOrderBy: orderBy === 'id' })}
              onClick={() => onChangeOrderBy('id')}
            >
              ID&nbsp;
              {orderBy === 'id' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              size="2.5"
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'name' })}
              onClick={() => onChangeOrderBy('name')}
            >
              NAME AND EMAIL&nbsp;
              {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>CONTACT NO</IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'country' })}
              onClick={() => onChangeOrderBy('country')}
            >
              COUNTRY&nbsp;
              {orderBy === 'country' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'state' })}
              onClick={() => onChangeOrderBy('state')}
            >
              PROVINCE&nbsp;
              {orderBy === 'state' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'city' })}
              onClick={() => onChangeOrderBy('city')}
            >
              CITY&nbsp;
              {orderBy === 'city' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'bookingsCnt' })}
              onClick={() => onChangeOrderBy('bookingsCnt')}
            >
              BOOKINGS&nbsp;
              {orderBy === 'bookingsCnt' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>STATUS</IonCol>
            <IonCol>ACTION</IonCol>
          </IonRow>
          {customers.map((customer, index) => (
            <IonRow key={customer.id} className="table-row">
              <IonCol className="sm">{index + 1}</IonCol>
              <IonCol className="sm">{customer.id}</IonCol>
              <IonCol size="2.5">
                <div className="user-name-email">
                  <Avatar user={customer} />
                  <div>
                    <h4>{`${customer.name} ${customer.lastName ?? ''}`}</h4>
                    <p>{customer.email}</p>
                  </div>
                </div>
              </IonCol>
              <IonCol>{customer.phone}</IonCol>
              <IonCol>{customer.locations?.[0]?.country}</IonCol>
              <IonCol>{customer.locations?.[0]?.state}</IonCol>
              <IonCol>{customer.locations?.[0]?.city}</IonCol>
              <IonCol>{customer?.bookingsCnt || 0}</IonCol>
              <IonCol>
                {customer.status === UserStatus.ACTIVE ? (
                  <div className="status active">Active</div>
                ) : null}
                {customer.status === UserStatus.INACTIVE ? (
                  <div className="status inactive">Inactive</div>
                ) : null}
                {customer.status === UserStatus.BLOCKED ? (
                  <div className="status blocked">Blocked</div>
                ) : null}
                {customer.status === UserStatus.PENDING ? (
                  <div className="status inactive">Pending</div>
                ) : null}
              </IonCol>
              <IonCol>
                <UserActions user={customer} updateUser={onUpdateUser} deleteUser={onDeleteUser} />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
        {isFetchedAll ? null : (
          <IonInfiniteScroll
            onIonInfinite={(ev) => {
              fetchMore(false);
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
            }}
          >
            <IonInfiniteScrollContent
              loadingText="Fetching more..."
              loadingSpinner="circles"
            />
          </IonInfiniteScroll>
        )}
      </div>

      <IonLoading isOpen={isFetching} />

      <IonModal
        cssClass="users-filter-modal customer"
        isOpen={showFilter}
        onDidDismiss={onDismissFilter}
      >
        <div className="modal-header">
          <h2>Filter Customers</h2>
          <IonButton color="favorite" fill="clear" onClick={onClear}>Clear All</IonButton>
        </div>
        <div className="setting">
          <div className="header">Country</div>
          <div className="content">
            <IonItem lines="none" className="select">
              <IonSelect
                className="select"
                placeholder="Select Country..."
                value={tempFilterParams.country}
                onIonChange={(e): void => setTempFilterParams({ ...tempFilterParams, country: String(e.detail.value) })}
              >
                <IonSelectOption value="United States">United States</IonSelectOption>
                <IonSelectOption value="Canada">Canada</IonSelectOption>
              </IonSelect>
            </IonItem>
          </div>
        </div>
        <div className="setting">
          <div className="header">Province</div>
          <div className="content">
            <IonItem lines="none" className="select">
              <IonSelect
                className="select"
                placeholder="Select State..."
                value={tempFilterParams.province}
                disabled={!tempFilterParams.country}
                onIonChange={(e): void => setTempFilterParams({ ...tempFilterParams, province: String(e.detail.value) })}
              >
                {availableStates.map((state) => (
                  <IonSelectOption key={state.name} value={state.name}>{state.name}</IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </div>
        </div>
        <div className="setting">
          <div className="header">City</div>
          <div className="content">
            <IonItem lines="none" className="select">
              <IonSelect
                className="select"
                placeholder="Select City..."
                value={tempFilterParams.city}
                disabled={!tempFilterParams.country || !tempFilterParams.province}
                onIonChange={(e): void => setTempFilterParams({ ...tempFilterParams, city: String(e.detail.value) })}
              >
                {availableCities.map((city) => (
                  <IonSelectOption key={city.name} value={city.name}>{city.name}</IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </div>
        </div>
        {/* <div className="setting">
          <div className="header">Customer Type</div>
          <div className="content">
            test
          </div>
        </div> */}
        <div className="setting">
          <div className="header">
            Number of booking
            <div className="range">
              {`${tempFilterParams.bookings.min} - ${tempFilterParams.bookings.max}`}
            </div>
          </div>
          <div className="content">
            <IonRange
              mode="ios"
              min={0}
              max={500}
              step={1}
              dualKnobs
              ticks
              value={{
                lower: tempFilterParams.bookings.min,
                upper: tempFilterParams.bookings.max
              }}
              onIonChange={(e: CustomEvent<any>) => setTempFilterParams({
                ...tempFilterParams,
                bookings: { min: e.detail.value.lower, max: e.detail.value.upper }
              })}
            />
          </div>
          <div className="range-mark">
            <span>0</span>
            <span>500</span>
          </div>
        </div>
        <div className="btn-apply">
          <IonButton color="favorite" expand="block" onClick={onApplyFilter}>
            Apply
          </IonButton>
        </div>
      </IonModal>
    </div>
  );
};

CustomersView.defaultProps = {
  dateRange: null
};

CustomersView.propTypes = {
  dateRange: PropTypes.any
};

export default React.memo(CustomersView);
