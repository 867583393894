import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import {
  IonButton, IonCol, IonGrid, IonIcon, IonRow, IonSpinner
} from '@ionic/react';
import {
  sendOutline, attachOutline, downloadOutline, mailOutline
} from 'ionicons/icons';
import { Conversation, Message } from '@twilio/conversations';
import PropTypes from 'prop-types';
import slugify from 'slugify';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import axios from 'axios';
import Dropzone from 'react-dropzone';

import { AppContext } from '../../../../data/AppContext';
import connect from '../../../../data/connect';
import { sendTranscript } from '../../../../data/dataApi';
import {
  getTranscriptData,
  getAgentNames,
  generateDownloadTranscript,
  getUniqueFilenames,
  generateEmailTranscript
} from '../../../../util/transcript';
import { Booking } from '../../../../models';

interface ChatInputProps {
  booking: Booking
  conversation: Conversation | null;
  messages: Message[];
  isChatWindowOpen: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
  booking,
  conversation,
  isChatWindowOpen,
  messages
}) => {
  const { state: { auth: { user: current } } } = useContext(AppContext);
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState('');
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [fileSendError, setFileSendError] = useState<string | null>(null);
  const [isGeneratingTranscript, setIsGeneratingTranscript] = useState(false);
  const [isDownloadingTranscript, setIsDownloadingTranscript] = useState(false);
  const [isEmailingTranscript, setEmailingTranscript] = useState(false);

  const handleSendFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('userfile', file);
      setIsSendingFile(true);
      setFileSendError(null);
      conversation
        ?.sendMessage(formData)
        .catch((e: any) => {
          if (e?.code === 413) {
            setFileSendError('File size is too large. Maximum file size is 150MB.');
          } else {
            setFileSendError('There was a problem uploading the file. Please try again.');
          }
          console.log('Problem sending file: ', e);
        })
        .finally(() => {
          setIsSendingFile(false);
        });
    }
  };

  const handleSendMessage = () => {
    if (/\S/.test(message)) {
      conversation?.sendMessage(message.trim());
      setMessage('');
    }
  };

  const getMediaInfo = async () => {
    const mediaMessages = messages?.filter((msg) => msg.attachedMedia);
    const mediaInfo = [];
    for (const message of mediaMessages || []) {
      for (const media of message.attachedMedia || []) {
        try {
          const file = {
            name: media.filename,
            type: media.contentType,
            size: media.size
          } as File;
          const url = media ? await media.getContentTemporaryUrl() : URL.createObjectURL(file);
          mediaInfo.push({ url, filename: media.filename, type: media.contentType });
        } catch (e) {
            console.error(`Failed downloading message attachment: ${e}`);
        }
      }
    }
    return mediaInfo;
  };

  const createFileName = () => {
    const fileName = `call-between-${booking.customer.name}-and-${booking.provider.name}`;
    return fileName.replace(/\s/g, '');
  };

  const handleDownloadTranscript = async () => {
    setIsDownloadingTranscript(true);
    setIsGeneratingTranscript(true);
    const transcriptData = getTranscriptData(messages);
    const customerName = current.name || '';
    const agentNames = getAgentNames(customerName, transcriptData);
    const transcript = generateDownloadTranscript(customerName, agentNames, transcriptData);
    const transcriptBlob = new Blob([transcript], { type: 'text/plain' });
    const mediaInfo = await getMediaInfo();

    let fileName = createFileName();
    // if (agentNames.length > 0) {
    //   agentNames.forEach((name) => (fileName = fileName.concat(`-and-${name}`)));
    // }

    fileName = fileName.concat(`-${slugify(transcriptData[0].timeStamp.toDateString())}`);
    fileName = fileName.toLowerCase();
    setIsGeneratingTranscript(false);
    if (mediaInfo.length > 0) {
      const uniqueFilenames = getUniqueFilenames(transcriptData);
      let mediaMessageIndex = 0;
      const zip = new JSZip();
      const folder = zip.folder(fileName);
      folder?.file(`${fileName}.txt`, transcriptBlob);
      mediaInfo.forEach((info) => {
        if (info.url) {
          const blobPromise = fetch(info.url).then(async (response) => {
            if (response.status === 200) return response.blob();
            return Promise.reject(new Error(response.statusText));
          });
          folder?.file(uniqueFilenames[mediaMessageIndex], blobPromise);
          mediaMessageIndex += 1;
        }
      });
      await zip
        .generateAsync({ type: 'blob' })
        .then((blob) => saveAs(blob, `${fileName}.zip`))
        .catch((e) => console.error(`Failed zipping message attachments: ${e}`));
    } else {
      saveAs(transcriptBlob, `${fileName}.txt`);
    }

    setTimeout(() => setIsDownloadingTranscript(false), 1000);
  };

  const emailSubject = (agentNames: (string | undefined)[]) => {
    let subject = 'Transcript of your chat';
    if (agentNames.length > 0) {
      subject = subject.concat(` with ${agentNames[0] || ''}`);
      agentNames.slice(1).forEach((name: any) => (subject = subject.concat(` and ${name || ''}`)));
    }
    return subject;
  };

  const emailContent = (customerName: string, transcript: string) => `<div><h1 style="text-align:center;">Chat Transcript</h1><p>Hello ${customerName},<br><br>Please see below your transcript, with any associated files attached, as requested.<br><br>${transcript}</p></div>`;

  const getBuffer = (info: any) => {
    return new Promise((resolve, reject) => {
      axios.get(info.url, { responseType: 'blob' })
        .then((res) => resolve({
          ...info,
          blob: res.data
        }))
        .catch((err) => reject(err));
    });
  };

  const handleEmailTranscript = async () => {
    setEmailingTranscript(true);
    setIsGeneratingTranscript(true);
    const transcriptData = getTranscriptData(messages);
    const customerName = current.name || '';
    const agentNames = getAgentNames(customerName, transcriptData);
    const mediaInfo = await getMediaInfo();
    const transcript = generateEmailTranscript(customerName, agentNames, transcriptData);
    setIsGeneratingTranscript(false);

    const subject = emailSubject(agentNames);
    const content = emailContent(customerName ?? '', transcript);

    const getAttachmentsPromises: any[] = [];
    mediaInfo.map(async (info) => {
      if (info.url) {
        getAttachmentsPromises.push(getBuffer(info));
      }
    });
    const attachments = await Promise.all(getAttachmentsPromises);
    const data = new FormData();
    data.append('subject', subject);
    data.append('content', content);
    attachments.map(({ filename, blob }) => {
      data.append('attachments', blob, filename);
    });

    await sendTranscript(data);
    setEmailingTranscript(false);
  };

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (!event.ctrlKey && event.which === 13 && message) {
      event.preventDefault();
      handleSendMessage();
    }
    if (event.ctrlKey && event.which === 13 && message) {
      setMessage(`${message}\n`);
    }
  };

  const onDrop = (files: File[]) => {
    const file = files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('userfile', file);
      setIsSendingFile(true);
      setFileSendError(null);
      conversation
        ?.sendMessage(formData)
        .catch((e: any) => {
          if (e?.code === 413) {
            setFileSendError('File size is too large. Maximum file size is 150MB.');
          } else {
            setFileSendError('There was a problem uploading the file. Please try again.');
          }
          console.log('Problem sending file: ', e);
        })
        .finally(() => {
          setIsSendingFile(false);
        });
    }
  };

  useEffect(() => {
    if (isChatWindowOpen) {
      // When the chat window is opened, we will focus on the text input.
      // This is so the user doesn't have to click on it to begin typing a message.
      setTimeout(() => {
        textInputRef?.current?.focus();
      }, 500);
    }
  }, [isChatWindowOpen]);

  return (
    <Dropzone noClick onDrop={onDrop}>
      {({ getRootProps }) => (
        <section>
          <div className="chat-input" {...getRootProps()}>
            <input
              ref={inputRef}
              type="file"
              style={{ display: 'none' }}
              onChange={handleSendFile}
            />
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <textarea
                    ref={textInputRef}
                    value={message}
                    placeholder="Send a message..."
                    rows={2}
                    disabled={isSendingFile || isDownloadingTranscript || isEmailingTranscript}
                    className="message-input"
                    onKeyPress={onKeyPress}
                    onChange={(e): void => {
                      setMessage(e.target.value || '');
                    }}
                  />
                </IonCol>
                <IonCol>
                  <div className="chat-actions">
                    <IonButton
                      color="dark"
                      fill="clear"
                      disabled={isSendingFile || isDownloadingTranscript || isEmailingTranscript || !messages.length}
                      onClick={handleDownloadTranscript}
                    >
                      {isDownloadingTranscript ? <IonSpinner /> : <IonIcon icon={downloadOutline} />}
                    </IonButton>
                    <IonButton
                      color="dark"
                      fill="clear"
                      disabled={isSendingFile || isDownloadingTranscript || isEmailingTranscript || !messages.length}
                      onClick={handleEmailTranscript}
                    >
                      {isEmailingTranscript ? <IonSpinner /> : <IonIcon icon={mailOutline} />}
                    </IonButton>
                    <IonButton
                      color="dark"
                      fill="clear"
                      disabled={isSendingFile || isDownloadingTranscript || isEmailingTranscript}
                      onClick={() => inputRef?.current?.click()}
                    >
                      {isSendingFile ? <IonSpinner /> : <IonIcon icon={attachOutline} />}
                    </IonButton>
                    <IonButton
                      color="dark"
                      fill="clear"
                      disabled={isSendingFile || isDownloadingTranscript || isEmailingTranscript || !message}
                      onClick={() => handleSendMessage()}
                    >
                      <IonIcon icon={sendOutline} />
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

ChatInput.propTypes = {
  booking: PropTypes.any.isRequired,
  conversation: PropTypes.any.isRequired,
  isChatWindowOpen: PropTypes.bool.isRequired,
  messages: PropTypes.any.isRequired,
};

export default connect<ChatInputProps, null, null>({
  component: ChatInput,
});
