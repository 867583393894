import React, {
  useContext, useEffect, Suspense
} from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp, IonLoading, IonRouterOutlet, IonSplitPane
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useIdleTimer } from 'react-idle-timer';
import 'font-awesome/css/font-awesome.min.css';

import Menu from '../../components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

/* Theme variables */
import '../../theme/variables.css';
import connect from '../../data/connect';
import { AppContextProvider, AppContext } from '../../data/AppContext';
import { StatusContextProvider, StatusContext } from '../../data/StatusContext';
import { dispatchValidate } from '../../data/store/auth/auth.actions';
import ProtectedRoute from '../../components/ProtectedRoute';
import CustomerView from '../CustomerView';
import ProviderView from '../ProviderView';
import ClinicAdminView from '../ClinicAdminView';
import ClinicMemberView from '../ClinicMemberView';
import AdminView from '../AdminView';
import MarketingMemberView from '../MarketingMemberView';
import RevenueAnalystView from '../RevenueAnalystView';
import HelpdeskView from '../HelpdeskView';

import Login from '../Login';
import Signup from '../Signup';
import Verification from '../Verification';
import ForgetPassword from '../ForgetPassword';
import ResetForgetPassword from '../ResetForgetPassword';
import VideoApp from '../VideoApp';
import { IDLE_TIMEOUT } from '../../data/constants';

import './App.scss';
import JsonForm from '../JsonForm';
import Signature from '../Signature';

interface DispatchProps {
  validate: typeof dispatchValidate
}

type IonicAppProps = DispatchProps;

const IonicApp: React.FC<IonicAppProps> = ({ validate }) => {
  const { updateStatus } = useContext(StatusContext);
  const { state: { auth: { user: current } } } = useContext(AppContext);

  const onIdle = () => {
    if (current && current.syncId > -1) {
      updateStatus(current, 'away');
    }
  };

  const onActive = () => {
    if (current && current.syncId > -1) {
      updateStatus(current, 'online');
    }
  };

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle,
    onActive,
  });

  const handleTabClosing = () => {
    if (current && current.syncId > -1) {
      updateStatus(current, 'away');
    }
  };

  useEffect(() => {
    validate();
  }, [validate]);

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('unload', handleTabClosing);
    };
  });

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main" when="md">
          <Suspense fallback={<IonLoading isOpen />}>
            <Menu />
            <IonRouterOutlet id="main">
              <ProtectedRoute
                path="/customer"
                component={CustomerView}
              />
              <ProtectedRoute
                path="/provider"
                component={ProviderView}
              />
              <ProtectedRoute
                path="/clinic-admin"
                component={ClinicAdminView}
              />
              <ProtectedRoute
                path="/admin"
                component={AdminView}
              />
              <ProtectedRoute
                path="/clinic-member"
                component={ClinicMemberView}
              />
              <ProtectedRoute
                path="/marketing-member"
                component={MarketingMemberView}
              />
              <ProtectedRoute
                path="/revenue-analyst"
                component={RevenueAnalystView}
              />
              <ProtectedRoute
                path="/helpdesk"
                component={HelpdeskView}
              />
              <Route path="/video/:room" component={VideoApp} exact />
              <Route path="/login" component={Login} exact />
              <Route path="/signup" component={Signup} exact />
              <Route path="/verify" component={Verification} exact />
              <Route path="/forget-password" component={ForgetPassword} exact />
              <Route
                path="/reset-forget-password/:phone"
                component={ResetForgetPassword}
                exact
              />
              <Route path="/json-form" component={JsonForm} />
              <Route path="/signature" component={Signature} />
              <Redirect exact from="/" to="/login" />
            </IonRouterOutlet>
          </Suspense>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

IonicApp.propTypes = {
  validate: PropTypes.func.isRequired
};

const IonicAppConnected = connect<
Record<string, unknown>,
Record<string, unknown>,
DispatchProps
>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {
    validate: dispatchValidate
  },
  component: IonicApp
});

const App: React.FC = () => (
  <AppContextProvider>
    <StatusContextProvider>
      <IonicAppConnected />
    </StatusContextProvider>
  </AppContextProvider>
);

export default App;
