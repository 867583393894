import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Video, { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';

export function useLocalAudioTrack() {
  const location = useLocation();
  const [track, setTrack] = useState<LocalAudioTrack>();

  useEffect(() => {
    if (location.pathname.includes('/video/')) {
      Video.createLocalAudioTrack().then((newTrack) => {
        setTrack(newTrack);
      });
    }
  }, [location]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return track;
}

export function useLocalVideoTrack() {
  const [track, setTrack] = useState<LocalVideoTrack>();

  const getLocalVideoTrack = useCallback(
    () => Video.createLocalVideoTrack({
      frameRate: 24,
      height: 720,
      width: 1280,
      name: 'camera'
    }).then((newTrack) => {
      setTrack(newTrack);
      return newTrack;
    }),
    []
  );

  useEffect(() => {
    // We get a new local video track when the app loads.
    getLocalVideoTrack();
  }, [getLocalVideoTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalVideoTrack] as const;
}

export default function useLocalTracks() {
  const audioTrack = useLocalAudioTrack();
  const [videoTrack, getLocalVideoTrack] = useLocalVideoTrack();

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined
  ) as (LocalAudioTrack | LocalVideoTrack)[];

  return { localTracks, getLocalVideoTrack };
}
