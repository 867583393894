import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonButton,
  IonListHeader,
  IonImg,
  IonLabel,
  IonIcon,
  IonModal,
  IonRange,
  IonRippleEffect,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonItem,
  IonInput
} from '@ionic/react';
import {
  options,
  checkmarkCircle,
  arrowBackOutline,
  searchOutline,
  closeOutline
} from 'ionicons/icons';
import clsx from 'clsx';

import connect from '../../data/connect';
import UserItem from '../../components/UserItem';
import {
  User, Language, Specialist, ProviderType, UserRole
} from '../../models';
import {
  fetchProviders,
  fetchClinics,
  fetchClinicMembers,
  addFavoriteProvider,
  deleteFavoriteProvider
} from '../../data/dataApi';
import { StarIcon } from '../../icons';
import emptyImg from '../../assets/empty.png';

import './ProvidersList.scss';

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}
interface MatchParams {
  id: string
}
interface StateProps {
  activeUser: User;
  languages: Language[];
  specialists: Specialist[];
  providerTypes: ProviderType[];
}

type ProvidersListProps = RouteComponentProps<MatchParams> & StateProps;

const Empty = () => (
  <div className="empty">
    <div>
      <IonImg src={emptyImg} alt="empty" />
      <IonLabel className="title">Nothing found</IonLabel>
    </div>
  </div>
);

const ProvidersList: React.FC<ProvidersListProps> = ({
  match,
  history,
  languages,
  specialists,
  providerTypes,
  activeUser
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRating, setSelectedRating] = useState(0);
  const [selectedLanguages, setSelectedLanguages] = useState([] as number[]);
  const [selectedSpecialists, setSelectedSpecialists] = useState([] as number[]);
  const [selectedProviderType, setSelectedProviderType] = useState(0);
  const [tempSelectedRating, setTempSelectedRating] = useState(0);
  const [tempSelectedLanguages, setTempSelectedLanguages] = useState(
    [] as number[]
  );
  const [tempSelectedSpecialists, setTempSelectedSpecialists] = useState(
    [] as number[]
  );
  const [tempSelectedProviderType, setTempSelectedProviderType] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [providers, setProviders] = useState<User[]>([]);
  const [clinics, setClinics] = useState<User[]>([]);
  const [members, setMembers] = useState<User[]>([]);
  const [segment, setSegment] = useState<'provider' | 'clinic-admin'>('provider');
  const [selectedClinic, setSelectedClinic] = useState<User | null>(null);
  const [searchKey, setSearchKey] = useState('');

  const onOpenFilter = (): void => {
    setTempSelectedLanguages(selectedLanguages);
    setTempSelectedSpecialists(selectedSpecialists);
    setTempSelectedProviderType(selectedProviderType);
    setShowFilter(true);
  };

  const onDismissFilter = (): void => {
    setTempSelectedLanguages([]);
    setTempSelectedSpecialists([]);
    setTempSelectedProviderType(0);
    setTempSelectedRating(0);
    setShowFilter(false);
  };

  const onClickLanguage = (id: number): void => {
    if (tempSelectedLanguages.includes(id)) {
      setTempSelectedLanguages(
        tempSelectedLanguages.filter((lang) => lang !== id)
      );
    } else {
      setTempSelectedLanguages([...tempSelectedLanguages, id]);
    }
  };

  const onClickSpecialist = (id: number): void => {
    if (tempSelectedSpecialists.includes(id)) {
      setTempSelectedSpecialists(
        tempSelectedSpecialists.filter((spec) => spec !== id)
      );
    } else {
      setTempSelectedSpecialists([...tempSelectedSpecialists, id]);
    }
  };

  const onClickProviderType = (id: number): void => {
    if (tempSelectedProviderType === id) {
      setTempSelectedProviderType(0);
    } else {
      setTempSelectedProviderType(id);
    }
    setTempSelectedSpecialists([]);
  };

  const onAddFavorite = async (id: number, provider: User) => {
    try {
      await addFavoriteProvider(id, provider.id);
      if (segment === 'provider') {
        const updatedProviders = providers.map((pv) => {
          if (pv.id === provider.id) {
            return { ...pv, isFavorite: 1 };
          }
          return pv;
        });
        setProviders([...updatedProviders]);
      }
      if (segment === 'clinic-admin' && !selectedClinic) {
        const updatedClinics = clinics.map((cl) => {
          if (cl.id === provider.id) {
            return { ...cl, isFavorite: 1 };
          }
          return cl;
        });
        setClinics([...updatedClinics]);
      }
      if (segment === 'clinic-admin' && selectedClinic) {
        const updatedMembers = members.map((mb) => {
          if (mb.id === provider.id) {
            return { ...mb, isFavorite: 1 };
          }
          return mb;
        });
        setMembers([...updatedMembers]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onRemoveFavorite = async (id: number, provider: User) => {
    try {
      await deleteFavoriteProvider(id, provider.id);
      if (segment === 'provider') {
        const updatedProviders = providers.map((pv) => {
          if (pv.id === provider.id) {
            return { ...pv, isFavorite: 0 };
          }
          return pv;
        });
        setProviders([...updatedProviders]);
      }
      if (segment === 'clinic-admin' && !selectedClinic) {
        const updatedClinics = clinics.map((cl) => {
          if (cl.id === provider.id) {
            return { ...cl, isFavorite: 0 };
          }
          return cl;
        });
        setClinics([...updatedClinics]);
      }
      if (segment === 'clinic-admin' && selectedClinic) {
        const updatedMembers = members.map((mb) => {
          if (mb.id === provider.id) {
            return { ...mb, isFavorite: 0 };
          }
          return mb;
        });
        setMembers([...updatedMembers]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMore = () => {
    setIsFetching(true);
    if (segment === 'provider' && !isFetchedAll) {
      const params = {
        skip: providers.length,
        providerType: selectedProviderType,
        languages: selectedLanguages,
        specialists: selectedSpecialists,
        searchKey
      };
      fetchProviders(params)
        .then((res) => {
          if (res.success) {
            setProviders([...providers, ...res.providers]);
            if (!res.providers.length || res.providers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
    if (segment === 'clinic-admin' && !isFetchedAll) {
      const params = {
        skip: clinics.length,
        providerType: selectedProviderType,
        languages: selectedLanguages,
        specialists: selectedSpecialists,
        searchKey
      };
      fetchClinics(params)
        .then((res) => {
          if (res.success) {
            setClinics([...clinics, ...res.clinics]);
            if (!res.clinics.length || res.clinics.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
  };

  const fetchMembers = (id: number) => {
    setIsFetching(true);
    const params = {
      searchKey
    };
    fetchClinicMembers(id, params)
      .then((res) => {
        if (res.success) {
          setMembers(res.members);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsFetching(false));
  };

  const onApplyFilter = (): void => {
    setSelectedLanguages(tempSelectedLanguages);
    setSelectedSpecialists(tempSelectedSpecialists);
    setSelectedRating(tempSelectedRating);
    setSelectedProviderType(tempSelectedProviderType);
    setProviders([]);
    setClinics([]);
    setMembers([]);
    setShowFilter(false);
    setIsFetchedAll(false);
  };

  const onClear = (): void => {
    setSelectedLanguages([]);
    setSelectedSpecialists([]);
    setSelectedProviderType(0);
    setSelectedRating(0);
    setProviders([]);
    setClinics([]);
    setMembers([]);
    setShowFilter(false);
    setIsFetchedAll(false);
  };

  const onSearch = (str: string) => {
    setIsFetching(true);
    if (segment === 'provider') {
      setIsFetchedAll(false);
      const params = {
        skip: 0,
        providerType: selectedProviderType,
        languages: selectedLanguages,
        specialists: selectedSpecialists,
        searchKey: str
      };
      fetchProviders(params)
        .then((res) => {
          if (res.success) {
            setProviders([...res.providers]);
            if (!res.providers.length || res.providers.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
    if (segment === 'clinic-admin' && !selectedClinic) {
      setIsFetchedAll(false);
      const params = {
        skip: 0,
        providerType: selectedProviderType,
        languages: selectedLanguages,
        specialists: selectedSpecialists,
        searchKey: str
      };
      fetchClinics(params)
        .then((res) => {
          if (res.success) {
            setClinics([...res.clinics]);
            if (!res.clinics.length || res.clinics.length < 30) {
              setIsFetchedAll(true);
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    }
    if (segment === 'clinic-admin' && selectedClinic) {
      const params = {
        searchKey: str
      };
      fetchClinicMembers(selectedClinic.id, params)
        .then((res) => {
          if (res.success) {
            setMembers(res.members);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setIsFetching(false));
    }
  };

  const isAdminView = useMemo(() => activeUser.role === UserRole.ADMIN, [activeUser]);

  const availableProviders = useMemo(() => {
    if (segment === 'provider') {
      return providers;
    }
    if (segment === 'clinic-admin' && !selectedClinic) {
      return clinics;
    }
    return members;
  }, [selectedClinic, providers, members, clinics, segment]);

  useEffect(() => {
    if (selectedClinic) {
      fetchMembers(selectedClinic.id);
    }
  }, [selectedClinic]);

  useEffect(() => {
    fetchMore();
  }, [selectedProviderType, selectedLanguages, selectedSpecialists, segment]);

  useEffect(() => {
    if (!searchKey) {
      onSearch('');
    }
  }, [searchKey]);

  return (
    <IonContent id="Providers-list" className="page-content ion-padding">
      <div className="providers-list-header">
        {segment === 'clinic-admin' && selectedClinic ? (
          <span className="cursor-pointer" onClick={() => setSelectedClinic(null)}>
            <IonIcon color="favorite" icon={arrowBackOutline} size="large" />
          </span>
        ) : null}
        <IonListHeader className="page-header">
          {segment === 'provider' ? 'Providers Directory' : null}
          {segment === 'clinic-admin' && !selectedClinic ? 'Clinics Directory' : null}
          {segment === 'clinic-admin' && selectedClinic ? `Clinic Members for ${selectedClinic.name}` : null}
          {isAdminView ? (
            <span>
              (
              <IonIcon icon={checkmarkCircle} color="danger" />
              Certified,&nbsp;
              <IonIcon icon={checkmarkCircle} color="favorite" />
              ID verified
              )
            </span>
          ) : null}
        </IonListHeader>
        <IonItem className="search-input" lines="none">
          <IonInput
            value={searchKey}
            placeholder="Search by provider name"
            onKeyPress={(e) => {
              if (e.keyCode === 13 || e.key === 'Enter') {
                onSearch(searchKey);
              }
            }}
            onIonChange={(e) => {
              setSearchKey(e.detail.value ?? '');
            }}
          />
          <IonIcon size="small" icon={searchOutline} onClick={() => onSearch(searchKey)} />
          <IonIcon size="small" icon={closeOutline} onClick={() => setSearchKey('')} />
        </IonItem>
        {segment === 'clinic-admin' ? null : (
          <IonButton color="dark" fill="outline" onClick={onOpenFilter}>
            <IonIcon icon={options} />
            Filter
          </IonButton>
        )}
      </div>
      {segment === 'clinic-admin' && selectedClinic ? null : (
        <IonSegment
          mode="ios"
          value={segment}
          onIonChange={(e): void => {
            setProviders([]);
            setClinics([]);
            setMembers([]);
            setIsFetchedAll(false);
            setSegment(e.detail.value as 'provider' | 'clinic-admin');
          }}
        >
          <IonSegmentButton value="provider">Individuals</IonSegmentButton>
          <IonSegmentButton value="clinic-admin">Clinics</IonSegmentButton>
        </IonSegment>
      )}
      {availableProviders && availableProviders.length ? (
        <IonGrid>
          <IonRow>
            {availableProviders
              .map((provider) => (
                <IonCol size="4">
                  <UserItem
                    key={provider.id}
                    user={provider}
                    addFavoriteProvider={onAddFavorite}
                    deleteFavoriteProvider={onRemoveFavorite}
                    onClick={() => {
                      if (segment === 'clinic-admin' && !selectedClinic) {
                        setMembers([]);
                        setSearchKey('');
                        setSelectedClinic(provider);
                      } else {
                        history.push(`/${activeUser.role}/provider/${provider.id}`);
                      }
                    }}
                  />
                </IonCol>
              ))}
          </IonRow>
        </IonGrid>
      ) : null}
      {!availableProviders.length && !isFetching ? <Empty /> : null}
      {isFetchedAll ? null : (
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            fetchMore();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent
            loadingText="Fetching more..."
            loadingSpinner="circles"
          />
        </IonInfiniteScroll>
      )}

      <IonLoading isOpen={isFetching && !providers.length} />

      <IonModal
        cssClass="providers-filter-modal"
        isOpen={showFilter}
        onDidDismiss={onDismissFilter}
      >
        <div className="modal-header">
          <h2>Filter Providers</h2>
          <IonButton color="favorite" fill="clear" onClick={onClear}>Clear All</IonButton>
        </div>
        <div className="setting">
          <div className="header">Provider Types</div>
          <div className="content">
            {providerTypes
              .map((item, index) => (
                <div
                  key={index}
                  className={`ion-activatable toggle${
                    tempSelectedProviderType === item.id ? ' active' : ''
                  }`}
                  onClick={(): void => onClickProviderType(item.id)}
                >
                  {item.name}
                  <IonRippleEffect />
                </div>
              ))}
          </div>
        </div>
        <div className="setting">
          <div className="header">Specialty</div>
          <div className="content">
            {specialists
              .map((item, index) => (
                <div
                  key={index}
                  className={clsx('ion-activatable toggle', {
                    active: tempSelectedSpecialists.includes(item.id),
                    disabled: item.type !== tempSelectedProviderType
                  })}
                  onClick={(): void => onClickSpecialist(item.id)}
                >
                  {item.name}
                  <IonRippleEffect />
                </div>
              ))}
          </div>
        </div>
        <div className="setting">
          <div className="header">Language Skills</div>
          <div className="content">
            {languages.map((item, index) => (
              <div
                key={index}
                className={`ion-activatable toggle${
                  tempSelectedLanguages.includes(item.id) ? ' active' : ''
                }`}
                onClick={(): void => onClickLanguage(item.id)}
              >
                {item.name}
                <IonRippleEffect />
              </div>
            ))}
          </div>
        </div>
        {isAdminView ? null : (
          <div className="setting">
            <div className="header">
              Rating
              <div>
                <StarIcon />
                {tempSelectedRating.toFixed(1)}
              </div>
            </div>
            <div className="content">
              <IonRange
                mode="ios"
                min={0}
                max={5}
                step={0.5}
                value={tempSelectedRating}
                onIonChange={({ detail }) => setTempSelectedRating(Number(detail.value))}
              />
            </div>
          </div>
        )}
        <div className="btn-apply">
          <IonButton color="favorite" expand="block" onClick={onApplyFilter}>
            Apply
          </IonButton>
        </div>
      </IonModal>
    </IonContent>
  );
};

ProvidersList.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  activeUser: PropTypes.any.isRequired,
  languages: PropTypes.array.isRequired,
  specialists: PropTypes.array.isRequired,
  providerTypes: PropTypes.array.isRequired
};

export default connect<RouteComponentProps<MatchParams>, StateProps, {}>({
  mapStateToProps: (state) => ({
    activeUser: state.auth.user,
    languages: state.data.allLanguages,
    specialists: state.data.allSpecialists,
    providerTypes: state.data.providerTypes,
  }),
  component: React.memo(ProvidersList)
});
