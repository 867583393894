import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonList,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonListHeader
} from '@ionic/react';

import connect from '../../data/connect';
import { User } from '../../models';
import ClinicMemberItem from '../../components/ClinicMemberItem';
import './ClinicMembers.scss';

interface StateProps {
  members: User[]
}

type ClinicMembersProps = RouteComponentProps & StateProps;

const ClinicMembers: React.FC<ClinicMembersProps> = ({ history, members }) => {
  const [segment, setSegment] = useState('active');
  const onClickMember = (id: number): void => {
    history.push(`/clinic-admin/member/${id}`);
  };

  const onAddMember = (): void => {
    history.push('/clinic-admin/add-member');
  };

  return (
    <IonContent id="clinic-member-list" className="page-content ion-padding">
      <div className="header">
        <IonListHeader className="page-header">Clinic Members</IonListHeader>
        <IonButton color="favorite" onClick={(): void => onAddMember()}>
          Add Member
        </IonButton>
      </div>
      <IonSegment
        mode="ios"
        value={segment}
        onIonChange={(e): void => setSegment(e.detail.value as 'acitve' | 'pending')}
      >
        <IonSegmentButton value="active">Active</IonSegmentButton>
        <IonSegmentButton value="pending">Pending</IonSegmentButton>
      </IonSegment>
      <IonList>
        {members
          .filter((member) => member.status === segment)
          .map((member) => (
            <ClinicMemberItem
              key={member.id}
              member={member}
              onItemClick={onClickMember}
            />
          ))}
      </IonList>
    </IonContent>
  );
};

ClinicMembers.propTypes = {
  members: PropTypes.array.isRequired,
  history: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    members: state.data.members
  }),
  component: React.memo(ClinicMembers)
});
