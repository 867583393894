import React, {
  createRef, useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonList,
  IonIcon,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonDatetime,
  IonLabel,
  IonAlert,
  IonLoading
} from '@ionic/react';
import {
  chevronBackOutline,
  chevronForwardOutline,
  chevronDownOutline,
  chevronUpOutline,
  document,
  person,
  calendarOutline
} from 'ionicons/icons';
import Dropzone, { DropzoneRef, ErrorCode } from 'react-dropzone';
import moment from 'moment';

import connect from '../../../data/connect';
import {
  uploadIdentity,
  uploadLicense,
  updateProviderProfile,
  socket
} from '../../../data/dataApi';
import { dispatchUpdateUser } from '../../../data/store/auth/auth.actions';
import {
  User, License, Identity, verificationMethods, UserRole
} from '../../../models';
import { FileIcon, UserIcon } from '../../../icons';
import states from '../../../data/json/states.json';
import { SOCKET_KEYS } from '../../../data/constants';

const initLicense = {
  id: -1,
  name: '',
  issuingOrganization: '',
  issueDate: '',
  expirationDate: '',
  credentialId: '',
  credentialFileName: '',
  number: '',
  state: '',
  country: '',
  type: '',
};

const initIdentity = {
  id: -1,
  type: '',
  country: '',
  firstname: '',
  lastname: '',
  birthdate: '',
  number: '',
  frontImage: '',
  backImage: '',
};

const providerTypes = ['Dietician', 'Massage Therapist', 'Physician'];

interface SixthStepProps {
  user: User
  next: () => void
  prev: () => void
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

const SixthStep: React.FC<SixthStepProps & DispatchProps> = ({
  user, next, prev, updateUser
}) => {
  const [selectedOption, setSelectedOption] = useState<'none' | 'license' | 'identity'>('none');
  const [uploading, setUploading] = useState(false);
  const [license, setLicense] = useState<License>(initLicense);
  const [credential, setCredential] = useState<File | null>(null);
  const [isLicenseUploaded, setIsLicenseUploaded] = useState(false);
  const [identity, setIdentity] = useState<Identity>(initIdentity);
  const [photoID, setPhotoID] = useState<File | null>(null);
  const [isIdentityUploaded, setIsIdentityUploaded] = useState(false);
  const [showUploadSuccessAlert, setShowUploadSuccessAlert] = useState({
    isOpen: false,
    type: ''
  });
  const [fileUploadError, setFileUploadError] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const dropzoneRef = createRef<DropzoneRef>();

  const onUploadLicense = () => {
    const {
      name,
      issuingOrganization,
      issueDate,
      expirationDate,
      credentialId,
      number,
      state,
      country
    } = license;
    const data = new FormData();
    data.append('name', name);
    data.append('issuingOrganization', issuingOrganization);
    data.append('issueDate', issueDate);
    data.append('expirationDate', expirationDate);
    data.append('credentialId', credentialId);
    data.append('number', number);
    data.append('state', state);
    data.append('country', country);

    // eslint-disable-next-line no-nested-ternary
    const typeStr = providerTypes[user.type - 1];
    data.append('type', typeStr);
    if (credential) {
      data.append('attachments', credential);
    }

    setUploading(true);
    uploadLicense(data)
      .then((res: { success: boolean, license: License }) => {
        if (res.success) {
          const currentLicenses: License[] = user.licenses ?? [];
          let updatedLicenses: License[] = [];
          if (currentLicenses.find((lic) => lic.type === res.license.type)) {
            updatedLicenses = currentLicenses.map((lic) => {
              if (lic.type === res.license.type) {
                return res.license;
              }
              return lic;
            });
          } else {
            updatedLicenses = [...currentLicenses, res.license];
          }
          updateUser({ ...user, licenses: updatedLicenses });
          setShowUploadSuccessAlert({
            isOpen: true,
            type: 'license'
          });
          setIsLicenseUploaded(true);
          socket.emit(SOCKET_KEYS['submitted-license'], user.id, res.license.id);
        }
      })
      .finally(() => setUploading(false));
  };

  const onUploadIdentity = () => {
    const {
      type,
      country,
      firstname,
      lastname,
      birthdate,
      number
    } = identity;
    const data = new FormData();
    data.append('type', type);
    data.append('country', country);
    data.append('firstname', firstname);
    data.append('lastname', lastname);
    data.append('birthdate', birthdate);
    data.append('number', number);
    if (photoID) {
      data.append('attachments', photoID);
    }
    setUploading(true);
    uploadIdentity(data)
      .then((res) => {
        updateUser({ ...user, identity: res.identity });
        setShowUploadSuccessAlert({
          isOpen: true,
          type: 'identity'
        });
        setIsIdentityUploaded(true);
        socket.emit(SOCKET_KEYS['submitted-identity'], user.id, res.identity.id);
      })
      .finally(() => setUploading(false));
  };

  const onSubmit = () => {
    setUploading(true);
    updateProviderProfile(user.id, {
      profile: {
        onboardingStep: 100
      },
      languages: user.languages.map((language) => language.id),
      specialists: user.specialists.map((specialist) => specialist.id),
      locations: user.locations,
      services: user.services
    })
      .then((res) => {
        if (res.success) {
          updateUser(res.user);
          next();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setUploading(false));
  };

  const availableTypes = useMemo(() => {
    if (identity.country) {
      return verificationMethods.filter((item) => item.countries.includes(identity.country));
    }
    return null;
  }, [identity.country]);

  const availableStates = useMemo(() => {
    if (license.country === 'us' || license.country === 'ca') {
      return states[license.country];
    }
    return [];
  }, [license.country]);

  const isSubmitLicenseDisabled = useMemo(() => {
    const {
      name,
      issuingOrganization,
      issueDate,
      expirationDate,
      credentialId,
      number,
      state,
      country
    } = license;

    if (
      !name
      || !issuingOrganization
      || !issueDate
      || !expirationDate
      || !credentialId
      || !number
      || !state
      || !country
      || !credential
    ) {
      return true;
    }
    return false;
  }, [license, credential]);

  const isSubmitIdentityDisabled = useMemo(() => {
    const {
      type,
      country,
      firstname,
      lastname,
      birthdate,
      number,
      frontImage
    } = identity;
    if (
      !type
      || !country
      || !firstname
      || !lastname
      || !birthdate
      || !number
      || !frontImage
    ) {
      return true;
    }
    return false;
  }, [identity]);

  useEffect(() => {
    if (user.licenses && user.licenses.length) {
      user.licenses.map((lc) => {
        if (providerTypes.indexOf(lc.type) === user.type - 1) {
          setIsLicenseUploaded(true);
          setLicense(lc);
        }
      });
    }
    if (user.identity && user.identity.status === 'pending') {
      setIsIdentityUploaded(true);
    }
  }, [user]);

  return (
    <div id="step-six">
      <IonGrid className="step-content">
        <IonRow>
          <IonCol>
            <IonList>
              <div className="sections">
                <div className="section">
                  <div
                    className="section-header"
                    onClick={() => {
                      if (selectedOption === 'license') {
                        setSelectedOption('none');
                      } else {
                        setFileUploadError('');
                        setSelectedOption('license');
                      }
                    }}
                  >
                    <span>
                      <FileIcon />
                      {`Upload license (${providerTypes[user.type - 1]})`}
                    </span>
                    {selectedOption === 'license' ? (
                      <span className="icon-btn">
                        <IonIcon icon={chevronUpOutline} />
                      </span>
                    ) : (
                      <span className="icon-btn">
                        <IonIcon icon={chevronDownOutline} />
                      </span>
                    )}
                  </div>
                  {selectedOption === 'license' ? (
                    <div className="section-body">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="12">
                            <IonItem lines="none" className="input">
                              <IonInput
                                name="license-name"
                                type="text"
                                placeholder="Name of license"
                                value={license?.name}
                                disabled={isLicenseUploaded}
                                onIonChange={(e): void => setLicense({ ...license, name: e.detail.value ?? '' })}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol size="12">
                            <IonItem lines="none" className="input">
                              <IonInput
                                name="license-organization"
                                type="text"
                                placeholder="Issuing organization"
                                value={license?.issuingOrganization}
                                disabled={isLicenseUploaded}
                                onIonChange={(e): void => setLicense({ ...license, issuingOrganization: e.detail.value ?? '' })}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol size="6">
                            <IonItem lines="none" className="input">
                              <IonIcon className="calendar-icon" icon={calendarOutline} />
                              <IonDatetime
                                max={moment().format('YYYY-MM-DD')}
                                displayFormat="MMMM YYYY"
                                pickerFormat="YYYY MMMM"
                                placeholder="Issue date"
                                value={license?.issueDate ? moment(license.issueDate).toLocaleString() : null}
                                disabled={isLicenseUploaded}
                                onIonChange={(event): void => setLicense({ ...license, issueDate: moment(event.detail.value || '').format('MMMM YYYY') })}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol size="6">
                            <IonItem lines="none" className="input">
                              <IonIcon className="calendar-icon" icon={calendarOutline} />
                              <IonDatetime
                                max="2100"
                                min={license.issueDate ? moment(license.issueDate).format('YYYY-MM-DD') : undefined}
                                displayFormat="MMMM YYYY"
                                pickerFormat="YYYY MMMM"
                                placeholder="Expiration date"
                                value={license?.expirationDate ? moment(license.expirationDate).toLocaleString() : null}
                                disabled={isLicenseUploaded}
                                onIonChange={(event): void => setLicense({ ...license, expirationDate: moment(event.detail.value || '').format('MMMM YYYY') })}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol size="12">
                            <IonItem lines="none" className="input">
                              <IonInput
                                name="license-number"
                                type="text"
                                placeholder="Issue number"
                                value={license?.number}
                                disabled={isLicenseUploaded}
                                onIonChange={(e): void => setLicense({ ...license, number: e.detail.value ?? '' })}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol size="6">
                            <IonItem lines="none" className="select">
                              <IonSelect
                                className="select"
                                placeholder="Issue country"
                                value={license.country}
                                disabled={isLicenseUploaded}
                                onIonChange={(e): void => setLicense({ ...license, country: String(e.detail.value) })}
                              >
                                <IonSelectOption value="us">United State</IonSelectOption>
                                <IonSelectOption value="ca">Canada</IonSelectOption>
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                          <IonCol size="6">
                            <IonItem lines="none" className="select">
                              <IonSelect
                                className="select"
                                placeholder="Issue state"
                                value={license.state}
                                disabled={!license.country || isLicenseUploaded}
                                onIonChange={(e): void => setLicense({ ...license, state: String(e.detail.value) })}
                              >
                                {availableStates.map((state) => (
                                  <IonSelectOption value={state.abbr}>{state.name}</IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                          <IonCol size="12">
                            <IonItem lines="none" className="input">
                              <IonInput
                                name="license-credential-id"
                                type="text"
                                placeholder="Credential ID"
                                value={license?.credentialId}
                                disabled={isLicenseUploaded}
                                onIonChange={(e): void => setLicense({ ...license, credentialId: e.detail.value ?? '' })}
                              />
                            </IonItem>
                          </IonCol>
                          <IonLabel color="danger">
                            <p style={{ marginLeft: 10 }}>
                              {fileUploadError}
                            </p>
                          </IonLabel>
                          <IonCol size="12">
                            <Dropzone
                              ref={dropzoneRef}
                              onDrop={(files, errors) => {
                                setFileUploadError(errors?.length > 0 ? 'Please upload PDF file of license' : '');
                                if (files.length) {
                                  setLicense({ ...license, credentialFileName: files[0].name });
                                  setCredential(files[0]);
                                }
                              }}
                              accept={{
                                'application/pdf': ['.pdf']
                              }}
                              disabled={isLicenseUploaded}
                            >
                              {({ getRootProps }) => (
                                <section>
                                  <div>
                                    <div className="drop-zone" {...getRootProps()}>
                                      <IonIcon icon={document} size="large" />
                                      <br />
                                      Upload license here (PDF)
                                    </div>
                                    <IonItem lines="none" className="input">
                                      <IonLabel slot="start">{license.credentialFileName || 'No Credential'}</IonLabel>
                                      <IonButton
                                        className="add-file"
                                        slot="end"
                                        color="favorite"
                                        fill="outline"
                                        disabled={isLicenseUploaded}
                                        onClick={() => dropzoneRef?.current?.open()}
                                      >
                                        {license.credentialFileName ? 'Change Credential' : 'Add Credential'}
                                      </IonButton>
                                    </IonItem>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </IonCol>
                          <IonCol size="12">
                            {isLicenseUploaded ? (
                              <IonButton
                                color="favorite"
                                expand="block"
                                fill="outline"
                                onClick={() => {
                                  setLicense(initLicense);
                                  setIsLicenseUploaded(false);
                                }}
                              >
                                Already Submitted - Submit Again?
                              </IonButton>
                            ) : (
                              <IonButton
                                color="favorite"
                                expand="block"
                                disabled={isSubmitLicenseDisabled}
                                onClick={onUploadLicense}
                              >
                                Submit
                              </IonButton>
                            )}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="sections">
                <div className="section">
                  <div
                    className="section-header"
                    onClick={() => {
                      if (selectedOption === 'identity') {
                        setSelectedOption('none');
                      } else {
                        setFileUploadError('');
                        setSelectedOption('identity');
                      }
                    }}
                  >
                    <span>
                      <UserIcon />
                      Upload ID Proof
                    </span>
                    {selectedOption === 'identity' ? (
                      <span className="icon-btn">
                        <IonIcon icon={chevronUpOutline} />
                      </span>
                    ) : (
                      <span className="icon-btn">
                        <IonIcon icon={chevronDownOutline} />
                      </span>
                    )}
                  </div>
                  {selectedOption === 'identity' ? (
                    <div className="section-body">
                      <IonGrid>
                        <IonRow>
                          <IonCol size="12">
                            <IonItem lines="none" className="input">
                              <IonInput
                                name="firstname"
                                type="text"
                                placeholder="First name"
                                value={identity.firstname}
                                onIonChange={(e): void => setIdentity({ ...identity, firstname: e.detail.value ?? '' })}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol size="12">
                            <IonItem lines="none" className="input">
                              <IonInput
                                name="lastname"
                                type="text"
                                placeholder="Last name"
                                value={identity.lastname}
                                onIonChange={(e): void => setIdentity({ ...identity, lastname: e.detail.value ?? '' })}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol size="12">
                            <IonItem lines="none" className="input">
                              <IonIcon className="calendar-icon" icon={calendarOutline} />
                              <IonDatetime
                                max={`${moment().subtract(16, 'years').format('YYYY')}-12-31`}
                                displayFormat="MMMM DD YYYY"
                                pickerFormat="YYYY MMMM DD"
                                placeholder="Birthdate"
                                value={identity.birthdate ? moment(identity.birthdate).toLocaleString() : null}
                                onIonChange={(event): void => setIdentity({ ...identity, birthdate: moment(event.detail.value || '').format('YYYY-MM-DD') })}
                              />
                            </IonItem>
                          </IonCol>
                          <IonCol size="6">
                            <IonItem lines="none" className="select">
                              <IonSelect
                                className="select"
                                placeholder="Country"
                                value={identity.country}
                                onIonChange={(e): void => setIdentity({ ...identity, country: String(e.detail.value) })}
                              >
                                <IonSelectOption value="us">United State</IonSelectOption>
                                <IonSelectOption value="ca">Canada</IonSelectOption>
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                          <IonCol size="6">
                            <IonItem lines="none" className="select">
                              <IonSelect
                                className="select"
                                placeholder="ID Type"
                                value={identity.type}
                                disabled={!availableTypes}
                                onIonChange={(e): void => setIdentity({ ...identity, type: String(e.detail.value) })}
                              >
                                {availableTypes?.map((type, index) => (
                                  <IonSelectOption key={index} value={type.name}>
                                    {type.name}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                          <IonCol size="12">
                            <IonItem lines="none" className="input">
                              <IonInput
                                name="number"
                                type="text"
                                placeholder="ID Number"
                                value={identity.number}
                                onIonChange={(e): void => setIdentity({ ...identity, number: e.detail.value ?? '' })}
                              />
                            </IonItem>
                          </IonCol>
                          <IonLabel color="danger">
                            <p style={{ marginLeft: 10 }}>
                              {fileUploadError}
                            </p>
                          </IonLabel>
                          <IonCol size="12">
                            <Dropzone
                              onDrop={(files, errors) => {
                                setFileUploadError(errors?.length > 0 ? 'Please upload image file of ID' : '');
                                if (files.length) {
                                  setIdentity({ ...identity, frontImage: files[0].name });
                                  setPhotoID(files[0]);
                                }
                              }}
                              accept={{
                                'image/*': ['.jpeg', '.png']
                              }}
                            >
                              {({ getRootProps }) => (
                                <section>
                                  <div {...getRootProps()}>
                                    <div className="drop-zone">
                                      {photoID ? (
                                        <img src={URL.createObjectURL(photoID)} alt="id" />
                                      ) : (
                                        <>
                                          <IonIcon icon={person} size="large" />
                                          <br />
                                          Upload your ID here
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </IonCol>
                          <IonCol size="12">
                            <IonButton
                              color="favorite"
                              expand="block"
                              disabled={isSubmitIdentityDisabled || isIdentityUploaded}
                              onClick={onUploadIdentity}
                            >
                              {isIdentityUploaded ? 'Submitted' : 'Submit'}
                            </IonButton>
                          </IonCol>
                          <input
                            ref={inputRef}
                            type="file"
                            id="myfile"
                            name="myfile"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(e) => {
                              if (e.target.files) {
                                setIdentity({ ...identity, frontImage: e.target.files[0].name });
                                setPhotoID(e.target.files[0] as any);
                              }
                            }}
                          />
                        </IonRow>
                      </IonGrid>
                    </div>
                  ) : null}
                </div>
              </div>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className="actions">
        <IonButton color="favorite" onClick={prev}>
          <IonIcon icon={chevronBackOutline} />
          Back
        </IonButton>
        <IonButton
          color="favorite"
          disabled={!isLicenseUploaded && !isIdentityUploaded}
          onClick={onSubmit}
        >
          Next
          <IonIcon icon={chevronForwardOutline} />
        </IonButton>
      </div>

      <IonAlert
        isOpen={showUploadSuccessAlert.isOpen}
        onDidDismiss={(): void => {
          setShowUploadSuccessAlert({ isOpen: false, type: '' });
        }}
        header="Care Platform"
        message={
          showUploadSuccessAlert.type === 'license' ? 'License has been uploaded successfully.'
            : 'ID Information has been uploaded successfully'
        }
        buttons={['Ok']}
      />
      <IonLoading isOpen={uploading} />
    </div>
  );
};

SixthStep.propTypes = {
  user: PropTypes.any.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<SixthStepProps, {}, {}>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(SixthStep)
});
