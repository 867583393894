import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const FileIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
    <path d="M13.1667 10.4167C13.1667 10.7482 13.0351 11.0661 12.8006 11.3006C12.5662 11.535 12.2483 11.6667 11.9167 11.6667H6.08342C5.75189 11.6667 5.43395 11.535 5.19953 11.3006C4.96511 11.0661 4.83342 10.7482 4.83342 10.4167C4.83342 10.0852 4.96511 9.76722 5.19953 9.5328C5.43395 9.29838 5.75189 9.16669 6.08342 9.16669H11.9167C12.2483 9.16669 12.5662 9.29838 12.8006 9.5328C13.0351 9.76722 13.1667 10.0852 13.1667 10.4167ZM9.41675 13.3334H6.08342C5.75189 13.3334 5.43395 13.465 5.19953 13.6995C4.96511 13.9339 4.83342 14.2518 4.83342 14.5834C4.83342 14.9149 4.96511 15.2328 5.19953 15.4672C5.43395 15.7017 5.75189 15.8334 6.08342 15.8334H9.41675C9.74827 15.8334 10.0662 15.7017 10.3006 15.4672C10.5351 15.2328 10.6667 14.9149 10.6667 14.5834C10.6667 14.2518 10.5351 13.9339 10.3006 13.6995C10.0662 13.465 9.74827 13.3334 9.41675 13.3334ZM17.3334 6.79752V15.4167C17.3321 16.6319 16.8488 17.7969 15.9895 18.6561C15.1303 19.5154 13.9653 19.9987 12.7501 20H5.25008C4.03491 19.9987 2.86989 19.5154 2.01064 18.6561C1.15138 17.7969 0.668072 16.6319 0.666748 15.4167V4.58335C0.668072 3.36818 1.15138 2.20316 2.01064 1.34391C2.86989 0.484653 4.03491 0.00134198 5.25008 1.85091e-05H10.5359C11.1381 -0.00169862 11.7346 0.116077 12.2909 0.346522C12.8472 0.576968 13.3522 0.915503 13.7767 1.34252L15.9909 3.55669C16.4179 3.98128 16.7563 4.48634 16.9868 5.04263C17.2172 5.59892 17.335 6.19539 17.3334 6.79752ZM14.8334 15.4167V7.50002H11.5001C11.0581 7.50002 10.6341 7.32442 10.3216 7.01186C10.009 6.6993 9.83342 6.27538 9.83342 5.83335V2.50002H5.25008C4.69755 2.50002 4.16764 2.71951 3.77694 3.11021C3.38624 3.50091 3.16675 4.03082 3.16675 4.58335V15.4167C3.16675 15.9692 3.38624 16.4991 3.77694 16.8898C4.16764 17.2805 4.69755 17.5 5.25008 17.5H12.7501C13.3026 17.5 13.8325 17.2805 14.2232 16.8898C14.6139 16.4991 14.8334 15.9692 14.8334 15.4167Z" fill={color} />
  </svg>
);

export default FileIcon;
