import React, { createContext, ReactNode } from 'react';
import {
  ConnectOptions,
  Room,
  TwilioError,
  LocalAudioTrack,
  LocalVideoTrack
} from 'twilio-video';
import { Callback, ErrorCallback } from '../../../data/VideoTypes';
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';

import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors';
import useHandleOnDisconnect from './useHandleOnDisconnect/useHandleOnDisconnect';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRoom from './useRoom/useRoom';

export interface IVideoContext {
  room: Room
  localTracks: (LocalAudioTrack | LocalVideoTrack)[]
  isConnecting: boolean
  connect: (token: string) => Promise<void>
  onError: ErrorCallback
  onDisconnect: Callback
  getLocalVideoTrack: () => Promise<LocalVideoTrack>
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions
  onError: ErrorCallback
  onDisconnect?: Callback
  children: ReactNode
}

export function VideoProvider({
  options,
  children,
  onError = () => {},
  onDisconnect = () => {}
}: VideoProviderProps) {
  const onErrorCallback = (error: TwilioError) => {
    console.log(`ERROR: ${error.message}`, error);
    onError(error);
  };

  const { localTracks, getLocalVideoTrack } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(
    localTracks,
    onErrorCallback,
    options
  );

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError);
  useHandleTrackPublicationFailed(room, onError);
  useHandleOnDisconnect(room, onDisconnect);

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        getLocalVideoTrack,
        connect
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
    </VideoContext.Provider>
  );
}
