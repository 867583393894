import { LocalAudioTrack } from 'twilio-video';
import { useCallback, useEffect, useState } from 'react';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useLocalAudioToggle() {
  const [hasMicrophone, setHasMicrophone] = useState(false);

  const { localTracks } = useVideoContext();
  const audioTrack = localTracks.find(
    (track) => track.kind === 'audio'
  ) as LocalAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack]);

  useEffect(() => {
    const checkAudioDevice = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      setHasMicrophone(!!devices.find((dv) => dv.deviceId && dv.kind === 'audioinput'));
    };

    checkAudioDevice();
  }, [navigator]);

  return [hasMicrophone, isEnabled, toggleAudioEnabled] as const;
}
