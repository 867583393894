import React from 'react';
import {
  IonContent, IonItem, IonList, IonListHeader,
} from '@ionic/react';

import connect from '../../data/connect';
import { EmailIcon } from '../../icons';
import './ContactUs.scss';

const ContactUs: React.FC<{}> = () => (
  <IonContent id="contact-us" className="page-content ion-padding">
    <IonListHeader className="page-header">Contact Us</IonListHeader>
    <IonList>
      <IonItem lines="none">
        <EmailIcon />
        info@teacuptechsystems.com
      </IonItem>
    </IonList>
  </IonContent>
);

export default connect<{}, {}, {}>({
  mapStateToProps: (state) => ({
    providers: state.data.providers
  }),
  component: React.memo(ContactUs)
});
