import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TimeIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g clipPath="url(#clip0_34_514)">
      <path d="M8.70833 22H1.375C1.01033 22 0.660591 21.8551 0.402728 21.5973C0.144866 21.3394 0 20.9897 0 20.625C0 20.2603 0.144866 19.9106 0.402728 19.6527C0.660591 19.3949 1.01033 19.25 1.375 19.25H8.70833C9.07301 19.25 9.42274 19.3949 9.6806 19.6527C9.93847 19.9106 10.0833 20.2603 10.0833 20.625C10.0833 20.9897 9.93847 21.3394 9.6806 21.5973C9.42274 21.8551 9.07301 22 8.70833 22Z" fill={color} />
      <path d="M6.875 17.4167H1.375C1.01033 17.4167 0.660591 17.2719 0.402728 17.014C0.144866 16.7562 0 16.4064 0 16.0417C0 15.6771 0.144866 15.3273 0.402728 15.0695C0.660591 14.8116 1.01033 14.6667 1.375 14.6667H6.875C7.23967 14.6667 7.58941 14.8116 7.84727 15.0695C8.10513 15.3273 8.25 15.6771 8.25 16.0417C8.25 16.4064 8.10513 16.7562 7.84727 17.014C7.58941 17.2719 7.23967 17.4167 6.875 17.4167Z" fill={color} />
      <path d="M5.04167 12.8333H1.375C1.01033 12.8333 0.660591 12.6884 0.402728 12.4305C0.144866 12.1727 0 11.8229 0 11.4583C0 11.0936 0.144866 10.7438 0.402728 10.486C0.660591 10.2281 1.01033 10.0833 1.375 10.0833H5.04167C5.40634 10.0833 5.75608 10.2281 6.01394 10.486C6.2718 10.7438 6.41667 11.0936 6.41667 11.4583C6.41667 11.8229 6.2718 12.1727 6.01394 12.4305C5.75608 12.6884 5.40634 12.8333 5.04167 12.8333Z" fill={color} />
      <path d="M13.2921 21.7451C12.9555 21.7455 12.6305 21.6223 12.3786 21.399C12.1267 21.1757 11.9655 20.8677 11.9255 20.5335C11.8855 20.1993 11.9695 19.862 12.1616 19.5855C12.3536 19.3091 12.6404 19.1127 12.9676 19.0336C14.3236 18.7012 15.5733 18.031 16.6003 17.0853C17.6273 16.1397 18.3982 14.9495 18.8412 13.6256C19.2842 12.3016 19.3849 10.8871 19.1338 9.51383C18.8827 8.14054 18.288 6.85315 17.4052 5.77165C16.5224 4.69014 15.3802 3.84972 14.085 3.32869C12.7898 2.80766 11.3838 2.62298 9.99799 2.79187C8.61218 2.96076 7.29167 3.47772 6.15953 4.29457C5.02738 5.11141 4.12044 6.20157 3.52323 7.46344C3.36395 7.78772 3.08345 8.03629 2.74236 8.15539C2.40127 8.27449 2.02702 8.25457 1.7005 8.09993C1.37399 7.94528 1.12146 7.66834 0.997514 7.32899C0.873567 6.98963 0.888162 6.61512 1.03814 6.28644C1.83396 4.6044 3.04271 3.15123 4.55171 2.06242C6.06071 0.973603 7.82083 0.284591 9.66799 0.0596114C11.5151 -0.165368 13.3892 0.081012 15.1154 0.775782C16.8417 1.47055 18.3639 2.59109 19.5402 4.03294C20.7165 5.47479 21.5086 7.191 21.8426 9.02159C22.1766 10.8522 22.0416 12.7375 21.4504 14.5019C20.8591 16.2663 19.8307 17.8522 18.461 19.1118C17.0914 20.3715 15.4251 21.2638 13.6176 21.7057C13.511 21.7317 13.4018 21.7449 13.2921 21.7451Z" fill={color} />
      <path d="M11.458 5.5C11.0933 5.5 10.7436 5.64487 10.4857 5.90273C10.2279 6.16059 10.083 6.51033 10.083 6.875V10.8103C10.0831 11.2964 10.2763 11.7627 10.6202 12.1064L12.2638 13.75C12.5231 14.0005 12.8704 14.1391 13.2309 14.1359C13.5915 14.1328 13.9363 13.9882 14.1913 13.7333C14.4462 13.4783 14.5908 13.1334 14.5939 12.7729C14.5971 12.4124 14.4585 12.0651 14.208 11.8057L12.833 10.4307V6.875C12.833 6.51033 12.6881 6.16059 12.4303 5.90273C12.1724 5.64487 11.8227 5.5 11.458 5.5Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_34_514">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TimeIcon;
