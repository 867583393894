import React from 'react';
import PropTypes from 'prop-types';
import { IonItem, IonLabel } from '@ionic/react';

import Avatar from './Avatar';
import { Booking, UserRole } from '../models';
import StarRating from './StarRating';
import { getImage } from '../data/dataApi';

interface RatingItemProps {
  booking: Booking
}

const RatingItem: React.FC<RatingItemProps> = ({ booking }) => {
  const feedback = booking.feedbacks.find(
    (item) => item.type === UserRole.PROVIDER
  );
  return (
    <>
      <IonItem lines="none" className="rating-item">
        <Avatar user={booking.customer} />
        <IonLabel>
          <h2>{booking.customer.name}</h2>
          <p>{`${booking.date} ${booking.time}`}</p>
          <StarRating readonly rating={(feedback && feedback.rating) || 0} />
        </IonLabel>
      </IonItem>
      {feedback && feedback.review ? (
        <IonItem lines="none" className="rating-item feedback">
          <IonLabel className="feedback">{feedback.review}</IonLabel>
        </IonItem>
      ) : null}
    </>
  );
};

RatingItem.propTypes = {
  booking: PropTypes.any.isRequired
};

export default RatingItem;
