import React, {
  useEffect, useRef, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  IonCol,
  IonGrid,
  IonRow,
  IonList,
  IonItem,
  IonButton,
  IonIcon,
  IonLoading,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { chevronForwardOutline, chevronBackOutline } from 'ionicons/icons';
import Dropzone from 'react-dropzone';

import connect from '../../../data/connect';
import PlaceSearchInput from '../../../components/PlaceSearchInput';
import Map from '../../../components/Map';
import { updateProviderProfile, uploadProfileImage, getImage } from '../../../data/dataApi';
import { dispatchUpdateUser } from '../../../data/store/auth/auth.actions';
import {
  Location, User, UserRole
} from '../../../models';
import { LocationIcon } from '../../../icons';

import emptyImg from '../../../assets/user.jpg';

interface SecondStepProps {
  user: User
  next: () => void
  prev: () => void
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

const SecondStep: React.FC<SecondStepProps & DispatchProps> = ({
  user, next, prev, updateUser
}) => {
  const [location, setLocation] = useState<Location | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onAddLocation = (): void => {
    if (location) {
      setLocations([...locations, location]);
      setLocation(null);
      setSelectedLocation(location);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    if (file) {
      await uploadProfileImage(user.id, file);
    }
    const nextStep = user.role === UserRole.PROVIDER ? 2 : 3;
    updateProviderProfile(user.id, {
      profile: {
        onboardingStep: user.onboardingStep > nextStep ? user.onboardingStep : nextStep
      },
      languages: user.languages.map((language) => language.id),
      specialists: user.specialists.map((specialist) => specialist.id),
      locations,
      services: user.services
    })
      .then((res) => {
        if (res.success) {
          updateUser(res.user);
          next();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const avatarImage = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }
    if (user.image) {
      return getImage(user.image);
    }
    return emptyImg;
  }, [file, user]);

  const isSubmitDisabled = useMemo(() => {
    if (locations.length > 0) {
      return false;
    }
    return true;
  }, [locations]);

  useEffect(() => {
    if (user) {
      if (user.locations && user.locations.length > 0) {
        setLocations(user.locations);
      }
    }
  }, [user]);

  return (
    <div id="step-two">
      <IonGrid className="step-content">
        <IonRow>
          <IonCol>
            <IonList className="step-container">
              <IonItem lines="none" className="input">
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <PlaceSearchInput
                        value={location?.address}
                        onChange={(value): void => setLocation(value)}
                      />
                    </IonCol>
                    <IonCol size="1.25">
                      <IonButton
                        color="favorite"
                        disabled={!location?.address}
                        onClick={(): void => onAddLocation()}
                      >
                        Add
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonItem>
              <IonItem lines="none" className="select">
                <div className="location-selector">
                  <LocationIcon />
                  <IonSelect
                    className="select"
                    interface="action-sheet"
                    value={selectedLocation}
                    disabled={locations.length < 1}
                    placeholder="Select location..."
                    onIonChange={(e): void => setSelectedLocation(e.detail.value)}
                  >
                    {locations.map((item) => (
                      <IonSelectOption key={item.id} value={item}>
                        {item.address}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </div>
              </IonItem>
              <IonItem lines="none" className="map">
                <div className="map-container">
                  <Map
                    locations={locations}
                    mapCenter={{ lat: selectedLocation?.lat, lng: selectedLocation?.lng }}
                  />
                </div>
              </IonItem>
              <IonItem lines="none" className="upload">
                <div className="upload-container">
                  <div className="upload-header">
                    <h3>Upload Photo</h3>
                    <p>Optional</p>
                  </div>
                  <Dropzone
                    noClick
                    onDrop={(files) => {
                      if (files.length) {
                        setFile(files[0]);
                      }
                    }}
                    accept={{
                      'image/*': ['.jpeg', '.png']
                    }}
                  >
                    {({ getRootProps }) => (
                      <section>
                        <div className="upload-panel" {...getRootProps()}>
                          <img
                            src={avatarImage}
                            alt="avatar"
                          />
                          <div className="panel">
                            <p>
                              Upload photo&nbsp;
                              <span onClick={() => inputRef.current?.click()}>here</span>
                            </p>
                            <span>
                              Image size should be at least 320px big, and less then 500kb. Alloweb .png and .jpg
                            </span>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </IonItem>
              <input
                ref={inputRef}
                type="file"
                id="myfile"
                name="myfile"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(e) => {
                  if (e.target.files) {
                    setFile(e.target.files[0] as any);
                  }
                }}
              />
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className="actions">
        <IonButton color="favorite" onClick={prev}>
          <IonIcon icon={chevronBackOutline} />
          Back
        </IonButton>
        <IonButton color="favorite" disabled={isSubmitDisabled} onClick={onSubmit}>
          Next
          <IonIcon icon={chevronForwardOutline} />
        </IonButton>
      </div>
      <IonLoading isOpen={isLoading} />
    </div>
  );
};

SecondStep.propTypes = {
  user: PropTypes.any.isRequired,
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<SecondStepProps, {}, DispatchProps>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(SecondStep)
});
