import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { IonButton, IonCol } from '@ionic/react';

import { exportBookingReceipt } from '../data/dataApi';
import {
  Charge, ChargeStatus, UserRole, BookingStatus
} from '../models';

interface PaymentItemProps {
  index: number
  charge: Charge
  role: UserRole
}

const PaymentItem: React.FC<PaymentItemProps> = ({ index, charge, role }) => {
  const { booking } = charge;

  const getBookingReceiptPDF = async (bookingId: number) => {
    const url = await exportBookingReceipt(bookingId);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `Booking_Receipt_${bookingId}.pdf`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  };

  const reason = useMemo(() => {
    if (charge.status && booking) {
      if (booking.status === BookingStatus.ACCEPTED) {
        return 'Accepted by Provider';
      }
      if (booking.status === BookingStatus.COMPLETED) {
        return 'Completed';
      }
      if (booking.status === BookingStatus.REJECTED_CUSTOMER) {
        return 'Pending appointment cancelled by Customer';
      }
      if (booking.status === BookingStatus.REJECTED_PROVIDER) {
        return 'Pending appointment rejected by Provider';
      }
      if (booking.status === BookingStatus.CANCELLED_PROVIDER) {
        return 'Accepted appointment cancelled by Provider';
      }
      if (booking.status === BookingStatus.CANCELLED_CUSTOMER && charge.chargedAmount === 0) {
        return 'Accepted appointment cancelled by Customer';
      }
      if (booking.status === BookingStatus.CANCELLED_CUSTOMER && charge.chargedAmount === charge.amount) {
        return 'Accepted appointment cancelled by Customer within one day of booking date';
      }
      if (booking.status === BookingStatus.CANCELLED_CUSTOMER && charge.chargedAmount) {
        return 'Accepted appointment cancelled by Customer one day prior to booking date';
      }
      if (booking.status === BookingStatus.INCOMPLETE && !charge.chargedAmount && charge.status === ChargeStatus.REFUNDED && !charge.stripeFee) {
        return 'Appointment not accepted';
      }
      if (booking.status === BookingStatus.INCOMPLETE && !charge.chargedAmount && charge.status === ChargeStatus.REFUNDED && charge.stripeFee) {
        return 'Provider did not join the call';
      }
      if (booking.status === BookingStatus.INCOMPLETE && charge.chargedAmount && charge.status === ChargeStatus.CHARGED && charge.providerFee > 0) {
        return 'Customer did not join the call';
      }
      if (booking.status === BookingStatus.INCOMPLETE && charge.chargedAmount && charge.status === ChargeStatus.CHARGED && charge.providerFee <= 0) {
        return 'Both of provider and customer did not join the call';
      }
      return 'Hold now';
    }
    return '';
  }, [booking, charge]);

  if (!booking) {
    return null;
  }

  return (
    <>
      {charge.status === ChargeStatus.CHARGED ? (
        <>
          <IonCol className="sm">
            {index}
          </IonCol>
          <IonCol className="sm">
            {booking.id}
          </IonCol>
          <IonCol>
            {role === UserRole.CUSTOMER ? `${charge.to.name} ${charge.to.lastName ?? ''}` : `${charge.from.name} ${charge.from.lastName ?? ''}`}
          </IonCol>

          {/* date column */}
          <IonCol>
            {moment.utc(`${booking.date} ${booking.time}`).local().format('YYYY-MM-DD hh:mm A')}
          </IonCol>

          {/* service column */}
          <IonCol>
            {`${booking.service} - ${booking.duration}m - $${booking.price}`}
          </IonCol>

          <IonCol className="charged">
            {charge.providerFee < 0 ? `-$${charge.providerFee * -1}` : `$${charge.providerFee}`}
          </IonCol>
          <IonCol className="captured">
            {booking.status === BookingStatus.ACCEPTED ? `$${charge.amount}` : `$${charge.chargedAmount}`}
          </IonCol>
          <IonCol className="refunded">
            {booking.status === BookingStatus.ACCEPTED ? '$0' : `$${(charge.amount * 1000 - charge.chargedAmount * 1000) / 1000}`}
          </IonCol>
          <IonCol className="fee">
            {charge.cpFee < 0 ? `-$${charge.cpFee * -1}` : `$${charge.cpFee}`}
          </IonCol>
          <IonCol>
            {`$${charge.stripeFee}`}
          </IonCol>
          <IonCol>
            {`$${charge.twilioFee}`}
          </IonCol>
          {
            role === UserRole.CLINIC_ADMIN
            || role === UserRole.ADMIN
            || role === UserRole.REVENUE_ANALYST
            || role === UserRole.HELPDESK ? <IonCol>{`${charge.to.name} ${charge.to.lastName ?? ''}`}</IonCol> : null
          }
          <IonCol>
            {reason}
          </IonCol>
          <IonCol className="status">
            <p className="charged">{`${charge.status.charAt(0).toUpperCase()}${charge.status.slice(1)}`}</p>
          </IonCol>
          <IonCol>
            <span className="receipt-btn" onClick={() => getBookingReceiptPDF(booking.id)}>
              Receipt
            </span>
          </IonCol>
        </>
      ) : null}
      {charge.status === ChargeStatus.REFUNDED || charge.status === ChargeStatus.RELEASED ? (
        <>
          <IonCol className="sm">
            {index}
          </IonCol>
          <IonCol className="sm">
            {booking.id}
          </IonCol>
          <IonCol>
            {role === UserRole.CUSTOMER ? `${charge.to.name} ${charge.to.lastName ?? ''}` : `${charge.from.name} ${charge.from.lastName ?? ''}`}
          </IonCol>

          {/* date column */}
          <IonCol>
            {moment.utc(`${booking.date} ${booking.time}`).local().format('YYYY-MM-DD hh:mm A')}
          </IonCol>

          {/* service column */}
          <IonCol>
            {`${booking.service} - ${booking.duration}m - $${booking.price}`}
          </IonCol>

          <IonCol className="charged">
            {charge.providerFee < 0 ? `-$${charge.providerFee * -1}` : `$${charge.providerFee}`}
          </IonCol>
          <IonCol className="captured">
            $0
          </IonCol>
          <IonCol className="refunded">
            {`$${charge.amount}`}
          </IonCol>
          <IonCol className="fee">
            {charge.cpFee < 0 ? `-$${charge.cpFee * -1}` : `$${charge.cpFee}`}
          </IonCol>
          <IonCol>
            {`$${charge.stripeFee}`}
          </IonCol>
          <IonCol>
            {`$${charge.twilioFee}`}
          </IonCol>
          {
            role === UserRole.CLINIC_ADMIN
            || role === UserRole.ADMIN
            || role === UserRole.REVENUE_ANALYST
            || role === UserRole.HELPDESK ? <IonCol>{`${charge.to.name} ${charge.to.lastName ?? ''}`}</IonCol> : null
          }
          <IonCol>
            {reason}
          </IonCol>
          <IonCol className="status">
            <p className="refunded">{`${charge.status.charAt(0).toUpperCase()}${charge.status.slice(1)}`}</p>
          </IonCol>
          <IonCol>
            <span className="receipt-btn" onClick={() => getBookingReceiptPDF(booking.id)}>
              Receipt
            </span>
          </IonCol>
        </>
      ) : null}
      {charge.status === ChargeStatus.HOLD ? (
        <>
          <IonCol className="sm">
            {index}
          </IonCol>
          <IonCol className="sm">
            {booking.id}
          </IonCol>
          <IonCol>
            {role === UserRole.CUSTOMER ? `${charge.to.name} ${charge.to.lastName ?? ''}` : `${charge.from.name} ${charge.from.lastName ?? ''}`}
          </IonCol>

          {/* date column */}
          <IonCol>
            {moment.utc(`${booking.date} ${booking.time}`).local().format('YYYY-MM-DD hh:mm A')}
          </IonCol>

          {/* service column */}
          <IonCol>
            {`${booking.service} - ${booking.duration}m - $${booking.price}`}
          </IonCol>

          <IonCol className="charged">
            $0
          </IonCol>
          <IonCol className="captured">
            <p className="hold">{`$${charge.amount}`}</p>
          </IonCol>
          <IonCol className="refunded">
            $0
          </IonCol>
          <IonCol className="fee">
            $0
          </IonCol>
          <IonCol>
            $0
          </IonCol>
          <IonCol>
            $0
          </IonCol>
          {
            role === UserRole.CLINIC_ADMIN
            || role === UserRole.ADMIN
            || role === UserRole.REVENUE_ANALYST
            || role === UserRole.HELPDESK ? <IonCol>{`${charge.to.name} ${charge.to.lastName ?? ''}`}</IonCol> : null
          }
          <IonCol>
            {reason}
          </IonCol>
          <IonCol className="status">
            <p className="hold">{`${charge.status.charAt(0).toUpperCase()}${charge.status.slice(1)}`}</p>
          </IonCol>
          <IonCol />
        </>
      ) : null}
    </>
  );
};

PaymentItem.propTypes = {
  index: PropTypes.number.isRequired,
  charge: PropTypes.any.isRequired,
  role: PropTypes.any.isRequired
};

export default PaymentItem;
