import React, {
  createContext, useCallback, useEffect, useContext, useState
} from 'react';
import { SyncClient, SyncList } from 'twilio-sync';

import { updateUser } from './dataApi';
import { User } from '../models';
import { TwilioSyncListName } from './constants';
import { AppContext } from './AppContext';

interface StatusContextState {
  token: string;
  syncList: SyncList | null
  syncConnect: (token: string, user: User) => Promise<void>;
  syncDisconnect: (user: User) => Promise<void>;
  updateStatus: (user: User, status: string) => Promise<void>;
}

export const StatusContext = createContext<StatusContextState>(null!);

export const StatusContextProvider: React.FC = ({ children }: any) => {
  const { dispatch } = useContext(AppContext);
  const [token, setToken] = useState('');
  const [syncClient, setSyncClient] = useState<SyncClient | null>(null);
  const [syncList, setSyncList] = useState<SyncList | null>(null);

  const syncConnect = useCallback(async (syncToken: string, user: User) => {
    setToken(syncToken);
    const client = new SyncClient(syncToken);
    const list = await client.list(TwilioSyncListName);
    // await list.removeList();
    console.log('Status of users: ', await list.getItems({ limit: 100 }));
    try {
      if (user && user.syncId > -1) {
        await list.update(user.syncId, { name: user.name, status: 'online' });
      } else {
        const res = await list.push({ name: user.name, status: 'online' });
        if (typeof res.index === 'number' && res.index > -1) {
          const data: any = await updateUser(user.id, { syncId: res.index });
          dispatch({
            type: '@user/set-user',
            data: data?.user
          });
        }
      }
    } catch (error) {
      console.log(error);
    }

    client.on('connectionStateChanged', (newState) => {
      if (newState === 'disconnected') {
        const url = `${window.location.origin}/login`;
        window.location.href = url;
      }
    });

    setSyncClient(client);
    setSyncList(list);
  }, []);

  const syncDisconnect = useCallback(async (user: User) => {
    try {
      if (syncList && user && user.syncId > -1) {
        await updateUser(user.id, { syncId: -1 });
        await syncList.remove(user.syncId);
      }
      if (syncClient) {
        setSyncClient(null);
      }
    } catch (error) {
      console.log(error);
    }
    setSyncList(null);
    setSyncClient(null);
  }, [syncList, setSyncList, syncClient, setSyncClient]);

  const updateStatus = useCallback(async (user: User, status: string) => {
    try {
      if (syncList && user && user.syncId > -1) {
        await syncList.update(user.syncId, { name: user.name, status });
      }
    } catch (err) {
      console.log(err);
    }
  }, [syncList, token]);

  return (
    <StatusContext.Provider
      value={{
        token,
        syncList,
        syncConnect,
        syncDisconnect,
        updateStatus
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};
