import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IonLoading, IonPage } from '@ionic/react';
import { ConnectOptions } from 'twilio-video';
import clsx from 'clsx';

import VideoStateProvider, { useAppState } from '../../data/VideoContext';
import { VideoProvider } from '../../components/Video/VideoProvider';
import { ChatProvider } from '../../components/Video/ChatProvider';
import Controls from '../../components/Video/Controls/Controls';
import connect from '../../data/connect';
import LocalVideoPreview from '../../components/Video/LocalVideoPreview/LocalVideoPreview';
import MenuBar from '../../components/Video/MenuBar/MenuBar';
import Room from '../../components/Video/Room/Room';
import ChatWindow from '../../components/Video/ChatWindow/ChatWindow';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useChatContext from '../../hooks/useChatContext/useChatContext';
import { AppContext } from '../../data/AppContext';
import { fetchBooking } from '../../data/dataApi';
import { BaseURL } from '../../data/constants';
import { Booking } from '../../models';

import './VideoApp.scss';

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions: ConnectOptions = {
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      dominantSpeakerPriority: 'standard',
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 720, width: 1280 },
        low: { height: 90, width: 160 }
      }
    }
  },
  dominantSpeaker: true,
  maxAudioBitrate: 12000,
  networkQuality: { local: 1, remote: 1 },
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }]
};

const AAA: React.FC = () => {
  const roomState = useRoomState();
  const { room } = useParams();
  const history = useHistory();
  const [currentDate, setCurrentDate] = useState(new Date());
  const {
    isChatWindowOpen,
    setIsChatWindowOpen,
  } = useChatContext();
  const { state: { auth: { user: { role } } } } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [booking, setBooking] = useState<Booking | null>(null);

  useEffect(() => {
    if (roomState === 'disconnected') {
      setIsChatWindowOpen(false);
    }
  }, [roomState, setIsChatWindowOpen]);

  useEffect(() => {
    setIsLoading(true);
    fetchBooking(Number(room))
      .then((res) => {
        if (res.success) {
          setBooking(res.booking);
        }
      })
      .catch((err) => {
        console.log(err);
        history.goBack();
      })
      .finally(() => setIsLoading(false));
  }, [room]);

  useEffect(() => {
    const timer = setInterval(() => {
      const datetime = new Date();
      setCurrentDate(datetime);
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleTabClose = (event: BeforeUnloadEvent) => {
      navigator.sendBeacon(`${BaseURL}/utility/upload-transcript/${role}/${room}`);
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return (
    <div className="video-container">
      {isLoading || !booking ? <IonLoading isOpen={isLoading} /> : (
        <>
          <MenuBar data={booking} />
          <div className={clsx('main', { enableChat: isChatWindowOpen })}>
            {roomState === 'disconnected'
              ? <LocalVideoPreview />
              : <Room booking={booking} currentDate={currentDate} setBooking={setBooking} />}
            <Controls booking={booking} currentDate={currentDate} />
          </div>
          <ChatWindow booking={booking} />
          {/* <ReconnectingNotification /> */}
        </>
      )}
    </div>
  );
};

const VideoApp: React.FC = () => {
  const { error, setError } = useAppState();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ChatProvider>
        {/* <ErrorDialog dismissError={() => setError(null)} error={error} /> */}
        <AAA />
      </ChatProvider>
    </VideoProvider>
  );
};

const VideoView: React.FC<RouteComponentProps> = ({
  location
}) => (
  <VideoStateProvider>
    <IonPage id={location.pathname.includes('/video/') ? 'video-room' : ''}>
      <VideoApp />
    </IonPage>
  </VideoStateProvider>
);

VideoView.propTypes = {
  location: PropTypes.any.isRequired,
};

export default connect<{}, {}, {}>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {},
  component: withRouter(VideoView)
});
