import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  IonContent,
  IonListHeader,
  IonList,
  IonItem,
  IonAvatar,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonLoading,
  IonAlert,
  IonText,
  IonToggle,
  IonGrid,
  IonRow,
  IonCol,
  IonModal
} from '@ionic/react';
import { brushOutline, checkmarkCircle, closeOutline } from 'ionicons/icons';
import clsx from 'clsx';

import connect from '../../data/connect';
import {
  User, Language, Specialist, Service, VerificationStatus, Location
} from '../../models';
import {
  updateProviderProfile,
  uploadProfileImage,
  getImage,
  getSignedUrl,
  deleteLocation
} from '../../data/dataApi';
import { dispatchUpdateUser } from '../../data/store/auth/auth.actions';
import ServiceManagement from '../../components/ServiceManagement';
import Map from '../../components/Map';
import PlaceSearchInput from '../../components/PlaceSearchInput';
import {
  UserIcon, PhoneIcon, ListIcon, SphereIcon, LocationIcon
} from '../../icons';
import IdentityModal from '../../components/Modal/IdentityModal';
import LicenseModal from '../../components/Modal/LicenseModal';
import ChangePasswordModal from '../../components/Modal/ChangePasswordModal';
import './ClinicMemberProfileManagement.scss';

interface StateProps {
  allLanguages: Language[]
  allSpecialists: Specialist[]
  user: User
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

type ClinicMemberProfileProps = StateProps & DispatchProps;

const ClinicMemberProfile: React.FC<ClinicMemberProfileProps> = ({
  user,
  allLanguages,
  allSpecialists,
  updateUser
}) => {
  const [avatar, setAvatar] = useState('');
  const [file, setFile] = useState();
  const history = useHistory();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [about, setAbout] = useState('');
  const [languages, setLanguages] = useState([] as number[]);
  const [specialists, setSpecialists] = useState([] as number[]);
  const [services, setServices] = useState<Service[]>([]);
  const [location, setLocation] = useState<Location | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(user.locations[0] ?? null);
  const [locations, setLocations] = useState(user.locations);
  const [type, setType] = useState(user.type);
  const [smsEnabled, setSmsEnabled] = useState(user.smsEnabled);
  const [emailEnabled, setEmailEnabled] = useState(user.emailEnabled);
  const [notificationEnabled, setNotificationEnabled] = useState(user.notificationEnabled);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: '', status: false });
  const [showLocationsModal, setShowLocationsModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmLocationDelete, setShowConfirmLocationDelete] = useState({
    locationId: 0,
    open: false
  });

  // state for change password
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  // state for license
  const [showLicenseModal, setShowLicenseModal] = useState(false);

  // state for identity
  const [showIdentityModal, setShowIdentityModal] = useState(false);
  const [showRejectedReason, setShowRejectedReason] = useState<'none' | 'identity' | 'license'>('none');

  const inputRef = useRef<HTMLInputElement>(null);

  const onAddLocation = (): void => {
    if (location) {
      setLocations([...locations, location]);
      setLocation({ ...location, address: '' });
    }
  };

  const onRemoveLocation = (locationId: number, address: string, shouldCheckBooking: boolean) => {
    if (!locationId) {
      return setLocations(locations.filter((loc) => loc.address !== address));
    }
    const data = {
      locationId,
      noCheckBooking: !shouldCheckBooking
    };
    setIsDeleting(true);
    return deleteLocation(data)
      .then((res: { success: boolean }) => {
        if (res.success) {
          const updatedLocations = locations.filter((loc) => loc.id !== locationId);
          setLocations([...updatedLocations]);
          if (!updatedLocations.length) {
            setShowLocationsModal(false);
            setSelectedLocation(null);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setShowConfirmLocationDelete({
            locationId,
            open: true
          });
        }
      })
      .finally(() => setIsDeleting(false));
  };

  const onUpdateProfile = (): void => {
    if (
      name.length === 0
      || email.length === 0
      || !type
      || !specialists
      || !specialists.length
      || !locations
      || !locations.length
    ) {
      return setShowAlert({
        message: 'Please add all mandatory fields',
        status: true
      });
    }
    if (user) {
      setLoading(true);
      if (file) {
        uploadProfileImage(user.id, file);
      }
      updateProviderProfile(user.id, {
        profile: {
          name,
          email,
          title,
          type,
          about,
          smsEnabled,
          emailEnabled,
          notificationEnabled
        },
        languages,
        specialists,
        services,
        locations,
      })
        .then((res: { success: boolean, user: User }) => {
          if (res.success) {
            updateUser(res.user);
            setShowAlert({
              message: 'Profile Updated Successfully',
              status: true
            });
          }
        })
        .catch(() => {
          setShowAlert({
            message: 'Something went wrong. Please try again ',
            status: true
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const getTypeId = (str: string) => {
    let typeId: number;
    switch (str) {
      case 'Dietician':
        typeId = 1;
        break;
      case 'Massage Therapist':
        typeId = 2;
        break;
      default:
        typeId = 3;
        break;
    }
    return typeId;
  };

  const onDownloadTermsAndConditions = async () => {
    const { url } = await getSignedUrl(user.signature);
    window.open(url, '_blank');
  };

  const isSubmitDisabled = useMemo(() => {
    if (
      !name
      || !email
      || !type
      || !locations
      || !locations.length
      || !specialists
      || !specialists.length
    ) {
      return true;
    }
    return false;
  }, [name, email, type, locations, specialists]);

  useEffect(() => {
    if (user) {
      setAvatar(getImage(user.image));
      setName(user.name ?? '');
      setEmail(user.email ?? '');
      setTitle(user.title);
      setAbout(user.about);
      setLanguages(user.languages.map((language) => language.id));
      setSpecialists(user.specialists.map((specialist) => specialist.id));
      setServices(user.services);
    }
  }, [user]);

  const Alert = () => (
    <>
      <IonAlert
        isOpen={showAlert.status}
        onDidDismiss={(): void => {
          setShowAlert((prev) => ({ ...prev, status: false }));
        }}
        header="Care Platform"
        message={showAlert.message}
        buttons={['Ok']}
      />
      <IonLoading isOpen={loading} />
    </>
  );

  const currentLicense = useMemo(() => {
    if (user.licenses) {
      return user.licenses.find((lic) => getTypeId(lic.type) === type);
    }
    return null;
  }, [type, user]);

  const verificationStatus = useMemo(() => {
    if (user.identity) {
      return user.identity.status;
    }
    return '';
  }, [user]);

  return (
    <IonContent id="clinic-member-profile" className="page-content ion-padding">
      <IonListHeader className="page-header">Account Settings</IonListHeader>
      <IonList>
        <IonItem lines="none" className="avatar">
          <IonAvatar slot="start">
            <img src={avatar} alt="selfie" />
            <IonButton color="light" onClick={() => inputRef.current?.click()}>
              <IonIcon icon={brushOutline} color="dark" />
            </IonButton>
          </IonAvatar>
          <input
            ref={inputRef}
            type="file"
            id="myfile"
            name="myfile"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              if (e.target.files) {
                setAvatar(URL.createObjectURL(e.target.files[0]));
                setFile(e.target.files[0] as any);
              }
            }}
          />
          <IonLabel>
            <h2>{name}</h2>
            {verificationStatus === VerificationStatus.VERIFIED ? (
              <IonIcon className="verified-badge" color="favorite" icon={checkmarkCircle} />
            ) : null}
            <p>{title}</p>
          </IonLabel>
        </IonItem>
        {!verificationStatus ? (
          <h5>
            Please&nbsp;
            <span className="verify-button" onClick={() => setShowIdentityModal(true)}>verify</span>
            &nbsp;your identification.
          </h5>
        ) : null}
        {verificationStatus === VerificationStatus.PENDING ? (
          <h5>
            Your information has been submitted. Once your identity is verified, you should be able to continue with the setup of your account and become available for appointments booking!
          </h5>
        ) : null}
        {verificationStatus === VerificationStatus.REJECTED ? (
          <div className="rejection-message">
            <h5>
              Your ID submission was rejected. Please&nbsp;
              <span className="verify-button" onClick={() => setShowIdentityModal(true)}>upload ID</span>
              &nbsp;again.
            </h5>
            <h5>
              Click&nbsp;
              <span className="verify-button" onClick={() => setShowRejectedReason('identity')}>here</span>
              &nbsp;to see reason why it was rejected.
            </h5>
          </div>
        ) : null}
        <IonItem lines="none" className="input">
          <UserIcon />
          <IonInput
            name="name"
            type="text"
            placeholder="* Name"
            color={name?.length ? '' : 'danger'}
            value={name}
            onIonChange={(e): void => {
              setName(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="input">
          <UserIcon />
          <IonInput
            name="title"
            type="text"
            placeholder="Title"
            value={title}
            onIonChange={(e): void => {
              setTitle(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="input disable">
          <PhoneIcon />
          <IonLabel>{user.phone}</IonLabel>
        </IonItem>
        <IonItem lines="none" className="input">
          <UserIcon />
          <IonInput
            name="email"
            type="email"
            placeholder="* Email "
            color={email?.length ? '' : 'danger'}
            required
            value={email}
            onIonChange={(e): void => {
              setEmail(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="input">
          <UserIcon />
          <IonTextarea
            name="about"
            value={about}
            rows={1}
            placeholder="About you..."
            onIonChange={(e): void => {
              setAbout(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="select">
          <UserIcon />
          <IonSelect
            className={clsx('select', { empty: !type })}
            placeholder="Select provider type..."
            value={type}
            onIonChange={(e): void => setType(parseInt(e.detail.value, 10))}
          >
            <IonSelectOption value={1}>Dietitian</IonSelectOption>
            <IonSelectOption value={2}>Massage Therapist</IonSelectOption>
            <IonSelectOption value={3}>Physician</IonSelectOption>
          </IonSelect>
          {currentLicense && currentLicense.status === VerificationStatus.VERIFIED ? <IonIcon color="favorite" icon={checkmarkCircle} /> : null}
        </IonItem>
        {!currentLicense ? (
          <IonButton slot="end" color="favorite" fill="clear" onClick={() => setShowLicenseModal(true)}>
            Upload license
          </IonButton>
        ) : null}
        {currentLicense && currentLicense.status === VerificationStatus.PENDING ? (
          <h5>Your uploaded license is under review now.</h5>
        ) : null}
        {currentLicense && currentLicense.status === VerificationStatus.REJECTED ? (
          <div className="rejection-message">
            <h5>
              Your uploaded license was rejected. Please&nbsp;
              <span className="verify-button" onClick={() => setShowLicenseModal(true)}>upload license</span>
              &nbsp;again.
            </h5>
            <h5>
              Click&nbsp;
              <span className="verify-button" onClick={() => setShowRejectedReason('license')}>here</span>
              &nbsp;to see reason why it was rejected.
            </h5>
          </div>
        ) : null}
        <IonItem lines="none" className="select">
          <ListIcon />
          <IonSelect
            className={clsx('select', { empty: !specialists || !specialists.length })}
            placeholder="Select your specialty..."
            multiple
            value={specialists}
            disabled={!type}
            onIonChange={(e): void => setSpecialists(e.detail.value)}
          >
            {allSpecialists
              .filter((specialist) => specialist.type === user.type)
              .map((specialist) => (
                <IonSelectOption key={specialist.id} value={specialist.id}>
                  {specialist.name}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonItem>
        <IonItem lines="none" className="select">
          <SphereIcon />
          <IonSelect
            className="select"
            placeholder="Select languages"
            multiple
            value={languages}
            disabled={!type}
            onIonChange={(e): void => setLanguages(e.detail.value)}
          >
            {allLanguages.map((language) => (
              <IonSelectOption key={language.id} value={language.id}>
                {language.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <ServiceManagement
          cssClass="services-view"
          services={services}
          updateServices={setServices}
          isEnableToAdd={!!type}
          hasTitle
        />
        {!locations || !locations.length ? (
          <IonText color="danger">
            <p>* You should add locations</p>
          </IonText>
        ) : null}
        <IonItem lines="none" className="input">
          <PlaceSearchInput
            value={location?.address}
            onChange={(value): void => setLocation(value)}
          />
          <IonButton
            color="favorite"
            disabled={!location?.address}
            onClick={(): void => onAddLocation()}
          >
            Add
          </IonButton>
        </IonItem>
        <IonItem lines="none" className="select">
          <LocationIcon />
          <IonSelect
            className="select"
            interface="action-sheet"
            placeholder="Select location..."
            value={selectedLocation}
            disabled={!locations.length}
            onIonChange={(e): void => setSelectedLocation(e.detail.value)}
          >
            {locations.map((item) => (
              <IonSelectOption key={item.address} value={item}>
                {item.address}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem lines="none">
          <IonButton slot="end" color="favorite" fill="clear" onClick={() => setShowLocationsModal(true)}>Manage locations</IonButton>
        </IonItem>
        <IonItem lines="none" className="map">
          <div className="map-container">
            <Map
              locations={locations}
              mapCenter={{ lat: location?.lat, lng: location?.lng }}
            />
          </div>
        </IonItem>
        <IonItem lines="none" className="input">
          <IonGrid>
            <IonRow>
              <IonCol>
                <br />
                <IonLabel>SMS, Email & Notification settings</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4">
                <IonItem lines="none">
                  <IonLabel>SMS</IonLabel>
                  <IonToggle
                    color="favorite"
                    checked={smsEnabled}
                    onIonChange={(event): void => setSmsEnabled(event.detail.checked)}
                  />
                </IonItem>
              </IonCol>
              <IonCol size="4">
                <IonItem lines="none">
                  <IonLabel>Email</IonLabel>
                  <IonToggle
                    color="favorite"
                    checked={emailEnabled}
                    onIonChange={(event): void => setEmailEnabled(event.detail.checked)}
                  />
                </IonItem>
              </IonCol>
              <IonCol size="4">
                <IonItem lines="none">
                  <IonLabel>Notification</IonLabel>
                  <IonToggle
                    color="favorite"
                    checked={notificationEnabled}
                    onIonChange={(event): void => setNotificationEnabled(event.detail.checked)}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonList>
      <IonButton color="favorite" onClick={(): void => history.push('/clinic-member/profile/reviews')}>
        Show Reviews
      </IonButton>
      <IonButton color="favorite" onClick={(): void => setShowChangePasswordModal(true)}>
        Change password
      </IonButton>
      <IonButton color="favorite" disabled={isSubmitDisabled} onClick={(): void => onUpdateProfile()}>
        Update
      </IonButton>
      <IonButton color="favorite" fill="outline" disabled={!user.signature} onClick={onDownloadTermsAndConditions}>
        Download T&C
      </IonButton>

      <Alert />

      {/* change password modal */}
      <ChangePasswordModal
        user={user}
        isOpen={showChangePasswordModal}
        toggleModal={setShowChangePasswordModal}
      />

      {/* upload license modal */}
      <LicenseModal
        user={user}
        type={type}
        isOpen={showLicenseModal}
        toggleModal={setShowLicenseModal}
      />

      <IdentityModal
        user={user}
        isOpen={showIdentityModal}
        toggleModal={setShowIdentityModal}
      />

      <IonAlert
        isOpen={showRejectedReason !== 'none'}
        onDidDismiss={() => setShowRejectedReason('none')}
        header="Rejected reason"
        message={
          showRejectedReason === 'identity' ? user?.identity?.rejectionReason
            : currentLicense?.rejectionReason
        }
        buttons={['OK']}
      />

      <IonAlert
        isOpen={showConfirmLocationDelete.open}
        onDidDismiss={() => setShowConfirmLocationDelete({ locationId: 0, open: false })}
        header="CarePlatform"
        message="You have some bookings with selected location. Are you sure you want to delete it?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: () => onRemoveLocation(showConfirmLocationDelete.locationId, '', false)
          }
        ]}
      />

      <IonModal
        cssClass="locations-modal"
        isOpen={showLocationsModal}
        onDidDismiss={() => setShowLocationsModal(false)}
      >
        <IonLabel>Manage Locations</IonLabel>
        <div className="locations">
          {locations.map((loc) => (
            <div className="location-item" onClick={() => onRemoveLocation(loc.id, loc.address ?? '', true)}>
              {loc.address}
              <IonIcon className="cursor-pointer" icon={closeOutline} />
            </div>
          ))}
        </div>
      </IonModal>

      <IonLoading isOpen={loading || isDeleting} />
    </IonContent>
  );
};

ClinicMemberProfile.propTypes = {
  user: PropTypes.any.isRequired,
  allLanguages: PropTypes.array.isRequired,
  allSpecialists: PropTypes.array.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<null, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    allLanguages: state.data.allLanguages,
    allSpecialists: state.data.allSpecialists,
    user: state.auth.user
  }),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(ClinicMemberProfile)
});
