import React from 'react';
import PropTypes from 'prop-types';
import Rating from 'react-rating';

interface StarRatingProps {
  hasOneStar?: boolean
  rating?: number
  readonly?: boolean
  onChange?: (value: number) => void
}

const StarRating: React.FC<StarRatingProps> = ({
  hasOneStar,
  rating,
  readonly,
  onChange
}) => (
  <Rating
    className="star-rating"
    stop={hasOneStar ? 1 : 5}
    emptySymbol="fa fa-star-o fa-2x medium"
    fullSymbol="fa fa-star fa-2x medium"
    initialRating={rating}
    readonly={readonly}
    onChange={onChange}
  />
);

StarRating.defaultProps = {
  hasOneStar: false,
  rating: 0,
  readonly: false,
  onChange: (): void => {}
};

StarRating.propTypes = {
  hasOneStar: PropTypes.bool,
  rating: PropTypes.number,
  readonly: PropTypes.bool,
  onChange: PropTypes.func
};

export default StarRating;
