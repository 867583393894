import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PortraitIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_34_4761)">
      <path d="M15.4167 0H4.58333C3.36816 0.00132347 2.20314 0.484634 1.34389 1.34389C0.484634 2.20314 0.00132347 3.36816 0 4.58333L0 15.4167C0.00132347 16.6318 0.484634 17.7969 1.34389 18.6561C2.20314 19.5154 3.36816 19.9987 4.58333 20H15.4167C16.6318 19.9987 17.7969 19.5154 18.6561 18.6561C19.5154 17.7969 19.9987 16.6318 20 15.4167V4.58333C19.9987 3.36816 19.5154 2.20314 18.6561 1.34389C17.7969 0.484634 16.6318 0.00132347 15.4167 0V0ZM17.5 15.4167C17.5 15.9692 17.2805 16.4991 16.8898 16.8898C16.4991 17.2805 15.9692 17.5 15.4167 17.5H15V16.6667C15 15.3406 14.4732 14.0688 13.5355 13.1311C12.5979 12.1935 11.3261 11.6667 10 11.6667C8.67392 11.6667 7.40215 12.1935 6.46447 13.1311C5.52678 14.0688 5 15.3406 5 16.6667V17.5H4.58333C4.0308 17.5 3.5009 17.2805 3.11019 16.8898C2.71949 16.4991 2.5 15.9692 2.5 15.4167V4.58333C2.5 4.0308 2.71949 3.5009 3.11019 3.11019C3.5009 2.71949 4.0308 2.5 4.58333 2.5H15.4167C15.9692 2.5 16.4991 2.71949 16.8898 3.11019C17.2805 3.5009 17.5 4.0308 17.5 4.58333V15.4167Z" fill={color} />
      <path d="M9.99992 9.99996C11.6107 9.99996 12.9166 8.69412 12.9166 7.08329C12.9166 5.47246 11.6107 4.16663 9.99992 4.16663C8.38909 4.16663 7.08325 5.47246 7.08325 7.08329C7.08325 8.69412 8.38909 9.99996 9.99992 9.99996Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_34_4761">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PortraitIcon;
