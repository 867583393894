import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const UserIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.54361 11.6817C5.99217 12.0027 3.28647 15.0036 3.33361 18.5692V18.75C3.33361 19.4403 3.89326 20 4.58361 20C5.27397 20 5.83361 19.4403 5.83361 18.75V18.5192C5.79604 16.3299 7.41166 14.463 9.58361 14.1858C11.8763 13.9585 13.9192 15.6328 14.1466 17.9255C14.16 18.061 14.1668 18.1971 14.1669 18.3333V18.75C14.1669 19.4403 14.7266 20 15.4169 20C16.1073 20 16.6669 19.4403 16.6669 18.75V18.3333C16.6629 14.6473 13.6714 11.6624 9.98541 11.6665C9.83803 11.6667 9.69068 11.6717 9.54361 11.6817Z" fill={color} />
    <path d="M10.0002 10C12.7617 10 15.0002 7.76141 15.0002 5C15.0002 2.23859 12.7617 0 10.0002 0C7.23884 0 5.00024 2.23859 5.00024 5C5.00298 7.76027 7.23997 9.99723 10.0002 10ZM10.0002 2.5C11.3809 2.5 12.5002 3.6193 12.5002 5C12.5002 6.3807 11.3809 7.5 10.0002 7.5C8.61954 7.5 7.50024 6.3807 7.50024 5C7.50024 3.6193 8.61954 2.5 10.0002 2.5Z" fill={color} />
  </svg>
);

export default UserIcon;
