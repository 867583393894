import React, { useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {
  IonItem, IonLabel, IonThumbnail, IonIcon, IonSpinner, IonAlert
} from '@ionic/react';
import {
  addCircle,
  checkmarkCircle,
  closeCircleOutline,
  ellipsisHorizontal,
  logInOutline,
  trashBinOutline,
  trashOutline
} from 'ionicons/icons';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import clsx from 'clsx';

import Avatar from './Avatar';
import connect from '../data/connect';
import {
  User, ProviderType, VerificationStatus, UserStatus, UserRole
} from '../models';
import StarRating from './StarRating';
import {
  activateAccount,
  inactivateAccount,
  signInAsUser,
  setToken
} from '../data/dataApi';
import { dispatchValidate } from '../data/store/auth/auth.actions';

interface StateProps {
  currentUser: User
  providerTypes: ProviderType[]
}

interface DispatchProps {
  validate: typeof dispatchValidate
}

interface UserItemProps {
  user: User;
  hasActions?: boolean;
  addFavoriteProvider?: (id: number, user: User) => void;
  deleteFavoriteProvider?: (id: number, user: User) => void;
  updateUser?: (user: User) => void;
  deleteUser?: (id: number) => void;
  onClick?: () => void;
}

const UserItem: React.FC<StateProps & DispatchProps & UserItemProps & RouteComponentProps> = ({
  currentUser,
  history,
  providerTypes,
  user,
  hasActions = false,
  validate,
  addFavoriteProvider,
  deleteFavoriteProvider,
  updateUser,
  deleteUser,
  onClick
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [showInactiveAlert, setShowInactiveAlert] = useState(false);

  const toggleProviderFavorite = () => {
    if (!Number(user.isFavorite)) {
      addFavoriteProvider?.(currentUser.id, user);
    } else {
      deleteFavoriteProvider?.(currentUser.id, user);
    }
  };

  const onRemove = () => {
    setIsLoading(true);
    inactivateAccount(user.id, true)
      .then((res) => {
        if (res.success) {
          deleteUser?.(user.id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onInactivate = () => {
    setIsLoading(true);
    inactivateAccount(user.id, false)
      .then((res) => {
        if (res.success) {
          updateUser?.({ ...user, status: UserStatus.INACTIVE });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onActivate = () => {
    setIsLoading(true);
    activateAccount(user.id)
      .then((res) => {
        if (res.success) {
          updateUser?.({ ...user, status: UserStatus.ACTIVE });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const signAs = () => {
    if (user) {
      signInAsUser(user.id)
        .then(async (res) => {
          if (res.success) {
            await setToken(res.token);
            validate();
            history.push('/');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const isSubscribed = useMemo(() => {
    if (user.subscription && ['active', 'paused'].includes(user.subscription.status)) {
      return true;
    }
    return false;
  }, [user]);

  const providerTypeName = useMemo(() => {
    const item = providerTypes.find((pt) => pt.id === user.type);
    if (item) {
      return item.name;
    }
    return '';
  }, [user, providerTypes]);

  const isAdminView = useMemo(() => currentUser.role === UserRole.ADMIN, [currentUser]);

  const isCertified = useMemo(() => {
    const licenses = user.licenses ?? [];
    return licenses.filter((license) => `${license.type}s` === providerTypeName && license.status === VerificationStatus.VERIFIED).length;
  }, [user, providerTypeName]);

  const isIDVerified = useMemo(() => user.identity && user.identity.status === VerificationStatus.VERIFIED, [user]);

  const rating = user?.rating ?? 0;

  return (
    <IonItem
      lines="none"
      className={clsx('list-item round-border profile clickable', {
        inactive: user.status === UserStatus.INACTIVE
      })}
      detail={false}
      onClick={onClick}
    >
      <Avatar user={user} />
      <IonLabel>
        <h2>
          {`${user.name} ${user.lastName ?? ''}`}
          {isSubscribed ? <span className="subscribe-badge">Subsribed</span> : null}
        </h2>
        <p>{isAdminView && !isCertified ? `${providerTypeName}` : `Certified ${providerTypeName}`}</p>
      </IonLabel>
      <div>
        {isAdminView ? (
          <>
            {hasActions ? (
              <div className="actions">
                <Popover
                  isOpen={isPopoverOpen}
                  positions={['bottom', 'top', 'left', 'right']}
                  content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor="#8dbfb6"
                      arrowSize={10}
                      className="popover-arrow-container"
                      arrowClassName="popover-arrow"
                    >
                      <div className="popover-content">
                        {user.status === UserStatus.ACTIVE ? (
                          <div className="option" onClick={() => { setShowInactiveAlert(true); setIsPopoverOpen(false); }}>
                            <IonLabel>
                              <IonIcon color="dark" icon={closeCircleOutline} />
                              Inactivate
                            </IonLabel>
                          </div>
                        ) : null}
                        {user.status === UserStatus.INACTIVE ? (
                          <div className="option" onClick={onActivate}>
                            <IonLabel color="primary">
                              <IonIcon color="primary" icon={addCircle} />
                              Activate
                            </IonLabel>
                          </div>
                        ) : null}
                        <div className="option" onClick={onRemove}>
                          <IonLabel color="dark">
                            <IonIcon color="dark" icon={trashBinOutline} />
                            Archive
                          </IonLabel>
                        </div>
                        <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                          <IonLabel color="danger">
                            <IonIcon color="danger" icon={trashOutline} />
                            Delete
                          </IonLabel>
                        </div>
                        <div className="option" onClick={signAs}>
                          <IonLabel>
                            <IonIcon color="dark" icon={logInOutline} />
                            Sign In As
                          </IonLabel>
                        </div>
                      </div>
                    </ArrowContainer>
                  )}
                  onClickOutside={() => setIsPopoverOpen(false)}
                >
                  {isLoading ? <IonSpinner /> : (
                    <span
                      className="action"
                      title="More actions"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsPopoverOpen(!isPopoverOpen);
                      }}
                    >
                      <IonIcon icon={ellipsisHorizontal} />
                    </span>
                  )}
                </Popover>
              </div>
            ) : (
              <IonLabel style={{ display: 'flex', alignItems: 'center' }}>
                <IonIcon icon={checkmarkCircle} color={isCertified ? 'danger' : 'medium'} />
                <IonIcon icon={checkmarkCircle} color={isIDVerified ? 'favorite' : 'medium'} />
              </IonLabel>
            )}
          </>
        ) : null}
        {!isAdminView ? (
          <IonLabel style={{ display: 'flex', alignItems: 'center' }}>
            <StarRating hasOneStar rating={Number((rating / 5).toFixed(1))} readonly />
            <span className="rating">{user?.rating?.toFixed(1) ?? '0.0'}</span>
            <IonThumbnail
              slot="end"
              style={{ marginLeft: 8, width: '22px', height: '20px' }}
              onClick={(e) => {
                e.stopPropagation();
                toggleProviderFavorite();
              }}
            >
              <img
                alt="favorite"
                src={Number(user.isFavorite) ? 'assets/img/icon/favorite-active.svg' : 'assets/img/icon/favorite-inactive.svg'}
              />
            </IonThumbnail>
          </IonLabel>
        ) : null}
      </div>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={(): void => setShowRemoveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to delete this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onRemove()
          }
        ]}
      />

      <IonAlert
        isOpen={showInactiveAlert}
        onDidDismiss={(): void => setShowInactiveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to inactivate this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onInactivate()
          }
        ]}
      />
    </IonItem>
  );
};

UserItem.defaultProps = {
  hasActions: false,
  addFavoriteProvider: () => {},
  deleteFavoriteProvider: () => {},
  updateUser: () => {},
  deleteUser: () => {},
  onClick: () => {},
};

UserItem.propTypes = {
  currentUser: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  providerTypes: PropTypes.array.isRequired,
  hasActions: PropTypes.bool,
  addFavoriteProvider: PropTypes.func,
  deleteFavoriteProvider: PropTypes.func,
  validate: PropTypes.func.isRequired,
  updateUser: PropTypes.func,
  deleteUser: PropTypes.func,
  onClick: PropTypes.func
};

export default connect<UserItemProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    currentUser: state.auth.user,
    providerTypes: state.data.providerTypes
  }),
  mapDispatchToProps: {
    validate: dispatchValidate
  },
  component: withRouter(UserItem)
});
