import { User, UserStatus, UserRole } from '../../../models';

export interface AuthState {
  user: User
  loading: boolean
  errorMessage: string
}

const initialAuthState: AuthState = {
  user: {
    id: 0,
    phone: '',
    email: '',
    name: 'No name',
    lastName: '',
    image: '',
    title: '',
    about: '',
    type: 0,
    currencyType: 0,
    role: UserRole.CUSTOMER,
    rating: 0,
    isFavorite: 0,
    status: UserStatus.PENDING,
    clinic: 0,
    syncId: 0,
    onboardingStep: 0,
    smsEnabled: true,
    emailEnabled: true,
    notificationEnabled: true,
    yearsOfExperience: 0,
    limitPeriod: 0,
    signature: '',
    services: [],
    languages: [],
    specialists: [],
    sources: [],
    locations: [],
    licenses: [],
    identity: null,
    subscription: null
  },
  loading: false,
  errorMessage: ''
};

export default initialAuthState;
