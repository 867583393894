import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonPage,
  IonButton,
  IonLoading
} from '@ionic/react';

import './JsonForm.scss';

import Form from '@rjsf/material-ui';
import { ISubmitEvent, IChangeEvent } from '@rjsf/core';
import connect from '../../data/connect';
import { getRegisterJson } from '../../data/dataApi';

interface StateProps {
  loading: boolean
  loggedin: boolean
  role: string
}

type JsonFormProps = RouteComponentProps & StateProps;

const JsonForm: React.FC<JsonFormProps> = ({
  history,
  loading,
  loggedin,
  role
}) => {
  const [jsonSchema, setJsonSchema] = useState({});
  const [formData, setformData] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const getRegisterJsonData = (): void => {
    getRegisterJson().then((res) => {
      if (res.success) {
        const registerSchema = res.registerformData;

        console.log(registerSchema.form_schema);
        setJsonSchema(JSON.parse(registerSchema.form_schema));

        setUiSchema(JSON.parse(registerSchema.ui_schema));
        setformData(JSON.parse(registerSchema.form_data));
      }
    });
  };
  useEffect(() => {
    getRegisterJsonData();
  }, []);

  const onSubmit = async (event: ISubmitEvent<IChangeEvent>) => {
    console.log(event);
    console.log(event.formData);
  };

  const customValidate = (formData: any, errors: any) => {
    if (formData.pass1 !== formData.pass2) {
      errors.pass2.addError("Passwords don't match");
    }
    return errors;
  };

  return (
    <IonPage id="JsonForm-page">
      <IonContent>
        <div>
          <Form
            schema={jsonSchema}
            formData={formData}
            onSubmit={onSubmit}
            omitExtraData
            uiSchema={uiSchema}
          >
            <IonButton type="submit">Submit </IonButton>
          </Form>
        </div>
        <IonLoading isOpen={loading} />
      </IonContent>
    </IonPage>
  );
};

JsonForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  loggedin: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, StateProps>({
  mapStateToProps: (state) => ({
    loading: state.auth.loading || state.data.loading,
    loggedin: !!state.auth.user.phone,
    role: state.auth.user.role
  }),
  mapDispatchToProps: {},
  component: JsonForm
});
