import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import {
  IonContent,
  IonListHeader,
  IonGrid,
  IonRow,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonLoading,
  IonButton,
  IonModal,
  IonIcon,
  IonTextarea,
  IonAlert
} from '@ionic/react';
import { checkmarkCircle, closeCircle } from 'ionicons/icons';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import connect from '../../data/connect';
import {
  License, ProviderType, User, VerificationStatus, Request, UserStatus
} from '../../models';
import Avatar from '../../components/Avatar';
import {
  fetchProvider,
  checkIdentity,
  checkLicense,
  socket,
  setToken,
  signInAsUser,
  inactivateAccount,
  activateAccount
} from '../../data/dataApi';
import { dispatchValidate } from '../../data/store/auth/auth.actions';
import { dispatchUpdateProvider, dispatchUpdateRequest } from '../../data/store/data/data.actions';
import { SOCKET_KEYS } from '../../data/constants';

import './ProviderCertification.scss';

interface MatchParams {
  id: string
}

interface StateProps {
  user: User
  requests: Request[]
  providerTypes: ProviderType[]
}

interface DispatchProps {
  updateProvider: typeof dispatchUpdateProvider
  updateRequest: typeof dispatchUpdateRequest
  validate: typeof dispatchValidate
}

type ProviderCertificationProps = RouteComponentProps<MatchParams> & StateProps & DispatchProps;

const ProviderCertification: React.FC<ProviderCertificationProps> = ({
  match,
  requests,
  providerTypes,
  updateProvider,
  updateRequest,
  validate
}) => {
  const history = useHistory();
  const location = useLocation();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [segment, setSegment] = useState<'identity' | 'license' | 'status'>('identity');
  const [isLoading, setIsLoading] = useState(false);
  const [provider, setProvider] = useState<User | null>(null);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null);
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const [rejectOption, setRejectOption] = useState<'none' | 'identity' | 'license'>('none');
  const [reason, setReason] = useState('');
  const [status, setStatus] = useState<'active' | 'inactive' | 'archive'>('active');
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);

  const providerTypeName = useMemo(() => {
    if (!provider) {
      return '';
    }
    const item = providerTypes.find((pt) => pt.id === provider.type);
    if (item) {
      return item.name;
    }
  }, [provider, providerTypes]);

  const updateRequestStatus = (type: string, resourceId: number) => {
    if (provider) {
      const request = requests.find((r) => r.userId === provider.id
        && r.type === type
        && r.resourceId === resourceId);
      if (request) {
        updateRequest({ ...request, status: 'completed' });
      }
    }
  };

  const onApproveIdentity = () => {
    if (provider?.identity) {
      setIsLoading(true);
      checkIdentity(provider.identity.id, '')
        .then((res) => {
          if (res.success) {
            updateProvider({ ...provider, identity: res.identity });
            socket.emit(SOCKET_KEYS['approved-identity'], provider.id, res.identity.id);
            updateRequestStatus('identity', res.identity.id);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  };

  const onRejectIdentity = () => {
    if (provider?.identity) {
      setIsLoading(true);
      checkIdentity(provider.identity.id, reason)
        .then((res) => {
          if (res.success) {
            updateProvider({ ...provider, identity: res.identity });
            socket.emit(SOCKET_KEYS['rejected-identity'], provider.id, res.identity.id);
            updateRequestStatus('identity', res.identity.id);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  };

  const onApproveLicense = () => {
    if (selectedLicense) {
      setIsLoading(true);
      checkLicense(selectedLicense.id, '')
        .then((res) => {
          if (res.success && provider?.licenses) {
            const updateLicenses = provider.licenses.map((license) => {
              if (license.id === selectedLicense.id) {
                return res.license;
              }
              return license;
            });
            updateProvider({ ...provider, licenses: updateLicenses });
            setSelectedLicense(res.license);
            socket.emit(SOCKET_KEYS['approved-license'], provider.id, res.license.id);
            updateRequestStatus('license', res.license.id);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    }
  };

  const onRejectLicense = () => {
    if (selectedLicense) {
      setIsLoading(true);
      checkLicense(selectedLicense.id, reason)
        .then((res) => {
          if (res.success && provider?.licenses) {
            const updateLicenses = provider.licenses.map((license) => {
              if (license.id === selectedLicense.id) {
                return res.license;
              }
              return license;
            });
            updateProvider({ ...provider, licenses: updateLicenses });
            setSelectedLicense(res.license);
            socket.emit(SOCKET_KEYS['rejected-license'], provider.id, res.license.id);
            updateRequestStatus('license', res.license.id);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    }
  };

  const onSubmitRejection = () => {
    if (rejectOption === 'identity') {
      onRejectIdentity();
    } else {
      onRejectLicense();
    }
    setRejectOption('none');
  };

  const signAs = () => {
    if (provider) {
      signInAsUser(provider.id)
        .then(async (res) => {
          if (res.success) {
            await setToken(res.token);
            validate();
            history.push('/');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onRemove = () => {
    if (provider) {
      setIsLoading(true);
      inactivateAccount(provider.id, true)
        .then((res) => {
          if (res.success) {
            history.goBack();
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onActivate = () => {
    if (provider) {
      setIsLoading(true);
      activateAccount(provider.id)
        .then((res) => {
          if (res.success) {
            setProvider({ ...provider, status: UserStatus.ACTIVE });
            setStatus('active');
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onInactivate = () => {
    if (provider) {
      setIsLoading(true);
      inactivateAccount(provider.id, false)
        .then((res) => {
          if (res.success) {
            setProvider({ ...provider, status: UserStatus.INACTIVE });
            setStatus('inactive');
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const updateStatus = (status: string) => {
    if (status === 'active') {
      onActivate();
    }
    if (status === 'inactive') {
      onInactivate();
    }
  };

  useEffect(() => {
    if (match.params.id) {
      setIsLoading(true);
      fetchProvider(Number(match.params.id))
        .then((res) => {
          if (res.success) {
            setProvider(res.user);
            setStatus(res.user.status);
          }
        })
        .catch((error) => console.error(error))
        .finally(() => setIsLoading(false));
    }
  }, [match]);

  useEffect(() => {
    if (location) {
      const state: any = location.state ?? {};
      if (state?.type) {
        setSegment(state?.type);
      }
    }
  }, [location]);

  return (
    <IonContent id="provider-certification" className="page-content ion-padding">
      <IonListHeader className="page-header">Verify Provider</IonListHeader>
      {!isLoading && provider ? (
        <IonGrid>
          <IonRow className="profile-content profile">
            <Avatar user={provider} size="lg" />
            <IonLabel>
              <h2>{`${provider.name} ${provider.lastName ?? ''}`}</h2>
              <p>{providerTypeName}</p>
            </IonLabel>
            <div className="actions">
              <IonButton color="dark" fill="outline" onClick={signAs}>Edit as Provider</IonButton>
              <IonButton color="danger" onClick={() => setShowRemoveAlert(true)}>
                Delete Provider
              </IonButton>
            </div>
          </IonRow>
          <IonRow className="select-segment">
            <IonSegment
              mode="ios"
              value={segment}
              onIonChange={(e): void => setSegment(e.detail.value as 'identity' | 'license' | 'status')}
            >
              <IonSegmentButton value="identity">ID</IonSegmentButton>
              <IonSegmentButton value="license">License</IonSegmentButton>
              <IonSegmentButton value="status">Status</IonSegmentButton>
            </IonSegment>
          </IonRow>
          <IonRow>
            {segment === 'identity' ? (
              <div className="id-verification">
                {provider?.identity ? (
                  <>
                    <div className="id-content">
                      <IonLabel color="dark" className="sub-title">ID Information</IonLabel>
                      <div className="id-details">
                        <div className="id-details-option">
                          <IonLabel>
                            First Name:
                          </IonLabel>
                          <span>{provider.identity.firstname}</span>
                        </div>
                        <div className="id-details-option">
                          <IonLabel>
                            Last Name:
                          </IonLabel>
                          <span>{provider.identity.lastname}</span>
                        </div>
                        <div className="id-details-option">
                          <IonLabel>
                            BirthDay:
                          </IonLabel>
                          <span>{provider.identity.birthdate}</span>
                        </div>
                        <div className="id-details-option">
                          <IonLabel>
                            Country:
                          </IonLabel>
                          <span>
                            {provider.identity.country === 'us' ? 'United State' : 'Canada'}
                          </span>
                        </div>
                        <div className="id-details-option">
                          <IonLabel>
                            ID Type:
                          </IonLabel>
                          <span>{provider.identity.type}</span>
                        </div>
                        <div className="id-details-option">
                          <IonLabel>
                            ID Number:
                          </IonLabel>
                          <span>{provider.identity.number}</span>
                        </div>
                        <div className="id-details-option">
                          <IonLabel>
                            Photo of ID:
                          </IonLabel>
                          <div>
                            <div onClick={() => setIsImageViewerOpen(true)}>
                              <img
                                src={provider.identity.frontImage}
                                alt="id-card"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {isImageViewerOpen ? (
                        <Lightbox
                          mainSrc={provider.identity.frontImage}
                          onCloseRequest={() => setIsImageViewerOpen(false)}
                        />
                      ) : null}
                    </div>
                    {provider.identity.status === 'pending' ? (
                      <div className="actions">
                        <IonButton
                          color="favorite"
                          onClick={() => onApproveIdentity()}
                        >
                          Approve
                        </IonButton>
                        <IonButton
                          color="danger"
                          onClick={() => setRejectOption('identity')}
                        >
                          Reject
                        </IonButton>
                      </div>
                    ) : (
                      <IonLabel className="id-status" color={provider.identity.status === VerificationStatus.VERIFIED ? 'favorite' : 'danger'}>
                        <IonIcon
                          color={provider.identity.status === VerificationStatus.VERIFIED ? 'favorite' : 'danger'}
                          icon={provider.identity.status === VerificationStatus.VERIFIED ? checkmarkCircle : closeCircle}
                          size="large"
                        />
                        {provider.identity.status === VerificationStatus.VERIFIED ? 'Approved' : 'Rejected'}
                      </IonLabel>
                    )}
                  </>
                ) : (
                  <div className="no-content">No uploaded ID information yet</div>
                )}
              </div>
            ) : null}
            {segment === 'license' ? (
              <div className="license-certification">
                {provider.licenses && provider.licenses.length > 0 ? (
                  <>
                    {provider.licenses.map((license) => (
                      <>
                        <div className="license-title" onClick={() => setSelectedLicense(license)}>
                          {license.type}
                          {license.status === VerificationStatus.VERIFIED ? (
                            <IonIcon color="favorite" icon={checkmarkCircle} />
                          ) : null}
                          {license.status === VerificationStatus.REJECTED ? (
                            <IonIcon color="danger" icon={closeCircle} />
                          ) : null}
                        </div>
                        {selectedLicense?.id === license.id ? (
                          <div className="license-details">
                            <IonLabel>
                              Name:
                              <span>{license.name}</span>
                            </IonLabel>
                            <IonLabel>
                              Issuing Org:
                              <span>{license.issuingOrganization}</span>
                            </IonLabel>
                            <IonLabel>
                              Issue Date:
                              <span>{license.issueDate}</span>
                            </IonLabel>
                            <IonLabel>
                              Expiration Date:
                              <span>{license.expirationDate}</span>
                            </IonLabel>
                            <IonLabel>
                              Issue Number:
                              <span>{license.number}</span>
                            </IonLabel>
                            <IonLabel>
                              Country:
                              <span>
                                {license.country === 'us' ? 'United State' : 'Canada'}
                              </span>
                            </IonLabel>
                            <IonLabel>
                              State:
                              <span>{license.state}</span>
                            </IonLabel>
                            <IonLabel>
                              Credential ID:
                              <span>{license.credentialId}</span>
                            </IonLabel>
                            <IonLabel
                              className="view-btn"
                              color="favorite"
                              onClick={() => setIsPdfViewerOpen(true)}
                            >
                              View Credential
                            </IonLabel>
                            {selectedLicense.status === VerificationStatus.PENDING ? (
                              <div className="actions">
                                <IonButton
                                  color="favorite"
                                  onClick={() => onApproveLicense()}
                                >
                                  Approve
                                </IonButton>
                                <IonButton
                                  color="danger"
                                  onClick={() => setRejectOption('license')}
                                >
                                  Reject
                                </IonButton>
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </>
                    ))}
                  </>
                ) : (
                  <div className="no-content">No uploaded licenses yet</div>
                )}
              </div>
            ) : null}
            {segment === 'status' ? (
              <div className="status-change">
                <IonLabel color="dark" className="sub-title">Provider status:</IonLabel>
                <IonSegment
                  mode="ios"
                  value={status}
                  onIonChange={(e): void => updateStatus(e.detail.value as 'active' | 'inactive' | 'archive')}
                >
                  <IonSegmentButton className="active" value="active">Active</IonSegmentButton>
                  <IonSegmentButton className="inactive" value="inactive">Inactive</IonSegmentButton>
                  <IonSegmentButton className="archive" value="archive">Archive</IonSegmentButton>
                </IonSegment>
              </div>
            ) : null}
          </IonRow>
        </IonGrid>
      ) : <IonLoading isOpen={isLoading} />}
      <IonModal
        cssClass="pdf-viewer-modal"
        isOpen={isPdfViewerOpen}
        onDidDismiss={() => setIsPdfViewerOpen(false)}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <div className="pdf-viewer">
            <span className="close-modal" onClick={() => setIsPdfViewerOpen(false)}>
              <IonIcon icon={closeCircle} color="dark" size="large" />
            </span>
            {selectedLicense ? (
              <Viewer
                fileUrl={selectedLicense.credentialFileName}
                plugins={[defaultLayoutPluginInstance]}
                defaultScale={1}
              />
            ) : null}
          </div>
        </Worker>
      </IonModal>

      <IonModal
        cssClass="reason-popover"
        isOpen={rejectOption !== 'none'}
        onDidDismiss={() => setRejectOption('none')}
      >
        <IonLabel>
          {`Please add reason why reject this ${rejectOption}`}
        </IonLabel>
        <IonTextarea
          placeholder="Enter reason"
          maxlength={500}
          rows={3}
          value={reason}
          onIonChange={(e) => setReason(e.detail.value ?? '')}
        />
        <div className="reason-length">
          {`Length: ${500 - reason.length}`}
        </div>
        <div className="actions">
          <IonButton color="favorite" disabled={!reason} onClick={onSubmitRejection}>Confirm</IonButton>
          <IonButton color="danger" onClick={() => setRejectOption('none')}>Cancel</IonButton>
        </div>
      </IonModal>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={(): void => setShowRemoveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to delete this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onRemove()
          }
        ]}
      />
    </IonContent>
  );
};

ProviderCertification.propTypes = {
  match: PropTypes.any.isRequired,
  requests: PropTypes.array.isRequired,
  providerTypes: PropTypes.array.isRequired,
  updateProvider: PropTypes.func.isRequired,
  updateRequest: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired
};

export default connect<RouteComponentProps<MatchParams>, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    user: state.auth.user,
    requests: state.data.requests,
    providerTypes: state.data.providerTypes
  }),
  mapDispatchToProps: {
    updateProvider: dispatchUpdateProvider,
    updateRequest: dispatchUpdateRequest,
    validate: dispatchValidate
  },
  component: React.memo(ProviderCertification)
});
