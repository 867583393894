import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel
} from '@ionic/react';
import Reviews from '../Reviews/Reviews';
import connect from '../../data/connect';
import {
  dispatchFetchTimeslots,
  dispatchFetchBookings,
  dispatchFetchLanguages,
  dispatchFetchProviderTypes,
  dispatchFetchSpecialists,
  dispatchGetAllNotification,
  dispatchFetchCurrencies
} from '../../data/store/data/data.actions';
import { getUnreadCount } from '../../data/selectors';
import { socket } from '../../data/dataApi';
import { User } from '../../models';
import Layout from '../../components/Layout';
import useGetView from '../../hooks/useGetView/useGetView';
import ClinicMemberDashboard from '../ClinicMemberDashboard';
import ClinicMemberProfileManagement from '../ClinicMemberProfileManagement';
import ProviderTimeslots from '../ProviderTimeslots';
import BusinessHour from '../BusinessHour';
import Bookings from '../Bookings';
import BookingReport from '../BookingReport/BookingReport';
import UserReport from '../UserReport/UserReport';
import ContactUs from '../ContactUs';
import Notifications from '../Notifications';
import ProviderOnboarding from '../ProviderOnboarding/ProviderOnboarding';

interface StateProps {
  user: User
}

interface DispatchProps {
  fetchTimeslots: typeof dispatchFetchTimeslots
  fetchBookings: typeof dispatchFetchBookings
  fetchLanguages: typeof dispatchFetchLanguages
  fetchProviderTypes: typeof dispatchFetchProviderTypes
  fetchSpecialists: typeof dispatchFetchSpecialists
  getAllNotification: typeof dispatchGetAllNotification
  fetchCurrencies: typeof dispatchFetchCurrencies
}

type ClinicMemberViewProps = StateProps & DispatchProps;

const ClinicMemberView: React.FC<ClinicMemberViewProps> = ({
  user,
  fetchTimeslots,
  fetchBookings,
  fetchLanguages,
  fetchProviderTypes,
  fetchSpecialists,
  fetchCurrencies,
  getAllNotification
}) => {
  const [tab, setTab] = useState(0);
  const { isMobile } = useGetView();

  useEffect(() => {
    socket.on('new-message', (e: any) => {
      fetchBookings();
      getAllNotification();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTimeslots(user.id);
  }, [user.id, fetchTimeslots]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    fetchSpecialists();
  }, [fetchSpecialists]);

  useEffect(() => {
    fetchProviderTypes();
  }, [fetchProviderTypes]);

  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  const renderRoutes = () => (
    <IonRouterOutlet>
      <Layout>
        <Route path="/clinic-member" render={() => <Redirect to="/clinic-member/onboarding" />} exact />
        <Route path="/clinic-member/onboarding" component={ProviderOnboarding} exact />
        <Route path="/clinic-member/dashboard" component={ClinicMemberDashboard} exact />
        <Route path="/clinic-member/profile" component={ClinicMemberProfileManagement} exact />
        <Route
          path="/clinic-member/availability"
          component={ProviderTimeslots}
          exact
        />
        <Route
          path="/clinic-member/:id/business-hour"
          component={BusinessHour}
          exact
        />
        <Route path="/clinic-member/bookings" component={Bookings} exact />
        <Route
          path="/clinic-member/report"
          component={UserReport}
          exact
        />
        <Route
          path="/clinic-member/report/booking/:id"
          component={BookingReport}
          exact
        />
        <Route path="/clinic-member/notification" component={Notifications} exact />
        <Route path="/clinic-member/profile/reviews" component={Reviews} exact />
        <Route path="/clinic-member/contact-us" component={ContactUs} exact />
      </Layout>
    </IonRouterOutlet>
  );

  if (!isMobile) {
    return renderRoutes();
  }

  return (
    <IonTabs>
      {renderRoutes()}
      <IonTabBar slot="bottom">
        <IonTabButton
          tab="dashboard"
          href="/clinic-member/dashboard"
          onClick={(): void => setTab(0)}
        >
          <IonIcon src="assets/img/icon/dashboard.svg" />
          <IonLabel>Dashboard</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

ClinicMemberView.propTypes = {
  user: PropTypes.any.isRequired,
  fetchTimeslots: PropTypes.func.isRequired,
  fetchBookings: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  fetchProviderTypes: PropTypes.func.isRequired,
  fetchSpecialists: PropTypes.func.isRequired,
  fetchCurrencies: PropTypes.func.isRequired,
  getAllNotification: PropTypes.func.isRequired
};

export default connect<null, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {
    fetchTimeslots: dispatchFetchTimeslots,
    fetchBookings: dispatchFetchBookings,
    fetchLanguages: dispatchFetchLanguages,
    fetchProviderTypes: dispatchFetchProviderTypes,
    fetchSpecialists: dispatchFetchSpecialists,
    fetchCurrencies: dispatchFetchCurrencies,
    getAllNotification: dispatchGetAllNotification
  },
  component: React.memo(ClinicMemberView)
});
