import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LocationIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_34_3603)">
      <path d="M10 3.744e-05C8.79444 -0.00309172 7.60027 0.233813 6.48716 0.69694C5.37406 1.16007 4.36428 1.84015 3.51671 2.69754C1.79548 4.43568 0.832829 6.78485 0.839389 9.23101C0.845948 11.6772 1.82119 14.0211 3.55171 15.75L6.66671 18.6325C7.55175 19.5044 8.74286 19.9952 9.98519 20.0001C11.2275 20.0049 12.4224 19.5233 13.3142 18.6584L16.4867 15.7117C18.2025 13.9808 19.1651 11.6422 19.1651 9.20504C19.1651 6.76784 18.2025 4.42928 16.4867 2.69837C15.6388 1.84043 14.6285 1.15996 13.5148 0.696679C12.4011 0.233395 11.2063 -0.0034257 10 3.744e-05ZM14.75 13.9167L11.5834 16.855C11.1523 17.267 10.5777 17.4947 9.98147 17.4899C9.38524 17.4851 8.81436 17.2481 8.39004 16.8292L5.28838 13.9467C4.03763 12.6852 3.33586 10.9807 3.33586 9.2042C3.33586 7.42775 4.03763 5.72322 5.28838 4.4617C5.9055 3.84007 6.63953 3.3467 7.44818 3.01002C8.25683 2.67334 9.12411 2.50001 10 2.50001C10.876 2.50001 11.7433 2.67334 12.5519 3.01002C13.3606 3.3467 14.0946 3.84007 14.7117 4.4617C15.9605 5.71583 16.6648 7.41152 16.672 9.18133C16.6791 10.9511 15.9886 12.6525 14.75 13.9167ZM14.1667 8.44004V11.6667C14.1667 11.8877 14.0789 12.0997 13.9226 12.256C13.7664 12.4122 13.5544 12.5 13.3334 12.5H12.5C12.279 12.5 12.0671 12.4122 11.9108 12.256C11.7545 12.0997 11.6667 11.8877 11.6667 11.6667V10C11.6667 9.77902 11.5789 9.56706 11.4226 9.41078C11.2664 9.2545 11.0544 9.1667 10.8334 9.1667H9.16671C8.94569 9.1667 8.73373 9.2545 8.57745 9.41078C8.42117 9.56706 8.33338 9.77902 8.33338 10V11.6667C8.33338 11.8877 8.24558 12.0997 8.0893 12.256C7.93302 12.4122 7.72106 12.5 7.50004 12.5H6.66671C6.44569 12.5 6.23373 12.4122 6.07745 12.256C5.92117 12.0997 5.83338 11.8877 5.83338 11.6667V8.44004C5.83341 8.17091 5.89862 7.9058 6.02343 7.66737C6.14823 7.42894 6.32892 7.22427 6.55004 7.07087L9.05004 5.33587C9.32903 5.14232 9.66049 5.03861 10 5.03861C10.3396 5.03861 10.6711 5.14232 10.95 5.33587L13.45 7.07087C13.6712 7.22427 13.8519 7.42894 13.9767 7.66737C14.1015 7.9058 14.1667 8.17091 14.1667 8.44004Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_34_3603">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LocationIcon;
