/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonListHeader,
  IonLoading,
  IonModal,
  IonRow
} from '@ionic/react';
import { DateRange as DateRangePicker, Range } from 'react-date-range';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import {
  calendarOutline, ellipsisHorizontal, helpCircle, removeOutline
} from 'ionicons/icons';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import moment from 'moment';
import clsx from 'clsx';

import connect from '../../data/connect';
import { fetchBookingsByUser, inactivateAccount, exportUserReport } from '../../data/dataApi';
import {
  Booking, BookingStatus, ProviderType, Specialist, User, UserRole
} from '../../models';
import Avatar from '../../components/Avatar';
import StarRating from '../../components/StarRating';
import { DetailsIcon, ExportIcon } from '../../icons';

import './UserReport.scss';

interface BookingActionsProps {
  role: string
  booking: Booking
}

const BookingActions: React.FC<BookingActionsProps> = ({ role, booking }) => {
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const onShowDetails = () => {
    history.push(`/${role}/report/booking/${booking.id}`);
  };

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'top', 'left', 'right']}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="#daf3ef"
          arrowSize={10}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <div className="popover-content">
            <div className="option" onClick={onShowDetails}>
              <IonLabel>
                <DetailsIcon />
                Details
              </IonLabel>
            </div>
          </div>
        </ArrowContainer>
      )}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      <span
        className="action"
        title="More actions"
        onClick={(e) => {
          e.stopPropagation();
          setIsPopoverOpen(!isPopoverOpen);
        }}
      >
        <IonIcon icon={ellipsisHorizontal} />
      </span>
    </Popover>
  );
};

BookingActions.propTypes = {
  role: PropTypes.string.isRequired,
  booking: PropTypes.any.isRequired
};

interface MatchParams {
  id: string
}

interface StateProps {
  currentUser: User
  providerTypes: ProviderType[]
  allSpecialists: Specialist[]
}

type UserReportProp = RouteComponentProps<MatchParams> & StateProps;

const UserReport: React.FC<UserReportProp> = ({
  match, history, currentUser, providerTypes, allSpecialists
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [servicesMap, setServicesMap] = useState<any>(null);
  const [showServicesInfo, setShowServicesInfo] = useState(false);
  const [dateRange, setDateRange] = useState<Range | null>(null);
  const [tempDateRange, setTempDateRange] = useState<Range | null>(null);
  const [showCalendarPopover, setShowCalendarPopover] = useState(false);

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const onRemove = () => {
    if (user) {
      setIsLoading(true);
      inactivateAccount(user.id, true)
        .then((res) => {
          if (res.success) {
            history.goBack();
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const selectPeriod = (type: string) => {
    if (type === 'today') {
      setTempDateRange({
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        key: 'selection'
      });
    }
    if (type === 'week') {
      setTempDateRange({
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastMonth') {
      setTempDateRange({
        startDate: moment().subtract(1, 'months').startOf('month').toDate(),
        endDate: moment().subtract(1, 'months').endOf('month').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastQuarter') {
      setTempDateRange({
        startDate: moment().subtract(3, 'months').startOf('quarter').toDate(),
        endDate: moment().subtract(3, 'months').endOf('quarter').toDate(),
        key: 'selection'
      });
    }
  };

  const getUserReportPDF = async () => {
    if (user) {
      const url = await exportUserReport(user.id, {
        dateRange: dateRange ? [
          moment(dateRange.startDate).toISOString(),
          moment(dateRange.endDate).toISOString()
        ] : null,
      });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `User_Report_${user.id}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const totalAmount = useMemo(() => {
    if (user) {
      return bookings.reduce((total, booking) => {
        if (user.role === UserRole.CUSTOMER) {
          return (total * 1000 + (booking.charge?.chargedAmount || 0) * 1000) / 1000;
        }
        return (total * 1000 + (booking.charge?.providerFee || 0) * 1000) / 1000;
      }, 0);
    }
    return 0;
  }, [bookings, user]);

  const mostUsedService = useMemo(() => {
    const serviceFrequencyMap: any = {};
    let mostService = '';

    bookings.forEach((booking) => {
      const { service } = booking;
      if (!serviceFrequencyMap[service]) {
        serviceFrequencyMap[service] = 0;
      }
      // eslint-disable-next-line no-plusplus
      serviceFrequencyMap[service]++;
    });

    let maxFrequency = -1;
    setServicesMap(serviceFrequencyMap);
    // eslint-disable-next-line no-restricted-syntax
    for (const service in serviceFrequencyMap) {
      if (serviceFrequencyMap[service] > maxFrequency) {
        maxFrequency = serviceFrequencyMap[service];
        mostService = service;
      }
    }

    return mostService;
  }, [bookings]);

  const sortedBookings = useMemo(() => {
    if (bookings.length) {
      const currentBookings = [...bookings];
      const sortedByCreatedAt = currentBookings.sort((a, b) => {
        if (a.createdAt && b.createdAt && a.createdAt < b.createdAt) {
          return -1;
        }
        return 1;
      });
      return sortedByCreatedAt;
    }
    return [];
  }, [bookings]);

  useEffect(() => {
    const getUser = (id: number) => {
      setIsLoading(true);
      const data = {
        userId: id,
        dateRange: dateRange ? [
          moment(dateRange.startDate).toISOString(),
          moment(dateRange.endDate).toISOString()
        ] : null,
        orderBy,
        isDescending,
      };
      fetchBookingsByUser(data)
        .then((res) => {
          if (res.success) {
            setUser(res.user);
            setBookings(res.bookings);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setIsLoading(false));
    };

    if (match.params.id) {
      getUser(Number(match.params.id));
    } else if (currentUser) {
      getUser(currentUser.id);
    }
  }, [match, currentUser]);

  useEffect(() => {
    if (user) {
      const data = {
        userId: user.id,
        dateRange: dateRange ? [
          moment(dateRange.startDate).toISOString(),
          moment(dateRange.endDate).toISOString()
        ] : null,
        orderBy,
        isDescending,
      };
      fetchBookingsByUser(data)
        .then((res) => {
          if (res.success) {
            setBookings([...res.bookings]);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [orderBy, isDescending, user, dateRange]);

  return (
    <IonContent id="user-report" className="page-content ion-padding">
      {isLoading || !user ? <IonLoading isOpen={isLoading} /> : (
        <>
          {match.params.id ? (
            <IonListHeader className="page-header">
              {currentUser.role === UserRole.ADMIN && user?.role === UserRole.PROVIDER ? 'Provider Information' : null}
              {currentUser.role === UserRole.ADMIN && user?.role === UserRole.CUSTOMER ? 'Customer Information' : null}
              {currentUser.role === UserRole.CLINIC_ADMIN && user?.role === UserRole.CLINIC_MEMBER ? 'Member Information' : null}
            </IonListHeader>
          ) : null}
          <div className="personal-summary">
            <div className="personal-info">
              <Avatar user={user} size="lg" />
              <div className="details">
                <h2>{`${user.name} ${user.lastName || ''}`}</h2>
                {user.role === UserRole.CUSTOMER ? <p>{user.email}</p> : null}
                {user.role === UserRole.PROVIDER ? <p>{providerTypes.find((pt) => pt.id === user.type)?.name}</p> : null}
                {user.role === UserRole.CLINIC_MEMBER ? <p>{providerTypes.find((pt) => pt.id === user.type)?.name}</p> : null}
                {user.role === UserRole.PROVIDER || user.role === UserRole.CLINIC_MEMBER ? (
                  <span className="rating">
                    <StarRating hasOneStar readonly rating={Number(((user.rating || 0) / 5).toFixed(1))} />
                    <span className="rating-value">{((user.rating || 0) / 5).toFixed(1)}</span>
                  </span>
                ) : null}
              </div>
            </div>
            <div className="report-actions">
              <IonButton color="dark" fill="outline" onClick={getUserReportPDF}>
                <ExportIcon />
                &nbsp;Export
              </IonButton>
              {currentUser.role === UserRole.ADMIN ? (
                <IonButton color="danger" onClick={onRemove}>
                  {user.role === UserRole.CUSTOMER ? 'Delete Customer' : 'Delete Provider'}
                </IonButton>
              ) : null}
            </div>
          </div>

          <div className="report-body">
            <div className="report-summary">
              <div className="report-sub-summary">
                <h2 className="sub-title">Info</h2>
                <div className="report-content">
                  <div className="report-option">
                    <span className="label">First name</span>
                    <span className="content">{user.name}</span>
                  </div>
                  <div className="report-option">
                    <span className="label">Last name</span>
                    <span className="content">{user.lastName || ''}</span>
                  </div>
                  <div className="report-option">
                    <span className="label">Location</span>
                    {user.role === UserRole.CUSTOMER ? (
                      <div className="list-content col">
                        <span className="location">{user.locations?.[0].address || ''}</span>
                      </div>
                    ) : (
                      <div className="list-content col">
                        {user.locations.map((location) => (
                          <span className="location">{location.address}</span>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="report-option">
                    <span className="label">Contact Number</span>
                    <span className="content">{user.phone}</span>
                  </div>
                  <div className="report-option">
                    <span className="label">Registration date</span>
                    <span className="content">{moment(user.createdAt).format('DD MMMM, YYYY')}</span>
                  </div>
                </div>
              </div>
              <div className="report-sub-summary">
                <h2 className="sub-title">Bookings Info</h2>
                <div className="report-content">
                  {user.role === UserRole.PROVIDER ? (
                    <div className="report-option">
                      <span className="label">Provider Type</span>
                      <span className="content">{providerTypes.find((pt) => pt.id === user.type)?.name}</span>
                    </div>
                  ) : null}
                  {user.role === UserRole.PROVIDER ? (
                    <div className="report-option">
                      <span className="label">Specialities</span>
                      <div className="list-content">
                        {user.specialists.map((spec) => (
                          <span className="speciality" key={spec.id}>
                            {spec.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  {user.role === UserRole.PROVIDER ? (
                    <div className="report-option">
                      <span className="label">Services</span>
                      <div className="list-content">
                        {user.services.map((service) => (
                          <span className="service" key={service.id}>
                            {service.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  <div className="report-option">
                    <span className="label">Total bookings</span>
                    <span className="content">{bookings.length}</span>
                  </div>
                  <div className="report-option">
                    <span className="label">First booking date</span>
                    <span className="content">{sortedBookings.length ? moment(sortedBookings[0].createdAt).format('DD MMMM, YYYY') : 'No booking'}</span>
                  </div>
                  <div className="report-option">
                    <span className="label">Last booking date</span>
                    <span className="content">{sortedBookings.length ? moment(sortedBookings[sortedBookings.length - 1].createdAt).format('DD MMMM, YYYY') : 'No booking'}</span>
                  </div>
                  {user.role === UserRole.CUSTOMER ? (
                    <div className="report-option">
                      <span className="label">Most used service</span>
                      <span className="content">
                        {mostUsedService}
                        {mostUsedService ? (
                          <span className="cursor-pointer" onClick={() => setShowServicesInfo(true)}>
                            <IonIcon color="favorite" icon={helpCircle} />
                          </span>
                        ) : null}
                      </span>
                    </div>
                  ) : null}
                  <div className="report-option">
                    <span className="label">Total amount booked</span>
                    <span className="content">{`$${totalAmount}`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bookings-view">
            <div className="sub-header">
              <h2 className="sub-title">Bookings Details</h2>
              <Popover
                isOpen={showCalendarPopover}
                positions={['bottom', 'top', 'left', 'right']}
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor="#daf3ef"
                    arrowSize={10}
                    className="popover-arrow-container"
                    arrowClassName="popover-arrow"
                  >
                    <div className="popover-content calendar">
                      <div className="panel">
                        <div
                          className={clsx('option', { selected: !tempDateRange })}
                          onClick={() => setTempDateRange(null)}
                        >
                          All periods
                        </div>
                        <div
                          className={clsx('option', {
                            selected: tempDateRange && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment(tempDateRange.endDate).format('YYYY-MM-DD')
                          })}
                          onClick={() => selectPeriod('today')}
                        >
                          Today
                        </div>
                        <div
                          className={clsx('option', {
                            selected: tempDateRange
                              && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().startOf('week').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().endOf('week').format('YYYY-MM-DD')
                          })}
                          onClick={() => selectPeriod('week')}
                        >
                          This week
                        </div>
                        <div
                          className={clsx('option', {
                            selected: tempDateRange
                              && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                          })}
                          onClick={() => selectPeriod('lastMonth')}
                        >
                          Last month
                        </div>
                        <div
                          className={clsx('option', {
                            selected: tempDateRange
                              && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').startOf('quarter').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').endOf('quarter').format('YYYY-MM-DD')
                          })}
                          onClick={() => selectPeriod('lastQuarter')}
                        >
                          Last quarter
                        </div>
                        <IonButton
                          color="favorite"
                          onClick={() => {
                            setDateRange(tempDateRange);
                            setShowCalendarPopover(false);
                          }}
                        >
                          Apply
                        </IonButton>
                      </div>
                      <div className="calendar">
                        <DateRangePicker
                          editableDateInputs
                          onChange={(item) => {
                            setTempDateRange({
                              startDate: moment(item.selection.startDate).toDate(),
                              endDate: moment(item.selection.endDate).add(1, 'days').subtract(1, 'milliseconds').toDate(),
                              key: 'selection'
                            });
                          }}
                          moveRangeOnFirstSelection={false}
                          ranges={tempDateRange ? [tempDateRange] : [
                            {
                              startDate: new Date(),
                              endDate: new Date(),
                              key: 'selection'
                            },
                          ]}
                          rangeColors={['#000']}
                        />
                      </div>
                    </div>
                  </ArrowContainer>
                )}
                onClickOutside={() => setShowCalendarPopover(false)}
              >
                <IonItem lines="none" className="date-range" onClick={() => setShowCalendarPopover(true)}>
                  <div>
                    {dateRange ? moment(dateRange.startDate).format('DD MMMM, YYYY') : 'Start Date'}
                  </div>
                  <IonIcon color="dark" icon={removeOutline} />
                  <div>
                    {dateRange ? moment(dateRange.endDate).format('DD MMMM, YYYY') : 'End Date'}
                  </div>
                  <IonIcon slot="end" icon={calendarOutline} />
                </IonItem>
              </Popover>
            </div>
            <IonGrid>
              <IonRow className="table-header">
                <IonCol className="sm">
                  NO
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer sm', { isOrderBy: orderBy === 'id' })}
                  onClick={() => onChangeOrderBy('id')}
                >
                  B.ID&nbsp;
                  {orderBy === 'id' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                {user.role === UserRole.PROVIDER || user.role === UserRole.CLINIC_MEMBER ? (
                  <IonCol
                    size="2.5"
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'customer' })}
                    onClick={() => onChangeOrderBy('customer')}
                  >
                    CUSTOMER NAME AND EMAIL&nbsp;
                    {orderBy === 'customer' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                ) : null}
                {user.role === UserRole.CUSTOMER ? (
                  <IonCol
                    size="2.5"
                    className={clsx('cursor-pointer', { isOrderBy: orderBy === 'provider' })}
                    onClick={() => onChangeOrderBy('provider')}
                  >
                    PROVIDER NAME AND EMAIL&nbsp;
                    {orderBy === 'provider' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                  </IonCol>
                ) : null}
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'date' })}
                  onClick={() => onChangeOrderBy('date')}
                >
                  APPT. DATETIME&nbsp;
                  {orderBy === 'date' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'createdAt' })}
                  onClick={() => onChangeOrderBy('createdAt')}
                >
                  CREATED AT&nbsp;
                  {orderBy === 'createdAt' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'service' })}
                  onClick={() => onChangeOrderBy('service')}
                >
                  SERVICE NAME&nbsp;
                  {orderBy === 'service' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'duration' })}
                  onClick={() => onChangeOrderBy('duration')}
                >
                  DURATION&nbsp;
                  {orderBy === 'duration' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'price' })}
                  onClick={() => onChangeOrderBy('price')}
                >
                  PRICE&nbsp;
                  {orderBy === 'price' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'providerFee' })}
                  onClick={() => onChangeOrderBy('providerFee')}
                >
                  PRO. SPLIT
                  {orderBy === 'providerFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'cpFee' })}
                  onClick={() => onChangeOrderBy('cpFee')}
                >
                  C.P SPLIT
                  {orderBy === 'cpFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'stripeFee' })}
                  onClick={() => onChangeOrderBy('stripeFee')}
                >
                  STRIPE FEES
                  {orderBy === 'stripeFee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol>
                  STATUS
                </IonCol>
                <IonCol>
                  ACTIONS
                </IonCol>
              </IonRow>
              {bookings.map((booking, index) => (
                <IonRow key={booking.id} className="table-row">
                  <IonCol className="sm">{index + 1}</IonCol>
                  <IonCol className="sm">{booking.id}</IonCol>
                  {user.role === UserRole.CUSTOMER ? (
                    <IonCol size="2.5">
                      <div className="user-name-email">
                        <Avatar user={booking.provider} />
                        <div>
                          <h4>{`${booking.provider.name} ${booking.provider.lastName ?? ''}`}</h4>
                          <p>{booking.provider.email}</p>
                        </div>
                      </div>
                    </IonCol>
                  ) : null}
                  {user.role === UserRole.PROVIDER || user.role === UserRole.CLINIC_MEMBER ? (
                    <IonCol size="2.5">
                      <div className="user-name-email">
                        <Avatar user={booking.customer} />
                        <div>
                          <h4>{`${booking.customer.name} ${booking.customer.lastName ?? ''}`}</h4>
                          <p>{booking.customer.email}</p>
                        </div>
                      </div>
                    </IonCol>
                  ) : null}
                  <IonCol>
                    {moment.utc(`${booking.date} ${booking.time}`).local().format('DD MMMM, YYYY hh:mm A')}
                  </IonCol>
                  <IonCol>
                    {moment.utc(booking.createdAt).local().format('DD MMM YYYY, hh:mm A')}
                  </IonCol>
                  <IonCol>
                    {booking.service}
                  </IonCol>
                  <IonCol>
                    {`${booking.duration}mins`}
                  </IonCol>
                  <IonCol>
                    {`$${booking.price}`}
                  </IonCol>
                  <IonCol>
                    {booking.charge.providerFee >= 0 ? `$${booking.charge.providerFee}` : `-$${-1 * booking.charge.providerFee}`}
                  </IonCol>
                  <IonCol>
                    {booking.charge.cpFee >= 0 ? `$${booking.charge.cpFee}` : `-$${-1 * booking.charge.cpFee}`}
                  </IonCol>
                  <IonCol>
                    {`$${booking.charge.stripeFee}`}
                  </IonCol>
                  <IonCol>
                    {booking.status === BookingStatus.PENDING ? (
                      <div className="status pending">Pending</div>
                    ) : null}
                    {booking.status === BookingStatus.REJECTED_PROVIDER ? (
                      <div className="status rejected">Rejected</div>
                    ) : null}
                    {booking.status === BookingStatus.REJECTED_CUSTOMER ? (
                      <div className="status abandoned">Abandoned</div>
                    ) : null}
                    {booking.status === BookingStatus.CANCELLED_CUSTOMER || booking.status === BookingStatus.CANCELLED_PROVIDER ? (
                      <div className="status cancelled">Cancelled</div>
                    ) : null}
                    {booking.status === BookingStatus.ACCEPTED ? (
                      <div className="status accepted">Accepted</div>
                    ) : null}
                    {booking.status === BookingStatus.COMPLETED ? (
                      <div className="status completed">Completed</div>
                    ) : null}
                    {booking.status === BookingStatus.INCOMPLETE ? (
                      <div className="status incomplete">Incomplete</div>
                    ) : null}
                  </IonCol>
                  <IonCol>
                    <BookingActions role={currentUser.role} booking={booking} />
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
          </div>

          <IonModal
            cssClass="services-map"
            isOpen={showServicesInfo}
            onDidDismiss={() => setShowServicesInfo(false)}
          >
            <IonLabel>Services Frequency Map</IonLabel>
            <div className="services">
              {servicesMap && Object.entries(servicesMap).map(([key, value]) => (
                <div className="service-option">
                  <span className="name">{key}</span>
                  <span className="count">{Number(value)}</span>
                </div>
              ))}
            </div>
            <IonButton color="favorite" expand="block" onClick={() => setShowServicesInfo(false)}>
              Confirm
            </IonButton>
          </IonModal>
        </>
      )}
    </IonContent>
  );
};

UserReport.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  currentUser: PropTypes.any.isRequired,
  providerTypes: PropTypes.array.isRequired,
  allSpecialists: PropTypes.array.isRequired
};

export default connect<RouteComponentProps<MatchParams>, StateProps, {}>({
  mapStateToProps: (state) => ({
    currentUser: state.auth.user,
    providerTypes: state.data.providerTypes,
    allSpecialists: state.data.allSpecialists,
  }),
  component: React.memo(UserReport)
});
