import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonSelect,
  IonSelectOption,
  IonAlert,
  IonListHeader,
  IonLoading,
  IonModal,
} from '@ionic/react';
import Calendar from 'rc-calendar';
import moment, { Moment } from 'moment';
import clsx from 'clsx';

import Avatar from '../../components/Avatar';
import PlaceSearchInput from '../../components/PlaceSearchInput';
import connect from '../../data/connect';
import {
  User, Service, Location, ServiceItem, AppointmentType
} from '../../models';
import { fetchProvider, fetchProviderTimeslots } from '../../data/dataApi';
import { StarFilledIcon } from '../../icons';

import './ProviderSchedule.scss';

interface MatchParams {
  id: string
}

interface StateProps {
  user: User
}

type ProviderScheduleProps = RouteComponentProps<MatchParams> & StateProps;

const ProviderSchedule: React.FC<ProviderScheduleProps> = ({
  match,
  history,
  user
}) => {
  const [provider, setProvider] = useState<User | null>(null);
  const [currentDate, setCurrentDate] = useState<Moment | null>(null);
  const [slots, setSlots] = useState<{ slot: number, type: string }[]>([]);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [selectedServiceItem, setSelectedServiceItem] = useState<ServiceItem | null>(null);
  const [location, setLocation] = useState<Location | null>(null);
  const [newLocation, setNewLocation] = useState<Location | null>(null);
  const [visitType, setVisitType] = useState<'video-visit' | 'provider-visit' | 'customer-visit'>('video-visit');
  const [alert, setAlert] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSelectDate = useCallback(
    async (date: Moment) => {
      if (!provider || !location) {
        setAlert(true);
        return;
      }
      setCurrentDate(date);
      const data = await fetchProviderTimeslots(
        provider.id,
        location.id,
        moment(date.format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm')
      );
      if (data.success) {
        setSlots(data.slots);
      }
    },
    [provider, location]
  );

  useEffect(() => {
    if (currentDate) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onSelectDate(currentDate);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (provider && provider.locations.length > 0) {
      setLocation(provider.locations[0]);
    }
    if (provider && provider.services.length === 1) {
      setSelectedService(provider.services[0]);
    }
  }, [provider]);

  useEffect(() => {
    if (selectedService && selectedService.items && selectedService.items.length > 0) {
      const availableItems = selectedService.items
        .filter((item) => item.type === visitType)
        .sort((a, b) => a.duration - b.duration);
      setSelectedServiceItem(availableItems[0]);
    }
  }, [selectedService, visitType]);

  useEffect(() => {
    if (match.params.id) {
      setIsLoading(true);
      fetchProvider(Number(match.params.id))
        .then((res: { success: boolean, user: User }) => {
          if (res.success) {
            setProvider(res.user);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setIsLoading(false));
    }
  }, [match]);

  useEffect(() => {
    if (visitType === AppointmentType.CUSTOMER_LOCATION) {
      setLocation(user.locations?.[0]);
    } else {
      setLocation(provider?.locations?.[0] || null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitType]);

  const renderService = (service: Service): JSX.Element => (
    <>
      <IonItem
        key={service.id}
        lines="none"
        className="book-service"
        onClick={(): void => {
          setSelectedService(service);
        }}
      >
        <IonRadio slot="start" value={service} />
        <IonLabel>{service.name}</IonLabel>
      </IonItem>
      {selectedService && selectedService.id === service.id && (
        <IonList>
          {service.items
            ?.filter((item) => item.type === visitType)
            ?.sort((a, b) => a.duration - b.duration)
            ?.map((item) => (
              <IonItem
                key={`subservice-${item.id}`}
                lines="none"
                className={clsx('service-item', { active: selectedService?.id === service.id && selectedServiceItem?.id === item.id })}
                onClick={() => setSelectedServiceItem(item)}
              >
                <IonLabel>
                  {`${Math.floor(item.duration / 60) > 0 ? `${Math.floor(item.duration / 60)}h` : ''}${item.duration % 60 ? ` ${item.duration % 60}mins` : ''}`}
                </IonLabel>
                <IonLabel>
                  {`$${item.price.toFixed(1)}`}
                </IonLabel>
              </IonItem>
            ))}
        </IonList>
      )}
    </>
  );

  const onConfirm = (slot: number, type: string): void => {
    if (currentDate) {
      if (provider && selectedService && selectedServiceItem && location) {
        history.push({
          pathname: `/customer/provider/${provider.id}/confirm-schedule`,
          state: {
            location,
            date: currentDate.format('YYYY-MM-DD'),
            slot,
            type,
            service: selectedService.id,
            item: selectedServiceItem.id
          }
        });
      } else {
        setAlert(true);
      }
    }
  };

  if (!provider) {
    return <div>Invalid Provider</div>;
  }

  return (
    <IonContent id="provider-schedule" className="provider-schedule-content page-content">
      <IonListHeader className="page-header">Appointment</IonListHeader>
      <IonGrid>
        <IonRow className="profile-content profile">
          <Avatar user={provider} size="lg" />
          <IonLabel>
            <h2>{`${provider.name} ${provider.lastName ?? ''}`}</h2>
            <p>{provider.title}</p>
            <span>
              <StarFilledIcon />
              {provider.rating || '0.0'}
            </span>
          </IonLabel>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonLabel className="section-title">Appointment type</IonLabel>
            <IonSelect
              className="location-select"
              value={visitType}
              onIonChange={(event) => setVisitType(event.detail.value as 'video-visit' | 'provider-visit' | 'customer-visit')}
            >
              <IonSelectOption value="video-visit">Virtual</IonSelectOption>
              <IonSelectOption value="provider-visit">Provider Location</IonSelectOption>
              <IonSelectOption value="customer-visit">Customer Location</IonSelectOption>
            </IonSelect>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonLabel className="section-title">Select service</IonLabel>
            <IonRadioGroup
              value={selectedService}
            >
              {provider.services.map((service) => renderService(service))}
            </IonRadioGroup>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {visitType === AppointmentType.PROVIDER_LOCATION ? (
              <>
                <IonLabel className="section-title">Select location</IonLabel>
                <IonSelect
                  interface="action-sheet"
                  className="location-select"
                  placeholder="Select location"
                  value={location}
                  onIonChange={(e): void => setLocation(e.detail.value)}
                >
                  {provider.locations.map((item) => (
                    <IonSelectOption key={item.id} value={item}>
                      {item.address}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </>
            ) : null}
            {visitType === AppointmentType.CUSTOMER_LOCATION ? (
              <>
                <div className="sub-header">
                  <IonLabel className="section-title">Meeting location</IonLabel>
                  <IonButton color="favorite" fill="clear" onClick={() => setShowLocationModal(true)}>Set Other Location</IonButton>
                </div>
                <div className="description">{location?.address ?? 'No Customer Location'}</div>
              </>
            ) : null}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonLabel className="section-title">
              {currentDate ? currentDate.format('MMMM Do, YYYY') : 'Select date'}
            </IonLabel>
          </IonCol>
          <IonCol>
            <Calendar
              className="calendar"
              showDateInput={false}
              showToday={false}
              onSelect={(date): Promise<void> => onSelectDate(date)}
              disabledDate={(current): boolean => {
                if (provider.limitPeriod) {
                  return !(moment(moment().format('MM-DD-YYYY')).isSameOrBefore(current) && moment(moment().add(provider.limitPeriod, 'weeks').format('MM-DD-YYYY')).isSameOrAfter(current));
                }
                return moment(moment().format('MM-DD-YYYY')).isSameOrAfter(current);
              }}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          {slots
            .filter(({ slot, type }) => {
              if (type !== visitType) {
                return false;
              }
              if (!selectedServiceItem) {
                return true;
              }
              return !!slots.find((sl) => sl.slot === slot + selectedServiceItem.duration / 15 - 1);
            })
            .map(({ slot, type }, index: number) => (
              <IonCol key={index} size="3">
                <IonButton
                  className="round-border time-slot"
                  fill="solid"
                  expand="block"
                  // eslint-disable-next-line no-nested-ternary
                  color={type === 'video-visit' ? 'facebook' : type === 'provider-visit' ? 'primary' : 'workshop'}
                  onClick={(): void => onConfirm(slot, type)}
                >
                  {moment({ hour: slot / 4, minute: 15 * (slot % 4) }).format(
                    'h:mm A'
                  )}
                </IonButton>
              </IonCol>
            ))}
          {slots.length === 0 && 'No available slots'}
        </IonRow>
      </IonGrid>
      <IonAlert
        isOpen={alert}
        onDidDismiss={(): void => setAlert(false)}
        header="Care Platform"
        message="Please select service, duration and location."
        buttons={['ok']}
      />
      <IonModal
        cssClass="locations-modal"
        isOpen={showLocationModal}
        onDidDismiss={() => {
          setNewLocation(null);
          setShowLocationModal(false);
        }}
      >
        <IonLabel>Other Location for Appointment</IonLabel>
        <div className="locations">
          <div className="location-input">
            <PlaceSearchInput
              value={newLocation?.address}
              onChange={(value): void => setNewLocation(value)}
            />
          </div>
        </div>
        <div className="actions">
          <IonButton
            color="favorite"
            disabled={!location || !location.address}
            onClick={() => {
              setLocation(newLocation);
              setShowLocationModal(false);
            }}
          >
            Confirm
          </IonButton>
          <IonButton
            color="danger"
            onClick={() => {
              setNewLocation(null);
              setShowLocationModal(false);
            }}
          >
            Cancel
          </IonButton>
        </div>
      </IonModal>
      <IonLoading isOpen={isLoading} />
    </IonContent>
  );
};

ProviderSchedule.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  component: React.memo(ProviderSchedule)
});
