import React from 'react';
import PropTypes from 'prop-types';
import {
  IonItem, IonLabel, IonIcon, IonButton
} from '@ionic/react';
import { cash, trashOutline } from 'ionicons/icons';

import { PaymentSource } from '../models';
import { WalletIcon } from '../icons';

interface BankAccountItemProps {
  isEnableDelete?: boolean
  source: PaymentSource
  onDelete?: (id: number) => void
  onSelectDefault?: (id: number) => void
}

const BankAccountItem: React.FC<BankAccountItemProps> = ({
  isEnableDelete,
  source,
  onDelete,
  onSelectDefault
}) => (
  <IonItem lines="none" className="bank-account">
    {source.default ? (
      <IonButton fill="clear">
        <WalletIcon color="#08A284" />
      </IonButton>
    ) : (
      <IonButton
        fill="clear"
        onClick={(): void => onSelectDefault && onSelectDefault(source.id)}
      >
        <WalletIcon />
      </IonButton>
    )}
    <IonLabel>
      <p>{`Bank Account ending in ... ${source.last4}`}</p>
    </IonLabel>
    <div className="actions" slot="end">
      {!source.default && isEnableDelete ? (
        <IonButton
          fill="clear"
          color="dark"
          onClick={(): void => onDelete && onDelete(source.id)}
        >
          <IonIcon
            className="close-icon"
            icon={trashOutline}
            size="small"
          />
        </IonButton>
      ) : null}
    </div>
  </IonItem>
);

BankAccountItem.defaultProps = {
  isEnableDelete: true,
  onDelete: (): void => {},
  onSelectDefault: (): void => {}
};

BankAccountItem.propTypes = {
  isEnableDelete: PropTypes.bool,
  source: PropTypes.any.isRequired,
  onDelete: PropTypes.func,
  onSelectDefault: PropTypes.func
};

export default BankAccountItem;
