import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import { IonRouterOutlet, IonTabs } from '@ionic/react';

import connect from '../../data/connect';
import Layout from '../../components/Layout';
import { socket } from '../../data/dataApi';
import {
  dispatchFetchProviderTypes,
  dispatchFetchLanguages,
  dispatchFetchSpecialists,
  dispatchFetchRequests,
  dispatchSocialMediaTypes,
  dispatchFetchRoles
} from '../../data/store/data/data.actions';
import { User } from '../../models';
import useGetView from '../../hooks/useGetView/useGetView';
import AdminDashboard from '../AdminDashboard';
import ProvidersList from '../ProvidersList';
import ProviderCertification from '../ProviderCertification';
import ClinicsList from '../ClinicsList';
import CalendarView from '../CalendarView';
import AdminReports from '../AdminReports';
import BookingReport from '../BookingReport';
import ClinicReport from '../ClinicReport';
import UserReport from '../UserReport';
import AdminClinicMembers from '../AdminClinicMembers';
import AdminMarketing from '../AdminMarketing';
import Users from '../Users';

interface StateProps {
  user: User
}

interface DispatchProps {
  fetchProviderTypes: typeof dispatchFetchProviderTypes
  fetchLanguages: typeof dispatchFetchLanguages
  fetchSpecialists: typeof dispatchFetchSpecialists
  fetchRequests: typeof dispatchFetchRequests
  fetchSocialMediaTypes: typeof dispatchSocialMediaTypes
  fetchRoles: typeof dispatchFetchRoles
}

type MarketingMemberViewProps = StateProps & DispatchProps;

const MarketingMemberView: React.FC<MarketingMemberViewProps> = ({
  user,
  fetchProviderTypes,
  fetchLanguages,
  fetchSpecialists,
  fetchRequests,
  fetchSocialMediaTypes,
  fetchRoles
}) => {
  const { isMobile } = useGetView();

  useEffect(() => {
    fetchProviderTypes();
  }, [fetchProviderTypes]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    fetchSpecialists();
  }, [fetchSpecialists]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests]);

  useEffect(() => {
    fetchSocialMediaTypes();
  }, [fetchSocialMediaTypes]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    socket.on('update-request', (request: Request) => {
      fetchRequests();
    });
  }, []);

  const renderRoutes = () => (
    <IonRouterOutlet>
      <Layout>
        <Route path="/marketing-member" render={() => <Redirect to="/marketing-member/dashboard" />} exact />
        <Route path="/marketing-member/dashboard" component={AdminDashboard} exact />
        <Route path="/marketing-member/providers" component={ProvidersList} exact />
        <Route path="/marketing-member/provider/:id" component={ProviderCertification} exact />
        <Route path="/marketing-member/clinics" component={ClinicsList} exact />
        <Route path="/marketing-member/clinic/:id" component={ProvidersList} exact />
        <Route path="/marketing-member/calendar" component={CalendarView} exact />
        <Route path="/marketing-member/users" component={Users} exact />
        <Route path="/marketing-member/report" component={AdminReports} exact />
        <Route path="/marketing-member/report/user/:id" component={UserReport} exact />
        <Route path="/marketing-member/report/clinic/:id" component={ClinicReport} exact />
        <Route path="/marketing-member/report/booking/:id" component={BookingReport} exact />
        <Route path="/marketing-member/clinic-members" component={AdminClinicMembers} exact />
        <Route path="/marketing-member/marketing" component={AdminMarketing} exact />
      </Layout>
    </IonRouterOutlet>
  );

  if (!isMobile) {
    return renderRoutes();
  }

  return (
    <IonTabs>
      {renderRoutes()}
    </IonTabs>
  );
};

MarketingMemberView.propTypes = {
  user: PropTypes.any.isRequired,
  fetchProviderTypes: PropTypes.func.isRequired,
  fetchLanguages: PropTypes.func.isRequired,
  fetchSpecialists: PropTypes.func.isRequired,
  fetchRequests: PropTypes.func.isRequired,
  fetchSocialMediaTypes: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {
    fetchProviderTypes: dispatchFetchProviderTypes,
    fetchLanguages: dispatchFetchLanguages,
    fetchSpecialists: dispatchFetchSpecialists,
    fetchRequests: dispatchFetchRequests,
    fetchSocialMediaTypes: dispatchSocialMediaTypes,
    fetchRoles: dispatchFetchRoles
  },
  component: React.memo(MarketingMemberView)
});
