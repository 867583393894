import React, {
  useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  IonList,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonModal,
  IonLabel,
  IonListHeader,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import {
  brushOutline,
  trashOutline,
  checkmarkOutline,
  closeOutline
} from 'ionicons/icons';
import { BiHome, BiClinic, BiVideo } from 'react-icons/bi';

import {
  AppointmentType,
  Service
} from '../models';

const allOptions = [15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240];

interface Item {
  service: string
  duration: number
  price: number
  type: string
}
interface ServiceManagementProps {
  cssClass?: string
  isEnableToAdd?: boolean
  hasTitle?: boolean
  services: Service[]
  updateServices: (services: Service[]) => void
}

const ServiceManagement: React.FC<ServiceManagementProps> = ({
  cssClass, isEnableToAdd, hasTitle, services, updateServices
}) => {
  const [newServiceName, setNewServiceName] = useState('');
  const [showSelectDurationModal, setShowSelectDurationModal] = useState(false);
  const [timeOptions, setTimeOptions] = useState<number[]>([]);
  const [isItemEdit, setIsItemEdit] = useState(false);
  const [selectedServiceItem, setSelectedServiceItem] = useState<Item>({
    service: '',
    duration: 15,
    price: 0,
    type: AppointmentType.VIRTUAL
  });
  const [availableTypes, setAvailableTypes] = useState<string[]>([]);

  const onAddNewService = () => {
    updateServices([...services, { id: 0, name: newServiceName, items: [] }]);
    setNewServiceName('');
  };

  const updateServiceName = (previousServiceName: string, newName: string) => {
    const index = services.findIndex((sv) => sv.name === previousServiceName);
    const newServices = [...services];
    newServices[index].name = newName;
    updateServices([...newServices]);
  };

  const removeService = (service: Service) => {
    const updatedServices = services.filter((sv) => sv.name !== service.name);
    updateServices([...updatedServices]);
  };

  const onAddNewItem = () => {
    const updatedServices = services.map((sv) => {
      if (sv.name === selectedServiceItem.service) {
        const updatedItems = sv.items
          ? [...sv.items, { id: 0, duration: selectedServiceItem.duration, price: selectedServiceItem.price, type: selectedServiceItem.type }]
          : [{ id: 0, duration: selectedServiceItem.duration, price: selectedServiceItem.price, type: selectedServiceItem.type }];
        return { ...sv, items: [...updatedItems] };
      }
      return sv;
    });
    updateServices([...updatedServices]);
    setShowSelectDurationModal(false);
  };

  const onUpdateItem = () => {
    const updatedServices = services.map((sv) => {
      if (sv.name === selectedServiceItem.service && sv.items) {
        const updatedItems = sv.items?.map((it) => {
          if (it.duration === selectedServiceItem.duration && availableTypes.includes(it.type)) {
            return { ...it, type: selectedServiceItem.type, price: selectedServiceItem.price };
          }
          return it;
        });
        return { ...sv, items: [...updatedItems] };
      }
      return sv;
    });
    updateServices([...updatedServices]);
    setShowSelectDurationModal(false);
  };

  const onDeleteItem = () => {
    const updatedServices = services.map((sv) => {
      if (sv.name === selectedServiceItem.service && sv.items) {
        const updatedItems = sv.items.filter((it) => it.duration !== selectedServiceItem.duration || it.type !== selectedServiceItem.type);
        return { ...sv, items: [...updatedItems] };
      }
      return sv;
    });
    updateServices([...updatedServices]);
    setShowSelectDurationModal(false);
  };

  useEffect(() => {
    if (showSelectDurationModal && !isItemEdit && selectedServiceItem.service) {
      const currentService = services.find((sv) => sv.name === selectedServiceItem.service);
      const similarItems = currentService!.items?.filter((item) => item.duration === selectedServiceItem.duration);
      const tempTypes = ['video-visit', 'provider-visit', 'customer-visit'].filter((tp) => !similarItems?.find((item) => item.type === tp));
      if (tempTypes.length) {
        setSelectedServiceItem({ ...selectedServiceItem, type: tempTypes[0] });
      } else {
        setSelectedServiceItem({ ...selectedServiceItem, type: '' });
      }
      setAvailableTypes([...tempTypes]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceItem.service, selectedServiceItem.duration, isItemEdit, showSelectDurationModal]);

  const ServiceElement = ({ service }: { service: Service}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [newService, setNewService] = useState<Service | null>(null);
    const [isEdit, setIsEdit] = useState(false);
    const [newName, setNewName] = useState(service.name);

    useEffect(() => {
      if (isEdit) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 200);
      }
    }, [isEdit]);

    useEffect(() => {
      if (service) {
        setNewService(service);
      }
    }, [service]);

    if (!newService) {
      return null;
    }

    return (
      <div key={service.name} className="service">
        <div>
          {isEdit ? (
            <>
              <input
                className="name-input"
                ref={inputRef}
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
              <span
                className="icon-btn"
                onClick={() => {
                  setIsEdit(false);
                  updateServiceName(service.name, newName);
                }}
              >
                <IonIcon icon={checkmarkOutline} />
              </span>
              <span
                className="icon-btn"
                onClick={() => {
                  setIsEdit(false);
                  setNewName(service.name);
                }}
              >
                <IonIcon icon={closeOutline} />
              </span>
            </>
          ) : (
            <>
              <p>{newService?.name}</p>
              <span className="icon-btn" onClick={() => setIsEdit(true)}>
                <IonIcon icon={brushOutline} />
              </span>
              <span className="icon-btn" onClick={() => removeService(service)}>
                <IonIcon icon={trashOutline} />
              </span>
            </>
          )}
        </div>
        {newService.items?.sort((a, b) => a.duration - b.duration).map((it) => (
          <div
            className="service-item"
            onClick={() => {
              const similarItems = newService.items?.filter((item) => item.duration === it.duration && item.type !== it.type);
              const tempTypes = ['video-visit', 'provider-visit', 'customer-visit'].filter((tp) => !similarItems?.find((item) => item.type === tp));
              setAvailableTypes([...tempTypes]);
              setSelectedServiceItem({
                service: newService.name, duration: it.duration, price: it.price, type: it.type
              });
              setIsItemEdit(true);
              setShowSelectDurationModal(true);
            }}
          >
            <span className="duration">
              {`${Math.floor(it.duration / 60) > 0 ? `${Math.floor(it.duration / 60)}h` : ''}${it.duration % 60 ? ` ${it.duration % 60}m` : ''}`}
            </span>
            <span className="price">{`$${it.price}`}</span>
            {it.type === AppointmentType.VIRTUAL ? <span className="service-type video"><BiVideo /></span> : null}
            {it.type === AppointmentType.PROVIDER_LOCATION ? <span className="service-type provider"><BiClinic /></span> : null}
            {it.type === AppointmentType.CUSTOMER_LOCATION ? <span className="service-type customer"><BiHome /></span> : null}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <IonGrid className={cssClass}>
        {hasTitle ? <IonListHeader className="sub-title">Services</IonListHeader> : null}
        <IonRow>
          <IonCol>
            <IonList>
              <IonItem lines="none" className="input">
                <IonInput
                  name="service"
                  type="text"
                  placeholder="Enter new service name..."
                  value={newServiceName}
                  disabled={!isEnableToAdd}
                  onIonChange={(e): void => setNewServiceName(e.detail.value || '')}
                />
                <IonButton
                  slot="end"
                  color="favorite"
                  disabled={!newServiceName || !isEnableToAdd}
                  onClick={onAddNewService}
                >
                  Add
                </IonButton>
              </IonItem>
              <IonItem lines="none" className="input">
                <div className="service-board">
                  <div className="time-options">
                    <span>Service Items</span>
                    <IonButton
                      color="favorite"
                      fill="clear"
                      className="add-option-btn"
                      disabled={!services.length}
                      onClick={() => {
                        setSelectedServiceItem({
                          service: services[0].name, duration: 15, price: 0, type: AppointmentType.VIRTUAL
                        });
                        setAvailableTypes(['video-visit', 'provider-visit', 'customer-visit']);
                        setIsItemEdit(false);
                        setShowSelectDurationModal(true);
                      }}
                    >
                      + Add Service Item
                    </IonButton>
                  </div>
                  <div className="services">
                    {services.map((service: Service, index) => (
                      <section key={index}>
                        <ServiceElement service={service} />
                      </section>
                    ))}
                  </div>
                </div>
              </IonItem>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonModal
        cssClass="service-details-modal"
        isOpen={showSelectDurationModal}
        onDidDismiss={() => setShowSelectDurationModal(false)}
      >
        <IonButton className="close-btn" fill="clear" color="dark" onClick={() => setShowSelectDurationModal(false)}>
          <IonIcon icon={closeOutline} size="large" />
        </IonButton>
        <IonLabel>{isItemEdit ? 'Update service option' : 'Add new service option'}</IonLabel>
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonLabel className="field-title">Duration</IonLabel>
              <IonItem lines="none" className="select">
                <IonSelect
                  className="select"
                  value={selectedServiceItem.duration}
                  disabled={isItemEdit}
                  onIonChange={(e): void => setSelectedServiceItem({ ...selectedServiceItem, duration: Number(e.detail.value) })}
                >
                  {allOptions.map((time) => (
                    <IonSelectOption value={time}>
                      {`${Math.floor(time / 60) > 0 ? `${Math.floor(time / 60)}h` : ''}${time % 60 ? ` ${time % 60}mins` : ''}`}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size="12">
              <IonLabel className="field-title">Service</IonLabel>
              <IonItem lines="none" className="select">
                <IonSelect
                  className="select"
                  value={selectedServiceItem.service}
                  disabled={isItemEdit}
                  onIonChange={(e): void => setSelectedServiceItem({ ...selectedServiceItem, service: String(e.detail.value) })}
                >
                  {services.map((service) => (
                    <IonSelectOption value={service?.name}>{service?.name}</IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size="12">
              <IonLabel className="field-title">Appointment type</IonLabel>
              <IonItem lines="none" className="select">
                <IonSelect
                  className="select"
                  value={selectedServiceItem.type}
                  disabled={!availableTypes.length}
                  onIonChange={(e): void => setSelectedServiceItem({ ...selectedServiceItem, type: String(e.detail.value) })}
                >
                  {availableTypes.map((at) => (
                    <IonSelectOption value={at}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {at === AppointmentType.VIRTUAL ? 'Virtual' : at === AppointmentType.PROVIDER_LOCATION ? 'Provider Location' : 'Customer Location'}
                    </IonSelectOption>
                  ))}
                  {!availableTypes.length ? (
                    <IonSelectOption value="">{`All types available for ${selectedServiceItem.duration}mins`}</IonSelectOption>
                  ) : null}
                </IonSelect>
              </IonItem>
            </IonCol>
            <IonCol size="12">
              <IonLabel className="field-title">Price</IonLabel>
              <IonItem lines="none" className="input">
                <IonInput
                  name="price"
                  type="number"
                  value={selectedServiceItem?.price}
                  onIonChange={(e): void => setSelectedServiceItem({ ...selectedServiceItem, price: Number(e.detail.value)})}
                />
              </IonItem>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="actions">
          {isItemEdit ? (
            <>
              <IonButton color="favorite" onClick={onUpdateItem}>Update</IonButton>
              <IonButton color="danger" onClick={onDeleteItem}>Delete</IonButton>
            </>
          ) : (
            <IonButton color="favorite" onClick={onAddNewItem}>Add</IonButton>
          )}
        </div>
      </IonModal>
    </>
  );
};

ServiceManagement.defaultProps = {
  cssClass: '',
  isEnableToAdd: true,
  hasTitle: false,
};

ServiceManagement.propTypes = {
  cssClass: PropTypes.string,
  isEnableToAdd: PropTypes.bool,
  hasTitle: PropTypes.bool,
  services: PropTypes.array.isRequired,
  updateServices: PropTypes.func.isRequired
};

export default React.memo(ServiceManagement);
