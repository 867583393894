import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonContent,
  IonList,
  IonItem,
  IonButton,
  IonInput,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonAlert,
  IonLoading,
  IonAvatar,
  IonLabel,
  IonListHeader,
  IonIcon,
  IonText,
  IonToggle,
  IonGrid,
  IonRow,
  IonCol,
  IonModal
} from '@ionic/react';
import { brushOutline, closeOutline } from 'ionicons/icons';
import clsx from 'clsx';

import connect from '../../data/connect';
import {
  User, Language, Specialist, Service, Location
} from '../../models';
import {
  updateClinicProfile,
  uploadProfileImage,
  getImage,
  getSignedUrl,
  deleteLocation
} from '../../data/dataApi';
import { dispatchUpdateUser } from '../../data/store/auth/auth.actions';
import Map from '../../components/Map';
import PlaceSearchInput from '../../components/PlaceSearchInput';
import {
  UserIcon, ListIcon, SphereIcon, StethoscopeIcon, LocationIcon
} from '../../icons';
import './ClinicAdminProfile.scss';

interface StateProps {
  allLanguages: Language[]
  allSpecialists: Specialist[]
  user: User
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

type ClinicAdminProfileProps = StateProps & DispatchProps;

const ClinicAdminProfile: React.FC<ClinicAdminProfileProps> = ({
  user,
  allLanguages,
  allSpecialists,
  updateUser
}) => {
  const [avatar, setAvatar] = useState(getImage(user.image));
  const [file, setFile] = useState();
  const [name, setName] = useState(user.name ?? '');
  const [title, setTitle] = useState(user.title ?? '');
  const [about, setAbout] = useState(user.about);
  const [email, setEmail] = useState(user.email ?? '');
  const [type, setType] = useState(user.type);
  const [languages, setLanguages] = useState(
    user.languages.map((language) => language.id)
  );
  const [specialists, setSpecialists] = useState(
    user.specialists.map((specialist) => specialist.id)
  );
  const [services, setServices] = useState(user.services);
  const [newService, setNewService] = useState('');

  const [location, setlocation] = useState<Location | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(user.locations[0] ?? null);
  const [locations, setLocations] = useState(user.locations);

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: '', status: false });
  const [smsEnabled, setSmsEnabled] = useState(user.smsEnabled);
  const [emailEnabled, setEmailEnabled] = useState(user.emailEnabled);
  const [notificationEnabled, setNotificationEnabled] = useState(user.notificationEnabled);
  const [showLocationsModal, setShowLocationsModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // state for change password
  const [showModal, setShowModal] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [changing, setChanging] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const onAddLocation = (): void => {
    if (location) {
      setLocations([...locations, location]);
      setlocation({ ...location, address: '' });
    }
  };

  const onRemoveLocation = (locationId: number, address: string, shouldCheckBooking: boolean) => {
    if (!locationId) {
      return setLocations(locations.filter((loc) => loc.address !== address));
    }
    const data = {
      locationId,
      noCheckBooking: !shouldCheckBooking
    };
    setIsDeleting(true);
    return deleteLocation(data)
      .then((res: { success: boolean }) => {
        if (res.success) {
          const updatedLocations = locations.filter((loc) => loc.id !== locationId);
          setLocations([...updatedLocations]);
          if (!updatedLocations.length) {
            setShowLocationsModal(false);
            setSelectedLocation(null);
          }
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsDeleting(false));
  };

  const onAddService = (): void => {
    if (newService && !services.find((item) => item.name === newService)) {
      setServices([...services, { id: -1, name: newService } as Service]);
      setNewService('');
    }
  };

  const onRemoveService = (item: Service): void => {
    setServices(services.filter((service) => service.name !== item.name));
  };

  const onUpdateProfile = (): void => {
    if (
      name.length === 0
      || email.length === 0
      || !type
      || !specialists
      || !specialists.length
      || !locations
      || !locations.length
    ) {
      return setShowAlert({
        message: 'Please add all mandatory fields',
        status: true
      });
    }
    setLoading(true);
    if (file) {
      uploadProfileImage(user.id, file);
    }
    updateClinicProfile(user.id, {
      profile: {
        name,
        title,
        about,
        type,
        email,
        smsEnabled,
        emailEnabled,
        notificationEnabled
      },
      languages,
      specialists,
      locations,
      services: services.map((service) => service.name)
    })
      .then((res) => {
        if (res.success) {
          updateUser(res.user);
          setShowAlert({
            status: true,
            message: 'Profile updated successfully.'
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const onDownloadTermsAndConditions = async () => {
    const { url } = await getSignedUrl(user.signature);
    window.open(url, '_blank');
  };

  const isSubmitDisabled = useMemo(() => {
    if (
      !name
      || !email
      || !type
      || !locations
      || !locations.length
      || !specialists
      || !specialists.length
    ) {
      return true;
    }
    return false;
  }, [name, email, type, specialists, locations]);

  return (
    <IonContent id="clinic-admin-profile" className="page-content ion-padding">
      <IonListHeader className="page-header">Account Settings</IonListHeader>
      <IonList>
        <IonItem lines="none" className="avatar">
          <IonAvatar>
            <img src={avatar} alt="selfie" />
            <IonButton color="light" onClick={() => inputRef.current?.click()}>
              <IonIcon icon={brushOutline} color="dark" />
            </IonButton>
          </IonAvatar>
          <input
            ref={inputRef}
            type="file"
            id="myfile"
            name="myfile"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              if (e.target.files) {
                setAvatar(URL.createObjectURL(e.target.files[0]));
                setFile(e.target.files[0] as any);
              }
            }}
          />
          <IonLabel>
            <h2>{title}</h2>
          </IonLabel>
        </IonItem>
        <IonItem lines="none" className="input">
          <UserIcon />
          <IonInput
            name="name"
            type="text"
            placeholder="* Name "
            color={name?.length ? '' : 'danger'}
            required
            value={name}
            onIonChange={(e): void => {
              setName(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="input">
          <StethoscopeIcon />
          <IonInput
            name="title"
            type="text"
            placeholder="Title"
            value={title}
            onIonChange={(e): void => {
              setTitle(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="input">
          <UserIcon />
          <IonInput
            name="email"
            type="email"
            placeholder="* Email "
            color={email?.length ? '' : 'danger'}
            required
            value={email}
            onIonChange={(e): void => {
              setEmail(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="input">
          <UserIcon />
          <IonTextarea
            name="about"
            value={about}
            rows={2}
            placeholder="About you..."
            onIonChange={(e): void => {
              setAbout(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem lines="none" className="select">
          <UserIcon />
          <IonSelect
            className={clsx('select', { empty: !type })}
            placeholder="* Select provider type..."
            value={type}
            onIonChange={(e): void => setType(parseInt(e.detail.value, 10))}
          >
            <IonSelectOption value={1}>Dietitian</IonSelectOption>
            <IonSelectOption value={2}>Massage Therapist</IonSelectOption>
            <IonSelectOption value={3}>Physician</IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem lines="none" className="select">
          <ListIcon />
          <IonSelect
            className={clsx('select', { empty: !type })}
            placeholder="* Select your specialty..."
            multiple
            value={specialists}
            disabled={!type}
            onIonChange={(e): void => setSpecialists(e.detail.value)}
          >
            {allSpecialists
              .filter((specialist) => specialist.type === type)
              .map((specialist) => (
                <IonSelectOption key={specialist.id} value={specialist.id}>
                  {specialist.name}
                </IonSelectOption>
              ))}
          </IonSelect>
        </IonItem>
        <IonItem lines="none" className="select">
          <SphereIcon />
          <IonSelect
            className="select"
            placeholder="Select languages..."
            multiple
            value={languages}
            disabled={!type}
            onIonChange={(e): void => setLanguages(e.detail.value)}
          >
            {allLanguages.map((language) => (
              <IonSelectOption key={language.id} value={language.id}>
                {language.name}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        {!locations || !locations.length ? (
          <IonText color="danger">
            <p>* You should add locations</p>
          </IonText>
        ) : null}
        <IonItem lines="none" className="input">
          <LocationIcon />
          <PlaceSearchInput
            isRequired={!locations.length}
            value={location?.address}
            onChange={(value): void => setlocation(value)}
          />
          <IonButton
            slot="end"
            color="favorite"
            expand="block"
            disabled={!location?.address}
            onClick={(): void => onAddLocation()}
          >
            Add
          </IonButton>
        </IonItem>
        <IonItem lines="none" className="select">
          <LocationIcon />
          <IonSelect
            className="select"
            interface="action-sheet"
            value={selectedLocation}
            placeholder="Select location..."
            disabled={!locations.length}
            onIonChange={(e): void => setSelectedLocation(e.detail.value)}
          >
            {locations.map((item) => (
              <IonSelectOption key={item.id} value={item}>
                {item.address}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        <IonItem lines="none">
          <IonButton slot="end" color="favorite" fill="clear" onClick={() => setShowLocationsModal(true)}>Manage locations</IonButton>
        </IonItem>
        <IonItem lines="none" className="map">
          <div className="map-container">
            <Map
              locations={locations}
              mapCenter={{ lat: location?.lat, lng: location?.lng }}
            />
          </div>
        </IonItem>
        <IonItem lines="none" className="input">
          <IonGrid>
            <IonRow>
              <IonCol>
                <br />
                <IonLabel>SMS, Email & Notification settings</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4">
                <IonItem lines="none">
                  <IonLabel>SMS</IonLabel>
                  <IonToggle
                    color="favorite"
                    checked={smsEnabled}
                    onIonChange={(event): void => setSmsEnabled(event.detail.checked)}
                  />
                </IonItem>
              </IonCol>
              <IonCol size="4">
                <IonItem lines="none">
                  <IonLabel>Email</IonLabel>
                  <IonToggle
                    color="favorite"
                    checked={emailEnabled}
                    onIonChange={(event): void => setEmailEnabled(event.detail.checked)}
                  />
                </IonItem>
              </IonCol>
              <IonCol size="4">
                <IonItem lines="none">
                  <IonLabel>Notification</IonLabel>
                  <IonToggle
                    color="favorite"
                    checked={notificationEnabled}
                    onIonChange={(event): void => setNotificationEnabled(event.detail.checked)}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonList>
      {/* <IonButton color="favorite" onClick={(): void => setShowModal(true)}>
        Change password
      </IonButton> */}
      <IonButton color="favorite" onClick={(): void => onUpdateProfile()}>
        Update
      </IonButton>
      <IonButton color="favorite" fill="outline" disabled={!user.signature} onClick={onDownloadTermsAndConditions}>
        Download T&C
      </IonButton>

      <IonAlert
        isOpen={showAlert.status}
        onDidDismiss={(): void => {
          setShowAlert((prev) => ({ ...prev, status: false }));
        }}
        header="Care Platform"
        message={showAlert.message}
        buttons={['Ok']}
      />

      <IonModal
        cssClass="locations-modal"
        isOpen={showLocationsModal}
        onDidDismiss={() => setShowLocationsModal(false)}
      >
        <IonLabel>Manage Locations</IonLabel>
        <div className="locations">
          {locations.map((loc) => (
            <div className="location-item" onClick={() => onRemoveLocation(loc.id, loc.address ?? '', false)}>
              {loc.address}
              <IonIcon className="cursor-pointer" icon={closeOutline} />
            </div>
          ))}
        </div>
      </IonModal>

      <IonLoading isOpen={loading || isDeleting} />
    </IonContent>
  );
};

ClinicAdminProfile.propTypes = {
  user: PropTypes.any.isRequired,
  allLanguages: PropTypes.array.isRequired,
  allSpecialists: PropTypes.array.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    allLanguages: state.data.allLanguages,
    allSpecialists: state.data.allSpecialists,
    user: state.auth.user
  }),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(ClinicAdminProfile)
});
