import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  IonContent,
  IonListHeader,
  IonItem,
  IonLabel,
  IonImg,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';

import connect from '../../data/connect';
import { getImage } from '../../data/dataApi';
import { ProviderType, Specialist } from '../../models';

interface StateProps {
  providerTypes: ProviderType[],
  specialists: Specialist[]
}

type ProviderTypeViewProps = RouteComponentProps & StateProps;

const ProviderTypeView: React.FC<ProviderTypeViewProps> = ({
  history, providerTypes, specialists
}) => {
  const { providerTypeId } = useParams();
  const [providerTypeName, setProviderTypeName] = useState('');
  const [specialistItems, setSpecialistItems] = useState<Specialist[][]>([]);

  useEffect(() => {
    const providerType = providerTypes.find((item) => item.id === Number(providerTypeId));
    if (providerType) {
      setProviderTypeName(providerType.name);

      const results: Specialist[][] = [];
      let row: Specialist[] = [];
      // eslint-disable-next-line array-callback-return
      const activeSpecialists = specialists.filter((item: Specialist) => item.type === Number(providerTypeId));
      activeSpecialists.map((item, index) => {
        row.push(item);
        if (index % 3 === 2 || index + 1 === activeSpecialists.length) {
          results.push(row);
          row = [];
        }
      });
      setSpecialistItems(results);
    }
  }, [providerTypeId, specialists, providerTypes, setProviderTypeName, setSpecialistItems]);

  return (
    <IonContent className="page-content ion-padding">
      <IonListHeader>
        {providerTypeName}
      </IonListHeader>

      <IonGrid fixed>
        {specialistItems.map((items) => (
          <IonRow>
            {items.map((item) => (
              <IonCol size="4" key={item.id}>
                <IonItem
                  lines="none"
                  className="list-item round-border main-list-item"
                  onClick={() => history.push(`/customer/dashboard/category/${providerTypeId}/specialist/${item.id}`)}
                >
                  <IonImg slot="start" src={getImage(item.image)} />
                  <IonLabel>{item.name}</IonLabel>
                </IonItem>
              </IonCol>
            ))}
          </IonRow>
        ))}
      </IonGrid>
    </IonContent>
  );
};

ProviderTypeView.propTypes = {
  history: PropTypes.any.isRequired,
  providerTypes: PropTypes.array.isRequired,
  specialists: PropTypes.array.isRequired
};

export default connect<RouteComponentProps, StateProps, null>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes,
    specialists: state.data.allSpecialists,
  }),
  component: React.memo(ProviderTypeView)
});
