import { combineReducers } from './combineReducers';
import authReducer from './store/auth/auth.reducer';
import dataReducer from './store/data/data.reducer';

import initialAuthState from './store/auth/auth.state';
import initialDataState from './store/data/data.state';

export const initialState: AppState = {
  auth: initialAuthState,
  data: initialDataState
};

export const reducers = combineReducers({
  auth: authReducer,
  data: dataReducer
});

export type AppState = ReturnType<typeof reducers>;
