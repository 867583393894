import React, {
  useContext, useMemo, useEffect, useState
} from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import {
  IonAlert,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonTextarea
} from '@ionic/react';
import moment from 'moment';

import connect from '../../../data/connect';
import {
  fetchBooking,
  updateBooking,
  checkTimeSlots,
  extendBooking,
  joinCall,
  socket
} from '../../../data/dataApi';
import ParticipantStrip from '../ParticipantStrip/ParticipantStrip';
import MainParticipant from '../MainParticipant/MainParticipant';
import useParticipants from '../../../hooks/useParticipants/useParticipants';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { StatusContext } from '../../../data/StatusContext';
import {
  AppointmentType, Booking, BookingStatus, ServiceItem, User, UserRole
} from '../../../models';
import { SOCKET_KEYS } from '../../../data/constants';

import './Room.scss';

interface RoomProps {
  booking: Booking
  currentDate: Date
  setBooking: (booking: Booking) => void
}

interface StateProps {
  user: User
}

const Room: React.FC<RouteComponentProps & RoomProps & StateProps> = ({
  history, user, booking, currentDate, setBooking
}) => {
  const { room } = useVideoContext();
  const { closeConversation } = useChatContext();
  const { updateStatus } = useContext(StatusContext);
  const participants = useParticipants();
  const [isTimeout, setIsTimeout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(5);
  const [showEndAlert, setShowEndAlert] = useState(false);
  const [availableServiceItems, setAvailableServiceItems] = useState<ServiceItem[]>([]);
  const [isChecking, setIsChecking] = useState(false);
  const [showExtendModal, setShowExtendModal] = useState(false);
  const [showNextSlotNoEmptyAlert, setShowNextSlotNoEmptyAlert] = useState(false);
  const [selectedServiceItem, setSelectedServiceItem] = useState<ServiceItem | null>(null);
  const [isMeaningfulCall, setIsMeaningfulCall] = useState(false);

  // extend request
  const [showExtendCallAlert, setShowExtendCallAlert] = useState(false);
  const [showExtendRejectedAlert, setShowExtendRejectedAlert] = useState(false);
  const [showExtendApprovedAlert, setShowExtendApprovedAlert] = useState(false);

  const [showReasonsModal, setShowReasonsModal] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const [otherReason, setOtherReason] = useState('');

  const onCancel = async (onlyEndCall: boolean, isRebook: boolean) => {
    try {
      setIsLoading(true);
      if (!onlyEndCall) {
        const noAvailableReason = user.role === UserRole.CUSTOMER ? 'Provider no available at that time.' : 'Customer no available at that time.';
        const reason = selectedReason === 'other' ? otherReason : noAvailableReason;
        if (user.role === UserRole.CUSTOMER) {
          await updateBooking(Number(room.name), BookingStatus.CANCELLED_CUSTOMER, reason);
          socket.emit(SOCKET_KEYS[BookingStatus.CANCELLED_CUSTOMER], Number(room.name), reason);
        } else {
          await updateBooking(Number(room.name), BookingStatus.CANCELLED_PROVIDER, reason);
          socket.emit(SOCKET_KEYS[BookingStatus.CANCELLED_PROVIDER], Number(room.name), reason);
        }
      }
      room?.localParticipant.audioTracks.forEach((publication) => {
        publication.track.stop();
      });
      room?.localParticipant.videoTracks.forEach((publication) => {
        publication.unpublish();
        publication.track.stop();
      });
      closeConversation(false);
      room.disconnect();
      await updateStatus(user, 'online');
      if (isRebook) {
        history.push(`/customer/provider/${booking.provider.id}/schedule`);
      } else {
        history.goBack();
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const onCheckTimeslots = async () => {
    try {
      setIsChecking(true);
      const {
        providerId, locationId, date: bookingDate, time, duration, service
      } = booking;
      const bookingDateTime = moment.utc(`${bookingDate} ${time}`).add(duration, 'minutes').local();
      const date = moment().format('YYYY-MM-DD');
      const { slots, service: { items } } = await checkTimeSlots(
        providerId,
        locationId,
        service,
        moment(date).utc().format('YYYY-MM-DD HH:mm'),
        'video-visit'
      );
      const nextSlot = bookingDateTime.hour() * 4 + Math.floor(bookingDateTime.minute() / 15);

      // check available slots
      let cnt = 0;
      while ((slots as number[]).includes(nextSlot + cnt)) {
        cnt += 1;
      }
      const serviceItems: ServiceItem[] = [];
      (items as ServiceItem[]).map((item) => {
        if (item.duration <= cnt * 15 && item.type === AppointmentType.VIRTUAL) {
          serviceItems.push(item);
        }
      });
      setAvailableServiceItems([...serviceItems]);
      if (serviceItems.length > 0) {
        setShowExtendModal(true);
      } else {
        setShowNextSlotNoEmptyAlert(true);
      }
      setShowEndAlert(false);
    } catch (err) {
      console.log(err);
    }
    setIsChecking(false);
  };

  const onRequestExtend = () => {
    socket.emit(SOCKET_KEYS['request-extend'], booking.id, selectedServiceItem);
    setShowExtendModal(false);
  };

  const onApproveExtend = () => {
    if (booking && selectedServiceItem) {
      extendBooking({
        bookingId: booking.id,
        duration: selectedServiceItem.duration,
        price: selectedServiceItem.price
      })
        .then((res) => {
          if (res.success) {
            setBooking(res.booking as Booking);
            socket.emit(SOCKET_KEYS['approved-extend'], booking.id);
            setSelectedServiceItem(null);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const onRejectExtend = () => {
    socket.emit(SOCKET_KEYS['rejected-extend'], booking.id);
    setShowExtendCallAlert(false);
  };

  const onJoinCall = () => {
    joinCall(Number(room.name))
      .then((res) => {
        if (res.success) {
          setBooking(res.booking as Booking);
        }
      })
      .catch((err) => console.log(err));
  };

  const cancelButtonDisabled = useMemo(() => {
    if (!selectedReason || (selectedReason === 'other' && !otherReason)) {
      return true;
    }
    return false;
  }, [selectedReason, otherReason]);

  useEffect(() => {
    socket.on('call-message', (e: any) => {
      if (e.type === 'request') {
        setShowEndAlert(false);
        setShowExtendCallAlert(true);
        setSelectedServiceItem(e.serviceItem as ServiceItem);
      }
      if (e.type === 'rejected') {
        setShowExtendRejectedAlert(true);
      }
      if (e.type === 'approved') {
        setShowExtendApprovedAlert(true);
        setSelectedServiceItem(null);
        if (booking) {
          fetchBooking(booking.id)
            .then((res) => {
              if (res.success) {
                setBooking(res.booking);
              }
            })
            .catch((err) => console.log(err));
        }
      }
    });

    const timer = setInterval(() => {
      const datetime = new Date();
      setCount((prevCount) => {
        if (
          prevCount > 0
          && booking
          && moment.utc(`${booking.date} ${booking.time}`).diff(moment(datetime).utc(), 'minutes') < 0
        ) {
          return prevCount - 1;
        }
        return prevCount;
      });
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (participants.length === 0 && count <= 0) {
      setIsTimeout(true);
    }
    if (participants.length) {
      setIsMeaningfulCall(true);
    }
  }, [participants, count]);

  useEffect(() => {
    if (booking) {
      if (moment.utc(`${booking.date} ${booking.time}`).add(booking.duration, 'minutes').diff(moment(currentDate).utc(), 'minutes') === 5) {
        setShowEndAlert(true);
      }
      if (moment.utc(`${booking.date} ${booking.time}`).add(booking.duration, 'minutes').diff(moment(currentDate).utc(), 'minutes') <= 0 && (user.role === UserRole.PROVIDER)) {
        setShowExtendCallAlert(false);
      }
      if (
        moment.utc(`${booking.date} ${booking.time}`).diff(moment(currentDate).utc(), 'minutes') <= 0
        && ((user.role === UserRole.CUSTOMER && !booking.customerConnected) || (user.role !== UserRole.CUSTOMER && !booking.providerConnected))
      ) {
        onJoinCall();
      }
    }
  }, [currentDate, booking]);

  useEffect(() => {
    if (!participants.length) {
      if (user.role === UserRole.CUSTOMER) {
        socket.emit(SOCKET_KEYS['meeting-customer'], Number(room.name));
      } else {
        socket.emit(SOCKET_KEYS['meeting-provider'], Number(room.name));
      }
    }
  }, []);

  return (
    <div className="room-container">
      <div className="main-participant-container">
        <MainParticipant />
      </div>
      <ParticipantStrip />
      <IonAlert
        cssClass="cancel-alert"
        isOpen={isTimeout && !isMeaningfulCall}
        onDidDismiss={(): void => setIsTimeout(false)}
        header="CarePlatform"
        message={user.role === UserRole.CUSTOMER ? 'You can cancel appointment and book again.' : 'You can cancel appointment.'}
        buttons={user.role === UserRole.CUSTOMER ? [
          {
            text: 'Rebook',
            handler: () => onCancel(false, true)
          },
          {
            text: 'Cancel appointment',
            handler: () => setShowReasonsModal(true)
          }
        ] : [
          {
            text: 'Cancel appointment',
            handler: () => setShowReasonsModal(true)
          }
        ]}
      />

      <IonAlert
        isOpen={showEndAlert}
        onDidDismiss={(): void => setShowEndAlert(false)}
        header="CarePlatform"
        message="Your call will be finished in 5 minutes."
        buttons={user.role === UserRole.CUSTOMER && isMeaningfulCall ? [
          'OK',
          {
            text: 'Extend Call',
            handler: () => onCheckTimeslots()
          }
        ] : ['OK']}
      />

      <IonAlert
        isOpen={showNextSlotNoEmptyAlert}
        onDidDismiss={(): void => setShowNextSlotNoEmptyAlert(false)}
        header="CarePlatform"
        message="Next slot is not available. Please schedule another meeting."
        buttons={['OK']}
      />

      {selectedServiceItem ? (
        <IonAlert
          isOpen={showExtendCallAlert && !!selectedServiceItem}
          header="CarePlatform"
          backdropDismiss={false}
          message={`Customer is requesting to extend call for ${Math.floor(selectedServiceItem.duration / 60) > 0 ? `${Math.floor(selectedServiceItem.duration / 60)}hour` : ''}${selectedServiceItem.duration % 60 ? ` ${selectedServiceItem.duration % 60}mins.` : '.'}`}
          buttons={[
            {
              text: 'Approve',
              handler: () => onApproveExtend()
            },
            {
              text: 'Reject',
              handler: () => onRejectExtend()
            }
          ]}
        />
      ) : null}

      <IonAlert
        isOpen={showExtendRejectedAlert}
        onDidDismiss={(): void => setShowExtendRejectedAlert(false)}
        header="CarePlatform"
        message="Your extend call request has been rejected."
        buttons={['OK']}
      />

      <IonAlert
        isOpen={showExtendApprovedAlert}
        onDidDismiss={(): void => setShowExtendApprovedAlert(false)}
        header="CarePlatform"
        message="Your extend call request has been approved."
        buttons={['OK']}
      />

      <IonModal
        cssClass="extend-call-modal"
        isOpen={showExtendModal}
        onDidDismiss={() => setShowExtendModal(false)}
      >
        <IonLabel>Please choose option</IonLabel>
        <div className="options">
          <IonRadioGroup value={selectedServiceItem} onIonChange={(e) => setSelectedServiceItem(e.detail.value)}>
            {availableServiceItems.map((item) => (
              <IonItem key={item.id}>
                <IonLabel>
                  {`${Math.floor(item.duration / 60) > 0 ? `${Math.floor(item.duration / 60)}h` : ''}${item.duration % 60 ? ` ${item.duration % 60}m` : ''}`}
                </IonLabel>
                <IonLabel>
                  {`-  $${item.price}`}
                </IonLabel>
                <IonRadio slot="end" value={item} />
              </IonItem>
            ))}
          </IonRadioGroup>
        </div>
        <div className="actions">
          <IonButton color="favorite" disabled={!selectedServiceItem} onClick={onRequestExtend}>
            Confirm
          </IonButton>
          <IonButton color="danger" onClick={() => setShowExtendModal(false)}>
            Cancel
          </IonButton>
        </div>
      </IonModal>

      {/* modal to set reason */}
      <IonModal
        cssClass="reasons-modal"
        isOpen={showReasonsModal}
        onDidDismiss={() => {
          setOtherReason('');
          setSelectedReason('');
          setShowReasonsModal(false);
        }}
      >
        <IonLabel>Select Reason</IonLabel>
        <div id="modal">
          <IonList>
            <IonRadioGroup
              value={selectedReason}
              onIonChange={(e) => setSelectedReason(e.detail.value)}
            >
              <IonItem lines="none" className="input">
                <IonLabel>No available at that time</IonLabel>
                <IonRadio slot="end" value="no_available" />
              </IonItem>
              <IonItem lines="none" className="input">
                <IonLabel>Other</IonLabel>
                <IonRadio slot="end" value="other" />
              </IonItem>
            </IonRadioGroup>
            {selectedReason === 'other' ? (
              <IonTextarea
                name="personalReason"
                value={otherReason}
                rows={3}
                placeholder="Please input reason here..."
                onIonChange={(e) => setOtherReason(e.detail.value as string)}
              />
            ) : null}
            <IonItem lines="none">
              <IonButton
                color="favorite"
                disabled={cancelButtonDisabled}
                onClick={() => onCancel(false, false)}
              >
                OK
              </IonButton>
            </IonItem>
          </IonList>
        </div>
      </IonModal>

      <IonLoading isOpen={isLoading} />
    </div>
  );
};

Room.propTypes = {
  history: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  booking: PropTypes.any.isRequired,
  currentDate: PropTypes.any.isRequired,
  setBooking: PropTypes.func.isRequired
};

export default connect<RoomProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  component: withRouter(Room)
});
