/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactNode, useContext, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  IonToolbar,
  IonPage,
  IonButtons,
  IonTitle,
  IonHeader,
  IonBackButton,
  IonLabel,
  IonAlert,
} from '@ionic/react';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import clsx from 'clsx';

import Avatar from '../Avatar';
import connect from '../../data/connect';
import { startMeeting, socket } from '../../data/dataApi';
import {
  ProviderTypeLabels, Message, User, UserRole, Booking, AppointmentType
} from '../../models';
import { StatusContext } from '../../data/StatusContext';
import { dispatchGetAllNotification } from '../../data/store/data/data.actions';

import './Layout.scss';

interface StateProps {
  user: User,
  notifications: Message[]
}
interface Props extends RouteComponentProps {
  children: ReactNode
}
interface DispatchProps {
  getAllNotification: typeof dispatchGetAllNotification
}

type LayoutProps = StateProps & DispatchProps & Props;

const Layout: React.FC<LayoutProps> = ({
  user,
  history,
  location,
  notifications,
  getAllNotification,
  children
}) => {
  const { updateStatus } = useContext(StatusContext);
  const [pageTitle, setPageTitle] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [callAlert, setCallAlert] = useState<Booking | null>(null);
  const [meetingStartConfirmedAlert, setMeetingStartConfirmedAlert] = useState<Booking | null>(null);

  const changeStatus = (status: string) => {
    updateStatus(user, status);
    setIsPopoverOpen(false);
  };

  const onStartMeeting = (id: number | undefined) => {
    if (id) {
      startMeeting(id, true)
        .then((res) => {
          //
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const pathname = location.pathname.split('/').slice(-1)[0];
    if (location.pathname.includes('/member') && (
      location.pathname.includes('timeslot')
      || location.pathname.includes('business-hour')
      || location.pathname.includes('profile')
    )) {
      setPageTitle('Clinic member');
    } else if (location.pathname.includes('dashboard/category')) {
      if (location.pathname.includes('specialist')) {
        setPageTitle('Providers');
      } else {
        setPageTitle('Categories');
      }
    } else if (location.pathname.includes('/report/user') || location.pathname.includes('/report/booking')) {
      setPageTitle('Reports');
    } else {
      switch (pathname) {
        case 'dashboard':
          setPageTitle('Dashboard');
          break;
        case 'availability':
          setPageTitle('Availability');
          break;
        case 'wallet':
          setPageTitle('Wallet');
          break;
        case 'new-payment-source':
          if (location.pathname.includes('customer')) {
            setPageTitle('Credit Card Information');
          } else {
            setPageTitle('Bank Information');
          }
          break;
        case 'favorites':
          setPageTitle('Favorites');
          break;
        case 'bookings':
          if (user.role === 'customer') {
            setPageTitle('My Bookings');
          } else {
            setPageTitle('Appointments');
          }
          break;
        case 'payment':
          setPageTitle('Payments');
          break;
        case 'profile':
          setPageTitle('Account Settings');
          break;
        case 'schedule':
          setPageTitle('Appointment');
          break;
        case 'notification':
          setPageTitle('Notifications');
          break;
        case 'reviews':
          setPageTitle('Reviews');
          break;
        case 'business-hour':
          setPageTitle('Business Hours');
          break;
        case 'users':
          setPageTitle('Users');
          break;
        case 'providers':
          setPageTitle('Providers Directory');
          break;
        case 'clinics':
          setPageTitle('Clinics Directory');
          break;
        case 'members':
          setPageTitle('Clinic members');
          break;
        case 'calendar':
          setPageTitle('Calendar View');
          break;
        case 'add-member':
          setPageTitle('Add member');
          break;
        case 'onboarding':
          if (user.role === UserRole.PROVIDER) {
            setPageTitle('Provider Onboarding');
          }
          break;
        case 'report':
          setPageTitle('Reports');
          break;
        case 'earnings':
          setPageTitle('Earnings');
          break;
        case 'marketing':
          setPageTitle('Marketing');
          break;
        case 'clinic-members':
          setPageTitle('Earnings');
          break;
        case 'subscribe':
          setPageTitle('Subscription');
          break;
        case 'taxes':
          setPageTitle('Taxes');
          break;
        case 'contact-us':
          setPageTitle('Contact Us');
          break;
        default:
          if (location.pathname.includes('providers')) {
            setPageTitle(`Registered ${
              ProviderTypeLabels[parseInt(pathname, 10)]
            }`);
          } else if (location.pathname.includes('member')) {
            setPageTitle('Clinic member');
          } else if (location.pathname.includes('customer/rate-booking')) {
            setPageTitle('Provider Rating');
          } else if (location.pathname.includes('provider/rate-booking')) {
            setPageTitle('Customer Rating');
          } else if (location.pathname.includes('admin/clinic')) {
            setPageTitle('Memebers in Clinic');
          } else {
            setPageTitle('Provider Profile');
          }
          break;
      }
    }
  }, [location]);

  const isSubscribed = useMemo(() => {
    if (user.subscription && ['active', 'paused'].includes(user.subscription.status)) {
      return true;
    }
    return false;
  }, [user]);

  const showBackButton = useMemo(() => {
    if (
      pageTitle === 'Appointment'
      || pageTitle === 'Provider Profile'
      || pageTitle === 'Bank Information'
      || pageTitle === 'Credit Card Information'
      || pageTitle === 'Add member'
      || pageTitle === 'Categories'
      || pageTitle === 'Providers'
      || pageTitle === 'Reviews'
      || pageTitle === 'Provider Rating'
      || pageTitle === 'Customer Rating'
      || pageTitle === 'Memebers in Clinic'
      || (pageTitle === 'Clinic member' && (
        location.pathname.includes('timeslot')
        || location.pathname.includes('business-hour')
        || location.pathname.includes('profile')
        || location.pathname.includes('clinic-admin')
      ))
      || (pageTitle === 'Wallet' && !location.pathname.includes('/customer/'))
      || (pageTitle === 'Reports' && (location.pathname.includes('/report/user') || location.pathname.includes('/report/booking')))
      || (pageTitle === 'Earnings' && location.pathname.includes('/admin/clinic-members'))
    ) {
      return true;
    }
    return false;
  }, [pageTitle, location.pathname]);

  useEffect(() => {
    socket.on('new-message', (e: { type: string, booking: Booking }) => {
      if (e?.type?.includes('call-customer') || e?.type?.includes('call-provider')) {
        setCallAlert(e?.booking);
      }
    });

    socket.on('start-meeting', (e: Booking) => {
      setMeetingStartConfirmedAlert(e);
    });

    getAllNotification();
  }, []);

  const isVideoRoom = useMemo(() => location.pathname.includes('/video/'), [location.pathname]);

  const callAlertMessage = useMemo(() => {
    const message = callAlert && callAlert.type !== AppointmentType.VIRTUAL ? 'has arrived for meeting.' : 'is waiting for you in Meeting.';
    if (callAlert && user.role === UserRole.CUSTOMER) {
      return `${callAlert.provider.name} ${callAlert.provider.lastName ?? ''} ${message}`;
    }
    if (callAlert && user.role === UserRole.PROVIDER) {
      return `${callAlert.customer.name} ${callAlert.customer.lastName ?? ''} ${message}`;
    }
    return '';
  }, [callAlert]);

  const startConfirmedMessage = useMemo(() => {
    if (meetingStartConfirmedAlert && user.role === UserRole.CUSTOMER) {
      return `Meeting with ${meetingStartConfirmedAlert?.provider.name} ${meetingStartConfirmedAlert.provider.lastName ?? ''} has been started.`;
    }
    if (meetingStartConfirmedAlert && user.role === UserRole.PROVIDER) {
      return `Meeting with ${meetingStartConfirmedAlert?.customer.name} ${meetingStartConfirmedAlert.customer.lastName ?? ''} has been started.`;
    }
    return '';
  }, [meetingStartConfirmedAlert]);

  if (isVideoRoom) {
    return null;
  }

  return (
    <IonPage id="customer-dashboard">
      <IonHeader>
        <IonToolbar className="top-header">
          {showBackButton ? (
            <IonButtons slot="start">
              <IonBackButton color="favorite" />
            </IonButtons>
          ) : null}
          <IonTitle>{pageTitle}</IonTitle>
          <Popover
            isOpen={isPopoverOpen}
            positions={['bottom', 'top', 'left', 'right']}
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor="#8dbfb6"
                arrowSize={10}
                className="popover-arrow-container"
                arrowClassName="popover-arrow"
              >
                <div className="popover-content">
                  <div className="option" onClick={() => changeStatus('online')}>
                    <IonLabel>
                      <span className="status-available" />
                      Available
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => changeStatus('busy')}>
                    <IonLabel>
                      <span className="status-busy" />
                      Busy
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => changeStatus('away')}>
                    <IonLabel>
                      <span className="status-away" />
                      Away
                    </IonLabel>
                  </div>
                </div>
              </ArrowContainer>
            )}
            onClickOutside={() => setIsPopoverOpen(false)}
          >
            <div className="user-info">
              {['provider', 'clinic-admin'].includes(user.role) ? (
                <div
                  className={clsx('subscribe-status', { subscribed: isSubscribed })}
                  onClick={() => history.push(`/${user.role}/subscribe`)}
                >
                  {isSubscribed ? 'Subscribed' : 'Upgrade'}
                </div>
              ) : null}
              <div className="user-details" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                <Avatar user={user} />
                <div className="info">
                  <IonLabel className="user-name">{user.role === 'clinic-admin' ? user.name : `${user?.name} ${user?.lastName || ''}`}</IonLabel>
                  <IonLabel className="user-role">{user.role}</IonLabel>
                </div>
              </div>
            </div>
          </Popover>
        </IonToolbar>
      </IonHeader>
      {children}

      <IonAlert
        isOpen={!!callAlert}
        onDidDismiss={() => setCallAlert(null)}
        header="CarePlatform"
        message={callAlertMessage}
        buttons={callAlert?.type === 'video-visit' ? [
          {
            text: 'Join Call',
            handler: () => history.push(`/video/${callAlert?.id}`)
          },
          {
            text: 'Cancel',
            handler: () => setCallAlert(null)
          }
        ] : [
          {
            text: 'Start',
            handler: () => onStartMeeting(callAlert?.id)
          },
          {
            text: 'Cancel',
            handler: () => setCallAlert(null)
          }
        ]}
      />

      <IonAlert
        isOpen={!!meetingStartConfirmedAlert}
        onDidDismiss={() => setMeetingStartConfirmedAlert(null)}
        header="CarePlatform"
        message={startConfirmedMessage}
        buttons={['OK']}
      />
    </IonPage>
  );
};

Layout.propTypes = {
  history: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  notifications: PropTypes.array.isRequired,
  getAllNotification: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user,
    notifications: state.data.notifications
  }),
  mapDispatchToProps: {
    getAllNotification: dispatchGetAllNotification
  },
  component: withRouter(Layout)
});
