import React, {
  useState, useMemo, useRef, useEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  IonContent,
  IonList,
  IonItem,
  IonButton,
  IonInput,
  IonTextarea,
  IonAlert,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
  IonLabel,
  IonListHeader,
  IonIcon,
  IonToggle
} from '@ionic/react';
import { brushOutline } from 'ionicons/icons';

import {
  UserIcon, LocationIcon, PhoneIcon, EmailIcon
} from '../../icons';
import connect from '../../data/connect';
import { Location, User } from '../../models';
import {
  updateCustomerProfile,
  uploadProfileImage,
  getImage,
  getSignedUrl
} from '../../data/dataApi';
import { dispatchUpdateUser } from '../../data/store/auth/auth.actions';
import Map from '../../components/Map';
import PlaceSearchInput from '../../components/PlaceSearchInput';
import IdentityModal from '../../components/Modal/IdentityModal';
import ChangePasswordModal from '../../components/Modal/ChangePasswordModal';

import './CustomerProfile.scss';

interface StateProps {
  user: User
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

type CustomerProfileProps = StateProps & DispatchProps;

const CustomerProfile: React.FC<CustomerProfileProps> = ({
  user,
  updateUser
}) => {
  const [avatar, setAvatar] = useState(getImage(user.image));
  const [file, setFile] = useState();
  const [name, setName] = useState(user.name ?? '');
  const [lastName, setLastName] = useState(user.lastName ?? '');
  const [email, setEmail] = useState(user.email ?? '');
  const [about, setAbout] = useState(user.about);
  const [location, setlocation] = useState<Location | null>(null);
  const [smsEnabled, setSmsEnabled] = useState(user.smsEnabled);
  const [emailEnabled, setEmailEnabled] = useState(user.emailEnabled);
  const [notificationEnabled, setNotificationEnabled] = useState(user.notificationEnabled);

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: '', status: false });

  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  // state for identity
  const [showIdentityModal, setShowIdentityModal] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const onUpdateProfile = (): void => {
    if (
      name.length === 0
      || lastName.length === 0
      || email.length === 0
      || !location
      || !location.address
    ) {
      setShowAlert({
        message: 'Please add all mandatory fields',
        status: true
      });
      return;
    }

    setLoading(true);
    if (file) {
      uploadProfileImage(user.id, file);
    }
    updateCustomerProfile(user.id, {
      profile: {
        name,
        lastName,
        about,
        email,
        smsEnabled,
        emailEnabled,
        notificationEnabled
      },
      location
    })
      .then((res: { success: boolean, user: User }) => {
        if (res.success) {
          updateUser(res.user);
          setShowAlert({
            message: 'Profile updated successfully.',
            status: true
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const onDownloadTermsAndConditions = async () => {
    const { url } = await getSignedUrl(user.signature);
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (user.locations.length) {
      setlocation(user.locations[0]);
    }
  }, [user]);

  return (
    <IonContent id="customer-profile" className="page-content ion-padding">
      <IonListHeader>Account Settings</IonListHeader>
      <IonList>
        <IonItem lines="none" className="avatar">
          <IonAvatar slot="start" id="customer-profile-avatar">
            <img src={avatar} alt="selfie" />
            <IonButton color="light" onClick={() => inputRef.current?.click()}>
              <IonIcon icon={brushOutline} color="dark" />
            </IonButton>
          </IonAvatar>
          <input
            ref={inputRef}
            type="file"
            id="myfile"
            name="myfile"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={(e) => {
              if (e.target.files) {
                setAvatar(URL.createObjectURL(e.target.files[0]));
                setFile(e.target.files[0] as any);
              }
            }}
          />
          <IonLabel>
            <h2>{`${user?.name} ${user?.lastName ?? ''}`}</h2>
            <p>{user.role}</p>
          </IonLabel>
        </IonItem>
        <IonItem id="customer-profile-first-name" lines="none" className="input">
          <UserIcon />
          <IonInput
            name="name"
            type="text"
            placeholder="* First Name"
            color={name?.length ? '' : 'danger'}
            required
            value={name}
            onIonChange={(e): void => {
              setName(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem id="customer-profile-first-name" lines="none" className="input">
          <UserIcon />
          <IonInput
            name="lastName"
            type="text"
            placeholder="* Last Name"
            color={lastName?.length ? '' : 'danger'}
            required
            value={lastName}
            onIonChange={(e): void => {
              setLastName(e.detail.value || '');
            }}
          />
        </IonItem>

        <IonItem id="customer-profile-phone" lines="none" className="input disable">
          <PhoneIcon />
          <IonLabel>{user.phone}</IonLabel>
        </IonItem>

        <IonItem id="customer-profile-email" lines="none" className="input">
          <EmailIcon />
          <IonInput
            name="email"
            type="email"
            placeholder="* Email "
            color={email?.length ? '' : 'danger'}
            required
            value={email}
            onIonChange={(e): void => {
              setEmail(e.detail.value || '');
            }}
          />
        </IonItem>

        <IonItem id="customer-profile-about-you" lines="none" className="input">
          <UserIcon />
          <IonTextarea
            name="about"
            value={about}
            rows={1}
            placeholder="About you..."
            onIonChange={(e): void => {
              setAbout(e.detail.value || '');
            }}
          />
        </IonItem>
        <IonItem id="customer-profile-location" lines="none" className="input">
          <LocationIcon />
          <IonGrid>
            <IonRow>
              <IonCol>
                <PlaceSearchInput
                  value={location?.address}
                  onChange={(value): void => setlocation(value)}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem id="customer-profile-map" lines="none" className="map">
          <div className="map-container">
            <Map
              locations={location ? [location] : []}
              mapCenter={{ lat: location?.lat, lng: location?.lng }}
            />
          </div>
        </IonItem>
        <IonItem id="customer-profile-settings" lines="none" className="input">
          <IonGrid>
            <IonRow>
              <IonCol>
                <br />
                <IonLabel>SMS, Email & Notification settings</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="4">
                <IonItem id="customer-profile-sms" lines="none">
                  <IonLabel>SMS</IonLabel>
                  <IonToggle
                    color="favorite"
                    checked={smsEnabled}
                    onIonChange={(event): void => setSmsEnabled(event.detail.checked)}
                  />
                </IonItem>
              </IonCol>
              <IonCol size="4">
                <IonItem id="customer-profile-email-notification" lines="none">
                  <IonLabel>Email</IonLabel>
                  <IonToggle
                    color="favorite"
                    checked={emailEnabled}
                    onIonChange={(event): void => setEmailEnabled(event.detail.checked)}
                  />
                </IonItem>
              </IonCol>
              <IonCol size="4">
                <IonItem id="customer-profile-notification" lines="none">
                  <IonLabel>Notification</IonLabel>
                  <IonToggle
                    color="favorite"
                    checked={notificationEnabled}
                    onIonChange={(event): void => setNotificationEnabled(event.detail.checked)}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonList>
      <IonButton id="customer-profile-change-password" color="favorite" onClick={(): void => setShowChangePasswordModal(true)}>
        Change password
      </IonButton>
      <IonButton id="customer-profile-update" color="favorite" onClick={(): void => onUpdateProfile()}>
        Update
      </IonButton>
      <IonButton id="customer-profile-t&c" color="favorite" fill="outline" disabled={!user.signature} onClick={onDownloadTermsAndConditions}>
        Download T&C
      </IonButton>

      <IonAlert
        isOpen={showAlert.status}
        onDidDismiss={(): void => {
          setShowAlert((prev) => ({ ...prev, status: false }));
        }}
        header="Care Platform"
        message={showAlert.message}
        buttons={['Ok']}
      />

      <ChangePasswordModal
        user={user}
        isOpen={showChangePasswordModal}
        toggleModal={setShowChangePasswordModal}
      />

      <IdentityModal
        user={user}
        isOpen={showIdentityModal}
        toggleModal={setShowIdentityModal}
      />
      <IonLoading isOpen={loading} />
    </IonContent>
  );
};

CustomerProfile.propTypes = {
  user: PropTypes.any.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(CustomerProfile)
});
