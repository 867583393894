/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import PropTypes from 'prop-types';
import {
  IonContent,
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
  IonLoading,
  IonAlert
} from '@ionic/react';
import moment from 'moment';
import slugify from 'slugify';

import Avatar from '../../components/Avatar';
import connect from '../../data/connect';
import { Booking, Rating, UserRole } from '../../models';
import { updateCustomerRating, fetchBooking } from '../../data/dataApi';
import StarRating from '../../components/StarRating';

import './CustomerRating.scss';

interface MatchParams {
  id: string
}

type ProviderRatingProps = RouteComponentProps<MatchParams>;

const ProviderRating: React.FC<ProviderRatingProps> = ({
  match,
  history,
}) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [booking, setBooking] = useState<Booking | null>(null);
  const [feedback, setFeedback] = useState<Rating | null>(null);

  const onUpdate = (): void => {
    if (!booking) {
      return;
    }
    setIsUpdating(true);
    updateCustomerRating({
      bookingId: booking.id,
      rating,
      review
    }).then((res) => {
      setShowSuccessAlert(true);
    }).finally(() => setIsUpdating(false));
  };

  useEffect(() => {
    const getData = (id: number) => {
      setIsLoading(true);
      fetchBooking(id)
        .then((res: { success: boolean, booking: Booking }) => {
          if (res.success) {
            setBooking(res.booking);
            const fd = res.booking.feedbacks.find((item: Rating) => item.type === UserRole.CUSTOMER);
            if (fd) {
              setFeedback(fd);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          history.goBack();
        })
        .finally(() => setIsLoading(false));
    };

    if (match.params.id) {
      getData(Number(match.params.id));
    }
  }, [match.params.id]);

  useEffect(() => {
    if (feedback) {
      setRating(feedback?.rating);
      setReview(feedback?.review);
    }
  }, [feedback]);

  return (
    <IonContent id="booking-rating" className="page-content ion-padding">
      {isLoading || !booking ? <IonLoading isOpen={isLoading} /> : (
        <div className="booking-rating-body">
          <IonItem id={`customer-rating-profile-information-${slugify(booking?.customer.name).toLowerCase()}`} lines="none" className="profile-content">
            {booking?.customer ? (<Avatar user={booking?.customer} size="lg" />) : null}
            <div className="profile-information">
              <div className="name">{`${booking.customer.name} ${booking.customer.lastName ?? ''}`}</div>
              <br />
              <div>{moment.utc(`${booking.date} ${booking.time}`).local().format('MMM DD, YYYY hh:mm A')}</div>
            </div>
          </IonItem>
          <IonItem id="customer-rating-rating" lines="none">
            <IonLabel className="rating" position="stacked">Rating</IonLabel>
            <StarRating
              rating={rating || 0}
              readonly={false}
              onChange={(value): void => setRating(value)}
            />
          </IonItem>
          <IonItem id="customer-rating-review" lines="none">
            <IonLabel position="stacked">Review</IonLabel>
            <IonTextarea
              rows={4}
              placeholder="Leave feedback here.."
              value={review}
              onIonChange={(evt) => setReview(evt.detail.value!)}
            />
          </IonItem>
          <IonButton id="customer-rating-update" color="favorite" expand="block" onClick={() => onUpdate()}>
            Update
          </IonButton>

          <IonLoading isOpen={isUpdating} />
          <IonAlert
            id="customer-rating-review-update-success"
            isOpen={showSuccessAlert}
            onDidDismiss={(): void => {
              setShowSuccessAlert(false);
              history.goBack();
            }}
            header="Care Platform"
            message={`Your review for booking with ${booking.customer.name} ${booking.customer.lastName ?? ''} was updated successfully.`}
            buttons={['Ok']}
          />
        </div>
      )}
    </IonContent>
  );
};

ProviderRating.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired
};

export default connect<RouteComponentProps, {}, {}>({
  component: React.memo(ProviderRating)
});
