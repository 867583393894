import React from 'react';
import { IonFabButton, IonIcon } from '@ionic/react';
import { desktop } from 'ionicons/icons';

import useScreenShareToggle from '../../../../hooks/useScreenShareToggle/useScreenShareToggle';
import useScreenShareParticipant from '../../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const STOP_SCREEN_SHARE_TEXT = 'Stop Sharing Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const [isScreenShared, toggleScreenShare] = useScreenShareToggle();
  const screenShareParticipant = useScreenShareParticipant();
  const { room } = useVideoContext();
  const disableScreenShareButton = screenShareParticipant && screenShareParticipant !== room.localParticipant;
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;

  let tooltipMessage = SCREEN_SHARE_TEXT;

  if (isScreenShared) {
    tooltipMessage = STOP_SCREEN_SHARE_TEXT;
  }

  if (disableScreenShareButton) {
    tooltipMessage = SHARE_IN_PROGRESS_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  return (
    <div>
      {/* The div element is needed because a disabled button will not emit hover events and we want to display
        a tooltip when screen sharing is disabled */}
      <IonFabButton onClick={toggleScreenShare} disabled={isDisabled}>
        {isScreenShared ? (
          <IonIcon icon={desktop} />
        ) : (
          <IonIcon icon={desktop} />
        )}
      </IonFabButton>
    </div>
  );
}
