import React from 'react';
import PropTypes from 'prop-types';

interface ChatBotMessageProps {
  body: string;
  date: string;
}

const ChatBotMessage: React.FC<ChatBotMessageProps> = ({
  body, date
}) => (
  <div className="chatbot-message">
    {`${body} at ${date}`}
  </div>
);

ChatBotMessage.propTypes = {
  body: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default ChatBotMessage;
