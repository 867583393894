import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonListHeader,
  IonLabel,
  IonToggle,
} from '@ionic/react';

import Avatar from '../../components/Avatar';
import ChangePasswordModal from '../../components/Modal/ChangePasswordModal';
import connect from '../../data/connect';
import { User, UserStatus } from '../../models';
import * as selectors from '../../data/selectors';
import { dispatchValidate } from '../../data/store/auth/auth.actions';
import { dispatchFetchTimeslots, dispatchFetchClinicMembers } from '../../data/store/data/data.actions';
import {
  updateUser,
  signInAsUser,
  setToken
} from '../../data/dataApi';
import Map from '../../components/Map';
import { StarFilledIcon } from '../../icons';

import './ClinicMemberDetail.scss';

interface StateProps {
  user: User
  member: User | undefined
}

interface DispatchProps {
  fetchTimeslots: typeof dispatchFetchTimeslots
  fetchClinicMembers: typeof dispatchFetchClinicMembers
  validate: typeof dispatchValidate
}

type ClinicMemberDetailProps = RouteComponentProps & StateProps & DispatchProps;

const ClinicMemberDetail: React.FC<ClinicMemberDetailProps> = ({
  history,
  user,
  member,
  fetchTimeslots,
  fetchClinicMembers,
  validate
}) => {
  const [showMap, setShowMap] = useState(false);
  // state for change password
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const onEditProfile = (): void => {
    if (member) {
      history.push(`/clinic-admin/member/${member.id}/profile`);
    }
  };

  const onUpdateBusinessHours = (): void => {
    if (member) {
      fetchTimeslots(member.id);
      history.push(`/clinic-admin/member/${member.id}/business-hour`);
    }
  };

  const onUpdateTimeslots = (): void => {
    if (member) {
      history.push(`/clinic-admin/member/${member.id}/timeslot`);
    }
  };

  const onChangeStatus = (): void => {
    if (!member) {
      return;
    }
    updateUser(member.id, {
      status:
        member.status === UserStatus.ACTIVE
          ? UserStatus.PENDING
          : UserStatus.ACTIVE
    })
      .then(() => {
        fetchClinicMembers(user.id);
      })
      .catch((err) => console.log(err));
  };

  const signAs = () => {
    if (member) {
      signInAsUser(member.id)
        .then(async (res) => {
          if (res.success) {
            await setToken(res.token);
            validate();
            history.push('/');
          }
        })
        .catch((err) => console.log(err));
    }
  };

  if (!member) {
    return <div>Invalid Member</div>;
  }

  return (
    <IonContent id="clinic-member-detail" className="provider-detail-content page-content ion-padding">
      <IonListHeader className="page-header">Clinic Member</IonListHeader>
      <IonGrid>
        <IonRow className="profile-content profile">
          <Avatar user={member} size="lg" />
          <IonLabel>
            <h2>{member.name}</h2>
            <p>{member.title}</p>
            <span>
              <StarFilledIcon />
              {member.rating || '0.0'}
            </span>
          </IonLabel>
        </IonRow>
        <IonRow>
          <div className="sub-detail">
            <div className="sub-title">
              {`About ${member.name}`}
            </div>
            <div>{member.about || 'No description'}</div>
          </div>
        </IonRow>
        <IonRow>
          <div className="sub-detail">
            <div className="sub-title">Languages Spoken</div>
            <div>
              {member.languages.map((language) => language.name).join(' - ')}
            </div>
          </div>
        </IonRow>
        <IonRow>
          <div className="sub-detail">
            <div className="sub-title">Services Offered</div>
            <div>
              {member.specialists
                .map((specialist) => specialist.name)
                .join(' | ')}
            </div>
          </div>
        </IonRow>
        <IonRow>
          <div className="sub-detail location">
            <div className="sub-title">Location</div>
            <IonRow>
              {member.locations.length > 0 ? (
                <>
                  <div>{member.locations[0].address}</div>
                  <IonButton
                    color="primary"
                    size="small"
                    onClick={(): void => setShowMap(!showMap)}
                  >
                    Directions
                  </IonButton>
                  {showMap && (
                    <div className="map-container">
                      <Map
                        locations={member.locations}
                        mapCenter={member.locations[0]}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div>No address</div>
              )}
            </IonRow>
          </div>
        </IonRow>
        <IonRow>
          <IonCol size="2">
            <IonToggle
              color="favorite"
              checked={member.status === UserStatus.ACTIVE}
              onIonChange={(): void => onChangeStatus()}
            />
          </IonCol>
          <IonCol size="10">
            <div className="label">
              {member.status === UserStatus.ACTIVE
                ? 'Enable Member'
                : 'Disable Member'}
            </div>
          </IonCol>
          <IonCol size="12">
            <IonButton
              expand="block"
              color="favorite"
              fill="outline"
              onClick={(): void => onEditProfile()}
            >
              Edit Profile
            </IonButton>
          </IonCol>
          <IonCol size="12">
            <IonButton
              expand="block"
              color="favorite"
              fill="outline"
              onClick={(): void => onUpdateBusinessHours()}
            >
              Business Hours
            </IonButton>
          </IonCol>
          <IonCol size="12">
            <IonButton
              expand="block"
              color="favorite"
              fill="outline"
              onClick={(): void => onUpdateTimeslots()}
            >
              Availability
            </IonButton>
          </IonCol>
          <IonCol size="12">
            <IonButton
              expand="block"
              color="favorite"
              fill="outline"
              onClick={(): void => setShowChangePasswordModal(true)}
            >
              Update password
            </IonButton>
          </IonCol>
          <IonCol size="12">
            <IonButton
              expand="block"
              color="favorite"
              fill="outline"
              onClick={signAs}
            >
              Signin As
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>

      {/* change password modal */}
      <ChangePasswordModal
        user={member}
        isOpen={showChangePasswordModal}
        toggleModal={setShowChangePasswordModal}
      />
    </IonContent>
  );
};

ClinicMemberDetail.propTypes = {
  history: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired,
  member: PropTypes.any.isRequired,
  fetchTimeslots: PropTypes.func.isRequired,
  fetchClinicMembers: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

export default connect<RouteComponentProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    user: state.auth.user,
    member: selectors.getMember(state, OwnProps)
  }),
  mapDispatchToProps: {
    fetchTimeslots: dispatchFetchTimeslots,
    fetchClinicMembers: dispatchFetchClinicMembers,
    validate: dispatchValidate
  },
  component: React.memo(ClinicMemberDetail)
});
