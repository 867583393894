import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonList,
  IonBackButton
} from '@ionic/react';

import connect from '../../data/connect';
import { User } from '../../models';
import { fetchClinicMembers } from '../../data/dataApi';
import * as selectors from '../../data/selectors';
import UserItem from '../../components/UserItem';
import './ClinicMemberList.scss';
import { dispatchAddFavoriteProvider, dispatchDeleteFavoriteProvider } from '../../data/store/data/data.actions';

interface MatchParams {
  id: string
}

interface StateProps {
  clinic: User|undefined;
}

interface DispatchProps {
  addFavoriteProvider: typeof dispatchAddFavoriteProvider;
  deleteFavoriteProvider: typeof dispatchDeleteFavoriteProvider;
}

type ClinicMemberListProps = RouteComponentProps<MatchParams> & StateProps & DispatchProps;

const ClinicMemberList: React.FC<ClinicMemberListProps> = ({
  match, clinic, addFavoriteProvider, deleteFavoriteProvider
}) => {
  const [members, setMembers] = useState([] as User[]);

  useEffect(() => {
    fetchClinicMembers(Number(match.params.id)).then((res) => {
      if (res.success) {
        setMembers(res.members);
      }
    });
  }, [match.params.id]);

  if (!clinic) {
    return <div>erorr</div>;
  }

  return (
    <IonPage id="clinic-member-list">
      <IonHeader>
        <IonToolbar className="top-header">
          <IonButtons slot="start">
            <img src="assets/img/hair-logo.svg" alt="ionic logo" />
          </IonButtons>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
        <IonToolbar className="dashboard-header">
          <IonButtons slot="start">
            <IonBackButton color="primary" />
          </IonButtons>
          <IonTitle color="primary" className="header-text">
            {`Members of ${clinic.title}`}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonList>
          {
            members.map((user) => (
              <UserItem
                key={user.id}
                user={user}
                addFavoriteProvider={addFavoriteProvider}
                deleteFavoriteProvider={deleteFavoriteProvider}
                onClick={() => {}}
              />
            ))
          }
        </IonList>
      </IonContent>
    </IonPage>
  );
};

ClinicMemberList.propTypes = {
  match: PropTypes.any.isRequired,
  clinic: PropTypes.any.isRequired,
  addFavoriteProvider: PropTypes.func.isRequired,
  deleteFavoriteProvider: PropTypes.func.isRequired
};

export default connect<RouteComponentProps<MatchParams>, StateProps, {}>({
  mapStateToProps: (state, OwnProps) => ({
    clinic: selectors.getProvider(state, OwnProps)
  }),
  mapDispatchToProps: {
    addFavoriteProvider: dispatchAddFavoriteProvider,
    deleteFavoriteProvider: dispatchDeleteFavoriteProvider,
  },
  component: React.memo(ClinicMemberList)
});
