import React, {
  createRef, useContext, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  IonItem,
  IonButton,
  IonInput,
  IonAlert,
  IonLoading,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonModal,
  IonDatetime,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import moment from 'moment';
import Dropzone, { DropzoneRef } from 'react-dropzone';

import { User, License } from '../../models';
import { AppContext } from '../../data/AppContext';
import { uploadLicense } from '../../data/dataApi';
import states from '../../data/json/states.json';

const initLicense = {
  id: -1,
  name: '',
  issuingOrganization: '',
  issueDate: '',
  expirationDate: '',
  credentialId: '',
  credentialFileName: '',
  number: '',
  state: '',
  country: '',
  type: '',
};

interface LicenseProps {
  user: User
  type: number
  isOpen: boolean
  toggleModal: (value: boolean) => void
}

const LicenseModal: React.FC<LicenseProps> = ({
  user, type, isOpen, toggleModal
}) => {
  const [license, setLicense] = useState<License>(initLicense);
  const [credential, setCredential] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [showUploadSuccessAlert, setShowUploadSuccessAlert] = useState(false);
  const [fileUploadError, setFileUploadError] = useState('');

  const dropzoneRef = createRef<DropzoneRef>();
  const { dispatch } = useContext(AppContext);

  const onDismissModal = () => {
    toggleModal(false);
  };

  const onUploadLicense = () => {
    const {
      name,
      issuingOrganization,
      issueDate,
      expirationDate,
      credentialId,
      number,
      state,
      country
    } = license;
    const data = new FormData();
    data.append('name', name);
    data.append('issuingOrganization', issuingOrganization);
    data.append('issueDate', issueDate);
    data.append('expirationDate', expirationDate);
    data.append('credentialId', credentialId);
    data.append('number', number);
    data.append('state', state);
    data.append('country', country);

    // eslint-disable-next-line no-nested-ternary
    const typeStr = type === 1 ? 'Dietician' : type === 2 ? 'Massage Therapist' : 'Physician';
    data.append('type', typeStr);
    if (credential) {
      data.append('attachments', credential);
    }

    setUploading(true);
    uploadLicense(data)
      .then((res) => {
        if (res.success) {
          const prevLicenses = user.licenses ?? [];
          dispatch({
            type: '@user/set-user',
            data: { ...user, licenses: [...prevLicenses, res.license] }
          });
          setShowUploadSuccessAlert(true);
        }
      })
      .finally(() => setUploading(false));
  };

  const availableStates = useMemo(() => {
    if (license.country === 'us' || license.country === 'ca') {
      return states[license.country];
    }
    return [];
  }, [states, license.country]);

  const submitDisabled = useMemo(() => {
    const {
      name,
      issuingOrganization,
      issueDate,
      expirationDate,
      credentialId,
      number,
      state,
      country
    } = license;

    if (!name || !issuingOrganization || !issueDate || !expirationDate || !credentialId || !number || !state || !country || !credential) {
      return true;
    }
    return false;
  }, [license, credential]);

  return (
    <IonModal
      cssClass="license-modal"
      isOpen={isOpen}
      onDidDismiss={onDismissModal}
    >
      <IonLabel>Upload License</IonLabel>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonLabel className="field-title">Name</IonLabel>
            <IonItem lines="none" className="input">
              <IonInput
                name="license-name"
                type="text"
                placeholder="Ex: Microsoft certified"
                value={license?.name}
                onIonChange={(e): void => setLicense({ ...license, name: e.detail.value ?? '' })}
              />
            </IonItem>
          </IonCol>
          <IonCol size="12">
            <IonLabel className="field-title">Issuing Organization</IonLabel>
            <IonItem lines="none" className="input">
              <IonInput
                name="license-organization"
                type="text"
                placeholder="Ex: Microsoft"
                value={license?.issuingOrganization}
                onIonChange={(e): void => setLicense({ ...license, issuingOrganization: e.detail.value ?? '' })}
              />
            </IonItem>
          </IonCol>
          <IonCol size="6">
            <IonLabel className="field-title">Issue Date</IonLabel>
            <IonItem lines="none" className="input">
              <IonDatetime
                max={moment().format('YYYY-MM-DD')}
                displayFormat="MMMM YYYY"
                pickerFormat="YYYY MMMM"
                value={license?.issueDate ? moment(license.issueDate).toLocaleString() : null}
                onIonChange={(event): void => setLicense({ ...license, issueDate: moment(event.detail.value || '').format('MMMM YYYY') })}
              />
            </IonItem>
          </IonCol>
          <IonCol size="6">
            <IonLabel className="field-title">Expiration Date</IonLabel>
            <IonItem lines="none" className="input">
              <IonDatetime
                max="2100"
                min={license.issueDate ? moment(license.issueDate).format('YYYY-MM-DD') : undefined}
                displayFormat="MMMM YYYY"
                pickerFormat="YYYY MMMM"
                value={license?.expirationDate ? moment(license.expirationDate).toLocaleString() : null}
                onIonChange={(event): void => setLicense({ ...license, expirationDate: moment(event.detail.value || '').format('MMMM YYYY') })}
              />
            </IonItem>
          </IonCol>
          <IonCol size="12">
            <IonLabel className="field-title">Issue Number</IonLabel>
            <IonItem lines="none" className="input">
              <IonInput
                name="license-number"
                type="text"
                placeholder=""
                value={license?.number}
                onIonChange={(e): void => setLicense({ ...license, number: e.detail.value ?? '' })}
              />
            </IonItem>
          </IonCol>
          <IonCol size="6">
            <IonLabel className="field-title">Issue Country</IonLabel>
            <IonItem lines="none" className="select">
              <IonSelect
                className="select"
                placeholder="Select Country..."
                value={license.country}
                onIonChange={(e): void => setLicense({ ...license, country: String(e.detail.value) })}
              >
                <IonSelectOption value="us">United State</IonSelectOption>
                <IonSelectOption value="ca">Canada</IonSelectOption>
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size="6">
            <IonLabel className="field-title">Issue State</IonLabel>
            <IonItem lines="none" className="select">
              <IonSelect
                className="select"
                placeholder="Select State..."
                value={license.state}
                disabled={!license.country}
                onIonChange={(e): void => setLicense({ ...license, state: String(e.detail.value) })}
              >
                {availableStates.map((state) => (
                  <IonSelectOption value={state.abbr}>{state.name}</IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonCol>
          <IonCol size="12">
            <IonLabel className="field-title">Credential ID</IonLabel>
            <IonItem lines="none" className="input">
              <IonInput
                name="license-credential-id"
                type="text"
                placeholder=""
                value={license?.credentialId}
                onIonChange={(e): void => setLicense({ ...license, credentialId: e.detail.value ?? '' })}
              />
            </IonItem>
          </IonCol>
          <IonLabel color="danger">
            <p style={{ marginLeft: 10 }}>
              {fileUploadError}
            </p>
          </IonLabel>
          <IonCol size="12">
            <Dropzone
              ref={dropzoneRef}
              noClick
              onDrop={(files, errors) => {
                setFileUploadError(errors?.length > 0 ? 'Please upload PDF file of license' : '');
                if (files.length) {
                  setLicense({ ...license, credentialFileName: files[0].name });
                  setCredential(files[0]);
                }
              }}
              accept={{
                'application/pdf': ['.pdf']
              }}
            >
              {({ getRootProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <IonLabel className="field-title">Credential</IonLabel>
                    <div className="drop-zone">
                      Drag & Drop File Here
                      <br />
                      <span>(PDF)</span>
                    </div>
                    <IonItem lines="none" className="input">
                      <IonLabel slot="start">{license.credentialFileName || 'No Credential'}</IonLabel>
                      <IonButton
                        className="add-file"
                        slot="end"
                        color="favorite"
                        onClick={() => dropzoneRef?.current?.open()}
                      >
                        {license.credentialFileName ? 'Change Credential' : 'Add Credential'}
                      </IonButton>
                    </IonItem>
                  </div>
                </section>
              )}
            </Dropzone>
          </IonCol>
          <IonCol size="12">
            <IonItem lines="none">
              <IonButton color="favorite" disabled={submitDisabled} onClick={onUploadLicense}>
                Submit
              </IonButton>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonAlert
        isOpen={showUploadSuccessAlert}
        onDidDismiss={(): void => {
          setShowUploadSuccessAlert(false);
          toggleModal(false);
        }}
        header="Care Platform"
        message="License has been uploaded successfully."
        buttons={['Ok']}
      />

      <IonLoading isOpen={uploading} />
    </IonModal>
  );
};

LicenseModal.propTypes = {
  user: PropTypes.any.isRequired,
  type: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
};

export default React.memo(LicenseModal);
