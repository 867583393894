import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StarFilledIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M1.10551 10.3332L4.07217 12.4999L2.94551 15.989C2.76343 16.5302 2.76113 17.1157 2.93893 17.6583C3.11673 18.2009 3.46513 18.6714 3.93217 18.9999C4.39122 19.3389 4.9475 19.5205 5.51813 19.5176C6.08877 19.5148 6.64321 19.3276 7.09884 18.984L9.99967 16.849L12.9013 18.9815C13.3596 19.3186 13.9128 19.5017 14.4816 19.5044C15.0505 19.5072 15.6055 19.3295 16.067 18.997C16.5285 18.6644 16.8726 18.1941 17.0499 17.6536C17.2272 17.1131 17.2286 16.5304 17.0538 15.989L15.9272 12.4999L18.8938 10.3332C19.3515 9.99864 19.6916 9.528 19.8658 8.98854C20.0399 8.44907 20.0392 7.86837 19.8636 7.32937C19.688 6.79037 19.3465 6.32065 18.888 5.9873C18.4295 5.65395 17.8774 5.47402 17.3105 5.47321H13.6663L12.5605 2.02655C12.3866 1.48401 12.0449 1.01073 11.5846 0.674935C11.1244 0.339144 10.5694 0.158203 9.99967 0.158203C9.42995 0.158203 8.87495 0.339144 8.4147 0.674935C7.95445 1.01073 7.61274 1.48401 7.43884 2.02655L6.33301 5.47321H2.69217C2.12529 5.47402 1.57317 5.65395 1.11466 5.9873C0.656146 6.32065 0.314706 6.79037 0.139107 7.32937C-0.0364925 7.86837 -0.0372697 8.44907 0.136886 8.98854C0.311042 9.528 0.651223 9.99864 1.10884 10.3332H1.10551Z" fill={color} />
  </svg>
);

export default StarFilledIcon;
