import React, { createRef, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonLoading,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTextarea
} from '@ionic/react';
import {
  closeOutline, options, searchOutline, calendarOutline, ellipsisHorizontal, toggle
} from 'ionicons/icons';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import clsx from 'clsx';
import moment from 'moment';

import connect from '../../data/connect';
import {
  fetchUsers,
  fetchCampaigns,
  addCampaign,
  updateCampaign,
  deleteCampaign,
  addCampainDescription,
  getSignedUrl
} from '../../data/dataApi';
import {
  Attachment,
  Campaign, CampaignStatus, SocialMedia, User, UserRole
} from '../../models';
import { DetailsIcon, TrashIcon } from '../../icons';

interface ActionsProps {
  campaign: Campaign
  onSelect: (value: Campaign) => void
  removeCampaign: (id: number) => void
}

const Actions: React.FC<ActionsProps> = ({ campaign, onSelect, removeCampaign }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);

  const onRemove = () => {
    setIsDeleting(true);
    deleteCampaign(campaign.id)
      .then((res) => {
        if (res.success) {
          removeCampaign(campaign.id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsDeleting(false));
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'top', 'left', 'right']}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="#daf3ef"
            arrowSize={10}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className="popover-content">
              <div className="option" onClick={() => onSelect(campaign)}>
                <IonLabel>
                  <DetailsIcon />
                  Details
                </IonLabel>
              </div>
              <div className="option" onClick={() => { setIsPopoverOpen(false); setShowRemoveAlert(true); }}>
                <IonLabel color="danger">
                  <TrashIcon />
                  Delete
                </IonLabel>
              </div>
            </div>
          </ArrowContainer>
        )}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        {isDeleting ? <IonSpinner /> : (
          <span
            className="action"
            title="More actions"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <IonIcon icon={ellipsisHorizontal} />
          </span>
        )}
      </Popover>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={(): void => setShowRemoveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to delete this campaign?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onRemove()
          }
        ]}
      />
    </>
  );
};

Actions.propTypes = {
  campaign: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  removeCampaign: PropTypes.func.isRequired
};

const initCampaign: Campaign = {
  id: -1,
  name: '',
  assigneeId: -1,
  assignee: null,
  status: 'new',
  socialMediaId: -1,
  socialMedia: null,
  budget: null,
  numberSent: null,
  expectedResponse: null,
  expectedRevenue: null,
  startDate: null,
  endDate: null,
  descriptions: []
};

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface CampaignsProps {
  toggleHeader: (value: boolean) => void
}

interface StateProps {
  socialMediaTypes: SocialMedia[]
}

interface Description {
  content: string
  files: File[]
}

const Campaigns: React.FC<CampaignsProps & StateProps> = ({
  socialMediaTypes, toggleHeader
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);
  const [marketingMembers, setMarketingMembers] = useState<User[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [detailsTab, setDetailsTab] = useState<'info' | 'scheduled' | 'sent'>('info');
  const [description, setDescription] = useState('');
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [newDescriptions, setNewDescriptions] = useState<Description[]>([]);
  const searchTimeoutRef = useRef<number | null>(null);

  const dropzoneRef = createRef<DropzoneRef>();

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const onAddNew = () => {
    toggleHeader(false);
    setIsEdit(false);
    setSelectedCampaign(initCampaign);
  };

  const onSubmit = async () => {
    if (
      !selectedCampaign
      || selectedCampaign.assigneeId <= 0
      || !selectedCampaign.name
      || selectedCampaign.socialMediaId <= 0
    ) {
      return setShowWarningAlert(true);
    }
    setIsUpdating(true);
    const data = Object.fromEntries(Object.entries(selectedCampaign).filter(([_, v]) => v != null && v !== '' && v !== -1));
    let campaignId = selectedCampaign.id;
    if (isEdit) {
      await updateCampaign(selectedCampaign.id, data);
    } else {
      const { campaign } = await addCampaign(data);
      campaignId = campaign.id;
    }

    // check removed files id
    const promises: any[] = [];
    if (newDescriptions.length) {
      newDescriptions.map((nd) => {
        const assetsData = new FormData();
        assetsData.append('id', String(campaignId));
        assetsData.append('content', nd.content);
        nd.files.map((file) => {
          assetsData.append('attachments', file);
        });
        promises.push(addCampainDescription(assetsData));
      });
    }
    await Promise.all(promises);
    setSelectedCampaign(null);
    setIsUpdating(false);
  };

  const removeCampaign = (campaignId: number) => {
    const updatedCampaigns = campaigns.filter((cmp) => cmp.id !== campaignId);
    setCampaigns([...updatedCampaigns]);
  };

  const onShowAttachment = async (name: string) => {
    const { url } = await getSignedUrl(name);
    window.open(url, '_blank');
  };

  const fetchMore = (isNew: boolean, str?: string) => {
    const params = {
      skip: isNew ? 0 : campaigns.length,
      searchKey: str === undefined ? searchKey : str,
      isDescending,
      orderBy
    };

    setIsFetching(true);
    fetchCampaigns(params)
      .then((res) => {
        if (res.success) {
          if (isNew) {
            setCampaigns([...res.campaigns]);
          } else {
            setCampaigns([...campaigns, ...res.campaigns]);
          }
          if (!res.campaigns.length || res.campaigns.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    const getMarketingMembers = () => {
      setIsFetching(true);
      fetchUsers({ role: UserRole.MARKETING_MEMBER })
        .then((res) => {
          if (res.success) {
            setMarketingMembers(res.users);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => setIsFetching(false));
    };

    getMarketingMembers();
  }, []);

  useEffect(() => {
    if (!selectedCampaign) {
      fetchMore(true);
      toggleHeader(true);
      setNewFiles([]);
      setNewDescriptions([]);
    }
  }, [selectedCampaign]);

  return (
    <div className="marketing-section">
      {(selectedCampaign && selectedCampaign.id === -1) || (selectedCampaign && selectedCampaign.id > -1 && isEdit) ? (
        <div className="marketing-section-details">
          <div className="marketing-section-header">
            <div className="screen-header">
              {isEdit ? 'Edit Campaign' : 'Add Campaign'}
            </div>
          </div>
          <div className="marketing-section-content">
            <IonGrid className="campaign">
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="field-title">Assignee*</IonLabel>
                  <IonItem lines="none" className="select">
                    <IonSelect
                      className="select"
                      placeholder="Please Select"
                      disabled={!marketingMembers.length}
                      value={selectedCampaign.assigneeId}
                      onIonChange={(e) => setSelectedCampaign({ ...selectedCampaign, assigneeId: e.detail.value })}
                    >
                      {marketingMembers.map((mm) => (
                        <IonSelectOption key={mm.id} value={mm.id}>
                          {`${mm.name} ${mm.lastName || ''}`}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="field-title">Budget Cost</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonInput
                      type="number"
                      placeholder=""
                      value={selectedCampaign.budget}
                      onIonChange={(e) => setSelectedCampaign({ ...selectedCampaign, budget: parseInt(e.detail.value as string, 10) })}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="field-title">Name*</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonInput
                      type="text"
                      placeholder=""
                      value={selectedCampaign.name}
                      onIonChange={(e) => setSelectedCampaign({ ...selectedCampaign, name: e.detail.value as string })}
                    />
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="field-title">Number Sent</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonInput
                      type="number"
                      placeholder=""
                      value={selectedCampaign.numberSent}
                      onIonChange={(e) => setSelectedCampaign({ ...selectedCampaign, numberSent: parseInt(e.detail.value as string, 10) })}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="field-title">Status*</IonLabel>
                  <IonItem lines="none" className="select">
                    <IonSelect
                      className="select"
                      placeholder="Please Select"
                      disabled={!isEdit}
                      value={selectedCampaign.status}
                      onIonChange={(e) => setSelectedCampaign({ ...selectedCampaign, status: e.detail.value })}
                    >
                      <IonSelectOption value="new">New</IonSelectOption>
                      <IonSelectOption value="scheduled">Scheduled</IonSelectOption>
                      <IonSelectOption value="active">Active</IonSelectOption>
                      <IonSelectOption value="inactive">Inactive</IonSelectOption>
                      <IonSelectOption value="cancelled">Cancelled</IonSelectOption>
                      <IonSelectOption value="completed">Completed</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="field-title">Expected Response</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonInput
                      type="number"
                      placeholder=""
                      value={selectedCampaign.expectedResponse}
                      onIonChange={(e) => setSelectedCampaign({ ...selectedCampaign, expectedResponse: parseInt(e.detail.value as string, 10) })}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="field-title">Social Media*</IonLabel>
                  <IonItem lines="none" className="select">
                    <IonSelect
                      className="select"
                      placeholder="Please Select"
                      value={selectedCampaign.socialMediaId}
                      onIonChange={(e) => setSelectedCampaign({ ...selectedCampaign, socialMediaId: parseInt(e.detail.value as string, 10) })}
                    >
                      {socialMediaTypes.map((socialMedia) => (
                        <IonSelectOption key={socialMedia.id} value={socialMedia.id}>
                          {socialMedia.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="field-title">Expected Revenue</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonInput
                      type="number"
                      placeholder=""
                      value={selectedCampaign.expectedRevenue}
                      onIonChange={(e) => setSelectedCampaign({ ...selectedCampaign, expectedRevenue: parseInt(e.detail.value as string, 10) })}
                    />
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <IonLabel className="field-title">Start Date</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonDatetime
                      max={moment().format('YYYY-MM-DD')}
                      displayFormat="MMMM DD YYYY"
                      pickerFormat="YYYY MMMM DD"
                      value={selectedCampaign.startDate ? moment(selectedCampaign.startDate).toLocaleString() : null}
                      onIonChange={(e) => setSelectedCampaign({ ...selectedCampaign, startDate: moment(e.detail.value || '').format('YYYY-MM-DD') })}
                    />
                    <IonIcon slot="end" icon={calendarOutline} />
                  </IonItem>
                </IonCol>
                <IonCol size="6">
                  <IonLabel className="field-title">End Date</IonLabel>
                  <IonItem lines="none" className="input">
                    <IonDatetime
                      max="2100"
                      min={selectedCampaign.startDate ? moment(selectedCampaign.startDate).format('YYYY-MM-DD') : undefined}
                      displayFormat="MMMM DD YYYY"
                      pickerFormat="YYYY MMMM DD"
                      value={selectedCampaign.endDate ? moment(selectedCampaign.endDate).toLocaleString() : null}
                      onIonChange={(e) => setSelectedCampaign({ ...selectedCampaign, endDate: moment(e.detail.value || '').format('YYYY-MM-DD') })}
                    />
                    <IonIcon slot="end" icon={calendarOutline} />
                  </IonItem>
                </IonCol>
              </IonRow>
              <br />
              <IonRow>
                <IonCol size="12">
                  <IonLabel className="field-title">Description</IonLabel>
                </IonCol>
                <IonCol size="12">
                  <IonGrid className="table descriptions">
                    <IonRow className="table-header">
                      <IonCol className="sm">NO</IonCol>
                      <IonCol>CREATED AT</IonCol>
                      <IonCol size="6">CONTENT</IonCol>
                      <IonCol size="3">ATTACHMENTS</IonCol>
                      <IonCol />
                    </IonRow>
                    {!selectedCampaign.descriptions.length && !newDescriptions.length ? (
                      <IonRow>
                        <IonCol size="12" className="empty-table">
                          No Descriptions
                        </IonCol>
                      </IonRow>
                    ) : (
                      <>
                        {selectedCampaign.descriptions.map((cd, index) => (
                          <IonRow className="table-row">
                            <IonCol className="sm">{index + 1}</IonCol>
                            <IonCol>{moment.utc(cd.createdAt).local().format('MMM DD YYYY, hh:mm A')}</IonCol>
                            <IonCol size="6">{cd.content}</IonCol>
                            <IonCol size="3" className="attachments">
                              {cd.attachments.map((at) => (
                                <div key={at.name}>
                                  <span onClick={() => onShowAttachment(at.assetName)}>
                                    {at.name}
                                  </span>
                                </div>
                              ))}
                            </IonCol>
                            <IonCol />
                          </IonRow>
                        ))}
                        {newDescriptions.map((nd, index) => (
                          <IonRow className="table-row">
                            <IonCol className="sm">
                              {selectedCampaign.descriptions.length + index + 1}
                            </IonCol>
                            <IonCol>{moment().format('MMM DD YYYY, hh:mm A')}</IonCol>
                            <IonCol size="6">{nd.content}</IonCol>
                            <IonCol size="3" className="attachments">
                              {nd.files.map((fl) => (
                                <div key={fl.name}>
                                  <a
                                    href={URL.createObjectURL(fl)}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                  >
                                    {fl.name}
                                  </a>
                                  <IonIcon
                                    icon={closeOutline}
                                    onClick={() => {
                                      const tempFiles = nd.files.filter((f) => f.name !== fl.name);
                                      const updatedNd = { ...nd, files: tempFiles };
                                      const temp = [...newDescriptions];
                                      temp.splice(index, 1, updatedNd);
                                      setNewDescriptions([...temp]);
                                    }}
                                  />
                                </div>
                              ))}
                            </IonCol>
                            <IonCol>
                              <IonButton
                                color="danger"
                                fill="clear"
                                onClick={() => {
                                  const temp = [...newDescriptions];
                                  temp.splice(index, 1);
                                  setNewDescriptions([...temp]);
                                }}
                              >
                                Remove
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        ))}
                      </>
                    )}
                  </IonGrid>
                </IonCol>
                <IonCol size="12">
                  <IonItem lines="none" className="input">
                    <IonTextarea
                      placeholder=""
                      maxlength={500}
                      rows={3}
                      value={description}
                      onIonChange={(e) => setDescription(e.detail.value as string)}
                    />
                  </IonItem>
                </IonCol>
                <IonCol size="12">
                  <Dropzone
                    ref={dropzoneRef}
                    multiple
                    onDrop={(files, errors) => {
                      if (files.length) {
                        setNewFiles([...newFiles, ...files]);
                      }
                    }}
                  >
                    {({ getRootProps }) => (
                      <section>
                        <div className="drop-zone" {...getRootProps()}>
                          Drag & Drop Files Here
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </IonCol>
                {newFiles.length ? (
                  <IonCol size="12">
                    <IonLabel className="field-title">Attachments:</IonLabel>
                    <div className="campaign-attachments">
                      {newFiles.map((file) => (
                        <div key={file.name}>
                          <a
                            href={URL.createObjectURL(file)}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {file.name}
                          </a>
                          <IonIcon
                            icon={closeOutline}
                            onClick={() => {
                              setNewFiles(newFiles.filter((fl) => fl.name !== file.name));
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </IonCol>
                ) : null}
                <IonCol size="12" className="campaign-description-actions">
                  <IonButton
                    color="favorite"
                    disabled={!description}
                    onClick={() => {
                      setNewDescriptions([...newDescriptions, { content: description, files: newFiles }]);
                      setDescription('');
                      setNewFiles([]);
                    }}
                  >
                    Add Description
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div className="campaign-actions">
                    <IonButton color="favorite" onClick={onSubmit}>
                      {isUpdating ? <IonSpinner /> : null}
                      {!isUpdating && isEdit ? 'Update' : null}
                      {!isUpdating && !isEdit ? 'Save' : null}
                    </IonButton>
                    <IonButton
                      color="dark"
                      fill="outline"
                      onClick={() => {
                        if (selectedCampaign.id === -1) {
                          setSelectedCampaign(null);
                          toggleHeader(true);
                        } else {
                          setIsEdit(false);
                        }
                        setNewDescriptions([]);
                      }}
                    >
                      Cancel
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      ) : null }
      {selectedCampaign && selectedCampaign.id > -1 && !isEdit ? (
        <>
          <div className="marketing-section-header">
            <IonListHeader className="page-title">
              {selectedCampaign.name}
            </IonListHeader>
          </div>
          <div className="marketing-options">
            <IonSegment
              mode="ios"
              className="md"
              value={detailsTab}
              onIonChange={(e) => setDetailsTab(e.detail.value as 'info' | 'scheduled' | 'sent')}
            >
              <IonSegmentButton value="info">Information</IonSegmentButton>
              <IonSegmentButton value="scheduled">Scheduled Messages</IonSegmentButton>
              <IonSegmentButton value="sent">Sent Messages</IonSegmentButton>
            </IonSegment>
          </div>
          <div className="marketing-section-details">
            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      ASSIGNEE
                    </span>
                    <span className="field-content">
                      {selectedCampaign.assignee?.name}
                    </span>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      BUDGET COST
                    </span>
                    <span className="field-content">
                      {selectedCampaign.budget}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <br />
              <IonRow>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      NAME
                    </span>
                    <span className="field-content">
                      {selectedCampaign.name}
                    </span>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      NUMBER SENT
                    </span>
                    <span className="field-content">
                      {selectedCampaign.numberSent}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <br />
              <IonRow>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      STATUS
                    </span>
                    <span className="field-content">
                      {selectedCampaign.status === CampaignStatus.NEW ? (
                        <div className="campaign-status new">
                          <span />
                          New
                        </div>
                      ) : null}
                      {selectedCampaign.status === CampaignStatus.SCHEDULED ? (
                        <div className="campaign-status scheduled">
                          <span />
                          Scheduled
                        </div>
                      ) : null}
                      {selectedCampaign.status === CampaignStatus.ACTIVE ? (
                        <div className="campaign-status active">
                          <span />
                          Active
                        </div>
                      ) : null}
                      {selectedCampaign.status === CampaignStatus.INACTIVE ? (
                        <div className="campaign-status inactive">
                          <span />
                          Inactive
                        </div>
                      ) : null}
                      {selectedCampaign.status === CampaignStatus.CANCELLED ? (
                        <div className="campaign-status cancelled">
                          <span />
                          Cancelled
                        </div>
                      ) : null}
                      {selectedCampaign.status === CampaignStatus.COMPLETED ? (
                        <div className="campaign-status completed">
                          <span />
                          Completed
                        </div>
                      ) : null}
                    </span>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      EXPECTED RESPONSE
                    </span>
                    <span className="field-content">
                      {selectedCampaign.expectedResponse}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <br />
              <IonRow>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      SOCIAL MEDIA
                    </span>
                    <span className="field-content">
                      {selectedCampaign.socialMedia?.name}
                    </span>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      EXPECTED REVENUE
                    </span>
                    <span className="field-content">
                      {selectedCampaign.expectedRevenue}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <br />
              <IonRow>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      START DATE
                    </span>
                    <span className="field-content">
                      {selectedCampaign.startDate ? moment(selectedCampaign.startDate).format('MMMM DD, YYYY') : ''}
                    </span>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      END DATE
                    </span>
                    <span className="field-content">
                      {selectedCampaign.endDate ? moment(selectedCampaign.endDate).format('MMMM DD, YYYY') : ''}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <br />
              <IonRow>
                <IonCol size="12">
                  <div className="detail-option">
                    <span className="field-title">
                      DESCRIPTION
                    </span>
                    <IonGrid className="table descriptions">
                      <IonRow className="table-header">
                        <IonCol className="sm">NO</IonCol>
                        <IonCol>CREATED AT</IonCol>
                        <IonCol size="6">CONTENT</IonCol>
                        <IonCol size="3">ATTACHMENTS</IonCol>
                        <IonCol />
                      </IonRow>
                      {!selectedCampaign.descriptions.length ? (
                        <IonRow>
                          <IonCol size="12" className="empty-table">
                            No Descriptions
                          </IonCol>
                        </IonRow>
                      ) : (
                        <>
                          {selectedCampaign.descriptions.map((cd, index) => (
                            <IonRow className="table-row">
                              <IonCol className="sm">{index + 1}</IonCol>
                              <IonCol>{moment.utc(cd.createdAt).local().format('MMM DD YYYY, hh:mm A')}</IonCol>
                              <IonCol size="6">{cd.content}</IonCol>
                              <IonCol size="3" className="attachments">
                                {cd.attachments.map((at) => (
                                  <div key={at.name}>
                                    <span onClick={() => onShowAttachment(at.assetName)}>
                                      {at.name}
                                    </span>
                                  </div>
                                ))}
                              </IonCol>
                              <IonCol />
                            </IonRow>
                          ))}
                        </>
                      )}
                    </IonGrid>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <div className="details-actions">
            <IonButton color="favorite" onClick={() => setIsEdit(true)}>
              Edit
            </IonButton>
            <IonButton
              color="dark"
              fill="outline"
              onClick={() => {
                toggleHeader(true);
                setSelectedCampaign(null);
              }}
            >
              Back
            </IonButton>
          </div>
        </>
      ) : null}
      {!selectedCampaign ? (
        <>
          <div className="marketing-section-header">
            <IonButton color="favorite" onClick={onAddNew}>
              + Add Campaign
            </IonButton>
            <div className="marketing-section-filter">
              <IonItem className="search-input" lines="none">
              <IonInput
                  value={searchKey}
                  placeholder="Search"
                  onIonChange={(e) => {
                    const inputValue = e.detail.value ?? '';
                    setSearchKey(inputValue);
                    if (searchTimeoutRef.current) {
                      clearTimeout(searchTimeoutRef.current);
                    }
                  
                    searchTimeoutRef.current = window.setTimeout(() => {
                      fetchMore(true, inputValue);
                    }, 450);
                  }}
                />
                <IonIcon size="small" icon={searchOutline} onClick={() => fetchMore(true, searchKey)} />
                <IonIcon size="small" icon={closeOutline} onClick={() => { setSearchKey(''); fetchMore(true, ''); }} />
              </IonItem>
              <IonButton color="dark" fill="outline">
                <IonIcon icon={options} />
                Filter
              </IonButton>
            </div>
          </div>
          <div className="marketing-section-list">
            <IonGrid className="table">
              <IonRow className="table-header">
                <IonCol className="sm">NO</IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'name' })}
                  onClick={() => onChangeOrderBy('name')}
                >
                  NAME&nbsp;
                  {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'assignee' })}
                  onClick={() => onChangeOrderBy('assignee')}
                >
                  ASSIGNEE&nbsp;
                  {orderBy === 'assignee' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'socialMedia' })}
                  onClick={() => onChangeOrderBy('socialMedia')}
                >
                  SOCIAL MEDIA&nbsp;
                  {orderBy === 'socialMedia' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'start' })}
                  onClick={() => onChangeOrderBy('start')}
                >
                  START&nbsp;
                  {orderBy === 'start' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'end' })}
                  onClick={() => onChangeOrderBy('end')}
                >
                  END&nbsp;
                  {orderBy === 'end' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol
                  className={clsx('cursor-pointer', { isOrderBy: orderBy === 'status' })}
                  onClick={() => onChangeOrderBy('status')}
                >
                  STATUS&nbsp;
                  {orderBy === 'status' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
                </IonCol>
                <IonCol>ACTION</IonCol>
              </IonRow>
              {campaigns.map((cmp, index) => (
                <IonRow key={cmp.id} className="table-row">
                  <IonCol className="sm">
                    {index + 1}
                  </IonCol>
                  <IonCol>
                    {cmp.name}
                  </IonCol>
                  <IonCol>
                    {cmp?.assignee?.name}
                  </IonCol>
                  <IonCol>
                    {cmp?.socialMedia?.name}
                  </IonCol>
                  <IonCol>
                    {cmp.startDate ? moment(cmp.startDate).format('MMMM DD, YYYY') : ''}
                  </IonCol>
                  <IonCol>
                    {cmp.endDate ? moment(cmp.endDate).format('MMMM DD, YYYY') : ''}
                  </IonCol>
                  <IonCol>
                    {cmp.status === CampaignStatus.NEW ? (
                      <div className="campaign-status new">
                        New
                      </div>
                    ) : null}
                    {cmp.status === CampaignStatus.SCHEDULED ? (
                      <div className="campaign-status scheduled">
                        Scheduled
                      </div>
                    ) : null}
                    {cmp.status === CampaignStatus.ACTIVE ? (
                      <div className="campaign-status active">
                        Active
                      </div>
                    ) : null}
                    {cmp.status === CampaignStatus.INACTIVE ? (
                      <div className="campaign-status inactive">
                        Inactive
                      </div>
                    ) : null}
                    {cmp.status === CampaignStatus.CANCELLED ? (
                      <div className="campaign-status cancelled">
                        Cancelled
                      </div>
                    ) : null}
                    {cmp.status === CampaignStatus.COMPLETED ? (
                      <div className="campaign-status completed">
                        Completed
                      </div>
                    ) : null}
                  </IonCol>
                  <IonCol>
                    <Actions
                      campaign={cmp}
                      onSelect={(value) => {
                        toggleHeader(false);
                        setIsEdit(false);
                        setSelectedCampaign(value);
                      }}
                      removeCampaign={removeCampaign}
                    />
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
            {isFetchedAll ? null : (
              <IonInfiniteScroll
                onIonInfinite={(ev) => {
                  fetchMore(false);
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
                }}
              >
                <IonInfiniteScrollContent
                  loadingText="Fetching more..."
                  loadingSpinner="circles"
                />
              </IonInfiniteScroll>
            )}
          </div>
        </>
      ) : null}

      <IonAlert
        isOpen={showWarningAlert}
        onDidDismiss={(): void => setShowWarningAlert(false)}
        header="CarePlatform"
        message="Please input required information."
        buttons={['OK']}
      />

      <IonLoading isOpen={isFetching} />
    </div>
  );
};

Campaigns.propTypes = {
  socialMediaTypes: PropTypes.array.isRequired,
  toggleHeader: PropTypes.func.isRequired
};

export default connect<CampaignsProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    socialMediaTypes: state.data.socialMediaTypes
  }),
  component: React.memo(Campaigns)
});
