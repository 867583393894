import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IonContent, IonList, IonLoading } from '@ionic/react';

import { User } from '../../models';
import { fetchProviderRatings } from '../../data/dataApi';
import connect from '../../data/connect';
import RatingItem from '../../components/RatingItem';

interface StateProps {
  user: User
}

const Reviews: React.FC<StateProps> = ({
  user
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetchProviderRatings(Number(user.id))
      .then((res) => {
        setRatings(res.bookings);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, [user]);

  return (
    <IonContent id="reviews" className="page-content ion-padding">
      <IonList>
        {ratings.map((booking, index) => (
          <RatingItem key={index} booking={booking} />
        ))}
      </IonList>
      <IonLoading isOpen={isLoading} />
    </IonContent>
  );
};

Reviews.propTypes = {
  user: PropTypes.any.isRequired
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  mapDispatchToProps: {},
  component: React.memo(Reviews)
});
