import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BlockIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
    <path d="M14.1247 0H5.87468C3.09442 0 0.833008 2.26142 0.833008 5.04167V16.9583C0.833008 19.7386 3.09442 22 5.87468 22H14.1247C16.9049 22 19.1663 19.7386 19.1663 16.9583V5.04167C19.1663 2.26142 16.9049 0 14.1247 0ZM16.4163 16.9583C16.4163 18.2215 15.3878 19.25 14.1247 19.25H5.87468C4.61151 19.25 3.58301 18.2215 3.58301 16.9583V5.04167C3.58301 3.7785 4.61151 2.75 5.87468 2.75H14.1247C15.3878 2.75 16.4163 3.7785 16.4163 5.04167V16.9583ZM14.583 5.95833C14.583 6.71825 13.9679 7.33333 13.208 7.33333H11.3747C9.56701 7.30308 9.56793 4.61358 11.3747 4.58333H13.208C13.9679 4.58333 14.583 5.19842 14.583 5.95833ZM14.583 10.5417C14.583 11.3016 13.9679 11.9167 13.208 11.9167H11.3747C9.56701 11.8864 9.56793 9.19692 11.3747 9.16667H13.208C13.9679 9.16667 14.583 9.78175 14.583 10.5417ZM14.583 15.125C14.583 15.8849 13.9679 16.5 13.208 16.5H11.3747C9.56701 16.4697 9.56793 13.7803 11.3747 13.75H13.208C13.9679 13.75 14.583 14.3651 14.583 15.125ZM8.16634 5.95833C8.13518 7.766 5.44659 7.76508 5.41634 5.95833C5.44751 4.15067 8.13609 4.15158 8.16634 5.95833ZM8.16634 10.5417C8.13518 12.3493 5.44659 12.3484 5.41634 10.5417C5.44751 8.734 8.13609 8.73492 8.16634 10.5417ZM8.16634 15.125C8.13518 16.9327 5.44659 16.9318 5.41634 15.125C5.44751 13.3173 8.13609 13.3183 8.16634 15.125Z" fill={color} />
  </svg>
);

export default BlockIcon;
