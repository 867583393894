import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CalenderIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path d="M16.9583 1.83333H16.5V1.375C16.5 0.615083 15.884 0 15.125 0C14.366 0 13.75 0.615083 13.75 1.375V1.83333H8.25V1.375C8.25 0.615083 7.634 0 6.875 0C6.116 0 5.5 0.615083 5.5 1.375V1.83333H5.04167C2.26233 1.83333 0 4.09475 0 6.875V16.9583C0 19.7386 2.26233 22 5.04167 22H16.9583C19.7377 22 22 19.7386 22 16.9583V6.875C22 4.09475 19.7377 1.83333 16.9583 1.83333ZM16.9583 19.25H5.04167C3.77758 19.25 2.75 18.2215 2.75 16.9583V8.25H19.25V16.9583C19.25 18.2215 18.2224 19.25 16.9583 19.25ZM16.3579 10.9762C16.8767 11.5298 16.8483 12.4007 16.2947 12.9195L12.4667 16.5073C11.8928 17.0821 11.0871 17.4167 10.2309 17.4167C9.37475 17.4167 8.56808 17.083 7.96125 16.4762L5.68975 14.2798C5.14342 13.7518 5.12967 12.8819 5.65767 12.3356C6.18567 11.7911 7.0565 11.7764 7.60192 12.3035L9.88992 14.5163C10.0237 14.6493 10.1594 14.6667 10.23 14.6667C10.3006 14.6667 10.4372 14.6493 10.5545 14.5328L14.4146 10.9138C14.9683 10.3941 15.8372 10.4225 16.3579 10.9762Z" fill={color} />
  </svg>
);

export default CalenderIcon;
