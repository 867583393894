import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSpinner
} from '@ionic/react';
import {
  closeOutline,
  ellipsisHorizontal,
  helpCircle,
  options,
  searchOutline
} from 'ionicons/icons';
import { Range } from 'react-date-range';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import moment from 'moment';
import clsx from 'clsx';

import connect from '../../data/connect';
import {
  activateAccount,
  inactivateAccount,
  fetchClinics
} from '../../data/dataApi';
import {
  Language, ProviderType, Specialist, User, UserStatus
} from '../../models';
import {
  ArchiveIcon,
  ActiveIcon,
  BlockActionIcon,
  DetailsIcon,
  InactiveIcon,
  TrashIcon,
  UnblockActionIcon
} from '../../icons';
import Avatar from '../../components/Avatar';

interface UserActionsProps {
  user: User
  updateUser: (user: User) => void
  deleteUser: (id: number) => void
}

const UserActions: React.FC<UserActionsProps> = ({ user, updateUser, deleteUser }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [showInactiveAlert, setShowInactiveAlert] = useState(false);

  const onInactivate = () => {
    setIsLoading(true);
    inactivateAccount(user.id, false)
      .then((res) => {
        if (res.success) {
          updateUser?.({ ...user, status: UserStatus.INACTIVE });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    setIsPopoverOpen(false);
  };

  const onActivate = () => {
    setIsLoading(true);
    activateAccount(user.id)
      .then((res) => {
        if (res.success) {
          updateUser?.({ ...user, status: UserStatus.ACTIVE });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    setIsPopoverOpen(false);
  };

  const onRemove = () => {
    setIsLoading(true);
    inactivateAccount(user.id, true)
      .then((res) => {
        if (res.success) {
          deleteUser?.(user.id);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    setIsPopoverOpen(false);
  };

  const onShowDetails = () => {
    history.push(`/admin/report/clinic/${user.id}`);
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'top', 'left', 'right']}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="#daf3ef"
            arrowSize={10}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className="popover-content">
              {user.status === UserStatus.ACTIVE ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowInactiveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel>
                      <InactiveIcon />
                      Inactivate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.INACTIVE ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={onActivate}>
                    <IonLabel>
                      <ActiveIcon />
                      Activate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.BLOCKED ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <UnblockActionIcon />
                      Unblock
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <ArchiveIcon />
                      Archive
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.ARCHIVED ? (
                <>
                  <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div>
                  <div className="option" onClick={onActivate}>
                    <IonLabel>
                      <ActiveIcon />
                      Activate
                    </IonLabel>
                  </div>
                  <div className="option">
                    <IonLabel>
                      <BlockActionIcon />
                      Block
                    </IonLabel>
                  </div>
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
              {user.status === UserStatus.PENDING ? (
                <>
                  {/* <div className="option" onClick={onShowDetails}>
                    <IonLabel>
                      <DetailsIcon />
                      Details
                    </IonLabel>
                  </div> */}
                  <div className="option" onClick={() => { setShowRemoveAlert(true); setIsPopoverOpen(false); }}>
                    <IonLabel color="danger">
                      <TrashIcon />
                      Delete
                    </IonLabel>
                  </div>
                </>
              ) : null}
            </div>
          </ArrowContainer>
        )}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        {isLoading ? <IonSpinner /> : (
          <span
            className="action"
            title="More actions"
            onClick={(e) => {
              e.stopPropagation();
              setIsPopoverOpen(!isPopoverOpen);
            }}
          >
            <IonIcon icon={ellipsisHorizontal} />
          </span>
        )}
      </Popover>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={(): void => setShowRemoveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to delete this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onRemove()
          }
        ]}
      />

      <IonAlert
        isOpen={showInactiveAlert}
        onDidDismiss={(): void => setShowInactiveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to inactivate this user?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onInactivate()
          }
        ]}
      />
    </>
  );
};

UserActions.propTypes = {
  user: PropTypes.any.isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired
};

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface ClinicsViewProps {
  dateRange: Range | null
}
interface StateProps {
  providerTypes: ProviderType[]
  languages: Language[]
  specialists: Specialist[]
}

const ClinicsView: React.FC<ClinicsViewProps & StateProps> = ({
  providerTypes, languages, specialists, dateRange
}) => {
  const [segment, setSegment] = useState<'all' | 'active' | 'inactive' | 'archive' | 'blocked'>('all');
  const [searchKey, setSearchKey] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [filterParams, setFilterParams] = useState({
    country: '',
    province: '',
    city: '',
    type: '',
    bookings: {
      min: 0,
      max: 500
    }
  });
  const [tempFilterParams, setTempFilterParams] = useState({
    country: '',
    province: '',
    city: '',
    type: '',
    bookings: {
      min: 0,
      max: 500
    }
  });
  const [clinics, setClinics] = useState<User[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [showTooltipPopover, setShowTooltipPopover] = useState(false);

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const providerTypeName = (type: number) => {
    const item = providerTypes.find((pt) => pt.id === type);
    return item?.name || '';
  };

  const isSubscribedProvider = (clinic: User) => {
    if (clinic.subscription && ['active', 'paused'].includes(clinic.subscription.status)) {
      return true;
    }
    return false;
  };

  const fetchMore = (isNew: boolean) => {
    const params = {
      skip: isNew ? 0 : clinics.length,
      status: segment,
      searchKey,
      hasBookingsInfo: true,
      dateRange: dateRange ? [
        moment(dateRange.startDate).toISOString(),
        moment(dateRange.endDate).toISOString()
      ] : null,
      country: filterParams.country,
      state: filterParams.province,
      city: filterParams.city,
      bookingsCntRange: filterParams.bookings,
      orderBy,
      isDescending
    };

    setIsFetching(true);
    fetchClinics(params)
      .then((res) => {
        if (res.success) {
          if (isNew) {
            setClinics([...res.clinics]);
          } else {
            setClinics([...clinics, ...res.clinics]);
          }
          if (!res.clinics.length || res.clinics.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsFetching(false));
  };
  const onSearch = () => {
    fetchMore(true);
  };

  const onOpenFilter = () => {
    setShowFilter(true);
    setTempFilterParams(filterParams);
  };

  const onDeleteUser = (id: number) => {
    const updatedClinics = clinics.filter((c) => c.id !== id);
    setClinics([...updatedClinics]);
  };

  const onUpdateUser = (user: User) => {
    if (segment === 'all') {
      const updatedClinics = clinics.map((c) => {
        if (c.id === user.id) {
          return user;
        }
        return c;
      });
      setClinics([...updatedClinics]);
    } else {
      onDeleteUser(user.id);
    }
  };

  useEffect(() => {
    fetchMore(true);
  }, [dateRange, segment, orderBy, isDescending, filterParams]);

  useEffect(() => {
    if (!searchKey) {
      fetchMore(true);
    }
  }, [searchKey]);

  return (
    <div className="admin-report-users">
      <div className="sub-header">
        <IonSegment
          mode="ios"
          value={segment}
          onIonChange={(e) => setSegment(e.detail.value as 'all' | 'active' | 'inactive' | 'archive' | 'blocked')}
        >
          <IonSegmentButton value="all">All</IonSegmentButton>
          <IonSegmentButton value="active">Active</IonSegmentButton>
          <IonSegmentButton value="inactive">Inactive</IonSegmentButton>
          <IonSegmentButton value="archive">Archive</IonSegmentButton>
          <IonSegmentButton value="blocked">Blocked</IonSegmentButton>
        </IonSegment>
        <Popover
          isOpen={showTooltipPopover}
          positions={['bottom', 'top', 'left', 'right']}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor="#daf3ef"
              arrowSize={10}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              <div className="popover-content tooltip sm">
                <div
                  className="status-option live"
                  onClick={() => {
                    setSegment('all');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    All
                  </p>
                  <span>
                    All Clinics
                  </span>
                </div>
                <div
                  className="status-option completed"
                  onClick={() => {
                    setSegment('active');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Active
                  </p>
                  <span>
                    Clinics Who are active now
                  </span>
                </div>
                <div
                  className="status-option inactive"
                  onClick={() => {
                    setSegment('inactive');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Inactive
                  </p>
                  <span>
                    Clinics Who are inactive now
                  </span>
                </div>
                <div
                  className="status-option archive"
                  onClick={() => {
                    setSegment('archive');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Archive
                  </p>
                  <span>
                    Clinics Who are archived now
                  </span>
                </div>
                <div
                  className="status-option abandoned"
                  onClick={() => {
                    setSegment('blocked');
                    setShowTooltipPopover(false);
                  }}
                >
                  <p>
                    <span />
                    Blocked
                  </p>
                  <span>
                    Clinics Who are blocked now
                  </span>
                </div>
              </div>
            </ArrowContainer>
          )}
          onClickOutside={() => setShowTooltipPopover(false)}
        >
          <span
            className="help-icon"
            onClick={() => setShowTooltipPopover(true)}
          >
            <IonIcon color="favorite" icon={helpCircle} />
          </span>
        </Popover>
        <IonItem className="search-input" lines="none">
          <IonInput
            value={searchKey}
            placeholder="Search by name"
            onKeyPress={(e) => {
              if (e.keyCode === 13 || e.key === 'Enter') {
                onSearch();
              }
            }}
            onIonChange={(e) => {
              setSearchKey(e.detail.value ?? '');
            }}
          />
          <IonIcon size="small" icon={searchOutline} onClick={() => onSearch()} />
          <IonIcon size="small" icon={closeOutline} onClick={() => setSearchKey('')} />
        </IonItem>
        <IonButton color="dark" fill="outline" onClick={onOpenFilter}>
          <IonIcon icon={options} />
          Filter
        </IonButton>
      </div>
      <div className="user-list">
        <IonGrid>
          <IonRow className="table-header">
            <IonCol className="sm">NO</IonCol>
            <IonCol
              className={clsx('cursor-pointer sm', { isOrderBy: orderBy === 'id' })}
              onClick={() => onChangeOrderBy('id')}
            >
              ID&nbsp;
              {orderBy === 'id' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              size="2.5"
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'name' })}
              onClick={() => onChangeOrderBy('name')}
            >
              NAME AND EMAIL&nbsp;
              {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>CONTACT NO</IonCol>
            <IonCol>SPECIALITY</IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'country' })}
              onClick={() => onChangeOrderBy('country')}
            >
              COUNTRY&nbsp;
              {orderBy === 'country' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'state' })}
              onClick={() => onChangeOrderBy('state')}
            >
              PROVINCE&nbsp;
              {orderBy === 'state' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'city' })}
              onClick={() => onChangeOrderBy('city')}
            >
              CITY&nbsp;
              {orderBy === 'city' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'bookingsCnt' })}
              onClick={() => onChangeOrderBy('bookingsCnt')}
            >
              BOOKINGS&nbsp;
              {orderBy === 'bookingsCnt' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>STATUS</IonCol>
            <IonCol>ACTION</IonCol>
          </IonRow>
          {clinics.map((clinic, index) => (
            <IonRow key={clinic.id} className="table-row">
              <IonCol className="sm">{index + 1}</IonCol>
              <IonCol className="sm">{clinic.id}</IonCol>
              <IonCol size="2.5">
                <div className="user-name-email">
                  <Avatar user={clinic} />
                  <div>
                    <h4>
                      {`${clinic.name} ${clinic.lastName ?? ''}`}
                      {isSubscribedProvider(clinic) ? <span className="subscribe-badge">Subscribed</span> : null}
                    </h4>
                    <p>{clinic.email}</p>
                  </div>
                </div>
              </IonCol>
              <IonCol>{clinic.phone}</IonCol>
              <IonCol>{providerTypeName(clinic.type)}</IonCol>
              <IonCol>{clinic.locations?.[0]?.country}</IonCol>
              <IonCol>{clinic.locations?.[0]?.state}</IonCol>
              <IonCol>{clinic.locations?.[0]?.city}</IonCol>
              <IonCol>{clinic.bookingsCnt}</IonCol>
              <IonCol>
                {clinic.status === UserStatus.ACTIVE ? (
                  <div className="status active">Active</div>
                ) : null}
                {clinic.status === UserStatus.INACTIVE ? (
                  <div className="status inactive">Inactive</div>
                ) : null}
                {clinic.status === UserStatus.BLOCKED ? (
                  <div className="status blocked">Blocked</div>
                ) : null}
              </IonCol>
              <IonCol>
                <UserActions user={clinic} updateUser={onUpdateUser} deleteUser={onDeleteUser} />
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </div>
    </div>
  );
};

ClinicsView.defaultProps = {
  dateRange: null
};

ClinicsView.propTypes = {
  dateRange: PropTypes.any,
  providerTypes: PropTypes.array.isRequired,
  specialists: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired
};

export default connect<ClinicsViewProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes,
    languages: state.data.allLanguages,
    specialists: state.data.allSpecialists
  }),
  component: React.memo(ClinicsView)
});
