import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonListHeader,
  IonRow
} from '@ionic/react';
import {
  calendarOutline, closeOutline, removeOutline, searchOutline
} from 'ionicons/icons';
import { DateRange as DateRangePicker, Range } from 'react-date-range';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import moment from 'moment';
import clsx from 'clsx';

import connect from '../../data/connect';
import {
  fetchClinicMembers
} from '../../data/dataApi';
import Avatar from '../../components/Avatar';

import './AdminClinicMembers.scss';
import { ProviderType, User, UserStatus } from '../../models';

interface StateProps {
  providerTypes: ProviderType[]
}

const AdminCinicMembers: React.FC<StateProps> = ({ providerTypes }) => {
  const [dateRange, setDateRange] = useState<Range | null>(null);
  const [tempDateRange, setTempDateRange] = useState<Range | null>(null);
  const [showCalendarPopover, setShowCalendarPopover] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [members, setMembers] = useState<User[]>([]);
  const [totalRevenue, setTotalRevenue] = useState(0);

  const selectPeriod = (type: string) => {
    if (type === 'today') {
      setTempDateRange({
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        key: 'selection'
      });
    }
    if (type === 'week') {
      setTempDateRange({
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastMonth') {
      setTempDateRange({
        startDate: moment().subtract(1, 'months').startOf('month').toDate(),
        endDate: moment().subtract(1, 'months').endOf('month').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastQuarter') {
      setTempDateRange({
        startDate: moment().subtract(3, 'months').startOf('quarter').toDate(),
        endDate: moment().subtract(3, 'months').endOf('quarter').toDate(),
        key: 'selection'
      });
    }
  };

  const fetchMembers = () => {
    setIsFetching(true);
    const params = {
      dateRange: dateRange ? [
        moment(dateRange.startDate).toISOString(),
        moment(dateRange.endDate).toISOString()
      ] : null,
      orderBy,
      isDescending,
      searchKey,
      hasRevenueInfo: true
    };
    fetchClinicMembers(-1, params)
      .then((res) => {
        if (res.success) {
          setMembers([...res.members]);
          setTotalRevenue(res.totalRevenue);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsFetching(false));
  };

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const onSearch = (str: string) => {
    setIsFetching(true);
    const params = {
      dateRange: dateRange ? [
        moment(dateRange.startDate).toISOString(),
        moment(dateRange.endDate).toISOString()
      ] : null,
      orderBy,
      isDescending,
      searchKey: str,
      hasRevenueInfo: true
    };
    fetchClinicMembers(-1, params)
      .then((res) => {
        if (res.success) {
          setMembers([...res.members]);
          setTotalRevenue(res.totalRevenue);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    fetchMembers();
  }, [dateRange, orderBy, isDescending]);

  return (
    <IonContent id="admin-clinic-members" className="page-content ion-padding">
      <div className="admin-clinic-members-header">
        <IonListHeader className="page-header">Clinic Members</IonListHeader>
        <Popover
          isOpen={showCalendarPopover}
          positions={['bottom', 'top', 'left', 'right']}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor="#daf3ef"
              arrowSize={10}
              className="popover-arrow-container"
              arrowClassName="popover-arrow"
            >
              <div className="popover-content calendar">
                <div className="panel">
                  <div
                    className={clsx('option', { selected: !tempDateRange })}
                    onClick={() => setTempDateRange(null)}
                  >
                    All periods
                  </div>
                  <div
                    className={clsx('option', {
                      selected: tempDateRange && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment(tempDateRange.endDate).format('YYYY-MM-DD')
                    })}
                    onClick={() => selectPeriod('today')}
                  >
                    Today
                  </div>
                  <div
                    className={clsx('option', {
                      selected: tempDateRange
                        && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().startOf('week').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().endOf('week').format('YYYY-MM-DD')
                    })}
                    onClick={() => selectPeriod('week')}
                  >
                    This week
                  </div>
                  <div
                    className={clsx('option', {
                      selected: tempDateRange
                        && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                    })}
                    onClick={() => selectPeriod('lastMonth')}
                  >
                    Last month
                  </div>
                  <div
                    className={clsx('option', {
                      selected: tempDateRange
                        && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').startOf('quarter').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').endOf('quarter').format('YYYY-MM-DD')
                    })}
                    onClick={() => selectPeriod('lastQuarter')}
                  >
                    Last quarter
                  </div>
                  <IonButton
                    color="favorite"
                    onClick={() => {
                      setDateRange(tempDateRange);
                      setShowCalendarPopover(false);
                    }}
                  >
                    Apply
                  </IonButton>
                </div>
                <div className="calendar">
                  <DateRangePicker
                    editableDateInputs
                    onChange={(item) => {
                      setTempDateRange({
                        startDate: moment(item.selection.startDate).toDate(),
                        endDate: moment(item.selection.endDate).add(1, 'days').subtract(1, 'milliseconds').toDate(),
                        key: 'selection'
                      });
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={tempDateRange ? [tempDateRange] : [
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: 'selection'
                      },
                    ]}
                    rangeColors={['#000']}
                  />
                </div>
              </div>
            </ArrowContainer>
          )}
          onClickOutside={() => setShowCalendarPopover(false)}
        >
          <IonItem lines="none" className="date-range" onClick={() => setShowCalendarPopover(true)}>
            <div>
              {dateRange ? moment(dateRange.startDate).format('DD MMMM, YYYY') : 'Start Date'}
            </div>
            <IonIcon color="dark" icon={removeOutline} />
            <div>
              {dateRange ? moment(dateRange.endDate).format('DD MMMM, YYYY') : 'End Date'}
            </div>
            <IonIcon slot="end" icon={calendarOutline} />
          </IonItem>
        </Popover>
      </div>
      <div className="admin-clinic-members-filter">
        <IonItem className="total-revenue" lines="none">
          {`Total Revenue - $${Number(totalRevenue.toFixed(0)) / 100}`}
        </IonItem>
        <IonItem className="search-input" lines="none">
          <IonInput
            value={searchKey}
            placeholder="Search here"
            onKeyPress={(e) => {
              if (e.keyCode === 13 || e.key === 'Enter') {
                onSearch(searchKey);
              }
            }}
            onIonChange={(e) => {
              setSearchKey(e.detail.value ?? '');
            }}
          />
          <IonIcon size="small" icon={searchOutline} onClick={() => onSearch(searchKey)} />
          <IonIcon size="small" icon={closeOutline} onClick={() => { setSearchKey(''); onSearch(''); }} />
        </IonItem>
      </div>

      <div className="cllinic-members-list">
        <IonGrid>
          <IonRow className="table-header">
            <IonCol className="sm">
              NO
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer sm', { isOrderBy: orderBy === 'id' })}
              onClick={() => onChangeOrderBy('id')}
            >
              ID
              {orderBy === 'id' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'clinic' })}
              onClick={() => onChangeOrderBy('clinic')}
            >
              CLINIC NAME
              {orderBy === 'clinic' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol
              size="3"
              className={clsx('cursor-pointer', { isOrderBy: orderBy === 'name' })}
              onClick={() => onChangeOrderBy('name')}
            >
              NAME AND EMAIL
              {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
            </IonCol>
            <IonCol>
              CONTACT NO
            </IonCol>
            <IonCol>
              PROVIDER SERVICE
            </IonCol>
            <IonCol>
              ENROLLED DATE
            </IonCol>
            <IonCol>
              TOTAL REVENUE
            </IonCol>
            <IonCol>
              STATUS
            </IonCol>
            <IonCol>
              ACTIONS
            </IonCol>
          </IonRow>
          {members.map((member, index) => (
            <IonRow key={member.id}>
              <IonCol className="sm">
                {index + 1}
              </IonCol>
              <IonCol className="sm">
                {member.id}
              </IonCol>
              <IonCol>
                {member.clinicAdmin?.name}
              </IonCol>
              <IonCol size="3">
                <div className="user-name-email">
                  <Avatar user={member} />
                  <div>
                    <h4>{`${member.name} ${member.lastName ?? ''}`}</h4>
                    <p>{member.email}</p>
                  </div>
                </div>
              </IonCol>
              <IonCol>
                {member.phone}
              </IonCol>
              <IonCol>
                {providerTypes.find((pt) => pt.id === member.type)?.name}
              </IonCol>
              <IonCol>
                {moment.utc(member.createdAt).local().format('DD MMM YYYY, hh:mm A')}
              </IonCol>
              <IonCol>
                {`$${Number(member.totalChargeAmount?.toFixed(0)) / 100}`}
              </IonCol>
              <IonCol>
                {member.status === UserStatus.ACTIVE ? (
                  <div className="status active">Active</div>
                ) : null}
                {member.status === UserStatus.INACTIVE ? (
                  <div className="status inactive">Inactive</div>
                ) : null}
                {member.status === UserStatus.BLOCKED ? (
                  <div className="status blocked">Blocked</div>
                ) : null}
              </IonCol>
              <IonCol>
                ACTIONS
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </div>
    </IonContent>
  );
};

AdminCinicMembers.propTypes = {
  providerTypes: PropTypes.array.isRequired
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes
  }),
  component: React.memo(AdminCinicMembers)
});
