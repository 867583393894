import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LandIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10.4167 17.4792V16.4083C10.4167 15.4823 10.0488 14.5942 9.39399 13.9394C8.73917 13.2845 7.85105 12.9167 6.925 12.9167H6.91667C6.57261 12.9167 6.23192 12.8489 5.91406 12.7172C5.59621 12.5855 5.30741 12.3925 5.06417 12.1492L2.52 9.60333C2.58796 8.27946 3.00618 6.99736 3.73191 5.88804C4.45764 4.77873 5.46487 3.88197 6.65068 3.2894C7.83648 2.69684 9.15835 2.42971 10.4812 2.51532C11.8041 2.60093 13.0805 3.03621 14.18 3.77667C13.9593 3.93861 13.6929 4.02616 13.4192 4.02667H12.2242C12.0538 4.02667 11.8851 4.06027 11.7277 4.12555C11.5703 4.19082 11.4274 4.2865 11.307 4.40709C11.1866 4.52769 11.0912 4.67083 11.0263 4.82834C10.9613 4.98585 10.928 5.15462 10.9283 5.325V6.19333C10.9283 6.53701 10.7918 6.86661 10.5488 7.10963C10.3058 7.35264 9.97618 7.48917 9.6325 7.48917C9.46205 7.48873 9.29319 7.52192 9.13559 7.58685C8.97798 7.65177 8.83474 7.74715 8.71406 7.86753C8.59337 7.9879 8.49763 8.1309 8.43229 8.28834C8.36697 8.44577 8.33334 8.61455 8.33334 8.785V9.65333C8.33334 9.99701 8.46986 10.3266 8.71288 10.5696C8.95589 10.8126 9.28549 10.9492 9.62917 10.9492H13.0925C13.4362 10.9492 13.7658 11.0857 14.0088 11.3287C14.2518 11.5717 14.3883 11.9013 14.3883 12.245V13.5117C14.3883 13.6819 14.4219 13.8505 14.4871 14.0078C14.5523 14.1651 14.6479 14.308 14.7683 14.4283L15.4625 15.1225C14.1472 16.5291 12.3395 17.3734 10.4167 17.4792Z" fill={color} />
  </svg>
);

export default LandIcon;
