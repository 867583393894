import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonContent,
  IonList,
  IonButton,
  IonLoading,
  IonListHeader,
  IonAlert,
  IonModal,
  IonLabel,
  IonDatetime,
  IonItem
} from '@ionic/react';
import moment from 'moment';

import connect from '../../data/connect';
import { User, UserRole } from '../../models';
import { dispatchUpdateUser } from '../../data/store/auth/auth.actions';
import {
  fetchAccountVerificationLink,
  updateCreditCard,
  changeDefaultBankAccount,
  changeDefaultCard,
  deleteStripeCard,
  deleteProviderBankAccount
} from '../../data/dataApi';
import CreditCardItem from '../../components/CreditCardItem';
import BankAccountItem from '../../components/BankAccountItem';
import './Wallet.scss';

interface StateProps {
  loading: boolean
  user: User
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}

type WalletProps = StateProps & DispatchProps;

const Wallet: React.FC<WalletProps> = ({
  loading,
  user,
  updateUser
}) => {
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [selectedSourceId, setSelectedSourceId] = useState(0);
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [cardExpireDate, setCardExpireDate] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [showUpdateSuccessAlert, setShowUpdateSuccessAlert] = useState(false);

  const onGetVerificationLink = (): void => {
    fetchAccountVerificationLink()
      .then((res) => {
        if (res.link) {
          window.location.href = res.link.url;
        }
      })
      .catch((error) => console.log(error));
  };

  const onDeleteSource = async () => {
    try {
      if (selectedSourceId) {
        const { sources } = user;
        if (user.role === UserRole.CUSTOMER) {
          await deleteStripeCard(selectedSourceId);
        } else {
          await deleteProviderBankAccount(selectedSourceId);
        }
        updateUser({ ...user, sources: sources.filter((source) => source.id !== selectedSourceId) });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setDefaultPaymentSource = async (id: number) => {
    try {
      if (user.role === UserRole.CUSTOMER) {
        await changeDefaultCard(id);
      } else {
        await changeDefaultBankAccount(id);
      }
      const { sources } = user;
      updateUser({
        ...user,
        sources: sources.map((source) => ({ ...source, default: source.id === id }))
      });
    } catch (error) {
      console.log(error);
    }
  };

  const confirmUpdate = () => {
    setIsUpdating(true);
    const data = cardExpireDate.split('/');
    updateCreditCard(selectedSourceId, {
      data: {
        exp_month: data[0],
        exp_year: data[1]
      }
    })
      .then((res) => {
        if (res.success) {
          setShowUpdateSuccessAlert(true);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsUpdating(false));
  };

  useEffect(() => {
    if (user.sources) {
      const defaultSource = user.sources.find((source) => source.status === 'active' && source.default);
      const activeSoures = user.sources.filter((source) => source.status === 'active');
      if (activeSoures.length === 1 && !defaultSource) {
        setDefaultPaymentSource(activeSoures[0].id)
          .catch((error) => console.log(error));
      }
    }
  }, [user.sources]);

  return (
    <IonContent id="wallet" className="page-content ion-padding">
      <div className="wallet-header">
        <IonListHeader className="page-header">Wallet</IonListHeader>
        {user.role !== UserRole.CUSTOMER && (
          (user.method && user.method.status === 'pending') || (!user.method && user.sources && user.sources.length > 0)
        ) ? (
          <IonButton
            color="favorite"
            expand="block"
            fill="outline"
            onClick={(): void => onGetVerificationLink()}
          >
            Verify account
          </IonButton>
          ) : null}
        <IonButton
          color="favorite"
          routerLink={`/${user.role}/new-payment-source`}
        >
          + Add Payment
        </IonButton>
      </div>
      <IonList>
        {user.sources
          .filter((source) => source.status === 'active')
          .map((source) => (user.role === UserRole.CUSTOMER ? (
            <CreditCardItem
              key={source.id}
              isEnableDelete={user.sources.filter((item) => item.status === 'active').length > 1}
              source={source}
              onDelete={(id): void => {
                setSelectedSourceId(id);
                setShowConfirmAlert(true);
              }}
              onEdit={(id): void => {
                setSelectedSourceId(id);
                setCardExpireDate('');
                setShowEditCardModal(true);
              }}
              onSelectDefault={async (id) => {
                await setDefaultPaymentSource(id);
              }}
            />
          ) : (
            <BankAccountItem
              key={source.id}
              isEnableDelete={user.sources.filter((item) => item.status === 'active').length > 1}
              source={source}
              onDelete={(id): void => {
                setSelectedSourceId(id);
                setShowConfirmAlert(true);
              }}
              onSelectDefault={async (id) => {
                await setDefaultPaymentSource(id);
              }}
            />
          )))}
      </IonList>

      <IonAlert
        isOpen={showConfirmAlert}
        onDidDismiss={(): void => setShowConfirmAlert(false)}
        header="Confirm"
        message={`Are you sure you wish to remove this ${user.role === UserRole.CUSTOMER ? 'credit card' : 'bank account'} from your wallet?`}
        buttons={[
          'No',
          {
            text: 'Yes',
            handler: async () => { await onDeleteSource(); }
          }
        ]}
      />

      <IonAlert
        isOpen={showUpdateSuccessAlert}
        onDidDismiss={(): void => {
          setShowUpdateSuccessAlert(false);
          setShowEditCardModal(false);
        }}
        header="Confirm"
        message="Your credit card has been updated successfully"
        buttons={['OK']}
      />

      <IonModal
        cssClass="edit-credit-card-modal"
        isOpen={showEditCardModal}
        onDidDismiss={() => setShowEditCardModal(false)}
      >
        <IonLabel>Edit Credit Card</IonLabel>
        <IonList>
          <IonItem lines="none">
            <IonLabel>Expiration Date</IonLabel>
            <IonDatetime
              max={moment().add(10, 'year').format('YYYY-MM-DD')}
              min={moment().format('YYYY-MM-DD')}
              displayFormat="MM/YYYY"
              pickerFormat="MM/YYYY"
              placeholder="MM/YYYY"
              // value={cardExpireDate}
              onIonChange={(event): void => setCardExpireDate(moment(event.detail.value || '').format('MM/YYYY'))}
            />
          </IonItem>
        </IonList>
        <div className="actions">
          <IonButton color="favorite" disabled={!cardExpireDate} onClick={confirmUpdate}>
            Confirm
          </IonButton>
          <IonButton color="danger" onClick={() => setShowEditCardModal(false)}>
            Cancel
          </IonButton>
        </div>
      </IonModal>

      <IonLoading isOpen={loading || isUpdating} />
    </IonContent>
  );
};

Wallet.propTypes = {
  loading: PropTypes.bool.isRequired,
  user: PropTypes.any.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    loading: state.data.loading || state.auth.loading,
    user: state.auth.user
  }),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(Wallet)
});
