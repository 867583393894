import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IonContent,
  IonList,
  IonListHeader,
  IonImg,
  IonLabel,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLoading
} from '@ionic/react';

import { User } from '../../models';
import { fetchClinics } from '../../data/dataApi';
import UserItem from '../../components/UserItem';

import emptyImg from '../../assets/empty.png';
import './ClinicsList.scss';

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

const Empty = () => (
  <div className="empty">
    <div>
      <IonImg src={emptyImg} alt="empty" />
      <IonLabel className="title">Nothing found</IonLabel>
    </div>
  </div>
);

const ClinicsList = () => {
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const [clinics, setClinics] = useState<User[]>([]);
  const [isFetchedAll, setIsFetchedAll] = useState(false);

  const fetchMore = () => {
    setIsFetching(true);
    const params = {
      skip: clinics.length
    };
    fetchClinics(params)
      .then((res) => {
        if (res.success) {
          setClinics([...clinics, ...res.clinics]);
          if (!res.clinics.length || res.clinics.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    fetchMore();
  }, []);

  return (
    <IonContent id="clinics-list" className="page-content ion-padding">
      <IonListHeader className="page-header">Clinics Directory</IonListHeader>
      {clinics && clinics.length ? (
        <IonList>
          {clinics.map((clinic) => (
            <UserItem
              key={clinic.id}
              user={clinic}
              addFavoriteProvider={() => {}}
              deleteFavoriteProvider={() => {}}
              onClick={() => history.push(`/admin/clinic/${clinic.id}`)}
            />
          ))}
        </IonList>
      ) : null}
      {!clinics.length && !isFetching ? <Empty /> : null}
      {isFetchedAll ? null : (
        <IonInfiniteScroll
          onIonInfinite={(ev) => {
            fetchMore();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
          }}
        >
          <IonInfiniteScrollContent
            loadingText="Fetching more..."
            loadingSpinner="circles"
          />
        </IonInfiniteScroll>
      )}
      <IonLoading isOpen={isFetching && !clinics.length} />
    </IonContent>
  );
};

export default React.memo(ClinicsList);
