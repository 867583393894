export const BaseURL = 'https://api.careplatform.ca/api';
// export const BaseURL = 'http://localhost:2017/api';
export const SocketURL = 'https://api.careplatform.ca';
// export const SocketURL = 'http://localhost:2017';

export const StripePK = 'pk_test_51MRJzIL4GfLmVlp59UDlDWwcNlWgTjlXCcuhvzGMeAsoVrMOESkZdIwYPsTMdKTbQHzu7esuTZmAVKcviFkiU8gp00zK5Z5wua';
export const TwilioSyncListName = process.env.NODE_ENV === 'development' ? 'clients' : 'users';
export const IDLE_TIMEOUT = 1000 * 60 * 15;

export const SOCKET_KEYS = {
  greeting: 'VH-greeting',
  pending: 'VH-new-booking',
  accepted: 'VH-accept-booking',
  'cancelled-customer': 'VH-cancel-booking-customer',
  'cancelled-provider': 'VH-cancel-booking-provider',
  'rejected-customer': 'VH-reject-booking-customer',
  'rejected-provider': 'VH-reject-booking-provider',
  rejected: 'VH-reject-booking',
  completed: 'VH-complete-booking',
  incomplete: 'VH-incomplete-booking',
  'meeting-customer': 'VH-meeting-customer',
  'meeting-provider': 'VH-meeting-provider',
  'meeting-invite': 'VH-meeting-invite',
  'reset-password': 'VH-password-change',
  'approved-identity': 'VH-approve-identity',
  'rejected-identity': 'VH-reject-identity',
  'approved-license': 'VH-approve-license',
  'rejected-license': 'VH-reject-license',
  'submitted-identity': 'VH-submit-identity',
  'submitted-license': 'VH-submit-license',
  'request-extend': 'VH-request-extend',
  'approved-extend': 'VH-approved-extend',
  'rejected-extend': 'VH-rejected-extend'
};

export const STATUS_KEYS = {
  AVAILABLE: 'available',
  AWAY: 'away',
  CALL: 'call',
  OFFLINE: 'offline'
};
