import React, { useMemo, useState } from 'react';
import {
  IonContent,
  IonListHeader,
  IonSegment,
  IonSegmentButton
} from '@ionic/react';

import Campaigns from './Campaigns';
import SMSList from './SMSList';
import MailingList from './MailingList';
import Coupons from './Coupons';
import CRM from './CRM';
import Templates from './Templates';

import './AdminMarketing.scss';

const AdminMarketing = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [tab, setTab] = useState<'crm' | 'campaign' | 'smsList' | 'mailingList' | 'templates' | 'coupons' | 'configurations'>('crm');

  const pageTitle = useMemo(() => {
    switch (tab) {
      case 'crm':
        return 'CRM';
      case 'campaign':
        return 'All Campaigns';
      case 'smsList':
        return 'SMS List';
      case 'mailingList':
        return 'Mailing List';
      case 'templates':
        return 'Templates';
      case 'coupons':
        return 'Coupons';
      case 'configurations':
        return 'Configurations';
      default:
        return '';
    }
  }, [tab]);

  return (
    <IonContent id="admin-marketing" className="page-content ion-padding">
      {showHeader ? (
        <>
          <IonListHeader className="page-header">{pageTitle}</IonListHeader>
          <div className="marketing-options">
            <IonSegment
              mode="ios"
              value={tab}
              onIonChange={(e) => setTab(e.detail.value as 'crm' | 'campaign' | 'smsList' | 'mailingList' | 'templates' | 'coupons' | 'configurations')}
            >
              <IonSegmentButton value="crm">CRM</IonSegmentButton>
              <IonSegmentButton value="campaign">Campaigns</IonSegmentButton>
              <IonSegmentButton value="smsList">SMS List</IonSegmentButton>
              <IonSegmentButton value="mailingList">Mailing List</IonSegmentButton>
              <IonSegmentButton value="templates">Templates</IonSegmentButton>
              <IonSegmentButton value="coupons">Coupons</IonSegmentButton>
              <IonSegmentButton value="configurations">Configurations</IonSegmentButton>
            </IonSegment>
          </div>
        </>
      ) : null}
      {tab === 'crm' ? <CRM toggleHeader={setShowHeader} /> : null}
      {tab === 'campaign' ? <Campaigns toggleHeader={setShowHeader} /> : null}
      {tab === 'smsList' ? <SMSList toggleHeader={setShowHeader} /> : null}
    {tab === 'mailingList' ? <MailingList toggleHeader={setShowHeader} /> : null}
      {tab === 'templates' ? <Templates toggleHeader={setShowHeader} /> : null}
      {tab === 'coupons' ? <Coupons toggleHeader={setShowHeader} /> : null}
    </IonContent>
  );
};

export default React.memo(AdminMarketing);
