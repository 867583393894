import React from 'react';

interface Props {
  color?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const SphereIcon = ({ color = '#000' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM17.4508 9.16667H14.5525C14.3635 6.99574 13.7132 4.89019 12.645 2.99083C13.9363 3.47916 15.0679 4.31392 15.9158 5.40342C16.7636 6.49293 17.2947 7.79502 17.4508 9.16667ZM8.07084 11.6667H11.9292C11.601 13.4123 10.9465 15.0804 10 16.5833C9.05332 15.0804 8.39881 13.4123 8.07084 11.6667ZM7.96084 9.16667C8.19422 7.11982 8.89165 5.15322 10 3.41667C11.1085 5.15316 11.8059 7.11978 12.0392 9.16667H7.96084ZM7.355 2.99083C6.28681 4.89019 5.63648 6.99574 5.4475 9.16667H2.54917C2.70529 7.79502 3.23643 6.49293 4.08425 5.40342C4.93206 4.31392 6.06375 3.47916 7.355 2.99083ZM2.69334 11.6667H5.53417C5.81457 13.5426 6.43141 15.3525 7.355 17.0092C6.19845 16.5709 5.16791 15.854 4.35474 14.922C3.54158 13.9901 2.97091 12.8719 2.69334 11.6667ZM12.645 17.0092C13.569 15.3526 14.1858 13.5427 14.4658 11.6667H17.3067C17.0291 12.8719 16.4584 13.9901 15.6453 14.922C14.8321 15.854 13.8016 16.5709 12.645 17.0092Z" fill={color} />
  </svg>
);

export default SphereIcon;
