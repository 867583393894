import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { User, UserRole } from '../../models';

import './ProviderMapView.scss';

interface ProviderMapViewProps {
  users: User[]
  center: any
  onSelectUser?: (role: string, id: number) => void
  hasSearchbar?: boolean
}

const ProviderMapView: React.FC<ProviderMapViewProps> = ({
  center,
  users,
  onSelectUser,
  hasSearchbar = true,
}) => {
  const mapEle = useRef<HTMLDivElement>(null);
  const pacEle = useRef<HTMLInputElement>(null);
  const map = useRef<google.maps.Map>();
  const pac = useRef<google.maps.places.Autocomplete>();

  const addMarkers = useCallback((): void => {
    users.forEach((user) => {
      if (user.role === UserRole.PROVIDER) {
        user.locations.forEach((markerData) => {
          const infoWindow = new google.maps.InfoWindow({
            content: `
              <h4>${user.name}</h4>
              <span>${markerData.address}</span>
              <button id="provider-${user.id}">Go to details</button>
            `
          });

          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(markerData.lat, markerData.lng),
            map: map.current,
            title: markerData.address
          });

          google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
            const detail = document.getElementById(`provider-${user.id}`);
            if (detail) {
              detail.addEventListener(
                'click',
                () => onSelectUser && onSelectUser(UserRole.PROVIDER, user.id)
              );
            }
          });

          marker.addListener('click', () => onSelectUser && onSelectUser(UserRole.PROVIDER, user.id));
          marker.addListener('mouseover', () => {
            infoWindow.open(map.current, marker);
          });
          marker.addListener('mouseout', () => {
            infoWindow.close();
          });
        });
      } else if (user.role === UserRole.CLINIC_ADMIN) {
        user.locations.forEach((markerData) => {
          const infoWindow = new google.maps.InfoWindow({
            content: `
              <h4>${user.title}</h4>
              <span>${markerData.address}</span>
              <button id="clinic-${user.id}">Go to details</button>
            `
          });

          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(markerData.lat, markerData.lng),
            map: map.current,
            title: markerData.address
          });

          google.maps.event.addListenerOnce(infoWindow, 'domready', () => {
            const detail = document.getElementById(`clinic-${user.id}`);
            if (detail) {
              detail.addEventListener(
                'click',
                () => onSelectUser && onSelectUser(UserRole.CLINIC_ADMIN, user.id)
              );
            }
          });

          marker.addListener('click', () => onSelectUser && onSelectUser(UserRole.CLINIC_ADMIN, user.id));
          marker.addListener('mouseover', () => {
            infoWindow.open(map.current, marker);
          });
          marker.addListener('mouseout', () => {
            infoWindow.close();
          });
        });
      }
    });
  }, [users, onSelectUser]);

  useEffect(() => {
    if (!mapEle.current || !pacEle.current) {
      return;
    }
    map.current = new google.maps.Map<HTMLDivElement>(mapEle.current, {
      center: {
        lat: center.lat || 37.01902,
        lng: center.lng || -95.7129
      },
      streetViewControl: false,
      zoom: center.zoom || 3
    });

    map.current.controls[google.maps.ControlPosition.TOP_RIGHT].push(
      pacEle.current
    );
    pac.current = new google.maps.places.Autocomplete(pacEle.current);
    pac.current.setFields([
      'address_components',
      'formatted_address',
      'geometry'
    ]);

    addMarkers();

    google.maps.event.addListenerOnce(map.current, 'idle', () => {
      if (mapEle.current) {
        mapEle.current.classList.add('show-map');
      }
    });

    google.maps.event.addListenerOnce(pac.current, 'place_changed', () => {
      if (pac.current && map.current) {
        const place = pac.current.getPlace();
        if (place.geometry) {
          map.current.setCenter(place.geometry.location);
          map.current.setZoom(9);
        }
      }
    });
  }, [addMarkers, center]);

  return (
    <>
      <div className="pac-container">
        <input
          id="pac-input"
          type="text"
          placeholder="Enter a location"
          ref={pacEle}
          className={hasSearchbar ? 'shown' : 'hidden'}
        />
      </div>
      <div className="map-container">
        <div ref={mapEle} className="map-canvas" />
      </div>
    </>
  );
};

ProviderMapView.defaultProps = {
  users: [],
  onSelectUser: (): void => {}
};

ProviderMapView.propTypes = {
  users: PropTypes.any,
  center: PropTypes.any.isRequired,
  onSelectUser: PropTypes.func
};

export default ProviderMapView;
