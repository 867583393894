import React, { useState, useEffect, useRef } from 'react';
import { IonButton } from '@ionic/react';

import './Signature.scss';

const Signature = () => {
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;
    // canvas.height= canvas.width *1.3;
    // canvas.width=700;
    // canvas.height=500;

    canvas.height = canvas.width * 1.5;
    const context = canvas.getContext('2d');
    if (!context) return;
    context.lineCap = 'round';
    context.strokeStyle = 'black';
    context.fillStyle = 'white';
    context.lineWidth = 5;
    context.fillRect(0, 0, canvas.width, canvas.height);
    contextRef.current = context;
  }, []);

  const startDrawing = (e: any) => {
    let mouseX = (e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0].pageX
      : e.nativeEvent.offsetX;
    let mouseY = (e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0].pageY
      : e.nativeEvent.offsetY;
    if (!canvasRef.current) return;
    mouseX -= canvasRef.current.offsetLeft;
    mouseY -= canvasRef.current.offsetTop;

    if (!contextRef.current) return;
    contextRef.current.beginPath();
    contextRef.current.moveTo(mouseX, mouseY);
    contextRef.current.lineTo(mouseX, mouseY);
    contextRef.current.stroke();

    setIsDrawing(true);

    /*
const {offsetX,offsetY} =( e as MouseEvent).nativeEvent;
if(!contextRef.current) return;
contextRef.current.beginPath();
contextRef.current.moveTo(offsetX,offsetY);
contextRef.current.lineTo(offsetX,offsetY);
contextRef.current.stroke(); */
  };

  const draw = (e: any) => {
    if (!isDrawing) return;
    if (!contextRef.current) return;

    let mouseX = (e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0].pageX
      : e.nativeEvent.offsetX;
    let mouseY = (e as TouchEvent).changedTouches
      ? (e as TouchEvent).changedTouches[0].pageY
      : e.nativeEvent.offsetY;
    if (!canvasRef.current) return;
    mouseX -= canvasRef.current.offsetLeft;
    mouseY -= canvasRef.current.offsetTop;

    contextRef.current.lineTo(mouseX, mouseY);
    contextRef.current.stroke();
    /*
  if(!isDrawing) return;
  if(!contextRef.current) return;
  const {offsetX,offsetY}  =nativeEvent;
  contextRef.current.lineTo(offsetX,offsetY);
  contextRef.current.stroke(); */
  };

  const stopDrawing = () => {
    if (!contextRef.current) return;
    contextRef.current.closePath();
    setIsDrawing(false);
  };
  const clearDrawing = () => {
    if (!contextRef.current) return;
    setIsDrawing(false);
    if (!canvasRef.current) return;

    const context = canvasRef.current.getContext('2d');
    if (!context) return;
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };

  const saveDrawing = async () => {
    if (!contextRef.current) return;
    if (!canvasRef.current) return;

    const image = canvasRef.current.toDataURL('image/jpeg');
    const blob = await (await fetch(image)).blob();
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;

    link.download = `${getRandomFileName()}.jpeg`;

    link.click();

    // getBase64(blob);
  };
  const getRandomFileName = () => {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, '');
    const random = `${Math.random()}`.substring(2, 8);
    return timestamp + random;
  };

  const getBase64 = (file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // console.log(reader.result)
      return reader.result;
    };
  };

  return (
    <>
      <canvas
        className="canvas-container"
        ref={canvasRef}
        style={{ border: '1px solid #000' }}
        onTouchStart={startDrawing}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseLeave={stopDrawing}
        onTouchMove={draw}
        onTouchCancel={stopDrawing}
        onTouchEnd={stopDrawing}
      />

      <IonButton color="danger" onClick={clearDrawing}>
        {' '}
        Clear
        {' '}
      </IonButton>
      <IonButton onClick={saveDrawing}>Save </IonButton>
    </>
  );
};

export default Signature;
