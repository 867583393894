/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createRef, useEffect, useMemo, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonLoading,
  IonRow,
  IonSpinner,
  IonTextarea
} from '@ionic/react';
import {
  arrowBackOutline,
  chatbubbleOutline,
  closeOutline,
  ellipsisHorizontal,
  mailOutline,
  options,
  searchOutline
} from 'ionicons/icons';
import { Column } from 'react-table';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import { BsSortUpAlt, BsSortDownAlt } from 'react-icons/bs';
import clsx from 'clsx';
import moment from 'moment';

import {
  fetchTemplates,
  addTemplate,
  updateTemplate,
  deleteTemplate,
  getSignedUrl
} from '../../data/dataApi';
import { Template } from '../../models';
import Table from '../../components/Table';
import { DetailsIcon, TrashIcon } from '../../icons';

interface ActionsProps {
  template: Template
  onSelect: () => void
  removeTemplate: (id: number) => void
}

const Actions: React.FC<ActionsProps> = ({
  template, onSelect, removeTemplate
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);

  const onRemove = () => {
    setIsProcessing(true);
    deleteTemplate(template.id)
      .then((res: { success: boolean }) => {
        if (res.success) {
          removeTemplate(template.id);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'top', 'left', 'right']}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="#daf3ef"
            arrowSize={10}
            className="popover-arrow-container"
            arrowClassName="popover-arrow"
          >
            <div className="popover-content">
              <div className="option" onClick={onSelect}>
                <IonLabel>
                  <DetailsIcon />
                  Details
                </IonLabel>
              </div>
              <div className="option" onClick={() => { setIsPopoverOpen(false); setShowRemoveAlert(true); }}>
                <IonLabel color="danger">
                  <TrashIcon />
                  Delete
                </IonLabel>
              </div>
            </div>
          </ArrowContainer>
        )}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        {isProcessing ? <IonSpinner /> : (
          <span
            className="action cursor-pointer"
            title="More actions"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          >
            <IonIcon icon={ellipsisHorizontal} />
          </span>
        )}
      </Popover>

      <IonAlert
        isOpen={showRemoveAlert}
        onDidDismiss={(): void => setShowRemoveAlert(false)}
        header="Care Platform"
        message="Are you sure you want to delete this template?"
        buttons={[
          {
            text: 'Cancel',
            cssClass: 'cancel-button',
          },
          {
            text: 'Confirm',
            cssClass: 'confirm-button',
            handler: (): void => onRemove()
          }
        ]}
      />
    </>
  );
};

Actions.propTypes = {
  template: PropTypes.any.isRequired,
  onSelect: PropTypes.func.isRequired,
  removeTemplate: PropTypes.func.isRequired
};

const initTemplate: Template = {
  id: -1,
  type: 'sms',
  name: '',
  description: '',
  attachments: []
};

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface TemplatesViewProps {
  toggleHeader: (value: boolean) => void
}

const TemplatesView: React.FC<TemplatesViewProps> = ({
  toggleHeader
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [orderBy, setOrderBy] = useState('id');
  const [isDescending, setIsDescending] = useState(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [type, setType] = useState<'sms' | 'email'>('sms');
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [showOptionsPopover, setShowOptionsPopover] = useState(false);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [removedExistingFileIds, setRemovedExistingFileIds] = useState<number[]>([]);
  const searchTimeoutRef = useRef<number | null>(null);


  const dropzoneRef = createRef<DropzoneRef>();

  const onChangeOrderBy = (option: string) => {
    if (orderBy === option) {
      setIsDescending(!isDescending);
    } else {
      setOrderBy(option);
      setIsDescending(false);
    }
  };

  const onShowAttachment = async (name: string) => {
    const { url } = await getSignedUrl(name);
    window.open(url, '_blank');
  };

  const onAddNew = (templateType: string) => {
    setType(templateType as 'sms' | 'email');
    setSelectedTemplate(initTemplate);
    setIsEdit(true);
    setShowOptionsPopover(false);
    toggleHeader(false);
    setNewFiles([]);
    setRemovedExistingFileIds([]);
  };

  const removeTemplate = (id: number) => {
    const updateTemplates = templates.filter((tp) => tp.id !== id);
    setTemplates([...updateTemplates]);
  };

  const fetchMore = (isNew: boolean, str?: string) => {
    const params = {
      skip: isNew ? 0 : templates.length,
      searchKey: str === undefined ? searchKey : str,
      isDescending,
      orderBy
    };
    setIsFetching(true);
    fetchTemplates(params)
      .then((res: { success: boolean, templates: Template[] }) => {
        if (res.success) {
          if (isNew) {
            setTemplates([...res.templates]);
          } else {
            setTemplates([...templates, ...res.templates]);
          }
          if (!res.templates.length || res.templates.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setIsFetching(false));
  };

  const onSubmit = async () => {
    if (
      !selectedTemplate
      || !selectedTemplate.name
      || !selectedTemplate.description
    ) {
      return setShowWarningAlert(true);
    }

    try {
      setIsSaving(true);
      const data = new FormData();
      data.append('name', selectedTemplate.name);
      data.append('description', selectedTemplate.description);
      newFiles.map((file) => {
        data.append('attachments', file);
      });
      if (selectedTemplate.id > -1) {
        data.append('type', selectedTemplate.type);
        if (removedExistingFileIds.length) {
          data.append('removedFiles', removedExistingFileIds.join(','));
        }
        const { template } = await updateTemplate(selectedTemplate.id, data);
        setSelectedTemplate(template);
      } else {
        data.append('type', type);
        await addTemplate(data);
        setSelectedTemplate(null);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsEdit(false);
      setIsSaving(false);
      setOrderBy('id');
      setRemovedExistingFileIds([]);
      setIsFetchedAll(false);
    }
  };

  const templateColumns = useMemo<Column<Template>[]>(
    () => [
      {
        Header: 'NO',
        id: 'index',
        accessor: (_row: any, i : number) => i + 1,
        width: 60
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'name' })}
            onClick={() => onChangeOrderBy('name')}
          >
            NAME
            {orderBy === 'name' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'name',
        width: 'auto'
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'type' })}
            onClick={() => onChangeOrderBy('type')}
          >
            TYPE
            {orderBy === 'type' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'type',
        Cell: ({ cell: { value } }) => (value === 'sms' ? 'SMS' : 'Email'),
        width: 'auto'
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'description' })}
            onClick={() => onChangeOrderBy('description')}
          >
            DESCRIPTION
            {orderBy === 'description' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'description',
        width: '50%'
      },
      {
        Header: () => (
          <div
            className={clsx('cursor-pointer', { active: orderBy === 'createdAt' })}
            onClick={() => onChangeOrderBy('createdAt')}
          >
            CREATED AT
            {orderBy === 'createdAt' && isDescending ? <BsSortUpAlt /> : <BsSortDownAlt />}
          </div>
        ),
        accessor: 'createdAt',
        Cell: ({ cell: { value } }) => moment.utc(value).local().format('MMM DD YYYY, hh:mm A'),
        width: 'auto'
      },
      {
        Header: 'ACTION',
        Cell: ({ row: { original } }: { row: { original: Template} }) => (
          <Actions
            template={original}
            onSelect={() => {
              toggleHeader(false);
              setIsEdit(false);
              setIsFetchedAll(false);
              setSelectedTemplate(original);
              setType(original.type as 'sms' | 'email');
              setRemovedExistingFileIds([]);
            }}
            removeTemplate={removeTemplate}
          />
        ),
        width: 70
      },
    ], [orderBy, isDescending, onChangeOrderBy]
  );

  useEffect(() => {
    if (!selectedTemplate) {
      toggleHeader(true);
      fetchMore(true);
    }
  }, [selectedTemplate, orderBy, isDescending]);

  return (
    <div className="marketing-section">
      {/* main screen to show templates list */}
      {!selectedTemplate ? (
        <>
          <div className="marketing-section-header">
            <Popover
              isOpen={showOptionsPopover}
              positions={['bottom', 'top', 'left', 'right']}
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor="#daf3ef"
                  arrowSize={10}
                  className="popover-arrow-container"
                  arrowClassName="popover-arrow"
                >
                  <div className="popover-content">
                    <div className="option" onClick={() => onAddNew('email')}>
                      <IonLabel>
                        <IonIcon icon={mailOutline} color="dark" />
                        &nbsp;Mail Template
                      </IonLabel>
                    </div>
                    <div className="option" onClick={() => onAddNew('sms')}>
                      <IonLabel>
                        <IonIcon icon={chatbubbleOutline} color="dark" />
                        &nbsp;SMS Template
                      </IonLabel>
                    </div>
                  </div>
                </ArrowContainer>
              )}
              onClickOutside={() => setShowOptionsPopover(false)}
            >
              <IonButton
                color="favorite"
                onClick={() => setShowOptionsPopover(true)}
              >
                + Create Template
              </IonButton>
            </Popover>
            <div className="marketing-section-filter">
              <IonItem className="search-input" lines="none">
              <IonInput
                  value={searchKey}
                  placeholder="Search"
                  onIonChange={(e) => {
                    const inputValue = e.detail.value ?? '';
                    setSearchKey(inputValue);
                    if (searchTimeoutRef.current) {
                      clearTimeout(searchTimeoutRef.current);
                    }
                  
                    searchTimeoutRef.current = window.setTimeout(() => {
                      fetchMore(true, inputValue);
                    }, 450);
                  }}
                />
                <IonIcon size="small" icon={searchOutline} onClick={() => fetchMore(true, searchKey)} />
                <IonIcon size="small" icon={closeOutline} onClick={() => { setSearchKey(''); fetchMore(true, ''); }} />
              </IonItem>
              <IonButton color="dark" fill="outline">
                <IonIcon icon={options} />
                Filter
              </IonButton>
            </div>
          </div>
          <div className="marketing-section-list">
            <Table
              columns={templateColumns}
              data={templates}
            />
            {isFetchedAll ? null : (
              <IonInfiniteScroll
                onIonInfinite={(ev) => {
                  fetchMore(false);
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
                }}
              >
                <IonInfiniteScrollContent
                  loadingText="Fetching more..."
                  loadingSpinner="circles"
                />
              </IonInfiniteScroll>
            )}
          </div>
        </>
      ) : null}

      {/* screen to create new or update existing template */}
      {selectedTemplate && isEdit ? (
        <>
          <div className="marketing-section-details">
            <div className="marketing-section-header">
              <div className="screen-header">
                {selectedTemplate.id !== -1 ? 'Update Template' : 'Add Template'}
              </div>
            </div>
            <div className="marketing-section-content">
              <IonGrid>
                <IonRow>
                  <IonCol size="4">
                    <IonLabel className="field-title">Name*</IonLabel>
                    <IonItem lines="none" className="input">
                      <IonInput
                        type="text"
                        placeholder=""
                        value={selectedTemplate.name}
                        onIonChange={
                          (e) => setSelectedTemplate({
                            ...selectedTemplate,
                            name: e.detail.value as string
                          })
                        }
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol size="8" />
                  <IonCol size="12">
                    <IonLabel className="field-title">Description*</IonLabel>
                    <IonItem lines="none" className="input">
                      <IonTextarea
                        placeholder=""
                        maxlength={500}
                        rows={3}
                        value={selectedTemplate.description}
                        onIonChange={
                          (e) => setSelectedTemplate({
                            ...selectedTemplate,
                            description: e.detail.value as string
                          })
                        }
                      />
                    </IonItem>
                  </IonCol>
                  {type === 'email' ? (
                    <IonCol size="12">
                      <Dropzone
                        ref={dropzoneRef}
                        multiple
                        onDrop={(files, errors) => {
                          if (files.length) {
                            setNewFiles([...newFiles, ...files]);
                          }
                        }}
                      >
                        {({ getRootProps }) => (
                          <section>
                            <div className="drop-zone" {...getRootProps()}>
                              Drag & Drop Files Here
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </IonCol>
                  ) : null}
                  {newFiles.length || selectedTemplate.attachments.length ? (
                    <IonCol size="12">
                      <IonLabel className="field-title">Attachments:</IonLabel>
                      <div className="campaign-attachments">
                        {newFiles.map((file) => (
                          <div key={file.name}>
                            <a
                              href={URL.createObjectURL(file)}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              {file.name}
                            </a>
                            <IonIcon
                              icon={closeOutline}
                              onClick={() => {
                                setNewFiles(newFiles.filter((fl) => fl.name !== file.name));
                              }}
                            />
                          </div>
                        ))}
                        {selectedTemplate.attachments
                          .filter((at) => !removedExistingFileIds.includes(at.id))
                          .map((at) => (
                            <div key={at.name}>
                              <span onClick={() => onShowAttachment(at.assetName)}>
                                {at.name}
                              </span>
                              <IonIcon
                                icon={closeOutline}
                                onClick={() => {
                                  setRemovedExistingFileIds([...removedExistingFileIds, at.id]);
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    </IonCol>
                  ) : null}
                </IonRow>
                <IonRow>
                  <IonCol>
                    <div className="campaign-actions">
                      <IonButton color="favorite" onClick={onSubmit}>
                        {isSaving ? <IonSpinner /> : null}
                        {!isSaving && selectedTemplate.id > -1 ? 'Update' : null}
                        {!isSaving && selectedTemplate.id === -1 ? 'Save' : null}
                      </IonButton>
                      <IonButton
                        color="dark"
                        fill="outline"
                        onClick={() => {
                          if (selectedTemplate.id === -1) {
                            setIsFetchedAll(false);
                            setOrderBy('id');
                            setSelectedTemplate(null);
                            toggleHeader(true);
                          }
                          setIsEdit(false);
                        }}
                      >
                        Cancel
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        </>
      ) : null}

      {/* template details */}
      {selectedTemplate && !isEdit ? (
        <>
          <div className="mailing-info-header">
            <IonListHeader className="page-header">
              <IonIcon color="favorite" icon={arrowBackOutline} onClick={() => { setSelectedTemplate(null); setOrderBy('id'); }} />
              Template Details
            </IonListHeader>
            <div className="details-actions">
              <IonButton
                color="favorite"
                fill="outline"
                onClick={() => {
                  setIsEdit(true);
                  setNewFiles([]);
                }}
              >
                Edit
              </IonButton>
            </div>
          </div>
          <div className="marketing-section-details">
            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      TYPE
                    </span>
                    <span className="field-content">
                      {selectedTemplate.type === 'sms' ? 'SMS' : 'Email'}
                    </span>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      NAME
                    </span>
                    <span className="field-content">
                      {selectedTemplate.name}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <br />
              <IonRow>
                <IonCol size="12">
                  <div className="detail-option">
                    <span className="field-title">
                      DESCRIPTION
                    </span>
                    <span className="field-content">
                      {selectedTemplate.description}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <br />
              <IonRow>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      CREATED AT
                    </span>
                    <span className="field-content">
                      {moment.utc(selectedTemplate.createdAt).local().format('MMM DD YYYY, hh:mm A')}
                    </span>
                  </div>
                </IonCol>
                <IonCol size="6">
                  <div className="detail-option">
                    <span className="field-title">
                      UPDATED AT
                    </span>
                    <span className="field-content">
                      {moment.utc(selectedTemplate.updatedAt).local().format('MMM DD YYYY, hh:mm A')}
                    </span>
                  </div>
                </IonCol>
              </IonRow>
              <br />
              {type === 'email' && selectedTemplate.attachments.length ? (
                <IonRow>
                  <IonCol size="12">
                    <div className="detail-option">
                      <span className="field-title">
                        ATTACHMENTS
                      </span>
                      <div className="attachments">
                        {selectedTemplate.attachments.map((at) => (
                          <div key={at.name}>
                            <span onClick={() => onShowAttachment(at.assetName)}>
                              {at.name}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              ) : null}
            </IonGrid>
          </div>
        </>
      ) : null}

      <IonAlert
        isOpen={showWarningAlert}
        onDidDismiss={(): void => setShowWarningAlert(false)}
        header="CarePlatform"
        message="Please input mandatory information."
        buttons={['OK']}
      />

      <IonLoading isOpen={isFetching} />
    </div>
  );
};

TemplatesView.propTypes = {
  toggleHeader: PropTypes.func.isRequired
};

export default React.memo(TemplatesView);
