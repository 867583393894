import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

interface TextMessageProps {
  body: string;
  isLocalParticipant: boolean;
}

const TextMessage: React.FC<TextMessageProps> = ({
  body,
  isLocalParticipant,
}) => {
  return (
    <div className={clsx('message-body', { sent: isLocalParticipant })}>
      <span>{body}</span>
    </div>
  );
};

TextMessage.propTypes = {
  body: PropTypes.string.isRequired,
  isLocalParticipant: PropTypes.bool.isRequired,
};

export default TextMessage;
