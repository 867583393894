import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonToolbar,
  IonImg,
} from '@ionic/react';
import slugify from 'slugify';

import connect from '../data/connect';
import { setToken, signOut } from '../data/dataApi';
import { User } from '../models';
import useGetView from '../hooks/useGetView/useGetView';
import { dispatchLogout, dispatchValidate } from '../data/store/auth/auth.actions';
import { getUnreadCount } from '../data/selectors';
import { StatusContext } from '../data/StatusContext';
import {
  PanelIcon,
  HeartIcon,
  EmailIcon,
  CalenderIcon,
  BlockIcon,
  BankIcon,
  ClockIcon,
  TimeIcon,
  UsersIcon,
  WalletIcon,
  AlarmIcon,
  SignOutIcon,
  UserIcon,
  FileIcon,
  ExportIcon,
  CoinsIcon,
  MarketingIcon
} from '../icons';

import logoImg from '../assets/logo.png';
import './Menu.scss';

interface Dictionary {
  [id: string]: any[]
}

const routesMobile: Dictionary = {
  customer: [
    {
      title: 'Account Settings',
      path: '/customer/account',
      icon: <PanelIcon />
    },
    { title: 'Profile', path: '/customer/profile' },
    { title: 'Wallet', path: '/customer/wallet' },
    { title: 'My Bookings', path: '/customer/bookings' },
    {
      title: 'Favorite Providers',
      path: '/customer/favorites',
    },
    { title: 'Contact Us', path: '/customer/contact-us' }
  ],
  provider: [
    {
      title: 'Account Settings',
      path: '/provider/account',
    },
    { title: 'Profile', path: '/provider/profile' },
    { title: 'Bank Accounts', path: '/provider/wallet' },
    {
      title: 'Business Hours',
      path: '/provider/:id/business-hour',
    },
    { title: 'Appointments', path: '/provider/bookings' },
    { title: 'Contact Us', path: '/provider/contact-us' }
  ],
  'clinic-admin': [
    { title: 'Profile', path: '/clinic-admin/profile' },
    {
      title: 'Availability',
      path: '/clinic-admin/availability',
    },
    { title: 'Bank Accounts', path: '/clinic-admin/wallet' }
  ],
  admin: [
    { title: 'Dashboard', path: '/admin/dashboard', icon: <PanelIcon /> },
    {
      title: 'Providers',
      path: '/admin/providers',
      icon: <BlockIcon />
    }
  ],
};

const routesDesktop: Dictionary = {
  customer: [
    { title: 'Dashboard', path: '/customer/dashboard', icon: <PanelIcon /> },
    // {
    //   title: 'Account Settings',
    //   path: '/customer/account'
    // },
    {
      title: 'Favorites',
      path: '/customer/favorites',
      icon: <HeartIcon />
    },
    { title: 'My Bookings', path: '/customer/bookings', icon: <CalenderIcon /> },
    {
      title: 'Providers Directory',
      path: '/customer/providers',
      icon: <BlockIcon />
    },
    { title: 'Wallet', path: '/customer/wallet', icon: <WalletIcon /> },
    { title: 'Payment', path: '/customer/payment', icon: <BankIcon /> },
    {
      title: 'Notifications',
      path: '/customer/notification',
      icon: <AlarmIcon />
    },
    {
      title: 'Reports',
      path: '/customer/report',
      icon: <ExportIcon />
    },
    { title: 'Settings', path: '/customer/profile', icon: <UserIcon /> },
    { title: 'Contact Us', path: '/customer/contact-us', icon: <EmailIcon /> },
  ],
  provider: [
    { title: 'Dashboard', path: '/provider/dashboard', icon: <PanelIcon /> },
    {
      title: 'Business Hours',
      path: '/provider/:id/business-hour',
      icon: <TimeIcon />
    },
    {
      title: 'Availability',
      path: '/provider/availability',
      icon: <ClockIcon />
    },
    { title: 'Appointments', path: '/provider/bookings', icon: <BlockIcon /> },
    { title: 'Payment', path: '/provider/payment', icon: <BankIcon /> },
    // {
    //   title: 'Account Settings',
    //   path: '/provider/account',
    // },
    // { title: 'Profile', path: '/provider/profile' },
    {
      title: 'Notifications',
      path: '/provider/notification',
      icon: <AlarmIcon />
    },
    {
      title: 'Reports',
      path: '/provider/report',
      icon: <ExportIcon />
    },
    { title: 'Settings', path: '/provider/profile', icon: <UserIcon /> },
    { title: 'Contact Us', path: '/provider/contact-us', icon: <EmailIcon /> },
  ],
  'clinic-admin': [
    { title: 'Dashboard', path: '/clinic-admin/dashboard', icon: <PanelIcon /> },
    { title: 'Members', path: '/clinic-admin/members', icon: <UsersIcon /> },
    {
      title: 'Availability',
      path: '/clinic-admin/availability',
      icon: <CalenderIcon />,
    },
    { title: 'Payment', path: '/clinic-admin/payment', icon: <BankIcon /> },
    // { title: 'Wallet', path: '/clinic-admin/wallet', icon: <WalletIcon /> },
    {
      title: 'Notifications',
      path: '/clinic-admin/notification',
      icon: <AlarmIcon />
    },
    { title: 'Settings', path: '/clinic-admin/profile', icon: <UserIcon /> },
    {
      title: 'Reports',
      path: '/clinic-admin/report',
      icon: <ExportIcon />
    },
  ],
  'clinic-member': [
    { title: 'Dashboard', path: '/clinic-member/dashboard', icon: <PanelIcon /> },
    {
      title: 'Business Hours',
      path: '/clinic-member/:id/business-hour',
      icon: <TimeIcon />
    },
    {
      title: 'Availability',
      path: '/clinic-member/availability',
      icon: <ClockIcon />
    },
    { title: 'Appointments', path: '/clinic-member/bookings', icon: <BlockIcon /> },
    // { title: 'Payment', path: '/provider/payment', icon: <BankIcon /> },
    // {
    //   title: 'Account Settings',
    //   path: '/provider/account',
    // },
    // { title: 'Profile', path: '/provider/profile' },
    {
      title: 'Notifications',
      path: '/clinic-member/notification',
      icon: <AlarmIcon />
    },
    {
      title: 'Reports',
      path: '/clinic-member/report',
      icon: <ExportIcon />
    },
    { title: 'Settings', path: '/clinic-member/profile', icon: <UserIcon /> },
    { title: 'Contact Us', path: '/clinic-member/contact-us', icon: <EmailIcon /> },
  ],
  admin: [
    { title: 'Dashboard', path: '/admin/dashboard', icon: <PanelIcon /> },
    {
      title: 'Providers Directory',
      path: '/admin/providers',
      icon: <BlockIcon />
    },
    {
      title: 'Calendar View',
      path: '/admin/calendar',
      icon: <CalenderIcon />
    },
    {
      title: 'Users',
      path: '/admin/users',
      icon: <UsersIcon />
    },
    {
      title: 'Payment',
      path: '/admin/payment',
      icon: <BankIcon />
    },
    {
      title: 'Reports',
      path: '/admin/report',
      icon: <FileIcon />
    },
    {
      title: 'Marketing',
      path: '/admin/marketing',
      icon: <MarketingIcon />
    },
    {
      title: 'Earnings',
      path: '/admin/earnings',
      icon: <CoinsIcon />
    },
    {
      title: 'Taxes',
      path: '/admin/taxes',
      icon: <CoinsIcon />
    }
  ],
  'marketing-member': [
    { title: 'Dashboard', path: '/marketing-member/dashboard', icon: <PanelIcon /> },
    {
      title: 'Providers Directory',
      path: '/marketing-member/providers',
      icon: <BlockIcon />
    },
    {
      title: 'Calendar View',
      path: '/marketing-member/calendar',
      icon: <CalenderIcon />
    },
    {
      title: 'Users',
      path: '/marketing-member/users',
      icon: <UsersIcon />
    },
    {
      title: 'Reports',
      path: '/marketing-member/report',
      icon: <FileIcon />
    },
    {
      title: 'Marketing',
      path: '/marketing-member/marketing',
      icon: <MarketingIcon />
    }
  ],
  'revenue-analyst': [
    { title: 'Dashboard', path: '/revenue-analyst/dashboard', icon: <PanelIcon /> },
    {
      title: 'Payment',
      path: '/revenue-analyst/payment',
      icon: <BankIcon />
    },
    {
      title: 'Reports',
      path: '/revenue-analyst/report',
      icon: <FileIcon />
    },
    {
      title: 'Earnings',
      path: '/revenue-analyst/earnings',
      icon: <CoinsIcon />
    },
    {
      title: 'Taxes',
      path: '/revenue-analyst/taxes',
      icon: <CoinsIcon />
    },
  ],
  helpdesk: [
    { title: 'Dashboard', path: '/helpdesk/dashboard', icon: <PanelIcon /> },
    {
      title: 'Providers Directory',
      path: '/helpdesk/providers',
      icon: <BlockIcon />
    },
    {
      title: 'Calendar View',
      path: '/helpdesk/calendar',
      icon: <CalenderIcon />
    },
    {
      title: 'Users',
      path: '/helpdesk/users',
      icon: <UsersIcon />
    },
    {
      title: 'Payment',
      path: '/helpdesk/payment',
      icon: <BankIcon />
    },
    {
      title: 'Reports',
      path: '/helpdesk/report',
      icon: <FileIcon />
    }
  ]
};

interface Pages {
  title: string
  path: string
  icon: {
    ios: string
    md: string
  }
  routerDirection?: string
}
interface StateProps {
  isAuthenticated: boolean
  isAllowed: boolean
  user: User
  unreadCount: number
}

interface DispatchProps {
  logout: typeof dispatchLogout
  validate: typeof dispatchValidate
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps {}

const Menu: React.FC<MenuProps> = ({
  user,
  unreadCount,
  location,
  history,
  isAuthenticated,
  isAllowed,
  logout,
  validate
}) => {
  const { isMobile } = useGetView();
  const { syncDisconnect } = useContext(StatusContext);
  const renderlistItems = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const list: Pages[] = !isMobile ? routesDesktop[user.role] : routesMobile[user.role];
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle id={`nav-${slugify(p.title).toLowerCase()}`} key={p.title} auto-hide="false">
          <IonItem
            button
            routerLink={p.path.replace(':id', `${user.id}`)}
            routerDirection="none"
            mode="ios"
            detail={false}
            lines="none"
            className={location.pathname === p.path ? 'active' : 'deactive'}
          >
            {p.icon ? p.icon : null}
            <IonLabel>{p.title}</IonLabel>
            {p.title === 'Notifications' && unreadCount > 0 ? <span className="unread-count">{unreadCount}</span> : null}
          </IonItem>
        </IonMenuToggle>
      ));
  }, [location, unreadCount, user]);

  const onSignOut = (): void => {
    syncDisconnect(user);
    signOut()
      .then(async (res: { success: boolean, token: string }) => {
        if (res.success) {
          await setToken(res.token);
          validate();
          history.push('/');
        } else {
          logout();
          history.push('/login');
        }
      })
      .catch((error) => {
        console.log(error);
        logout();
        history.push('/login');
      });
  };

  return (
    <IonMenu
      type="overlay"
      side={!isMobile ? 'start' : 'end'}
      disabled={
        isAuthenticated
        || isMobile
        || location.pathname.includes('/video/')
        || location.pathname.includes('/onboarding')
        || location.pathname.includes('/verify')
      }
      contentId="main"
      className="menu"
    >
      <IonHeader>
        <IonToolbar className="menu-header">
          <IonImg id="nav-logo" src={logoImg} alt="logo" onClick={() => history.push(`/${user.role}/dashboard`)} />
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding">
        {isAllowed ? (
          <IonList>
            {renderlistItems}
            <IonMenuToggle auto-hide="false" id="nav-sign-out">
              <IonItem
                button
                onClick={() => {
                  onSignOut();
                }}
                routerDirection="none"
                mode="ios"
                detail={false}
                lines="none"
              >
                <SignOutIcon />
                <IonLabel>Sign Out</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        ) : (
          <div className="signout-button">
            <IonMenuToggle auto-hide="false">
              <IonItem
                button
                onClick={() => {
                  onSignOut();
                }}
                routerDirection="none"
                mode="ios"
                detail={false}
                lines="none"
              >
                <SignOutIcon />
                <IonLabel>Sign Out</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </div>
        )}
      </IonContent>
    </IonMenu>
  );
};

Menu.propTypes = {
  user: PropTypes.any.isRequired,
  unreadCount: PropTypes.number.isRequired,
  location: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isAllowed: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    isAuthenticated: !state.auth.user.phone,
    isAllowed: state.auth.user.status === 'active',
    user: state.auth.user,
    unreadCount: getUnreadCount(state)
  }),
  mapDispatchToProps: {
    logout: dispatchLogout,
    validate: dispatchValidate
  },
  component: withRouter(Menu)
});
