import React from 'react';
import clsx from 'clsx';
import { Participant } from 'twilio-video';

import useParticipantIsReconnecting from '../../../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import './ParticipantConnectionIndicator.scss';

export default function ParticipantConnectionIndicator({
  participant
}: {
  participant: Participant
}) {
  const isReconnecting = useParticipantIsReconnecting(participant);
  return <span className={clsx('indicator', { isReconnecting })} />;
}
