import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { IonIcon, IonItem, IonLabel } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import moment from 'moment';
import clsx from 'clsx';

import connect from '../data/connect';
import { ProviderType, Request } from '../models';
import Avatar from './Avatar';

interface RequestItemProps {
  request: Request
}

interface StateProps {
  providerTypes: ProviderType[]
}

const RequestItem: React.FC<RequestItemProps & StateProps> = ({
  request, providerTypes
}) => {
  const history = useHistory();
  const {
    requestor, type, userId, status, createdAt
  } = request;

  const onClick = () => {
    history.push(`/admin/provider/${userId}`, { type });
  };

  return (
    <div className="request">
      <IonItem lines="none" className="content">
        <Avatar size="md" user={requestor} />
        <IonLabel color="dark">
          <h2>
            {`${requestor.name} ${requestor.lastName ?? ''}`}
            <span className={clsx('badge', { id: type === 'identity', license: type === 'license' })}>
              {type === 'identity' ? 'IDENTITY' : 'LICENSE'}
            </span>
          </h2>
          <p>
            {providerTypes.find((pt) => pt.id === requestor.type)?.name}
          </p>
          <p>{moment.utc(createdAt).local().format('DD MMM YYYY, hh:mm A')}</p>
        </IonLabel>
        {/* {type === 'identity' ? (
          <p>{`${requestor.name} ${requestor.lastName ?? ''} has requested ID verification.`}</p>
        ) : null}
        {type === 'license' ? (
          <p>{`${requestor.name} ${requestor.lastName ?? ''} has requested License Cerification.`}</p>
        ) : null} */}
        <div className="actions">
          {status === 'pending' ? (
            <>
              <span className="status pending">Pending</span>
              <span className="action-btn" slot="end" onClick={onClick}>
                Details
                <IonIcon icon={chevronForwardOutline} />
              </span>
            </>
          ) : null}
          {status === 'completed' ? (
            <span className="status completed">Completed</span>
          ) : null}
          {status === 'rejected' ? (
            <span className="status rejected">Rejected</span>
          ) : null}
          {status === 'cancelled' ? (
            <span className="status cancelled">Cancelled</span>
          ) : null}
        </div>
      </IonItem>
    </div>
  );
};

RequestItem.propTypes = {
  request: PropTypes.any.isRequired,
  providerTypes: PropTypes.array.isRequired
};

export default connect<RequestItemProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    providerTypes: state.data.providerTypes
  }),
  component: React.memo(RequestItem)
});
