import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonList,
  IonToggle,
  IonDatetime,
  IonLoading
} from '@ionic/react';
import {
  chevronBackOutline,
  chevronForwardOutline,
  removeOutline,
  trashOutline
} from 'ionicons/icons';
import moment from 'moment';

import connect from '../../../data/connect';
import { Location, Timeslot, User, UserRole } from '../../../models';
import { updateProviderProfile } from '../../../data/dataApi';
import { dispatchUpdateUser } from '../../../data/store/auth/auth.actions';
import {
  dispatchCreateTimeslot,
  dispatchDeleteTimeslot,
  dispatchUpdateTimeslot
} from '../../../data/store/data/data.actions';
import {
  localToUtc, getLocalTimeSlots, getPreviosDay, getNextDay
} from '../../../util/slots';

const workdays: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

interface FourthStepProps {
  user: User
  next: () => void
  prev: () => void
}

interface StateProps {
  slots: Timeslot[]
}

interface DispatchProps {
  createTimeslot: typeof dispatchCreateTimeslot
  deleteTimeslot: typeof dispatchDeleteTimeslot
  updateTimeslot: typeof dispatchUpdateTimeslot
  updateUser: typeof dispatchUpdateUser
}

const FourthStep: React.FC<FourthStepProps & DispatchProps & StateProps> = ({
  user,
  slots,
  next,
  prev,
  createTimeslot,
  deleteTimeslot,
  updateTimeslot,
  updateUser
}) => {
  const [location, setLocation] = useState({} as Location);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user.locations.length) {
      setLocation(user.locations[0]);
    }
  }, [user]);

  const addSlot = (day: string, start: string): void => {
    const startHour = Number(start.split(':')[0]);
    const startMins = Number(start.split(':')[1]);
    const startUtc = localToUtc(startHour, startMins);
    const endUtc = localToUtc(17, 0);
    const startDayIndex = workdays.indexOf(day) + Number(startUtc[2]);
    const endDayIndex = workdays.indexOf(day) + Number(endUtc[2]);

    createTimeslot({
      user: user.id,
      location: location.id,
      startDay: workdays[startDayIndex],
      start: `${startUtc[0]}:${startUtc[1]}`,
      endDay: workdays[endDayIndex],
      end: `${endUtc[0]}:${endUtc[1]}`
    });
  };

  const removeSlot = (id: number) => {
    deleteTimeslot(id);
  };

  const updateSlot = (id: number, updatedData: any) => {
    updateTimeslot(id, updatedData);
  };

  const onSubmit = () => {
    setIsLoading(true);
    const nextStep = user.role === UserRole.PROVIDER ? 4 : 5;
    updateProviderProfile(user.id, {
      profile: {
        onboardingStep: user.onboardingStep > nextStep ? user.onboardingStep : nextStep
      },
      languages: user.languages.map((language) => language.id),
      specialists: user.specialists.map((specialist) => specialist.id),
      locations: user.locations,
      services: user.services
    })
      .then((res) => {
        if (res.success) {
          updateUser(res.user);
          next();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const isSubmitDisabled = useMemo(() => {
    if (slots.length > 0) {
      return false;
    }
    return true;
  }, [slots]);

  const WeekdayItem = ({ day, daySlots }: { day: string, daySlots: Timeslot[] }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
      setIsOpen(daySlots.length > 0);
    }, [daySlots]);

    const updateTime = (item: any, tag: string, value: string): void => {
      const time = moment(value).format('HH:mm');
      const utcTime = localToUtc(Number(time.split(':')[0]), Number(time.split(':')[1]));
      if (tag === 'type') {
        const updatedData = {
          type: value
        };
        updateSlot(item.id, updatedData);
      } else if (item[tag] !== time) {
        const updatedData = {
          [tag]: `${utcTime[0]}:${utcTime[1]}`
        };
        // eslint-disable-next-line no-nested-ternary
        updatedData[`${tag}Day`] = utcTime[2] === -1 ? getPreviosDay(day)
          : utcTime[2] === 1 ? getNextDay(day) : day;

        updateSlot(item.id, updatedData);
      }
    };

    const removeTime = (item: Timeslot) => {
      if (item.startDay === day && item.endDay === day) {
        removeSlot(item.id);
      }
      if (item.endDay !== item.startDay && item.startDay === day && Number(item.end.split(':')[0]) === 0) {
        removeSlot(item.id);
      }
      if (item.startDay !== day) {
        const newEndUTC = localToUtc(0, 0);
        const updatedData = {
          endDay: newEndUTC[2] === -1 ? getPreviosDay(day)
          : newEndUTC[2] === 1 ? getNextDay(day) : day,
          end: `${newEndUTC[0]}:${newEndUTC[1]}`
        };
        updateSlot(item.id, updatedData);
      }
      if (item.endDay !== day) {
        const newEndUTC = localToUtc(24, 0);
        const updatedData = {
          startDay: newEndUTC[2] === -1 ? getPreviosDay(day)
          : newEndUTC[2] === 1 ? getNextDay(day) : day,
          start: `${newEndUTC[0]}:${newEndUTC[1]}`
        };
        updateSlot(item.id, updatedData);
      }
    };

    const lastTime = useMemo(() => {
      if (!daySlots || daySlots.length === 0) {
        return '09:00';
      }
      const lastSlot = daySlots.slice(-1)[0];
      return lastSlot.end;
    }, [daySlots]);

    return (
      <div className="weekday-item">
        <div className="weekday-name">{day}</div>
        <div className="weekday-content">
          {isOpen ? (
            <>
              {daySlots.map((item) => (
                <div className="time-slot">
                  <IonDatetime
                    className="time-select"
                    displayFormat="hh:mm A"
                    pickerFormat="HH:mm"
                    minuteValues="0, 30"
                    max={moment(item.end, 'HH:mm').format('HH:mm')}
                    value={item.startDay === day ? moment(item.start, 'HH:mm').toLocaleString() : moment('00:00', 'HH:mm').toLocaleString()}
                    onIonChange={(event): void => updateTime(item, 'start', event.detail.value || '')}
                  />
                  <IonIcon
                    icon={removeOutline}
                    color="dark"
                    className="arrow"
                  />
                  <IonDatetime
                    className="time-select"
                    displayFormat="hh:mm A"
                    pickerFormat="HH:mm"
                    minuteValues="0, 30"
                    min={moment(item.start, 'HH:mm').format('HH:mm')}
                    value={item.endDay === day ? moment(item.end, 'HH:mm').toLocaleString() : moment('00:00', 'HH:mm').toLocaleString()}
                    onIonChange={(event): void => updateTime(item, 'end', event.detail.value || '')}
                  />
                  <IonSelect
                    value={item.type}
                    onIonChange={(event) => updateTime(item, 'type', event.detail.value as string)}
                  >
                    <IonSelectOption value="video-visit">Virtual</IonSelectOption>
                    <IonSelectOption value="provider-visit">Provider Location</IonSelectOption>
                    <IonSelectOption value="customer-visit">Customer Location</IonSelectOption>
                  </IonSelect>
                  <span className="icon-btn" onClick={() => removeTime(item)}>
                    <IonIcon icon={trashOutline} />
                  </span>
                </div>
              ))}
              <div className="add-slot" onClick={() => addSlot(day, lastTime)}>+ Add time</div>
            </>
          ) : <div className="close-day">Closed</div>}
        </div>
        <div>
          <IonToggle
            color="favorite"
            checked={isOpen}
            onIonChange={(event): void => setIsOpen(event.detail.checked)}
          />
        </div>
      </div>
    );
  };

  return (
    <div id="step-four">
      <IonGrid className="step-content">
        <IonRow>
          <IonCol>
            <IonList>
              <IonItem lines="none" className="select">
                <IonSelect
                  className="select"
                  placeholder="Select your location"
                  value={location}
                  onIonChange={(e): void => setLocation(e.detail.value)}
                >
                  {user.locations.map((item) => (
                    <IonSelectOption key={item.id} value={item}>
                      {item.address}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <br />
              {workdays.map((workday) => (
                <IonItem lines="none" className="input">
                  <WeekdayItem
                    day={workday}
                    daySlots={getLocalTimeSlots(slots).filter(
                      (item: Timeslot) => ((item.startDay === item.endDay && item.startDay === workday)
                        || (item.startDay !== item.endDay && (item.startDay === workday || (item.endDay === workday && Number(item.end.split(':')[0]) !== 0)))) && item.locationId === location.id
                    )}
                  />
                </IonItem>
              ))}
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className="actions">
        <IonButton color="favorite" onClick={prev}>
          <IonIcon icon={chevronBackOutline} />
          Back
        </IonButton>
        <IonButton color="favorite" disabled={isSubmitDisabled} onClick={onSubmit}>
          Next
          <IonIcon icon={chevronForwardOutline} />
        </IonButton>
      </div>
      <IonLoading isOpen={isLoading} />
    </div>
  );
};

FourthStep.propTypes = {
  user: PropTypes.any.isRequired,
  slots: PropTypes.array.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  createTimeslot: PropTypes.func.isRequired,
  deleteTimeslot: PropTypes.func.isRequired,
  updateTimeslot: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<FourthStepProps, {}, {}>({
  mapStateToProps: (state) => ({
    slots: state.data.timeslots,
  }),
  mapDispatchToProps: {
    createTimeslot: dispatchCreateTimeslot,
    deleteTimeslot: dispatchDeleteTimeslot,
    updateTimeslot: dispatchUpdateTimeslot,
    updateUser: dispatchUpdateUser
  },
  component: React.memo(FourthStep)
});
