import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  IonButton,
  IonItem,
  IonToggle,
  IonList,
  IonDatetime,
  IonIcon,
  IonSelect,
  IonSelectOption
} from '@ionic/react';
import { addOutline, removeOutline, trashOutline } from 'ionicons/icons';
import moment from 'moment';

import { AppointmentType, Timeslot } from '../models';
import { localToUtc, getPreviosDay, getNextDay } from '../util/slots';

import './WeekdayItem.scss';

interface WeekdayItemProps {
  type: string
  locationId: number
  slots: Timeslot[]
  add: (day: string, start: string, type: string) => void
  remove: (id: number) => void
  update: (id: number, data: any) => void
}

const WeekdayItem: React.FC<WeekdayItemProps> = ({
  type,
  locationId,
  slots,
  add,
  remove,
  update
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const updateTime = (item: any, tag: string, value: string): void => {
    const time = moment(value).format('HH:mm');
    const utcTime = localToUtc(Number(time.split(':')[0]), Number(time.split(':')[1]));
    if (tag === 'type') {
      const updatedData = {
        type: value,
        locationId: value === AppointmentType.PROVIDER_LOCATION ? locationId : null
      };
      update(item.id, updatedData);
    } else if (item[tag] !== time) {
      const updatedData = {
        [tag]: `${utcTime[0]}:${utcTime[1]}`
      };
      // eslint-disable-next-line no-nested-ternary
      updatedData[`${tag}Day`] = utcTime[2] === -1 ? getPreviosDay(type)
        : utcTime[2] === 1 ? getNextDay(type) : type;

      update(item.id, updatedData);
    }
  };

  const removeTime = (item: any) => {
    if (item.startDay === type && item.endDay === type) {
      remove(item.id);
    }
    if (item.endDay !== item.startDay && item.startDay === type && Number(item.end.split(':')[0]) === 0) {
      remove(item.id);
    }
    if (item.startDay !== type) {
      const newEndUTC = localToUtc(0, 0);
      const updatedData = {
        endDay: newEndUTC[2] === -1 ? getPreviosDay(type)
        : newEndUTC[2] === 1 ? getNextDay(type) : type,
        end: `${newEndUTC[0]}:${newEndUTC[1]}`
      };
      update(item.id, updatedData);
    }
    if (item.endDay !== type) {
      const newEndUTC = localToUtc(24, 0);
      const updatedData = {
        startDay: newEndUTC[2] === -1 ? getPreviosDay(type)
        : newEndUTC[2] === 1 ? getNextDay(type) : type,
        start: `${newEndUTC[0]}:${newEndUTC[1]}`
      };
      update(item.id, updatedData);
    }
  };

  const lastTime = useMemo(() => {
    if (!slots || slots.length === 0) {
      return '09:00';
    }
    const lastSlot = slots.slice(-1)[0];
    return lastSlot.end;
  }, [slots]);

  const hasVirtualTime = useMemo(() => {
    return !!slots.find((slt) => slt.type === AppointmentType.VIRTUAL);
  }, [slots]);

  useEffect(() => {
    setIsOpen(slots.length !== 0);
  }, [slots]);

  return (
    <IonItem lines="none" className="list-item round-border weekday">
      <div className="content">
        <div className="toggle-container">
          <div className={`title${isOpen ? ' open' : ''}`}>
            {type.slice(0, 3)}
          </div>
          <IonToggle
            color="favorite"
            checked={isOpen}
            onIonChange={(event): void => {
              setIsOpen(event.detail.checked);
              if (!event.detail.checked && slots) {
                slots.map((slot) => {
                  removeTime(slot);
                });
              }
            }}
          />
        </div>
        {isOpen ? (
          <>
            <IonList>
              {slots
                .sort((a, b) => a.start.localeCompare(b.start))
                .map((item: Timeslot) => (
                  <IonItem key={item.id} lines="none" className="time-slot">
                    <div className="time-select-container">
                      <IonDatetime
                        className="time-select"
                        displayFormat="hh:mm A"
                        pickerFormat="hh:mm A"
                        minuteValues="0, 30"
                        disabled={item.type === AppointmentType.PROVIDER_LOCATION && locationId !== item.locationId}
                        value={item.startDay === type ? moment(item.start, 'HH:mm').toLocaleString() : moment('00:00', 'HH:mm').toLocaleString()}
                        onIonChange={(event): void => updateTime(item, 'start', event.detail.value || '')}
                      />
                      <IonIcon
                        icon={removeOutline}
                        color="dark"
                        className="arrow"
                      />
                      <IonDatetime
                        className="time-select"
                        displayFormat="hh:mm A"
                        pickerFormat="hh:mm A"
                        minuteValues="0, 30"
                        disabled={item.type === AppointmentType.PROVIDER_LOCATION && locationId !== item.locationId}
                        value={item.endDay === type ? moment(item.end, 'HH:mm').toLocaleString() : moment('00:00', 'HH:mm').toLocaleString()}
                        onIonChange={(event): void => updateTime(item, 'end', event.detail.value || '')}
                      />
                      {item.type === AppointmentType.PROVIDER_LOCATION && locationId !== item.locationId ? (
                        <div className="no-available">Used for other location</div>
                      ) : (
                        <IonSelect
                          value={item.type}
                          onIonChange={(event) => updateTime(item, 'type', event.detail.value as string)}
                        >
                          <IonSelectOption
                            // disabled={hasVirtualTime && item.type !== AppointmentType.VIRTUAL}
                            value="video-visit"
                          >
                            Virtual
                          </IonSelectOption>
                          <IonSelectOption value="provider-visit">Provider Location</IonSelectOption>
                          <IonSelectOption value="customer-visit">Customer Location</IonSelectOption>
                        </IonSelect>
                      )}
                    </div>
                    <IonIcon
                      slot="end"
                      icon={trashOutline}
                      color="dark"
                      className="btn-remove"
                      onClick={(): void => removeTime(item)}
                    />
                  </IonItem>
                ))}
            </IonList>
            <div className="time-select-container">
              <IonButton
                color="dark"
                fill="clear"
                size="small"
                className="btn-add"
                onClick={(): void => {
                  if (hasVirtualTime) {
                    add(type, lastTime, AppointmentType.PROVIDER_LOCATION);
                  } else {
                    add(type, lastTime, AppointmentType.VIRTUAL);
                  }
                }}
              >
                <IonIcon
                  icon={addOutline}
                  color="dark"
                />
              </IonButton>
            </div>
          </>
        ) : (
          <div className="closed">Closed</div>
        )}
      </div>
    </IonItem>
  );
};

WeekdayItem.propTypes = {
  type: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
  slots: PropTypes.array.isRequired,
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
};

export default React.memo(WeekdayItem);
