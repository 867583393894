/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  IonContent,
  IonSegment,
  IonSegmentButton,
  IonLoading,
  IonListHeader,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/react';
import {
  calendarOutline, closeOutline, removeOutline, searchOutline
} from 'ionicons/icons';
import Calendar from 'rc-calendar';
import { DateRange as DateRangePicker, Range } from 'react-date-range';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import moment, { Moment } from 'moment';
import clsx from 'clsx';

import connect from '../../data/connect';
import {
  Booking, BookingStatus, User, UserRole
} from '../../models';
import CustomerBookingItem from '../../components/CustomerBookingItem';
import ProviderBookingItem from '../../components/ProviderBookingItem';
import { fetchBookingsByDate, fetchBookings, socket } from '../../data/dataApi';

import './Bookings.scss';

interface InfiniteScrollCustomEvent extends CustomEvent {
  target: HTMLIonInfiniteScrollElement;
}

interface StateProps {
  user: User
}

type BookingsProps = StateProps;

const Bookings: React.FC<BookingsProps> = ({ user }) => {
  const history = useHistory();
  const [segment, setSegment] = useState<'all' | 'accepted' | 'pending' | 'completed' | 'incomplete' | 'cancelled' | 'abandoned' | 'rejected'>(
    'accepted'
  );
  const [isCalendarView, setIsCalendarView] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [bookingsByDate, setBookingsByDate] = useState<Booking[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [searchKey, setSearchKey] = useState('');
  const [showCalendarPopover, setShowCalendarPopover] = useState(false);
  const [dateRange, setDateRange] = useState<Range | null>(null);
  const [tempDateRange, setTempDateRange] = useState<Range | null>(null);

  const selectPeriod = (type: string) => {
    if (type === 'today') {
      setTempDateRange({
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
        key: 'selection'
      });
    }
    if (type === 'week') {
      setTempDateRange({
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastMonth') {
      setTempDateRange({
        startDate: moment().subtract(1, 'months').startOf('month').toDate(),
        endDate: moment().subtract(1, 'months').endOf('month').toDate(),
        key: 'selection'
      });
    }
    if (type === 'lastQuarter') {
      setTempDateRange({
        startDate: moment().subtract(3, 'months').startOf('quarter').toDate(),
        endDate: moment().subtract(3, 'months').endOf('quarter').toDate(),
        key: 'selection'
      });
    }
  };

  const fetchMore = () => {
    setIsFetching(true);
    const data = {
      skip: bookings.length,
      status: segment,
      dateRange: dateRange ? {
        start: moment(dateRange.startDate).toISOString(),
        end: moment(dateRange.endDate).toISOString()
      } : null,
      searchKey,
    };
    fetchBookings(data)
      .then((res: { success: boolean, bookings: Booking[] }) => {
        if (res.success) {
          setBookings([...bookings, ...res.bookings]);
          if (res.bookings.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsFetching(false));
  };

  const onFetchBookingsWithDateRange = () => {
    setIsFetching(true);
    setIsFetchedAll(false);
    const data = {
      skip: 0,
      status: segment,
      dateRange: dateRange ? {
        start: moment(dateRange.startDate).toISOString(),
        end: moment(dateRange.endDate).toISOString()
      } : null,
      searchKey
    };
    fetchBookings(data)
      .then((res: { success: boolean, bookings: Booking[] }) => {
        if (res.success) {
          setBookings([...res.bookings]);
          if (res.bookings.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsFetching(false));
  };

  const onSelectDate = useCallback(
    async (date: Moment) => {
      setIsFetching(true);
      await fetchBookingsByDate(
        user.id,
        moment(date.format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm')
      )
        .then((res: { success: boolean, bookings: Booking[] }) => {
          if (res.success && res.bookings) {
            setBookingsByDate([...res.bookings]);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setIsFetching(false));
    },
    [user]
  );

  const filterDateBookings = (booking: Booking) => {
    if (segment === 'all') {
      return true;
    }
    if (segment === 'rejected' && booking.status === BookingStatus.REJECTED_PROVIDER) {
      return true;
    }
    if (segment === 'abandoned' && booking.status === BookingStatus.REJECTED_CUSTOMER) {
      return true;
    }
    if (segment !== 'rejected' && segment !== 'abandoned' && booking.status.includes(segment)) {
      return true;
    }
    return false;
  };

  const onUpdateBooking = (booking: Booking) => {
    const updatedBookings = segment === 'all' || (segment === 'accepted' && booking.status === BookingStatus.ACCEPTED)
      ? bookings.map((bk) => {
        if (bk.id === booking.id) {
          return booking;
        }
        return bk;
      })
      : bookings.filter((bk) => bk.id !== booking.id);
    setBookings([...updatedBookings]);
  };

  const onSearch = (str: string) => {
    setIsFetching(true);
    setIsFetchedAll(false);
    const data = {
      skip: 0,
      status: segment,
      dateRange: dateRange ? {
        start: moment(dateRange.startDate).toISOString(),
        end: moment(dateRange.endDate).toISOString()
      } : null,
      searchKey: str
    };
    fetchBookings(data)
      .then((res: { success: boolean, bookings: Booking[] }) => {
        if (res.success) {
          setBookings([...res.bookings]);
          if (res.bookings.length < 30) {
            setIsFetchedAll(true);
          }
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000);

    socket.on('start-meeting', (e: Booking) => {
      fetchMore();
    });

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setBookingsByDate([]);
    setSegment('accepted');
  }, [isCalendarView]);

  useEffect(() => {
    fetchMore();
  }, [segment]);

  useEffect(() => {
    if (!searchKey) {
      onSearch('');
    }
  }, [searchKey]);

  useEffect(() => {
    onFetchBookingsWithDateRange();
  }, [dateRange]);

  const renderDate = (date: moment.Moment) => {
    const today = new Date();
    const isToday = date.year() === today.getFullYear()
                    && date.month() === today.getMonth()
                    && date.date() === today.getDate();

    return (
      <div className={clsx('rc-calendar-date', { today: isToday })}>
        {date.date()}
      </div>
    );
  };

  const renderBooking = (booking: Booking): JSX.Element => {
    if (user.role === UserRole.CUSTOMER) {
      return (
        <CustomerBookingItem
          key={booking.id}
          currentDate={currentDate}
          booking={booking}
          onUpdate={onUpdateBooking}
          onMeeting={(): void => history.push(`/video/${booking.id}`)}
        />
      );
    }
    if (user.role === UserRole.PROVIDER) {
      return (
        <ProviderBookingItem
          key={booking.id}
          currentDate={currentDate}
          booking={booking}
          onUpdate={onUpdateBooking}
          onMeeting={(): void => history.push(`/video/${booking.id}`)}
        />
      );
    }
    if (user.role === UserRole.CLINIC_ADMIN || user.role === UserRole.CLINIC_MEMBER) {
      return (
        <ProviderBookingItem
          key={booking.id}
          currentDate={currentDate}
          booking={booking}
          onUpdate={onUpdateBooking}
          onMeeting={(): void => history.push(`/video/${booking.id}`)}
        />
      );
    }
    return <></>;
  };

  return (
    <IonContent id="booking-list" className="page-content ion-padding">
      <div className="bookings-header">
        <IonListHeader>{user.role === 'customer' ? 'My Bookings' : 'Appointments'}</IonListHeader>
        {isCalendarView && user.role === UserRole.PROVIDER ? (
          <IonButton color="favorite" fill="clear" onClick={() => setIsCalendarView(false)}>Status View</IonButton>
        ) : null}
        {!isCalendarView && user.role === UserRole.PROVIDER ? (
          <IonButton color="favorite" fill="clear" onClick={() => setIsCalendarView(true)}>Calendar View</IonButton>
        ) : null}
      </div>
      {isCalendarView ? null : (
        <div className="booking-filter">
          <IonItem className="booking-filter-option" lines="none">
            <IonInput
              autofocus
              placeholder={user.role === UserRole.CUSTOMER ? 'Search by provider name' : 'search by customer name'}
              value={searchKey}
              onKeyPress={(e) => {
                if (e.keyCode === 13 || e.key === 'Enter') {
                  onSearch(searchKey);
                }
              }}
              onIonChange={(e) => {
                setSearchKey(e.detail.value ?? '');
              }}
            />
            <IonIcon className="icon-btn" size="small" icon={searchOutline} onClick={() => onSearch(searchKey)} />
            <IonIcon className="icon-btn" size="small" icon={closeOutline} onClick={() => setSearchKey('')} />
          </IonItem>
          <Popover
            isOpen={showCalendarPopover}
            positions={['bottom', 'top', 'left', 'right']}
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
                arrowColor="#daf3ef"
                arrowSize={10}
                className="popover-arrow-container"
                arrowClassName="popover-arrow"
              >
                <div className="popover-content calendar">
                  <div className="panel">
                    <div
                      className={clsx('option', { selected: !tempDateRange })}
                      onClick={() => setTempDateRange(null)}
                    >
                      All periods
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment(tempDateRange.endDate).format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('today')}
                    >
                      Today
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().startOf('week').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().endOf('week').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('week')}
                    >
                      This week
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('lastMonth')}
                    >
                      Last month
                    </div>
                    <div
                      className={clsx('option', {
                        selected: tempDateRange
                          && moment(tempDateRange.startDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').startOf('quarter').format('YYYY-MM-DD') && moment(tempDateRange.endDate).format('YYYY-MM-DD') === moment().subtract(3, 'months').endOf('quarter').format('YYYY-MM-DD')
                      })}
                      onClick={() => selectPeriod('lastQuarter')}
                    >
                      Last quarter
                    </div>
                    <IonButton
                      color="favorite"
                      onClick={() => {
                        setDateRange(tempDateRange);
                        setShowCalendarPopover(false);
                      }}
                    >
                      Apply
                    </IonButton>
                  </div>
                  <div className="calendar">
                    <DateRangePicker
                      editableDateInputs
                      onChange={(item) => {
                        setTempDateRange({
                          startDate: moment(item.selection.startDate).toDate(),
                          endDate: moment(item.selection.endDate).add(1, 'days').subtract(1, 'milliseconds').toDate(),
                          key: 'selection'
                        });
                      }}
                      moveRangeOnFirstSelection={false}
                      ranges={tempDateRange ? [tempDateRange] : [
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: 'selection'
                        },
                      ]}
                      rangeColors={['#000']}
                    />
                  </div>
                </div>
              </ArrowContainer>
            )}
            onClickOutside={() => setShowCalendarPopover(false)}
          >
            <IonItem lines="none" className="date-range" onClick={() => setShowCalendarPopover(true)}>
              <div>
                {dateRange ? moment(dateRange.startDate).format('DD MMMM, YYYY') : 'Start Date'}
              </div>
              <IonIcon color="dark" icon={removeOutline} />
              <div>
                {dateRange ? moment(dateRange.endDate).format('DD MMMM, YYYY') : 'End Date'}
              </div>
              <IonIcon slot="end" icon={calendarOutline} />
            </IonItem>
          </Popover>
        </div>
      )}
      {!isCalendarView ? (
        <div className="content">
          <IonSegment
            mode="ios"
            value={segment}
            onIonChange={(e): void => {
              setBookings([]);
              setIsFetchedAll(false);
              setSegment(e.detail.value as 'all' | 'accepted' | 'pending' | 'completed' | 'incomplete' | 'cancelled' | 'abandoned' | 'rejected');
            }}
          >
            <IonSegmentButton value="accepted">Accepted</IonSegmentButton>
            <IonSegmentButton value="pending">Pending</IonSegmentButton>
            <IonSegmentButton value="completed">Completed</IonSegmentButton>
            <IonSegmentButton value="cancelled">Cancelled</IonSegmentButton>
            <IonSegmentButton value="abandoned">Abandoned</IonSegmentButton>
            <IonSegmentButton value="rejected">Rejected</IonSegmentButton>
            <IonSegmentButton value="incomplete">Incomplete</IonSegmentButton>
            <IonSegmentButton value="all">All</IonSegmentButton>
          </IonSegment>
          <IonGrid>
            <IonRow>
              {bookings.map((booking) => <IonCol size="4" key={booking.id}>{renderBooking(booking)}</IonCol>)}
            </IonRow>
          </IonGrid>
          {isFetchedAll ? null : (
            <IonInfiniteScroll
              onIonInfinite={(ev) => {
                fetchMore();
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                setTimeout(() => (ev as InfiniteScrollCustomEvent).target.complete(), 500);
              }}
            >
              <IonInfiniteScrollContent
                loadingText="Please wait..."
                loadingSpinner="circles"
              />
            </IonInfiniteScroll>
          )}
        </div>
      ) : (
        <div className="content">
          <IonGrid>
            <IonRow>
              <IonCol size="4">
                <Calendar
                  className="calendar"
                  showDateInput={false}
                  showToday={false}
                  dateRender={(current) => renderDate(current)}
                  onSelect={(date): Promise<void> => onSelectDate(date)}
                />
              </IonCol>
              <IonCol offset="0.1">
                <IonSegment
                  mode="ios"
                  value={segment}
                  onIonChange={(e): void => setSegment(e.detail.value as 'all' | 'accepted' | 'pending' | 'completed' | 'incomplete' | 'cancelled')}
                >
                  <IonSegmentButton value="accepted">Accepted</IonSegmentButton>
                  <IonSegmentButton value="pending">Pending</IonSegmentButton>
                  <IonSegmentButton value="completed">Completed</IonSegmentButton>
                  <IonSegmentButton value="cancelled">Cancelled</IonSegmentButton>
                  <IonSegmentButton value="abandoned">Abandoned</IonSegmentButton>
                  <IonSegmentButton value="rejected">Rejected</IonSegmentButton>
                  <IonSegmentButton value="incomplete">Incomplete</IonSegmentButton>
                  <IonSegmentButton value="all">All</IonSegmentButton>
                </IonSegment>
                <IonGrid>
                  <IonRow>
                    {bookingsByDate
                      .filter(filterDateBookings)
                      .map((booking) => (
                        <IonCol size="6" key={booking.id}>
                          <ProviderBookingItem
                            key={booking.id}
                            currentDate={currentDate}
                            booking={booking}
                            onUpdate={onUpdateBooking}
                            onMeeting={(): void => history.push(`/video/${booking.id}`)}
                          />
                        </IonCol>
                      ))}
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      )}

      <IonLoading isOpen={isFetching} />
    </IonContent>
  );
};

Bookings.propTypes = {
  user: PropTypes.any.isRequired
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.auth.user
  }),
  component: React.memo(Bookings)
});
