import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import {
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonInput,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonLoading
} from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';

import connect from '../../../data/connect';
import {
  Currency,
  Language, ProviderType, Specialist, User, UserRole
} from '../../../models';
import {
  UserIcon, StethoscopeIcon, SphereIcon, ListIcon, ClockIcon, WalletIcon
} from '../../../icons';
import { updateProviderProfile } from '../../../data/dataApi';
import { dispatchUpdateUser } from '../../../data/store/auth/auth.actions';

interface StateProps {
  allLanguages: Language[]
  allSpecialists: Specialist[]
  providerTypes: ProviderType[]
  currencies: Currency[]
}

interface DispatchProps {
  updateUser: typeof dispatchUpdateUser
}
interface FirstStepProps {
  user: User
  next: () => void
}

const FirstStep: React.FC<FirstStepProps & StateProps & DispatchProps> = ({
  user, next, allLanguages, allSpecialists, providerTypes, currencies, updateUser
}) => {
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [bio, setBio] = useState('');
  const [type, setType] = useState(-1);
  const [currency, setCurrency] = useState(1);
  const [languages, setLanguages] = useState<number[]>([]);
  const [specialists, setSpecialists] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [years, setYears] = useState(0);

  const onSubmit = () => {
    setIsLoading(true);
    const nextStep = user.role === UserRole.PROVIDER ? 1 : 2;
    updateProviderProfile(user.id, {
      profile: {
        email,
        title,
        about: bio,
        type,
        currencyType: currency,
        yearsOfExperience: years,
        onboardingStep: user.onboardingStep > nextStep ? user.onboardingStep : nextStep
      },
      languages,
      specialists,
      locations: user.locations ?? [],
      services: user.services ?? []
    })
      .then((res) => {
        if (res.success) {
          updateUser(res.user);
          next();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const isSubmitDisabled = useMemo(() => {
    if (
      !email
      || !title
      || !bio
      || type < 1
      || years === 0
      || !languages.length
      || !specialists.length
    ) {
      return true;
    }
    return false;
  }, [email, title, bio, type, languages, specialists, years]);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setTitle(user.title);
      setBio(user.about);
      setType(user.type ?? -1);
      setCurrency(user.currencyType);
      setYears(user.yearsOfExperience);
      if (user.languages.length > 0) {
        setLanguages(user.languages.map((language) => language.id));
      }
      if (user.specialists.length > 0) {
        setSpecialists(user.specialists.map((specialist) => specialist.id));
      }
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.type === type) {
        setSpecialists(user.specialists.map((specialist) => specialist.id));
      } else {
        setSpecialists([]);
      }
    }
  }, [type, user]);

  return (
    <div id="step-one">
      <IonGrid className="step-content">
        <IonRow>
          <IonCol>
            <IonList className="step-container user-information">
              <IonItem lines="none" className="input">
                <UserIcon />
                <div style={{ display: 'flex' }}>
                  <span className="mandatory">*</span>
                  <IonInput
                    name="email"
                    type="email"
                    placeholder="Email"
                    required
                    value={email}
                    onIonChange={(e): void => {
                      setEmail(e.detail.value || '');
                    }}
                  />
                </div>
              </IonItem>
              <IonItem lines="none" className="input">
                <StethoscopeIcon />
                <IonInput
                  name="title"
                  type="text"
                  placeholder="Title"
                  required
                  value={title}
                  onIonChange={(e): void => {
                    setTitle(e.detail.value || '');
                  }}
                />
              </IonItem>
              <IonItem lines="none" className="input">
                <UserIcon />
                <div style={{ display: 'flex', width: '100%' }}>
                  <span className="mandatory">*</span>
                  <IonTextarea
                    name="about"
                    value={bio}
                    rows={1}
                    placeholder="About you..."
                    onIonChange={(e): void => {
                      setBio(e.detail.value || '');
                    }}
                  />
                </div>
              </IonItem>
              <IonItem lines="none" className="select">
                <UserIcon />
                <IonSelect
                  className="select"
                  placeholder="Select Profession..."
                  value={type}
                  onIonChange={(e): void => {
                    setType(parseInt(e.detail.value, 10));
                  }}
                >
                  {providerTypes.map((providerType) => (
                    <IonSelectOption key={providerType.id} value={providerType.id}>
                      {providerType.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <IonItem lines="none" className="input">
                <ClockIcon />
                <div style={{ display: 'flex' }}>
                  <span className="mandatory">*</span>
                  <IonInput
                    name="yoe"
                    type="number"
                    placeholder="Years of Experience"
                    value={years || ''}
                    onIonChange={(e): void => {
                      setYears(Number(e.detail.value ?? 0));
                    }}
                  />
                </div>
              </IonItem>
              <IonItem lines="none" className="select">
                <ListIcon />
                <IonSelect
                  className="select"
                  placeholder="Select your specialty..."
                  multiple
                  value={specialists}
                  disabled={!type}
                  onIonChange={(e): void => setSpecialists(e.detail.value)}
                >
                  {allSpecialists
                    .filter((specialist) => specialist.type === type)
                    .map((specialist) => (
                      <IonSelectOption key={specialist.id} value={specialist.id}>
                        {specialist.name}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonItem>
              <IonItem lines="none" className="select">
                <SphereIcon />
                <IonSelect
                  className="select"
                  placeholder="Select languages..."
                  multiple
                  value={languages}
                  disabled={!type}
                  onIonChange={(e): void => setLanguages(e.detail.value)}
                >
                  {allLanguages.map((language) => (
                    <IonSelectOption key={language.id} value={language.id}>
                      {language.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <IonItem lines="none" className="select">
                <WalletIcon />
                <IonSelect
                  className="select"
                  placeholder="Select Currency..."
                  value={currency}
                  onIonChange={(e): void => {
                    setCurrency(parseInt(e.detail.value, 10));
                  }}
                >
                  {currencies.map((cu) => (
                    <IonSelectOption key={cu.id} value={cu.id}>
                      {cu.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonList>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className="actions">
        <IonButton color="favorite" disabled={isSubmitDisabled} onClick={onSubmit}>
          Next
          <IonIcon icon={chevronForwardOutline} />
        </IonButton>
      </div>
      <IonLoading isOpen={isLoading} />
    </div>
  );
};

FirstStep.propTypes = {
  user: PropTypes.any.isRequired,
  next: PropTypes.func.isRequired,
  allLanguages: PropTypes.array.isRequired,
  allSpecialists: PropTypes.array.isRequired,
  providerTypes: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  updateUser: PropTypes.func.isRequired
};

export default connect<FirstStepProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    allLanguages: state.data.allLanguages,
    allSpecialists: state.data.allSpecialists,
    providerTypes: state.data.providerTypes,
    currencies: state.data.currencies
  }),
  mapDispatchToProps: {
    updateUser: dispatchUpdateUser
  },
  component: React.memo(FirstStep)
});
