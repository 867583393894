import React from 'react';
import PropTypes from 'prop-types';
import { IonItem, IonLabel } from '@ionic/react';

import Avatar from './Avatar';
import { User } from '../models';
import StarRating from './StarRating';

interface ClinicMemberItemProps {
  member: User
  onItemClick?: (id: number) => void
}

const ClinicMemberItem: React.FC<ClinicMemberItemProps> = ({
  member,
  onItemClick
}) => (
  <IonItem
    lines="none"
    className="list-item round-border profile cursor-pointer"
    onClick={(): void => onItemClick && onItemClick(member.id)}
    detail={false}
  >
    <Avatar user={member} />
    <IonLabel>
      <h2>{member.name}</h2>
      <p>Clinic Member</p>
    </IonLabel>
    <div style={{ marginTop: 0 }}>
      <StarRating readonly hasOneStar rating={Number((member.rating / 5).toFixed(1))} />
    </div>
  </IonItem>
);

ClinicMemberItem.defaultProps = {
  onItemClick: (): void => {}
};

ClinicMemberItem.propTypes = {
  member: PropTypes.any.isRequired,
  onItemClick: PropTypes.func
};

export default ClinicMemberItem;
