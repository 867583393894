import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { IonItem, IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import moment from 'moment';

import { Message } from '../models';
import { AppContext } from '../data/AppContext';

interface MessageItemProps {
  message: Message
  isSelected: boolean
  onClick?: () => void
}

const MessageItem: React.FC<MessageItemProps> = ({
  message,
  isSelected,
  onClick
}) => {
  const { sender, booking, isRead } = message;
  const { state: { data: { providerTypes } } } = useContext(AppContext);

  const classes = useMemo(() => {
    if (isSelected) {
      return 'notification selected';
    }
    if (isRead) {
      return 'notification read';
    }
    return 'notification';
  }, [isRead, isSelected]);

  const providerType = useMemo(() => {
    if (booking && providerTypes && providerTypes.length) {
      const item = providerTypes.find((pt) => pt.id === booking?.provider?.type);
      if (item) {
        return item.name;
      }
      return '';
    }
    return '';
  }, [providerTypes, booking]);

  const createdAt = useMemo(() => {
    if (moment.utc().diff(moment.utc(message.createdAt), 'days') < 3) {
      return moment.utc(message.createdAt).fromNow();
    }
    return moment.utc(message.createdAt).local().format('YYYY-MM-DD HH:mm');
  }, [message.createdAt]);

  return (
    <IonItem lines="none" className={classes} onClick={onClick}>
      <div>
        {message.type === 'identity.approved'
          || message.type === 'identity.rejected'
          || message.type === 'license.approved'
          || message.type === 'license.rejected'
          || message.type === 'booking.charged'
          || message.type === 'booking.refunded'
          || message.type === 'booking.released'
          || message.type === 'booking.reminder' ? (
            <>
              <h3>Administrator</h3>
              <p>{createdAt}</p>
            </>
          ) : (
            <>
              <h3>{`${sender.name} ${sender.lastName ?? ''}`}</h3>
              <p>{`${createdAt} - ${providerType}`}</p>
            </>
          )}
      </div>
      <div className="icon">
        {isSelected ? <IonIcon icon={chevronForwardOutline} /> : null}
        {!isRead && !isSelected ? <span className="unread" /> : null}
      </div>
    </IonItem>
  );
};

MessageItem.defaultProps = {
  onClick: (): void => {}
};

MessageItem.propTypes = {
  message: PropTypes.any.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func
};

export default MessageItem;
